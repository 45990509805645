import React from 'react';
import ReactTooltip from 'react-tooltip';


// sport is unique for each table, so that's the key
// reusable table component for use in intro sections of tests
const IntroTable = ({ data, caption, extraText }) => {
    return (
        <div className="table-container">
            {
                extraText && 
                <>
                    {extraText}
                    <hr className="table-line"/>
                </>
            }
            <div className="table-subcontainer">
                <table className="default-table">
                    { caption && <caption>{caption}</caption> }
                    <thead>
                        <tr>
                            <th>Sport</th>
                            <th data-tip="The injury rate is the number of injuries per 100,000 athlete exposures.">Injury Rate</th>
                            <th>Reference</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map(row => <TableRow key={row[0]} row={row} />)
                        }
                    </tbody>
                </table>
            </div>
            <ReactTooltip place="top" className="tooltip-axgen" effect="solid"/>
        </div>
    );
};

const TableRow = ({ row }) => {
    return (
        <tr>
            {
                row.map((r, idx) => <td key={idx}>{r}</td>)
            }
        </tr>
    );
};


export default IntroTable;
