import React from 'react';
import { useDispatch } from 'react-redux';
import { _vidListMaker } from './prehab';
import { Link } from 'react-router-dom';

/*
This file contains custom React hooks for use in functional components
*/


// React hook that combines useDispatch and useCallback, removing the boilerplate code necessary to dispatch actions
// Hook accepts a thunk from actions.js and will accept any number of arguments
export const useCustomDispatch = (fn) => {
    const dispatcherExpr = (dispatch, fn) => {
        return (...args) => dispatch(fn(...args));
    };
    const dispatch = useDispatch();
    return dispatcherExpr(dispatch, fn);
};


// custom hook that renders the video list and card under the videos
export const useVideo = (videos) => {
    return (
        <>
            <div className="card p-1">
                { _vidListMaker(videos) }
            </div>
            <div className="card">
                <p className="mb-0">The videos above are examples of exercises that can reduce your chance of injury. For advice personalizing your strength program based on your genetic tests, you can schedule a strength consultation <Link to="/consultations">here</Link>.</p>
            </div>
        </>
    );
};
