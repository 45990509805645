import { LOAD_CRM } from '../actions';

const crmReducer = (state = {}, action) => {
  const newState = Object.assign({}, state);
  let crmID;

  switch (action.type) {
    case LOAD_CRM:
      const { id, val } = action.payload.data;
      newState[id] = val;
      return newState;
    case "LOAD_CRM_REJECTED":
      crmID = action.payload.request.responseURL.split('/crm/')[1];
      newState[crmID] = null;
      return newState;
    default:
      return state;
  }
};

export default crmReducer;
