import React from 'react';
import GeneTable from '../../components/gene-table';

class COQ10Results extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Coencyme Q10</h2>
          <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/27149984/">Degenhardt et al., 2016</a>]</h5>
        </div>
        <div className="card">
          <p>The normal rnage for CoQ10 is 0.85 +/- 0.33 ug/ml</p>
          <p>The <b>score</b> column shows how your genotype affects your levels of Coenzyme Q10.</p>

          <GeneTable tag='COQ10' cols={[ 'gene', 'beta', 'running-total'] } />
        </div>
        <div className='title-break'>
          <span> The genetic test has a <b>small</b> effect on coenzyme Q10.</span>
          </div>
        <div className="card">
        <p>Each A allele at rs9952641 has a beneficial effect of raising your coenzyme Q10 level by 0.06 ug/ml. Since coenzyme Q10 levels normally range between 0.52 to 1.18 μg/ml, the difference in coenzyme Q10 levels between the best and worst genetic scores is about 14%.  </p>
        </div>
         </div>
    )
  }
}

export default COQ10Results;
