import React from 'react';
import GeneTable from '../../components/gene-table';
import RiskGraph from '../../components/risk-graph';
import Collapsible from 'react-collapsible';

class DQTResults extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for De Quervain’s Tenosynovitis</h2>
          <h5>[<a href="https://www.ncbi.nlm.nih.gov/pubmed/28985641">Kim et al., 2017</a>]</h5>
        </div>

        <div className="card p-1">
          <Collapsible trigger="Tutorial">
            <p className='intro-vid'><iframe title="Results" width="560" height="315" src="https://www.youtube.com/embed/kJCXJl1juVs?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
          </Collapsible>
        </div>
        <div className="card">
          <RiskGraph tag='de-quervains-tenosynovitis' />
          <p>The de Quervain's tenosynovitis genetic marker was identified using a European population. When this marker was analyzed in other ancestry groups, no difference was found for Latin American or East Asians (<a href="https://www.ncbi.nlm.nih.gov/pubmed/28985641">Kim et al., 2017</a>). However, the results were different (but not significantly so) for individuals of African ancestry.</p>
        </div>
        <div className="card">
          <p>The A allele of rs35360670 has an allele frequency of 2.4% and leads to a 38% increased risk of injury.  </p>
          <GeneTable tag='de-quervains-tenosynovitis' cols={['alleleFreq', 'relativeRisk'] } />
        </div>
      </div>
    )
  }
}

export default DQTResults;
