import React from 'react';


const GlutenomicsPrevention = () => {
    return (
        <div>
            <div className='title-margins'>
                <h2>Gluten Sensitivity Recommendations</h2>
            </div>
            <div className="card">
            <img src="/assets/imgs/results/content/gluten_free.jpg" height={300} className="overview-img" alt=""/>
                <p>The AxGen gluten genetic score is highly informative, and the good news is that you can delay or prevent celiac disease by simply refraining from eating gluten.  </p>
                <ul className="mb-0">
                    <li>If you have a high gluten genetic score and you are starting to have gastrointestinal problems, consider going on a gluten free diet. Scores above the 50th percentile have increased risk for celiac disease, and the risk gets worse as the genetic score rises. </li>
                    <li>If you have a high gluten genetic score but do not show any symptoms, it is up to you whether or not to cut back on eating foods containing gluten.  </li>
                    <li>Cutting back on gluten may delay or prevent getting celiac disease. However, a gluten-free diet may interfere with your physician's ability to diagnose celiac disease. </li>
                    <li>On the other hand, you could continue to enjoy foods with gluten because only a small fraction of people with a high gluten genetic score get the disease and most are asymptomatic. </li>
                    <li>If you have a high gluten genetic score, you should consult your primary care physician for testing for celiac disease. Your physician can determine your HLA type, as this histocompatibility locus is strongly linked to celiac disease. Your physician may also look for elevated levels of certain antibodies indicative of an immune reaction to gluten and do a biopsy of your intestine. These tests are necessary to diagnose celiac disease. Your physician will then be best suited to give you recommendations based on your results and medical history.</li>
                </ul>
            </div>
        </div>
    );
};

export default GlutenomicsPrevention;
