import React from 'react';


const G6DIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to G6PD Deficiency</h2>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/g6pd_fig.png" height={300}/> 
        <p>Glucose-6-phosphate dehydrogenase (G6PD) deficiency is a genetic disorder caused by mutations in the <i>G6PD</i> gene that encodes an enzyme crucial for glucose regulation. The G6PD enzyme is responsible for helping red blood cells work properly, and deficiency can cause red blood cells to break down faster than they are made. As a result, people with G6PD deficiency can experience a variety of symptoms, including fatigue, jaundice, anemia, and even seizures.</p>
        <p>G6PD deficiency is most common in people of African, Mediterranean, and Southeast Asian descent, and is estimated to affect 6 million people in the United States. It is an inherited condition, meaning that it is passed down from parents to their children. While there is no cure for G6PD deficiency, there are treatments available to help manage the symptoms. These treatments include avoiding certain medications and foods that can trigger a reaction.</p>
      </div>
      <div className='title-break'>
        <span>Causes</span>
      </div>
      <div className="card">
        <p>G6PD deficiency is an inherited condition caused by a mutation in the <i>G6PD</i> gene. Because <i>G6PD</i> is on the X chromosome, deficiency occurs most often in men and is rarer in women.  All men who have a mutation in the gene will have G6PD deficiency. Women who carry one copy of the gene are carriers, and can pass G6PD deficiency to their children. In rare cases, these women may be homozygous for mutations in both of their <i>G6PD</i> copies and exhibit G6PD deficiency.</p>
        <p>AxGen tests for the two most common mutations in <i>G6PD</i> (rs1050828 and rs5030868). Besides these, there are a large number of other <i>G6PD</i> mutations that are very rare and are not tested. </p>
      </div>
      <div className='title-break'>
        <span>Treatment</span>
      </div>
      <div className="card">
        <p>In most cases, G6PD deficiency does not cause problems. Symptoms may occur if you are exposed to medicines or foods that may harm your blood cells, resulting in jaundice and hemolytic anemia. </p>
      </div>
    </div>
  )
}

export default G6DIntro;
