import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const levelToStyle = {
  'error': 'alert-danger',
  'success': 'alert-success'
};


// margT and margB define the margins of the alert, they are set to 4 and 1 respectively by default, but any numbers could be passed in
// TODO: Add ability to render html in alerts
const Alerts = ({ tag='', level='error', message='', forceRender=false, margT = 4, margB = 0 }) => {
  const { alerts } = useSelector(state => ({
    alerts: state.alerts
  }));

  let tagAlerts = (tag in alerts && level in alerts[tag]) ? alerts[tag][level] : [];
  const globalAlerts = ((alerts['global'] || {})[level] || []);
  tagAlerts = tagAlerts.concat(globalAlerts);

  if (tagAlerts.length === 0 && !forceRender) {
    return null;
  };

  return (
    <div className={`alert ${levelToStyle[level]} w-100 text-left mt-${margT} mb-${margB}`}>
      <strong>{message}</strong>
      <ul className={classNames('mb-0', {'list-unstyled': tagAlerts.length === 1})}>
        {tagAlerts.map((alert, i) => (
          <li key={i}>{alert}</li>
        ))}
      </ul>
    </div>
  );
};

export default Alerts;
