import React from 'react';

class MCLPrevention extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>MCL Injury Prehabilitation Measures</h2>
        </div>
        
        <div className="card">
          <p>You can’t be protected from game-day hits. But an overall program that strengthens the hips, thighs and knees provides some protection from MCL injuries.</p>
          <p>Manage your weight. Every pound in excess of your normal weight puts three or four additional pounds of pressure on your knee every time you take a step.</p>
          <p>Keep your legs strong.  A knee that is supported by strong leg muscles is less prone to injury. Therefore, regular exercise and maintaining good physical condition are excellent ways to avoid ligament injuries. The hamstrings (in the back of the thigh) and the quadriceps (in the front of the thigh) are crucial shock and impact absorbers. These muscles must be kept strong and flexible to protect the joint surfaces in your knee.  </p>
          <p>Support. If your knee is unstable or weak, wearing a brace during exercise and activity can reduce the risk of injury of the ligament. For an added level of support and stability, strapping and taping can help the stability of weak and injured knees. </p>
          <p>Stretch. You should stretch during warm-up, but it is important not to over-stretch. </p>
          <p>Balancing Exercises. Your workout routine should include activities that challenge your ability to balance. This initiates the proprioceptive mechanisms surrounding the knee to activate muscles involved in stabilizing the knee to control joint movement and avoid injury.</p>
          <p>Wear proper shoes. Well-cushioned, well-fitting athletic shoes can reduce the impact of the load exerted on the knees.</p>
          
          <p>Ease into shape. If your activities require twisting and turning (such as racket sports, skiing, soccer and basketball), don't assume you can play yourself into shape. Make sure you are in good physical shape before you play.</p>
          <p>Knee brace. Functional bracing may reduce MCL injury in football players, particularly interior linemen.</p>
        </div>
      </div>
    )
  }
}

export default MCLPrevention;
