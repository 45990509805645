import React from 'react';
import ReactTooltip from 'react-tooltip'
import { _tooltipper, _vidListMaker } from '../../utils/prehab';
import { Link } from 'react-router-dom';

const vids = {
  'Assisted Pull Up': 'https://www.youtube.com/embed/5uoXoWGvttY?rel=0',
  'Assisted Scapular Depression': 'https://www.youtube.com/embed/M9bDrGsohPQ?rel=0',
  'Assisted Scapular Retraction': 'https://www.youtube.com/embed/69oTkRBC74I?rel=0',
  'Band Assisted Eccentric Push Up': 'https://www.youtube.com/embed/qqxF0EzGGYI?rel=0',
  'Scapular Depression and Retraction Pull Up': 'https://www.youtube.com/embed/UU3OIdNtkdg?rel=0',
  'Low Row Scapular Retraction': 'https://www.youtube.com/embed/uMwBN4jEcB8?rel=0',
  'Eccentric Push Up': 'https://www.youtube.com/embed/p0gWcXJ42D4?rel=0',
  'Resistance Band Wall Slides': 'https://www.youtube.com/embed/AH0406YDi-o?rel=0',
  'Overhead and Thoracic Mobility': 'https://www.youtube.com/embed/MWUw2BqcOWY?rel=0',
  'Overhead Assisted Mobility': 'https://www.youtube.com/embed/aoZeGyZDWc8?rel=0',
  'Plyometric Push Up': 'https://www.youtube.com/embed/EzCc9_lwNHk?rel=0',
  'Wall Assisted Eccentric Push Up': 'https://www.youtube.com/embed/vTAcwIL7gTg',
  'Weighted Pull Up': 'https://www.youtube.com/embed/A2hMeZQ38_w?rel=0'
};

const ShoulderExercises = (props) => {
  const path = window.location.pathname; // ShoulderExercises covers both Shoulder Instability and Rotator Cuff exercises. Hence why pageHeader determines the header based on current path
  const pageHeader = path.split("/").includes("rotator-cuff") ? "Rotator Cuff Injury Prehabilitation Exercises" : "Shoulder Instability Prehabilitation Exercises"
  const vidList = _vidListMaker(vids);
  const { width } = props;

  return (
    <div>
      <div className="title-margins">
        <h2>{pageHeader}</h2>
      </div>
      <div className="card p-1">
        {vidList}
      </div>
      <div className="card">
        <p className="mb-0">The videos above are examples of exercises that can reduce your chance of injury. For advice personalizing your strength program based on your genetic tests, you can schedule a strength consultation <Link to="/consultations">here</Link>.</p>
      </div>
      <div className="card">
        <p>Essential elements for preventing rotator cuff tears and shoulder instability include: maintaining shoulder flexibility, emphasizing motor control, strengthening the rotator cuff and larger extrinsic shoulder muscles, and performing {_tooltipper("dynamic movement", "dynamic", width)}.</p>
        <p>Muscle tightness in the anterior shoulder girdle can contribute to altered shoulder kinematics. Furthermore, tightness through the pectoralis major and pectoralis minor can add force to the glenohumeral joint and can narrow the subacromial space (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3105366/">Page, 2011</a>). {_tooltipper("Stretching exercises", "range of motion", width)} including the overhead assisted mobility and thoracic mobility can help prevent shoulder tightness. The muscles around the scapula and glenohumeral joint need to have proper mobility to avoid impingement and altered kinematics which, when external force is applied could damage soft tissue leading to shoulder instability. Additionally, the position of the thoracic spine has a large emphasis on resting shoulder position and overall shoulder range of motion. Glenohumeral stability is compromised when an athlete adopts a non-neutral position that leads to excess rotation of the humeral head and possible shoulder impingement.  Both the overhead assisted mobility exercise and the thoracic mobility exercise ensure that one has proper mobility in muscles around the scapula and glenohumeral joint and help improve glenohumeral kinematics.</p>
        <p>Rotator cuff motor control involves developing isolated, smooth movement patterns through flexion, abduction, internal rotation, external rotation and the scapular plane. Abnormal movement patterns as a result of damage or imbalance cause reorganization in the cerebral cortex, and require corrective motor control strategies to retrain the neural connections (<a target="_blank" rel="noopener noreferrer" href="https://www.jstage.jst.go.jp/article/jpts/23/4/23_4_687/_pdf">Bae et al., 2011</a>). Athletes with improper motor control should focus on corrective movement patterns before progressing to strengthening exercises. Dynamic exercises, like plyometric push ups, force the body to control and decelerate a ballistic force. The high velocity eccentric movement of this exercise prepares the shoulder complex to absorb large impact forces common in athletics that can cause shoulder instability or even dislocation.</p>
        <p>During volitional movement, the rotator cuff muscles provide shoulder stability by compressing the humeral head within the glenoid fossa, which enable the larger, extrinsic shoulder muscles to achieve the force needed for arm and shoulder movements (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4827371/">Edwards et al., 2016</a>). The larger extrinsic muscles include the latissimus dorsi, pectoralis major, and deltoid muscles. If the relative activity of the pectoralis major and deltoid muscles is increased greater than the activity of the rotator cuff, athletes may experience decreased stability of the glenohumeral joint (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4668174/">Jo et al., 2015</a>).</p>
        <p>Scapular dyskinesis reflects an imbalance in scapulothoracic power, which can cause protraction of the scapula and can predispose an athlete to shoulder impingement and rotator cuff injury. Certain exercises, which fall into the categories of {_tooltipper("activation", "activation", width)} and {_tooltipper("strength", "strength", width)}, can improve periscapular muscle strength and reduce the risk of rotator cuff injury.</p>
        <p>Strength exercises, namely the scapular depression and retraction pull up and the eccentric push up can improve periscapular muscle strength and can reduce the risk of rotator cuff injury. The scapular depression and retraction pull up works to activate and couple the periscapular muscles including the upper trapezius, lower trapezius, and serratus anterior, helping support scapular kinematics (<a target="_blank" rel="noopener noreferrer" href="http://www.oapublishinglondon.com/images/article/pdf/1397690352.pdf">Postacchini and Carone, 2013</a>). Also, when external force is applied anterior to posterior, the shoulder is at high risk for injury. The eccentric push up exercise helps increase motor control in deceleration at the shoulder complex, thus increasing the ability for the athlete to handle the anterior to posterior forces.</p>
        <p className="mb-0">Alongside strength exercises, activation exercises, namely low row scapular retraction, assisted scapular depression/retraction and resistance band wall slides, warm up the scapular muscle group, increasing motor unit recruitment and neuromuscular efficiency. Protraction of the scapula can lead to shoulder impingement and rotator cuff injury. The low row scapular retraction exercise works to activate and strengthen the periscapular muscles, which helps support scapular kinematics. Activation of the lats and lower trapezius are paramount to maintaining shoulder kinematics. The assisted scapular depression/retraction exercise decreases the load allowing focus to be brought to the periscapular musculature. Finally, resistance band wall slides encourage proper mobility through ab/adduction with an externally-rotated humeral head, helping restore proper range of motion through the pectoral and deltoids which, when overactive can decrease stability of the glenohumeral joint.</p>
      </div>
      <ReactTooltip place="top" className="tooltip-axgen" effect="solid" id="exercise-types" />
    </div>
  );
};

export default ShoulderExercises;
