import React from 'react';


const CancerIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction </h2>
      </div>
      <div className="card">
        <p>This paper presents polygenic risk scores for various cancers. On the results tab, you can look up your scores to find if you have risk for a certain cancer.</p>
        <p>Below are just the bare bones backgound for the various cancers. If you are knowledgeable about some of the cancers and would like to fill in the background, please <a href="mailto:stuart@axgen.us">contact me</a>. </p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20356104">Bladder cancer</a></p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20352501">Cervical cancer</a></p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20353669">Colorectal cancer</a></p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20352461">Endometrial cancer</a></p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20352664">Kidney cancer</a></p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20374620">Lung cancer</a></p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20352428">Lymphocytic leukemia</a></p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20374884">Melanoma</a></p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20375680">Non-Hodgkin's lymphoma</a></p>
        <p>WdbMD background for <a href="https://www.webmd.com/oral-health/guide/oral-cancer">Oral cancer</a></p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20375941">Ovarian cancer</a></p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20353087">Prostate cancer</a></p>
        <p>Mayo Clinic background for <a href="https://www.mayoclinic.org/diseases-conditions/bladder-cancer/symptoms-causes/syc-20354161">Thyroid cancer</a></p>





      </div>


     </div>
  )
}

export default CancerIntro;
