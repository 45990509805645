import { UPDATE_WIDTH } from '../actions';

const defaultState = {
    width: null
};


// reducer responsible for handling ui actions, namely setting the window width in the global state allowing for access around the entire app

const uiReducer = (state = defaultState, action) => {
    Object.freeze(state);
    switch(action.type) {
        case UPDATE_WIDTH:
            const nextState = Object.assign({}, state);
            nextState.width = action.payload.width;
            return nextState;
        default:
            return state;
    };
};

export default uiReducer;