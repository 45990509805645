import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import GeneTable from '../../components/gene-table';

export const PhosResults = (props) => {
    const { crm, result } = props;
    let testComponent;
    if (result['value'] !== null) {    
        let prefixedGeneticScore = result['value'] > 0 ? `+${result['value'].toFixed(2)}` : `${result['value'].toFixed(2)}`;
   
      testComponent =
      <div>
        <div className="card">
        <p>The AxGen test for phosphorus uses a genetic algorithm with 7 genetic markers developed by <a href="https://pubmed.ncbi.nlm.nih.gov/20558539/">Kestenbaum et al., 2010</a>.  </p>
        </div>
        <div className="card">
          <BetaHistogram crm={crm} result={result} crmKey='results-phos-histogram' />
          <p>To use this graph, find the red line that shows your genetic score.  The blue histogram shows the number of people and their phosphorus genetic scores. The bin containing your score tells you how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly lower level of phosphorus.  </p>
          <p>Based solely on your genetics, your predicted phosphorus score is <strong>{prefixedGeneticScore}</strong> mg/dL, which modifies your overall phosphorus level (3.4 mg/dL average).</p> 
          <p><TagPercentile result={props.result} tag='phos'/></p>
        </div>
        <div className="card">
        <p>The <b>score</b> column shows how your genotype affects your levels of phosphorus.</p>
          <GeneTable tag='phos' cols={[ 'gene', 'beta', 'running-total'] } />
          <p>Three loci identified in the discovery analysis were near genes that encode proteins known to be involved in phosphorus metabolism: the sodium phosphate co-transporter type IIa (<i>SLC34A1</i>), the calcium-sensing receptor (<i>CASR</i>), and an endocrine factor that regulates phosphorus balance (<i>FGF23</i>). </p>
        </div>
        <div className='title-break'>
          <span>The genetic test has a <b>small</b> effect on phosphorus.</span>
        </div>
        <div className="card">
        <p>First, the overall variance of phosphorus explained by the 7 SNPs is only 1.5% [<a href="https://pubmed.ncbi.nlm.nih.gov/20558539/">Kestenbaum et al., 2010</a>]. This means that genetics has a much smaller influence on your phosphorus level compared to diet and activity.  </p>
        <p>Second, the blue histogram spans about 0.27 mg/dL, which means that the difference between the best and worst possible genetic score is only about 8% of the average level of phosphorus. </p>
        </div>
      </div>

      return (
      <div>
        <div className='title-margins'>
        <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Phosphorus</h2>
        <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/20558539/">Kestenbaum et al., 2010</a>]</h5>
        </div>
        { testComponent }

      </div>
    );
    };
};


export default PhosResults;
