// api that creates that encapsulates the creation of the url for a calendly instance and autofills user email and name
class CalendlyAPI {
  constructor(consultantUN, name, email, consultantName) {
    this.url = this.createURL(consultantUN, name, email, consultantName);
  };

  // utm parameters is a hidden input meant to track users and what they do. however, i am using it here to pass an immutable user email that will allow the consultation to be searched for and found in the dB once the webhook returns it in a payload
  // the utm passage of email prevents a 404 error if the user changes their email in the prefilled calendly input. the same is true for consultants. the passage of the consultant name as a utm parameter prevents mismatches between the consultant name and/or email on calendly and what is stored for them in the AxGen dB.
  createURL(consultantUN, name, email, consultantName) {
    const splitName = consultantName.split(" ").join("-"); //changes name Andrew Roos to Andrew-Roos for use in a url
    const baseURL = "https://calendly.com/axgen";
    return `${baseURL}/${consultantUN}?name=${name}&email=${email}&utm_content=${email}&utm_source=${splitName}`;
  };
}

export default CalendlyAPI;