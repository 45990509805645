import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import * as Actions from '../actions';
import Banner from './banner';
import { useCustomDispatch } from '../utils/customHooks';

const userNavItemsNoKitMobile = [
  {'url':'/activate','text':'Activate Kit'},
  {'url':'/buy','text':'Buy Genetic Analysis'},
  {'url':'/consultations','text':'Buy Strength Plan'},
  {'url':'/receive','text':'Receive Kit'},
  {'url':'/upload','text':'Upload File'},
  {'url':'/strength','text':'My Strength'},
  {'url':'/team','text':'My Team'},
];
const userNavItemsWKitZeroMobile = [
  {'url':'/activate','text':'Activate Kit'},
  {'url':'/consultations','text':'Buy Strength Plan'},
  {'url':'/strength','text':'My Strength'},
  {'url':'/team','text':'My Team'}
];

// items without urls are placeholders for dropdowns
const userNavItemsNoKitLg = [
  {'url':'/activate','text':'Activate Kit'},
  {'url': '','text':'Products'},
  {'url':'/receive','text':'Receive Kit'},
  {'url':'/upload','text':'Upload File'},
  {'url':'/strength','text':'My Strength'},
  {'url':'/team','text':'My Team'}
];
const userNavItemsWKitZeroLg = [
  {'url':'/activate','text':'Activate Kit'},
  {'url': '','text':'Products'},
  {'url':'/strength','text':'My Strength'},
  {'url':'/team','text':'My Team'}
];

const userNavItemsWKitOneLg = [
  {'url': '','text':'Products'},
  {'url':'/strength','text':'My Strength'},
  {'url':'/team','text':'My Team'}
];
const userNavItemsWKitOneMobile = [
  {'url':'/consultations','text':'Buy Strength Plan'},
  {'url':'/strength','text':'My Strength'},
  {'url':'/team','text':'My Team'}
];

// TODO: since book consultation and buy strength use the same form, we need to come up with better wording for users on step 2 so they know they can get more than just a consultation by clicking on Book Consultation
//   {'url':'/results', 'text':'Results'}, MUST BE THE FIRST ITEM!!
const userNavItemsWResults = [
  {'url':'/results', 'text':'Results'},
  {'url':'/consultations','text':'Schedule Session'},
  {'url':'/strength','text':'My Strength'},
  {'url':'/team','text':'My Team'}
];

// navbar for admins
const adminNavItems = [
  {'url':'/admin','text':'Admin'},
  {'url':'/order','text':'Order Kits'},
  {'url':'/team','text':'My Team'},
  {'url':'/admin/consultations','text':'Consultations'},
  {'url': '/strength', 'text': 'Strength'}
];

const consultantNavItems = [
  {'url':'/team','text':'My Team'},
  {'url':'/strength','text':'Strength'}
];

const managerNavItems = [
  {'url':'/results', 'text':'Results'},
  {'url':'/business','text':'Customer Portal'},
  {'url':'/team','text':'My Team'}
];

// dropdown items, which differ from nav items
// these were split off from the nav items to avoid overlaps
const userDropdownItems = [
  { 'url': '/permissions', 'text': 'Share My Results' },
  { 'url': '/settings', 'text': 'Account Settings' }
];


export const Navbar = ({ stateless, history, clean=false }) => {

  // global state
  const { session } = useSelector(state => ({ 
    session: state.session
  }));
  let { userRole, userKitStep, myUploadID } = session; //destructures the session state

  // local state, set to the width of the window once the page loads
  const [width, adjustWidth] = useState(window.innerWidth);

  // decides if there is a banner or not and handles the banner content
  // the local banner state also needs to be changed in result-viewer.js to match a change to this local state
  const [banner] = useState(false);

  // dispatch
  const updateWindowWidthDispatch = useCustomDispatch(Actions.updateWindowWidthThunk);

  // helper functions
  // extracts a user's initials from their username
  // TODO: create a way to handle suffixes
  const _getUserInitials = () => {
    let initials = '';
    
    // edge case of empty string
    if (session.userName === '') {
      return initials;
    };

    let nameNoWhitespace = session.userName.trim(); //.trim() removes whitespace from both sides of a string 
    let nameArr = nameNoWhitespace.split(" ");
    let firstName = nameArr[0];
    let lastName = nameArr.length > 1 ?  nameArr[1] : null;
    
    // we already know that one's initials will be the aggregation of the first and second words of nameArr (this will likely be modified later once we have a method to manage suffixes)
    switch(nameArr.length) {
      case 0:
        break;
      case 1:
        if (firstName.length > 0) {
          initials += firstName[0].toUpperCase();
        };
        break;
      default:
        if (firstName.length > 0) {
          initials += firstName[0].toUpperCase();
        };
        if (lastName.length > 0) {
          initials += lastName[0].toUpperCase();
        };
        break;
    };

    return initials;
  };

  // generates the dropdown items from passed in array
  const _generateDropdownItems = (items) => {
    if (items === undefined || items.length < 1) return;
    return items.map((item, idx) => (
      <Dropdown.Item key={idx} active={history.location.pathname === item.url} onClick={() => history.push(item.url)}>{item.text}</Dropdown.Item>
    ));
  };

  // does the actual generating of the nav items based on the array passed in
  // handles the very rare edge case if navItems is an empty array
  const _itemGenerator = (navItems) => {
    if (navItems === undefined || navItems.lenth < 1) return;

    // add in a My Results if the user is not a regular user
    if (['strength-user', 'user', 'team-manager'].indexOf(userRole) === -1) {
      let duped = navItems.slice(); //duplicate to avoid mutating state
      if (myUploadID !== null) {
        duped.push({'url':'/results','text': 'My Results'});
      };
      navItems = duped;
    };

    // md and sm devices have nav items as a dropdown
    if (width < 992) {
      return navItems.map((item, idx) => {
        // handle making the nav item active
        let activeLink = history.location.pathname === item.url;
        
        // ensure that My Results is active even in subpages
        if (item.url === '/results' && myUploadID !== null) {
          activeLink = history.location.pathname === item.url || (history.location.pathname.split("/").indexOf(myUploadID.toString()) > -1 && history.location.pathname.split("/").indexOf("results") > -1);
        };

        return (
          <Dropdown.Item key={idx} active={activeLink} onClick={() => history.push(item.url)}>{item.text}</Dropdown.Item>
        );
      });
    
    // lg devices
    } else {
      // generates all of the nav items that are not dropdowns
      // while these can be done with loops, for performance, the dropdowns are built here. loops lead to nested loops
      // Phil: need to modify /results to handle startPage for normal users for MyTeam ane.
      return navItems.map((item, idx) => {
        // handles active nav item
        let linkParentClassName = history.location.pathname === item.url ? 'nav-item active' : 'nav-item';
        let listItemClassName = history.location.pathname === item.url ? 'nav-link active' : 'nav-link';

        // if (item.url === '/results' && myUploadID !== null) {  #myUploadID is undefined
        if (item.url === '/results' && typeof myUploadID !== 'undefined' && myUploadID !== null) {
          let activeCond = history.location.pathname === item.url || (history.location.pathname.split("/").indexOf(myUploadID.toString()) > -1 && history.location.pathname.split("/").indexOf("results") > -1);
          
          
          linkParentClassName = activeCond ? 'nav-item active' : 'nav-item';
          listItemClassName = activeCond ? 'nav-link active' : 'nav-link';
        };
        // the following condition will only be true if a user is a strength-user or user and if the user does not have results yet
        if (item.text === "Products") {
          return (
            <Dropdown className="nav-item pl-0" as="li" key={idx}>
              <Dropdown.Toggle as="a" className="nav-link dropdown-toggle" variant="link" id="dropdown-basic">
              {item.text}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  userKitStep === null ? renderNonMobileDropdownItems([
                    { "url": "/buy", "title": "Genetics" },
                    { "url": "/consultations", "title": "Strength" }
                  ]) : renderNonMobileDropdownItems([
                    { "url": "/consultations", "title": "Strength" }
                  ])
                }
              </Dropdown.Menu>
            </Dropdown>
          );
        } else {
          return (
            <li className={linkParentClassName} key={idx}>
              <Link to={item.url} className={listItemClassName}>{item.text}</Link>
            </li>
          );
        };
      });
    };
  };

  // creates dropdown items from an array of objects in the non-mobile navbar
  const renderNonMobileDropdownItems = (items) => {
    return items.map((item, idx) => (
      <Dropdown.Item key={idx} active={history.location.pathname === item.url} onClick={() => history.push(item.url)}>{item.title}</Dropdown.Item>
    ));
  };

  // generates the role-specific nav items by generating an array of the items and passing it into a function
  // strength users are a special subset of "user"
  const _generateNavItems = () => {
    let navURLs;
    switch(userRole) {
      case "user":
        if (session.startPage){
          (userNavItemsWResults.find(u=>u.text === 'Results') || {}).url = `/results/${session.myUploadID}/${session.startPage}`; 
        }
        navURLs = userNavItemsWResults;
        break;
      case "strength-user":
        if (userKitStep === null) {
          navURLs = width < 992 ? userNavItemsNoKitMobile : userNavItemsNoKitLg;
        } else if (userKitStep === 4) {
          navURLs = userNavItemsWResults;
         } else if (userKitStep === 0) {
          navURLs = width < 992 ? userNavItemsWKitZeroMobile : userNavItemsWKitZeroLg;
        } else {
          navURLs = width < 992 ? userNavItemsWKitOneMobile : userNavItemsWKitOneLg;
        };
        break;
      case "axgen-consultant":
        navURLs = consultantNavItems;
        break;
      case 'axgen-admin':
        navURLs = adminNavItems;
        break;
      case 'team-manager':
        if (session.startPage){
          (managerNavItems.find(u=>u.text === 'Results') || {}).url = `/results/${session.myUploadID}/${session.startPage}`; 
        }
        navURLs = managerNavItems;
        break;
      default:
          navURLs = [];
        break;
    };
    return _itemGenerator(navURLs);
  };

  
  // adjusts the width local state every time the width changes allowing for dynamic resizing
  const _updateWidth = () => {
    let newWidth = window.innerWidth;
    if (newWidth !== width) {
      adjustWidth(newWidth);
    };
  };

  // main function
  // fires on mount and whenever the width state changes
  // creates an event listener that listens for window resizing and updates the local state based on it
  // if there is a width, dispatches action letting the entire app know about the current width state
  useEffect(() => {
    if (width) updateWindowWidthDispatch(width);
    const resize = () => _updateWidth(); //binds the function to this context, allowing for adding and removing at will
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);


  // presentational elements
  // conditional prevents errors if the navbar is stateless, as a stateless navbar has no user, so _getUserInitials fails. sets navBarContent based on regular or mobile screen size
  // separating out the navItems and dropdownItems because these items have different positions depending on screenSize
  let navBarContent;
  if (!stateless) {
    const initials = _getUserInitials();
    const navItems = _generateNavItems();
    const dropdownItems = _generateDropdownItems(userDropdownItems);

    let navBarMobile = 
      <Dropdown alignRight={true} >
        <Dropdown.Toggle as="li" className="nav-item d-flex h-100">
          <span className="align-self-center menu-bars"><i className="fas fa-bars"></i></span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item><span className="nav-user-icon d-flex justify-content-center align-items-center align-self-center">{initials}</span></Dropdown.Item>
          {navItems}
          {dropdownItems}
          <Dropdown.Item onClick={Actions.attemptLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>;
    let navBarRegular =
      <ul className="navbar-nav flex-row">
        { navItems }
        <Dropdown alignRight={true} >
          <Dropdown.Toggle as="li" className="nav-item d-flex h-100">
            <span className="nav-user-icon d-flex justify-content-center align-items-center align-self-center">{initials}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {dropdownItems}
            <Dropdown.Item onClick={Actions.attemptLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ul>;

    navBarContent = (width < 992) ? (
      navBarMobile
    ) : (
      navBarRegular
    );  
  };

  return (
    <div className="sticky-top">
      { banner ? <Banner text="10% of all sales will be donated to the United Way Bay Area COVID-19 fund to support local families in need." url="https://uwba.org/" /> : null }
      <div className={classNames("navbar navbar-expand-lg p-0", {'navbar-clean': clean})}>
        <div className="container">
          <div className="navbar-brand p-0 m-0">
            <Link to="/">
              <img src="/assets/imgs/axgen-logo.png" alt="" className="logo py-2 pr-2" />
              <span className="logo-text">AxGen</span>
            </Link>
          </div>
          { navBarContent }
        </div>
    </div>

    </div>
  );
};

export default withRouter(Navbar);
