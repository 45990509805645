import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import RiskFactorGraph from '../../components/risk-factor-graph';
import { getPercentileText2} from '../../utils/results';

export const AF_YeResults = (props) => {
    const { crm, result, userName, nameEndsWithS } = props;
    let testComponent;
    let percentile;
    let adjusted = result['adjusted_result'];
    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
       adjusted = result['adjusted_result'];
       
    }
    console.log('result :', result)
    console.log('percentile :', percentile)
    console.log('adjusted :', adjusted)
    if (result['value'] !== null) {
        testComponent =
            <div>
                <div className='title-break'>
                    <span>Your Results</span>
                </div>
                <div className="card">
                    <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have higher risk for atrial fibrillation. (Units for Beta are arbitrary.)</p>
                    <div>
                        {
                            adjusted ? (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-adjusted_AF_Ye-histogram' />
                                    <p> You are in the <b>{getPercentileText2(percentile)} </b>. Your age, sex, height and weight have been factored in to your score to better predict your risk for atrial fibrillation.</p>
                                </>
                            ) : (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-AF_Ye-histogram' />
                                    <TagPercentile result={props.result} tag='AF_Ye' />
                                </>
                            )
                        }

                    </div>

                </div>
                <div className='title-break'>
                    <span>How To Interpret Your Results</span>
                </div>
                <div className="card">
                    <p>You can look up your risk for atrial fibrillation using this figure. Using your percentile, find the corresponding bin on the x-axis. Poor, intermediate and ideal refer to how healthy your lifestyle is. Once you know your PRS bin and lifestyle, the value on the y axis tells your hazard ratio for atrial fibrillation, which is how often atrial fibrillation occurs compared to how often it happens in the median group. For example, if your PRS is &lt;1% and you have an intermediate lifestyle, you have a 3.7-fold increased risk of atrial fibrillation.</p>
                    <img src="/assets/imgs/results/content/afib_fig.jpg" height={350} className="overview-img" alt="" />
                </div>
                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">
                    <p>This study developed a polygenic risk score (PRS) for atrial fibrillation that includes 2,996,793 variants based on 92,928 people (3,606 cases of atrial fibrillation). The PRS was then validated on an independent cohort of 183,168 individuals.</p>
                    <p>The study was performed on individuals of White British descent. It is unclear how these results generalize to other ancestry groups.</p>
                </div>

                {
                    adjusted ? (
                        (() => {
                            const graphMax = .07;
                            const data = [0.01324, 0.05262, .06523];
                            return (
                                <div>
                                    <div className='title-break'>
                                        <span>Impact of Genetics and Clinical Risk Factors on Risk for AFIB</span>
                                    </div>
                                    <div className="card">
                                        <RiskFactorGraph graphMax={graphMax} data={data} />
                                        <p>The graph shows how risk for AFIB is affected by genetics, risk factors (height, age, weight, sex) or both combined. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts AFIB exactly and 0.00 means that there is no information. For AFIB, genetics has a smaller effect than risk factors, but combining them together has the most effect; overall, the combined score explains 6.5% of the variance in the AFIB. </p>
                                    </div>
                                </div>
                            );
                        })()
                    ) : null
                }
            </div>

        return (
            <div>
                <div className='title-margins'>
                    <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Atrial Fibrillation</h2>

                    <h5><a href="https://pubmed.ncbi.nlm.nih.gov/33433237/">Ye et al., 2021</a></h5>
                </div>
                {testComponent}

            </div>

        );
    };
};


export default AF_YeResults;
