import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import RiskFactorGraph from '../../components/risk-factor-graph';

export const ThCResults = (props) => {
    const { crm, result } = props;
    let testComponent;
    let adjusted = result['adjusted_result'];
    // const { adjusted_result } = result;
    if (result['value'] !== null) {
        let prefixedGeneticScore = result['value'] > 0 ? `+${result['value'].toFixed(2)}` : `${result['value'].toFixed(2)}`;
        testComponent =
            <div>
                <div className='title-break'>
                    <span>Your Results</span>
                </div>
                <div className="card">
                <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have higher risk for thyroid cancer. (Units for Beta are arbitrary.)</p>
                    <div>
                        {
                            adjusted ? (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-adjusted_ThC-histogram' />
                                    <p> <TagPercentile result={result} tag='adjusted_ThC' /> Your sex has been factored in to your score to better predict your risk for thyroid cancer.</p>
                                    
                                </>
                            ) : (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-ThC-histogram' />
                                    <TagPercentile result={props.result} tag='ThC' />
                                </>
                            )
                        }

                    </div>

                </div>
                <div className='title-break'>
                    <span>How To Interpret Your Results</span>
                </div>
                <div className="card">
                <img src="/assets/imgs/results/content/ThC_chart.png" height={300} className="overview-img" alt="" />
                <p>You can look up your risk for thyroid cancer using this figure. Find your percentile on the x-axis, then find your ancestry group. Your risk for thyroid cancer is approximately your odds ratio (OR), shown on the y-axis.</p>
                <p>EUR (European), CSA (Central South Asian), AFR (African)</p>
                <p>For example, if you are European in the 95th percentile (decile 10), then your risk for thyroid cancer is about 4.5 times more than a European in the 5th percentile (decile 1).</p>
                </div>
                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">
                <p>This study developed a polygenic risk score for thyroid cancer that includes 911,462 variants based on &gt;2.2 million individuals from the Global Biobank Meta-analysis Initiative, which brings together 23 biobanks developed in countries spanning different continents.</p>
                </div>

                {
  adjusted ? (
    (() => {
      const graphMax = .055 ;
      const data = [0.015,  0.035, .05];
      return (
        <div>
          <div className='title-break'>
            <span>Impact of Genetics and Age on Risk for Thyroid Cancer</span>
          </div>
          <div className="card">
            <RiskFactorGraph graphMax={graphMax} data={data} />
            <p>The graph shows how risk for thyroid cancer is affected by genetics, sex or both combined. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts thyroid cancer exactly and 0.00 means that there is no information. For thyroid cancer, genetics has a smaller effect than sex, but combining them together has the most effect; overall, the combined score explains 5% of the variance in risk for thyroid cancer. </p>
          </div>
        </div>
      );
    })()
  ) : null
}
            </div>

        return (
            <div>
                <div className='title-margins'>
                    <h2>Results for Thyroid Cancer</h2>
                    <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/36777179/">Wang et al. (2023)</a>]</h5>
                </div>
                {testComponent}

            </div>
            
        );
    };
};


export default ThCResults;
