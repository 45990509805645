import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as Actions from '../actions';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import Alerts from '../components/alerts';

// TODO: Once sessions are implemented, consider removing localStorage usage
// TODO: transform component into a functional component with hooks

class ReceivePage extends React.Component {
  constructor(props) {
    super(props);
    this.form = { shipping: {} }
    this.state = { errors: [], disabled: false }
  }
  
  reduceForm(fields) {
    return Object.entries(fields).reduce((acc, entry) => {
      const [key, ele] = entry;
      if (ele.value === undefined) { // is object not DOM (e.g. shipping)
        acc[key] = this.reduceForm(ele);
      } else {
        acc[key] = ele.value;
      }
      return acc;
    }, {});
  }

  submitForm(evt) {
    evt.preventDefault();
    this.setState({disabled: true});
    const body = this.reduceForm(this.form);
    Actions.clearAlerts();
    Actions.receiveKit(body);
  }

  componentDidMount() {
    Actions.clearAlerts();
  };

  // undisables the submit in case of errors
  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.alerts).length !== 0 && (JSON.stringify(this.props.alerts) !== JSON.stringify(prevProps.alerts))) {
      this.setState({disabled:false});
    };
  };

  render() {
    const { alerts, width } = this.props;
    const success = ((alerts['claim-kit'] || {})['success'] || []).length > 0;
    const params = new URLSearchParams(this.props.location.search);
    const colClass = width < 992 ? "col-12" : "col";

    return (
      <div>
        <Navbar />
        <div className="container mt-4">
          <div className="row mx-0">
            <div className={`${colClass} px-0 align-self-center`}>
              <h2 className={ width < 768 ? 'text-center' : null }>Receive Kit</h2>
              <form
                onSubmit={(evt) => { this.submitForm(evt); }}
                className={classNames("card form mt-3 py-3", { "d-none": success })}
              >
                <div>
                  <div>
                    <strong>Order ID</strong>
                    <p className="mt-2">Please enter your order ID</p>
                    <input
                      type="text"
                      ref={(ref) => { this.form.orderId = ref; }}
                      placeholder="order ID (16 numbers and letters)"
                      className="form-control"
                      defaultValue={ params.get('orderID') || "" }
                      required
                    />
                  </div>
                  <hr className="my-4" />
                </div>

                <strong>Shipping Details</strong>
                <p className="mt-2">Please tell us where you would like us to ship your kit. (Unfortunately we cannot ship outside of the United States).</p>
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      ref={(ref) => { this.form.shipping.name = ref; }}
                      name="full-name"
                      placeholder="full name"
                      className="form-control"
                      defaultValue=""
                      required
                    />
                  </div>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      ref={(ref) => { this.form.shipping.street = ref; }}
                      name="ship-address"
                      placeholder="street address"
                      className="form-control"
                      defaultValue=""
                      required
                    />
                  </div>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      ref={(ref) => { this.form.shipping.apt = ref; }}
                      name="apt-number"
                      placeholder="apt / suite"
                      className="form-control"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      ref={(ref) => { this.form.shipping.city = ref; }}
                      name="ship-city"
                      placeholder="city"
                      className="form-control"
                      defaultValue=""
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      ref={(ref) => { this.form.shipping.state = ref; }}
                      name="ship-state"
                      placeholder="state / province"
                      className="form-control"
                      defaultValue=""
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      ref={(ref) => { this.form.shipping.zip = ref; }}
                      name="ship-zip"
                      placeholder="zip / postal code"
                      className="form-control"
                      defaultValue=""
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      ref={(ref) => { this.form.shipping.country = ref; }}
                      name="ship-country"
                      placeholder="country"
                      className="form-control"
                      defaultValue=""
                      required
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  className="form-control btn btn-axgen mb-0"
                  value="RECEIVE KIT"
                  disabled={this.state.disabled}
                />
              </form>
              <Alerts tag='claim-kit' message="Failed to receive kit." />
              <Alerts tag='claim-kit' level='success' />
            </div>
            <div className="form-img-container align-self-center">
              <img src="/assets/imgs/axgen-kit2.png" height={350} className="form-img-wrap" alt=""/>
            </div>
          </div>
          <br/>
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
    orders: state.orders,
    session: state.session,
    width: state.ui.width
  };
};

export default connect(mapStateToProps)(ReceivePage);
