import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { tableGenerator, getAllOrdersConfigs } from '../utils/data-tables';
import { withRouter } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { loadAdminOrdersThunk, clearAlerts, addAlert, addManagerThunk, removeManagerThunk } from '../actions';
import { useCustomDispatch } from '../utils/customHooks';
import Alert from '../components/alerts';


// passing in history as part of react-router-dom (withRouter gives us access to this)
const AdminPage = ({ history }) => {
  // global state
  const { alerts, admin, session, ui } = useSelector((state) => ({
    alerts: state.alerts,
    admin: state.admin,
    session: state.session,
    ui: state.ui
  }));

  const [managerEmailToRemove, setManagerEmailToRemove] = useState("");
  const [newManagerEmail, setNewManagerEmail] = useState("");
  const [newManagerCodes, setNewManagerCodes] = useState("");
  const [newUploadCodes, setNewUploadCodes] = useState("");
  const [disabled, setDisabled] = useState(false);

  // dispatch
  const loadAdminOrdersDispatch = useCustomDispatch(loadAdminOrdersThunk);
  const addManagerDispatch = useCustomDispatch(addManagerThunk)
  const removeManagerDispatch = useCustomDispatch(removeManagerThunk);

  // helper functions

  // FEDEX codes currently are 12 digits long
  // usps codes vary, but they are longer than 12 digits, that's the key 
  // this function handles usps and fedex codes. I would add ups, but they have some policy against tracking with urls rather than their api
  // target="blank" opens up a new window if the link is not already opened in a new window, else it reuses the same window, this is safer than target="_blank", which was used before
  const _trackingExtractor = (str) => {
    const carriers = {
      "FEDEX": `https://www.fedex.com/apps/fedextrack/?tracknumbers=${str}`,
      "USPS": `https://tools.usps.com/go/TrackConfirmAction?tLabels=${str}`
    };
    let url;
    switch(str.length) {
      case 12:
        url = carriers["FEDEX"];
        break;
      default:
        url = carriers["USPS"];
        break;
    }
    return (
      <a href={url} target="blank">{str}</a>
    );  
  };

  //sets up the data states for this page 
  const _setupData = () => {
    if (!admin || (admin && !admin.orders)) return [[], [], [], [], [], []];

    // empty arrays that will be set as the local states once the loop completes
    let orderDataArr = [];
    let uploadDataArr = [];
    let kitDataArr = []
    let bulkCodesDataArr = [];
    let bulkKitsDataArr = [];
    let progressKitsArr = [];

    // order.resultsAvailable is coming up null?
    admin.orders.forEach(order => {
      // first, we add rows to the all orders table
      let orderRow = [order.orderDate, order.orderID, order.orderedBy, order.orderedFor, order.inviteeEmail, order.couponCode, order.orderType, order.kitBarcode, order.trackingNum, order.kitStatus, order.failureMessage, order.updatedAt, order.lastUpdatedDays, order.resultsAvailable];
      orderDataArr.push(orderRow);

      // handles setting up the different arrays based on conditionals
      switch(order.orderType) {
        case 'ancestry':
        case '23andme':
          // these are uploads without results
          if (order.resultsAvailable === "-" && order.orderedFor !== "-") {
            let uploadRow = [order.orderID, order.orderedFor, order.orderType, order.orderDate, order.updatedAt, order.lastUpdatedDays];
            uploadDataArr.push(uploadRow);
          };
          break;
        case 'kit':
          // handles orders from admins, consultants, and team-managers, as only they could bulk order. this is different than from regular users that order kits
          // consultants cannot view (& use) the bulk order form, but in the past they could, which is why order.orderByRole includes (legacy) consultant orders
          if ((order.orderByRole !== 'user' || order.orderByRole !== "-") && order.orderedFor === "-") {
            let bulkKitRow = [order.orderID, order.orderedBy, order.kitBarcode, order.trackingNum, order.orderDate, order.updatedAt, order.lastUpdatedDays];
            bulkKitsDataArr.push(bulkKitRow);
            
          //conditional accounts for the kits at akesgoen, but without results
          } else if (order.kitStatus !== "-" && order.resultsAvailable === '-') {
              let akesProgressRow = [order.orderID, order.orderedFor, order.kitBarcode, order.kitStatus, order.failureMessage, order.orderDate, order.updatedAt, order.lastUpdatedDays];
              progressKitsArr.push(akesProgressRow);

          //these are kits belonging to regular users with no results
          } else if (order.resultsAvailable === '-' && order.orderedFor !== "-") {
            let kitRow = [order.orderID, order.orderedFor, order.kitBarcode, order.trackingNum, order.orderDate, order.updatedAt, order.lastUpdatedDays];
            kitDataArr.push(kitRow);
          };
          break;
        case "*":
          // bulk email codes not connected to any accounts
          if (order.orderedFor === "-") {
            let bulkCodesRow = [order.orderID, order.inviteeEmail, order.orderedBy, order.orderDate, order.updatedAt, order.lastUpdatedDays];
            bulkCodesDataArr.push(bulkCodesRow);
          };
          break;
        default:
          break;
      };
    });

    return [orderDataArr, uploadDataArr, kitDataArr, bulkKitsDataArr, bulkCodesDataArr, progressKitsArr];
  };

  // returns the url of one's results
  // Delivers result to orderCols lin 188
  const _resultsExtractor = (result) => (
    <a href={`/results/${result}`} target="blank">{result}</a>
  );


  // main functions

  // make a new manager
  const addManager = e => {
    e.preventDefault();
    clearAlerts();
    setDisabled(true);
    let codeCount = newManagerCodes !== "" ? parseInt(newManagerCodes) : 0;
    if (codeCount < 0 || codeCount > 100) {
      addAlert("add-manager", "error", "Number of codes must be between 0 and 100");
    };
    let uploadCount = newUploadCodes !== "" ? parseInt(newUploadCodes) : 0;
    if (codeCount < 0 || codeCount > 100) {
      addAlert("add-manager", "error", "Number of uploads must be between 0 and 100");
    };
    addManagerDispatch(newManagerEmail, codeCount, uploadCount);
  };

  // remove an existing manager
  const removeManager = e => {
    e.preventDefault();
    clearAlerts();
    setDisabled(true);
    removeManagerDispatch(managerEmailToRemove);
  };

  // redirects to homepage for unauthorized users
  useEffect(() => {
    if (session.userRole === 'axgen-admin') {
      loadAdminOrdersDispatch();
    } else {
      history.push("/");
    };
  }, []);

  // un-disables form submission if there are alerts
  useEffect(() => {
    if (Object.keys(alerts).length > 0) {
      setDisabled(false);
      // clear inputs if success
      if (alerts.hasOwnProperty('add-manager') && alerts['add-manager'].hasOwnProperty('success')) {
        setNewManagerCodes("");
        setNewUploadCodes("");
        setNewManagerEmail("")
      };
      if (alerts.hasOwnProperty('remove-manager') && alerts['remove-manager'].hasOwnProperty('success')) {
        setManagerEmailToRemove("");
      };
    };
  }, [alerts]);


  // get the data for the tables
  const [orderData, uploadData, kitData, bulkCodesData, bulkKitsData, progressData]  = _setupData();

  // generates the orders table
  // array of orderCols, that is the columns for the order table
  // this array looks irregular because links, like tracking number, need to be presented as a customBodyRender to avoid errors
  const orderCols = ["Date Ordered", "Order ID", "Ordered By", "Ordered For", "Invitee Email", "Coupon Code", "Order Type", "Kit Barcode", 
    {
      name: "Tracking Number",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== '-') {
            return (
              _trackingExtractor(value)
            );
          } else {
            return value;
          };
        }
      }
    },
    "Kit Status", "Failure Message", "Last Updated", "Days Since Last Update", {
      name: "Results Available",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== '-') {
            return (
              _resultsExtractor(value)
            );
          } else {
            return "False";
          };
        }
      }
    }
  ];
  const orderTable = tableGenerator(orderData, orderCols, "All Orders", ui.width, getAllOrdersConfigs());


  // generates the uploads without results table
  const uploadCols = ["Order ID", "Ordered For", "Order Type", "Date Ordered", "Last Updated", "Days Since Last Update"];
  const uploadTable = tableGenerator(uploadData, uploadCols, "User Uploads Without Results", ui.width);

  // generates the kits without results table
  const kitCols = ["Order ID", "Ordered For", "Kit Barcode", {
      name: "Tracking Number",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== '-') {
            return (
              _trackingExtractor(value)
            );
          } else {
            return value;
          };
        }
      }
    }, "Date Ordered", "Last Updated", "Days Since Last Update"];
  const kitTable = tableGenerator(kitData, kitCols, "User Kits Without Results", ui.width);


  // generates unused bulk code table
  const bulkCodesCols = ["Order ID", "Invitee Email", "Ordered By", "Data Ordered", "Last Updated", "Days Since Last Update"];
  const bulkOrderCodesTable = tableGenerator(bulkCodesData, bulkCodesCols, "Invited Users Without Accounts", ui.width);

  // generates unused bulk kits table
  const bulkKitsCols = ["Order ID", "Ordered By", "Kit Barcode", 
    {
      name: "Tracking Number",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== '-') {
            return (
              _trackingExtractor(value)
            );
          } else {
            return value;
          };
        }
      }
    }, "Data Ordered", "Last Updated", "Days Since Last Update"];
  const bulkOrderKitsTable = tableGenerator(bulkKitsData, bulkKitsCols, "Unused Kits at Enterprises", ui.width);


  // generates the table for kits at akesogen
  const progressCols = ["Order ID", "Customer", "Kit Barcode", "Kit Status", "Failure Message", "Data Ordered", "Last Updated", "Days Since Last Update"];
  const progressKits = tableGenerator(progressData, progressCols, "Kits at AKESOgen", ui.width);

  // styles adjacent columns to width 50% (side-by-side) or 100% (different lines) based on the screen size 
  const colStyle = ui.width < 992 ? "" : "col-6";
  const topTableCol = ui.width < 992 ? "" : "col-12";
  const tableSection = ui.width < 992 ? "flex-column" : "flex-row";
  const mobileMargin = ui.width < 992 ? "mb-4" : "mb-2";

  const adminActionForm =
    <div className="admin-section mb-3">
      <div className='title-break mb-4'>
          <span>Enterprise Control</span>
      </div>
      <div className={`${tableSection} d-flex`}>
        <div className={` ${colStyle} ${mobileMargin}`}>
          <form className="card form py-3 my-3" onSubmit={e => addManager(e)}>
            <h4 className="text-center">Add Manager & Codes</h4>
            <p>Using this form, you can make a normal user a team manager and assign the person the number of codes you specify. For users that are already team managers, this form will increase the number of codes they already have.</p>

            <div>
              <strong>Email</strong>
              <p className="mt-1">Please type the person's email. Note that the email must be associated with an existing AxGen account.</p>
              <input
                className="form-control"
                placeholder="email *"
                type="email"
                value={newManagerEmail}
                required
                onChange={(e) => setNewManagerEmail(e.target.value)}
              />
              <hr />
            </div>
            <div>
              <strong>Number of Kit Codes</strong>
              <p className="mt-1">Please indicate how many preloaded codes you want the person to have. The number must be between 0 and 100. If you specify no number, no codes will be preloaded. </p>
              <input
                className="form-control"
                placeholder="number of codes"
                type="number"
                min="0"
                max="100"
                value={newManagerCodes}
                onChange={(e) => setNewManagerCodes(e.target.value)}
              />
              <hr />
              
              <strong>Number of Upload Codes</strong>
              <p className="mt-1">Please indicate how many preloaded upload codes you want the person to have. </p>
              <input
                className="form-control"
                placeholder="number of uploads"
                type="number"
                min="0"
                max="100"
                value={newUploadCodes}
                onChange={(e) => setNewUploadCodes(e.target.value)}
              />
              <hr />
              <input
                type="submit"
                className="form-control btn btn-axgen mb-0"
                value="ADD MANAGER / KIT CODES / UPLOAD CODES"
                disabled={disabled}
              />
              <Alert tag="add-manager" level="error" />
              <Alert tag="add-manager" level="success" />
            </div>
          </form>
        </div>
        <div className={`${colStyle}`}>
          <form className="card form py-3 my-3" onSubmit={(e) => removeManager(e)}>
            <h4 className="text-center">Remove Manager</h4>
            <p>Using this form, you can remove someone's team manager status and make him or her a regular user. Note that this form does not delete the user from the system, and the person will keep any unused preloaded codes.</p>
            <div>
              <strong>Email</strong>
              <p className="mt-1">Please type the person's email. Note that the email must be associated with an existing AxGen account.</p>
              <input
                className="form-control"
                placeholder="email *"
                type="EMAIL"
                required
                value={managerEmailToRemove}
                onChange={(e) => setManagerEmailToRemove(e.target.value)}
              />
              <hr />
            </div>
            <input
              type="submit"
              className="form-control btn btn-axgen mb-0"
              value="REMOVE MANAGER"
              disabled={disabled}
            />
              <Alert tag="remove-manager" level="error" />
              <Alert tag="remove-manager" level="success" />
          </form>
        </div>  
      </div>
    </div>


  const adminOnlySection =
    <div>
      <div className="admin-section mb-3">
        <div className='title-break mb-4'>
            <span>Kits at AKESOgen</span>
        </div>
          <div className={`${topTableCol}`}>
            {progressKits}
          </div>
      </div>
      <div className="admin-section mb-3">
        <div className='title-break mb-4'>
            <span>Customer Orders</span>
        </div>
        <div className={`${tableSection} d-flex`}>
          <div className={` ${colStyle} ${mobileMargin}`}>
            {uploadTable}
          </div>
          <div className={`${colStyle}`}>
            {kitTable}
          </div>  
        </div>
      </div>
      <div className="admin-section mb-3">
        <div className='title-break mb-4'>
            <span>Enterprise Orders</span>
        </div>
        <div className={`${tableSection} d-flex`}>
          <div className={` ${colStyle} ${mobileMargin}`}>
            {bulkOrderCodesTable}
          </div>
          <div className={`${colStyle}`}>
            {bulkOrderKitsTable}
          </div>  
        </div>
      </div>
      { adminActionForm }
    </div>

  return (
    <div>
      <Navbar />
      <div className="container mt-4">
        <div className="buy-header text-center mb-2">
            <h2>Admin Dashboard</h2> 
        </div>
        <div className="admin-section mb-3">
          <div className='title-break mb-4'>
              <span>All Orders</span>
          </div>
          <div className={`${topTableCol}`}>
            {orderTable}
          </div>
        </div>
        { adminOnlySection }
      </div>
      <div className="mt-4">
      <Footer />
      </div>
    </div>
  );
};

export default withRouter(AdminPage);