import React from 'react';


const HAMYDIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Hereditary Amyloidosis</h2>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
        <p>Hereditary amyloidosis is a condition in which abnormal protein deposits (called amyloid) form in almost every tissue in the body. Harmful deposits most often form in the heart, kidneys, and nervous system. These protein deposits damage the tissues and interfere with how organs work.</p>
        <p>The most common form of hereditary amyloidosis is caused by a mutation in the transthyretin (<i>TTR</i>) gene. This mutation results in misfolding of the TTR protein, which can cause it to form insoluble protein aggregates that are not broken down and accumulate in the heart, kidneys, liver, and other organs. The protein aggregates are called amyloid plaques and their accumulation can lead to organ damage and other serious complications.  </p>
      </div>
      <div className='title-break'>
        <span>Symptoms</span>
      </div>
      <div className="card">
        <p>Signs and symptoms of amyloidosis may include:</p>
        <ul>
          <li>Severe fatigue and weakness</li>
          <li>Shortness of breath</li>
          <li>Numbness, tingling, or pain in the hands or feet</li>
          <li>Swelling of the ankles and legs</li>
          <li>Diarrhea, possibly with blood, or constipation</li>
          <li>An enlarged tongue, which sometimes looks rippled around its edge</li>
          <li>Skin changes, such as thickening or easy bruising, and purplish patches around the eyes</li>
        </ul>
        <p>Amyloidosis can seriously damage the:</p>
        <ul>
          <li>Heart. Amyloid reduces the heart's ability to fill with blood between heartbeats. Less blood is pumped with each beat. This can cause shortness of breath. If amyloidosis affects the heart's electrical system, it can cause heart rhythm problems. Amyloid-related heart problems can become life-threatening.</li>
          <li>Kidneys. Amyloid can harm the kidneys' filtering system. This affects their ability to remove waste products from the body. It can eventually cause kidney failure.</li>
          <li>Nervous system. Nerve damage can cause pain, numbness, or tingling of the fingers and feet. If amyloid affects the nerves that control bowel function, it can cause periods of alternating constipation and diarrhea. Damage to the nerves that control blood pressure can make people feel faint if they stand up too quickly.</li>
        </ul>
      </div>
      <div className='title-break'>
        <span>Diagnosis and Treatment</span>
      </div>
      <div className="card">
        <p>Early diagnosis of hereditary amyloidosis is very important to successful treatment. Because the disease is inherited, genetic screening can be an effective way to identify a patient's family members who may carry the mutation.</p>
        <p>As the liver produces the mutated transthyretin protein, a liver transplant may be a treatment option, if the disease is caught early enough. Patients with severe heart involvement may benefit from a heart transplant.</p>
      </div>
    </div>
  )
}

export default HAMYDIntro;
