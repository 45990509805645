import React from 'react';

class VitAIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Vitamin A</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/vitA.jpg" height={200}/>   
        <p>Vitamin A (retinol) is a fat-soluble vitamin with several important functions, including helping the body’s immune system function properly, keeping skin healthy, maintaining cellular communication, and improving vision, especially in low light conditions. Vitamin A is an essential micronutrient, meaning that it cannot be manufactured by our bodies and therefore it must be consumed through diet or supplements to maintain proper vitamin A levels. Good sources of vitamin A include cheese, eggs, liver, fish, milk and yoghurt. Additionally, you can indirectly get vitamin A by including sources of beta-carotene in your diet that can be converted by your body into vitamin A. Good sources of beta-carotene are red/yellow vegetables (like carrots, sweet potatoes, and peppers), leafy vegetables (like spinach and kale), and yellow fruits (such as papayas and mangos).</p>
        </div>
        <div className='title-break'>
          <span>Symptoms of Vitamin A deficiency</span>
        </div>  
        <div className="card">
                <ul style={{listStyleType: 'square'}}>
                  <li>Impaired night vision is an early warning sign of vitamin A deficiency. In extreme cases, impaired vision can lead to partial or total blindness.</li>
                  <li>Dry flaky skin and sudden rashes. </li>
                  <li>A weakened immune system leading to an increased likelihood and severity of illnesses.</li>
              </ul> 
        </div>
        <div className='title-break'>
          <span>Vitamin A Recommended Daily Allowances</span>
        </div>        
        <div className="card">
        <p>The UK National Health Service recommends that men (ages 18-65) consume 700 micrograms (µg) and women (ages 18-65) consume 600 µg of vitamin A per day. Vitamin A that is not used is stored in the liver, usually up to 3000 µg. The liver can store excess vitamin A for years at a time. However, <a href="https://www.health.harvard.edu/newsletter_article/vitamin-a-and-your-bones"> consuming too much vitamin A</a> (1500-3000µg/day) can lead to weaker bones, increasing the likelihood of bone fractures as well as the likelihood of developing osteoporosis later in life. Because vitamin A is fat soluble, the body stores excess amounts, and these levels can accumulate over time.</p>
        </div>
      </div>
    )
  }
}

export default VitAIntro;
