import React from 'react';
import GeneTable from '../../components/gene-table';


const HAMYDResults = (props) => {

    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Hereditary Amyloidosis</h2>
            </div>
            <div className="card">
                <p>Your genotype for Hereditary Amyloidosis is shown in the table below. Table definitions can be shown by hovering your mouse over the column headings.</p>
                <GeneTable tag='hamyd' cols={['gene', 'alleleFreq']} />
            </div>
            <div className='title-break'>
                <span>Comments</span>
            </div>
            <div className="card">
                <p>Transthyretin (TTR or TBPA) is a transport protein in the plasma and cerebrospinal fluid that transports the thyroid hormone thyroxine (T4) and retinol to the liver. The liver secretes TTR into the blood, and the choroid plexus secretes TTR into the cerebrospinal fluid.</p>
                <p>Mutations in the <i>TTR</i> gene cause the transthyretin protein to aggregate and to form amyloid plaques, giving rise to various amyloid diseases including hereditary transthyretin amyloidosis, familial amyloid polyneuropathy (caused by amyloid plaques in the peripheral nervous system) and familial amyloid cardiomyopathy (caused by amyloid plaques in the chambers of the heart).</p>
                <p>rs76992529:</p>
                <ol>
                    <li>A person with one or two copies of the (A) allele at rs76992529 is at 2 - 3 fold higher risk of developing cardiac amyloidosis, in which levels of the transthyretin protein build up and can lead to heart failure, especially with increasing age.</li>
                    <li>rs76992529 causes an amino-acide change (Valine to Isoleucine at position 122) in the TTR protein.  </li>
                    <li>This mutation is most common in African Americans (allele frequency of 2%).</li>
                </ol>
                <p>rs28933979:</p>
                <ol>
                    <li>A person carrying one or two copies of the (A) allele at rs28933979 has higher risk for familial amyloid polyneuropathy, with the majority of carriers developing the condition by the time they are 80.  Over 92% of people with rs28933979 (A) have either retinal amyloid angiopathy (RAA) or choroidal amyloid angiopathy (CAA).    </li>
                    <li>rs28933979 causes an amino-acide change (Valine to Methionine at position 30) in the TTR protein.</li>
                    <li>This mutation is most common in people of Portuguese, Northern Swedish, and Japanese descent.</li>
                </ol>
                <p>rs121918070:</p>
                <ol>
                    <li>A person carrying one or two copies of the (G) allele at rs121918070 has higher risk for TTR-related familial amyloid polyneuropathy, often manifesting as heart-related symptoms.</li>
                    <li>rs121918070 causes an amino-acide change (Threonine to Alanine at position 60) in the TTR protein.</li>
                    <li>The rs121918070 (G) mutation is overall rare, but has an allele frequency of 0.5% in people of Irish descent.</li>
                </ol>
                <p>Results showing a mutation in <i>TTR</i> should be verified by clinical sequencing.</p>
                <p>The normal form of TTR eventually forms amyloid plaques, but it takes a long time. Supercentenarians are people that live to be over 110, and 70% of them die from amyloid plaques in their heart, a condition known as senile systemic amyloidosis. Senile systemic amyloidosis is caused by the eventual aggregation of normal TTR. </p>
            </div>
        </div>
    );
};

export default HAMYDResults;