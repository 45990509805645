import React from 'react';
import GeneTable from '../../components/gene-table';
import RiskGraph from '../../components/risk-graph';

class Chondro2Results extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Runner's Knee</h2>
        </div>
        <div className="card">
          <RiskGraph tag='chondro2' />
          <p>Although runner's knee sometimes refers to a diverse set of injuries affecting the knee, this genetic test is for a specific injury involving the cartilage below your kneecap, called chondromalacia patellae.  The bar graph shows your risk of runner's knee compared to the risk for a person lacking the risk allele (1.00). Your risk is calculated based on your genotype at the DNA polymorphism rs144449054.</p>
        </div>
        <div className="card">
          <p>Having an A at the rs144449054 genetic marker indicates significant increased risk (3.5 fold). However, only 0.36% of individuals have a risk allele.  </p>
          <GeneTable tag='chondro2' cols={[ 'alleleFreq', 'relativeRisk'] } />
        </div>
      </div>
    )
  }
}

export default Chondro2Results;
