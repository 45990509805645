import React from 'react';
import GeneTable from '../../components/gene-table';


const G6DResults = (props) => {

    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for G6PD Deficiency</h2>
            </div>
            <div className="card">
                <p>Your genotype for G6PD deficiency is shown in the table below. Table definitions can be shown by hovering your mouse over the column headings.</p>
                <GeneTable tag='g6d' cols={['gene', 'alleleFreq']} />
            </div>
            <div className='title-break'>
                <span>Comments</span>
            </div>
            <div className="card">
                <p>The <i>G6PD</i> gene encodes the enzyme glucose-6-phosphate dehydrogenase. People with a deficiency for this gene are predisposed to hemolysis (spontaneous destruction of red blood cells). Most individuals with G6PD deficiency are asymptomatic. Because the <i>G6PD</i> gene is on the X chromosome, most patients are male.</p>
                <p>In people with glucose-6-phosphate dehydrogenase deficiency, hemolytic anemia is most often triggered by bacterial or viral infections or by certain drugs (such as some antibiotics and medications used to treat malaria). Hemolytic anemia can also occur after eating fava beans or inhaling pollen from fava plants (a reaction called favism).</p>
                <p>The rs5030868 mutation in <i>G6PD</i> is referred to as the “Mediterranean variant”. It causes a non-synonymous Serine to Phenylalanine substitution at amino acid position 188 in the protein resulting in reduced catalytic efficiency and instability. The rs1050828 mutation causes a Valine to Methionine substitution at position 98. A postive result for either of these mutations should be verified by clinical sequencing of the <i>G6PD</i> gene. </p>
                <p>Individuals that are heterozygous for a <i>G6PD</i>  mutation are carriers. If their partner is also a heterozygous carrier for a <i>G6PD</i> mutation, then there is a 25% chance that their children will be homozygous and be at risk for G6PD deficiency.</p>
                <p>Relatives of people carrying a <i>G6PD</i> mutation should consider genetic testing; e.g. siblings have a 50% chance of also carrying the mutation</p>
                <p>Besides the two mutations tested, there are many other rare variants in <i>G6PD</i>. AxGen does not test for these SNPs since they are not detectable by the technology that is used. </p>
            </div>
        </div>
    );
};

export default G6DResults;