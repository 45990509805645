import React from 'react';
import GeneTable from '../../components/gene-table';


const HFEResults = (props) => {

    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Hereditary Hemochromatosis</h2>
            </div>
            <div className="card">
                <p>Your genotype for Hereditary Hemochromatosis is shown in the table below. Table definitions can be shown by hovering your mouse over the column headings.</p>
                <GeneTable tag='hfe' cols={['gene', 'alleleFreq']} />
            </div>
            <div className='title-break'>
                <span>Comments</span>
            </div>
            <div className="card">
                <p>The <i>homeostatic iron regulator (HFE)</i> gene regulates circulating iron uptake by regulating the interaction of the transferrin receptor with transferrin.</p>
                <p>rs1800562 causes an amino-acide change (Cysteine to Tyrosine at position 282) in the HFE protein.</p>
                <p>Individuals with an rs1800562 (A/A) genotype have a 21% (men) or 10% (women) chance of having hemochromatosis. Women are less affected by hemochromatosis due to elimination of excess iron during menstruation.</p>
                <p>Individuals with an rs1800562 (A/A) genotype account for ~85% of all cases of hemochromatosis.</p>
                <p>Individuals with one A allele (A/G) have no symptoms but are carriers of the mutation. If their partner is also a heterozygous carrier for a HFE mutation, then there is a 25% chance that their children will be homozygous and be at risk for hemochromatosis.</p>
                <p>This mutation is only present in people with European ancestry (allele frequency of 0.5%).</p>
            </div>
        </div>
    );
};

export default HFEResults;