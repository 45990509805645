
import React from 'react';
import Collapsible from 'react-collapsible';

// util function that takes in html, an exerciseType, and window width and outputs a tooltip
// object insider the function contains the exercise type and the description
// only will show tips for desktop devices. we must discuss a solution for smaller devices
export const _tooltipper = (html, exerciseType, windowWidth) => {
    const exerciseTooltips = {
        "range of motion": "These exercises are meant to restore proper degrees of motion and freedom of movement to joint complexes. Proper range of motion and muscular tension allows proper motor control, which decreases likelihood of injury.",
        "proprioception": "These exercises are meant to increase 'awareness' and neuromuscular connection. By increasing 'awareness' in muscle groups, we not only increase motor control, but also rate of force development (RFD). The increased motor control and RFD allows the body to react faster to external stimulus decreasing risk of injury.",
        "activation": "These exercises are meant to warm up a muscle group increasing motor unit recruitment as well as neuromuscular efficiency. Being able to generate enough counter force when an external load is applied can decrease the compensatory patterns, which lead to overuse injuries.",
        "strength": "These exercises are meant to increase the musculoskeletal integrity of the athlete. Increasing muscle size and force output are the primary goals. Strength training has been shown to improve the economy of movement in athletes, which in turn decreases the risk of overuse injuries.",
        "dynamic": "These exercises are designed to mimic or replicate athletic movements. Dynamic exercises aim to allow the athlete to practice both skill and strength synergistically. Injuries are more prevalent in unskilled motor patterns. By increasing skillfulness in athletic movements, we decrease risk of injury."
    };    
    let tipHTML;
    if (windowWidth < 992) {
      tipHTML = <span>{html}</span>
    } else {
    // data-for matches with the id of the corresponding ReactTooltip element. this element is placed in the component itself
    // it makes more sense to place the tooltip element itself here, but the DOM forbids making a div ele (tooltip) a child of a p element
      tipHTML = <span className="exercise-span-datatip" data-tip={exerciseTooltips[exerciseType]} data-for="exercise-types">{html}</span>
    };
    return tipHTML;
};


// returns list of collapsibles for videos passed in
// this function works for all of the exercise pages with the exception of the bmd page, as that page has nested dropdowns
export const _vidListMaker = (videos) => {
    return Object.keys(videos).map((title, i) => (
        <Collapsible trigger={title} key={i}>
            <p className="intro-vid"><iframe title={title} width="560" height="315" src={videos[title]} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
        </Collapsible>
    ));
};