import React from 'react';

class CeliacIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Gluten Sensitivity</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/bread2.jpg" height={200}/>   <p>Gluten is the general name for a group of proteins found in certain cereal grains. This includes any species of wheat, as well as barley, rye, and some oats. Gluten makes up around 80% of the total protein found in most wheats. Gluten is important to the structure of most breads, giving dough its elasticity and texture, and helps it maintain its shape.</p>
        <p>Gluten is a good source of several vitamins and minerals like selenium, phosphorus, and folate, but does not otherwise provide essential nutrients. However, gluten can trigger adverse reactions in some people, such as inflammation and an autoimmune response. The most common types of adverse reactions to gluten are:</p>
        <ul >
                    <li>Non-Celiac Gluten Sensitivity (NCGS) - found in approximately 5-10% of the general population.</li>
                    <li>Celiac Disease - present in 1-2% of the general population. But most cases remain unrecognized and undiagnosed, which means that these individuals are untreated and exposed to the risks of long-term complications.</li>
                </ul>
        <p>Both disorders are treated by a gluten-free diet.</p>
        </div>
        <div className='title-break'>
          <span>Non-Celiac Gluten Sensitivity </span>
        </div>
        <div className="card">
        <p>Non-Celiac Gluten Sensitivity (NCGS), also known as gluten intolerance or gluten sensitivity, is a moderately negative reaction from consuming gluten. The most common symptoms of NCGS are headaches, brain-fog, abdominal pain, bloating, depression, and fatigue. In more severe cases of NCGS, the body’s immune system can react and cause intestinal cell damage, though not as severe as the damage caused by celiac disease.  </p>
        <p>These symptoms typically occur soon after gluten ingestion and improve or disappear anywhere from hours to days after abstaining from gluten. NCGS can trigger anytime during life in people who consume gluten. NCGS has many overlapping characteristics with Irritable Bowel Syndrome, wheat allergies, and celiac disease, making it difficult to distinguish between these conditions. </p>
        </div>
      <div className='title-break'>
          <span>Celiac Disease</span>
        </div>
      <div className="card">
        <p>Celiac Disease is a serious autoimmune disorder in which the ingestion of gluten leads to small intestine damage. When someone with celiac disease eats gluten, their body mounts an immune response that attacks the small intestine. This immune attack damages the lining of the small intestine, and interferes with the ability of the intestines to absorb nutrients properly into the body. Both celiac disease and NCGS can develop at any age in people who consume gluten products.</p>
        <p>Celiac disease is strongly dependent on your genetics. The heritability of celiac disease is 80% and a genetic algorithm has been developed that is highly informative for predicting disease [<a href="https://pubmed.ncbi.nlm.nih.gov/16354797/">Nistico et al., 2006</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/26244058/">Abraham et al., 2015</a>]. People with a strong genetic disposition for celiac disease should consider refraining from consuming gluten, as this may delay or prevent the disease.  </p>
      </div>
      </div>
    )
  }
}

export default CeliacIntro;
