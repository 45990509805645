import React from 'react';
import GeneTable from '../../components/gene-table';

class VitEResults extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Vitamin E</h2>
          <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/21729881/">Major et al., 2011</a>]</h5>
        </div>
        <div className="card">
        <p>The average level of vitamin E (alpha-tocopherol) is 11.9 +/- 3.4 mg/L.</p>
        <p>The <b>score</b> column shows how your genotype affects your level of vitamin E.</p>
        <GeneTable tag='vitE' cols={[ 'gene', 'beta', 'running-total'] } />
        </div>
        <div className='title-break'>
          <span>The genetic test has a <b>small to moderate</b> effect on vitamin E.</span>
        </div>
        <div className="card">
          <p>First, each G allele of rs964184, A allele of rs11057830 and T allele of rs2108622 has a beneficial effect on vitamin E levels of 1.00, 0.45 and 0.45 mg/l, respectively. The total level of vitamin E is 11.9 +/- 3.4 mg/l. Second, the difference between the best and worst possible genetic scores is 3.8 mg/l, which is about 30% of the average vitamin E level. Third, the three SNP markers (rs964184, rs2108622, rs11057830) together explain 1.7% of the variance in vitamin E levels, which means that genetics has a much smaller effect than environment on vitamin E levels.</p>
        </div>
         </div>
    )
  }
}

export default VitEResults;
