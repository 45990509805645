import React from 'react';
import GeneTable from '../../components/gene-table';

class BoasResults extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results </h2>
        </div>

        <div className="card">
        <p>Custom Tests for Dr. Steve Boas</p>
        <GeneTable tag='boas' cols={['gene'] } />
        </div>
      </div>
    )
  }
}

export default BoasResults;
