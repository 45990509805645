import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";

import store from './js/store';
import AppRouter from './js/router';

// TODO: reorganize build location
import './build/css/main.css';

render(
  <Provider store={store}>
    <AppRouter />
  </Provider>,
  document.getElementById('root')
);
