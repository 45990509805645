import React from 'react';

const AsthmaIntro = () => {
    return (
        <div>
            <div className='title-margins'>
                <h2>Introduction to Asthma</h2>
            </div>
            <div className="card">
            <img alt="" className="overview-img" src="/assets/imgs/results/asthma_fig.png" height={200}/> 
                <p>Asthma is a disease that affects your lungs. It causes repeated episodes of wheezing, breathlessness, chest tightness, and nighttime or early morning coughing.</p>
                <p>More than 25 million people in the U.S. have asthma, with more than 3 million new cases per year.</p>
            </div>

            <div className='title-break'>
                <span>Causes and Risk Factors</span>
            </div>
            <div className="card">
                <ul>
                    <li>Air pollution: Air pollution includes factory emissions, car exhaust and wildfire smoke.</li>
                    <li>Dust mites: Dust mites can cause an asthma attack if you have a dust mite allergy.</li>
                    <li>Exercise: For some people, exercise can cause an attack.</li>
                    <li>Mold: Damp places can spawn mold, which can trigger an asthma attack.</li>
                    <li>Pests: Cockroaches, mice and other household pests can cause asthma attacks.</li>
                    <li>Pets: Your pets can cause asthma attacks. If you&#39;re allergic to pet dander (dried skin flakes), breathing in the dander can irritate your airways.</li>
                    <li>Tobacco smoke: If you or someone in your home smokes, you have a higher risk of developing asthma. You should never smoke in enclosed places like the car or home, and the best solution is to quit smoking.</li>
                    <li>Strong chemicals or smells. These things can trigger attacks in some people.</li>
                    <li>Certain occupational exposures can trigger asthma, including cleaning products, dust from flour or wood, or other chemicals.</li>
                    <li><strong>Genetics:</strong> The results page shows a genetic test for asthma that scans your entire genome for risk. This test shows that certain people have &gt;5 fold higher risk than others.</li>
                </ul>
            </div>
            <div className='title-break'>
                <span>Symptoms</span>
            </div>
            <div className="card">
                <p>Symptoms of asthma include:</p>
                <ul>
                    <li>Shortness of breath.</li>
                    <li>Chest tightness or pain.</li>
                    <li>Wheezing when exhaling, which is a common sign of asthma in children.</li>
                    <li>Trouble sleeping caused by shortness of breath, coughing or wheezing.</li>
                    <li>Coughing or wheezing attacks that are worsened by a respiratory virus, such as a cold or the flu.</li>
                </ul>
            </div>
            <div className='title-break'>
                <span>Genetic Testing</span>
            </div>
            <div className="card">
                <p>Genotation presents a polygenic risk score for asthma where people in the top 5% have a 2.37 fold increased risk of asthma compared to the rest. </p>
            </div>
            <div className='title-break'>
                <span>Prevention</span>
            </div>
            <div className="card">
                <p>The best way to manage asthma is to avoid triggers, take medications to prevent symptoms, and prepare to treat asthma episodes if they occur.</p>
                <p>The <a href="https://www.cdc.gov/asthma/exhale/index.htm">EXHALE program</a> is a list of strategies to prevent and treat asthma.</p>
            </div>
        </div>

    )
}

export default AsthmaIntro;