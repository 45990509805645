import React from 'react';

const ConsultConfirmation = ({ consultant }) => {

    // shows the confirmation once one schedules a consultation
    const confirmation = 
        <div className="date-time-info">
            <p>Your consultation with { consultant } is confirmed.</p>
            <p>Please check your email for a Calendly invite.</p>
        </div>

    return (
        <div className="consult-show-parent pt-3 pb-0">
            <div className="subsection-title mb-2">Consultation Confirmation</div>
            <div className="consult-datetime">
                <div className="calendar-icon"><i className="far fa-calendar-check"></i></div>
                { confirmation }
            </div>
        </div>
    );
};

export default ConsultConfirmation;