import React from 'react';

class ShoulderIntro extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Introduction to Shoulder Instability</h2>
          <h6>written by Dr. Megan Roche, M.D.</h6>
        </div>

        <div className='title-break'>
          <span>High Risk Sports</span>
        </div>
        <div className='high-risk-list my-3 py-1'>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/quarterback.png'/>
            <span>Football</span>
          </div>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/volleyball.png' />
            <span>Volleyball</span>
          </div>
        </div>

        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" src="/assets/imgs/results/content/shoulderAthlete.jpg" height={300} className="overview-img" />
          <p>The shoulder joint is the most mobile joint in the human body, which can be attributed to the articulation of the humeral head within the glenoid. Given the approximate 30% contact surface between the humeral head and the glenoid fossa, the primary mechanism of shoulder stability can be attributed to soft tissue rather than bony constraints (<a href="https://www.ncbi.nlm.nih.gov/pubmed/25405191">Sofu et al., 2014</a>). The shoulder anatomy creates an inherently unstable joint that is prone to instability. In the general population, the incidence of traumatic shoulder instability is 1.7% (<a href="https://www.ncbi.nlm.nih.gov/pubmed/21808996">Dumont et al., 2011</a>). Instability includes cases of dislocation (complete separation of articular surfaces), subluxation (symptomatic shoulder translation without separation), and apprehension (fear of dislocation or subluxation).</p>
          <p>Athletes account for half of all cases of traumatic shoulder instability, with the highest incidence of shoulder instability seen in ice hockey, football, and wrestling (<a href="https://www.ncbi.nlm.nih.gov/pubmed/19556471">Owens et al., 2009</a>). A study in NCAA collegiate athletes found that shoulder instability injuries resulted in significant recovery time, with 45% of athletes requiring more than ten days off from sport for recovery (<a href="https://www.ncbi.nlm.nih.gov/pubmed/19556471">Owens et al., 2009</a>). </p>
          <p>More than 95% of all shoulder dislocations occur in the anterior direction. Traumatic anterior shoulder dislocation in the athlete often results from a posterior force applied to an abducted, externally rotated arm (<a href="https://www.pulsus.com/scholarly-articles/shoulder-dislocation-in-athletes-overview-and-update-on-treatment-4496.html">Lopez-Franco et al., 2018</a>). Other common mechanisms of injury include trauma to the posterior humerus or a fall on an outstretched arm. The forceful, anterior movement of the humeral head during shoulder dislocation can result in soft tissue and bony lesions that can predispose to recurrent shoulder instability. </p>
          <p>Two to four percent of shoulder dislocations occur in the posterior direction and 0.5% occur in the inferior direction (<a href="https://www.ncbi.nlm.nih.gov/books/NBK441919/">Kammel and Leber, 2018</a>). Posterior shoulder dislocations often occur as a result of anterior-directed shoulder trauma or falling on an outstretched hand. Inferior shoulder dislocations are rare, but can be caused by a sudden and forceful arm hyperabduction.</p>
          <p>Individuals with recurrent shoulder instability often have unidirectional instability from a prior traumatic injury or multidirectional instability due to generalized joint laxity. Young athletes are at high risk for developing recurrent instability following a first shoulder dislocation. <a href="https://www.ncbi.nlm.nih.gov/pubmed/7001307">Rowe</a> found a 79% redislocation rate in individuals between the ages of 20 and 30 years.</p>
        </div>
        
        <div className='title-break'>
          <span>Frequency</span>
        </div>
        <div className="card">
          <p>Athletes participating in collision sports (football), contact sports (wrestling, soccer, basketball), and extreme sports (skiing, snowboarding) are at higher risk for shoulder instability events as a result of acute trauma. Shoulder instability events can also occur from microtrauma associated with the repetitive overhead motion seen in baseball, volleyball, and swimming (<a href="https://www.ncbi.nlm.nih.gov/pubmed/30204635">De Froda et al., 2018</a>).</p>
          <p>In most sports, a higher level of play is associated with greater contact forces. <a href="https://www.ncbi.nlm.nih.gov/pubmed/28556681">Kraeutler et al.</a> found overall higher rates of shoulder dislocation in NCAA athletes compared to high school athletes.</p>
          <p>A study examining 328 NFL football players found that 9.5% of NFL players had a history of shoulder instability prior to entering the NFL (<a href="https://www.ncbi.nlm.nih.gov/pubmed/24427417">LeClere et al., 2013</a>). NFL players who sustained a shoulder dislocation and were treated conservatively returned to NFL regular season play at a median of 3.0 weeks, whereas athletes who sustained a shoulder dislocation and received surgery returned to regular season play at a median of 39.3 weeks. In the same population, NFL players who had surgical repair had a lower recurrence rate (26%) compared to players managed with conservative care (55%) (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5542321/">Okoroha et al., 2017</a>). </p>
          <p>Shoulder instability is also common in the special forces given the physical demands required for service. <a href="https://www.ncbi.nlm.nih.gov/pubmed/19339562">Owens et al.</a> showed an incidence of 1.69 cases of shoulder instability per 1,000 person-years in the military.</p>
          <p>Some studies indicated a higher incidence of shoulder instability events in male athletes, but when analyses were limited to sex-comparable athletic events, no significant differences in incidence rates between male and female athletes were found (<a href="https://www.ncbi.nlm.nih.gov/pubmed/28556681">Kraeutler et al., 2018</a>).  </p>
          <p>The incidence of multidirectional instability is difficult to quantify given the spectrum of hyperlaxity that can occur. However, the incidence of multidirectional instability is higher in overhead athletes, particularly in swimmers and gymnasts (<a href="https://books.google.com/books?id=2THRAQAAQBAJ&pg=PA762&lpg=PA762&dq=epidemiology+shoulder+instability+athletes&source=bl&ots=8Wfz5lb4yr&sig=ACfU3U2glX4L2nDjtQ0M9kjAXCPyMwwmsQ&hl=en&sa=X&ved=2ahUKEwjWm8axu-zhAhWGCTQIHRBxDBc4HhDoATAHegQICBAB#v=onepage&q=epidemiology%20shoulder%20instability%20athletes&f=false">Thompson, 2013</a>).</p>
        </div>
        
        <div className='title-break'>
          <span>Causes</span>
        </div>
        <div className="card">
          <p>The greatest risk factor for shoulder instability is a prior episode of shoulder instability, which highlights the importance of prehabilitation measures. In the scientific literature, there’s a debate on the association between shoulder strength and risk for shoulder instability events. The majority of the research shows a trend linking a decrease in strength of dynamic muscle stabilizers with an increased risk of shoulder instability (6 different citations coming). In athletes, an imbalance in internal/external rotation strength ratio may alter the shoulder’s movement patterns and increase the risk for injury (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4225029/">Sadeghifar et al., 2014</a>).</p>
          <p>The anatomic configuration of the shoulder joint also contributes to the risk of shoulder instability. The ratio of the glenoid height-to-width, also known as the glenoid index, impacts risk for anterior shoulder instability events. <a href="https://www.ncbi.nlm.nih.gov/pubmed/25248922">Owens et al.</a> found that individuals with a glenoid height-to-width ratio of greater than 1.58 (taller and thinner glenoid configuration) had a 2.64 times higher risk of injury compared to individuals with a ratio of less than 1.58. </p>
        </div>
      </div>
    )
  }
}

export default ShoulderIntro;
