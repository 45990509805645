import React from 'react';

class VTE2Intro extends React.Component {
    render() {
        return (
            <div>
                <div className="title-margins">
                    <h2>Introduction to Clotting (Venous Thromboembolism)</h2>
                </div>
                <div className='title-break'>
                    <span>Overview</span>
                </div>
                <div className="card">
                <img alt="" className="overview-img" src="/assets/imgs/results/vte_fig.png" height={300}/> 
                    <p>Venous thromboembolism (VTE) occurs when a blood clot forms in a vein. VTE includes deep vein thrombosis (DVT) and pulmonary embolism (PE). DVT occurs when a blood clot forms in a deep vein, usually in the lower leg, thigh, pelvis and sometimes in the arms. Pulmonary embolism occurs when a clot breaks loose and travels through the bloodstream to the lungs. </p>
                    <p>There are about 600,000 VTE events each year in the United States.  It is the third leading vascular diagnosis after heart attack and stroke, affecting between 300,000 to 600,000 Americans each year.  About half of people with VTE experience a pulmonary embolism. About 25% of those who have a pulmonary embolism die from it. After the age of 40, your risk of VTE almost doubles every decade.</p>
                </div>
                <div className='title-break'>
                    <span>Clinical Risk Factors</span>
                </div>

                <div className="card">
                    <p>Deep vein thrombosis forms in the legs when something slows or changes the flow of blood. The risk of developing VTE is highest after major surgery, major injury, or during periods of infection and inflammation. This is because blood clots can develop in veins damaged by surgery or injury. Lack of movement after surgery or while traveling long distances can raise the likelihood of blood clotting. Inflammation and serious infection raise the likelihood of blood clots. In women, pregnancy and the use of hormones like oral contraceptives or estrogen for menopause symptoms can play a role.</p>
                    <p>Certain groups are at higher risk for clotting:</p>
                    <ul style={{ listStyleType: 'square' }}>
                        <li>Older people.</li>
                        <li>People who are obese or overweight.</li>
                        <li>People with cancer or other conditions (including autoimmune disorders such as lupus).</li>
                        <li>People whose blood is thicker than normal because their bone marrow produces too many blood cells.</li>
                    </ul>

                </div>
                <div className='title-break'>
                    <span>Genetic Risk</span>
                </div>
                <div className="card">
                    <p>Genetics contributes to your risk for VTE, explaining about 30% of the risk for developing blood clots. The Factor V Leiden and prothrombin gene mutation (rs1799963) are Mendelian genetic defects that increase your risk for blood clotting. About 3% to 8% of people with ancestors from Europe have a copy of the Factor V Leiden mutation. About 1 in 50 people with ancestors from Europe have the prothrombin mutation.</p>
                    <p>Recently, a new genetic test has been developed that is at least as informative for VTE as the two Mendelian mutations (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6858581/">Klarin et al., 2019</a>). The genetic test is offered exclusively by AxGen, and is a polygenic risk score (PRS) with 297 SNPs that provides information about one’s risk for VTE. The PRS does not include either of the two Mendelian risk factors, so it is independent. The 5% of people with the highest PRS risk score have a 3 fold increased risk (similar to the risk for people that carry the Factor V Leiden mutation).</p>

                </div>
                <div className='title-break'>
                    <span>Symptoms</span>
                </div>
                <div className="card">
                    <p>Signs of an obstructive DVT occur at the site of the clot. They can include:</p>
                    <ul>
                        <li>Swelling.</li>
                        <li>Redness</li>
                        <li>Warmth</li>
                        <li>Pain</li>
                    </ul>
                    <p>A pulmonary embolism can cause sudden chest pain and shortness of breath. Without treatment, VTE can restrict or block blood flow and oxygen, which can damage the body’s tissue or organs. This can be especially serious in the case of a pulmonary embolism, which blocks blood flow to the lungs. If a blood clot is large or there are many clots, a pulmonary embolism can cause death.</p>
                </div>
                <div className='title-break'>
                    <span>Treatment</span>
                </div>
                <div className="card">
                    <p>Anticoagulant medications include:</p>
                    <ul>
                        <li>Aspirin.</li>
                        <li>Warfarin (Coumadin® or Jantoven®), a tablet you swallow.</li>
                        <li>Heparin, a liquid medication you get through an IV in your vein or from an injection in the hospital.</li>
                        <li>Low-molecular weight heparin, an injection you get once or twice a day.</li>
                        <li>Fondaparinux, an injection.</li>
                        <li>Direct oral anticoagulants (tablets) such as rivaroxaban, apixaban or dabigatran.</li>
                    </ul>
                </div>

            </div>
        )
    }
}

export default VTE2Intro;
