import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Cleave from 'cleave.js/react';
import Dropzone from 'react-dropzone'
import Alerts from '../components/alerts';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import * as Actions from '../actions';
import { Link } from 'react-router-dom';

const fileDropContents = {
  'null': {
    'title': 'Select File for Upload',
    'container-class': '',
    'icon': 'fa-cloud-upload',
    'subtitle': 'Click or Drag & Drop to select file'
  },
  'success': {
    'title': 'Ready for Upload',
    'container-class': 'active',
    'icon': 'fa-check-circle',
    'subtitle': 'Click or Drag & Drop to change file'
  },
  'failed': {
    'title': 'Invalid File',
    'container-class': 'failed',
    'icon': 'fa-times-circle',
    'subtitle': 'We only accept .txt files. Click or Drag & Drop to change file'
  }
}

const instructions = {
  '23andme': <div className="download-instructions">
      <h2 className="mobile-title">How to Download Your 23andMe Data</h2>
      <p>If you don't already have your 23andMe file downloaded <strong>since 2015</strong>, please follow these instructions:</p>
      <ol>
        <li className="break-word">Go to your 23andMe data dashboard <a rel="noopener noreferrer" href="https://you.23andme.com/tools/data/download/" target="_blank">here</a></li>
        <li>Click the blue <b>Submit Request</b> button near the bottom of the page</li>
        <li>Wait a few minutes for your files to be ready (you will also be notified by email)</li>
        <li>Follow email instructions, or reload dashboard page and click blue <b>Download raw data</b> button</li>
        <li>Once you download the file, unzip it</li>
      </ol>
      <p>If there are any difficulties, please view the <a href="https://customercare.23andme.com/hc/en-us/articles/212196868-Accessing-and-Downloading-Your-Raw-Data" target="_blank" rel="noopener noreferrer">Official 23andMe Download Guide</a> or email us at <a href="mailto:contact@axgen.us?Subject=23AndMe%20Download%20Help">contact@axgen.us</a>.</p>
    </div>,
  'ancestry': <div className="download-instructions">
      <h2 className="mobile-title">How to Download Your Ancestry Data</h2>
      <p>If you don't already have your Ancestry file, please follow these instructions:</p>
      <ol>
        <li>Sign in to your Ancestry account <a target="_blank" rel="noopener noreferrer" href="http://ancestry.com/">here</a></li>
        <li>From any page on Ancestry, click the <b>DNA tab</b> and select <b>Your DNA Results Summary</b></li>
        <li>On your DNA homepage, click <b>Settings</b> on the right side of the page</li>
        <li>In the panel on the right side, click <b>Download Raw DNA Data</b></li>
        <li>When you receive the email from AncestryDNA, open it and click <b>Confirm Data Download</b></li>
        <li>On the Download DNA Raw Data page on Ancestry, click <b>Download DNA Raw Data</b></li>
        <li>Once you download the file, unzip it</li>
      </ol>
      <p>If there are any difficulties, please view the <a href="https://support.ancestry.com/s/article/Downloading-AncestryDNA-Raw-Data" target="_blank" rel="noopener noreferrer">Official Ancestry Download Guide</a> or email us at <a href="mailto:contact@axgen.us?Subject=Ancestry%20Download%20Help">contact@axgen.us</a>.</p>
    </div>
};

class UploadFilePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      disabled: false,
      orderID: "",
      uploadType: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  };

  // handles the change in the input field
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    Actions.clearAlerts();
    this.props.clearKitStatus();
    // autofill fields from params
    const params = new URLSearchParams(this.props.location.search);
    if (params && params.get('orderID') && params.get('uploadType')) {
      this.setState({ 
        orderID: params.get('orderID'),
        uploadType: params.get('uploadType')
      });
    // if no params, we look for an order in the dB instead
    } else {
      this.props.loadKit('upload');
    };
  };

  submitForm(evt) {
    evt.preventDefault();
    this.props.clearAlerts();
    this.setState({disabled: true});
    const { file, orderID, uploadType } = this.state;
    if (file && file['content']) {
      this.props.uploadFile(file['content'], uploadType, orderID);
    } else {
      // un-disables the submit if there is an error, preventing the button from staying disabled
      // this is a problem only if the user tries to upload an invalid file multiple times in a row
      this.props.addAlert('upload-file', 'error', 'Invalid file. Genome file must be in .txt format.');
      this.setState({ disabled: false });
    }
  }

  uploadFile(file) {
    const reader = new FileReader();
    reader.onload = () => {
      file['content'] = reader.result
      this.setState({ file });
    };
    if (file.type === "text/plain") {
      reader.readAsBinaryString(file);
    } else {
      this.setState({ file });
    }
  }

  componentDidUpdate(prevProps) {
    // autofills orderID and uploadType
    const myOrder = this.props.myOrder;
    if ((Object.keys(myOrder).length !== 0) && (Object.keys(myOrder).length !== Object.keys(prevProps.myOrder).length)) {
      this.setState({
        uploadType: myOrder.orderType,
        orderID: myOrder.orderID
      });
    };

    // undisables submit if alerts
    if (Object.keys(this.props.alerts).length !== 0 && (JSON.stringify(this.props.alerts) !== JSON.stringify(prevProps.alerts))) {
      this.setState({ disabled: false });
    };
  };

  render() {
    const { alerts } = this.props;
    const { file, disabled, orderID, uploadType } = this.state;
    const fileStatus = file === null ? 'null' : (file['content'] ? 'success' : 'failed');
    const fileData = fileDropContents[fileStatus];
    const success = ((alerts['upload-file'] || {})['success'] || []).length > 0;

    const instructionBox = uploadType !== "" ? (
      <div className="col-12 col-md-6 px-5">
      { instructions[uploadType] }
      </div>
    ) : null;

    return (
      <div>
        <Navbar />
        <div className="container mt-5">
          <div className="mx-auto">
            <div className="row justify-content-center">
              { instructionBox }
              <div className="col-12 col-md-6">
                <h2 className="mobile-title">Upload Genome File</h2>
                <p>Use the form below to upload your genome file. If you have not purchased an AxGen file upload yet, you must do so <Link to="/buy">here</Link> before uploading your genome.</p>
                <form
                onSubmit={(evt) => { this.submitForm(evt); }}
                className={classNames("card form mt-3 pt-3", {"d-none":success})}
                >
                  <div>
                    <strong>File Type</strong>
                    <p className="mt-1">Please select your file type</p>
                    <select
                      onChange={this.handleChange}
                      className="form-control"
                      name="uploadType"
                      value={uploadType}>
                      <option value="" disabled>Select File Type</option>
                      <option value="23andme">23andMe</option>
                      <option value="ancestry">Ancestry</option>
                    </select>
                    <hr />
                  </div>
                  <div>
                    <strong>Order ID</strong>
                    <p className="mt-1">Please enter your order ID</p>
                    <Cleave
                      type="text"
                      className="form-control"
                      value={orderID}
                      required
                      onChange={this.handleChange}
                      name="orderID"
                      options={{
                        uppercase: true
                      }}/>
                    <hr className="my-0" />
                  </div>
                  <div className="my-4 file-upload">
                  <strong>Genome File</strong>
                    <p className="mt-1">Please drag and drop your file into the box below, or click on the box below and select your file. We only accept files in .txt format.</p>
                    <Dropzone onDrop={acceptedFiles => this.uploadFile(acceptedFiles[0])}>
                      {({getRootProps, getInputProps}) => (
                        <div className={
                          `card-container ${fileData['container-class']}`}
                          {...getRootProps()}
                        >
                          <b>{fileData['title']} {file !== null && '('+file['name']+')'}</b>
                          <div className={`fal fa-2x d-block my-2 ${fileData['icon']}`} />
                          <small>{fileData['subtitle']}</small>
                          <input {...getInputProps()} accept=".txt" />
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  <small>Uploads may take a few minutes depending on your internet connection. Please be patient. If uploads take longer than 5 minutes, please <a href='mailto:contact@axgen.us'>contact us</a>.</small>
                  <br/>
                  <input
                    type="submit"
                    className="form-control btn btn-axgen mb-0"
                    value={disabled ? "UPLOADING..." : "UPLOAD GENOME"}
                    disabled={disabled}
                  />
                </form>
                <Alerts tag='upload-file' message="Failed to upload file" />
                <Alerts tag='upload-file' level='success' />
              </div>
            </div>
          </div>
        </div>
        <br/><br/>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  alerts: state.alerts,
  myOrder: state.orders.myKit
});

const mapDispatchToProps = dispatch => ({
  clearAlerts: () => Actions.clearAlerts(),
  addAlert: (tag, level, msg) => Actions.addAlert(tag, level, msg),
  uploadFile: (fileContents, fileType, orderId) => dispatch(Actions.uploadFileThunk(fileContents, fileType, orderId)),
  loadKit: (orderType) => dispatch(Actions.loadKitThunk(orderType)),
  clearKitStatus: () => dispatch(Actions.clearKitStatusThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFilePage);
