import React from 'react';
import GeneTable from '../../components/gene-table';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';


class PUFAResults extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Omega-3 Polyunsaturated Fatty Acids (PUFA)</h2>
          <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/21829377/">Lemaitre et al., 2011</a>]</h5>
        </div>
        <div className="card">
          <BetaHistogram crm={this.props.crm} result={this.props.result} crmKey='results-PUFA-histogram' />
          <p>To use this graph, find the red line that shows your genetic score.  The blue histogram shows the number of people and their PUFA genetic scores. The bin containing your score tells how many other people have a score like yours.  If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly lower level of omega-3 polyunsaturated fatty acids. The X axis shows values in % DPA (DPA is one of the omege-3 polyunsaturated fatty acids).  </p>
          <p>Your genetic score for omega-3 polyunsaturated fatty acids is <strong>{ typeof this.props.result.value === "number" ? `${this.props.result.value.toFixed(2)} % DPA` : null}</strong>. This number is the change in docosapentaenoic acid (DPA) from the average, which is 0.90%. DPA levels range from 0.83% to 0.98% DPA/total fatty acids. </p>
          <p><TagPercentile result={this.props.result} tag='PUFA'/></p>
        </div>
        <div className="card">
        <p>The <b>score</b> column shows how your genotype affects your levels of omega-3 polyunsaturated fatty acids.</p>  
          <GeneTable tag='PUFA' cols={[ 'gene', 'running-total'] } />
          <p><i>FADS1</i> encodes a fatty acid desaturase and <i>ELOVL2</i> encodes an elongase that are both directly involved in the synthesis of omega-3 polyunsaturated fatty acids. <i>GCKR</i> encodes a glucokinase regulator that is associated with levels of PUFAs. </p>
        </div>
        <div className='title-break'>
          <span>The genetic test has a <b>moderate</b> effect on omega-3 polyunsaturated fatty acids</span>
        </div>
        <div className="card">
          <p>The <i>FADS1</i> and <i>ELOVL2</i> SNPs explain more than 11% of the variance in DPA levels.  </p>
        </div>
      </div>
    )
  }
}

export default PUFAResults;
