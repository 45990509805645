import React from 'react';
import { _vidListMaker } from '../../utils/prehab';
import { Link } from 'react-router-dom';

const vids = {
    'Banded Forward Neck Flexion': 'https://www.youtube.com/embed/8Ca6kuzjYZE',
    'Banded Lateral Flexion': 'https://www.youtube.com/embed/WJ3r39QwDVU',
    'Banded Neck Extension': 'https://www.youtube.com/embed/WWH9mT0BjbA',
    'Tall Kneeling Neck Mobility': 'https://www.youtube.com/embed/2K0N6nbDEZQ'
};


const ConcussionExercises = () => {
  const vidList = _vidListMaker(vids);

  return (
    <div>
        <div className="title-margins">
            <h2>Concussion Prehabilitation Exercises</h2>
        </div>
        <div className="card p-1">
            {vidList}
        </div>
        <div className="card">
            <p className="mb-0">The videos above are examples of exercises that can reduce your chance of injury. For advice personalizing your strength program based on your genetic tests, you can schedule a strength consultation <Link to="/consultations">here</Link>.</p>
        </div>
        <div className="card">
            <p>Recent studies highlight that greater neck strength is associated with a decreased kinematic response of the head to impulse/force loading, resulting in reduced concussion susceptibility (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6037875/#bib5">Toninanto et al., 2018</a>). A study performed in high school athletes found that every 1 lb increase in neck strength resulted in a 5% decrease in odds for concussion (<a href="https://www.ncbi.nlm.nih.gov/pubmed/24930131">Collins et al., 2014</a>). Performing neck exercises 2-3 times per week can help improve neck strength, size, and posture.</p>
            <p className="mb-0">Examples of strengthening exercises include: isometric neck extension/flexion, lateral isometric neck flexion, banded neck rotation, and neck wall presses.</p>
        </div>
    </div>
  );
};

export default ConcussionExercises;
