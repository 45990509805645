import React, { useEffect } from 'react';
import { loadCRMThunk } from '../actions';
import { getPercentileSuffix, findBucket } from '../utils/results';
import { useSelector } from 'react-redux';
import { useCustomDispatch } from '../utils/customHooks';


export const TagPercentile = ({ tag, result, polygenic = false, usesUKData = false, showPercentile = false }) => {
  const { crm } = useSelector((state) => ({
    crm: state.crm
  }));

  const crmKey = `results-${tag}-percentile`;
  const loadCRM = useCustomDispatch(loadCRMThunk);

  useEffect(() => {
    if (crm && !crm.hasOwnProperty(crmKey)) {
      loadCRM(crmKey);
    };
  }, []);

  // handles percentiles where counts = 100
  // counts are 100 values for each percentile. 
  // cur is the current number from each percentile.
  // val is the value from the test.
  // This finds the percentile when the value is at a percentile (agg).
  const findPercentileOutOf100 = (counts, val) => {
    return counts.reduce((agg, cur, i) => {
      if (cur < val) return i;
      return agg;
    }, 0);
  };

  // handles percentiles where counts != 100
  const findPercentile = (counts, val) => {
    let bucketSum = counts.reduce((agg, cur, idx) => {
      if (idx > val) return agg;
      return agg + cur;
    });
    let totalPop = counts.reduce((acc, cur) => acc + cur);
    return (bucketSum / totalPop) * 100;
  };

  // generates the suffix and percentile needed for rendering
  const generateTagPercentile = () => {
    let suffix, percentile, val;
    const crmVal = (crm && crm.hasOwnProperty(crmKey)) ? crm[crmKey] : null;
    if (crmVal) {
      if (!usesUKData) {
        val = result.value;
        percentile = findPercentileOutOf100(crmVal, val);
      } else {
        // the crm state shape for these tests is: { "binSize": [], "binScore": [] } 
        if (crmVal.hasOwnProperty("binSize") && crmVal.hasOwnProperty("binScore")) {
          if (tag === 'male-testosterone' || tag === 'female-testosterone') {
            val = result.value;
          } else {
            val = result.hasOwnProperty('estScore') ? result.estScore : result.value;

          };
          val = result.hasOwnProperty('estScore') ? result.estScore : result.value;
          let index = findBucket(val, crmVal.binScore);
          percentile = findPercentile(crmVal.binSize, index);
        };
      };
      // round to a whole number
      if (typeof percentile === "number") percentile = percentile.toFixed(0);
      // get the percentile suffix
      suffix = getPercentileSuffix(percentile);
    };
    
    return [percentile, suffix];
  };
  const [percentile, suffix] = generateTagPercentile();
  let glutenId;
  if (percentile < 35) glutenId = 'supplements-group-a'
  else if (percentile < 51) glutenId = 'supplements-group-b'
  else if (percentile < 81) glutenId = 'supplements-group-c'
  else if (percentile < 91) glutenId = 'supplements-group-d'
  else if (percentile <= 100) glutenId = 'group-e-91'

  // polygenic percentiles, like testosterone or bmd, have slightly different formats than non-polygenic ones
  const content = polygenic ? (
    <span><b>{percentile}%</b> of people have a genetic score equal to or lower than you.</span>
  ) : (
    <span>You are in the <b>{percentile}<sup>{suffix}</sup></b> percentile.</span>
  );
  // glutenContent returns line for glutenomics page.
  const glutenContent = showPercentile && glutenId ? <span> Click <a href={`https://www.glutenomics.com/${glutenId}`}>here</a> to see your personalized supplement and​ lifestyle recommendations</span> : null;
  return (<>
    {content}
    <br />
    {glutenContent}
  </>);
};

export default TagPercentile;


