import React from 'react';


export const IronRecommendation = () => {
  return (
    <div>
        <div className='title-margins'>
            <h2>Iron Recommendations</h2>
            <p>written by Dr. Megan Roche, M.D.</p>
        </div>
        <div className='title-break'>
            <span>Quick Checklist</span>
        </div>
        <div className="card">
            <ul style={{listStyleType: 'square'}} className="mb-0">
                <li>Consider requesting an iron panel and a complete blood count (CBC) from your primary care physician if you are concerned about low levels or deficiency. Iron levels within standard reference range may not be optimized for athletic performance.</li>
                <li>Review iron-rich and iron-fortified foods.</li>
                <li>If you are vegetarian or vegan, review common sources of non-heme iron.</li>
                <li>If you are deficient in iron or have abnormalities on the iron panel, consult with a primary care physician to discuss the possibility of supplementation.</li>
                <li className="mb-0">If you are supplementing with iron, review recommendations for avoiding interactions that may limit iron absorption.</li>
            </ul>
        </div>
      
        <div className="card">
            <p>Dietary iron is found in two different forms in food: heme and non-heme iron. Heme iron is found in animal products including meat, poultry, and fish, and is more readily absorbed than non-heme iron. Vegetarians and vegans may be at higher risk of iron deficiency because plant sources only provide non-heme iron.</p>
            <p className="mb-0">Food sources that are high in heme iron include: lean red meat, chicken, canned tuna, sardines, oysters, clams, and mussels. Food sources that are high in non-heme iron include: rice, wheat, oats, beans, leafy green vegetables, dark chocolate, and tofu. Many breakfast cereals and bread products are fortified with non-heme iron.</p>
        </div>
        <div className="card">
            <p>Consuming iron rich meals or iron supplements with foods rich in Vitamin C can help in iron absorption. Vitamin C is known to capture non-heme iron and store it in a form that can be more easily absorbed. Foods rich in Vitamin C include citrus fruits, fruit-based beverages, tomatoes, spinach, and broccoli.</p>
            <p className="mb-0">Other foods and supplements inhibit iron absorption. For example, the tannins in coffee and tea can inhibit non-heme iron absorption and the phosphoproteins in eggs can also inhibit non-heme iron absorption. Foods or supplements containing calcium, phosphorus, zinc, or magnesium can inhibit iron absorption because they compete for the same binding sites as iron. To optimize iron absorption, it can be helpful to avoid foods and supplements that limit iron absorption 2-3 hours prior to consumption of iron products.</p>
        </div>
        <div className="card">
            <p className="mb-0">Iron supplements are available as ferrous and ferric iron salts including ferrous sulfate, ferrous gluconate, ferrous fumarate, ferric citrate, and ferric fumarate. In general, ferrous iron is more soluble and more readily bioavailable than ferric iron. Multivitamin supplements, particularly multivitamin supplements designed for females may contain iron. Multivitamin supplements designed for males or for the senior population often do not contain iron. Gastrointestinal side effects may occur with iron supplementation. Please consult with your doctor to determine the best iron supplement and to discuss any side effects that may occur.</p>
        </div>
    </div>
  )
}

export default IronRecommendation;
