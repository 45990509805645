import React from 'react';
import { useVideo } from '../../utils/customHooks';

const vids = {
    'Depth Jump': 'https://www.youtube.com/embed/a3HZ16BNy1s?rel=0',
    'Tempo Squats': 'https://www.youtube.com/embed/bfJgSnWQW84?rel=0',
    'Multi-Directional Jumps': 'https://www.youtube.com/embed/QXLlfYkG2sU?rel=0',
    'Short Foot Single Leg Squats': 'https://www.youtube.com/embed/EE5-PXOGBgE?rel=0',
    "Barbell Front Squat": "https://www.youtube.com/embed/W3dSkq_8klw?rel=0",
    "Single Leg Squat Jumps": "https://www.youtube.com/embed/WYK-U4Ul6q8?rel=0"
};


const PatellarPrevention = () => {
    const vidList = useVideo(vids)

    return (
        <div>
            <div className='title-margins'>
                <h2>Patellar Tendinitis Prehabilitation Measures</h2>
            </div>
            <div className='title-break'>
                <span>Exercises</span>
            </div>
            { vidList }
            <div className='title-break'>
                <span>Quick Checklist</span>
            </div>
            <div className="card">
                <ul className="mb-0">
                    <li>Avoid rapid increases in training volume/intensity.</li>
                    <li>Focus on proper technique to avoid overloading the patellar tendon.</li>
                    <li>Perform strength and mobility exercises targeting the thigh muscles and glutes.</li>
                </ul>
            </div>
            <div className='title-break'>
                <span>Training Considerations</span>
            </div>
            <div className="card">
                <img src="/assets/imgs/results/content/ankleAthlete.jpg" alt="" height={250} className="overview-img" />
                <p>Avoid sudden/large increases in training volume and/or intensity, especially for plyometric exercises, jumping and heavy/deep squatting. These can place excessive load on the patellar tendon and contribute to tendinitis. It is best to gradually increase load through a progressive strength training program. For example, start with bodyweight mini-squats, then progress to deeper squats, then gradually add weight.</p>
                <p className="mb-0">Ensure you are using proper technique when training and doing strength work. To be sure you're using your body correctly, consider taking lessons or getting professional instructions when starting a new sport or using exercise equipment. Improper technique can lead to excessive force on the patellar tendon and trigger tendinitis. When squatting, do not let the knee push forward beyond your feet. Think about sitting back in a chair with the knees and feet in the same plane. A decline/slant board can be used for athletes with inadequate ankle mobility to prevent the knees from pushing too far forward. When jumping, do not land flat-footed as this transmits force to your tendons and bones. Instead, try to land softly and engage your muscles to absorb the force.</p>
            </div>
            <div className='title-break'>
                <span>Strength/Mobility Exercises</span>
            </div>
            <div className="card">
                <p>Strong and balanced thigh muscles and glutes are better able to handle the stresses that can cause patellar tendinitis. A proper strength and conditioning routine with progressive buildup of the load on the patellar tendon can help prevent injury. Eccentric quadriceps and hamstring exercises, are particularly helpful. To ensure balanced development of muscular strength, single-leg versions of exercises can be performed. For example, single-leg squats and single-leg Romanian deadlifts selectively target the quads and hamstrings respectively.</p>
                <p className="mb-0">Thigh and glute muscle mobility is important for preventing patellar tendinitis as tight/stiff muscles absorb force poorly and transfer the force to the tendons and bones. Self-massage and foam rolling are effective ways of increasing muscle mobility and flexibility, which helps with proper force absorption when jumping. For example, foam roll the quadriceps, hamstrings and glutes 3 times a week for ~10 minutes after exercise or in the evening.</p>
            </div>
        </div>
    );
};

export default PatellarPrevention;
