import React from 'react';
import GeneTable from '../../components/gene-table';

const ComtResults = (props) => {
    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for COMT</h2>

            </div>
            {/* <div className="card">
                <RiskGraph tag='comt' />
                <p className="mb-0">The bar graph shows your risk of concussion compared to the risk for an average person (1.00). Your risk is calculated based on your genotypes at the two DNA polymorphisms shown in the table below.</p>
            </div> */}
            <div className="card">
                <p>COMT has many associations with different neurological phenotypes. p-value, relative risk and beta are not shown as they depend on the specific neural phenotype.</p>
                <GeneTable tag='comt' />
                <p>COMT encodes Catechol-O-Methyltransferase, which is an enzyme involved in degrading catecholamines, including the neurotransmitters adrenaline, noradrenaline and dopamine.  </p>
                <p>rs4680 (Val158Met) is a well-studied SNP in the COMT gene. The G allele codes for the amino acid valine (Val); the (A) polymorphism changes the amino acid to a methionine (Met), reducing the activity of the enzyme by 4 fold. As a result, A allele carriers have more dopamine in their prefrontal cortex, which may be responsible for many of the neuropsychological associations listed below.</p>
                <p>rs4680(A) = Worrier. More exploratory, lower COMT enzymatic activity, therefore higher dopamine levels; lower pain threshold, enhanced vulnerability to stress, yet also more efficient at processing information under most conditions</p>
                <p>rs4680(G) = Warrior. Less exploratory, higher COMT enzymatic activity, therefore lower dopamine levels; higher pain threshold, better stress resiliency, albeit with a modest reduction in executive cognition performance under most conditions</p>
                <p>COMT and depression:</p>
                <ul>
                    <li>rs4680(A) carriers are more likely to develop depression after exposure to adverse events. </li>
                    <li>Increased depression scores during stressful periods (such as the peripartum period) for women carrying the rs4680(A) allele compared to the rs4680(G) allele.</li>
                    <li>The rs4680(A) allele is associated with increased activity in limbic areas and prefrontal cortex, but is also more likely to have a better response to antidepressant treatment, compared to the rs4680(G) allele. </li>
                </ul>
                <p>COMT and response to anti-depressants:</p>
                <ul>
                    <li>rs4680(A) carriers had better effect on the antidepressant efficacy of fluvoxamine and fluoxetine than rs4680(G/G) homozygotes.</li>
                    <li>rs4680(G/G) homozygous participants showed a worse response to treatment with paroxetine. </li>
                    <li>rs4680(A/A) genotype is associated with a significantly faster therapeutic effect of the antidepressant milnacipran.</li>
                </ul>
                <p>The rs4680(G) allele is associated with attention-deficit hyperactivity disorder and substance use disorder, as defined by DSM-IV criteria.</p>
            </div>
        </div>
    );
};

export default ComtResults;