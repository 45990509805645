import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import RiskFactorGraph from '../../components/risk-factor-graph';
import { getPercentileText2} from '../../utils/results';

export const POAGResults = (props) => {
    
    const { crm, result, userName, nameEndsWithS } = props;
    let testComponent;
    let adjusted = result['adjusted_result'];
    let percentile;
    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
       adjusted = result['adjusted_result'];
    }
    console.log("result  ", result )
    if (result['value'] !== null) {
        testComponent =
            <div>
                <div className='title-break'>
                    <span>Your Results</span>
                </div>
                <div className="card">
                <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have higher risk for primary open angle glaucoma. (Units for Beta are arbitrary.)</p>
                    <div>
                        {
                            adjusted ? (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-adjusted_POAG-histogram' />
                                    <p> You are in the <b>{getPercentileText2(percentile)} </b>. Your age has been factored in to your score to better predict your risk for glaucoma.</p>
                                    
                                </>
                            ) : (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-POAG-histogram' />
                                    <TagPercentile result={props.result} tag='POAG' />
                                </>
                            )
                        }

                    </div>

                </div>
                <div className='title-break'>
                    <span>How To Interpret Your Results</span>
                </div>
                <div className="card">
                <img src="/assets/imgs/results/content/POAG_chart.png" height={300} className="overview-img" alt="" />
                <p>You can look up your risk for primary open angle glaucoma using this figure. Find your percentile on the x-axis, then find your ancestry group. Your risk for primary open angle glaucoma is approximately your odds ratio (OR), shown on the y-axis.</p>
                <p>EUR (European), EAS (East Asian), CSA (Central South Asian), AFR (African)</p>
                <p>For example, if you are European in the 95th percentile (decile 10), then your risk for primary open angle glaucoma is about 9 times more than a European in the 5th percentile (decile 1).</p>
                </div>
                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">
                <p>This study developed a polygenic risk score for primary open angle glaucoma that includes 911,402 variants based on &gt;2.2 million individuals from the Global Biobank Meta-analysis Initiative, which brings together 23 biobanks developed in countries spanning different continents.</p>
                </div>

                {
  adjusted ? (
    (() => {
      const graphMax = .08 ;
      const data = [0.0293,  0.0447, .074];
      return (
        <div>
          <div className='title-break'>
            <span>Impact of Genetics and Age on Risk for Glaucoma</span>
          </div>
          <div className="card">
            <RiskFactorGraph graphMax={graphMax} data={data} />
            <p>The graph shows how risk for glaucoma is affected by genetics, age or both combined. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts glaucoma exactly and 0.00 means that there is no information. For glaucoma, genetics has a smaller effect than age, but combining them together has the most effect; overall, the combined score explains 7.4% of the variance in risk for glaucoma. </p>
          </div>
        </div>
      );
    })()
  ) : null
}
            </div>

        return (
            <div>
                <div className='title-margins'>
                    <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Glaucoma</h2>
                    <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/36777179/">Wang et al. (2023)</a>]</h5>
                </div>
                {testComponent}

            </div>
            
        );
    };
};


export default POAGResults;
