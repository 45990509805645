/*
  This file contains presentational utility functions that are used in React components
*/

// titleizes a string
export const titleizer = str => {
  if (str === "") return str;
  
  let sentArr = str.split(" ");
  let newSent = [];
  for(let i = 0; i < sentArr.length; i++) {
    //conditional prevents crashing in case of empty string, which occurs when one does str.split(" ") on something like " "
    if (sentArr[i] === "") continue;
    let wordStart = sentArr[i][0].toUpperCase();
    let wordEnd = sentArr[i].slice(1);
    let newWord = wordStart + wordEnd;
    newSent.push(newWord);
  };
  return newSent.join(" ");  
};

// formats address properly with capital letters
export const formatAddress = address => {
  let formattedAddress = Object.assign({}, address);
  Object.keys(formattedAddress).forEach(key => {
    if (key === 'zip') {
      formattedAddress[key] = formattedAddress[key].toUpperCase();
    } else if (key === 'apt') {
      formattedAddress[key] = formattedAddress[key];
    } else {
      formattedAddress[key] = titleizer(formattedAddress[key]);
    };
  });
  return formattedAddress;
};

// validates address
// ensures all of the required fields are present in the shipping object
export const validateAddressInfo = address => {
  // an empty object is a truthy value so it alone cannot be the basis of the conditional
  if (Object.keys(address).length === 0) return false;

  for (let field in address) {
    if (address[field] === "" && field !== "apt") return false;
  };
  return true;
};

// ensures that numKits is a valid number
export const numKitsValidator = numKits => {
  if (typeof numKits !== 'string' || numKits === "") return false;
  let intKits = parseInt(numKits);
  // NaN can happen for cases like "--1"
  if (isNaN(intKits)) return false;
  if (intKits <= 0 || intKits > 100) return false;
  return true;
};

// uses regex to validate emails
export const validateEmail = email => {
  let regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexPattern.test(email);
};
