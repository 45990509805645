import React from 'react';

class IbuprofenIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Ibuprofen Sensitivity</h2>
        <p>written by Dr. Megan Roche, M.D.</p>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
          <img src="/assets/imgs/results/content/ibuprofen.png" height={200} className="overview-img" alt="" />  
          <p>Ibuprofen is an over-the-counter and prescription non-steroidal anti-inflammatory drug (NSAID) that is often used by athletes for pain management and recovery. Ibuprofen is also commonly used for treating fever, inflammation, migraines, and menstrual cramps. A typical dose is 400 mg every four hours. The maximum recommended amount of ibuprofen for adults is 800 milligrams a dose or 3200 milligrams a day.</p>
          <p>Ibuprofen relieves pain and reduces inflammation by inhibiting the action of the cyclooxygenase enzymes COX-1 and COX-2. As a result of COX inhibition, ibuprofen keeps the body from producing proinflammatory prostanoids and reduces the stimulation of sensory neurons involved in pain sensation (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3191627/">Bushra and Aslam, 2010</a>).</p>
        </div>
        <div className="card">
          <p>Ibuprofen is relatively safe given its short half-life, wide therapeutic window, and lack of prolonged retention in the body (<a href="https://www.ncbi.nlm.nih.gov/pubmed/25502615">Mazaleuskaya et al., 2015</a>). Common side effects of ibuprofen include skin rash, dizziness, heartburn, and nausea. Less common side effects include gastrointestinal ulcers, ear ringing, vomiting, and decreased appetite. More severe side effects are rare, but may include heart failure, kidney impairment, and asthma exacerbation. </p>
          <p>Peak ibuprofen concentration occurs 1-2 hours after oral intake. The body metabolizes ibuprofen primarily in the liver through the Cytochrome P450 (CYP) enzymes 2C8 and 2C9. Genetic variations in CYP2C8 and CYP2C9 are associated with reduced ibuprofen clearance. Individuals with genetic variation in CYP2C8 and/or CYP2C9 experience between 35% and ten-fold reduction in ibuprofen clearance (<a href="https://www.ncbi.nlm.nih.gov/pubmed/15289789">Garcia-Martin et al., 2004</a>). A decrease in ibuprofen clearance causes a higher peak concentration and longer duration for ibuprofen in your bloodstream (<a href="https://www.ncbi.nlm.nih.gov/pubmed/25502615">Mazaleuskaya et al., 2015</a>).	</p>
        </div>
        <div className="card">
          <p>Up to 20% of individuals have CYP2C8 and CYP2C9 genetic variants associated with increased ibuprofen exposure (<a href="https://www.ncbi.nlm.nih.gov/pubmed/30562214">Zajic et al., 2019</a>). Given ibuprofen’s wide therapeutic window, individuals with genetic variants in CYP2C8 and CYP2C9 may be able to avoid adverse events or drug-drug interactions following intake of ibuprofen, but it is still prudent to be forewarned (<a href="https://www.ncbi.nlm.nih.gov/pubmed/25502615">Mazaleuskaya et al., 2015</a>).  <a href="https://www.ncbi.nlm.nih.gov/pubmed/30562214">Zajic et al.</a> found that individuals with genetic variants in CYP2C8 and CYP2C9 are more likely to take less than the suggested dose of ibuprofen for everyday pain, indicating they may have learned through personal experience that ibuprofen is more effective in them than in others, and have reduced their doses accordingly. More clinical research is needed to better understand the impact of genetic variants on ibuprofen side effects and pain relief efficacy.</p>
        </div>
        <div className="card">
          <p>In addition, research indicates that the combined use of ibuprofen and selective serotonin reuptake inhibitors (SSRIs), which are often used for depression management, can increase the risk for gastrointestinal side effects (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1884264/">de Jong et al., 2003</a>). Drug-drug interactions can also occur when ibuprofen is taken with aspirin, alcohol, warfarin, phenytoin, and some antihypertensives (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4508078/">Moore, Pollack, and Butkerait, 2015</a>; <a href="https://www.ncbi.nlm.nih.gov/pubmed/25502615">Mazaleuskaya et al., 2015</a>). Athletes who are at genetic risk for high ibuprofen levels and are taking a drug listed above should consider switching to Tylenol or consulting with a physician.</p>
          <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/28679502">Lipman et al.</a> found that ibuprofen use during a 50-mile race was associated with an 18% increased risk of acute kidney injury (AKI). We predict that endurance athletes who are at genetic risk for high ibuprofen levels may be more susceptible to AKI.</p>
        </div>
        </div>
    )
  }
}

export default IbuprofenIntro;
