import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer';
import * as Actions from '../../actions';
import Alerts from '../../components/alerts';
import { useCustomDispatch } from '../../utils/customHooks';
import { useSelector } from 'react-redux';

const ConfirmEmail3Page = ({ location, match }) => {

  const attemptConfirmEmailDispatch = useCustomDispatch(Actions.confirmEmailThunk);
  const [loginLink, setLoginLink] = useState("/login")
  const { alerts } = useSelector((state) => ({
    alerts: state.alerts,
    id: state.id,
    orderType: state.orderType, //is this right?
  }));

  useEffect(() => {
    const token = match.params.token;
    attemptConfirmEmailDispatch(token);
  }, []);
 
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const orderID = params.get('orderID');
    const orderType = params.get('orderType');
    // adjust link if success. OrderID and uploadType are passed in from the URL. 
    if (alerts.hasOwnProperty("confirm-email") && alerts['confirm-email'].hasOwnProperty("success") && loginLink === "/login") setLoginLink(`/login?next=upload&orderID=${orderID}&uploadType=${orderType}`);
  }, [alerts]);
  return (
    <div className="bg self-align-stretch">
      <div className="d-flex self-align-stretch justify-content-center align-items-center h-100">
        <div className="auth-form-parent">
          <div className="auth-form-container">
            <div className="logreg-form">
              <div className="auth-form-header">
                <img alt="" src="/assets/imgs/axgen-logo.png" className="logo-img" />
                <h1>AxGen</h1>
              </div>
                <Alerts tag='confirm-email' level="success" />
                <Alerts tag='confirm-email' level="error" />
            </div>
          </div>
          <div className="auth-form-bottom">
            <p className="form-links mb-0"><Link to={loginLink} className="btn-link">Click here</Link> to log in to your account and upload your genome file.</p>
          </div>
        </div>
      </div>
      <Footer forceWhite={true} />
    </div>
  );
};

export default ConfirmEmail3Page;
