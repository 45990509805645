import { ACTIVATE_KIT, SET_LOGIN_USER_ID } from '../actions';

const defaultState = {
  userID: null,
  userRole: null,
  userName: null,
  userEmail: null,
  userDemographics: {},
  startPage: null,
  userKitStep: null,
  myUploadID: null,
  expectedByDate: null
};

const logout = () => {
  localStorage.removeItem('user-id');
  localStorage.clear();
  // TODO: Replace with a react-router redirect
  window.location = '/login';
};

const sessionReducer = (state = defaultState, action) => {
  const payload = action.payload || {};
  const { userID, userRole, userName, userEmail, userDemographics, startPage, kitStatus, myUploadID, expectedByDate } = ('data' in payload) ? payload.data : payload;
  const newState = Object.assign({}, state);
  if ((payload.response || {}).status === 401 && !(['LOGIN_REJECTED', 'REGISTER_REJECTED'].includes(action.type))) {
    logout();
    return defaultState;
  }

  switch (action.type) {
    // ensures userKitStep is always up to date
    case ACTIVATE_KIT:
      let newKitStep = payload.data.kitStatus;
      let newExpectedByDate = payload.data.expectedByDate;
      newState.userKitStep = newKitStep;
      newState.expectedByDate = newExpectedByDate;
      if (newKitStep !== parseInt(localStorage.getItem('user-kit-step'))) window.localStorage.setItem('user-kit-step', newKitStep);
      if (newExpectedByDate !== null && newExpectedByDate !== localStorage.getItem('expected-by-date')) window.localStorage.setItem('expected-by-date', newExpectedByDate);
      return newState;
    case 'RECEIVE_KIT_FULFILLED':
    case 'BUY_KIT_FULFILLED':
      newState.userKitStep = 0;
      newState.expectedByDate = null;
      if (newState.userKitStep !== parseInt(localStorage.getItem('user-kit-step'))) window.localStorage.setItem('user-kit-step', newState.userKitStep);
      return newState;
    case 'CHECK_SESSION_FULFILLED':
    case 'LOGIN_FULFILLED':
      window.localStorage.setItem('user-id', payload.data.userID);
      window.localStorage.setItem('user-role', payload.data.userRole);
      window.localStorage.setItem('user-name', payload.data.userName);
      window.localStorage.setItem('user-email', payload.data.userEmail);
      window.localStorage.setItem('user-demographics', JSON.stringify(payload.data.userDemographics));
      window.localStorage.setItem('start-page', payload.data.startPage);
      // this check needs to be here because you cannot set a null value in localStorage
      let parsedMyUploadID = null;
      let parsedExpectedByDate = null;
      let parsedKitStatus = null;
      if (payload.data.myUploadID !== null) {
        parsedMyUploadID = payload.data.myUploadID;
        window.localStorage.setItem('myUploadID', parsedMyUploadID);
      };
      if (payload.data.expectedByDate !== null) {
        parsedExpectedByDate = payload.data.expectedByDate;
        window.localStorage.setItem('expected-by-date', parsedExpectedByDate);
      };
      if (payload.data.kitStatus !== null) {
        parsedKitStatus = payload.data.kitStatus;
        window.localStorage.setItem('user-kit-step', parsedKitStatus);
      };
      return { userID, userRole, userName, userEmail, userDemographics, startPage, userKitStep: parsedKitStatus, myUploadID: parsedMyUploadID, expectedByDate: parsedExpectedByDate };
    case 'CHECK_SESSION_REJECTED':
    case 'LOGOUT_FULFILLED':
      logout();
      return defaultState;
    case SET_LOGIN_USER_ID:
      return { userID, userRole, userName, userEmail, userDemographics, startPage, kitStatus, userKitStep: kitStatus, myUploadID, expectedByDate };
    case 'SAVE_ACCOUNT_SETTINGS_FULFILLED':
      return Object.assign({}, state, { userName, userEmail, userDemographics, startPage} );
    default:
      return state;
  };
};

export default sessionReducer;
