import React from 'react';

class VitCIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Vitamin C</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/vitC.jpg" height={200}/>  
        <p>Vitamin C (L-ascorbic acid) is a water-soluble vitamin that is naturally present in many fruits and vegetables, added to other foods, and available as a dietary supplement. Vitamin C has several important functions including the maintenance and protection of healthy cells, skin, bones and cartilage. Additionally, vitamin C is required for the synthesis of collagen, an essential component of connective tissue and wound healing. Vitamin C therefore is vital to the body’s immune system and ability to heal itself. The full scope of vitamin C’s benefits to the body is still not fully understood. Vitamin C may help prevent or delay the development of certain cancers, cardiovascular disease, and other diseases where oxidative stress plays a causal role. Vitamin C may also limit the formation of carcinogens and possibly limit oxidative damage leading to cancer.</p>
        <p>Unlike most mammals, humans are not able to synthesize vitamin C naturally, therefore it is an essential dietary component. Luckily, vitamin C is abundant in a wide variety of fruits and vegetables found all over the world. Sources of vitamin C include citrus fruit (oranges, grapefruits, etc.), peppers, strawberries, broccoli, brussels sprouts, and potatoes. </p>
        </div>
        <div className='title-break'>
          <span>Recommended Daily Intake</span>
        </div>
        <div className="card">
        <p>It is recommended by the National Institute of Health that healthy men over 18 years old should intake 90 mg of vitamin C daily, and healthy women over 18 need 70 mg. Unlike many other vitamins, vitamin C cannot be stored in the body, so it is important to include enough in your diet every day. This amount of vitamin C is easily met by consuming a serving of fruits and vegetables daily.</p>
        <p>If you are a smoker, it is recommended that you consume 35mg more vitamin C per day than the recommended amount for non-smokers. Smokers typically have lower plasma & vitamin C baseline levels than nonsmokers, due in part to increased oxidative stress.</p>
        </div>
        <div className='title-break'>
          <span>Symptoms of Vitamin C Deficiency</span>
        </div>
        <div className="card">
        <p>A deficiency or lack of vitamin C in the diet over several weeks or months will eventually lead to scurvy. Because vitamin C is so readily available in the developed world, scurvy is now a relatively rare disease in the US and Europe, occurring most commonly in people with mental disorders, very unusual eating habits, or suffering from alcoholism.</p>
        <p>Early signs of vitamin C deficiency include muscle weakness, fatigue, and very sore arms and legs. Without treatment or intervention, symptoms will eventually worsen, leading to decreased red blood cells, gum disease, hair loss, and rashes on the skin.</p>
        </div>
      </div>
    )
  }
}

export default VitCIntro;
