import React from 'react';

class PUFAIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Omege-3 Polyunsaturated Fatty Acids</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/PUFA.jpeg" height={200}/>   
        <p>Omega-3 polyunsaturated fatty acids (PUFAs) are nutrients you get from food (or supplements) that have many beneficial effects, such as reducing triglycerides, improving heart health and alleviating chronic inflammation. There are four types of omega-3 PUFA: ALA, EPA, DPA and DHA. ALA is found in plant sources such as nuts and seeds and the other three are primarily found in certain fish. </p>

        <p>Many studies show that eating fatty fish and other types of seafood as part of a healthy eating pattern helps keep your heart healthy. Getting more omega-3 PUFAs from foods or dietary supplements lowers triglyceride levels, for example. The American Heart Association recommends eating one to two servings of seafood per week to reduce your risk of some heart problems, especially if you consume the seafood in place of less healthy foods. </p>
        </div>
        <div className='title-break'>
          <span>Benefits of omega-3 PUFAs</span>
        </div>
        <div className="card">
        <p>The benetifs of omega-3 PUFAs include: </p>
        <p>Triglycerides and HDL. Omega-3 PUFAs can increase levels of "good" HDL and cause a large reduction (15–30%) in "bad" triglyceride levels. Higher levels of HDL and lower levels of triglycerides are beneficial as they reduce risk for heart disease and stroke.</p>
        <p>Chronic Inflammation. Chronic inflammation is inflammation that persists, even without an infection or injury. Chronic inflammation contributes to many chronic diseases, such as heart disease, diabetes and cancer. Omega-3 PUFAs reduce the production of hormones that cause chronic inflammation, such as inflammatory eicosanoids and cytokines. A diet high in omega-3 PUFAs lowers chronic inflammation.</p>
        <p>Rheumatoid Arthritis (RA). RA causes chronic pain, swelling, stiffness, and loss of function in the joints. Omega-3 supplements may help manage RA when taken together with standard RA medications and other treatments. Omega-3 supplements also boost the effectiveness of anti-inflammatory drugs.</p>
        </div>
        <div className='title-break'>
          <span>Sources of omega-3 PUFAs</span>
        </div>
        <div className="card">
        <p>You can get adequate amounts of omega-3 PUFAs from a variety of foods, such as:
        <ul style={{listStyleType: 'square'}}>
                  <li>Fish and other seafood (such as salmon, tuna, herring, and sardines)</li>
                  <li>Nuts and seeds (such as flaxseed, chia seeds, and walnuts)</li>
                  <li>Plant oils (such as flaxseed oil, soybean oil, and canola oil)</li>
                  <li>Fortified foods (such as certain brands of eggs, yogurt, juices, milk and soy beverages).</li>
        </ul>
        </p>
        </div>
        <div className='title-break'>
          <span>Most people have sufficient levels of omega-3 PUFAs</span>
        </div>
        <div className="card">
        <p>Most people in the United States get enough omega-3 PUFAs from the foods they eat. Recommended amounts of EPA and DHA have not been established. A deficiency of omega-3s can cause rough, scaly skin and a red, swollen, itchy rash. </p>
        </div>
      </div>
    )
  }
}

export default PUFAIntro;
