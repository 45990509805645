import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';

export const CaResults = (props) => {
    const { crm, result } = props;
    let testComponent;
    if (result['value'] !== null) {    
        let prefixedGeneticScore = result['value'] > 0 ? `+${result['value'].toFixed(2)}` : `${result['value'].toFixed(2)}`;
        
      testComponent =
      <div>
        <div className="card">
        <p>The AxGen test for calcium uses a genetic algorithm with 208 genetic markers developed by <a href="https://pubmed.ncbi.nlm.nih.gov/33887147/">Young et al., 2021</a>. Each of these genetic markers shows an independent association with serum total calcium concentration that is genome-wide significant. The genetic algorithm adds each of the genetic markers according to their effect size, and reports an overall score. Other genetic testing companies currently use only one or two genetic markers. AxGen's test includes these top two markers but adds hundreds more, which significantly improves the predictive power of the test. </p>
        </div>
        <div className="card">
          <BetaHistogram crm={crm} result={result} crmKey='results-Ca-histogram' />
          <p>To use this graph, find the red line that shows your genetic score.  The blue histogram shows the number of people and their calcium genetic scores. The bin containing your score tells you how many other people have a score like yours.  If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly lower level of calcium.  </p>
          <p>Based solely on your genetics, your predicted calcium score is <strong>{prefixedGeneticScore}</strong> mmol/L, which is the amount that your individual level is shifted from the median value (about 2.4 mmol/L).</p> 
          <p><TagPercentile result={props.result} tag='Ca'/></p>
        </div>
        <div className='title-break'>
          <span>The genetic test has a <b>small</b> effect on calcium</span>
        </div>
        <div className="card">
        <p>First, the genetic markers explain only 5.8% of the variance in calcium levels [<a href="https://pubmed.ncbi.nlm.nih.gov/33887147/">Young et al., 2021</a>]. This means that your calcium levels are mostly set by your diet and activity, rather than your genetics.  </p>
        <p>Second, the left edge of the blue histogram has calcium scores around -0.1 mmol/L. Total calcium levels are typically 2.2 to 2.6 mmol/L, so a change of -0.1 nmol/L (the worst genetic result) does not change your calcium levels by much.  </p>
        </div>
      </div>

      return (
      <div>
        <div className='title-margins'>
        <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Calcium Levels</h2>
        <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/33887147/">Young et al., 2021</a>]</h5>
        </div>
        { testComponent }

      </div>
    );
    };
};


export default CaResults;
