import React from 'react';
import GeneTable from '../../components/gene-table';


const AMDResults = (props) => {

    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Age-Related Macular Degeneration</h2>
            </div>
            <div className="card">
                <p>Your genotype for age-related macular degeneration (AMD) is shown in the table below. Table definitions can be shown by hovering your mouse over the column headings.</p>
                <GeneTable tag='amd' cols={['gene', 'alleleFreq']} />
            </div>
            <div className='title-break'>
                <span>Comments</span>
            </div>
            <div className="card">
                <p>rs800292 is in the <i>CFH</i> gene, which encodes complement factor H that regulates the complement system in the immune response.</p>
                <p>For rs800292, each G allele increases your risk 1.5 fold for early AMD, 2.25 fold for intermediate AMD, and 5.5 fold for late, vision threatening AMD. </p>
                <p>rs10490924 is in the <i>age-related maculopathy susceptibility 2 (ARMS2)</i> gene, which encodes a protein whose function is unknown and is expressed primarily in the placenta and in the specialized light-sensing tissue in the back of the eye (the retina).</p>
                <p>For rs10490924, individuals with a genotype of (T/G) have a 2.6 fold increased risk of ARM and homozygous (T/T) individuals have a 8.2 fold risk.</p>
                <p>A postive result for either the rs800292 or rs10490924 mutation should be verified by clinical sequencing.</p>
                <p>Other genetic testing companies report the genotype of rs11200638 in the <i>HTRA1</i> gene as well as rs10490924 in <i>ARMS2</i>. However, rs11200638 is tightly linked to rs10490924, meaning that the two SNPs are redundant. Thus, rs11200638 provides no additional information as AxGen already reports the genotype for rs10490924 in <i>ARMS2</i>.</p>
            </div>
        </div>
    );
};

export default AMDResults;