const defaultState = {
  consultant: "",
  orderType: "",
  status: "",
  startTime: "",
  endTime: "",
  strengthIdx: 0,
};

// reducer handles the ordering and getting of consultation
// LOAD_CONSULTATION_FULFILLED exists to get preexisting consult data 
// COMPLETE_CONSULT_FULFILLED exists to notify the frontend that a consult is completed
const consultationReducer = (state = defaultState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case 'ORDER_CONSULTATION_FULFILLED':
      const newState = Object.assign({}, state);
      newState.consultant = action.payload.data.consultant;
      newState.status = action.payload.data.status;
      newState.orderType = action.payload.data.order_type;
      return newState;
    case 'LOAD_CONSULT_FULFILLED':
      const nextState = Object.assign({}, state);
      nextState.consultant = action.payload.data.consultant;
      nextState.status = action.payload.data.status;
      nextState.orderType = action.payload.data.order_type;
      nextState.strengthIdx = action.payload.data.strength_idx;
      if (action.payload.data.start_time !== null && action.payload.data.start_time !== null) {
        nextState.startTime = action.payload.data.start_time;
        nextState.endTime = action.payload.data.end_time;
      };
      return nextState;
    case 'COMPLETE_CONSULT_FULFILLED':
      const completedState = Object.assign({}, state);
      completedState.status = action.payload.data.status;
      return completedState;
    default:
      return state;
  }
};

export default consultationReducer;