import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Cleave from 'cleave.js/react';
import * as Actions from '../actions';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import Alerts from '../components/alerts';
import { useCustomDispatch } from '../utils/customHooks';

export const ActivatePage = () => {

  // global state
  const { alerts, orders } = useSelector(state => ({ 
    alerts: state.alerts,
    orders: state.orders,
  }));

  // local states
  const [barcode, setBarcode] = useState("");
  const [kitType, setKitType] = useState("");
  const [disabled, disableSubmit] = useState(false);

  // dispatches
  const activateKitDispatch = useCustomDispatch(Actions.activateKitThunk);
  const loadKitDispatch = useCustomDispatch(Actions.loadKitThunk);
  const clearKitStatusDispatch = useCustomDispatch(Actions.clearKitStatusThunk);

  // helper functions
  // validates barcode by length and type. for each type of barcode, unique validator functions are called
  // Stuart modified. NEED TO REVISE TO ACCEPT REMAR OR INFINITY
  const _validateBarcode = (code) => {
    if (kitType === "akesogen") {
      return _validateAkeso(code);
    };
    return false;
  };

  // checks if the first characters are AX, if that is not the case returns false
  // then iterates through the rest of the code to check for non-numerial characters
  const _validateAkeso = (code) => {
    // make sure that code is entered.
    if (!code) {
      return false;
    }
    const codeArr = code.split(''); 
    // check for 14 digits.
    // TODO: change every to some so that it stops at first error.
    let isAllNumbers = false;
    isAllNumbers = codeArr.length === 14 && codeArr.every((c) => Number.isInteger(parseInt(c)));
    if (isAllNumbers) {
      return true;
      // check for AX... format
    }
    isAllNumbers = codeArr.length === 9 && codeArr.slice(0,2).join('') === 'AX' && codeArr.slice(2).every((c) => Number.isInteger(parseInt(c)))
    if (isAllNumbers) {
      return true;
    }
    return false;
  };

  // determines the kit type based on the code in the state
  // rejects an empty barcode with an early return
  const _determineKitType = () => {
    if (barcode === "") {
      return;
    };

    if (barcode === 'AX' || barcode.includes("AX") || barcode.includes("5")) {
      setKitType('akesogen')
    };
  };

  // sets the state of the component based on what a user types
  const _handleChange = (e) => {
    e.preventDefault();
    setBarcode(e.target.value);
  };

  // handles the submitting of the form
  // validates the barcode format before submission
  const submitForm = (e) => {
    e.preventDefault();
    Actions.clearAlerts();
    disableSubmit(true);
    const validated = _validateBarcode(barcode);
    if (!validated) {
      Actions.addAlert('activate-kit', 'error', 'Invalid barcode. Barcode must have the format AX followed by 7 digits or a 14 digit number');
      return;
    };
    activateKitDispatch(barcode)
  };

  // fires on mount
  // autofills the barcode state if one exists
  useEffect(() => {
    clearKitStatusDispatch(); // clear on mount
    loadKitDispatch('kit');
  }, []);

  // autofills the barcode state if there is a barcode
  useEffect(() => {
    const { myKit } = orders;
    if (Object.keys(myKit).length !== 0) {
      setBarcode(myKit.kitBarcode);
    };
  }, [orders]);

  // determines kit type if there is a barcode
  // kit type enables the proper validation to happen for barcode
  // written dynamically so it can switch types if necessary
  useEffect(() => {
    if (barcode) {
      _determineKitType();
    };
  }, [barcode]);

  // un-disables the submit if there are any alerts, allowing for re-submission in case of errors
  useEffect(() => {
    if (Object.keys(alerts).length !== 0) {
      disableSubmit(false);
    };
  }, [alerts]);

  // presentational elements
  // defines a behavior if there is 'success'
  const success = ((alerts['activate-kit'] || {})['success'] || []).length > 0;
  
  // defines the cleave component, which formats the input
  // the delimiterLazyShow renders the next part of text only if it is typed
  // blocks formats the number of characters for each block
  const barcodeInput = 
    <Cleave
      type="text"
      placeholder="barcode"
      className="form-control"
      required
      value={barcode}
      options={{
        delimiter: '  ',
        blocks: [14],
        delimiterLazyShow: true,
        uppercase: true
        }}
      onChange={(e) => _handleChange(e)}
    />

  // ensures the form stays in column orientation with md and small devices
  const colClass = window.innerWidth < 992 ? "col-12" : "col";

  return (
    <div>
      <Navbar />
      <div className="container mt-4">
        <div className="row mx-0">
          <div className={`${colClass} px-0 align-self-center`}>
            <h2 className={ window.innerWidth < 768 ? 'text-center' : null }>Activate Kit</h2>
            <form
              className={classNames("card form py-3", { "d-none": success })}
            >
              <p className="mb-2">You can activate your kit here. If the field below is autofilled with a barcode already, please check to ensure that the barcode shown matches with that of your kit. If not, please make changes.</p>
              <p className="mb-2">Your barcode can be found on a sticker on the end of your DNA kit. The barcode is either AX followed by 7 digits or a number with 14 digits (e.g. 51820183855216)).</p>
              <div className="mb-2">
                <strong>Barcode</strong>
              </div>
              { barcodeInput }
              <input
                type="submit"
                className="form-control btn btn-axgen mb-0"
                value="ACTIVATE KIT"
                disabled={disabled}
                onClick={(e) => submitForm(e) }
              />
            </form>
            <Alerts tag='activate-kit' message="Failed to activate kit." />
            <Alerts tag='activate-kit' level="success" />
          </div>
          <div className="form-img-container align-self-center">
              <img src="/assets/imgs/axgen-kit2.png" height={350} className="form-img-wrap" alt=""/>
          </div>
        </div>
      </div>
      <br/>
      <Footer />
    </div>
  );
};


export default ActivatePage;
