import React from 'react';
import MUIDataTable from "mui-datatables";
import MUIMobileTable from '../components/mui_theme_overrides';


// made the search query case insensitive for easy searching
export const customSearch = (searchQuery, currentRow, columns) => {
  return currentRow.reduce((agg, row) => {
    if (agg) return agg;
    let target = row;

    while (typeof target === 'object') {
      let children = target.props.children;
      if (Array.isArray(children)) {
        target = children[0];
      } else {
        target = target.props.children;
      }
    }

    if (typeof target === 'undefined') {

      return false; // Return false when target is undefined
    }

    // Make sure returnTarget is a string before calling .includes()
    let returnTarget = target && target.toString().toLowerCase();

    if (returnTarget) {
      let caseInsensQuery = searchQuery.toLowerCase();
      return returnTarget.includes(caseInsensQuery);
    }

    return false; // Return false when returnTarget is undefined or falsy
  }, false);
};


// given data, columns, and a title, transforms it into a mui table
// optionally you can pass in custom configs
// selectableRows: 'none' removes the ability to select rows and press the delete button, which at the moment lacks actual functionality 
export const tableGenerator = (data, cols, title, width, customOpts=null) => {
  const options = !customOpts ? {
    customSearch: customSearch,
    sort: true,
    filter: false,
    print: false,
    download: false,
    selectableRows: 'none',
    responsive: 'standard',
    tableBodyMaxHeight: "500px"
  } : customOpts;

  if (width > 767) {
    return (
      <MUIDataTable title={title} data={data} columns={cols} options={options} className="mb-5"/>
    );
  } else {
    return (
      <MUIMobileTable title={title} data={data} columns={cols} options={options} className="mb-5"/>
    );
  };
};

// boilerplate function used for sorting, just pass it an array and callback
const quikSort = (arr, cb) => {
  if (arr.length <= 1) return arr;

  const dupedArr = arr.slice(); //duplicate array to avoid mutation
  // NOTE: you can either use dupedArr[0] or dupedArr.shift() here to get pivot. if you use the former, you must start loop at index 1
  let pivot = dupedArr.shift();
  const left = [];
  const right = [];
  dupedArr.forEach(el => {
    if (cb(pivot, el) === 1) {
      left.push(el);
    } else {
      right.push(el);
    };
  });
  return quikSort(left, cb).concat(quikSort([pivot], cb)).concat(quikSort(right, cb));
};

// comparator used to compare orders by updatedAt date
const compareOrdersByDateComparator = (el1, el2) => {
  let date1 = new Date(el1.updatedAt);
  let date2 = new Date(el2.updatedAt);
  
  if (date1 > date2) {
    return -1;
  } else if (date1 < date2) {
    return 1;
  } else {
    return 0;
  };
};
// function memoizes the comparator and quikSort into a single function
export const sortOrdersByDate = (orders) => quikSort(orders, compareOrdersByDateComparator);

// comparator used to compare consults by createdAt date
const consultComparator = (el1, el2) => {
  let date1 = new Date(el1.createdAt);
  let date2 = new Date(el2.createdAt);
  
  if (date1 > date2) {
    return -1;
  } else if (date1 < date2) {
    return 1;
  } else {
    return 0;
  };
};
// function memoizes the comparator and quikSort into a single function
export const sortConsultsByDate = (consults) => quikSort(consults, consultComparator);


// gets the options for the All Orders table in admin dash, which differ from those of other tables
// NOTE: this config includes custom sorting
// NOTE: localCompare works slightly differently depending on your browser
export const getAllOrdersConfigs = () => {
  return {
    download: false,
    filter: false,
    print: false, 
    selectableRows: 'none',
    responsive: 'standard',
    tableBodyMaxHeight: "500px",
    customSearch: customSearch,
    customSort: (data, colIndex, order) => {
      switch(colIndex) {
        // cols with values of type integer and no special chars (days since last updated, order type)
        case 6:
        case 12:
          return data.sort((a, b) => {
            let aScore = integerSortingHelper(a.data[colIndex]);
            let bScore = integerSortingHelper(b.data[colIndex]);
            return (order === 'asc') ? aScore - bScore : bScore - aScore;
          });
        // results available col
        case 13:
          return data.sort((a, b) => {
            let aScore = a.data[colIndex] === '-' ? -1 : a.data[colIndex];
            let bScore = b.data[colIndex] === '-' ? -1 : b.data[colIndex];
            return (order === 'asc') ? aScore - bScore : bScore - aScore;
          });
        // cols with values of type string and possibly special characters
        default:
          return data.sort((a, b) => (order === 'asc') ? a.data[colIndex].localeCompare(b.data[colIndex]) : 
          b.data[colIndex].localeCompare(a.data[colIndex]));
      };
    }
  }
};

// handles custom sorting by orderType or days since list update
const integerSortingHelper = (value) => {
  switch(value) {
    case "kit":
      return -1;
    case "23andme":
      return -3;
    case "ancestry":
      return -2;
    case "*":
      return 0;
    // default will occur with days since last update values
    default:
      return value;
  };
};
