import React from 'react';


const ConcussionPrevention = () => {
    return (
        <div>
            <div className='title-margins'>
                <h2>Concussion Prehabilitation Measures</h2>
            </div>
            <div className='title-break'>
                <span>Quick Checklist</span>
            </div>
            <div className="card">
                <ul className="mb-0">
                    <li>Review specific evidence on protective gear relevant to your sport including helmets and mouthguards</li>
                    <li>If you are a cyclist, consider a helmet with rotational force-reducing technology such as MIPS or WaveCel</li>
                    <li>If you are participating in a contact sport, consider a custom-made mouthguard with higher average thickness</li>
                    <li>Work with a specialist to make sure gear fits and functions properly (i.e. adjust chin straps of helmets to ensure proper fit; replace helmets that have been through a crash or have sustained damage)</li>
                    <li>Perform neck-strengthening exercises to improve the neck’s strength, size, and posture to help reduce concussion risk</li>
                    <li>Consider reviewing results with your primary care physician and consider a more cautious plan for return to play in the event of a concussion</li>
                </ul>
            </div>
            <div className='title-break'>
                <span>Protective Gear</span>
            </div>
            <div className="card">
                <img src="/assets/imgs/results/content/helmet.jpeg" alt="" height={200} className="overview-img" />
                <p>We’ve seen a growth in research and technology that aims to develop protective gear for head injury and concussion. It’s also important to work with a specialist to ensure proper fit and to replace any damaged gear.</p>
                <ul className="mb-0">
                    <li>
                        <p className="mb-2"><u>Helmets</u>: Helmets are known to reduce head and face injury, skull fractures, and brain bleeding; however, data highlighting their direct involvement in preventing concussion are more limited. Although there are no helmets that directly prevent concussion, certain helmet technology has been shown to be superior.</p>
                        <p>In cycling, MIPS and WaveCel helmet technologies work to limit rotational forces and redirect energy that occurs at the moment of impact to help reduce severity of head injury. In football, researchers and companies have used computational models to help design newer helmets. Traditional football helmets relied on a hard outer shell with foam padding to form the inner helmet. Newer technology uses a more deformable outer shell with inner layers of columnar structures that help absorb impact forces. Staying educated on up-to-date research can help you stay protected.</p>
                    </li>
                    <li>
                        <p className="mb-2"><u>Mouthguards</u>: Mouthguards can reduce concussion risk by absorbing shock, stabilizing the head and neck, and limiting movement from a direct blow to the jaw (<a href="http://www.digitaljournal.com/pr/1888375">Academy of General Dentistry, 2014</a>). Several sports require mouthguards for participation (i.e. football, lacrosse, field hockey), whereas  they are optional in other sports . Athletes at higher genetic risk for concussion may want to consider wearing a mouthguard for participation in sports such as soccer or basketball.</p>
                        <p className="mb-0">Preliminary research indicates that athletes who wear custom-fit mouthguards with increased thickness have reduced rates of concussion compared to athletes who wear over the counter mouthguards (<a href="http://www.digitaljournal.com/pr/1888375">Academy of General Dentistry, 2014</a>).</p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ConcussionPrevention;
