import React from 'react';


const ConcussionIntro = () => {
    return (
        <div>
            <div className='title-margins'>
                <h2>Introduction to Concussion</h2>
                <p>written by Dr. Megan Roche, M.D.</p>
            </div>
            <div className='title-break'>
                <span>High Risk Sports</span>
            </div>
            <div className='high-risk-list my-3 py-1'>
                <div className="result-sport-figure">
                    <img src='/assets/imgs/results/athletes/quarterback.png' alt="" />
                    <span>Football</span>
                </div>
                <div className="result-sport-figure mb-2">
                    <img src='/assets/imgs/results/athletes/soccer.png' alt=""/>
                    <span>Soccer</span>
                </div>
                <div className="result-sport-figure">
                    <img src='/assets/imgs/results/athletes/volleyball.png' alt=""/>
                    <span>Volleyball</span>
                </div>
            </div>
            <div className='title-break'>
                <span>Overview</span>
            </div>
            
            <div className="card">
                <img src="/assets/imgs/results/content/football-impact.jpeg" alt="" height={200} className="overview-img" />
                <p>Concussion is a traumatic brain injury (TBI) induced by biomechanical forces (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/28446457">McRory et al., 2017</a>). It can result from a direct blow to the head or neck area or from an impacted force to another portion of the body with resultant forces to the head. Concussions present with a variety of overlapping symptoms: physical, cognitive, emotional, and sleep disturbances. The most common symptom is headache or "brain fog." Other symptoms include delayed reaction time, drowsiness, emotional lability, and irritability.</p>
                <p>80-90% of individuals who experience a concussion for the first time feel better within 7-10 days (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/28446457">McRory et al., 2017</a>). However, individuals who have sustained multiple concussions or who have underlying health conditions may take longer to recover. Young athletes (ages 12-18) may also take longer to recover and should take longer before they return to play.</p>
                <p className="mb-0">Cumulative head trauma and repetitive concussion leads to a long-term neurodegenerative disease termed Chronic Traumatic Encephalopathy (CTE). CTE is caused by atypical hyperphosphorylated tau-neuronal deposits in the brain and has been identified in autopsies of contact sport athletes and military personnel. Symptoms of CTE include cognitive impairment, impulsive behavior, emotional lability, substance abuse, and changes in executive function.</p>
            </div>
            <div className='title-break'>
                <span>Frequency</span>
            </div>
            <div className="card">
                <p className="mb-0">Concussions are common amongst athletes participating in collision sports and in military personnel. In the United States, there are an estimated 1.6 to 3.8 million sports-related concussions each year (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/16983222">Langlois et al,. 2006</a>). Football is associated with the highest rates of concussion for males, whereas soccer is associated with the highest rates for females. Athletes who sustain an initial concussion are more likely to sustain repeat concussion, particularly in youth sports; 1 in 5 adolescents (12-15 years old) who were diagnosed with a concussion sustained at least one additional concussion within 2 years (<a target="_blank" rel="noopener noreferrer" href="https://www.jpeds.com/article/S0022-3476(19)30421-4/fulltext">Curry et al., 2019</a>).</p>
            </div>
        </div>
    );
};

export default ConcussionIntro;