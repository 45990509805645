import React from 'react';


const T2D_YeIntro = () => {
    return (
        <div>
            <div className='title-margins'>
                <h2>Introduction to Type 2 Diabetes</h2>
            </div>
            <div className='title-break'>
        <span>Overview</span>
      </div>
            <div className="card">
            <img alt="" className="overview-img" src="/assets/imgs/results/T2D.png" height={400}/> 
                <p>Type 2 diabetes is an impairment in the way the body regulates and uses glucose. This happens because your body cannot react to insulin effectively or is unable to produce enough of it. Normally, the hormone insulin helps move glucose from your blood to your cells but, with type 2 diabetes, your body&apos;s cells either don&apos;t produce enough insulin or they aren&apos;t able to respond to insulin as well as they should.  Eventually, type 2 diabetes can lead to disorders of the circulatory, nervous and immune systems.</p>
                <p>There are about 29 million people in the U.S. with type 2 diabetes. Of these, 25% are unaware that they have it. Another 84 million have prediabetes, meaning their blood sugar (or blood glucose) is high but not high enough to be diabetes yet.</p>
                <p>Type 2 diabetes occurs most often in middle-aged and older people. You are more likely to develop type 2 diabetes if you have a family history of diabetes, are overweight or have a sedentary lifestyle. Diabetes is more common in people who are African American, Hispanic/Latino, American Indian, Asian American, or Pacific Islander.</p>
                <p>The average medical expenses for people with diabetes are 2.3 times higher in the absence of diabetes. Diabetes nearly triples the risk of heart attack and stroke and is the leading cause of kidney failure. Diabetes is the seventh leading cause of death in the United States, either as the underlying cause of death or as a contributing cause of death. </p>
                </div>
                <div className='title-break'>
                    <span>Causes and Risk Factors</span>
                </div>
                <div className="card">

                <p>Type 2 diabetes is primarily the result of three interrelated problems: </p>
                <ol>
                    <li>Cells in muscle, fat and liver become resistant to insulin. Because these cells don&apos;t interact in a normal way with insulin, they don&apos;t take in enough sugar. </li>
                    <li>The pancreas is unable to produce enough insulin to manage blood sugar levels. Exactly why this happens is unknown, but being overweight and inactive are key contributing factors.</li>
                    <li>Certain genes predispose some people to get type 2 diabetes. For the AxGen type 2 diabetes test, those with a high genetic score have about a 3.7-fold increased risk.</li>
                </ol>
                <p>Risk factors that increase your risk of type 2 diabetes include:</p>
                <ul>
                    <li>Weight. Being overweight or obese is a main risk.</li>
                    <li>Inactivity. The less active you are, the greater your risk. Physical activity helps control your weight, metabolizes glucose as energy and increases the sensitivity of your cells to insulin.</li>
                    <li>Race and ethnicity. Black, Hispanic, Native American and Asian people are more likely than Caucassian people to develop type 2 diabetes.</li>
                    <li>Blood lipid levels. Low levels of high-density lipoprotein (HDL) cholesterol and high levels of triglycerides increase the risk of type 2 diabetes.</li>
                    <li>Age. The risk of type 2 diabetes increases as you get older, especially after age 45.</li>
                    <li>Pregnancy-related risks. Your risk of developing type 2 diabetes increases if you developed gestational diabetes when you were pregnant or if you gave birth to a baby weighing more than 9 pounds (4 kilograms).</li>
                    <li>Polycystic ovary syndrome. Having polycystic ovary syndrome — a common condition characterized by irregular menstrual periods, excess hair growth and obesity — increases the risk of type 2 diabetes.</li>
                    <strong>Genetics:</strong> The results page shows a genetic test for type 2 diabetes that scans your entire genome for risk. This test shows that certain people have 3.7 fold higher risk than others. If the test indicates increased risk, you can consult your health care provider to have your blood glucose level measured.
                </ul>
                </div>     
                <div className='title-break'>
                    <span>Symptoms</span>
                </div>
                <div className="card">
                <p>Signs of type 2 diabetes often develop slowly over the course of years and can go unnoticed until there are more serious health problems.</p>
                <p>When symptoms are present, they may include:</p>
                <ul>
                    <li>Increased thirst</li>
                    <li>Frequent urination</li>
                    <li>Increased hunger</li>
                    <li>Unintended weight loss</li>
                    <li>Fatigue</li>
                    <li>Blurred vision</li>
                    <li>Slow-healing sores</li>
                    <li>Frequent infections</li>
                    <li>Numbness or tingling in the hands or feet</li>
                    <li>Areas of darkened skin, usually in the armpits and neck</li>
                </ul>
                </div>
                <div className='title-break'>
                    <span>Prevention</span>
                </div>
                <div className="card">
                <p>Genetic testing can alert you that you are at risk for type 2 diabetes. Fortunately, this information can empower you to monitor your blood glucose and adopt healthy lifestyle choices that can help prevent the disease. </p>
                <p>A healthy lifestyle includes:</p>
                <ul>
                    <li>Eating healthy foods. The best kind of diet to prevent type 2 diabetes is rich in fruits, vegetables, healthy carbs, healthy fats, and very little refined sugar.</li>
                    <li>Exercise. Aim for 150 or more minutes a week of moderate to vigorous exercise, combining muscle strengthening and aerobic activity.</li>
                    <li>Weight management. Keeping a moderate weight is a good way to avoid chronic complications.</li>
                </ul>

            </div>
        </div>
    )
}

export default T2D_YeIntro;