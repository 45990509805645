import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip'
import { loadResultsThunk, loadSNPsThunk } from '../actions';

const defaultCols = ['gene', 'alleleFreq', 'relativeRisk', 'beta', 'pValue'];

// The title for the beta and running-total columns. Combined with betaUnits in some places. To expand, add 'tag': 'text'
const betaTitles = {
  'coffee-consumption': '',
  'testosterone': 'Testosterone',
  'vitamin-b12': 'Vitamin B12',
  'mg': 'Magnesium',
  'PUFA': "Omege-3 Polyunsaturated Fatty Acid",
  'vitA': 'vitamin A',
  'vitC': 'SD',
  'vitE' : 'alpha-tocopherol',
  'vitB6' : 'vitamin B6',
  'COQ10' : 'CoQ 10',
  'phos' : 'phosphorus',
  'vte2' : 'fold-risk',  
};
// The units of beta, similar rules as betaTitles
const betaUnits = {
  'coffee-consumption': 'Cups coffee/day',
  'testosterone': '(ng/dL)',
  'vitamin-b12': '(pmol/L)',
  'mg': '(mmol/L)',
  'PUFA': '(% DPA)',
  'vitA': '(ug/L)',
  'vitC': '(units)',
  'vitE' : '(mg/L)',
  'vitB6' : '(ng/ml)',
  'COQ10' : '(ug/ml)',
  'phos' : '(mg/dL)'
};
// Used to set the degree of toFixed in the beta score column. Default is 2 for allele_freq and 1 for running total
// mg is  sigfigs because any less results in values with negative 0's and such
const sigFigs = {
  'mg': 3,
  'vitamin-b12': 1,
  'testosterone': 1,
  'PUFA': 3,
  'vitA': 1,
  'vitC': 2,
  'Ca' : 3,
  'vitE' : 3,
  'vitB6' : 2,
  'COQ10' : 2,
  'phos' : 2,
  'strength' : 4
}

// pValue is used for all tests except lactose-intolerance so pValue is excluded via conditional if tag === lactose-intolerance

class GeneTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { tag, myUploadID } = this.props;
    const paths = window.location.pathname.split('/');
    const pathUploadID = paths.reduce((agg, path) => {
      return agg || parseInt(path, 10);
    }, false);
    const uploadID = (pathUploadID) ? pathUploadID : myUploadID;
    if (!(uploadID in this.props.results.uploads)) {
      this.props.loadResults(uploadID);
    }

    if (!(tag in this.props.results.snps)) {
      this.props.loadSNPs(tag);
    }
    this.setState({ uploadID });
  }
  render() {
    const { uploadID } = this.state;
    const { cols = defaultCols, results, tag } = this.props;
    const snps = (tag in results.snps) ? results.snps[tag] : [];
    snps.sort((a, b) => (Math.abs(a.weight) < Math.abs(b.weight)) ? 1 : -1);
    const userGenes = (((((results.uploads || {})[uploadID] || {})['results'] || {})[tag] || {}).meta || {}).genes || {};
    const betaTitle = (tag in betaTitles) ? betaTitles[tag] : 'Beta';
    const betaUnit = (tag in betaUnits) ? betaUnits[tag] : '';
    // Custom logic for caffeine-sensitivity table
    let caffeineResult = 'Inconclusive Results';
    const snpRS762551 = userGenes['rs762551'] || ['', ''];
    if (snpRS762551[0] === 'A' && snpRS762551[1] === 'A') {
      caffeineResult = 'Fast Metabolism';
    }
    else if (snpRS762551[0] === 'C' && snpRS762551[1] === 'C') {
      caffeineResult = 'Slow Metabolism';
    }
    else if ((snpRS762551[0] === 'C' && snpRS762551[1] === 'A') || (snpRS762551[0] === 'A' && snpRS762551[1] === 'C')) {
      caffeineResult = 'Intermediate Metabolism'
    }
    // RunningTotal is used to calculate cumulative beta scores for some tables (e.g. Caffeine)
    let runningTotal = 0
    return (
      <div className="table-responsive">
        <table className="gene-table w-100 col-sm-12 table-bordered table-condensed cf">
          <thead>
            <tr>
              {cols.includes('gene') ? <th data-tip="Target Gene.">Gene</th> : null}
              <th data-tip="Single Nucleotide Polymorphism. This denotes a spot in the genome where individuals can have different sequences.">SNP</th>
              <th data-tip="Your genotype at this position.">Genotype</th>
              <th data-tip="Which allele is associated with a change in injury risk.">Effect Allele</th>
              {cols.includes('alleleFreq') ? <th data-tip="The frequency of the effect allele. The frequency was measured in Europeans, and can be different for different ethnicities.">Allele Freq</th> : null}
              {
                tag !== "lactose-intolerance" ? (
                  <th data-tip="Statistical significance of risk associated with an effect allele.">P-Value</th>
                ) : (
                  null
                )
              }
              {cols.includes('relativeRisk') ? <th data-tip="Change in your risk of injury associated with having one copy of the effect allele. A relative risk greater than 1.0 means there is increased risk and less than 1.0 means decreased risk.">Relative Risk</th> : null}
              {cols.includes('beta') ? <th data-tip="Change in your score for each effect allele">{betaTitle} {betaUnit}</th> : null}
              {cols.includes('caffeine-metabolism') ? <th data-tip="Relative speed at which caffeine is metabolized.">Caffeine Metabolism</th> : null}
              {cols.includes('running-total') ? <th data-tip="Your Running Total">Score {betaUnit}</th> : null}
            </tr>
          </thead>
          <tbody>
            {snps.map((snp, i) => (
              <tr key={i} >
                {cols.includes('gene') ?
                  <td>{snp.gene ? snp.gene : '-'}</td> : null
                }
                <td>{snp.id}</td>
                <td>{(snp.id in userGenes ? userGenes[snp.id].join('/') : '??')}</td>
                
                {/* allele frequency goes to two sigfigs as of 9/17/19 */}
                <td>{snp.allele}</td>
                {cols.includes('alleleFreq') ?
                  <td>{snp.alleleFreq ? snp.alleleFreq.toPrecision(2) : '-'}</td> : null
                }

                {
                  tag !== "lactose-intolerance" ? (
                    <td>{snp.pValue ? snp.pValue : '-'}</td>
                  ) : (
                    null
                  )
                }

                {cols.includes('relativeRisk') ?
                  <td>{snp.relativeRisk ? snp.relativeRisk + 'x' : '-'}</td> : null
                }
                {cols.includes('beta') ?
                  <td>{snp.weight ? ( tag === 'mg' ? snp.weight.toPrecision(1) : snp.weight.toFixed(2))
                     : '-'}</td> : null
                }
                {cols.includes('caffeine-metabolism') ?
                  <td>{caffeineResult}</td> : null
                }
                {/* Running total counts beta scores */}
                {/* the following handles sigfigs in the running total; as of now (9/17/19), this running total goes to 1 sigfig */}
                {cols.includes('running-total') ? 
                  <td>{(runningTotal += calcNextRunningTotal(snp.weight, snp.alleleFreq, (snp.id in userGenes ? userGenes[snp.id].join('/') : '??'), snp.allele)).toFixed((tag in sigFigs ? sigFigs[tag] : 1))}</td> : null
                }
              </tr>
            ))}
          </tbody>
        </table>
        <p className="table-comment" align="center">**Hover over the table columns for more information.**</p>
        <ReactTooltip place="top" className="tooltip-axgen" effect="solid" />
      </div>
    );
  }
};

const calcNextRunningTotal = (weight, freq, allele, effectAllele) => {
  // When there is no allele, returns average calculated by allele frequency * allele weight * 2 (because 2 allele in each snp)
  if (allele === '??') {
    return weight * freq * 2
  }
  // If there is an allele, return allele weight * number of effect alleles
  else {
    // in RegEx, g indicates globalm that the whole string should be searched rather than the first occurance of the character
    // match returns the result of matching a string against a regular expression
    return weight * (allele.match(new RegExp(effectAllele,"g")) || []).length
  }
}

const mapStateToProps = (state) => {
  return {
    results: state.results,
    myUploadID: state.session.myUploadID
  };
};

const mapDispatchToProps = dispatch => ({
  loadResults: (uploadID) => dispatch(loadResultsThunk(uploadID)),
  loadSNPs: tag => dispatch(loadSNPsThunk(tag))
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneTable);
