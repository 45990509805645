import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';

// The HRrest result.value is normalized to the median in results.py.
// historgram, genetic scores and tagPercentile use the normalized HR rest result.value
// to calculate percentiles for UER.percentile, switch results-HRrestRAW-percentile with results-HRrest-percentile because calculate_percentile uses UER.result and not result.value.

export const HRrestResults = (props) => {
    const { crm, result } = props;
    let testComponent;
    if (result['value'] !== null) {    
        let prefixedGeneticScore = result['value'] > 0 ? `+${result['value'].toFixed(2)}` : `${result['value'].toFixed(2)}`;
        
      testComponent =
      <div>
        <div className="card">
       <p>AxGen developed a polygenic risk score for resting heart rate using 433 SNPs from <a href="https://pubmed.ncbi.nlm.nih.gov/27798624/">Eppinga et al., 2016</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/31648709/">Mensah-Kane et al., 2021</a> and <a href="https://pubmed.ncbi.nlm.nih.gov/31648709/">Guo et al., 2019</a>.  </p> 
        </div>
        <div className="card">
          <BetaHistogram crm={crm} result={result} crmKey='results-HRrest-histogram' />
          <p>The x-axis shows your genetic score for resting heart rate compared to the average. </p>
          <p>To use this graph, find the red line that shows your genetic score.  The blue histogram shows the number of people and their resting HR genetic scores. The bin containing your score tells you how many other people have a score like yours.  If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly lower resting heart rate.  </p>
          <p>Based solely on your genetics, your resting heart rate score is <strong>{prefixedGeneticScore}</strong> bpm compared to the average heart rate.  </p> 
          <p><TagPercentile result={props.result} tag='HRrest'/> </p>
        </div>
        <div className='title-break'>
          <span>How does my genetic score affect my training? </span>
        </div>
        <div className="card">
        <p>The score informs how your genetics affects how you use your HR data to monitor and evaluate your training regimen. For instance, if your genetic score is -5 bpm, then your HR will be shifted 5 bpm lower throughout your life. This is good news as lower HR is associated with fitness and health. However, this also means that the HR you measure is equivalent to a HR that is 5 bpm HIGHER for others. So a resting HR of 55 bpm for you is equivalent to a resting HR of 60 bpm for others.</p>
      </div>
        <div className='title-break'>
          <span>The genetic test has a <b>moderate</b> effect on your resting heart rate. </span>
        </div>
        <div className="card">
        <p><a href="https://pubmed.ncbi.nlm.nih.gov/31648709/">Mensah-Kane et al., 2021</a> estimated that the 433 SNPs in the genetic score explain 9.1% of the variability in resting HR in the general population, which includes elderly, sedentary individuals as well as young, highly-trained athletes. This highly heterogeneous cohort has a large range of resting heart rate (40-100 bpm) due to many factors such as age and training status. </p>
        <p>In a homogenous group of young, well-trained athletes, the range in resting HR is much smaller than in the general population. The range of resting HRs in elite athletes is approximately 3x smaller than in the general population, suggesting that the genetic test has a correspondingly larger effect for elite athletes. This means that your genetics has a significant effect when comparing your resting HR to that of similar athletes.</p>
      </div>
      </div>

      return (
      <div>
        <div className='title-margins'>
        <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for resting Heart Rate</h2>
        </div>
        { testComponent }

      </div>
    );
    };
};


export default HRrestResults;
