import React from 'react';
import IntroTable from '../../components/introTable';

const tableData = [
  ["Military recruits", "31%", <a href="https://www.ncbi.nlm.nih.gov/pubmed/4055871">Milgrom et al., 1985</a>],
  ["Track and field", "21%", <a href="https://www.ncbi.nlm.nih.gov/pubmed/8775123">Brennell et al., 1996</a>],
  ["Women's cross-country", 28, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Women's gymnastics", 25, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Women's outdoor track", 22, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Men's cross-country", 16, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Women's basketball", 14, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Women's tennis", 9, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Men's basketball", 8, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Men's outdoor track", 7, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Women's soccer", 7, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Women's lacrosse", 7, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Women's volleyball", 6, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Women's field hockey", 5, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Men's soccer", 4, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Football", 3, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>],
  ["Baseball", 1, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28937802">Rizzone et al., 2017</a>]
];


export class BMDIntro extends React.Component {
  render() {
    const table = <IntroTable
      data={tableData}
      caption="Table: BSI Incidence in Different Sports. AE: Athlete Exposures"
      extraText={<p>BSIs account for up to 20% of all sports medicine injuries (<a href="https://www.ncbi.nlm.nih.gov/pubmed/26616181">Tenforde et al., 2016</a>). Competitive cross-country and track-and-field athletes have the highest incidence of BSIs. Gymnasts, basketball players, and military recruits also have a high incidence of BSI (<a href="https://www.ncbi.nlm.nih.gov/pubmed/17414993">Fredericson et al., 2006</a>). Additionally, recreational athletes who modify or intensify their training regimen may be at higher risk of BSI.</p>}
    />

    return (
      <div>
        <div className='title-margins'>
          <h2>Introduction to Bone Stress Injuries and Bone Mineral Density</h2>
          <h6>written by Dr. Megan Roche, M.D.</h6>
        </div>

        <div className='title-break'>
          <span>High Risk Sports</span>
        </div>
        <div className='high-risk-list my-3 py-1'>
          <div className="result-sport-figure mb-2">
            <img src='/assets/imgs/results/athletes/runner.png'alt="" />
            <span>Endurance Running</span>
          </div>
          <div className="result-sport-figure mb-2">
            <img src='/assets/imgs/results/athletes/basketball.png' alt="" />
            <span>Basketball</span>
          </div>
          <div className="result-sport-figure mb-2">
            <img src='/assets/imgs/results/athletes/volleyball.png' alt="" />
            <span>Volleyball</span>
          </div>
        </div>

          <div className='title-break'>
            <span>Overview</span>
          </div>
          <div className="card">
            <img src="/assets/imgs/results/content/RunnerCut.jpg" alt="" height={300} className="overview-img"  />
            <p> Bone stress injuries (BSIs) occur when bone is unable to withstand mechanical loading forces and most often occur from repetitive running or jumping motions. BSIs exist along a continuum--from bone stress reactions in the early stages of injury to stress fractures and complete fractures in the later stages of injury. Early identification of BSIs may help prevent progression down the BSI continuum. </p>
            <p> BSIs can be classified into low-, moderate-, and high-risk injuries based on injury location. BSIs in the femoral neck, anterior cortex of the tibia, medial malleolus, navicular, and base of the 2nd metatarsal are considered high-risk. High-risk injuries are prone to delayed healing time and progression to complete fracture as a result of low blood flow or high bone tensile load.  </p>
            <p className="mb-0">BSIs can be further classified by grade on magnetic resonance imaging (MRI). On the grading scale defined by <a href="https://www.ncbi.nlm.nih.gov/pubmed/7573660">Fredericson</a> and modified by <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4367232/">Nattiv et al.</a>, grade 1 and grade 2 injuries are considered low-grade and grade 3 and grade 4 injuries are considered high-grade. <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4367232/">Nattiv et al.</a> further demonstrated that high-grade BSIs require longer recovery times. BSI recovery ranges between 3-12 weeks. Both BSI risk and BSI grade can help determine appropriate BSI management and recovery duration.  </p>
          </div>
          <div className='title-break'>
            <span>Frequency</span>
          </div>
          { table }
          <div className="title-break">
            <span>Causes</span>
          </div>
          <div className="card">
            <p>BSIs are caused by repetitive activity and overuse. Repetitive forces result in microscopic damage to your bone that can accumulate over time. If athletes do not allow enough time to recover between workouts, BSIs can occur.</p>
            <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/25103133">Warden et al.</a> classify risk factors for BSI into two categories: (1) the extrinsic load applied to bones and (2) the intrinsic ability of bones to resist load. As discussed in the prevention page, the extrinsic load applied to bones is influenced by biomechanics, footwear, and training regimen. The intrinsic ability of bone to resist load is influenced by exercise history, energy availability, and bone mineral status (<a href="https://www.ncbi.nlm.nih.gov/pubmed/25103133">Warden et al., 2014</a>).</p>
            <p>BSI may reflect a systemic hormonal or metabolic deficit as a result of low energy availability. The term Relative Energy Deficiency in Sport (RED-S), an updated and more inclusive name for the Female Athlete Triad (energy availability, menstrual function, and bone mineral density), reflects insufficient caloric intake and/or excessive energy expenditure. RED-S occurs in both male and female athletes and can increase the risk of sustaining a BSI (<a href="https://www.ncbi.nlm.nih.gov/pubmed/28684389">Statuta 2017</a>).</p>
            <p className="mb-0">Bone mineral density contributes to the intrinsic ability of bones to resist a load. Low bone mineral density is a strong predictor of BSI. Bone mineral density can be measured on a dual-energy x-ray absorptiometry (DXA) scan. Studies suggest that genetics also plays a large role in bone mineral density, with estimates of heritability of 0.45 to 0.82 (<a href="https://www.ncbi.nlm.nih.gov/pubmed/9718201">Howard et al., 1998</a>, <a href="https://www.ncbi.nlm.nih.gov/pubmed/12378368">Karasik et al., 2002</a>, <a href="https://www.ncbi.nlm.nih.gov/pubmed/22222934">Liu et al., 2012</a>, <a href="https://www.ncbi.nlm.nih.gov/pubmed/8992884">Arden et al., 1996</a>). In addition to genetic makeup, hormonal status, energy availability, and exercise history are other factors that can impact bone mineral density. Peak bone mass is attained around the age of 30. Bone density steadily declines after this peak period due to bone reabsorption (<a href="https://www.ncbi.nlm.nih.gov/pubmed/21520276">Baxter-Jones et al., 2011</a>). </p>
        </div>
      </div>
    )
  }
}

export default BMDIntro;
