import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Alerts from '../components/alerts';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import * as Actions from '../actions';
import StripeForm from '../components/stripe-form';

// create Stripe object
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const BuyPage = () => {

  const { session, orders, alerts, width } = useSelector((state) => ({
    session: state.session,
    orders: state.orders,
    alerts: state.alerts,
    width: state.ui.width
  }));

  // clears alerts on mount
  useEffect(() => {
    Actions.clearAlerts();
  }, []);

  // presentational element(s)
  // since the mobile view is constrained with this long className, this long className will be limited to md and lg devices
  const nonMobileDivClass = window.innerWidth < 768 ? "" : "col-12 col-sm-10 col-md-8 col-lg-6 mx-auto";

  return (
    <div>
    <Navbar />
    <div className="container mt-4">
      <div className={nonMobileDivClass}>
        <div className="buy-header">
          <h2>Buy a Kit or an Upload</h2>
       </div>
       <div>
          <Elements stripe={stripePromise}>
            <StripeForm width={width} session={session} orders={orders} alerts={alerts} />
          </Elements>
        </div>
        <Alerts tag='buy-kit' level='success' />
      </div>
      <br/>
    </div>
    <Footer />
  </div>
  );
};

export default BuyPage;