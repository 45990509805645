import React from 'react';
import GeneTable from '../../components/gene-table';
import RiskGraph from '../../components/risk-graph';
import Collapsible from 'react-collapsible';

class MCLResults extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for MCL Injury</h2>
          <h5>[<a href="https://www.ncbi.nlm.nih.gov/pubmed/28482362">Roos et al., 2017</a>]</h5>
        </div>
        <div className="card p-1">
          <Collapsible trigger="Tutorial">
          <p className='intro-vid'><iframe title="Results" width="560" height="315" src="https://www.youtube.com/embed/0kEsuRVgZXQ?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
          </Collapsible>
        </div>
        <div className="card">
          <RiskGraph tag='medial-collateral-ligament' />
          <p>The MCL injury genetic markers were identified using a European population. When these markers were analyzed in African, Asian and Latin American ancestry groups, no significant difference was found (<a href="https://www.ncbi.nlm.nih.gov/pubmed/28482362">Roos et al., 2017</a>).</p>
        </div>
        <div className="card">
          <p>MCL injury is a serious injury and one genetic marker indicates significant increased risk. However, the risk allele for the SNP is relatively rare. The C allele of rs6083471 has an allele frequency of 4.3% and is associated with a 60% increased risk. </p>
          <GeneTable tag='medial-collateral-ligament' cols={[ 'alleleFreq', 'relativeRisk'] } />
        </div>
      </div>
    )
  }
}

export default MCLResults;
