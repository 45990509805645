import React from 'react';
import ReactTooltip from 'react-tooltip';


// TODO: Effect Allele datatip refers to injury, 

// dictionary of all possible column types pairing tag with pretty tag for user presentation
const prettyCols = {
    gene:  {
        "prettyLabel": "Gene",
        "dataTip": "Target Gene."
    },
    snp: {
        "prettyLabel": "SNP",
        "dataTip": "Single Nucleotide Polymorphism. This denotes a spot in the genome where individuals can have different sequences."
    },
    genotype: {
        "prettyLabel": "Genotype",
        "dataTip": "Your genotype at this position."
    },
    allele: {
        "prettyLabel": "Effect Allele",
        "dataTip": "Which allele is associated with a change in risk."
    },
    alleleFreq: {
        "prettyLabel": "Allele Freq",
        "dataTip": "The frequency of the effect allele. The frequency was measured in Europeans, and can be different for different ethnicities."
    },
    pValue: {
        "prettyLabel": "P-Value",
        "dataTip": "Statistical significance of risk associated with a change in injury risk."
    },
    relativeRisk: {
        "prettyLabel": "Relative Risk",
        "dataTip": "Change in your risk of injury associated with having one copy of the effect allele. A relative risk greater than 1.0 means there is increased risk and less than 1.0 means decreased risk."
    },
    sensitivity: {
        "prettyLabel": "Ibuprofen Sensitivity",
        "dataTip": "Your ibuprofen sensitivity."
    },
    CYP2C8: {
        "prettyLabel": "CYP2C8",
        "dataTip": "Your genoptype for CYP2C8."
    },
    CYP2C9: {
        "prettyLabel": "CYP2C9",
        "dataTip": "Your genotype for CYP2C9."
    },
    apoE: {
        "prettyLabel": "apoE",
        "dataTip": "Your genotype for apoE."
    },
    risk: {
        "prettyLabel": "Alzheimer's risk",
        "dataTip": "Your relative risk for Alzheimer's Disease."
    },
};

// in the future, this component will handle all of the tests we do, so we can dynmaically create our tables without the messy exception-filled class we currently have in gene-table.js

const TableComposer = ({ tableConfig }) => {
    
    // creates columns for the table body, which are directly from the values of each snp object
    const _bodyColumnizer = (valArr) => {
        return valArr.map((val, idx) => (
            <td key={idx}>{val}</td>
        ));
    };

    // creates columns for the table header, the passed in array. these array elements are translated into prettyCols for user presentation.
    // table headers use <th> tags not <td> tags
    const _headerColumnizer = (valArr) => {
        return valArr.map((val, idx) => {
            let dataObj = prettyCols[val];
            let dataTip = dataObj.hasOwnProperty("dataTip") ? dataObj["dataTip"] : null;
            let prettyLabel = dataObj["prettyLabel"];
            return (
                <th key={idx} data-tip={dataTip}>
                    {prettyLabel}
                </th>
            );       
        });
    };

    // renders the columns of the body and the header of the table
    // type is optional and determines the function used to create the columns, as header and body differ
    // right now, this function is configured to create the two-row table for ibuprofen. in the future, an iterator will be added to handle tables with an n-number of rows 
    const _rowCreator = (snpObj, type = "body") => {
        if (type === "body") {
            const data = Object.values(snpObj);
            return (
                <tr>
                    {_bodyColumnizer(data)}
                </tr>    
            );    
        } else {
            const data = Object.keys(snpObj);
            return (
                <tr>
                    {_headerColumnizer(data)}
                </tr>    
            );    
        };
    };

    // creates the table header, which is based on the keys of tableConfig
    const _headerizer = (config) => (
        <thead>
            {_rowCreator(config, "header")}
        </thead>
    );

    // creates the table body, which is based on the values of the snp objects
    const _bodyizer = (config) => (
        <tbody>
            {_rowCreator(config)}
        </tbody>
    );

    // parent function for creating the table
    const tableCreator = (config) => (
        <table className="gene-table w-100 col-sm-12 table-bordered table-condensed cf">
            {_headerizer(config)}
            {_bodyizer(config)}
        </table>
    );

    const table = tableCreator(tableConfig);

    return (
        <div className="table-responsive">
            { table }
            <ReactTooltip place="top" className="tooltip-axgen" effect="solid" />
      </div>
    );
};

export default TableComposer;