import React from 'react';

export const VitB12Recommendation = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Vitamin B12 Recommendations</h2>
        <p>written by Dr. Megan Roche, M.D.</p>
      </div>
      
      <div className="card">
            <ul style={{listStyleType: 'square'}}>
                <li>Consider requesting vitamin B12 levels and a complete blood count (CBC) from your primary care physician if you are concerned about low levels or deficiency.</li>
                <li>Review foods that are high in vitamin B12.</li>
                <li>If you are a vegetarian, review vitamin B12-fortified foods.</li>
                <li>If you are deficient in vitamin B12, consult with a primary care physician to discuss the possibility of supplementation.</li>
            </ul>
      </div>
      <div className="card">
        <p>If you are concerned about low vitamin B12 levels or have clinical signs consistent with vitamin B12 deficiency, consider consulting with your primary care doctor to check vitamin B12 levels and a complete blood count (CBC). A CBC analyzes hemoglobin, hematocrit, and red blood cell distribution width (RDW), which reveal the quantity, size, and shape of the red blood cells. If abnormal, the doctor may then want to check for intrinsic factor antibodies, which prevent the absorption of vitamin B12. </p>
      </div>
      <div className="card">
        <p>Vitamin B12 can be obtained from diet or supplements. The recommended dietary intake for vitamin B12 is 2.4 mcg, but is higher for women who are pregnant or breastfeeding. Vitamin B12 is predominantly found in meat and dairy products. A food product is considered high in vitamin B12 content if a serving contains 20% or more of the recommended daily value of vitamin B12 intake. Foods that are high in vitamin B12 include clams, beef, tuna, salmon, and eggs. Vegetarian food sources high in vitamin B12 include nutritional yeast, non-dairy fortified milk, fortified cereal, and soy/veggie burgers. </p>
        </div>
      <div className="table-container">
        <div className="table-subcontainer">

          <table className="default-table">
            <caption>Table: Selected Food Sources of Vitamin B12</caption>
              <thead>
                <tr>
                  <th>Food</th>
                  <th>Micrograms/Serving (mcg)</th>
                  <th>Percent DV</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Clams, cooked, 3 ounces</td><td>84.1	</td><td>1,402</td>
                </tr>
                <tr>
                  <td>Liver, beef, cooked, 3 ounces</td><td>70.7	</td><td>1,178</td>        
                </tr>
                <tr>
                  <td>Nutritional yeasts, fortified with 100% of the DV for vitamin B12, 1 serving</td><td>6.0</td><td>100</td>
                </tr>
                <tr>
                  <td>Trout, rainbow, wild, cooked, 3 ounces</td><td>5.4</td><td>90</td>
                </tr>
                <tr>
                  <td>Salmon, sockeye, cooked, 3 ounces</td><td>4.8</td><td>80</td>
                </tr>
                <tr>
                  <td>Trout, rainbow, farmed, cooked, 3 ounces</td><td>3.5</td><td>58</td>
                </tr>
                <tr>
                  <td>Tuna fish, light, canned in water, 3 ounces</td><td>2.5</td><td>42</td>
                </tr>
                <tr>
                  <td>Cheeseburger, double patty and bun, 1 sandwich</td><td>2.5</td><td>35</td>
                </tr>
                <tr>
                  <td>Haddock, cooked, 3 ounces</td><td>1.8</td><td>30</td>
                </tr>
                <tr>
                  <td>Breakfast cereals, fortified with 25% of the DV for vitamin B12, 1 serving	</td><td>1.5</td><td>25</td>
                </tr>
                <tr>
                  <td>Beef, top sirloin, broiled, 3 ounces</td><td>1.4</td><td>23</td>
                </tr>
                <tr>
                  <td>Milk, low-fat, 1 cup</td><td>1.2</td><td>18</td>
                </tr>
                <tr>
                  <td>Yogurt, fruit, low-fat, 8 ounces</td><td>1.1</td><td>18</td>
                </tr>
                <tr>
                  <td>Cheese, Swiss, 1 ounce</td><td>0.9</td><td>15</td>
                </tr>
                <tr>
                  <td>Beef taco, 1 soft taco</td><td>0.9</td><td>15</td>
                </tr>
                <tr>
                  <td>Ham, cured, roasted, 3 ounces</td><td>0.6</td><td>10</td>
                </tr>
                <tr>
                  <td>Egg, whole, hard boiled, 1 large</td><td>0.6</td><td>10</td>
                </tr>
                <tr>
                  <td>Chicken, breast meat, roasted, 3 ounces</td><td>0.3</td><td>5</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="card">
        <p>Individuals with known vitamin B12 deficiency or disorders related to vitamin B12 malabsorption may benefit from vitamin B12 supplementation. The body’s ability to absorb vitamin B12 from supplements is limited by the capacity of intrinsic factor binding. For many healthy people, only 10 mcg of vitamin B12 can get absorbed from a 500 mcg supplement. Vitamin B12 can also be prescribed as an intramuscular injection.</p>
        </div>

    </div>
  )
}

export default VitB12Recommendation;
