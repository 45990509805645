import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Alerts from '../components/alerts';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import * as Actions from '../actions';
import { useCustomDispatch } from '../utils/customHooks';

const sportList = [
  { 'value': 'baseball', 'text': 'Baseball' },
  { 'value': 'basketball', 'text': 'Basketball' },
  { 'value': 'boxing', 'text': 'Boxing' },
  { 'value': 'cricket', 'text': 'Cricket' },
  { 'value': 'cycling', 'text': 'Cycling' },
  { 'value': 'field-hockey', 'text': 'Hockey (Field)' },
  { 'value': 'ice-hockey', 'text': 'Hockey (Ice)' },
  { 'value': 'football-american', 'text': 'Football (American)' },
  { 'value': 'golf', 'text': 'Golf' },
  { 'value': 'gymnastics', 'text': 'Gymnastics' },
  { 'value': 'mma', 'text': 'MMA' },
  { 'value': 'rugby', 'text': 'Rugby' },
  { 'value': 'running', 'text': 'Running' },
  { 'value': 'skiing', 'text': 'Skiing' },
  { 'value': 'soccer', 'text': 'Soccer' },
  { 'value': 'swimming', 'text': 'Swimming' },
  { 'value': 'tennis', 'text': 'Tennis' },
  { 'value': 'triathlon', 'text': 'Triathlon' },
  { 'value': 'volleyball', 'text': 'Volleyball' },
  { 'value': 'wrestling', 'text': 'Wrestling' },
  { 'value': 'other', 'text': 'Other' }
];


const SettingsPage = () => {
  const { alerts, session, uploads, width } = useSelector((state) => ({
    alerts: state.alerts,
    session: state.session,
    uploads: state.results.uploads,
    width: state.ui.width
  }));
  const [demographics, setDemogs] = useState({
    "gender": session.userDemographics.gender || "",
    "birthYear": session.userDemographics.birthYear || "",
    "height": session.userDemographics.height || "",
    "weight": session.userDemographics.weight || "",
    "sport": session.userDemographics.sport || ""
  });
  const [name, setName] = useState(session.userName);
  const [email, setEmail] = useState(session.userEmail);
  const [disabled, disableSubmit] = useState(false);
  const loadResults = useCustomDispatch(Actions.loadResultsThunk);

  const submitAccountSettings = (evt) => {
    evt.preventDefault();
    disableSubmit(true);
    Actions.clearAlerts();
    Actions.saveAccountSettings({
      name: name,
      email: email,
      demographics: demographics
    });
  };

  // handles changes to demogs state
  const handleDemogsChange = (e) => {
    e.preventDefault();
    let copiedState = Object.assign({}, demographics);
    const intTypes = ['birthYear', 'height', 'weight'];
    if (intTypes.indexOf(e.target.name) > -1 && e.target.value !== "") {
      copiedState[e.target.name] = parseInt(e.target.value);
    } else {
      copiedState[e.target.name] = e.target.value;
    };
    setDemogs(copiedState);
  };


  // lifecycle

  useEffect(() => {
    Actions.clearAlerts();
  }, []);

  useEffect(() => {
    if (Object.keys(alerts).length !== 0) {
      disableSubmit(false);
      
      // the success case. if one updates their settings and has results, we need to get results again if there are results in the global state already to allow for a full update
      const success = (alerts['save-account-settings'] || {})['success'] || [];
      const { myUploadID } = session;
      if (success.length > 0 && myUploadID !== null) {
        if (uploads.hasOwnProperty(myUploadID)) {
          loadResults(myUploadID);
        };
      };
    };
  }, [alerts, loadResults, session, uploads ]);


  // presentational
  const curYear = new Date().getFullYear();
  const demographicsForm = 
    <div className="demogs-form">
      <strong className="mt-2">Demographics</strong>
      <p className="mt-2">To provide you with the most accurate results, we combine both genetic and demographic factors in our calculations.</p>
      <div className="row">
        <div className="col-6">
          <select
            className="form-control"
            value={demographics.gender}
            onChange={(e) => handleDemogsChange(e)}
            name="gender"
          >
            <option value="" disabled>Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className="col-6">
          <input
            type="number"
            name="birthYear"
            placeholder="birth year"
            min={curYear - 100}
            max={curYear}
            className="form-control"
            value={demographics.birthYear}
            onChange={(e) => handleDemogsChange(e)}
          />
        </div>
        <div className="col-6">
          <input
            type="number"
            name="weight"
            placeholder="weight (lbs)"
            className="form-control"
            value={demographics.weight}
            min={1}
            onChange={(e) => handleDemogsChange(e)}
          />
        </div>
        <div className="col-6">
          <input
              type="number"
              name="height"
              placeholder="height (inches)"
              className="form-control"
              value={demographics.height}
              min={1}
              onChange={(e) => handleDemogsChange(e)}
            />
        </div>
        <div className="col-12">
          <select
            className="form-control"
            value={demographics.sport}
            name="sport"
            onChange={(e) => handleDemogsChange(e)}
          >
            <option value="" disabled>Select Sport</option>
            {sportList.map((sport) =>
              <option key={sport.value} value={sport.value}>{sport.text}</option>
            )}
          </select>
        </div>
      </div>
    </div>

  return (
    <div>
      <Navbar />
      <div className="container mt-4 col-12 col-sm-8 col-md-6 col-lg-5">
        <h2 className={ width < 768 ? "text-center" : null }>Account Settings</h2>
        <div className="row mt-3">
          <div className="col-12">
            <form onSubmit={ (evt) => submitAccountSettings(evt) } className="card p-3 form">
                <strong className="mb-1">Name</strong>
                <input
                  type="text"
                  name="name"
                  placeholder="full name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  required
                />
                <strong className="mb-1">Email</strong>
                <input
                  type="email"
                  name="email"
                  placeholder="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  required
                />
                { demographicsForm }
                <input
                  type="submit"
                  className="form-control btn btn-axgen mb-0"
                  value="UPDATE SETTINGS"
                  disabled={disabled}
                />
                <Alerts tag='save-account-settings' />
                <Alerts tag='save-account-settings' level='success' />
            </form>
          </div>
        </div>
      </div>
      <br/><br/>
      <Footer />
    </div>
  );
};


export default SettingsPage;
