import React from 'react';


export const AAAIntro = () => {
    return (
        <div>
            <div className="title-margins">
                <h2>Introduction to Abdominal Aortic Aneurysm</h2>
            </div>
            <div className='title-break'>
                <span>Overview</span>
            </div>
            <div className="card">
            <img alt="" className="overview-img" src="/assets/imgs/results/AAA2.png" height={300}/> 
                <p>An abdominal aortic aneurysm (AAA) is an enlarged area in the lower part of the major vessel that supplies blood to the body (aorta). The aorta runs from the heart through the center of the chest and abdomen. The aorta is the largest blood vessel in the body, so a ruptured abdominal aortic aneurysm can cause life-threatening bleeding.</p>
                <p>AAA is a common condition affecting about 200,000 people in the U.S. each year. It’s the 10th leading cause of death in men over 55.</p>
                <p>Screening can help spot a swelling in the aorta early on, when it can be treated. Women aged 70 or over with underlying risk factors such as high blood pressure or chronic obstructive pulmonary disease may also benefit from an ultrasound scan. </p>
            </div>
            <div className='title-break'>
                <span>Symptoms</span>
            </div>
            <div className="card">
                <p>Abdominal aortic aneurysms often grow slowly without noticeable symptoms, making them difficult to detect. </p>
                <p>If you have an enlarging abdominal aortic aneurysm, there may be a constant pain in the belly area or abdomen.</p>
            </div>
            <div className='title-break'>
                <span>Risk Factors</span>
            </div>
            <div className="card">
                <p>People at a higher risk of getting an AAA include all men aged 66 or over and women aged 70 or over who have one or more of the following risk factors:</p>
                <ul>
                    <li>high blood pressure</li>
                    <li>smoking</li>
                    <li>chronic obstructive pulmonary disease</li>
                    <li>high blood cholesterol</li>
                    <li>cardiovascular disease, such as heart disease or a history of stroke</li>
                </ul>
                <p><strong>Genetics:</strong> The results page shows a genetic test for AAA that scans your entire genome for risk. This test shows that certain people have 4 fold higher risk for AAA than others. If the test indicates an increased risk of AAA, you can check for an aneurysm with ultrasound scanning.</p>
            </div>
            <div className='title-break'>
                <span>Prevention</span>
            </div>
            <div className="card">
                <p>If your genetics indicates an increased risk for AAA, steps you can take to prevent an aneurysm include:</p>
                <ul>
                    <li>stopping smoking</li>
                    <li>eating healthily – eat a balanced diet and cut down on fatty food</li>
                    <li>exercising regularly – aim to do at least 150 minutes of exercise a week</li>
                    <li>maintaining a healthy weight</li>
                    <li>cutting down on alcohol</li>
                </ul>
                <p>If a large AAA is detected before it ruptures, most people will be advised to have treatment to prevent it rupturing.</p>
            </div>
        </div>
    );
}

export default AAAIntro;
