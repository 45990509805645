import React from 'react';
import GeneTable from '../../components/gene-table';

class VitAResults extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Vitamin A</h2>
          <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/21878437/">Mondul et al., 2011</a>]</h5>
        </div>
        <div className="card">
          <p>The average level of vitamin A (retinol) is 201 to 802 μg per liter.</p>
          <p>The <b>score</b> column shows how your genotype affects your levels of vitamin A.</p>

          <GeneTable tag='vitA' cols={[ 'gene', 'beta', 'running-total'] } />
          <p><i>RBP4</i> and <i>TTR</i> encode retinol-binding protein 4 and transthyretin, respectively. These proteins bind to each other and are major carriers of vitamin A (retinol) in serum. </p>
        </div>
        <div className='title-break'>
          <span>The genetic test has a <b>small</b> effect on vitamin A</span>
        </div>
        <div className="card">
        <p>First, each C allele at rs10882272 and each C allele at rs1667255 have beneficial effects of raising your vitamin A level by 19 ug/l and 23 ug/l, respectively (vitamin A levels normally range between 201 to 802 μg/l). Second, the difference in vitamin A levels between the best and worst genetic scores is about 15%. Third, the two SNPs together account for 2.3% of the variance in vitamin A levels, meaning that environment has a much larger effect on your vitamin A levels than genetics. </p>

        </div>
         </div>
    )
  }
}

export default VitAResults;
