import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Navbar from '../components/navbar';
import { withRouter } from 'react-router-dom';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';
import  ProgressBar from '../components/progress-bar';

// arrays that determine the items a user will see
const adminDashboardItems = [
  {'url': '/admin', 'title': 'Admin Dashboard', 'icon': 'fal fa-users-cog fa-6x'},
  {'url': '/order', 'title': 'Order Kits', 'icon': 'fal fa-shopping-cart fa-6x'},
  {'url': '/team', 'title': 'Team Results', 'icon': 'fal fa-running fa-6x'},
  {'url': '/admin/consultations', 'title': 'Consultations', 'icon': 'fal fa-user-md-chat fa-6x' },
  {'url': '/strength', 'title': 'Strength', 'icon': 'fal fa-dumbbell fa-6x'}
];

const staffDashboardItems = [
  {'url': '/team', 'title': 'Team Results', 'icon': 'fal fa-running fa-6x'},
  {'url': '/strength', 'title': 'Strength', 'icon': 'fal fa-dumbbell fa-6x'}
];

const managerDashboardItems = [
  {'url': '/business', 'title': 'Customer Portal', 'icon': 'fal fa-users-cog fa-6x'},
  {'url': '/team', 'title': 'Team Results', 'icon': 'fal fa-running fa-6x'}
];

// icons differ for the user
const userDashboardItems = [
  {'url': '/buy', 'title': 'Buy Genetic Analysis', 'icon': 'fal fa-dna fa-6x'},
  {'url': '/consultations', 'title': 'Buy Strength Plan', 'icon': 'fal fa-dumbbell fa-6x'},
  {'url': '/activate', 'title': 'Activate Your Kit', 'icon': 'fal fa-barcode-alt fa-6x'},
  {'url': '/receive', 'title': 'Receive Kit', 'icon': 'icon-axgen-kit'},
  {'url': '/upload', 'title': 'Upload File', 'icon': 'icon-uploads'},
  {'url': '/strength', 'title': 'My Strength', 'icon': 'fal fa-running fa-6x'}
];

// for uses that have a kit already, only these nav items are needed to avoid confusion
const userDashboardItemsStep2 = [
  {'url': '/consultations', 'title': 'Buy Strength Plan', 'icon': 'fal fa-dumbbell fa-6x'},
  {'url': '/activate', 'title': 'Activate Your Kit', 'icon': 'fal fa-barcode-alt fa-6x'},
  {'url': '/strength', 'title': 'My Strength', 'icon': 'fal fa-running fa-6x'}
];

// aggregates all of the above arrays into a single object allowing for easy determination 
const dashboardList = {
  "admin": adminDashboardItems,
  "consultant": staffDashboardItems,
  "manager": managerDashboardItems,
  "userStep1": userDashboardItems,
  "userStep2": userDashboardItemsStep2
};


export const LandingPage = ({ history }) => {
  // global state
  const { userRole, userKitStep, width, myUploadID, expectedByDate } = useSelector(state => ({ 
    userRole: state.session.userRole,
    myUploadID: state.session.myUploadID,
    userKitStep: state.session.userKitStep,
    expectedByDate: state.session.expectedByDate,
    width: state.ui.width
  }));

  // local state
  const [role, setRole] = useState("");

  // helper functions
  // determines the user role based on the global state and returns a string that will be used to key into the correct dashboard
  const _determineUserRole = () => {
    switch(userRole) {
      case "axgen-admin":
        return "admin";
      case "axgen-consultant":
        return "consultant";
      case "team-manager":
        return "manager";
      case "user":
      case "strength-user":
        return "user";
      default:
        break;
    };
  };

  // generates the dashboard based on the user role and the kit step
  // returns early preventing items from rendering if user does not have a role
  const _generateDashboard = () => {
    if (role === "") return;

    // determines nav items that will be rendered
    let items;
    if (role === 'user') {
      items = userKitStep === null ? dashboardList['userStep1'] : dashboardList['userStep2'];
    } else {
      items = dashboardList[role];
    };    
    
    if (['strength-user', 'user'].indexOf(role) === -1) {
      let duped = items?.slice() || []; //duplicate to avoid mutating state
      if (myUploadID !== null) {
        duped.push({'url': '/results', 'title': 'My Results', 'icon': 'fal fa-dna fa-6x'});
      };
      items = duped;
    };
    
    return items.map((item, idx) => (
      <div className={`dashboard-item ${oneItemWidth} ${pad} ${margY} ${cardHeight}`} key={idx}>
        <Link to={item['url']}>
          <div className="card card-hover h-100">
            <div className="card-body text-center">
              <div className={`${item['icon']} text-axgen`} />
              <div className="mt-3"><h3>{item['title']}</h3></div>
            </div>
          </div>
        </Link>
      </div> 
    ));
  };

  // main functions
  // redirects the user to results if they have results (userKitStep === 4) and are a user. we do not want auto-redirection for staff and team-managers
  // sets the user role into the state on mount so it can be used as a key in dashboardList
  // strength-users are also redirected
  useEffect(() => {
    if (userKitStep === 4 && (userRole === 'user' || userRole === "strength-user")) {
      history.push("/results");
    };
    // sets user role
    let uRole = _determineUserRole();
    setRole(uRole);
  }, []);


  // presentational elements
  // screen-size determined styles
  // displayOverride is used to center the cards when the number of cards in a row is less than 3. the bootstrap styles we use center only with three cards
  // oneItemWidth handles the width of dashboard-items on pages with only one item (i.e. user kit steps 2 and 3)
  const pad = width < 768 ? 'px-2' : '';
  const marg = width < 768 ? 'mx-1' : '';
  const margY = width < 768 ? 'my-2' : 'my-3';
  const cardHeight = width < 768 ? 'min-vh-8' : 'min-vh-15';
  const displayOverride = role === 'user' ? 'justify-content-center' : null; // centers the user dashboard items if the window width is md or lg, else does not center
  const oneItemWidth = width < 768 ? 'col-6' : "col-4";
  const mobileBreak = width < 768 ? null : <br/>; //adds extra break for non-mobile devices
  const dashboardItems = _generateDashboard();

  // users that have not bought an AxGen product yet do not see the tracker
  const orderTracker = userKitStep !== null ? 
  <div>
    <ProgressBar currentStep={userKitStep} width={width} expectedByDate={expectedByDate}/>
    <br/>
  </div> : null;

  // renders the content based on role
  // regular users have sections so this conditional-based rendering is necessary
  // slice allows for the creation of different dashboard rows
  // regular users have all of their sections centered except for the second section, which only centers items on non-mobile devices
  const content = role === 'user' ? (
    <div className="landing-items-parent">
      { orderTracker }
      <div className='title-break'>
        <span>Need to buy a genetic analysis (DNA testing kit or upload existing genome file) or shedule a strength consultation?</span>
      </div>
      <div className={`row ${marg} mt-4 ${(userKitStep !== null || width > 767) ? displayOverride : null}`}>
        { userKitStep !== null ? dashboardItems.slice(0, 1) : dashboardItems.slice(0, 2) }
      </div>
      <br/>{mobileBreak}
      {
        userKitStep === null || userKitStep === 0 ? (
          <div>
            <div className='title-break'>
              <span>Already have a kit?</span>
            </div>
            <div className={`row ${marg} mt-4 ${displayOverride}`}>
              { userKitStep !== null ? dashboardItems.slice(1, 2) : dashboardItems.slice(2, 3) }
            </div>
            <br/>{mobileBreak}
          </div>
        ) : null
      }
      { userKitStep !== null ? null : 
        <div>
          <div className='title-break'>
            <span>Already have a code?</span>
          </div>
          <div className={`row ${marg} mt-4 ${width < 768 ? null : displayOverride}`}>
            { dashboardItems.slice(3, 5) }
          </div>
          <br/>{mobileBreak}
        </div>
      }
      <div className='title-break'>
        <span>View your strength plan</span>
      </div>
      <div className={`row ${marg} mt-4 ${displayOverride}`}>
        { userKitStep !== null ? dashboardItems.slice(2) : dashboardItems.slice(5) }
      </div>
    </div>
  ) : (
    <div>
      <div className={`row ${marg} ${displayOverride}`}>
        { dashboardItems }
      </div>
      { (role === 'consultant' && userKitStep !== 4) ?
        <div className="mt-4">
          {orderTracker}
        </div> : null 
      }
    </div>
  );

  return (
    <div>
      <Navbar />
      <div className="container mt-4">
        { content }
      </div>
      <br/><br/>
      <Footer />
    </div>
  );
}

export default withRouter(LandingPage);
