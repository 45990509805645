import React from 'react';
import Navbar from '../components/navbar';

class FileNotFoundPage extends React.Component {
  render() {
    return (
      <div>
        <Navbar stateless={true} />
        <div className="container">
        <br></br>
        <br></br>
        <br></br>
        <h3>Blast! There was no page at the address you went to. ERROR 404 - File Not Found.</h3>
        </div>
      </div>
    )
  }
}

export default FileNotFoundPage;
