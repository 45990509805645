import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import GeneTable from '../../components/gene-table';

export const VitCResults = (props) => {
    const { crm, result } = props;
    let testComponent;
    if (result['value'] !== null) {    
        let prefixedGeneticScore = result['value'] > 0 ? `+${result['value'].toFixed(2)}` : `${result['value'].toFixed(2)}`;
        
      testComponent =
      <div>
        <div className="card">
        <p>The AxGen test for vitamin C uses a genetic algorithm with 11 genetic markers developed by <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7783939/">Zheng et al., 2021</a>.  </p>
        </div>
        <div className="card">
          <BetaHistogram crm={crm} result={result} crmKey='results-vitC-histogram' />
          <p>To use this graph, find the red line that shows your genetic score.  The blue histogram shows the number of people and their vitamin C genetic scores. The bin containing your score tells you how many other people have a score like yours.  If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly lower level of vitamin C.  </p>
          <p>The X axis shows values in standard deviation (SD) units. The standard deviation for vitamin C levels is about 23 umol/L. Multiply your genetic SD score by 23 (umol/L) to get the estimated effect on your vitamin C levels.   </p>
          <p>Based solely on your genetics, your predicted vitamin D score is <strong>{prefixedGeneticScore}</strong> SD units.</p> 
          <p><TagPercentile result={props.result} tag='vitC'/></p>
        </div>
        <div className="card">
        <p>The <b>score</b> column shows how your genotype affects your levels of vitamin C.</p>
          <GeneTable tag='vitC' cols={[ 'gene', 'beta', 'running-total'] } />
          <p>SLC23A1 and SLC23A3 both encode vitamin C transporters involved in the uptake of vitamin C into target tissues.  These two genes have the strongest effect (largest SD values) of the 11 SNPs. There was not an obvious connection with the other nine genes and vitamin C metabolism.  </p>
        </div>
        <div className='title-break'>
          <span>The genetic test has a <b>small</b> effect on vitamin C</span>
        </div>
        <div className="card">
        <p>The overall variance of vitamin C explained by the 11 SNPs is only 1.8% [<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7783939/">Zheng et al., 2021</a>]. This means that the influence of genetics on your vitamin C level is small, and it is mostly set by your diet and activity.  </p>
        <p>Second, the blue histogram spans about 0.5 SD units, which translates to about 12 umol/L vitamin C. The average level of Vitamin C ranges between 16 - 70 umol/L, so a change of 12 umol/L is small but noticeable.  </p>
        </div>
      </div>

      return (
      <div>
        <div className='title-margins'>
        <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Vitamin C</h2>
        <h5>[<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7783939/">Zheng et al., 2021</a>]</h5>
        </div>
        { testComponent }

      </div>
    );
    };
};


export default VitCResults;
