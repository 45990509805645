import { LOAD_ADMIN_COUNSULTATIONS, CREATE_ADMIN_CONSULTATION, LOAD_ADMIN_ORDERS } from '../actions';
import { sortOrdersByDate, sortConsultsByDate } from '../utils/data-tables';

const defaultState = {
  'orders': [], 
  'consultations': {}
};


const adminReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case LOAD_ADMIN_ORDERS:
      const sortedOrders = sortOrdersByDate(action.payload.data);
      return Object.assign({}, state, { orders: sortedOrders });
    case LOAD_ADMIN_COUNSULTATIONS:
      const { consultations, athletes, counselors } = action.payload.data;
      const sortedConsults = sortConsultsByDate(consultations);
      return Object.assign({}, state, { consultations: { consultations: sortedConsults, athletes, counselors } });
    case CREATE_ADMIN_CONSULTATION:
      const newState = Object.assign({}, state);
      const consultState = newState.consultations;
      const newConsult = action.payload.data;
      const consultant = consultState.counselors.find((v) => v.id === newConsult.consultantId);
      const consultee = consultState.athletes.find((v) => v.id === newConsult.consulteeId);
      if (consultant) newConsult.consultantName = consultant.name;
      if (consultee) {
        newConsult.consulteeName = consultee.name;
        newConsult.uploadEntryId = consultee.uploadEntryId;
      };
      consultState.consultations.unshift(newConsult);
      return newState;
    default:
      return state;
  };
};

export default adminReducer;
