import React from 'react';

class IbuprofenRecommendation extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Ibuprofen Sensitivity Recommendations</h2>
          <p>written by Dr. Megan Roche, M.D.</p>
        </div>
        <div className='title-break'>
          <span>Quick Checklist</span>
        </div>
        <div className="card">
          <ul>
            <li>Schedule an appointment with your physician if you are concerned about side effects from ibuprofen use.</li>
            <li>Consider reducing dose of ibuprofen, reducing the frequency of ibuprofen use, or switching to acetaminophen (Tylenol) if you are at risk for high ibuprofen levels.</li>
            <li>If you are taking a medication that has a known drug-drug interaction with ibuprofen, discuss your medications with your physician. </li>
            <li>Individuals who have sickle cell disease and are at risk for high ibuprofen levels should not take ibuprofen.</li>
          </ul>
        </div>  
        <div className="card">
          <p>We recommend that individuals with low ibuprofen clearance due to mutations in either CYP2C8 or CYP2C9 levels reduce their dose of ibuprofen, reduce the frequency of ibuprofen use, and/or switch to acetaminophen (Tylenol). </p>
        </div>
      </div>
    )
  }
}

export default IbuprofenRecommendation;
