import React from 'react';
import { useSelector } from 'react-redux';
import Alerts from '../components/alerts';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import GiftForm from '../components/gift-form';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// gift.js based on buy.js.
// create Stripe object
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const GiftPage = ({ history }) => {
  // global states
  const { session, orders, alerts, width } = useSelector((state) => ({
    session: state.session,
    orders: state.orders,
    alerts: state.alerts,
    width: state.ui.width
  }));
    
  

  // presentational elements
  const nonMobileDivClass = window.innerWidth < 768 ? "" : "col-12 col-sm-10 col-md-8 col-lg-6 mx-auto";

  // creates header
  const header = 
    <div className="buy-header">
      <h2>Order AxGen as a gift for your favorite athlete</h2>
    </div>
  
  // putting it all together
  const giftForm =
        <div>
          <Elements stripe={stripePromise}>
            <GiftForm width={width} session={session} orders={orders} alerts={alerts} />
          </Elements>
          <Alerts tag='buy-kit' level='success' />
        </div>
        

  return (
    <div>
      <Navbar />
      <div className="container mt-4">
      <div className={nonMobileDivClass}>
        { header }
        <div className="card py-3 mb-4">
          { giftForm }
        </div>
        </div>
      </div> 
      <Footer />
    </div>
  );
}

export default GiftPage;
