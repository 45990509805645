import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

class MUIMobileTable extends React.Component {
  
  //this method utilizes a method imported from the MaterialUi library to overwrite the default MUITables styles
  getMuiTheme() {
    return createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTableBody: {
          emptyTitle: {
            textAlign: "left"
          }
        },
        MUIDataTableBodyRow: {
          root: {
            textAlign: "left",
            }
        },
        MUIDataTableBodyCell: {
          root: {
            verticalAlign: "middle"
          },
        },
      }
    });
  };
       
    render() {
      //to complete the theme overwrite, the MUITable must be wrapped in MUIThemeProviderTags
        const { title, data, columns, options } = this.props;
        return (
            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable title={title} data={data} columns={columns} options={options} />
            </MuiThemeProvider>
      );
    }
}

export default MUIMobileTable; 
  