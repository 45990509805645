import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';


export const ProstateResults = ({ userName, nameEndsWithS, uploadID, width }) => {
    // this page contains the results of multiple tests
    const { crm, uploads } = useSelector((state) => ({
        crm: state.crm,
        uploads: state.results.uploads,
    }));

    // gets the score for a given tag and returns the score sentence
    const getScoreContent = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        let score = tagResult.hasOwnProperty("value") ? tagResult['value'] : null;
        console.log('score =', score)
        let prefixedScore;
        if (score !== null) {
            prefixedScore = score > 0 ? `+${score.toFixed(2)}` : `${score.toFixed(2)}`;
        };
        return (
            <strong>{prefixedScore}</strong>
        );
    };

    const getGraph = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});

        const crmKey = `results-${tag}-histogram`;
        return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
    };

    const getPercentile = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        return <TagPercentile result={tagResult} tag={tag} usesUKData={false} />;
    };

    return (
        <div>
            <div className='title-margins'>
                <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Cancer</h2>
                <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/33579919/">Graff et al., Nature Commun. 2021</a>]</h5>
            </div>

            <div className='title-break'>
                <span>Your Results</span>
            </div>
            <div className="card">
                <p>Below are your polygenic risk scores for 14 cancers (listed alphabetically).</p>
                <p>To use the graphs, find the red line that shows your genetic score.  The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have higher risk for cancer.  </p>
            </div>

            <div className='title-break'> 
                <span>Bladder Cancer</span>
            </div>
            <div className="card">
                {getGraph("bladder")}
                <p className="mb-0">The bladder cancer PRS has 14 SNPs. Each SNP has a genome-wide significant association with risk for bladder cancer. </p>
                <p>{getPercentile("bladder")} For bladder cancer, a score in the 84th percentile has about a 1.3 fold increased risk. A score in the 97th percentile has about a 2.6 fold increased risk.</p>
            </div>

            <div className='title-break'>
                <span>Cervical Cancer</span>
            </div>
            <div className="card">
                {getGraph("cervix")}
                <p className="mb-0">The cervical cancer PRS has 22 SNPs. Each SNP has a genome-wide significant association with risk for cervical cancer. </p>
                <p>{getPercentile("cervix")} For cervical cancer, a score in the 84th percentile has about a 1.2 fold increased risk. A score in the 97th percentile has about a 2.4 fold increased risk.</p>
            </div>

            <div className='title-break'>
                <span>Colorectal Cancer</span>
            </div>
            <div className="card">
                {getGraph("colorectal")}
                <p className="mb-0">The colorectal cancer PRS has 102 SNPs. Each SNP has a genome-wide significant association with risk for colorectal cancer. </p>
                <p>{getPercentile("colorectal")} For colorectal cancer, a score in the 84th percentile has about a 1.4 fold increased risk. A score in the 97th percentile has about a 2.8 fold increased risk.</p>
            </div>

            <div className='title-break'>
                <span>Endometrial Cancer</span>
            </div>
            <div className="card">
                {getGraph("endometrial")}
                <p className="mb-0">The endometrial cancer PRS has 8 SNPs. Each SNP has a genome-wide significant association with risk for endometrial cancer. </p>
                <p>{getPercentile("endometrial")} For endometrial cancer, a score in the 84th percentile has about a 1.2 fold increased risk. A score in the 97th percentile has about a 2.4 fold increased risk.</p>
            </div>

            <div className='title-break'>
                <span>Kidney Cancer</span>
            </div>
            <div className="card">
                {getGraph("kidney")}
                <p className="mb-0">The kidney cancer PRS has 18 SNPs. Each SNP has a genome-wide significant association with risk for kidney cancer. </p>
                <p>{getPercentile("kidney")} For kidney cancer, a score in the 84th percentile has about a 1.2 fold increased risk. A score in the 97th percentile has about a 2.4 fold increased risk.</p>
            </div>

            <div className='title-break'>
                <span>Lung Cancer</span>
            </div>
            <div className="card">
                {getGraph("lung")}
                <p className="mb-0">The lung cancer PRS has 108 SNPs. Each SNP has a genome-wide significant association with risk for lung cancer. </p>
                <p>{getPercentile("lung")} For lung cancer, a score in the 84th percentile has about a 1.1 fold increased risk. A score in the 97th percentile has about a 2.2 fold increased risk.</p>
            </div>

            <div className='title-break'>
                <span>Lymphocytic Leukemia</span>
            </div>
            <div className="card">
                {getGraph("lymph")}
                <p className="mb-0">The lymphocytic leukemia PRS has 74 SNPs. Each SNP has a genome-wide significant association with risk for lymphocytic leukemia. </p>
                <p>{getPercentile("lymph")} For lymphocytic leukemia, a score in the 84th percentile has about a 1.4 fold increased risk. A score in the 97th percentile has about a 2.8 fold increased risk.</p>
            </div>

            <div className='title-break'>
                <span>Melanoma</span>
            </div>
            <div className="card">
                {getGraph("melanoma")}
                <p className="mb-0">The melanoma PRS has 22 SNPs. Each SNP has a genome-wide significant association with risk for melanoma. </p>
                <p>{getPercentile("melanoma")} For melanoma, a score in the 84th percentile has about a 1.4 fold increased risk. A score in the 97th percentile has about a 2.9 fold increased risk.</p>
            </div>

            <div className='title-break'>
                <span>Non-Hodgkin's Leukemia</span>
            </div>
            <div className="card">
                {getGraph("NHL")}
                <p className="mb-0">The non-Hodgkin's leukemia PRS has 18 SNPs. Each SNP has a genome-wide significant association with risk for non-Hodgkin's leukemia. </p>
                <p>{getPercentile("NHL")} For non-Hodgkin's leukemia, a score in the 84th percentile has about a 1.3 fold increased risk. A score in the 97th percentile has about a 2.5 fold increased risk.</p>
            </div>

            <div className='title-break'>
                <span>Oral Cavity/Pharyngeal Cancer</span>
            </div>
            <div className="card">
                {getGraph("oral")}
                <p className="mb-0">The oral cavity/pharyngeal cancer PRS has 13 SNPs. Each SNP has a genome-wide significant association with risk for oral cavity/pharyngeal cancer. </p>
                <p>{getPercentile("oral")} For oral cavity/pharyngeal cancer, a score in the 84th percentile has about a 1.08 fold increased risk. A score in the 97th percentile has about a 2.16 fold increased risk.</p>
            </div>

            <div className='title-break'>
                <span>Ovarian Cancer</span>
            </div>
            <div className="card">
                {getGraph("ovary")}
                <p className="mb-0">The ovarian cancer PRS has 32 SNPs. Each SNP has a genome-wide significant association with risk for ovarian cancer. </p>
                <p>{getPercentile("ovary")} For ovarian cancer, a score in the 84th percentile has about a 1.14 fold increased risk. A score in the 97th percentile has about a 2.28 fold increased risk.</p>
            </div>

            <div className='title-break'>
                <span>Prostate Cancer</span>
            </div>
            <div className="card">
                {getGraph("prostate2")} 
                <p className="mb-0">The prostate cancer PRS has 160 SNPs. Each SNP has a genome-wide significant association with risk for prostate cancer. </p>
                <p>{getPercentile("prostate2")} For prostate cancer, a score in the 84th percentile has about a 1.4 fold increased risk. A score in the 97th percentile has about a 2.8 fold increased risk.</p>
            </div> 

            <div className='title-break'>
                <span>Thyroid Cancer</span>
            </div>
            <div className="card">
                {getGraph("thyroid")}
                <p className="mb-0">The thyroid cancer PRS has 11 SNPs. Each SNP has a genome-wide significant association with risk for thyroid cancer. </p>
                <p>You score for prostate cancer risk is {getScoreContent("thyroid")}. </p> 
                <p>{getPercentile("thyroid")}For thyroid cancer, a score in the 84th percentile has about a 1.55 fold increased risk. A score in the 97th percentile has about a 3.1 fold increased risk.</p>
            </div>





            <div className="card">
                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">

                    <p>The PRS scores were derived using European subjects. It is not clear how well these scores apply to other ancestry groups.</p>
                    <p>The PRS scores use predictors that are genome-wide significant (p&lt;5E-8). SNPs with larger p-values probably add more signal than noise. Algorithms that are more lenient about including SNPs may have higher performance. /</p>

                </div>
            </div>
        </div>
    );
};

export default ProstateResults;
