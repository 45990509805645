import { SAVE_PERMISSIONS, LOAD_PERMISSIONS, UPDATE_PERMISSION, ADD_PERMISSION } from '../actions';

// blank single permission
const defaultPermission = {
  toggleActive: true, name: '', email: ''
};


const permissionsReducer = (state = [defaultPermission], action) => {
  const newState = Object.assign([], state);
  
  switch (action.type) {
    case SAVE_PERMISSIONS:
      return newState;
    case UPDATE_PERMISSION:
      newState[action.payload.index] = action.payload.permission;
      return newState;
    case ADD_PERMISSION:
      newState.push(defaultPermission);
      return newState;
    case LOAD_PERMISSIONS:
      let nextState = action.payload.data.permissions.map(perm => {
        return {
          name: perm.name,
          email: perm.email,
          toggleActive: perm.active
        };
      });
      nextState.push(defaultPermission);
      return nextState;
    default:
      return state;
  }
};

export default permissionsReducer;
