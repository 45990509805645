import React from 'react';
import FAQ from '../../components/faq';


export class BMDFAQ extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Stress Fracture / BMD FAQ</h2>
        </div>
        
        <div className="card">
          <FAQ 
            header="How common is stress fracture for athletes?"
            body={
              <span>Stress fracture is a common overuse injury that accounts for roughly 10-20% of sports medicine consultations (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3702771/">Chen et al., 2013</a>). The incidence of stress fractures was approximately 9.2 % in female military recruits, 3 % in male military recruits, 9.7 % in female athletes, and 6.5 % in male athletes (<a href="https://www.ncbi.nlm.nih.gov/pubmed/21539165/">Wentz et al., 2011</a>).</span>
            }
          />
          <FAQ
            header="How is the BOG score calculated?"
            body={
              <span>The BOG score is calculated from a genetic algorithm for BMD and your height, age, weight and sex (<a href="https://www.ncbi.nlm.nih.gov/pubmed/30048462">Kim, 2018</a>). The genetic algorithm is calculated from 22,886 DNA polymorphisms, each contributing a small amount to your bone mineral density.</span>
            }
          />
          <FAQ
            header="How well does the BOG score predict risk for fracture?"
            body={
              <span>Individuals with low scores (2.2% of the total) show a 1.87 fold increased risk for fractures. The score is continuous; the graph shows your risk for fracture.</span>
            }
          />
          <FAQ
            header="How well does the BOG score predict bone mineral density?"
            body={
              <span>Your BOG score is an estimate of your T score, which is the standard measure of bone mineral density (BMD). The BOG score shows a 50% correlation with your T score. Correlation is a statistical term that measures the strength of the linear relationship between the genetic score and the measured BMD, where 100% means that they are the same and 0% means that there is no reltionship. For genetic tests of complex traits such as BMD, a value of 50% is high and means that there is good agreement between the genetic test and BMD. The score is continuous; you can find your own prediction for bone mineral density from the graph.</span> 
            }
          />
            <FAQ
            header="What if I have a high BOG score?"
            body={
              <span>Good News!!!! Individuals with high BOG scores correlate with high bone mineral density and a lower risk for fracture.</span> 
            }
          />
          <FAQ
            header="Is the BOG score informative if BMD is also known from dual X ray absorbtiometry (DXA)?"
            body={
              <span>The BOG score is partially redundant with DXA measurement for predicting risk of stress and bone fractures. Since it is based on your DNA, the genetic component of your BOG score never changes and indicates your inherent levels of bone mineral density. Bone mineral density measured by DXA scan can change over the course of a season or between seasons, and should be done at regular intervals. BOG scores can be calculated for anyone that has undergone genetic testing, so they are widely available whereas DXA scans are not routinely performed for most athletes.</span>
            }
          />
          <FAQ
            header="How well does the BOG score work for individuals with African, Asian or Indian ancestry?"
            body={
              <span><p>For non-European ancestry groups, the BOG score still shows a correlation with bone mineral density but not as strong as in Europeans. This is expected, as the BOG algorithm was trained using genotypes of 350,000 individuals of European ancestry.</p>
              <p>For Europeans, the BOG algorithm shows 50% correlation with BMD.</p>
              <p>For Asians, the BOG algorithm shows 28% correlation with BMD (60% compared to Eur.).</p>
              <p>For Africans, the BOG algorithm shows 24% correlation with BMD (47% compared to Eur.).</p>
              <p>For Indians, the BOG algorithm shows 34% correlation with BMD (70% compared to Eur.).</p></span>
            }
          />
          <FAQ
            header="Are there genes and pathways that help explain the underlying basis for bone mineral density?"
            body={
              <span>The genetic basis underlying bone mineral density is extremely complex; one recent study identified 899 genetic loci contributing to bone mineral density (<a href="https://www.ncbi.nlm.nih.gov/pubmed/30048462">Kim, 2018</a>). Each genetic locus contributes only a small fraction to BMD, and all of the loci together have a strong effect. Another study performed bioinformatic and genetic studies to identify GPC6 as a potential causal gene for low BMD (<a href="https://www.ncbi.nlm.nih.gov/pubmed/28869591">Kemp et al., 2017</a>). GPC6 encodes a glypican, which is a cell-surface protein involved in signaling whose loss of function leads to increased bone mineral content.</span>
            }
          />
        </div>
      </div>
    )
  }
}

export default BMDFAQ;
