import React from 'react';
import GeneTable from '../../components/gene-table';

class FolateResults extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Vitamin B9</h2>
          <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/30339177/">Shane et al., 2018</a>]</h5>
        </div>
        <div className="card">
        <p>The <b>score</b> column shows how many G alleles you have at <i>MTHFR</i> (rs1801133). A score greater than 0 indicates normal levels, and a score of 0 indicates lower levels of vitamin B9.</p>

          <GeneTable tag='folate' cols={[ 'gene', 'beta', 'running-total'] } />
        </div>
        <div className="table-container">
        <p>The next table shows how different genotypes at <i>MTHFR</i> (rs1801133) affect levels of vitamin B9. The reference range of red blood cell vitamin B9 is 317-1422 nmol/L. </p>
                <div className="table-subcontainer">
                    <table className="default-table">
                        <thead>
                            <tr>
                                <th>Gene (polymorphism)</th>
                                <th>Genotype</th>
                                <th>red blood cell vitamin B9 (nmol/L), mean ± SD</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><i>MTHFR</i> (rs1801133)</td>
                                <td>G/G</td>
                                <td>1096 ± 425</td>
                            </tr>
                            <tr>
                                <td><i>MTHFR</i> (rs1801133)</td>
                                <td>G/A</td>
                                <td>1048 ± 407</td>
                            </tr>
                            <tr>
                                <td><i>MTHFR</i> (rs1801133)</td>
                                <td>A/A</td>
                                <td>897 ± 425</td>
                            </tr>
                        </tbody>
                    </table>
                    <p></p>
                    <p><i>MTHFR</i> encodes 5,10-methylenetetrahydrofolate reductase, which converts 5,10-methylenetetrahydrofolate to the primary form of vitamin B9 called 5-methyltetrahydrofolate. 5-methyltetrahydrofolate is necessary for converting the amino acid homocysteine to another amino acid, methionine. </p>
        <p>The rs1801133 polymorphism (G vs A) changes the protein sequence at position 607 from cysteine to threonine. For rs1801133, the frequency of the G and A alleles is about 67% and 33%, respectively. </p>
                </div>
            </div>   

        <div className='title-break'>
          <span>The genetic test has a <b>moderate</b> effect on vitamin B9.</span>
        </div>
        <div className="card">
        <p>The rs1801133 polymorphism in <i>MTHFR</i> has a moderate effect on your vitamin B9 level. People with an A/A genotype have about 200 nmol/L less vitamin B9 in their red blood cells compared to people with a G/G genotype.  </p>

        </div>
         </div>
    )
  }
}

export default FolateResults;
