import React from 'react';

class PlantarPrevention extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Plantar Fasciitis Prehabilitation Measures</h2>
        </div>

        <div className="card">
          <img className="overview-img" src="https://i.ibb.co/nzDVzD0/action-athlete-athletics-618612.jpg" height={300} alt="" />
          <p>Athletes with high-arched feet (pes cavus) are more prone to under-pronation and less force dissipation during movement. The decreased shock absorption results in more force applied to the insertion of the plantar fascia. Athletes with low-arched feet (pes planus) are more susceptible to over-pronation, which can cause plantar fascia elongation. Other anatomic variants (including excessive subtalar or forefoot varus) can predispose an athlete to plantar fascia inflammation (<a href="https://www.ncbi.nlm.nih.gov/pubmed/16558682">Bolgla and Malone, 2004</a>).</p>
          <p>A gait analysis can help an athlete assess foot strike other abnormalities. Custom orthoses may help some athletes achieve biomechanical control. Running shoes should be replaced after 300-500 miles of use. The pattern of wear on running shoes may indicate the presence of under- or over-pronation. Other shoes including cleats and basketball shoes should be monitored for wear and regularly replaced.</p>
          <p>Athletes working in occupations that require prolonged standing or time on feet are at higher risk for plantar fasciitis. Taking short breaks, wearing supportive work shoes, and varying standing position might help athletes who have to stand for long durations. </p>
        </div>
      </div>
    )
  }
}

export default PlantarPrevention;
