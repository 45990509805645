import React from 'react';

class CaIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Calcium</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/Ca.jpg" height={200}/>   <p>Calcium is found in some foods and is available as a dietary supplement. It is present in bones and teeth and allows normal bodily movement by keeping tissue rigid and strong. It is also important for blood vessel contraction and dilation, muscle function, blood clotting, nerve transmission, and hormonal secretion.</p>
        <p>Total calcium levels are typically 2.2 - 2.6 mmol/L in healthy people. </p>
        </div>
        <div className='title-break'>
          <span>Recommended Daily Allowance</span>
        </div>
        <div className="card">
        <p>For adults, the recommended daily allowance is about 1 g of calcium for bone maintenance and neutral calcium balance. 39% of adults consume less than the estimated average requirements for calcium from foods and supplements [<a href="https://pubmed.ncbi.nlm.nih.gov/24724766/">Wallace et al., 2010</a>]</p>
        </div>
        <div className='title-break'>
          <span>Sources of Calcium</span>
        </div>
        <div className="card">
        <p>Dairy products such as milk, yogurt, and cheese are rich natural sources of calcium. Non-dairy sources include canned sardines and salmon with bones as well as certain vegetables, such as kale, broccoli, and Chinese cabbage. Foods fortified with calcium include fruit juices, tofu, and cereals.  </p>
        <p>Calcium is available in many dietary supplements, including multivitamin/mineral products and supplements. Some individuals who take calcium supplements might experience gastrointestinal side effects, including gas, bloating, constipation, or a combination of these symptoms. Calcium carbonate appears to cause more of these side effects than calcium citrate, especially in older adults who have lower levels of stomach acid. </p>
        </div>
        <div className='title-break'>
          <span>Calcium Deficiency</span>
        </div>
        <div className="card">
        <p>Calcium deficiency can reduce bone strength and leads to osteoporosis (characterized by fragile bones and an increased risk of fractures) and osteomalacia (defective bone mineralization and bone softening).</p>
      </div>
      </div>
    )
  }
}

export default CaIntro;
