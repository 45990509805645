import React from 'react';

class FEVTraining extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Improving your lung capacity</h2>
        </div>
          
        <div className="card">
          <p>blah.</p>
        </div>
        <div className="card">
          <p>blah</p>
        </div>
        <div className="card">
          <p>Black Sheep</p>
        </div>
      </div>
    )
  }
}

export default FEVTraining;
