import React from 'react';
import { Link } from 'react-router-dom';
import { validateDemographics } from '../../utils/results';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';


const TestosteroneResults = ({ result, userName, nameEndsWithS, demographics, userIsMe, crm }) => {
  const hasDemographics = validateDemographics(demographics);
  let testComponent;
  if (hasDemographics && result['value'] !== null) {    
    let configKey = `${demographics.gender}-testosterone`;
    let crmKey = `results-${configKey}-histogram`;
    let prefixedGeneticScore = result['value'] > 0 ? `+${result['value'].toFixed(2)}` : `${result['value'].toFixed(2)}`;
    let polyScore = result['estScore'];
    let prefixedPolyScore = polyScore > 0 ? `+${polyScore.toFixed(0)}` : `${polyScore.toFixed(0)}`;
    
    let graphComment, normalComment, snpAmt, sd;
    if (configKey === 'male-testosterone') {
      normalComment = "The normal range for testosterone is between 250-950 ng/dL for men.";
      snpAmt = "in males using 122 genetic markers";
      graphComment = "The graph shows a histogram of scores from 194,453 men";
      sd = "37";
    } else if (configKey === 'female-testosterone') {
      normalComment = "The normal range for testosterone is between 10-60 ng/dL for women.";
      snpAmt = "in females using 241 genetic markers";
      graphComment= "The graph shows a histogram of scores from 230,454 women";
      sd = "4";
    };

    testComponent =
    <div>
      <div className="card">
        <BetaHistogram result={result} crmKey={crmKey} crm={crm}/>
        <p>Based solely on your genetics, your score for testosterone levels is <strong>{prefixedGeneticScore}</strong> ng/dL compared to the median.</p>
        <p><TagPercentile result={result} tag={configKey} polygenic={true} usesUKData={false}/></p>
        <p>When combined with your height, weight, age and sex, your predicted testosterone score is <strong>{prefixedPolyScore}</strong> (+/- {sd}) ng/dL  (adjusted for your physical stats) compared to the median.</p>
        <p className="mb-0">{normalComment}</p>
      </div>
      <div className="card">
        <p>AxGen has developed an algorithm to predict testosterone levels {snpAmt} from <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/32042192/">Ruth et al., 2020</a> as well as information about your height, weight, age and sex.</p>
        <p className="mb-0">{graphComment}. The genetic score will be within ~{sd} ng/dL of your testosterone levels 68% of the time. The correlation of the testosterone combined score with observed levels of testosterone is 34%. (These statistics were derived by comparing the genetic score to the measured testosterone levels from a large number of people, and calculating the standard deviation and the correlation). </p>
      </div>
    </div>
  } else {
    if (userIsMe) {
      testComponent = 
      <div className="card">
          <p>In addition to genetic factors, age, weight, height and sex are required to calculate your testosterone levels, but you have not yet provided this information.</p>
          <p className="mb-0">We invite you to input your demographic information <Link to="/settings">here</Link> for a comprehensive analysis of your testosterone levels.</p>
      </div>
    } else {
      testComponent = 
      <div className="card">
          <p>In addition to genetic factors, age, weight, height and sex are required to calculate testosterone levels, but this person does not have this information on record.</p>
          <p className="mb-0">The person whose profile you are viewing has not input their demographic information. Please ask them to do so in their Account Settings in order to view a comprehensive analysis of testosterone levels.</p>
    </div>
    };
  };

  return (
    <div>
      <div className='title-margins'>
        <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Testosterone Levels</h2>
      </div>
      { testComponent }
    </div>
  );
};

export default TestosteroneResults;
