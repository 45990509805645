import React from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import FAQ from '../components/faq';


const HeroPage = ({ location }) => {
    const [version, setVersion] = React.useState("");

    React.useEffect(() => {
        let path = location.pathname;
        // determines version of the page
        if (path === '/hero' || path === '/hero/') {
            setVersion('/hero');
        } else if (path === '/hero2' || path === '/hero2/') {
            setVersion('/hero2');
        } else if (path === '/hero3' || path === '/hero3/') {
            setVersion('/hero3');
        };
    }, []);

    // renders content based on page version
    let heroChecklist, heroOverview, faq1, faq2;
    if (version === '/hero') {
        heroChecklist =
            <ol>
                <li><b>Click on this <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfu9msStHb5B6_ht4lmHQP5dri-jSh4vp2uq2AaAf-f6A1tKw/viewform?usp=sf_link">link</a> to sign up for the AxGen HERO project.</b></li>
                <li>We will mail you a spit kit. Follow the instructions and then drop it in the mail.</li>
                <li>We will then send you a $50 Amazon gift card as compensation for your time.</li>
            </ol>
        heroOverview =
            <div>
                <div className="hero-card">
                    <p>We have found in our work to date that elite athletes make up a unique group of humans, exhibiting qualities such as resilience, endurance and strength that are in part governed by genetic makeup. If the genetic basis of these positive qualities could be identified, we realized that it would provide the basis for new therapies for individuals.</p>
                    <p className="mb-0">As a result, Axgen has launched the AxGen Human Exceptional Resilience Optimization (HERO) databank, to identify the genetic basis of the positive physical traits athletes have.</p>
                </div>
                <div className="hero-card">
                    <p>We are inviting you to participate in the HERO research project at AxGen. By participating, you will give back to: 1) the greater community, by helping cure chronic diseases like sarcopenia and metabolic disease, among others, and 2) the sport of football, by helping reduce injuries for current football players, as follows:</p>
                    <ol>
                        <li>Chronic diseases: Some of your genes are partly responsible for your success as an athlete (HERO genes). For instance, your explosive strength required to become an NFL player will also guard you from muscle weakness diseases in old age, such as sarcopenia. At AxGen, we want to find new ways to cure sarcopenia by studying NFL players, and then use that knowledge to develop therapies for the civilian population.</li>
                        <li>Football injury risk: AxGen will compare your history of injury risk during your career to your DNA to find genes predisposing you to injuries. We can then use that knowledge to inform current players. The player could undergo genetic testing, find out their risk of injuries, and then work with their trainer to prevent those injuries before they occur.</li>
                    </ol>
                    <p className="mb-0">For participating in the HERO Project, AxGen will provide $50 compensation for your time (~15 min) to provide a saliva sample and fill out a questionnaire.</p>
                </div>
            </div>
        faq1 =
            <FAQ
                header="Is my data private?"
                body={
                    <span>Your data will be de-identified which means that your personal identity will not be used in the analysis or shared with anyone else. Your personal info will only be used to verify that you are a former player and to mail you a saliva kit.</span>
                }
            />
        faq2 = 
            <FAQ
                header="Sounds great, can I recommend my former NFL teammates?"
                body={
                    <span>We are limiting this initial sign up to 50 NFL players. Contact Jim Kovach (<a href="mailto:jim@jameskovach.com">jim@jameskovach.com</a>) to see if there is room to put your former teammates and other former NFL ballplayers on the list.</span>
                }
            />
    } else if (version === '/hero2') {
        heroChecklist =
            <ol>
                <li><b>Click on this <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfu9msStHb5B6_ht4lmHQP5dri-jSh4vp2uq2AaAf-f6A1tKw/viewform?usp=sf_link">link</a> to sign up for the AxGen HERO project.</b></li>
                <li>Fill out the questionnaire (~15 min).</li>
                <li>At present, we are only collecting questionnaires, but eventually we will send you a saliva kit to collect your DNA.</li>
            </ol>
        heroOverview =
        <div>
            <div className="hero-card">
                <p>We have found in our work to date that elite athletes make up a unique group of humans, exhibiting qualities such as resilience, endurance and strength that are in part governed by genetic makeup. If the genetic basis of these positive qualities could be identified, we realized that it would provide the basis for new therapies for individuals.</p>
                <p className="mb-0">As a result, Axgen has launched the AxGen Human Exceptional Resilience Optimization (HERO) databank, to identify the genetic basis of the positive physical traits athletes have.</p>
            </div>
            <div className="hero-card">
                <p>We are inviting you to participate in the HERO research project at AxGen. By participating, you will give back to: 1) the greater community, by helping cure chronic diseases like sarcopenia and metabolic disease, among others, and 2) the sport of football, by helping reduce injuries for current football players, as follows:</p>
                <ol>
                    <li>Chronic diseases: Some of your genes are partly responsible for your success as an athlete (HERO genes). For instance, your explosive strength required to become an NFL player will also guard you from muscle weakness diseases in old age, such as sarcopenia. At AxGen, we want to find new ways to cure sarcopenia by studying NFL players, and then use that knowledge to develop therapies for the civilian population.</li>
                    <li>Football injury risk: AxGen will compare your history of injury risk during your career to your DNA to find genes predisposing you to injuries. We can then use that knowledge to inform current players. The player could undergo genetic testing, find out their risk of injuries, and then work with their trainer to prevent those injuries before they occur.</li>
                </ol>
                <p className="mb-0">The list of players that have already agreed to participate as HEROs include Ronnie Lott, Jim Kovach, John Farquhar, Glyn Milburn, Hoby Brenner, Steve Bono, John Harty, John Macaulay, Randy Cross and many others. The NFL Retired Players United and the San Francisco 49ers Alumni Association are two organizations that are participating in recruiting for the HERO project.</p>
            </div>
        </div>
        faq1 =
            <FAQ
                header="Is my data private?"
                body={
                    <span>Your data will be de-identified which means that your personal identity will not be used in the analysis or shared with anyone else. Your personal info will only be used to verify that you are a former player.</span>
                }
            />
    } else if (version === '/hero3') {
        heroChecklist =
            <ol>
                <li><b>Click on this <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfoi2LL4MhO6BY67IBwYLbPd84sge30H65NhaV04mb4DnNUYw/viewform?usp=sf_link">link</a> to sign up for the AxGen HERO project.</b></li>
            </ol>
        heroOverview =
            <div>
                <div className="hero-card">
                    <p>Elite athletes make up a unique group of humans, exhibiting qualities such as strength, endurance, and resilience to injury that are in part governed by their genetic makeup. If the genetic bedrock of these exceptional qualities could be identified, they could have massive implications for our understanding of musculoskeletal diseases and illnesses.</p>
                    <p>For example, the explosive strength required to be an elite power athlete also guards from muscle weakness such as sarcopenia, and endurance athletes are less likely to get type 2 diabetes, heart disease or become obese. We believe we can find new ways to prevent or cure these diseases by finding beneficial mutations in the genomes of elite athletes, and then using that knowledge to develop therapies for the civilian population.</p>
                    <p className="mb-0">With the hopes of curing many diseases and illnesses, AxGen has launched the Human Exceptional Resilience Optimization (HERO) project. We are inviting you to participate in the HERO research project at AxGen. By participating, you will give back to the greater community, by helping cure chronic diseases like sarcopenia, heart disease and diabetes, among others.</p>
                </div>
                <div className="hero-card">
                    <p className="mb-0">Within a week of launching HERO to current and former NFL athletes, we’ve had hundreds of former players sign up including many Pro Bowlers and players in the Hall of Fame. In our next step, we are expanding the HERO invitation to elite athletes in all sports. If you or someone you know has played sports at a high level (Professional Athlete, Olympic Athlete, NCAA Division 1 Varsity, All-American or equivalent), please consider joining HERO.</p>
                </div>
            </div>
        faq1 =
            <FAQ
                header="Is my data private?"
                body={
                    <span>Your data will be de-identified which means that your personal identity will not be used in the analysis or shared with anyone else. Your personal info will only be used to verify that you are a former player.</span>
                }
            />
    };


    return (
        <div>
            <Navbar stateless={true}/>
            <div className="container mt-4">
                <div className="hero-header">
                    <h2 className="noflair">AxGen HERO Project</h2>
                </div>
                <section className="hero-section">
                    <div className="section-title title-break">
                        <span>How to be a HERO</span>
                    </div>
                    <div className="hero-card">
                        { heroChecklist }
                        <p className="mb-0">Thank you very much for helping us with the AxGen HERO project!</p>
                    </div>
                    { heroOverview }
                </section>
                <section className="hero-section">
                    <div className="section-title title-break">
                        <span>Overview</span>
                    </div>
                    { heroOverview }
                </section>
                <section className="hero-section">
                    <div className="section-title title-break">
                        <span>FAQ</span>
                    </div>
                    <div className="hero-card">
                        { faq1 }
                        <FAQ
                            header="Is my data secure?"
                            body={
                                <span>Your data is stored in a HIPAA secure location in the Amazon cloud.</span>
                            }
                        />
                        <FAQ
                            header="Will you share my data with third parties?"
                            body={
                                <span>All personal information will be removed from your file. AxGen will analyze your data along with other elite athletes to find both HERO genes and injury risk markers. AxGen may enter agreements with third parties to jointly analyze data in the HERO databank.</span>
                            }
                        />
                        <FAQ
                            header="Will I get to see my own genome?"
                            body={
                                <span>Presently, AxGen is not able to return your genetic information to you. In the future, we may be able to do so in which case you will be given an account at AxGen to view your own data.</span>
                            }
                        />
                        { faq2 }
                    </div>
                </section>
            </div>
            <br/>
            <Footer />
        </div>
    );
};

export default HeroPage;
