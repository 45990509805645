import React from 'react';
import GeneTable from '../../components/gene-table';

const AATResults = (props) => {
    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Alpha-1 Antitrypsin Deficiency</h2>
            </div>
            <div className="card">
                <GeneTable tag='aat' cols={['gene'] } />
            </div>
            <div className='title-break'>
                <span>Comments</span>
            </div>
            <div className="card">
                <p>rs28929474 is also known as PiZ with T as the effect allele.</p>
                <ol>
                    <li>Individuals with two T alleles (T/T) are at risk for Alpha-1 Antitrypsin Deficiency. This result should be verified using a clinical test designed specifically for Alpha-1 Antitrypsin Deficiency.</li>
                    <li>Individuals with one T allele (T/C) are carriers. If their partner is also a heterozygous carrier for a <i>SERPINA1</i> mutation, then there is a 25% chance that their children will be homozygous and be at risk for Alpha-1 Antitrypsin Deficiency.</li>
                </ol>
                <p>rs17580 is also known as PiS with T as the effect allele.</p>
                <ol>
                    <li>Those with the PiS/PiZ combination (genotype rs17580(T/A) rs28929474(T/C)) have an increased risk of developing lung disease, particularly if you smoke.</li>
                </ol>
                <p>rs28929474 (PiZ) and rs17580 (PiS) are the most common mutations in <i>SERPINA1</i>. Other <i>SERPINA1</i> mutations are much rarer. AxGen does not test for rare variants in <i>SERPINA1</i>, and so you may carry a rare mutation in <i>SERPINA1</i> even if you have a negative result for rs28929474. </p>
            </div>
        </div>
    );
};

export default AATResults;