import React from 'react';

const CAD_YeIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Coronary Artery Disease</h2>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/CAD.png" height={300}/> 
        <p>Coronary artery disease (CAD) occurs when the arteries of the heart cannot deliver enough blood to the heart. The reduction in oxygen-rich blood flow to the heart muscle is caused by a build up of atherosclerotic plaque in the arteries of the heart. Coronary artery disease is the leading cause of death in the United States, and with over 18 million diagnosed cases of coronary heart disease, it is the most common type of heart disease in the US. </p>
      </div>
      
        <div className='title-break'>
          <span>Causes and Risk Factors</span>
        </div>
        <div className="card">
        <p>The most common cause of CAD is atherosclerosis, a condition in which plaque builds up on the walls of the arteries. This buildup can eventually block the arteries, leading to a heart attack. Other factors that contribute to CAD include smoking, high blood pressure, high cholesterol, diabetes, obesity, and a sedentary lifestyle. Genetics plays a role in the development of CAD. People with a family history of heart disease are more likely to develop the condition. Other risk factors include age, gender, and race. Men are more likely to develop CAD than women, and African Americans are more likely to develop CAD than other races. </p>
        <p><strong>Genetics:</strong> The results page shows a genetic test for CAD that scans your entire genome for risk. This test shows that certain people have &gt;5 fold higher risk than others. </p>
        </div>
        <div className='title-break'>
          <span>Symptoms</span>
        </div>
        <div className="card">
          <p>Symptoms of CAD are:</p>
        <ul>
          <li>Chest pain (angina)</li>
          <li>Shortness of breath</li>
          <li>Fatigue</li>
          <li>Irregular heartbeats</li>
          <li>Dizziness, nausea, sweating, palpitations</li>
        </ul>
        </div>
        <div className='title-break'>
          <span>Diagnosis</span>
        </div>
        <div className="card">
        <p>Coronary heart disease is diagnosed through a variety of tests and procedures. The most common test used to diagnose coronary heart disease is an electrocardiogram (ECG or EKG). This test measures the electrical activity of the heart and can detect any abnormalities in the heart's rhythm. Other tests that may be used to diagnose coronary heart disease include an echocardiogram, which uses sound waves to create images of the heart; a stress test, which measures how the heart responds to physical activity; and a coronary angiogram, which uses X-rays to create images of the heart's blood vessels. Blood tests may also be used to measure levels of cholesterol, triglycerides, and other substances that can indicate the presence of coronary heart disease.</p>
        <p>Current clinical risk calculators are, however, failing to identify up to 40% of high-risk individuals. As prevention has broader benefits than treating CAD, improving identification of at-risk individuals using genetic testing would be an important benefit to public health.</p>
        </div>
        <div className='title-break'>
          <span>Prevention</span>
        </div>
        <div className="card">
        <p>Preventing coronary heart disease involves avoiding established controllable risk factors. Prevention includes physical exercise, maintaining healthy weight, maintaining healthy blood pressure, eating a balanced diet, lowering cholesterol levels, avoiding smoking, and limiting alcohol consumption.</p>
        <p>Getting high levels of physical exercise may be the best single way to reduce the risk of CAD, reducing risk by ~30% (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4979358/">Kyu et al., 2013</a>). The best kind of exercise for prevention is aerobic exercise, like walking, jogging, or swimming. A diet that is high in fruits and vegetables decreases the risk of cardiovascular disease. Vegetarian, Mediterranean, and high fiber diets are all associated with lower risk of cardiovascular diseases (<a href="https://pubmed.ncbi.nlm.nih.gov/19378874/">Walker and Reamy, 2009</a>). </p>
        <p>Medications can be used to lower cholesterol and blood pressure, thereby reducing the risk of heart attack and stroke.</p>
      </div>
      </div>

  )
}

export default CAD_YeIntro;