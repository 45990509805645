import React from 'react';
import GeneTable from '../../components/gene-table';
import RiskGraph from '../../components/risk-graph';
import Collapsible from 'react-collapsible';

class PlantarResults extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Plantar Fasciitis</h2>
          <h5>[<a href="https://www.ncbi.nlm.nih.gov/pubmed/29534260">Kim et al., 2018</a>]</h5>
        </div>
        <div className="card p-1">
          <Collapsible trigger="Tutorial">
          <p className='intro-vid'><iframe title="Results" width="560" height="315" src="https://www.youtube.com/embed/YP9qzOKpU6A?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
          </Collapsible>
        </div>
        <div className="card">
          <RiskGraph tag='plantar-fasciitis' />
          <p>The plantar fasciitis genetic markers were identified using a European population. When these markers were analyzed in African, Asian and Latin American ancestry groups, no significant difference was found (<a href="https://www.ncbi.nlm.nih.gov/pubmed/29534260">Kim et al, 2017</a>).</p>
        </div>
        <div className="card">
          <p>The genetic markers for plantar fasciitis have a relatively small effect. Still, this information may be valuable because plantar fasciitis is common, especially for endurance athletes.  </p>
          <p>There are two SNPs associated with plantar fasciitis. The T allele of rs32652 has an allele frequency of 24% and leads to a 6% decreased risk of injury. The T allele of rs62051384 has an allele frequency of 37% and is associated with a 3.9% increased risk. </p>
          <GeneTable tag='plantar-fasciitis' cols={[ 'alleleFreq', 'relativeRisk'] } />
        </div>        
      </div>

    )
  }
}

export default PlantarResults;
