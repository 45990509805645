import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';

export const IBDUcoll = ({ userName, nameEndsWithS, uploadID, width }) => {
  // this page contains the results of multiple tests
  const { crm, uploads } = useSelector((state) => ({
    crm: state.crm,
    uploads: state.results.uploads,
  }));

  const getGraph = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    const crmKey = `results-${tag}-histogram`;
    return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
  };

  const getPercentile = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    return <TagPercentile result={tagResult} tag={tag} />;
  };

  return (
    <div>
      <div className='title-margins'>
        <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for ulcerative colitis</h2>
      </div>
      <div className="card">
        <p>The AxGen test for ulcerative colitis uses a polygenic risk score with 756 genetic markers from <a href="https://pubmed.ncbi.nlm.nih.gov/35324888/">Tanigawa et al., 2022</a>. </p>
      </div>
      <div className="card">
        {getGraph("ucoll")}
        <p>The x-axis shows risk for ulcerative colitis. To use this graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic score for risk for ulcerative colitis. The bin containing your score tells you how many other people have a score like yours. <b>If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly higher risk for ulcerative colitis.</b>  </p>
        <p className="mb-0">{getPercentile("ucoll")}</p>
        <br></br>
        <img src="/assets/imgs/results/ucoll.png" height={400} className="overview-img" alt="" />
        <p>The Figure shows how your genetic score affects your risk for ulcerative colitis. For instance, if your percentile is below 10%, your risk for ulcerative colitis is 2.3-fold higher than the average person. If the prevalence of ulcerative colitis for the entire US is about 0.3%, then a genetic score below 10% indicates that you have a 0.69% chance for ulcerative colitis (2.3 fold x 0.3% = 0.69%). A 2.3-fold increased risk for ulcerative colitis is significant, but your chance of getting ulcerative colitis is still low because the disease is rare.  </p>
      </div>
      <div className='title-break'>
        <span>The genetic test has a <b>moderate</b> effect on your risk for ulcerative colitis.</span>
      </div>
      <div className="card">
        <p>The impact of the ulcerative colitis genetic score on an entire population is small because only 10% of people are affected. However, for the subset of people with a low score, the test is significant because it indicates a 2.3-fold increased risk for the disease. </p>
      </div>
    </div>
  );
};

export default IBDUcoll;
