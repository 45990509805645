import React, { useState, useEffect } from 'react';
import Alerts from './alerts';
import { validateEmail } from '../utils/components';
import * as Actions from '../actions';
import { useCustomDispatch } from '../utils/customHooks';


const EmailOrderForm = ({ userRole, alerts, isInPilot=false, numPreloadedCodes=0 }) => {
    const [email, setEmail] = useState("");
    const [emailList, setEmailList] = useState([]);
    const [disabled, disableSubmit] = useState(false);

    const orderKitsListDispatch = useCustomDispatch(Actions.orderCodesEmailListThunk);
    const sendCodeDispatch = useCustomDispatch(Actions.sendCodeThunk);

    // adds email to list while preventing duplicates, invalid emails, and empty vals
    const addEmailToList = (e) => {
        e.preventDefault();
        Actions.clearAlerts();
        if (email === "") return;
        if (!validateEmail(email)) {
            Actions.addAlert('order-by-email', 'error', 'Failed to add email. Invalid email.');
            return;
        };
        if (userRole === 'team-manager' && emailList.length === numPreloadedCodes) {
            Actions.addAlert('order-by-email', 'error', 'Failed to add email. You are out of preloaded codes. To get more codes, please contact us at contact@axgen.us');
            return;
        };
        
        if (emailList.indexOf(email) === -1) {
            const duped = emailList.slice();
            duped.push(email);
            setEmailList(duped);
            setEmail("");
        } else {
            Actions.addAlert('order-by-email', 'error', 'Failed to add email. Email already in the list.');
            setEmail("");
        };
    };

    const deleteEmail = (idx) => {
        Actions.clearAlerts();
        const duped = emailList.slice();
        duped.splice(idx, 1);
        setEmailList(duped);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        Actions.clearAlerts();
        disableSubmit(true);
        if (userRole === 'team-manager') {
            sendCodeDispatch(emailList);
        } else {
            orderKitsListDispatch("*", emailList);
        };
    };

    useEffect(() => {
        if (Object.keys(alerts).length !== 0) {
          disableSubmit(false);
          const alertKey = 'order-by-email';
          const alertSuccess = (alerts[alertKey] || {});
          if (alertSuccess.hasOwnProperty('success')) setEmailList([]);
        };
      }, [alerts]);
    
    const codeWord = emailList.length > 1 ? `${emailList.length} CODES` : emailList.length === 0 ? "CODE(s)" : `${emailList.length} CODE`;
    const btnTxt = disabled ? `EMAILING ${codeWord}` : `EMAIL ${codeWord}`;

    return (
        <div className="form">
            { 
                ((isInPilot || numPreloadedCodes === 0) && userRole === 'team-manager')  &&
                <div className="text-center">
                    <hr/>
                    <p><strong>You have {numPreloadedCodes - emailList.length} codes for kits left</strong></p>
                    <p>Need more codes? Please <a href="mailto:contact@axgen.us">contact us</a>.</p>
                    <hr/>
                </div>
            }
            <div>

            
                <form onSubmit={(e) => addEmailToList(e)}>
                    <div className="mb-2">
                        <strong>Add Emails for Kits</strong>
                        <p className="mt-2">Add emails to the form below one at a time. You can add the emails either by clicking "Add Email" or by pressing "Return" or "Enter" on your keyboard.</p>
                        <p>To remove an email from the list, simply click on it.</p>
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="email"
                            className="form-control mb-0"
                        />
                        <div className="input-group-append">
                            <input
                                type="submit"
                                className="btn btn-outline-axgen"
                                value="Add Email for Kit"
                            />
                        </div>
                    </div>
                </form>
                <hr />
                {
                    emailList.length === 0 ? null : (
                        <ul className="email-list-field">
                            {
                                emailList.map((eMail, idx) => <EmailListItem key={eMail} idx={idx} eMail={eMail} deleteEmail={(idx) => deleteEmail(idx)} />)
                            }
                        </ul>    
                    )
                }
            </div>
            <button
                className="form-control btn btn-axgen mb-0" 
                type="button"
                disabled={emailList.length === 0 || disabled}
                onClick={(e) => handleSubmit(e)}>
                <span>{btnTxt}</span>
            </button>
            <Alerts tag='order-by-email' level='error' />
            <Alerts tag='order-by-email' level='success' />
        </div>
    );
};


const EmailListItem = ({ eMail, deleteEmail }) => {
    return (
        <li onClick={deleteEmail}>
            <p>{eMail}</p>
            <div className="icon-container">
              <i className="far fa-times-circle"></i>
            </div>
        </li>
    );
};


export default EmailOrderForm;