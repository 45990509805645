import { combineReducers } from 'redux';
import adminReducer from './admin-reducer';
import alertReducer from './alert-reducer';
import consultationReducer from './consultation-reducer';
import crmReducer from './crm-reducer';
import orderReducer from './order-reducer';
import resultReducer from './result-reducer';
import sessionReducer from './session-reducer';
import stengthReducer from './strength-reducer';
import uiReducer from './ui-reducer';
import permissionsReducer from './permissions-reducer';


const reducers = combineReducers({
  admin: adminReducer,
  alerts: alertReducer,
  consultations: consultationReducer,
  crm: crmReducer,
  orders: orderReducer,
  results: resultReducer,
  permissions: permissionsReducer,
  session: sessionReducer,
  ui: uiReducer,
  strength: stengthReducer
});

export default reducers;
