import React from 'react';
import Chart from 'react-apexcharts';
import { loadCRMThunk } from '../actions';
import { findBucket } from '../utils/results';
import { connect } from 'react-redux';

// This component is used to create the histograms shown on vitamin and performance marker results pages.
// Example:
//    <BetaHistogram crm={this.props.crm} result={this.props.result} crmKey='results-vitamin-b12-histogram' />

// Used to create x-axis caption. To expand, add=> crmKey: caption
const betaTitles = {
  'results-coffee-consumption-histogram': 'Cups coffee/day',
  'results-vitamin-b12-histogram': 'Genetic Change in Vitamin B12 (pmol/L)',
  'results-mg-histogram': 'Genetic Change in Magnesium (mmol/L)',
  'results-male-testosterone-histogram': 'Testosterone Genetic Score (ng/dL)',
  'results-female-testosterone-histogram': 'Testosterone Genetic Score (ng/dL)',
  'results-iron-histogram': 'Iron Score (μmol/L)',
  'results-ferritin-histogram': 'Ferritin Score (ng/mL)',
  'results-transferrin-histogram': 'Transferrin Score (mg/dL)',
  'results-transferrin-saturation-histogram': 'Transferrin Saturation Score (%)',
  'results-rotator-cuff-histogram': 'Rotator Cuff Injury Risk',
  'results-PUFA-histogram': '% DPA/total fatty acids',
  'results-vitD-histogram': 'Vitamin D (nmol/L)',
  'results-vitC-histogram': 'Vitamin C (SD)',
  'results-Ca-histogram': 'Ca (mmol/L)',
  'results-phos-histogram': 'Phosphorus (mg/dL)',
  'results-celiac-histogram': 'Gluten genetic score',
  'results-strength-histogram': 'Muscle Strength genetic score',
  'results-HRrest-histogram': 'Resting Heart Rate (bpm)',
  'results-HRrec-histogram': 'Heart Rate Recovery (bpm)',
  'results-HRex-histogram': 'Heart Rate during Exercise (bpm)',
  'results-HRV-histogram': 'Heart Rate Variability Score',
  'results-glutenomics-histogram': 'Gluten genetic score',
  'results-osteo-histogram': 'Osteoporosis genetic score',
  'results-IBD-histogram': 'IBD genetic score',
  'results-ucoll-histogram': 'Ulcerative Colitis genetic score',
  'results-crohn-histogram': 'Crohns disease genetic score',
  'results-bone-histogram': 'Bone Mineral Density Genetic Score',
  'results-T2D_Ye-histogram': 'Type 2 Diabetes Genetic Score',
  'results-adjusted_T2D_Ye-histogram': 'Type 2 Diabetes Genetic Score (including Risk Factors)',
};

// used to determine if the graphType is median or not
const medianGraphs = ['results-HRV-histogram','results-HRrec-histogram','results-HRrest-histogram','results-HRex-histogram','results-iron-histogram', 'results-transferrin-saturation-histogram', 'results-transferrin-histogram', 'results-ferritin-histogram', 'results-male-testosterone-histogram', 'results-female-testosterone-histogram'];


class BetaHistogram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  };

  componentDidMount() {
    this.props.loadCRM(this.props.crmKey);
  };

  getHistogramConfigs(score, crm, binCallback, isNumpyOffset) {
    const { bins, counts } = crm;
    let index = findBucket(score, bins);
    let totalSum = counts.reduce((acc, el) => acc + el);
    let newCounts = counts.map(el => (el / totalSum) * 100);
        
    // the cb provides special formatting for bins
    let newBins = bins.map(el => binCallback(el));
    
    // offset to correct xaxis tick mark placement
    if (isNumpyOffset) {
      newCounts.push(0);
    };

    return [index, newCounts, newBins];
  };

  // dynamically finds the yaxis max and min
  findYMinMax(counts) {
    let min = 0;
    let max = counts[0];
    counts.forEach(el => {
      if (el > max) {
        max = el;
      };
    });
    // the yaxis max needs to be +3 larger than the max value in counts
    max += 3;
    return [min, max];
  };

  // prepares the graph
  componentDidUpdate(prevProps) {
    
    const width = this.props.hasOwnProperty("width") ? this.props.width : window.innerWidth;
    const crmHist = this.props.crm[this.props.crmKey];
    const prevCRMHist = prevProps.crm[prevProps.crmKey];

    if (crmHist && (crmHist !== prevCRMHist || this.props.result.value !== prevProps.result.value)) {

      // provides conditional-based formatting
      let binCallback;
      let isRotated = true;
      let tooltipHeader = 'Genetic Score Range';
      let isNumpyOffset = true;
      let userVal = this.props.result.value;
  
      if (medianGraphs.indexOf(this.props.crmKey) > -1) {
        binCallback = function(el) { return el > 0 ? `${el.toString()}` : el.toString(); };
        tooltipHeader = 'Score Range';
        isNumpyOffset = false;
        isRotated = width < 768 ? true : false;
        // sets the number of sig figs in the x-axis of the histogram. 
        if (this.props.crmKey === 'results-HRrest-histogram' && width < 992) isRotated = true;
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-Mars_HOsteo-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-male-testosterone-histogram') {
        binCallback = function(el) { return parseFloat(el).toPrecision(4).toString();};
      } else if (this.props.crmKey === 'results-Mars_HThy-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-Mars_KOsteo-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-mg-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-gout-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-adjusted_gout-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-POAG-histogram') {
        binCallback = function(el) { return el.toPrecision(3).toString(); };
      } else if (this.props.crmKey === 'results-adjusted_POAG-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-asthma-histogram') {
        binCallback = function(el) { return el.toPrecision(3).toString(); };
      } else if (this.props.crmKey === 'results-adjusted_asthma-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-T2D_Ye-histogram') {
        binCallback = function(el) { return el.toPrecision(3).toString(); };
      } else if (this.props.crmKey === 'results-adjusted_T2D_Ye-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-CAD_Ye-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-adjusted_CAD_Ye-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-adjusted_ThC-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-adjusted_Mars_ColCan-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-adjusted_Mars_arth-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-AF_Ye-histogram') {
        binCallback = function(el) { return el.toPrecision(3).toString(); };
      } else if (this.props.crmKey === 'results-adjusted_AF_Ye-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-adjusted_AAA-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-Ca-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-PUFA-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-vitC-histogram') {
        binCallback = function(el) { return el.toPrecision(3).toString(); };
      } else if (this.props.crmKey === 'results-vitD-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-phos-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-celiac-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-glutenomics-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-rotator-cuff-histogram') {
        binCallback = function(el) { return `${(el + 1).toFixed(2)}`; };
      } else if (this.props.crmKey === 'results-strength-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-HRrest-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-HRex-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-HRrec-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-HRV-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-IBD-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-ucoll-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-crohn-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-crohn-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
      } else if (this.props.crmKey === 'results-Yu_CKD-histogram') {
        binCallback = function(el) { return el.toPrecision(3).toString(); };
      } else if (this.props.crmKey === 'results-adjusted_Yu_CKD-histogram') {
        binCallback = function(el) { return el.toPrecision(2).toString(); };
        tooltipHeader = 'Injury Risk Range';
      } else {
        binCallback = function(el) { return el.toFixed(1).toString(); };
      };
      const [userIndex, normalizeCounts, newBins]  = this.getHistogramConfigs(userVal, crmHist, binCallback, isNumpyOffset);

      // xaxis title
      // NOTE: Variable names (betaTitle and betaXAxisLabel) are plural at the top of the page and singular here
      const betaTitle = (this.props.crmKey in betaTitles) ? betaTitles[this.props.crmKey] : 'Beta';
      
      // min and max of yaxis      
      const [min, max] = this.findYMinMax(normalizeCounts);

      const hist = {
        options: {
          chart: {
            height: "100%"
          },
          plotOptions: {
            bar: {
              columnWidth: "90%",
            }
          },
          xaxis: {
            title: {
              text: betaTitle,
              offsetY: width < 768 ? 27 : 0,
              style: {
                cssClass: 'apexcharts-axis-titles',
                fontSize: "16px"
              },
              // TODO: Currently there is some issue with the xaxis label and titles overlapping. Using offset leads to the title being cut off on the bottom. Temporary solution to remove labels from collision areas but we should find a permanent solution.
            },
            labels: {
              
              show: true,
              style: {
                cssClass: 'apexcharts-axis-labels',
                fontSize: "14px"
              },
              rotateAlways: isRotated,
              rotate: -45,
              formatter: function(value, opts, i) {
                if (width < 768 && (i % 2 !== 0 && i !== 0)) {
                  return "";
                } else {
                  return value;
                };
              }
            },
            type: 'category',
            categories: newBins
          },
          yaxis: [{
            title: {
              text: "% of Population",
              style: {
                cssClass: 'apexcharts-axis-titles',
                fontSize: "16px"
              }
            },
            labels: {
              style: {
                cssClass: 'apexcharts-axis-labels',
                fontSize: "14px"
              },
              formatter: v => v.toPrecision(2).toString() + '%',
              hideOverlappingLabels: false
            },
            tickAmount: 4,
            min: min,
            max: max
          }],
          dataLabels: {
            enabled: false
          },
          annotations: {
            xaxis: [{
              x: newBins[userIndex],
              borderColor: '#ff0000',
              opacity: 1.0,
              label: {
                text: 'You',
                borderWidth: 0,
                style: {
                  background: 'transparent',
                  color: '#ff0000',
                  fontSize: "14px",
                  cssClass: "apexcharts-annotations"
                }
              }
            }]
          },
          legend: {
            show: false
          },
          tooltip: {}
        },
        series: [{
          name: '% of population',
          data: normalizeCounts
        }]
      };
      
      hist.options.tooltip.fixed = width < 768 ? 
        { enabled: true, position: 'topRight', offsetY: -26 }
        : { enabled: false }

      hist.options.tooltip.custom = function ({ series, seriesIndex, dataPointIndex, w }) {
        const percentile = series[0].reduce((agg, v, i) => (i < dataPointIndex) ? agg + v : agg, 0.00);
        let scoreRange = null;
        const lastBinCondition = isNumpyOffset ? w.config.xaxis.categories.length - 2 : w.config.xaxis.categories.length - 1;
        if (dataPointIndex === 0) {
          scoreRange = '<' + w.config.xaxis.categories[dataPointIndex+1];
        } else if (dataPointIndex === lastBinCondition) {
          scoreRange = '>' + w.config.xaxis.categories[dataPointIndex];
        } else {
          scoreRange = w.config.xaxis.categories[dataPointIndex] + ' to ' + w.config.xaxis.categories[dataPointIndex+1];
        };
        return '<div class="bog-graph-tooltip">' +
          `<div class="tooltip-header"> ${tooltipHeader}: ` + scoreRange + '</div>' +
          '<div>Percentile Range: ' + percentile.toFixed(1) + ' to ' + ( percentile + series[0][dataPointIndex] ).toFixed(1) + '%</div>' +
          '</div>';
      };
      this.setState({ hist });
    };
  };

  render() {
    return (
      <div className="histogram-container b12-hist">
        {!this.state.hist ? null :
        <div>
          <Chart options={this.state.hist.options} series={this.state.hist.series} type="bar" height={300} />
          <p className="graph-comment" align="center">**Hover over the graph for more information.**</p>
        </div>
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loadCRM: (crmKey) => dispatch(loadCRMThunk(crmKey))
});


export default connect(null, mapDispatchToProps)(BetaHistogram);
