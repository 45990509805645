import { LOAD_TEAM, LOAD_RESULTS, LOAD_SNPS } from '../actions';
import { getPercentileText, getPercentileText2 } from '../utils/results';
import { getPercentileLabel } from '../pages/results';

const defaultState = {
  uploads: {},
  team: {},
  snps: {}
};

const resultsConfig = require('../../json/results-config.json');

// These are default values used to compare user-beta and classify a particular tag result
const thresholdDefaults = {
  'value': {
    'low': -Infinity,
    'high': Infinity
  },
  'risk': {
    'low': 0,
    'high': 0.1
  }
};

const colorMap = {
  'low': 'green',
  'high': 'red',
  'neutral': 'gray'
};

const invertColorMap = {
  'low': 'red',
  'high': 'green',
  'neutral': 'gray'
};

// add all performance tags here (tags that have percentile or relative_risk)  
const performanceTags = new Set(['Mars_HThy', 'ThC', 'gout', 'POAG', 'Yu_CKD', 'osteo', 'asthma', 'Mars_arth', 'T2D_Ye', 'CAD_Ye', 'AF_Ye', 'Mars_ColCan', 'AAA', 'FEV', 'glutenomics', 'testosterone', 'vitD', 'Mars_HOsteo', 'Mars_KOsteo', 'vitC', 'strength', 'vitamin-b12', 'mg', 'phos','HRrest', 'prostate', 'Ca']);

// Short labels are used on the team page
// Long labels are used on the results-list page and in the header of the results-viewer page
// naming conventions should be kept consistent, short labels are always all lower case and long labels have the first word capitalized but not the following words
const labelMap = {
  'health': {
    'short': {
      'low': 'low risk',
      'high': 'high risk',
      'neutral': 'normal'
    },
    'long': {
      'low': 'Decreased risk',
      'high': 'Increased risk',
      'neutral': 'Normal risk'
    }
  },
  'PRShealth': {
    'short': {
      'low': 'low risk',
      'high': 'high risk',
      'neutral': 'normal'
    },
    'long': {
      'low': 'Decreased risk',
      'high': 'Increased risk',
      'neutral': 'Normal risk'
    }
  },
  'injury': {
    'short': {
      'low': 'low risk',
      'high': 'high risk',
      'neutral': 'normal'
    },
    'long': {
      'low': 'Decreased risk',
      'high': 'Increased risk',
      'neutral': 'Normal risk'
    }
  },
  'performance': {
    'short': {
      'low': 'low baseline',
      'high': 'protected',
      'neutral': 'Pnormal'
    },
    'long': {
      'low': 'Low baseline',
      'high': 'Protected',
      'neutral': 'Normal'
    }
  },
  'caffeine': {
    'short': {
      'low': 'slow metabolism',
      'high': 'fast metabolism',
      'neutral': 'intermediate stuartmetabolism'
    },
    'long': {
      'low': 'Slow metabolism',
      'high': 'Fast metabolism',
      'neutral': 'Intermediate metabolism'
    }
  },
  'lactose-intolerance': {
    'short': {
      'low': 'lactose intolerant',
      'high': 'lactose tolerant'
    },
    'long': {
      'low': 'Lactose intolerant',
      'high': 'Lactose tolerant'
    }
  },
  'ibuprofen': {
    'short': {
      'low': 'normal metabolism',
      'high': 'very slow metabolism',
      'neutral': 'slow metabolism'
    },
    'long': {
      'low': 'Normal metabolism',
      'high': 'Very slow metabolism',
      'neutral': 'Slow metabolism'
    }
  },
  // 'strength': {
  //   'short': {
  //     'low': 'low baseline',
  //     'high': 'high baseline',
  //     'neutral': 'normal'
  //   },
  //   'long': {
  //     'low': 'Low baseline',
  //     'high': 'High baseline',
  //     'neutral': 'normal'
  //   }
  // },
  'IBD': {
    'short': {
      'low': 'increased risk',
      'high': 'normal',
      'neutral': 'normal'
    },
    'long': {
      'low': 'increased risk',
      'high': 'normal',
      'neutral': 'normal'
    }
  }
};

const calcResultLevels = (results) => {
  Object.entries(results).map(([entryID, upload]) => {
    // This ternary is used because the results endpoint and team-results endpoint return different formats
    const tags = ('results' in upload) ? upload.results : upload;
    Object.entries(tags).map(([tag, tagData]) => {
      // user does not have results for this specific test
      // for injury tests, relative risk is used rather than value
      // If tagData.value is null OR classification is 'injury' (not "bone-mineral-density") and either tagData doesn't have 'relativeRisk' or is null.
      if ((tagData.value === null) || (resultsConfig.hasOwnProperty(tag) && resultsConfig[tag]['classification'] === 'injury' && tag !== "bone-mineral-density" && (!tagData.hasOwnProperty('relativeRisk') || (tagData.hasOwnProperty('relativeRisk') && tagData['relativeRisk'] == null)))) {
        // caffeine-metabolism does not get an extra card so no color or label added for it
        if (tag !== 'caffeine-metabolism') {
          if (tag === 'testosterone') {
            let label = "Add demographic info to see results";
            tagData.shortLabel = label;
            tagData.longLabel = label;
          } else {
            tagData.shortLabel = 'unknown';
            tagData.longLabel = 'No results found';
          };
          tagData.level = 'unknown';
          tagData.color = 'gray-full';
        };

        // determine level (red, green, gray)
      } else {
        let level = calcResultLevel(tag, tagData, upload.demographics);
        tagData.level = level;

        // label is the text displayed in team and results pages
        if (performanceTags.has(tag)) { // put all tags that are performance or that have a percentile into performance tags

          if (resultsConfig[tag].hasOwnProperty('percentile')) { // performance and healthPRS
            let label = {}

            if (tag === 'testosterone') {
              label = getPercentileText(tag, tagData.value, upload.demographics.gender);

              // get percentile from uer.percentile
            } else if (tagData.hasOwnProperty('percentile') && (tagData.percentile || tagData.percentile === 0)) {
              label = getPercentileText2(tagData.percentile)

            } else if (tagData.percentile === null) {
              label = '~'

              // calculate percentile from crm
            } else {
              label = getPercentileText(tag, tagData.value, tagData.percentile);  
            }

            tagData.shortLabel = label;
            tagData.longLabel = label;
            tagData.color = invertColorMap[level];
          };

        } else if (tag === 'lactose-intolerance' || tag === 'ibuprofen') {
          tagData.shortLabel = labelMap[tag].short[level];
          tagData.longLabel = labelMap[tag].long[level];
          tagData.color = (tag === 'lactose-intolerance') ? invertColorMap[level] : (level === 'low') ? 'gray' : 'blue';
        } else if (tag === 'IBD') {
          tagData.shortLabel = labelMap[tag].short[level];
          tagData.longLabel = labelMap[tag].long[level];
          tagData.color = (tag === 'IBD') ? invertColorMap[level] : (level === 'low') ? 'red' : 'gray';

        } else {
          // default used for nonPercentiles for health/injury/performance
          tagData.shortLabel = labelMap.injury.short[level];
          tagData.longLabel = labelMap.injury.long[level];
          tagData.color = colorMap[level];
        };
      };
    });
    // re-runs may not include all tags
    if (tags.hasOwnProperty('coffee-consumption') || tags.hasOwnProperty('caffeine-metabolism')) {
      // Caffeine uses multiple algorithms (coffee-consumption + caffeine-metabolism) so evaluated outside the algorithm loop
      let level = calcCaffeineLevel(tags);
      tags['coffee-consumption'].level = level;
      tags['coffee-consumption'].shortLabel = labelMap.caffeine.short[level];
      tags['coffee-consumption'].longLabel = labelMap.caffeine.long[level];
      tags['coffee-consumption'].color = (level === 'neutral') ? 'gray' : 'blue';
    }
  });
}

// Function that calculates whether a particular tag should be classified as low/high/neutral risk
const calcResultLevel = (tag, resultData, demographics) => {
  if (!(tag in resultsConfig)) return null;
  const config = resultsConfig[tag];
  const thresholdType = (config.thresholds || {}).measurement || 'risk';
  let resultValue;

  // use percentile if it exists
  if (resultData.percentile !== undefined && resultData.percentile !== null) {
  // if  (thresholdType === 'percentile') {  
    resultValue = resultData.percentile;
    // if percentile is true but percentile is missing, use ~
    // TODO: make new measurement type "percentile" in config to separate percentile/percentile from percentile/value
    // } else if (config['percentile'] === 'true' && (resultData.percentile === undefined || resultData.percentile === null)) {
    //   resultValue = '~';

    // for values
  } else if (thresholdType === 'value') {
    
    resultValue = resultData.value;
    // for relative risk
  } else {
    resultValue = resultData.relativeRisk;
  }

  // thresholds are modified if they are "measurement". If risk, thresholds are set to default; hi risk is above 10% relative risk.
  const thresholds = (tag === 'testosterone') ? {
    'low': (config.thresholds || {}).low[demographics.gender] || thresholdDefaults[thresholdType].low
  } : {
    'invert': (config.thresholds || {}).invert || false,
    'low': (config.thresholds || {}).low || thresholdDefaults[thresholdType].low,
    'high': (config.thresholds || {}).high || thresholdDefaults[thresholdType].high
  };

  // in this statement, add cases for all performance factors
  // lactose-intolerance handled separately as there is no 'high' threshold. because of this, doing thresholds.high, which is the default case of the switch statement would cause an error
  // -4 is the only score one can have to be lactose intolerant. this means they have 4 recessive alleles, or 2 for the European snp and 2 for the African snp. the weight of each recessive allele is -1
  switch (tag) {
    case 'coffee-consumption':
      // Return null since calcCaffeineLevel is used instead (after classification loop completes)
      return null;
    case 'lactose-intolerance':
      if (resultValue === thresholds.low) {
        return 'low';
      } else {
        return 'high';
      }
    default:
      if (resultValue > thresholds.high) return (!thresholds.invert) ? 'high' : 'low';
      if (resultValue < thresholds.low) return (!thresholds.invert) ? 'low' : 'high';
      return 'neutral';
  }
  }

const calcCaffeineLevel = (userGenes) => {
  const caffGenes = (userGenes['caffeine-metabolism'] || {})['meta'] || {};
  const coffGenes = (userGenes['coffee-consumption'] || {})['meta'] || {};

  // Custom logic for caffeine-sensitivity table
  let caffeineResult = 'neutral';
  if (Object.keys(caffGenes).length > 0 && Object.keys(coffGenes).length > 0) {
    const snpRS762551 = userGenes['caffeine-metabolism'].meta.genes['rs762551'] || ['', ''];
    const snpRS2470893 = userGenes['coffee-consumption'].meta.genes['rs2470893'] || ['', ''];
    const coffeeResults = userGenes['coffee-consumption'].value
    if (snpRS762551[0] === 'A' && snpRS762551[1] === 'A'
      && snpRS2470893[0] === 'T' && snpRS2470893[1] === 'T'
      && coffeeResults > 0) {
      caffeineResult = 'high';
    }
    else if (snpRS762551[0] === 'C' && snpRS762551[1] === 'C'
      && snpRS2470893[0] === 'C' && snpRS2470893[1] === 'C'
      && coffeeResults < 0) {
      caffeineResult = 'low';
    }
  }
  return caffeineResult;
}

const resultReducer = (state = defaultState, action) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case LOAD_RESULTS:
      calcResultLevels(action.payload.data);
      return Object.assign(newState, { uploads: action.payload.data });
    case LOAD_SNPS:
      let reqTag = action.payload.request.responseURL.split('/')[5];
      return { ...state, snps: { ...state.snps, [reqTag]: action.payload.data } };
    case LOAD_TEAM:
      const values = Object.values(action.payload.data);
      const valuesLength = values.length;
      values.map((member, index) => {
        if (index !== valuesLength - 1) {
          calcResultLevels(member.uploads);
        }
      });
      return Object.assign(newState, { team: action.payload.data }, { user: values[valuesLength - 1] });
    default:
      return state;
  }
};

export default resultReducer;
