import React from 'react';


export const AchillesIntro = () => {
  return (
    <div>
      <div className="title-margins">
        <h2>Introduction to Achilles Tendon Injury</h2>
        <p>written by Dr. Megan Roche, M.D.</p>
      </div>
      <div className='title-break'>
          <span>High Risk Sports</span>
      </div>
      <div className='high-risk-list my-3 py-1'>
        <div className="result-sport-figure mb-2">
            <img src='/assets/imgs/results/athletes/runner.png'alt="" />
            <span>Endurance Running</span>
        </div>
        <div className="result-sport-figure">
          <img src='/assets/imgs/results/athletes/quarterback.png' alt="" />
          <span>Football</span>
        </div>
        <div className="result-sport-figure">
          <img src='/assets/imgs/results/athletes/basketball.png' alt="" />
          <span>Basketball</span>
        </div>
        <div className="result-sport-figure">
          <img src='/assets/imgs/results/athletes/tennis.png' alt="" />
          <span>Tennis</span>
        </div>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
        <div className="card">
          {/* Todo: put in  card-image everywhere. height={300} is required or img spans the entire page.*/}
          {/*   .card-image {
    float: right; */}
          <img src="/assets/imgs/results/content/ankleAthlete.jpg" alt="" height={300} className="overview-img" />
          {/* </div> */}
          <p>Achilles tendon injuries can be classified as either Achilles tendinopathy or Achilles tear / rupture. Achilles tendinopathy injuries, also known as Achilles tendonitis, are associated with overuse and are commonly seen in runners and other endurance athletes. On the other hand, Achilles tears and ruptures are more common in athletes with a long-standing history of Achilles tendinopathy or from direct trauma in sports such as basketball, football, and tennis (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6259075/">Lemme et al., 2018</a>).</p>
          <p className="mb-0">Ruptures are grouped into four types based on the severity of the tear and the degree of retraction of the Achilles tendon. Type I involves partial tearing, whereas Types II-IV involve complete rupture with increasing degrees of retraction of the Achilles tendon. An Achilles tendon rupture can be classified as chronic if it is diagnosed 4-6 weeks after the injury often due to misdiagnosis or failure to seek treatment.</p>
        </div>
        <div className='title-break'>
          <span>Causes</span>
        </div>
        <div className="card">
            <img src="/assets/imgs/results/content/achilles-heel.jpeg" alt="achilles heel" height={250} className="overview-img" />
          <p>Your genetic makeup can increase your risk for both Achilles tendinopathy or tears.</p>
          <p>In addition to your individual genetic differences, other risk factors for Achilles tendinopathy include inflexibility through the calf muscles, overuse, increase in the volume and/or intensity of training, worn-out footwear, cold weather training, and poor biomechanics (<a href="https://bjsm.bmj.com/content/53/21/1352.info">van der Vlist et al., 2019</a>). Systemic processes including metabolic syndrome or rheumatologic disease can cause tendon degeneration (<a href="https://pubmed.ncbi.nlm.nih.gov/18608383/">Ames et al., 2008</a>).</p>
          <p className="mb-0">Risk factors for Achilles tendon tears or ruptures include the use of Fluoroquinolone antibiotics (i.e. ciprofloxacin, moxifloxacin, levofloxacin), a change in exercise routine, long-standing Achilles tendinopathy, and corticosteroid injection at the Achilles tendon (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4080593/">Lewis et al., 2014</a>; <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4033727/">Vallone et al., 2014</a>). Direct trauma, sudden activation to an Achilles with long-standing tendinopathy, and sudden flexion of the ankle can cause Achilles rupture.</p>
        </div>
        <div className='title-break'>
          <span>Symptoms</span>
        </div>
        <div className="card">
          <p className="mb-0">Symptoms of Achilles tendinopathy include morning heel pain, pain during activity, crepitus (crackling sound or sensation in the Achilles), and stiffness. Symptoms of Achilles tears include a popping or snapping sound at time of injury, severe heel pain, difficulty walking, inability to walk/stand on your toes, trouble bending your foot, and bruising/swelling.</p>
        </div>
        <div className='title-break'>
          <span>Frequency</span>
        </div>
        <div className="card">
          <p>Achilles tendinopathy is one of the most frequent foot and ankle injuries, with a cumulative lifetime incidence of approximately 24% in athletes (<a href="https://systematicreviewsjournal.biomedcentral.com/articles/10.1186/s13643-017-0501-z#:~:text=The%20condition%20has%20a%20cumulative,h%20of%20running%20%5B3%5D.">Molyneux et al., 2017</a>). In competitive runners, the lifetime incidence of Achilles tendinopathy may be as high as 40 to 50 percent. Further, it’s estimated that 5% of professional athletes end their careers due to Achilles tendinopathy (<a href="https://www.uptodate.com/contents/achilles-tendinopathy-and-tendon-rupture">UpToDate, 2020</a>).</p>
          <p className="mb-0">Achilles tendon ruptures are more rare, with a peak incidence in athletes ages 30 to 50 (<a href="https://journals.sagepub.com/doi/10.1177/03635465020300022501">Schepsis et al., 2002</a>). Male athletes are 3.5 times more more likely to sustain an Achilles tendon rupture compared to female athletes (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6259075/">Lemme et al., 2018</a>). In the NBA, 44 Achilles tendon ruptures were reported between 1970 and 2018 with the highest prevalence of rupture occurring in early-season and pre-season. 21% of these Achilles tendon ruptures led to athlete retirement (<a href="https://journals.sagepub.com/doi/full/10.1177/0363546519858609?casa_token=pgUTZfiyMR4AAAAA%3AGI4oXrY8Cv5voihDc_WPxPffNAVdnXmCDL1kgfPhpL4jRMtBwhvN6NmT07HpokQUW8WRUYVfYhkZ">Lemme et al., 2019</a>). However, surgical management for Achilles tendon rupture has dramatically improved since 1970.</p>
        </div>
    </div>
  );
}

export default AchillesIntro;
