import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer';
import * as Actions from '../../actions';
import Alerts from '../../components/alerts';
import { useCustomDispatch } from '../../utils/customHooks';
import { useSelector } from 'react-redux';

const ConfirmEmailPage = ({ location, match }) => {
  const attemptConfirmEmailDispatch = useCustomDispatch(Actions.confirmEmailThunk);
  {/* TODO: if external_user_organization: NEED TO MAKE THIS OPTIONAL */}
  const [loginLink, setLoginLink] = useState("/login")

  const { alerts } = useSelector((state) => ({
    alerts: state.alerts
  }));

  useEffect(() => {
    const token = match.params.token;
    const params = new URLSearchParams(location.search);
    const nextURL = params.get('next');
    // set next login link provided there are url params
    if (nextURL !== null) setLoginLink(`/login?next=${encodeURIComponent(nextURL)}`);
    attemptConfirmEmailDispatch(token);
  }, []);

  useEffect(() => {
    // adjust link if success and no existing params
    if (alerts.hasOwnProperty("confirm-email") && alerts['confirm-email'].hasOwnProperty("success") && loginLink === "/login") setLoginLink("/login?next=%2Fbuy");
  }, [alerts]);
  
  return (
    <div className="bg self-align-stretch">
      <div className="d-flex self-align-stretch justify-content-center align-items-center h-100">
        <div className="auth-form-parent">
          <div className="auth-form-container">
            <div className="logreg-form">
              <div className="auth-form-header">
                <img alt="" src="/assets/imgs/axgen-logo.png" className="logo-img" />
                <h1>AxGen</h1>
              </div>
                <Alerts tag='confirm-email' level="success" />
                <Alerts tag='confirm-email' level="error" />
            </div>
          </div>
          <div className="auth-form-bottom">
            {/* TODO: if external_user_organization: NEED TO MAKE THIS OPTIONAL */}
            <p className="form-links mb-0"><Link to={loginLink} className="btn-link">Log in</Link> to your account</p>
          </div>
        </div>
      </div>
      <Footer forceWhite={true} />
    </div>
  );
};

export default ConfirmEmailPage;
