import React from 'react';


const Mars_HThyIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Hypothyroidism</h2>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/HThy.jpeg" height={300}/> 
        <p>Hypothyroidism is where the thyroid doesn’t create or release enough thyroid hormone into your bloodstream. The thyroid creates the hormones T4 and T3 to control your metabolism. These hormones work throughout the body to tell the body’s cells how much energy to use. They control your body temperature and heart rate. When your thyroid doesn’t make enough thyroid hormone, your metabolism slows down affecting nearly every organ in your body, even the way your heart beats.</p>
        <p>Nearly 5 out of 100 Americans have hypothyroidism, although most cases are mild or have few obvious symptoms. Women are much more likely than men to develop hypothyroidism. As many as 10% of women may have some degree of thyroid hormone deficiency. The disease is also more common among people older than age 60.</p>
        
      </div>
      <div className='title-break'>
        <span>Symptoms</span>
      </div>
      <div className="card">
        <p>Hypothyroidism may not cause noticeable symptoms in its early stages. Over time, hypothyroidism can lead to other health problems, such as:</p>
       <ul>
        <li>Tiredness.</li>
        <li>More sensitivity to cold.</li>
        <li>Constipation.</li>
        <li>Dry skin.</li>
        <li>Weight gain.</li>
        <li>Puffy face.</li>
        <li>Hoarse voice.</li>
        <li>Coarse hair and skin.</li>
        <li>Muscle weakness.</li>
        <li>Muscle aches, tenderness and stiffness.</li>
        <li>Menstrual cycles that are heavier than usual or irregular.</li>
        <li>Thinning hair.</li>
        <li>Slowed heart rate.</li>
        <li>Depression.</li>
        <li>Memory problems.</li>
       </ul>
      </div>
      <div className='title-break'>
        <span>Genetics</span>
      </div>
      <div className="card">
        <p>The results page shows a polygenic risk score for hypothyroidism where people in the 97th percentile have about a 2.9 fold increased risk. People at risk for hypothyroidism can keep a watch for early signs, and consult their healthcare provider. The healthcare provider can diagnose hypothyroidism by performing a blood test for thyroid stimulating hormone hypothyroidism. Hypothyroidism is treatable by taking a synthetic thyroid hormone.</p>
      </div>

    </div>
  )
}

export default Mars_HThyIntro;
