import { LOAD_KITS, LOAD_KIT, GET_KIT_STATUS, CLEAR_KIT_STATUS, ORDER_KITS_COUNT, ORDER_CODES_EMAIL_LIST, CHECK_MANAGER_STATUS, SEND_CODE, LOAD_VARIABLE } from '../actions';
import { sortOrdersByDate } from '../utils/data-tables';

const defaultState = {
  myOrder: {},
  myKits: [],
  myKit: {},
  coupon: null,
  isInPilot: false,
  numPreloadedCodes: 0,
  numPreloadedUploads: 0
};

const orderReducer = (state = defaultState, action) => {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case ORDER_KITS_COUNT:
      newState.myOrder = {
        status: 'success',
        type: 'count'
      };
      return newState;
    case ORDER_CODES_EMAIL_LIST:
      newState.myOrder = {
        status: 'success',
        type: 'list'
      };
      return newState;
    case 'BUY_KIT_FULFILLED':
      newState.myOrder = {
        orderIds: action.payload.data.orderIds,
        orderType: action.payload.data.orderType
      };
      return newState;
    case LOAD_KITS:
      const sortedOrders = sortOrdersByDate(action.payload.data);
      newState.myKits = sortedOrders;
      return newState;    
    case LOAD_KIT:
    case GET_KIT_STATUS:
      newState.myKit = action.payload.data;
      return newState;
    case CLEAR_KIT_STATUS:
      newState.myKit = {};
      return newState
    case 'LOAD_COUPON_FULFILLED':
      newState.coupon = action.payload.data;
      return newState;
    case 'REMOVE_COUPON':
    case 'LOAD_COUPON_REJECTED':
      newState.coupon = null;
      return newState;
    case CHECK_MANAGER_STATUS:
      let { isInPilot, numPreloadedCodes, numPreloadedUploads } = action.payload.data;
      newState.isInPilot = isInPilot;
      newState.numPreloadedCodes = numPreloadedCodes;
      newState.numPreloadedUploads = numPreloadedUploads;
      return newState;
    case SEND_CODE:
      newState.numPreloadedCodes = action.payload.data.numPreloadedCodes;
      newState.numPreloadedUploads = action.payload.data.numPreloadedUploads;
      return newState;
    case LOAD_VARIABLE:
      const { orderType, id } = action.payload.data;
      newState.orderType = orderType;
      newState.id = id;
      return newState;
    default:
      return state;
  }
};

export default orderReducer;
