import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';


export const IronResults = ({ userName, nameEndsWithS, uploadID, width }) => {
  // this page contains the results of multiple tests
  const { crm, uploads } = useSelector((state) => ({
    crm: state.crm,
    uploads: state.results.uploads,
  }));

  // gets the score for a given tag and returns the score sentence
  const getScoreContent = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    let score = tagResult.hasOwnProperty("value") ? tagResult['value'] : null;
    let prefixedScore;
    if (score !== null) {
      prefixedScore = score > 0 ? `+${score.toFixed(2)}` : `${score.toFixed(2)}`;
    };
    return (
      <strong>{prefixedScore}</strong>
    );
  };

  const getGraph = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    const crmKey = `results-${tag}-histogram`;
    return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
  };

  const getPercentile = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});    
    return <TagPercentile result={tagResult} tag={tag} usesUKData={true} />;
  };

  return (
    <div>
      <div className='title-margins'>
        <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Iron Levels</h2>
        <h5>[<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4215164/">Benyamin et al., 2014</a>]</h5>
      </div>

      <div className='title-break'>
        <span>Ferritin</span>
      </div>
      <div className="card">
      <p>To use these graphs, find the red line that shows your genetic score.  The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have slightly lower levels.  </p>

        { getGraph("ferritin") }
        <p>Ferritin is an intracellular protein that stores iron and acts as a buffer against iron deficiency and iron overload. It is responsible for maintaining iron in a soluble and non-toxic form within the body. Normal ferritin levels range from 12 to 300 nanograms per milliliter of blood (ng/mL) for males and 12 to 150 ng/mL for females. For athletes, it’s recommended that you be above 50 ng/mL.</p>
        <p>AxGen developed a polygenic risk score for ferritin levels using 6 SNPs from <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4215164/">Benyamin et al., 2014</a>. Your genetic results suggest that your ferritin level is shifted {getScoreContent("ferritin")} ng/mL compared to the average. The SNPs explain 0.9% of the variance in ferritin level, which means that your ferritin level is determined mostly by environment rather than genetics. A low percentile correlates with risk for low iron levels.</p>
        <p className="mb-0">{getPercentile("ferritin")} </p>
      </div>

      <div className='title-break'>
        <span>Iron</span>
      </div>
      <div className="card">
        { getGraph("iron") }
        <p>Serum iron measures the amount of circulating iron that is bound to transferrin. Normal range for total serum iron: 4.6 - 30.4 μmol/L in women and 13.6 - 35.4 μmol/L in men.</p>
        <p>AxGen developed a polygenic risk score for Iron using 5 SNPs from <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4215164/">Benyamin et al., 2014</a>. Your genetic results suggest that your iron is shifted {getScoreContent("iron")} μmol/L compared to the average. The SNPs explain 3.4% of the variance in iron levels, which means that the effects of diet and nutrition are far stronger than genetics for determining your iron levels. A low percentile correlates with risk for low iron levels.</p>
        <p className="mb-0">{getPercentile("iron")} </p>
      </div>

      <div className='title-break'>
        <span>Transferrin</span>
      </div>
      <div className="card">
        { getGraph("transferrin") }
        <p>Transferrin is an extracellular iron-binding protein that is used to transfer iron between tissues. Transferrin levels are inversely proportional to iron levels; low iron levels lead to high transferrin levels in the blood. Normal levels of serum transferrin are 170 to 370 mg/dL.</p>
        <p>AxGen developed a polygenic risk score for transferrin using 9 SNPs from <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4215164/">Benyamin et al., 2014</a>. Your genetic results suggest that your serum transferrin is shifted {getScoreContent("transferrin")} mg/dL compared to the average. The SNPs explain 7.2% of the variance in transferrin levels, which means that your transferrin levels are set more by diet and nutrition than by genetics. A low percentile correlates with risk for low iron levels.</p>
        <p className="mb-0">{getPercentile("transferrin")}</p>
      </div>

      <div className='title-break'>
        <span>Transferrin Saturation</span>
      </div>
      <div className="card">
        { getGraph("transferrin-saturation") }
        <p>Transferrin saturation indicates how much of transferrin is bound by iron. Transferrin saturation less than 16% indicates iron deficiency, whereas greater than 45% saturation is a sign of iron overload. When transferrin saturation exceeds 60%, non-transferrin-bound iron begins to accumulate in the circulation and damages parenchymal cells (functional cells of organs). The normal range for transferrin saturation is 20% - 50% for men and 15% - 45% for women.</p>
        <p>AxGen developed a polygenic risk score for transferrin saturation using 6 SNPs from <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4215164/">Benyamin et al., 2014</a>. Your genetic results suggest that your transferrin saturation is shifted {getScoreContent("transferrin-saturation")}% compared to the average, which is 25%. The SNPs explain 6.7% of the variance in transferrin saturation, meaning that most of transferrin saturation is determined by your environment rather than your genetic make-up. A low percentile correlates with risk for low iron levels.</p>
        <p className="mb-0">{getPercentile("transferrin-saturation")}</p>
      </div>
    </div>
  );
};

export default IronResults;
