import React from 'react';


const ComtIntro = () => {
    return (
        <div>
            <div className='title-margins'>
                <h2>Introduction to COMT Genetics</h2>
            </div>

            <div className='title-break'>
                <span>Overview</span>
            </div>

            <div className="card">
            <img alt="" className="overview-img" src="/assets/imgs/results/comts_fig.png" height={300}/> 
                <p>The COMT gene plays an important role in the metabolism of neurotransmitters in the brain. It is responsible for the breakdown of catecholamines, such as dopamine, norepinephrine, and epinephrine. </p>
                <p>A polymorphism in the COMT gene (rs4680) can lead to a variety of neurological and psychiatric disorders, including depression, substance use disorder, and attention-deficit/hyperactivity disorder (ADHD). rs4680(G) results in a valine (Val) amino acid and rs4680(A) encodes the methionine (Met) amino acid. People carrying the rs4680(A) allele have reduced enzyme activity, and as a result have increased levels of dopamine in limbic areas and the prefrontal cortex, leading to many of the phenotypic differences between the two alleles. COMT has a markedly different effect in men and women, as well as across different ethnicities.</p>
                <p>rs4680(A)
                    <ul>
                        <li>Codes for methionine.</li>
                        <li>Worrier.</li>
                        <li>Lower COMT enzymatic activity, therefore higher dopamine levels.</li>
                        <li>Increased risk of ADHD and Panic Disorder.</li>
                        <li>Increased risk of substance-use disorder.</li>
                        <li>Lower pain threshold.</li>
                        <li>Enhanced vulnerability to stress.</li>
                        <li>More efficient at processing information under most conditions.</li>
                    </ul>
                </p>
                <p>rs4680(G)
                    <ul>
                        <li>Codes for valine.</li>
                        <li>Warrior.</li>
                        <li>Modest reduction in executive cognition performance under most conditions.</li>
                        <li>Increased risk of bipolar disorder.</li>
                        <li>Increased risk of obsessive compulsive disorder in males.</li>
                        <li>Better response to antidepressant medications.</li>
                    </ul>
                </p>

            </div>

        </div>
    );
};

export default ComtIntro;