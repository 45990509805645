import React from 'react';
import Collapsible from 'react-collapsible';
import ReactTooltip from 'react-tooltip'
import { _tooltipper } from '../../utils/prehab';
import { Link } from 'react-router-dom';

const categoryTooltips = {
  "foundation": "Foundations: The exercises chosen are low load and low velocity movements. Our goal is to ensure proper motor patterns, biomechanics and to develop functional range of motion. Athletes need to demonstrate full or functional range of motion (ROM) motor control unloaded or with light load to decrease injury likelihood.",
  "prime": "Prime: After building a sound foundation of movement, we begin to load the system in a moderate velocity setting. The focus is on increasing biomechanical strength and increasing movement skill. The main goal of the program is to prepare for more advanced movements via breakdown exercises that segment the movement. There is a high allocation of time focused on repetition and progressive motor loading. Our goal is to ensure that all elements of advanced movements are mastered, in preparation for exercises used in the Elite phase.",
  "elite": "Elite: Given adequate control under load that has been built up through the prime program, we move into more advanced movements that demand more skill and control. Exercises include high velocity and high load movements. Rate of force development is a primary focus in this phase."
};

//the last 4 vids are uncategorized so i stuck them here in squats foundations...
const foundObj = {
    "Body Weight High Box Squat": {
      "url": "https://www.youtube.com/embed/gNVumFJbJqA?rel=0"
    },
    "Body Weight Medium Box Squat": {
      "url": "https://www.youtube.com/embed/-aEv7z8Qexs?rel=0"
    },
    "Body Weight Low Box Squat": {
      "url": "https://www.youtube.com/embed/5nSMXTD0I4k?rel=0"
    },
    "Box Down Jumps": {
      "url": "https://www.youtube.com/embed/fDsXntYQ1OE?rel=0"
    },
    "Box Jumps": {
      "url": "https://www.youtube.com/embed/zcISXcFSs_o?rel=0"
    },
    "In Place Squat Jumps": {
      "url": "https://www.youtube.com/embed/rUKvP8fqnZA?rel=0"
    },
    "Depth Box Jump": {
      "url": "https://www.youtube.com/embed/5XiRk7LeEBU?rel=0"
    },
    "Body Weight Hip Hinge": {
      "url": "https://www.youtube.com/embed/ZKdikvZ_2OA?rel=0"
    },
    "High Rack Pull": {
      "url": "https://www.youtube.com/embed/jx3TGXxYsTc?rel=0"
    },
    "Low Rack Pull": {
      "url": "https://www.youtube.com/embed/e_jzo8y4RNc?rel=0"
    },
    "Kettle Bell Deadlift": {
      "url": "https://www.youtube.com/embed/s3GSKfWdY6o?rel=0"
    },
    "Resisted Running": {
      "url": "https://www.youtube.com/embed/AJkwLSzchXo?rel=0"
    },
    "Hip Anchored Sled Drag": {
      "url": "https://www.youtube.com/embed/APgaRTiFcJE?rel=0"
    },
    "Hamstring Curls with Sliders": {
      "url": "https://www.youtube.com/embed/jxCyP36MjSY?rel=0"
    },
    "Hamstring Eccentric Slider": {
      "url": "https://www.youtube.com/embed/shVZP0NZAYk?rel=0"
    },
    "Single Leg Hamstring Swissball Curl": {
      "url": "https://www.youtube.com/embed/ZeA9Oe1i1Ck?rel=0"
  }
};

const primeObj = {
  "Multi-Directional Jumps": {
    "url": "https://www.youtube.com/embed/QXLlfYkG2sU?rel=0"
  },
  "Depth Jump": {
    "url": "https://www.youtube.com/embed/a3HZ16BNy1s?rel=0"
  },
  "Barbell Box Squat": {
    "url": "https://www.youtube.com/embed/AbA7GfK-qs0?rel=0"
  },
  "Barbell Back Squat": {
    "url": "https://www.youtube.com/embed/X4JLljbG5hw?rel=0"
  },
  "Barbell Front Squat": {
    "url": "https://www.youtube.com/embed/W3dSkq_8klw?rel=0"
  },
  "Barbell Deadlift": {
    "url": "https://www.youtube.com/embed/w48vYjweock?rel=0"
  },
  "Depth Single Leg Box Jump": {
    "url": "https://www.youtube.com/embed/46OyZdr46nU?rel=0"
  },
  "Single Leg Box Down Jump": {
    "url": "https://www.youtube.com/embed/-pjs62uHgE0?rel=0"
  },
  "Lateral Box Down Jumps": {
    "url": "https://www.youtube.com/embed/bypYeIOa_w8?rel=0"
  },
  "Lateral Box Jumps": {
    "url": "https://www.youtube.com/embed/iCCwoIWgQkM?rel=0",
  },
  "Sled Push": {
    "url": "https://www.youtube.com/embed/jD58oRoQ48Y?rel=0"
  },
  "Barbell Romanian Deadlift": {
    "url": "https://www.youtube.com/embed/k8RkT2lDK1s?rel=0"
  },
  "Ground Up Side Load Squat": {
    "url": "https://www.youtube.com/embed/r0bxGLZPAO0?rel=0"
  },
  "Raised Ground Up Squat": {
    "url": "https://www.youtube.com/embed/_9MkUIjXXK0?rel=0"
  },
  "Single Leg Squat Jumps": {
    "url": "https://www.youtube.com/embed/WYK-U4Ul6q8?rel=0"
  }
};

const eliteObj = { 
  "Tempo Squats": {
    "url": "https://www.youtube.com/embed/bfJgSnWQW84?rel=0"
  },
  "Deadlift": {
    "url": "https://www.youtube.com/embed/JUunjc0qJMg?rel=0"
  },
  "Zercher Squat": {
    "url": "https://www.youtube.com/embed/bUIJMvXn9BA?rel=0"
  },
  "Depth Jump to Lateral Jump": {
    "url": "https://www.youtube.com/embed/mWQP8uEXJ0g?rel=0"
  },
  "Depth Jump to Lateral Shuffle": {
    "url": "https://www.youtube.com/embed/ok7uFs7H0QI?rel=0"
  },
  "Hamstring Tantrums": {
    "url": "https://www.youtube.com/embed/O5TfIL504zM?rel=0"
  },
  "Nordic Hamstring Curl": {
    "url": "https://www.youtube.com/embed/bU1_VqGy9Sk?rel=0"
  },
  "Single Arm Sled Pull": {
    "url": "https://www.youtube.com/embed/dxKWmAdFvYk?rel=0"
  },
  "Raised Power Clean Pull": {
    "url": "https://www.youtube.com/embed/4R5NY7dcVDo?rel=0"
  },
  "Raised Power Clean": {
    "url": "https://www.youtube.com/embed/aWlMUKGVqIo?rel=0"
  },
  "Power Clean Pull": {
    "url": "https://www.youtube.com/embed/fDCDjbbUJyU?rel=0"
  },
  "Power Clean": {
    "url": "https://www.youtube.com/embed/lFAmxTVC8rc?rel=0"
  },
  "Resistance Banded Squats": {
    "url": "https://www.youtube.com/embed/xqcHrhoLaNM?rel=0"
  },
  "Longitudinal Hex Bar Deadlift": {
    "url": "https://www.youtube.com/embed/X6qzvgXN7ik?rel=0"
  },
  "Loaded Squat Jumps": {
    "url": "https://www.youtube.com/embed/TBzec_mClNQ?rel=0"
  }
};

const BMDExercises = (props) => {

  //this function is used to create the nested dropdown based on the large objects above
  const _vidListMaker = (level, cName, vidObj) => {
    const vids = Object.keys(vidObj).map((title, i) => {
      const curVid = vidObj[title];
      return (
        <Collapsible key={i} trigger={title} classParentString={`${cName}-video Collapsible`}>
          <p className="intro-vid"><iframe title={title} width="560" height="315" src={curVid["url"]} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
        </Collapsible>
      );
    });
    // adds tooltips for desktop devices for hovering over the exercise category
    let trigger;
    if (props.width < 992) {
      trigger = 
      <div className="collapsible-trigger">
        <span>{level}</span>
      </div>
    } else {
      trigger = 
        <div className="collapsible-trigger" data-tip={categoryTooltips[cName]} data-for="categ">
          <span>{level}</span>
        </div>
    };
    return (
      <Collapsible trigger={trigger} classParentString={`${cName}-video Collapsible`}>
        { vids }
      </Collapsible>
    );
  };
  const introVids = _vidListMaker("Foundations", "foundation", foundObj);
  const primeVids = _vidListMaker("Prime", "prime", primeObj);
  const eliteVids = _vidListMaker("Elite", "elite", eliteObj);

  const { width } = props;

  return (
    <div>
      <div className="title-margins" >
        <h2>Prehabilitation Exercises to Improve Bone Mineral Density and Reduce Risk of Bone Stress Injury</h2>
      </div>
      <div className="card p-1">
        {introVids}
        {primeVids}
        {eliteVids}
        <ReactTooltip place="bottom" className="tooltip-categ" effect="solid" id="categ" />
      </div>
      <div className="card">
        <p className="mb-0">The videos above are examples of exercises that can reduce your chance of injury. For advice personalizing your strength program based on your genetic tests, you can schedule a strength consultation <Link to="/consultations">here</Link>.</p>
      </div>
      <div className="card">
        <p>An exercise program for optimizing bone health should include exercises that load the skeletal sites of interest, produce dynamic strain, and involve high-impact activity. <a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/26092649">Hinton et al.</a> showed that whole body and lumbar spine bone mineral density can increase in men after 6 months of a resistance and jumping training program. Under Hinton’s protocol, {_tooltipper("strength exercises", "strength", width)}, like squats and deadlifts, were key resistance exercises for optimizing bone health and building strength. The eccentric components of the exercise were performed in at least 2-3 seconds and the concentric components were performed explosively. Squats produce movement meant to load the entire skeletal system including the lumbar spine. Increasing the weight and time under load has shown to be an effective way to increase bone mineral density. Deadlifts place a large percentage of load through the posterior chain musculature and specifically through the hip complex. Force generation and absorption directed through the hip complex should decrease the strain on the femur and tibia.</p>
        <p>Alongside squats and deadlifts, hamstring curls and the power clean can strengthen bone mineral density. Hamstring curls increase the strength of the hamstring complex, which aid in the ability to drop down when landing from a jump. The more powerful the hamstring complex, the more impact forces can be directed away from the skeleton. The power clean is an advanced compound movement that forces the body to generate large amounts of force through the musculoskeletal system and to decelerate high loads at high velocity. This exercise teaches proper biomechanics and osteokinematics, which places external loads through appropriate skeletal sites, decreasing risk of bone stress injuries.</p>
        <p>{_tooltipper("Dynamic exercises", "dynamic", width)} are also essential to a complete exercise program to optimize bone health. Plyometric exercises involve a progressive increase in load and demand of motor control. These exercises teach athletes how to decelerate with proper motor control and alignment with various loads and vectors. Jumping protocols that varied in intensity, direction, and loading pattern (single leg vs. double leg) and have been shown to be effective in increasing bone mineral density. Banded resistance jumps entail an increase in velocity during deceleration, which forces the athlete to quickly stabilize and redistribute force away from the skeleton by loading the musculotendinous structures of the lower limbs, minimizing the risk for a bone stress injury. Finally, the sled push/drag places high amounts of tension through the entire chain, forcing the athlete to maintain full postural integrity while generating power through the lower limbs. Proper alignment leads to improved biomechanics, placing external loads through appropriate skeletal sites and decreasing risk of bone stress injuries.</p>
        <p>Hinton’s jumping protocol entails adequate rest between jump loading cycles (10-15 seconds), sessions (8 hours), and blocks (2-3 days) to enhance bone formation and maximize effect (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/26092649">Hinton et al., 2015</a>). Also under this protocol, subjects refrained from exceeding 100 jumps in one training session. Evidence from animal studies indicates that the anabolic response of bone becomes saturated after 40-100 loading cycles (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/12162508">Robling et al., 2002</a>).</p>
        <p>Since the hip complex plays a central role in both strength and dynamic exercises for optimizing bone health, improving its {_tooltipper("range of motion", "range of motion", width)} is essential. One exercise that can improve the hip’s range of motion is the bodyweight hip hinge. This exercise is a foundational movement that teaches the body to absorb and generate force from the hip complex. Force generation and absorption directed through the hip complex should decrease the strain on the femur and tibia.</p>
        <p className="mb-0">Overall, mechanical loading through resistance training and jumping exercises tends to add bone to the most structurally relevant areas. As a result, modest changes in bone mineral density and bone mineral content from exercise can translate into larger changes in mechanical properties of bone (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/12162508">Robling et al., 2002</a>).</p>
      </div>
        <ReactTooltip place="top" className="tooltip-axgen" effect="solid" id="exercise-types" />
    </div>
  );
};

export default BMDExercises;
