import React from 'react';

class BoneIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
          <h2>Bone Mineral Density</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        {/* left-img requires both .card-image and .card to work */}
        <div className="card">
          <div className="card-image">
            <img src="/assets/imgs/results/content/osteoporosis.jpeg" height={200} className="overview-img" alt="" />
          </div>
          <p>Bone mineral density is a measure of your bone strength. People with low bone mineral density are at risk of breaking their bones from a simple fall. Bone mineral density is the strongest risk factor for fragility fractures and is the gold standard used to diagnose osteoporosis.</p>
          <p>Studies suggest that genetics also plays a large role in bone mineral density, with estimates of heritability of 45% to 82% (<a href="https://www.ncbi.nlm.nih.gov/pubmed/9718201">Howard et al., 1998</a>, <a href="https://www.ncbi.nlm.nih.gov/pubmed/12378368">Karasik et al., 2002</a>, <a href="https://www.ncbi.nlm.nih.gov/pubmed/22222934">Liu et al., 2012</a>, <a href="https://www.ncbi.nlm.nih.gov/pubmed/8992884">Arden et al., 1996</a>). In addition to genetic makeup, hormonal status, energy availability, and exercise history can impact bone mineral density. Peak bone mass is attained around the age of 30. Bone density steadily declines after this peak period due to bone reabsorption (<a href="https://www.ncbi.nlm.nih.gov/pubmed/21520276">Baxter-Jones et al., 2011</a>). </p>
        </div>
        <div className='title-break'>
          <span>Measuring Bone Mineral Density  </span>
        </div>
        <div className="card">
        <p>A bone density test (termed DXA) is the most common way to measure your bone mineral density. This test measures your bone density using T-scores, where a T-score below -2.5 indicates that you have osteoporosis and below -1.0 indicates osteopenia. </p>
        </div>
        <div className='title-break'>
          <span>Most people with with low bone mineral density are unaware of their condition</span>
        </div>
        <div className="card">
        <p>Approximately 10% of Americans over the age of 50 have low bone mineral density, but over 65% of these people are unaware that they have it (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4757905/">Wright et al., 2014</a>, <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4757905/">Siris et al., 2004</a>). Since they are unaware, these people do not take steps to either improve their bone health or avoid a fragility fracture, and are at elevated risk to break a bone from a simple fall. Low bone mineral density is responsible for an estimated two million broken bones in older Americans per year, with nearly 80% occurring in people who were unaware of their condition. </p>
        </div>
        <div className='title-break'>
          <span>Advantages of a genetic test for bone mineral density </span>
        </div>
        <div className="card">
        <p>The <a href="https://www.ncbi.nlm.nih.gov/pubmed/30048462">genetic algorithm</a> that tests for bone mineral density was developed in 2018 and is only available from AxGen.</p>
        <p>A real advantage of the Bone Mineral Density (BMD) genetic test is that it could inform you to undergo a bone scan, possibly uncovering an underlying condition that is treatable. Knowing that your bone density is low lets you take preventative steps to strengthen your bones and prevent a fracture from a simple fall. If your genetic test indicates risk for low BMD, we recommend that you visit OsteoStrong in either <a href="https://osteostrongla.as.me/schedule.php?appointmentType=9535338">Mar Vista</a> or <a href="https://www.osteostrongtorrance.com/first-time-schedule">Torrance</a>. OsteoStrong is a 10 minute-a-week bone strengthening center that uses cutting edge technology to increase your bone density at any age. </p>
        <p>Another advantage of the genetic test is that it works at any age, as your genetics do not change. The genetic information could inform you to take steps to strengthen your bones while you are relatively young, decades before you suffer loss of bone. Bone density peaks around age 30, and then steadily declines. When you are young or middle-aged, your bone health responds well to diet (Ca and vitamin D) and weight-bearing exercises. However, when you become elderly, your bone density does not respond as well, and taking medication becomes the most effective option. Individuals that undergo genetic testing when they are young or middle-aged can improve their bone density so that they retain higher bone density as they age. These people will also know to monitor their bone density with periodic bone scans, so they will be informed of their bone health status before troubles arise. </p>
        </div>

      </div>
    )
  }
}
export default BoneIntro;