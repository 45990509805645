import axios from 'axios';
import store from './store';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true
});

export const SET_LOGIN_USER_ID = 'SET_LOGIN_USER_ID';
const setLoginAction = (kitStatus, userDemographics, startPage, userEmail, userID, userName, userRole, myUploadID, expectedByDate) => ({
  type: SET_LOGIN_USER_ID,
  payload: { kitStatus, userDemographics, startPage, userEmail, userID, userName, userRole, myUploadID, expectedByDate }
});
export const setLoginThunk = () => dispatch => {
  const userDemographics = JSON.parse(localStorage.getItem('user-demographics'));
  const startPage = localStorage.getItem('start-page');
  const userEmail = localStorage.getItem('user-email');
  const userID = localStorage.getItem('user-id');
  const userName = localStorage.getItem('user-name');
  const userRole = localStorage.getItem('user-role');
  // since you cannot set null in localStorage (it becomes "null"), we must avoid placing null values into it
  let myUploadID = null;
  let expectedByDate = null;
  let kitStatus = null;
  if (localStorage.getItem('myUploadID')) myUploadID = localStorage.getItem('myUploadID');
  if (localStorage.getItem('expected-by-date')) expectedByDate = localStorage.getItem('expected-by-date');
  if (localStorage.getItem('user-kit-step')) kitStatus = parseInt(localStorage.getItem('user-kit-step'));
  
  dispatch(setLoginAction(kitStatus, userDemographics, startPage, userEmail, userID, userName, userRole, myUploadID, expectedByDate));
};

export const attemptLogin = (email, password) => {
  store.dispatch({
    type: 'LOGIN',
    payload: axiosInstance.post('/session', {
      email,
      password
    },{
      headers: {
        // "access-control-allow-origin": "origin",
        // 'Access-Control-Allow-Headers': 'Authorization',
        // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        'Access-Control-Allow-Credentials':true,
        // 'Access-Control-Allow-Methods': '*',
      }
    })
  });
};

export const attemptLogout = () => {
  store.dispatch({
    type: 'LOGOUT',
    payload: axiosInstance.delete('/session')
  });
};

export const attemptRegister = (guardianName, guardianEmail, email, password, name, referralName, urlParams, code) => {
  store.dispatch({
    type: 'REGISTER',
    payload: axiosInstance.post('/register', {
      guardianName,
      guardianEmail,
      email,
      name,
      password,
      referralName,
      urlParams,
      code,
    })
  });
};

// resends confirmation email
export const RESEND_CONFIRMATION = 'RESEND_CONFIRMATION';
const resendConfirmationAction = () => ({
  type: RESEND_CONFIRMATION
});
export const resendConfirmationThunk = (email, urlParams) => async dispatch => {
  try {
    await axiosInstance.post('/resend-confirmation', { email, urlParams });
    dispatch(resendConfirmationAction());
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while resending your confirmation email.";
    addAlert('resend-confirmation', 'error', errorMsg);
  };
};

export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
const confirmEmailAction = () => ({
  type: CONFIRM_EMAIL
});
export const confirmEmailThunk = token => async dispatch => {
  try {
    await axiosInstance.post('/confirm-email', { token });
    dispatch(confirmEmailAction());
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while confirming your email.";
    addAlert('confirm-email', 'error', errorMsg);
  };
};

export const CONFIRM_EMAIL2 = 'CONFIRM_EMAIL2';
const confirmEmailAction2 = () => ({
  type: CONFIRM_EMAIL2
});
export const confirmEmailThunk2 = token => async dispatch => {
  try {
    await axiosInstance.post('/confirm-email', { token });
    dispatch(confirmEmailAction2());
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while confirming your email.";
    addAlert('confirm-email', 'error', errorMsg);
  };
};

export const resetPassword = (email) => {
  store.dispatch({
    type: 'RESET_PASSWORD',
    payload: axiosInstance.post('/reset-password', { email })
  });
};

export const changePassword = (token, password) => {
  store.dispatch({
    type: 'CHANGE_PASSWORD',
    payload: axiosInstance.put('/change-password', { token, password })
  });
};

export const checkSession = () => {
  store.dispatch({
    type: 'CHECK_SESSION',
    payload: axiosInstance.get('/session')
  });
};

// loads results
export const LOAD_RESULTS = 'LOAD_RESULTS';
const loadResultsAction = results => ({
  type: LOAD_RESULTS,
  payload: results
});
export const loadResultsThunk = (uploadEntryID=false) => async dispatch => {
  try {
    const queryURI = (uploadEntryID) ? `/results/${uploadEntryID}` : '/results';
    const results = await axiosInstance.get(queryURI);
    dispatch(loadResultsAction(results));
  } catch (e) {};
};

// loads snps
export const LOAD_SNPS = 'LOAD_SNPS';
const loadSNPsAction = snps => ({
  type: LOAD_SNPS,
  payload: snps
});
export const loadSNPsThunk = tag => async dispatch => {
  try {
    const snps = await axiosInstance.get(`/snps/${tag}`);
    dispatch(loadSNPsAction(snps))
  } catch (e) {};
};

// bulk order kits
export const ORDER_KITS_COUNT = 'ORDER_KITS_COUNT';
const orderKitsCountAction = order => ({
  type: ORDER_KITS_COUNT,
  payload: order
});
export const orderKitsCountThunk = (shippingInfo, orderType, numKits=0) => async dispatch => {
  try {
    const order = await axiosInstance.post("/order", { numKits, shippingInfo, orderType });
    dispatch(orderKitsCountAction(order));
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while ordering your kits.";
    addAlert('order-by-count', 'error', errorMsg);
  };
};

// bulk order codes
export const ORDER_CODES_EMAIL_LIST = 'ORDER_CODES_EMAIL_LIST';
const orderCodesEmailListAction = order => ({
  type: ORDER_CODES_EMAIL_LIST,
  payload: order
});
export const orderCodesEmailListThunk = (orderType, emailList=[]) => async dispatch => {
  try {
    const order = await axiosInstance.post('/order', { emailList, orderType });
    dispatch(orderCodesEmailListAction(order));
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while ordering your codes.";
    addAlert('order-by-email', 'error', errorMsg);
  };
};

// used to order uploads for team manager
export const ORDER_UPLOADS_EMAIL_LIST = 'ORDER_UPLOADS_EMAIL_LIST';
const orderUploadsEmailListAction = order => ({
  type: ORDER_UPLOADS_EMAIL_LIST,
  payload: order
});
export const orderUploadsEmailListThunk = (orderType, emailList=[]) => async dispatch => {
  try {
    const order = await axiosInstance.post('/order', { emailList, orderType });
    dispatch(orderUploadsEmailListAction(order));
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while ordering your codes.";
    addAlert('order-by-email', 'error', errorMsg);
  };
};

// this handles purchases of kits
export const buyKit = (stripeToken, stripeTotal, stripeCouponCode, orderType, shippingInfo) => {
  store.dispatch({
    type: 'BUY_KIT',
    payload: axiosInstance.post('/order', { numKits: 1, stripeToken, stripeTotal, stripeCouponCode, orderType, shippingInfo })
  });
};

// this handles upgrades
export const buyUpgrade = (stripeToken, stripeTotal, stripeCouponCode, orderType, shippingInfo) => {
  store.dispatch({
    type: 'BUY_UPGRADE',
    payload: axiosInstance.post('/order', { numKits: 1, stripeToken, stripeTotal, stripeCouponCode, orderType, shippingInfo })
  });
};

// this handles the purchase of uploads
// separate from buyKit because we want different alerts for buying kits vs. uploads
export const buyUpload = (stripeToken, stripeTotal, stripeCouponCode, orderType, shippingInfo) => {
  store.dispatch({
    type: 'BUY_UPLOAD',
    payload: axiosInstance.post('/order', { numKits: 1, stripeToken, stripeTotal, stripeCouponCode, orderType, shippingInfo })
  });
};

// the following will be the template for our actions and dispatches from here on out (as of 8-19-2020). it does not raise an unhandled promise rejection warning, is easier to debug, reflects current best practice, and does not induce a state of callback craziness
export const LOAD_KITS = 'LOAD_KITS';
export const loadMyKitsAction = kits => ({
  type: LOAD_KITS,
  payload: kits 
});
export const loadMyKitsThunk = () => async dispatch => {
  try {
    const myKits = await axiosInstance.get('/order');
    dispatch(loadMyKitsAction(myKits));
  } catch (e) {
    // TODO: decide whether to add an error message or not
  };
};

// this endpoint will be fired from the /receive page
export const receiveKit = (body) => {
  store.dispatch({
    type: 'RECEIVE_KIT',
    payload: axiosInstance.post('/order/receive', body)
  });
};

// activates kit
export const ACTIVATE_KIT = 'ACTIVATE_KIT';
export const activateKitAction = data => ({
  type: ACTIVATE_KIT,
  payload: data 
});
export const activateKitThunk = barcode => async dispatch => {
  try {
    const data = await axiosInstance.post('/activation', { barcode });
    dispatch(activateKitAction(data));
  } catch (e) {
    addAlert('activate-kit', 'error', e.response.data.errors) 
  };
};

// gets kit status
export const GET_KIT_STATUS = 'GET_KIT_STATUS';
export const getKitStatusAction = kitStatus => ({
  type: GET_KIT_STATUS,
  payload: kitStatus 
});
export const getKitStatusThunk = barcode => async dispatch => {
  try {
    const kitStatus = await axiosInstance.get(`/order/${barcode}`);
    dispatch(getKitStatusAction(kitStatus));
  } catch (e) {
    dispatch(clearKitStatusAction());
    addAlert('track-kit', 'error', e.response.data.errors);
  };
};

// clear kit status (used in case of an error)
export const CLEAR_KIT_STATUS = 'CLEAR_KIT_STATUS';
export const clearKitStatusAction = () => ({
  type: CLEAR_KIT_STATUS
});
export const clearKitStatusThunk = () => dispatch => dispatch(clearKitStatusAction());

// loads kit
export const LOAD_KIT = 'LOAD_KIT';
export const loadKitAction = kit => ({
  type: LOAD_KIT,
  payload: kit 
});
// orderType determines whether a kit or an upload will be surfaced
export const loadKitThunk = (orderType) => async dispatch => {
  try {
    const kit = await axiosInstance.get(`/activation/${orderType}`);
    dispatch(loadKitAction(kit));
  } catch (e) {};
};

// checks manager status
export const CHECK_MANAGER_STATUS = 'CHECK_MANAGER_STATUS';
export const getManagerStatusAction = status => ({
  type: CHECK_MANAGER_STATUS,
  payload: status 
});
export const getManagerStatusThunk = () => async dispatch => {
  try {
    const status = await axiosInstance.get('/order/manager-status');
    dispatch(getManagerStatusAction(status));
  } catch (e) {};
};

// sends preloaded codes
export const SEND_CODE = 'SEND_CODE';
export const sendCodeAction = status => ({
  type: SEND_CODE,
  payload: status 
});
export const sendCodeThunk = (emailList) => async dispatch => {
  try {
    const status = await axiosInstance.post('/order/send-code', { emailList });
    dispatch(sendCodeAction(status));
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while emailing your code(s).";
    addAlert('order-by-email', 'error', errorMsg);
  };
};

// sends preloaded uploads
export const SEND_UPLOAD = 'SEND_UPLOAD';
export const sendUploadAction = status => ({
  type: SEND_UPLOAD,
  payload: status 
});
export const sendUploadThunk = (emailList) => async dispatch => {
  try {
    const status = await axiosInstance.post('/order/send-upload', { emailList });
    dispatch(sendUploadAction(status));
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while emailing your code(s).";
    addAlert('order-by-email', 'error', errorMsg);
  };
};

// loads permissions
export const LOAD_PERMISSIONS = 'LOAD_PERMISSIONS';
const loadPermissionsAction = permissions => ({
  type: LOAD_PERMISSIONS,
  payload: permissions
});
export const loadPermissionsThunk = () => async dispatch => {
  try {
    const permissions = await axiosInstance.get(`/permissions`);
    dispatch(loadPermissionsAction(permissions));
  } catch (e) {};
};

// saves permissions
export const SAVE_PERMISSIONS = 'SAVE_PERMISSIONS';
const savePermissionsAction = permissions => ({
  type: SAVE_PERMISSIONS,
  payload: permissions
});
export const savePermissionsThunk = permissions => async dispatch => {
  try {
    const updatedPerms = await axiosInstance.post('/permissions', permissions);
    dispatch(savePermissionsAction(updatedPerms));
  } catch (e) {
    addAlert('save-permissions', 'error', e.response.data.errors);
  };
};

// updates existing permissions on the frontend
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
const updatePermissionAction = (index, permission) => ({
  type: UPDATE_PERMISSION,
  payload: { index, permission }
});
export const updatePermissionThunk = (index, permission) => dispatch => dispatch(updatePermissionAction(index, permission));

// adds a new blank permission
export const ADD_PERMISSION = 'ADD_PERMISSION';
const addPermissionAction = () => ({
  type: ADD_PERMISSION
});
export const addPermissionThunk = () => dispatch => dispatch(addPermissionAction());

export const saveAccountSettings = (settings) => {
  store.dispatch({
    type: 'SAVE_ACCOUNT_SETTINGS',
    payload: axiosInstance.put('/account-settings', settings)
  });
};

export const UPLOAD_FILE = 'UPLOAD_FILE';
const uploadFileAction = () => ({
  type: UPLOAD_FILE
});
export const uploadFileThunk = (fileContents, fileType, orderId) => async dispatch => {
  try {
    await axiosInstance.post('/file/upload', { fileContents, source: 'web-app', fileType, orderId });
    dispatch(uploadFileAction());
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while uploading your file.";
    addAlert('upload-file', 'error', errorMsg);
  };
};

export const clearAlerts = () => {
  store.dispatch({
    type: 'CLEAR_ALERTS'
  });
};

// allows one to clear an alert with a specific tag while preserving the rest of the state
export const CLEAR_SPECIFIC_ALERT = 'CLEAR_SPECIFIC_ALERT';
const clearSpecificAlertAction = (tag) => ({
  type: CLEAR_SPECIFIC_ALERT,
  payload: { tag }
});
export const clearSpecificAlertThunk = (tag) => dispatch => dispatch(clearSpecificAlertAction(tag));

export const addAlert = (tag, level, msg) => {
  store.dispatch({
    type: 'ADD_ALERT',
    payload: { tag, level, msg }
  });
};

export const LOAD_CRM = 'LOAD_CRM';
export const loadCRMAction = crm => ({
  type: LOAD_CRM,
  payload: crm
});
export const loadCRMThunk = crmID => async dispatch => {
  try {
    const crmData = await axiosInstance.get(`/crm/${crmID}`);
    dispatch(loadCRMAction(crmData));
  } catch (e) {};
};

export const LOAD_TEAM = 'LOAD_TEAM';
const loadTeamAction = team => ({
  type: LOAD_TEAM,
  payload: team
});
export const loadTeamThunk = () => async dispatch => {
  try {
    const team = await axiosInstance.get('/team');
    dispatch(loadTeamAction(team));
  } catch (e) {};
};

// loads all the orders on the admin dashboard
export const LOAD_ADMIN_ORDERS = 'LOAD_ADMIN_ORDERS';
const loadAdminOrdersAction = (orders) => ({
  type: LOAD_ADMIN_ORDERS,
  payload: orders
});
export const loadAdminOrdersThunk = () => async dispatch => {
  try {
    let orders = await axiosInstance.get('/admin/orders');
    dispatch(loadAdminOrdersAction(orders));
  } catch (e) {};
};

// changes someone into a team manager and gives them more codes
export const ADD_MANAGER = 'ADD_MANAGER';
export const addManagerAction = (data) => ({
  type: ADD_MANAGER,
  payload: data
});
export const addManagerThunk = (email, numCodes, numUploads) => async dispatch => {
  try {
    const payload = await axiosInstance.post('/admin/add-manager', { email, numCodes, numUploads });
    if (payload && payload.data) {
      dispatch(addManagerAction(payload.data));
    };
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while making the user a manager.";
    addAlert('add-manager', 'error', errorMsg); 
  };
};

// removes someone's team manager status
export const REMOVE_MANAGER = 'REMOVE_MANAGER';
export const removeManagerAction = (data) => ({
  type: REMOVE_MANAGER,
  payload: data
});
export const removeManagerThunk = (email) => async dispatch => {
  try {
    const payload = await axiosInstance.post('/admin/remove-manager', { email });
    if (payload && payload.data) {
      dispatch(removeManagerAction(payload.data));
    };
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while removing the user's manager status.";
    addAlert('remove-manager', 'error', errorMsg); 
  };
};

export const loadCoupon = (productType, couponCode) => {
  store.dispatch({
    type: 'LOAD_COUPON',
    payload: axiosInstance.get(`/coupons?productType=${productType}&couponCode=${couponCode}`)
  });
};

export const removeCoupon = () => {
  store.dispatch({
    type: 'REMOVE_COUPON',
    payload: {}
  });
};

// loads admin consults
export const LOAD_ADMIN_COUNSULTATIONS = 'LOAD_ADMIN_COUNSULTATIONS';
export const loadAdminConsultsAction = consults => ({
  type: LOAD_ADMIN_COUNSULTATIONS,
  payload: consults
});
export const loadAdminConsultsThunk = () => async dispatch => {
  try {
    const adminConsults = await axiosInstance.get('/admin/consultations');
    dispatch(loadAdminConsultsAction(adminConsults));
  } catch (e) {
  };
};

// creates admin consult
export const CREATE_ADMIN_CONSULTATION = 'CREATE_ADMIN_CONSULTATION';
export const createAdminConsultAction = (consult) => ({
  type: CREATE_ADMIN_CONSULTATION,
  payload: consult 
});
export const createAdminConsultThunk = (consultantId, consulteeId) => async dispatch => {
  try {
    const consult = await axiosInstance.post('/admin/consultations', { consultantId, consulteeId });
    dispatch(createAdminConsultAction(consult));
  } catch (e) {
    const errorMsg = e.response && e.response.data && e.response.data.errors ? e.response.data.errors : "An error occured while creating the consultation.";
    addAlert('create-consultation', 'error', errorMsg); 
  };
};

// orders a consultation
export const orderConsult = (consultant, orderType, stripeToken, stripeTotal, stripeCouponCode, billingAddress) => {
  store.dispatch({
    type: 'ORDER_CONSULTATION',
    payload: axiosInstance.post("/consultations/order", {
      consultant,
      orderType,
      stripeToken,
      stripeTotal,
      stripeCouponCode,
      billingAddress
    })
  });
}

// gets consult from the dB for a user
export const loadMyConsult = () => {
  store.dispatch({
    type: 'LOAD_CONSULT',
    payload: axiosInstance.get('/consultations/getorder')
  });
};

// marks consult as completed so one can order another if desired
export const completeConsultation = (consultant) => {
  store.dispatch({
    type: 'COMPLETE_CONSULT',
    payload: axiosInstance.post('/consultations/complete', {
      consultant
    })
  });
};

// updates the width of the window, influencing mobile-only styles
// the width is updated from the one element that is always visible, the navbar, which handles the detection of screensize
// NOTE: YOU MUST PASS PAYLOAD AS AN OBJECT OR A CRASH WILL OCCUR
export const UPDATE_WIDTH = 'UPDATE_WIDTH';
const updateWindowWidthAction = width => ({
  type: UPDATE_WIDTH,
  payload: { width }
});
export const updateWindowWidthThunk = width => dispatch => dispatch(updateWindowWidthAction(width));

// grabs the strength plan from the backend
export const getStrengthPlan = () => {
  store.dispatch({
    type: 'GET_WORKOUT',
    payload: axiosInstance.get('/strength')
  });
};

// loads all of one's athletes for use in sending strength plans
export const loadMyAthletes = () => {
  store.dispatch({
    type: 'LOAD_ATHLETES',
    payload: axiosInstance.get('/strength/athletes')
  });
};

export const uploadStrengthPlan = (fileContent, fileType, athleteID) => {
  store.dispatch({
    type: 'UPLOAD_STRENGTH_PLAN',
    payload: axiosInstance.post('/strength', { fileContent, fileType, athleteID })
  });
};

// clears the strength plan state
export const clearStrengthPlan = () => {
  store.dispatch({
    type: 'CLEAR_STRENGTH_PLAN'
  });
};

export const LOAD_VARIABLE = 'LOAD_VARIABLE';
export const loadMyVariablesAction = stuart => ({
  type: LOAD_VARIABLE,
  payload: stuart
});
export const loadMyVariablesThunk = () => async dispatch => {
  try {
    const kim = await axiosInstance.get(`/external_order_variables`);
    dispatch(loadMyVariablesAction(kim));
  } catch (e) {
    console.error('loadMyVariablesThunk Failed')
  };
};

export default axiosInstance;
