import React from 'react';

const RotatorIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Rotator Cuff Injury</h2>
        <h6>written by Dr. Megan Roche, M.D.</h6>
      </div>

      <div className='title-break'>
        <span>High Risk Sports</span>
      </div>
      <div className='high-risk-list my-3 py-1'>
        <div className="result-sport-figure mb-2">
          <img alt="" src='/assets/imgs/results/athletes/baseball-pitcher.png'/>
          <span>Baseball</span>
        </div>
        <div className="result-sport-figure mb-2">
          <img alt="" src='/assets/imgs/results/athletes/quarterback.png' />
          <span>Football</span>
        </div>
        <div className="result-sport-figure mb-2">
          <img alt="" src='/assets/imgs/results/athletes/tennis.png' />
          <span>Tennis</span>
        </div>
      </div>

      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
      <img alt="" src="/assets/imgs/results/content/rotatorAthlete.jpg" height={300} className="overview-img"/>
        <p>The rotator cuff muscles enable shoulder movement and assist in shoulder stabilization. Athletes participating in overhead sports place significant demand on the rotator cuff. Repetitive, eccentric muscle contractions through throwing, spiking, and serving can cause rotator cuff tensile overload. Rotator cuff injury detracts from athletic performance in elite athletes and can shorten their careers (<a href="https://www.ncbi.nlm.nih.gov/pubmed/26945020">Gibbs et al., 2016</a>; <a href="https://www.ncbi.nlm.nih.gov/pubmed/21669100">Namdari et al., 2011</a>).</p>
        <p>The rotator cuff refers to four muscles that form a cuff at the glenohumeral joint of the shoulder. The supraspinatus, infraspinatus, teres minor, and subscapularis muscles arise from the scapula and connect to the head of the humerus through tendinous attachments. </p>
        <p>The rotator cuff enables abduction, internal rotation, and external rotation of the shoulder. In addition, during shoulder abduction, the rotator cuff counterbalances the elevating force of the deltoid muscle by compressing the glenohumeral joint to maintain the humeral head position within the glenoid fossa. This process, known as concavity compression, enables full shoulder abduction (<a href="https://www.ncbi.nlm.nih.gov/pubmed/8504601">Lippett and Matsen, 1993</a>).</p>
        <p>Rotator cuff pathology most often involves injury to the supraspinatus (<a href="https://www.ncbi.nlm.nih.gov/pubmed/20439653">Kim et al., 2010</a>). The supraspinatus originates from the fossa of the scapula and inserts onto the greater tubercle of the humerus. Overall, the supraspinatus contributes to abduction of the glenohumeral joint and assists in preventing shoulder subluxation. </p>
        <p>The most common rotator cuff injuries include rotator cuff tendinopathy, partial-thickness rotator cuff tears, and full-thickness rotator cuff tears. Rotator cuff tedinopathy is characterized by a degenerative process that results in disorganization and thickening of the tendon. Athletes with rotator cuff tendinopathy often experience pain and impaired shoulder strength.</p>
        <p>Rotator cuff tendinopathy can include partial-thickness rotator cuff tears when progressive degeneration occurs. Partial-thickness rotator cuff tears involve less than 50% of the muscle. In most circumstances, an increase in the size of the rotator cuff tear involves more pain and decreased function (<a href="https://www.ncbi.nlm.nih.gov/pubmed/21084574">Mall et al., 2010</a>).</p>
        <p className="mb-0">Full-thickness rotator cuff tears involve greater than 50% of the muscle and can occur in athletes from acute injury or acute-on-chronic rotator cuff injury. Older athletes and overhead athletes may sustain full-thickness rotator cuff tears as a result of chronic rotator cuff overload and underlying tendon pathology. Acute, full-thickness rotator cuff tears in young athletes can lead to irreversible muscle degeneration if they do not receive surgical intervention (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3438862/">Turman et al., 2010</a>).</p>
      </div>

      <div className='title-break'>
        <span>Frequency</span>
      </div>
      <div className="card">
        <p>A systematic review of 30 studies showed that rotator cuff abnormalities in the general population ranged from 9.7% in patients younger than 20 years of age and increased to 62% in patients aged 80 years and older (<a href="https://www.ncbi.nlm.nih.gov/pubmed/25441568">Teunis et al., 2014</a>).</p>
        <p>Aging athletes have a high incidence of rotator cuff injury. A study conducted at the Senior Olympics with 141 elite athletes over the age of 60 found that 21.3% of the population had full-thickness rotator cuff tears and 48.2% had partial-thickness rotator cuff tears. Although rotator cuff tears in older individuals are often asymptomatic, 30% of the athletes at the Senior Olympics with rotator cuff pathology experienced pain. (<a href="https://www.ncbi.nlm.nih.gov/pubmed/24619795">McMahon et al., 2014</a>).</p>
        <p className="mb-0">Rotator cuff injury reduces career length  and athletic performance in elite athletes. Draft-eligible football players who sustained a rotator cuff injury are significantly less likely to get drafted compared to uninjured players (<a href="https://www.ncbi.nlm.nih.gov/pubmed/26945020">Gibbs et al., 2016</a>). NFL players who were drafted with a pre-existing rotator cuff tear started about half as many games and played about 1.5 fewer years (<a href="https://www.ncbi.nlm.nih.gov/pubmed/26945020">Gibbs et al., 2016</a>). Similarly, a study conducted on Major League Baseball Players found that rotator cuff injuries led to a decline in a pitcher’s performance, eventually leading to surgery. After surgery, pitchers showed a gradual improvement in performance for three seasons, although their performance did not return to their pre-injury level (<a href="https://www.ncbi.nlm.nih.gov/pubmed/21669100">Namdari et al., 2011</a>).</p>
      </div>

      <div className='title-break'>
        <span>Causes</span>
      </div>
      <div className="card">
        <p>Rotator cuff tendon injuries can occur as a result of intrinsic or extrinsic mechanisms. Intrinsic mechanisms refer to the integrity of the rotator cuff tendons themselves. Extrinsic mechanisms refer to the compressive forces exerted on the rotator cuff tendons by surrounding structures. Intrinsic mechanisms include age-related tendon damage, chronic tendon microtrauma from tensile overload, and alterations in morphology, mechanical properties, and vascularity of the rotator cuff tendons. Extrinsic mechanisms include impingement from the acromion, coracoacromial ligaments, and the acromioclavicular joint (<a href="https://www.ncbi.nlm.nih.gov/pubmed/29264234">Pandey and Willems, 2015</a>).</p>
        <p>Overhead athletes include athletes participating in baseball, softball, volleyball, tennis, and track and field throwing events. Swimmers also form repetitive overhead movements, with year-round  swimmers performing 30,000 shoulder rotations per week on average (<a href="https://www.ncbi.nlm.nih.gov/pubmed/23015983">Heinlein and Cosgarea, 2010</a>).  Athletes participating in overhead sports are at higher risk for rotator cuff tensile overload during eccentric muscle contraction.</p>
        <p>Improper overhead mechanics, fatigue, and overtraining can result in microinstability, where the rotator cuff is unable to provide compressive force to maintain the humeral head within the glenoid fossa. Microinstability can cause rotator cuff overload and can predispose to partial- and full-thickness rotator cuff tears (<a href="https://www.ncbi.nlm.nih.gov/pubmed/29330670">Liu et al., 2018</a>).</p>
        <p>Abnormalities in the resting or active position of the scapula in shoulder movement (referred to as scapular dyskinesis) can occur in athletes performing repetitive overhead motions or athletes with muscular imbalance. Scapular protraction results in decreased subacromial space, causing a greater risk of impingement and increased extrinsic rotator cuff compression (<a href="https://www.ncbi.nlm.nih.gov/pubmed/27582990">Roche et al., 2015</a>). Overhead athletes are at higher risk for partial-thickness rotator cuff tears as a result of internal impingement (<a href="https://www.ncbi.nlm.nih.gov/pubmed/19915100">Mihata et al., 2010</a>).</p>
        <p>Age plays a significant role in rotator cuff pathology. Athletes participating in overhead sports over the age of 40 have an increased risk of rotator cuff injury. There is a decrease in blood flow to the intact rotator cuff in subjects over 40 compared to younger subjects (<a href="https://www.ncbi.nlm.nih.gov/pubmed/18069013">Rudzki et al., 2008</a>). Aging tendons are also prone to developing microtears, calcification, and fibrovascular proliferation.</p>
        <p className="mb-0">Multiple studies indicate that genetic differences contribute to an athlete’s risk for rotator cuff injury. A study by <a href="https://www.ncbi.nlm.nih.gov/pubmed/29228018">Roos et al.</a> found a single nucleotide polymorphism (SNP), rs71404070 associated with rotator cuff injury including partial- and full-thickness tears.</p>
      </div>
    </div>
  );
};

export default RotatorIntro;
