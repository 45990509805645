import React from 'react';

class AlzIntro extends React.Component {
    render() {
        return (
            <div>
                <div className="title-margins">
                    <h2>Introduction to Alzheimer's Disease</h2>
                </div>
                <div className='title-break'>
                    <span>Overview</span>
                </div>
                <div className="card">
                <img alt="" className="overview-img" src="/assets/imgs/results/alz_fig.png" height={400}/> 
                    <p>Alzheimer’s disease is a progressive, degenerative disorder that affects the brain and results in impaired memory, thinking, and behavior. It is the most common form of dementia, accounting for 60-80% of all cases. It is a devastating condition that affects millions of people around the world. While there is currently no cure, there are steps that can be taken to reduce the risk of developing Alzheimer’s.</p>
                </div>
                <div className='title-break'>
                    <span>Causes</span>
                </div>
                <div className="card">
                    <p>The exact cause of Alzheimer’s disease is still unknown, but it is believed to be caused by a combination of genetic, environmental, and lifestyle factors. </p>
                    <p>Genetics is the most significant factor in the development of Alzheimer’s. People with a family history of the disease are more likely to develop it themselves. The gene Apolipoprotein E (apoE) is largely responsible for Alzheimer risk, and comes in three forms. apoE3 is the most common allele, and sets the baseline for risk. One copy of apoE4 increases the risk of Alzheimer’s four fold while 2 copies of apoE4 increase risk 12 to 15 fold as well as increasing the likelihood of onset of the disease at an earlier age. apoE2 reduces the risk of Alzheimer’s disease by approximately 50% with just one copy present. </p>
                    <p>Environmental and lifestyle factors can also play a role in the development of Alzheimer’s. Exposure to certain environmental toxins such as aluminum or lead has been linked to an increased risk of developing the disease. Likewise, lifestyle factors such as smoking, lack of physical and mental activity, and poor diet have been linked to an increased risk of Alzheimer’s. </p>
                </div>
                <div className='title-break'>
                    <span>Symptoms</span>
                </div>
                <div className="card">
                    <p>The symptoms of Alzheimer’s disease can vary from person to person, but they typically include memory loss, difficulty with language, difficulty with problem-solving, confusion, and changes in behavior. As the disease progresses, these symptoms become more severe and can include difficulty with daily activities, such as dressing and bathing. </p>

                </div>
                <div className='title-break'>
                    <span>Prevention  </span>
                </div>
                <div className="card">
                    <p>Making lifestyle changes can help to improve the symptoms of Alzheimer's. Regular physical activity can improve blood flow to the brain and reduce inflammation. A healthy diet low in saturated fat and high in fruits, vegetables, and whole grains can provide essential nutrients for the brain. Staying socially active and engaging in activities that stimulate the brain can help reduce the risk of Alzheimer's disease. Getting enough sleep allows the brain to rest and repair itself. Finally, reducing stress can help reduce the risk of Alzheimer's disease by reducing inflammation and improving overall health. </p>

                </div>
                <div className='title-break'>
                    <span>Treatment  </span>
                </div>
                <div className="card">
                    <p>While there is no cure for Alzheimer’s, several medications are available to help manage the symptoms of Alzheimer's. Aducanumab is a disease-modifying medication currently approved to treat Alzheimer’s. This medication is a human antibody, or immunotherapy, that targets the protein beta-amyloid and helps to reduce amyloid plaques, which are brain lesions associated with Alzheimer’s. </p>
                    <p>Memantine, an N-methyl D-aspartate (NMDA) antagonist, is prescribed to treat moderate to severe Alzheimer’s disease. Its main effect is to decrease symptoms, which could enable some people to maintain certain daily functions a little longer than they would without the medication.</p>
                    <p>Cholinesterase inhibitors help to increase levels of the neurotransmitter acetylcholine, which is important for memory and learning. Examples of cholinesterase inhibitors include alantamine, rivastigmine, and donepezil that are prescribed for mild to moderate Alzheimer’s symptoms. These drugs may help reduce or control some cognitive and behavioral symptoms.</p>
                    <p>Making lifestyle changes can help to improve the symptoms of Alzheimer's. These include getting regular exercise, eating a healthy diet, and engaging in activities that stimulate the brain, such as puzzles and games. </p>
                    <p>Cognitive behavioral therapy (CBT) can help to improve the symptoms of Alzheimer's. CBT helps to identify and change negative thought patterns and behaviors that can worsen the symptoms of Alzheimer's.</p>

                </div>

            </div>
        )
    }
}

export default AlzIntro;
