import React from 'react';


const Mars_KOsteoIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Knee Osteoarthritis</h2>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/KOsteo_fig.png" height={350} />
        <p>Knee osteoarthritis is a degenerative joint condition that occurs when the natural cushioning in your knee (cartilage) wears away. It is especially common in athletes, as the repetitive stress of certain sports can cause the cartilage in the knee joint to wear away, leading to pain and stiffness. The most common sports associated with knee osteoarthritis are running, football, basketball and soccer. These sports involve a lot of repetitive motions, such as running, jumping, and pivoting, which can put a great deal of strain on the knee joint. Over time, this strain can cause the cartilage in the knee joint to break down, leading to pain and stiffness. </p>
        <p>The ends of your bones are covered in a smooth and slippery surface known as cartilage. This allows the bones to move against each other without friction, and protects your joint from stress. Healthy cartilage acts as a specially lubricated surface in the joint. When the cartilage wears away, it becomes rough or disappears entirely, leaving the bone surfaces to rub against one another with less shock-absorption. The rubbing results in pain, swelling, stiffness, decreased ability to move and the formation of bone spurs. As the disease advances, the condition can become extremely painful and debilitating.</p>
        <p>Knee osteoarthritis affects over 32.5 million US adults. Approximately 46% of people will develop it during their lifetimes. Women are more likely than men to develop osteoarthritis of the knee. Knee osteoarthritis mostly affects people after age 40.</p>
      </div>
      <div className='title-break'>
        <span>Risk Factors</span>
      </div>
      <div className="card">
        <ul>
          <li>Age. The ability of cartilage to replenish decreases as a person gets older.</li>
          <li>Athletics. Athletes are at higher risk for developing osteoarthritis of the knee, and should take precautions to avoid knee injury. However, regular exercise strengthens joints and can decrease the risk of osteoarthritis.</li>
          <li>Repetitive stress injuries. People with occupations that include activities that stress the knee (such as kneeling, squatting, or lifting heavy weights) are at higher risk because of the constant pressure.</li>
          <li>Weight. Weight increases pressure on all joints, especially the knees. </li>
          <li>Gender. Women are more likely than men to develop osteoarthritis of the knee.</li>
        </ul>
      </div>
      <div className='title-break'>
        <span>Symptoms</span>
      </div>
      <div className="card">
        <p>Knee osteoarthritis usually develops gradually over a period of years, although it can develop quite rapidly in certain circumstances. </p>
        <ul>
          <li>Pain is the most common symptom of osteoarthritis in the knee. Your knee might hurt when you move it, or even when you are just sitting still.</li>
          <li>Your knee feels stiff, particularly when you first get up or when you’ve been sitting for a long time.</li>
          <li>Your knee looks swollen or feels puffy.</li>
          <li>You hear a cracking or grinding noise when you move your knee.</li>
          <li>Your knee feels wobbly, as if it could buckle.</li>
          <li>Your knee might lock up, or feel as if it is stuck.</li>
        </ul>
      </div>

      <div className="title-break">
        <span>Genetics</span>
      </div>
      <div className="card">
        <p>The results page shows a polygenic risk score for knee osteoarthritis that indicates a 2.7 fold increased risk for people in the 97th percentile. People with a risk for knee osteoarthritis can take preventative steps to minimize the damage, years before serious symptoms appear. Athletes should consult their trainer and others should consult their health care providers about how to minimize damage caused by wear and tear. </p>
        <p>Athletes with genetic risk for knee osteoarthritis can take these preventative steps. First, athletes should make sure to warm up and cool down before and after each workout. This will help to reduce the amount of stress placed on the knee joint. Second, athletes should focus on strengthening the muscles around the knee joint, as this will help to support the joint and reduce the risk of injury. Third, athletes should make sure to take regular breaks from their sport to give the knee joint time to rest and recover.</p>
      </div>
    </div>
  )
}

export default Mars_KOsteoIntro;
