import React from 'react';


class BMDPrevention extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
          <h2>Prehabilitation Measures to Improve Bone Mineral Density and Reduce Risk of Bone Stress Injury</h2>
        </div>
        <div className="card">
          <p className="mb-0">BSI prevention parallels the risk categories outlined by <a href="https://www.ncbi.nlm.nih.gov/pubmed/25103133">Warden et al.</a> and involves examining the extrinsic load applied to bone and optimizing the intrinsic ability of bone to resist load. Prevention strategies can be broken down into the following categories:</p>
        </div>
        <div className='title-break'>
          <span>Quick Checklist</span>
        </div>
        <div className="card">
          <ul className="mb-0">
            <li>Evaluate biomechanics</li>
            <li>Avoid sudden and large increases in training duration or frequency</li>
            <li>Avoid rapid transition to minimalist footwear or barefoot running</li>
            <li>Replace running shoes every 300-400 miles, evaluate shoes for advanced age</li>
            <li>Maintain adequate energy availability. If concerned about energy availability consider an appointment with a registered dietitian or nutritionist. </li>
            <li>Evaluate calcium and Vitamin D intake. Consider supplementation, especially if levels are low or low-normal.</li>
            <li>Incorporate an exercise plan that includes exercises that produce dynamic strain or involve high-impact load.</li>
          </ul>
          
        </div>
        <div className='title-break'>
          <span>Biomechanics</span>
        </div>
        <div className="card">
          <p>Proper evaluation of biomechanics includes evaluation of anatomic alignment and evaluation of sport-specific loading patterns. Anatomic features that can contribute to increased BSI risk include leg length discrepancy, smaller calf size, and either flat or high-arched feet (<a href="https://www.ncbi.nlm.nih.gov/pubmed/25103133">Warden et al., 2014</a>).</p>
          <p>Vertical ground reaction force (VGRF) is the vertical component of the force exerted by the ground on the body during running and movement patterns. VGRF can be broken down into average and peak loading rates known as the vertical average loading rate (VALR) and vertical instantaneous loading rate (VILR) respectively. VALR and VILR have been retrospectively associated with BSI. Gait pattern relearning strategies and landing pattern modifications may help reduce BSI risk from high loading rates (<a href="https://www.ncbi.nlm.nih.gov/pubmed/26258133">An et al., 2015</a>). </p>
          <p className="mb-0">Custom orthotics, carefully selected footwear, and other devices can be used to address abnormalities in anatomic features. A strength and conditioning coach or physical therapist can help address abnormalities in sport-specific loading patterns including running gait, jumping technique, and cutting patterns.</p>
        </div>
        <div className='title-break'>
          <span>Training Patterns</span>
        </div>
        <div className="card">
          <p>Stress from increased training velocity, duration, or frequency can contribute to BSI risk. Running surface and court surface may also impact the risk, but the effect is complex and subject to debate. Endurance athletes building up volume of repetitive training or sustaining high volumes of training should consider one “down” week every three to four weeks to enable the body to recover from training stress. </p>
          <p>Studies show that participation in sports with high-impact and multi-directional movement patterns (such as soccer and basketball) in the adolescent years may improve bone density and BSI risk in the adult years (<a href="https://www.ncbi.nlm.nih.gov/pubmed/25499072">Tenforde et al., 2015</a>).</p>
          <p className="mb-0">Resistance training can build strong muscles, which in turn supports strong bones. Studies show that women with larger calf circumference have lower risk of tibial BSI (<a href="https://www.ncbi.nlm.nih.gov/pubmed/8947404">Bennel et al., 1996</a>). Adequate protein intake in athletes helps support their muscular foundation. </p>
        </div>
        <div className='title-break'>
          <span>Footwear</span>
        </div>
        <div className="card">
          <p>The influence of footwear on BSI risk is complex, subject to debate, and often dependent on characteristics of the individual athlete (<a href="https://www.ncbi.nlm.nih.gov/pubmed/25103133">Warden et al., 2014</a>). Studies show that a rapid transition to minimalist shoes or barefoot running can increase risk for foot BSI (<a href="https://www.ncbi.nlm.nih.gov/pubmed/23439417">Ridge et al., 2013</a>). On the other end of the spectrum, newer studies are showing that highly-cushioned “maximalist” shoes may amplify impact loading forces at faster running speeds (14.5 km/hr)(<a href="https://www.ncbi.nlm.nih.gov/pubmed/30504822">Kumala et al., 2018</a>).</p>
          <p className="mb-0">Replacing shoes before they wear down is another important consideration for injury prevention. On average, running shoes should be replaced every 300-400 miles, though body weight, training surface, and running form may change this estimate. Having an expert assess gait analysis and movement patterns in footwear may help prevent BSI.</p>
        </div>
        <div className='title-break'>
          <span>Past Medical History</span>
        </div>
        <div className="card">
          <p>Prior BSI is a risk factor for development of future BSI. Athletes with a personal history or family history of BSI or osteoporosis should consider closely examining risk factors for low bone mineral density. Further, medications such as steroids, anticonvulsants, antidepressants, and antacids could impact bone health and BSI risk.</p>
          <p>Alcohol use can influence bone mineral density and BSI risk. Chronic, heavy drinking in adolescence can affect bone development and can increase osteoporosis risk (<a href="https://www.ncbi.nlm.nih.gov/pubmed/15706795">Sampson, 1998</a>). In the later years, the negative effects of heavy alcohol use on bone health are harder, and sometimes even impossible to reverse (<a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0180132">Jang et al., 2017</a>) and (<a href="https://www.ncbi.nlm.nih.gov/pubmed/29696513">Cho 2018</a>). </p>
          <p className="mb-0">Smoking impairs the absorption of calcium and reduces blood supply to the bones. Any amount of smoking has a detrimental effect on bone health (<a href="https://www.ncbi.nlm.nih.gov/pubmed/15175845 ">Kanis et al., 2005</a>).</p>
        </div>
        <div className='title-break'>
          <span>Calcium Vitamin D Status</span>
        </div>
        <div className="card">
          <p>Calcium is a component of the matrix that gives strength to bone and Vitamin D promotes calcium absorption and enables bone remodeling. The Institute of Medicine published <a href="https://ods.od.nih.gov/factsheets/Calcium-HealthProfessional/#h2">calcium</a> and <a href="https://ods.od.nih.gov/factsheets/VitaminD-HealthProfessional/#h2">Vitamin D</a> guidelines that can be helpful for athletes. However, a higher intake of calcium and Vitamin D than the recommended dose may be needed, especially for athletes with a history of BSI or BSI risk factors.</p>
          <p className="mb-0">Higher Vitamin D levels have been associated with a faster return-to-play following BSI. In NCAA Division I distance runners, a 10-unit increase in serum Vitamin D level was associated with 17% faster return-to-play following BSI (<a href="https://www.researchgate.net/publication/288488825_Risk_Factors_for_Bone_Stress_Injuries_in_NCAA_Division_I_Collegiate_Distance_Runners_A_3_Year_Retrospective_Study">Kim, 2017</a>).</p>
        </div>
      </div>
    )
  }
}

export default BMDPrevention;
