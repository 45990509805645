import React from 'react';

class FolateIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Vitamin B9</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/folate.jpg" height={200}/>   
        <p>Vitamin B9 (folate) is important in red blood cell formation and for healthy cell growth and function. This nutrient is crucial during early pregnancy to reduce the risk of birth defects of the brain and spine.</p>
        <p>Vitamin B9 is naturally present in a wide variety of foods, especially spinach, liver, asparagus, and brussels sprouts. Vitamin B9 is also present in vegetables (especially dark green leafy vegetables), fruits and fruit juices, nuts, beans, peas, seafood, eggs, dairy products, meat, poultry, and grains. Cereals, breads and pastas are often fortified with vitamin B9. Vitamin B9 is also available from supplements such as multivitamins. </p>
        </div>
 


        <div className="card">
        <p>Most people in the United States consume adequate amounts of vitamin B9. Mean dietary intakes of vitamin B9 from foods are 602 mcg for men and 455 mcg for women.  Measurements of red blood cell vitamin B9 levels suggest that most people in the United States have adequate vitamin B9 status. In adults, the mean erythrocyte vitamin B9 concentrations range from 317-1422 nmol/L. </p>
        </div>
        <div className='title-break'>
          <span>Vitamin B9, <i>MTHFR</i> and Health</span>
        </div>        
        <div className="card">
        <p>Genetic variation in the <i>MTHFR</i> has a moderate effect on your vitamin B9 levels. The link between <i>MTHFR</i> and vitamin B9 levels is strongly backed by multiple scientific studies. However, some websites and products have made unsubstantiated claims that variants in <i>MTHFR</i> can cause a wide array of health conditions, such as: depression, dementia, cardiovascular disease, cancer and autism. According to the <a href="https://ods.od.nih.gov/factsheets/Folate-HealthProfessional/">vitamin B9 factsheet from the National Institute of Health</a>, the evidence for these claims is weak. Many claims have not been replicated or are based on weak evidence. </p>
        </div>
      </div>
    )
  }
}

export default FolateIntro;
