import React from 'react';
import ReactTooltip from 'react-tooltip'
import { _tooltipper, _vidListMaker } from '../../utils/prehab';
import { Link } from 'react-router-dom';


const vids = {
  'Star Excursion Balance Test': 'https://www.youtube.com/embed/21arMJTCRrs?rel=0',
  'Single Leg Squat Jumps': 'https://www.youtube.com/embed/WYK-U4Ul6q8?rel=0',
  'Hamstring Curls': 'https://www.youtube.com/embed/P5ciNZbDkbo?rel=0',
  'Mini-band Squat Jumps': 'https://www.youtube.com/embed/bjVhTLToo_o',
  'Banded Resistance Jumps': 'https://www.youtube.com/embed/cuLzj7nRmFQ?rel=0',
  'Inline Lunge': 'https://www.youtube.com/embed/Oq6Dt06ya2Y?rel=0',
  'Single Leg In Box Catches': 'https://www.youtube.com/embed/IxcZsPGI9UE?rel=0',
  'Partner Pull / Power Sled': 'https://www.youtube.com/embed/Y_xypT2TYL0?rel=0',
  'Hip Internal / External Rotation': 'https://www.youtube.com/embed/kemC5JTsPUM?rel=0'
};

const MCLExercises = (props) => {
  const vidList = _vidListMaker(vids);
  const { width } = props;
   
  return (
    <div>
      <div className="title-margins">
        <h2>MCL Injury Prehabilitation Exercises</h2>
      </div>
      <div className="card p-1">
        {vidList}
      </div>
      <div className="card">
        <p className="mb-0">The videos above are examples of exercises that can reduce your chance of injury. For advice personalizing your strength program based on your genetic tests, you can schedule a strength consultation <Link to="/consultations">here</Link>.</p>
      </div>
      <div className="card">
        <p>Integrative training programs involving a focus on {_tooltipper("range of motion", "range of motion", width)}, {_tooltipper("strength", "strength", width)}, muscle balance, {_tooltipper("proprioception", "proprioception", width)}, and {_tooltipper("dynamic postural control", "dynamic", width)} may help prevent MCL injury. The functional anatomy of the knee can be broken down into static and dynamic stabilizers. The MCL serves as a static knee stabilizer and the quadriceps, semimembranosus (hamstring), and pes anserinus serve as dynamic knee stabilizers (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2684213/">Chen et al., 2007</a>).</p>
        <p>Both strength and dynamic exercises can strengthen the quadriceps and hamstrings and can help provide dynamic support to the MCL and knee. Strength exercises include inline lunges and hamstring curls. Inline lunges aim to increase strength while maintaining alignment at the knee joint during flexion and extension. Dynamic neuromuscular control must be demonstrated to maintain alignment. Hamstring curls aim to increase the strength of the hamstring muscles. A quadricep dominant muscular imbalance may alter knee kinematics if left unchanged, increasing the risk of injury due to improper knee tracking.</p>
        <p>Dynamic exercises, which improve the strength and movement of the knee and MCL, include mini band squat jumps, single leg squat jumps, banded resistance jumps, and the power sled push. Mini band squat jumps serve as overcorrections against knee valgus during jumping. The valgus position of the knee places the athlete at high risk for MCL injury. Jumping with a MiniBand forces the athlete to quickly absorb impact while resisting forces that promote knee valgus. Single leg squat jumps serve to increase the rate of force development through the gluteus maximus, quadriceps and hamstrings to aid in dynamic support of the MCL and knee. Banded resistance jumps prepare the knee for the lateral forces found in sports that place the MCL at high risk for injury. The increase in velocity during deceleration forces the athlete to quickly stabilize and maintain proper knee kinematics. Also, the increased load of the power sled push places high amounts of tension through the entire chain, forcing the athlete to maintain full postural integrity while generating power through the lower limbs. Proper alignment leads to improved biomechanics, which decreases torque and shear forces applied to the knee complex.</p>
        <p>Many MCL injuries occur in sports involving cutting, jumping, or explosive movement. These movement patterns require neuromuscular control and proprioception to maintain stability when a perturbation or collision occurs. The star excursion balance test can improve neuromuscular control. Although created for ankle stability, this exercise also requires proper alignment through the knee joint. Foot and ankle complexes are also the first to come into contact with the ground, and this aspect improves knee kinematics</p>
        <p>Impaired muscular control of the hip and pelvis can impact patellofemoral joint kinematics (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/20118526">Powers, 2010</a>). Specifically, <a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/20118526">Powers</a> showed that dynamic knee valgus from excessive hip adduction and internal rotation can add strain to the MCL. Hip mobilization through hip internal/external rotation exercises can sometimes improve knee kinematics. These mobility-oriented exercises are aimed at restoring functional range of motion at the hip complex, thus decreasing both the amount of torque transferred to the knee joint and the shear forces on the MCL.</p>
        <p className="mb-0">Training programs should evaluate and address dynamic neuromuscular imbalances that can contribute to MCL injury. Athletes who are quadriceps dominant preferentially activate knee extensors over knee flexors during movement patterns, which may predispose the knee to injury. In addition, athletes who have a dominant leg imbalance often have weaker and less developed hamstring musculature on the non-dominant leg, which can also cause knee injury (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/12218474">Hewett et al., 2002</a>). </p>
      </div>
      <ReactTooltip place="top" className="tooltip-axgen" effect="solid" id="exercise-types" />
    </div>
  );
}

export default MCLExercises;
