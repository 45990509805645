import React from 'react';
import GeneTable from '../../components/gene-table';
import Collapsible from 'react-collapsible';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';

// results(longlabel) and team(shortLabel) are custom classified in result-reducer.js based on two CYP2 snps and the histogram result.

class CaffeineResults extends React.Component {
  render() {
    const { result } = this.props;
    
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Caffeine Sensitivity Results</h2>
          <h5>[<a href="https://www.ncbi.nlm.nih.gov/pubmed/25288136">Chasman et al., 2015</a>; <a href="https://www.ncbi.nlm.nih.gov/pubmed/29509641">Guest et al., 2018</a>]</h5>
        </div>
        <div className="card p-1">
          <Collapsible trigger="Tutorial">
          <p className='intro-vid'><iframe title="Results" width="560" height="315" src="https://www.youtube.com/embed/k6D1mfHZapU?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
          </Collapsible>
        </div>


        <div className='title-break'>
          <span>Coffee Consumption</span>
        </div>
        <div className='card'>
          {/* The graph and table at the top use tag='coffee-consumption' */}
          <p>The strongest result for how much coffee you drink is shown in the histogram below. In this <a href="https://www.ncbi.nlm.nih.gov/pubmed/25288136">study</a>, the entire genome was analyzed for an association with amount of coffee consumed from a cohort of 91,462 people. Eight significant associations were found, and then combined into one score. The chart shows your score as the number of coffee cups consumed/day compared to the average. So if the average person drinks 2 cups a day and you have a score of 0.5, then you would be predicted to drink about 2.5 cups/day.  </p>
          <BetaHistogram crm={this.props.crm} result={result} crmKey='results-coffee-consumption-histogram' />
          <p>Your score for coffee consumption (8 genes): <strong>{typeof result.value === "number" ? result.value.toFixed(2) : null}</strong> cups coffee/day vs average.</p>
          <p><TagPercentile result={result} tag='coffee-consumption' /></p>

        </div>
        <div className='card'>
          <p>Your score is based on the eight genes listed in the Table below. The DNA variant with the strongest effect (rs2470893) lies within the <i>CYP1A2</i> gene, which encodes the enzyme that degrades caffeine. The T allele is associated with drinking more coffee than the C allele.</p>
          <GeneTable tag='coffee-consumption' cols={['gene', 'alleleFreq', 'beta','running-total']} />
        </div>

        <div className='title-break'>
          {/* The table at the bottom plots CYP1A2 using tag='caffeine-metabolism' */}
          <span>Ergogenic Effect of Caffeine </span>
        </div>
        <div className='card'>
          <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/29509641">One study</a> directly tested the beneficial effects of caffeine on athletic performance. The advantage of this study is that it tested the ergogenic effects of caffeine, the disadvantages are that it was limited to a small number of athletes (101) and tested only one SNP (rs762551) in the CYP1A2 gene. </p>
          <p>Your results are shown in the Table below. The common A allele causes higher inducibility of CYP1A2 and faster caffeine metabolism than the minor C allele. Caffeine has a beneficial ergogenic effect (i.e. faster time trial) for athletes with an AA genotype and a detrimental effect (slower time trial) for athletes with a CC genotype (<a href="https://www.ncbi.nlm.nih.gov/pubmed/29509641">Guest et al., 2018</a>).</p>
          <GeneTable tag='caffeine-metabolism' cols={['gene', 'alleleFreq', 'caffeine-metabolism']} />
        </div>
       
      </div>
    )
  }
}

export default CaffeineResults;
