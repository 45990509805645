import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer';
import * as Actions from '../../actions';
import Alerts from '../../components/alerts';
import { useCustomDispatch } from '../../utils/customHooks';

  // global state
const LoginPage = ({ location, history }) => {
  const { alerts, session } = useSelector(state => ({
    session: state.session,
    alerts: state.alerts
  }));

  // dispatch
  const setLoginDispatch = useCustomDispatch(Actions.setLoginThunk);

  const [nextURL, setNextURL] = useState("");
  const [registerURL, setRegisterURL] = useState("");
  const [emailField, setEmail] = useState("");
  const [passField, setPass] = useState("");
  const [disabled, disableSubmit] = useState(false);

  const submitForm = (evt) => {
    evt.preventDefault();
    disableSubmit(true);
    Actions.clearAlerts();
    Actions.attemptLogin(emailField, passField);
  };

  // fires on mount, then sets the nextURL state based on one's current url parameters, so the form knows where to bring a user next on submission. ie a user wants to go to their results, but their session expired, if they try to navigate to their results page directly, once they log in, they will automatically be brought to results
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const nextVar = params.get('next');
    const uploadType = params.get('uploadType');
    const orderID = params.get('orderID');
    const regURL = nextVar ? `/register?next=${encodeURIComponent(nextVar)}` : '/register';
    setRegisterURL(regURL);  
    // const nxURL = nextVar && uploadType ? `${nextVar}&uploadType=${uploadType}` : '/';
    const orderIDqueryString = orderID ? `&orderID=${orderID}` : '';
    const nxURL = nextVar && uploadType ? `${nextVar}?uploadType=${uploadType}${orderIDqueryString}` : '/';
    setNextURL(nxURL);  
    const userID = localStorage.getItem('user-id');
    if (session.userID) {
      if (session.startPage) {
        const nxURL = session.startPage?.trim() !== '' ? `/results/${session.myUploadID}/${session.startPage}` : '/'; 
        history.push(nxURL);
      } else {  
      // history.push(nxURL);  //history.push did not work.

      window.location = nxURL;
      }
    } else if (userID) {
      setLoginDispatch();
    };
  }, [session]);

  // 1. accept start_page in jsonify from auth.py.  Use start_page to amend URL to start company on a page instead of results.
  // e.g. /results/uploadID vs /results/uploadID/glutenomics
  // 2. brings the user to the next url if there is an active session already
  // useEffect(() => {
  //   if (session.userID) {
  //     const nxURL = session.startPage?.trim() !== '' ? `/results/${session.myUploadID}/${session.startPage}` : '/'; 
  //     history.push(nxURL);
  //   }
  // }, [session]);

  // un-disable button if alerts
  useEffect(() => {
    if (Object.keys(alerts).length !== 0) {
      disableSubmit(false);
    };
  }, [alerts]);
  
  const toggleEmailActiveLabel = emailField !== "" ? 'auth-label active' : 'auth-label';
  const toggleEmailInput = emailField !== "" ? 'form-control active' : 'form-control';
  const togglePassActiveLabel = passField !== "" ? 'auth-label active' : 'auth-label';
  const togglePassInput = passField !== "" ? 'form-control active' : 'form-control';

  return (
    <div className="bg self-align-stretch">
      <div className="d-flex self-align-stretch justify-content-center align-items-center h-100">
        <div className="auth-form-parent">
          <div className="auth-form-container">
            <div className="logreg-form">
              <div className="auth-form-header">
                <img alt="" src="/assets/imgs/axgen-logo.png" className="logo-img" />
                <h1>AxGen</h1>
              </div>
              <div className="auth-line"></div>
              <form className="auth-form" onSubmit={(evt) => { submitForm(evt); }}>
                <div className="auth-input-container">
                  <i className="fa fa-envelope"></i>
                  <label id="email-label" className={toggleEmailActiveLabel}>Email</label>
                  <input
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={emailField}
                    className={toggleEmailInput}
                    id="email-input"
                    required
                  />
                </div>
                <div className="auth-input-container">
                  <i className="fas fa-lock-alt"></i>
                  <label className={togglePassActiveLabel}>Password</label>
                  <input
                    type="password"
                    name="password"
                    autoComplete="on"
                    onChange={(e) => setPass(e.target.value)}
                    value={passField}
                    className={togglePassInput}
                    required
                  />
                </div>
                <p className="form-links mb-0">Forgot your password? <Link to="/reset-password" className="btn-link">Reset Password</Link></p>
                <div className="submit-row">
                  <input
                    type="submit"
                    className="btn reg-btn"
                    value="LOG IN"
                    disabled={disabled}
                    />
                </div>
              </form>
              <Alerts tag='login' message='Failed to login' margT={0}/>
            </div>
          </div>
          <div className="auth-form-bottom">
            <p className="form-links mb-0">Don't have an account? <Link to={registerURL} className="btn-link">Sign Up</Link></p>
          </div>
        </div>
      </div>
      <Footer forceWhite={true} />
    </div>
  );
};


export default LoginPage;
