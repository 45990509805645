import React from 'react';
import GeneTable from '../../components/gene-table';
import RiskGraph from '../../components/risk-graph';
import Collapsible from 'react-collapsible';

class AnkleResults extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Ankle Injury</h2>
        <h5>[<a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0185355">Kim et al., 2017</a>]</h5>
        </div>

        <div className="card p-1">
            <Collapsible trigger="Tutorial">
              <p className="intro-vid"><iframe title="Results" width="560" height="315" src="https://www.youtube.com/embed/EtkHG_FUhbs?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
            </Collapsible>
        </div>

        <div className="card">
            <RiskGraph tag='ankle' />
        <p>The average incidence of ankle injuries for soccer players is 7.9%. The chance for injury is higher for basketball (41.1%) and football (9.3%) players. </p>
        <p>The ankle sprain genetic markers were identified using a European population. How well do the genetic markers work for other ethnicities (i.e. ancestry groups)? For rs13286037, no significant difference was found in a population of Latin American descent (<a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0185355">Kim et al., 2017</a>). For other ancestry groups as well as the other genetic marker (rs118069956), there was insufficient data to draw conclusions about heterogeneity of effect. </p>
      </div>
        <div className="card">
        <p>Ankle injuries are among the most common type of athletic injury. The two SNPs associated with ankle injuries both show significant increased risk, although both are relatively rare. The T allele of rs118069956 has an allele frequency of 1.5% and leads to an 86% increased risk of injury. The A allele of rs13286037 has an allele frequency of 2.5% and is associated with a 58% increased risk. </p>
        <GeneTable tag='ankle' cols={['alleleFreq', 'relativeRisk'] } />
        </div>
      </div>
    )
  }
}

export default AnkleResults;
