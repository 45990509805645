import React from 'react';

class TestosteroneIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
          <h2>Introduction to Testosterone</h2>
          <p>written by Andrew Roos </p>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
          <img alt="" src="/assets/imgs/results/content/test2.jpg" height={200} className="overview-img" />
          <p>Testosterone is a steroid hormone that binds to androgen receptors and serves as the primary sex hormone in men. It is produced by the testicles in men and at a much lower rate by the ovaries in women, as well as the adrenal glands in both sexes. Primarily, testosterone is responsible for the development of the male sexual organs and secondary sexual traits, but it also influences mood, energy and sexual arousal in both men and women. It is typically found at blood levels of 250-950 ng/dL in healthy men and 10-60 ng/dL in healthy women (<a target="_blank" rel="noopener noreferrer" href="https://onlinelibrary.wiley.com/doi/full/10.1111/cen.13840">Clark et al. 2018</a>).</p>
          <p>In men, testosterone levels increase rapidly during the adolescent years around puberty, peak in the 20's and then gradually decline after the age of 30. In women, testosterone levels are lowest during the adolescent years  and rise in middle age and later in life in conjunction with menopause. Low serum testosterone concentrations are associated with cardiovascular morbidity, metabolic syndrome, dyslipidemia, high blood pressure, type 2 diabetes, stroke, atherosclerosis, osteoporosis, sarcopenia, depression and increased mortality risk.</p>   
        </div>
        <div className="card">
          <p>Aside from it's role as the male sex hormone, testosterone is important for all athletes, both male and female. It is an anabolic hormone that increases muscle mass and strength, increases bone density and strength, promotes tendon/ligament strength, and promotes repair and healing of damaged tissue. All of these factors greatly improve athletic performance and the ability of athletes to recover from daily training and/or injury.</p>
          <p>In addition, testosterone improves fat metabolism, thus reducing body fat, increasing energy levels and regulating sleep. Conversely, low testosterone results in decreased muscle mass and strength, decreased bone density and strength, decreased tendon/ligament strength, increased body fat, increased fatigue and poor sleep. Maintaining testosterone levels is thus key for athletic performance.</p>
          </div>
        <div className="card">
          <p>Serum testosterone levels in males are thought to depend mostly on genetics. The estimated heritability is 0.65, meaning 65% of the variability in testosterone levels in men is determined by genetics.</p>
          <p>Besides genetics, testosterone levels are influenced by exercise, diet and environmental factors. All of these are important for athletes trying to optimize testosterone levels. Testosterone levels are increased by explosive strength/power training and decreased by endurance training. Endurance athletes are at high risk for low testosterone due to consistently large training volumes, which can reduce testosterone levels by 20-40%. </p>
          <p>Acute (short-term) stress from exercise or training is important for physiological adaptation and is beneficial to athletes, but must be followed by periods of recovery. Chronic (long-term) exercise or training stress not only decreases beneficial testosterone levels, but also increases harmful cortisol levels, a steroid hormone that decreases collagen synthesis, decreases bone density, decreases wound healing and severely impairs the immune system. </p>
          <p>Diets high in simple/refined sugars and/or low in fats/cholesterol reduce testosterone levels. High blood sugar is detrimental as it causes the conversion of testosterone to estrogen. Healthy levels of dietary fat intake is key to maintaining testosterone levels because fat leads to cholesterol production which in turn is used to synthesize testosterone. Additionally, vitamin D, magnesium or zinc deficiencies lower testosterone levels. Alcohol consumption, especially beer (due to the presence of hops), also lowers testosterone levels. Of the environmental factors affecting testosterone, lack of sleep and excessive stress are the most important. Increased sleep boosts testosterone production at night. Competition, either athletic or non-athletic, increases testosterone levels in men.</p>
        </div>
      </div>
    )
  }
}

export default TestosteroneIntro;
