import React from 'react';

const Yu_CKDIntro = () => {
    return (
        <div>
            <div className='title-margins'>
                <h2>Introduction to Chronic Kidney Disease</h2>
            </div>
            <div className='title-break'>
        <span>Overview</span>
      </div>
            <div className="card">
            <img alt="" className="overview-img" src="/assets/imgs/results/CKD.jpeg" height={200}/> 
                <p>Your kidneys are each just the size of a computer mouse, and they filter all of the blood in your body every 30 minutes. They remove wastes, toxins, and excess fluid from your blood. They also help control blood pressure, stimulate production of red blood cells, keep your bones healthy, and regulate essential blood chemicals. </p>
                <p>Chronic kidney disease (CKD) involves a gradual loss of kidney function. Advanced CKD can cause dangerous levels of fluid, electrolytes and wastes to build up in your body. This can lead to kidney failure, which is also called end-stage kidney disease. </p>
                </div>
                <div className='title-break'>
                    <span>Symptoms</span>
                </div>
                <div className="card">
                <p>In early stages of kidney disease, you usually don’t have noticeable symptoms. As the disease worsens, symptoms may include:</p>
                <ul>
                <li>High blood pressure.</li>
                <li>Increased need to urinate.</li>
                    <li>Tiredness, weakness, low energy level.</li>
                    <li>Loss of appetite.</li>
                    <li>Swelling of your hands, feet and ankles.</li>
                    <li>Shortness of breath.</li>
                    <li>Foamy or bubbly urine.</li>
                    <li>Puffy eyes.</li>
                    <li>Dry and itchy skin.</li>
                    <li>Trouble concentrating.</li>
                    <li>Trouble sleeping.</li>
                    <li>Numbness.</li>
                    <li>Nausea or vomiting.</li>
                    <li>Muscle cramps.</li>
                    <li>Darkening of your skin.</li>
                </ul>

                </div>
                <div className='title-break'>
                    <span>What tests are used to diagnose CKD?</span>
                </div>
                <div className="card">
                <p>Your healthcare provider will order blood and urine tests to check how well your kidney is functioning. The blood test will measure your serum creatinine level. Creatinine is a waste product that is normally filtered by the kidney and excreted in urine. With CKD, your kidney filters less creatinine and the unfiltered creatinine builds up in your blood. The level of creatinine in your blood can be used to calculate your glomerular filtration rate (GFR), which is a measure of your kidney’s filtering capacity. </p>
                <p>The urine test measures levels of protein (albumin) in your urine. Kidneys normally prevent proteins such as albumin from being excreted into the urine. With chronic kidney disease, the barrier function of the kidney is lessened and proteins appear in the urine. </p>
               
            </div>
            <div className='title-break'>
                    <span>How common is chronic kidney disease?</span>
                </div>
                <div className="card">
                <p>Some 37 million people in the U.S. (15% of the U.S. population) are living with chronic kidney disease. Of these, 15 million are undiagnosed. Because they are undiagnosed, they are not under the care of their medical providers nor are they able to take actions to prevent the disease from getting worse.  </p>
               
            </div>
            <div className='title-break'>
                    <span>A Genetic Test for CKD</span>
                </div>
                <div className="card">
                <p>The genetic algorithm for CKD was developed in 2021 and your results for this test are shown in the results page. A real advantage of this genetic test is that it could inform you that you have high risk for CKD. Knowing that your risk for CKD is high lets you contact your healthcare provider. He/She will assess your kidney function and guide you to maintain your kidney health and to either delay or prevent the onset of chronic kidney disease. </p>
                <p>Another advantage of the genetic test is that it works at any age, as your genetics do not change. Your genetic test could allow you to take early action to prevent CKD before you show symptoms. Damage to your kidney is irreparable, and an ounce of prevention is worth a pound of treatment. </p>
               
            </div>
        </div>
    )
}

export default Yu_CKDIntro;