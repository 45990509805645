import React from 'react';

class COQ10Intro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to coenzyme Q10</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/COQ.jpg" height={200}/>   
        <p>Coenzyme Q10 (CoQ10) is a nutrient that occurs naturally in the body, and is also found in many meats, nuts, fruits, and vegetables. It plays an important role as an antioxidant, protecting cells from oxidative damage.</p>
        <p>Most people produce enough CoQ10 naturally. Combined with the amount of CoQ10 present in a standard diet, nearly all healthy adults will have adequate levels of CoQ10.  CoQ10 supplements are considered to be safe with few side effects, but supplementation isn’t typically necessary. </p>
        <p>Increasing age and some medical conditions are associated with decreased levels of CoQ10, such as high blood pressure, cardiovascular disease or patients that take statins.  In these cases, a physician may prescribe a CoQ10 supplement, or recommend eating more meats, fruits, and vegetables with high CoQ10 levels. </p>
        </div>
      </div>
    )
  }
}

export default COQ10Intro;
