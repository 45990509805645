import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import RiskFactorGraph from '../../components/risk-factor-graph';
import Collapsible from 'react-collapsible';
import { getPercentileText2} from '../../utils/results';

export const T2D_YeResults = (props) => {
    const { crm, result, userName, nameEndsWithS } = props;
    let testComponent;
    let percentile; 
    let adjusted = result['adjusted_result'];
    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
       adjusted = result['adjusted_result'];
       
    }
    if (result['value'] !== null) {
        testComponent =
            <div>
                <div className='title-break'>
                    <span>Your Results</span>
                </div>
                <div className="card">
                    <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have higher risk for type 2 diabetes. (Units for Beta are arbitrary.)</p>
                    <div>
                        {
                            adjusted ? (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-adjusted_T2D_Ye-histogram' />
                                    <p> You are in the <b>{getPercentileText2(percentile)} </b>.
                                    Your age, sex, height and weight have been factored in to your score to better predict your diabetes risk.</p>
                                    
                                </>
                            ) : (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-T2D_Ye-histogram' />
                                    <TagPercentile result={props.result} tag='T2D_Ye' />
                                </>
                            )
                        }

                    </div>

                </div>
                <div className='title-break'>
                    <span>How To Interpret Your Results</span>
                </div>
                <div className="card">
                    <p>You can look up your risk for type 2 diabetes using this figure. Using your percentile, find the corresponding bin on the x-axis. Poor, intermediate and ideal refer to how healthy your lifestyle is. Once you know your PRS bin and lifestyle, the value on the y axis tells your hazard ratio for type 2 diabetes, which is how often type 2 diabetes occurs compared to how often it happens in the median group. For example, if your PRS is &lt;1% and you have an intermediate lifestyle, you have a 3.7-fold increased risk of type 2 diabetes.</p>
                    <img src="/assets/imgs/results/content/T2D_fig.jpg" height={350} className="overview-img" alt="" />
                </div>
                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">
                    <p>This study developed a polygenic risk score (PRS) for type 2 diabetes that includes 2,996,761 variants based on 92,928 people (4,639 cases of type 2 diabetes). The PRS was then validated on an independent cohort of 183,168 individuals.</p>
                    <p>The study was performed on individuals of White British descent. It is unclear how these results generalize to other ancestry groups.</p>
                    <p>T2D PRS is being used in the eMERGE IV network beginning in 2022. Patients with a high-risk T2D PRS will receive guideline-informed health recommendations.</p>
                </div>

                {
  adjusted ? (
    (() => {
      const graphMax = .07 ;
      const data = [0.0161 , 0.04709, 0.06122];
      return (
        <div>
          <div className='title-break'>
            <span>Impact of Genetics and Clinical Risk Factors on Type 2 Diabetes Risk</span>
          </div>
          <div className="card">
            <RiskFactorGraph graphMax={graphMax} data={data} />
            <p>The graph shows how risk for type 2 diabetes is affected by genetics, risk factors (height, age, weight, sex) or both combined. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts diabetes exactly and 0.00 means that there is no information. For diabetes, genetics has a smaller effect than risk factors, but combining them together has the most effect; overall, the combined score explains 6.1% of the variance in the type 2 diabetes. </p>
          </div>
        </div>
      );
    })()
  ) : null
}
            </div>

        return (
            <div>
                <div className='title-margins'>
                    <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Type 2 Diabetes</h2>
                    <h5><a href="https://pubmed.ncbi.nlm.nih.gov/33433237/">Ye et al., 2021</a></h5>
                </div>
                <div className="card p-1">
          <Collapsible trigger="Tutorial">
            <p className="intro-vid"><iframe title="Results" width="560" height="315" src="https://www.youtube.com/embed/wQDPdWV1-XU?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
          </Collapsible>
        </div>
                {testComponent}

            </div>
            
        );
    };
};


export default T2D_YeResults;
