import React from 'react';

class CaffeineIntro extends React.Component {  
  render() {
    const img_2 = window.innerWidth < 767 ? (
    <img src="/assets/imgs/results/content/rs762551.jpg" height="auto" width="100%" className="img-wrap" alt="" /> )
      : (
      <img src="/assets/imgs/results/content/rs762551.jpg" height={200} className="overview-img" alt="" /> );


    return (
      <div>
        <div className="title-margins">
          <h2>Introduction to Caffeine Sensitivity and Tolerance</h2>
          <p>written by Dr. Megan Roche, M.D. and Dr. Stuart Kim, PhD </p>
      </div>
      <div className="card">
        <img alt="" src="/assets/imgs/results/content/coffee2.jpg" height={200} className="overview-img" />
        <p>Caffeine has been shown to improve athletic performance during high intensity or endurance exercise when taken before or during exercise (<a href="http://www.ncbi.nlm.nih.gov/pubmed/7775331">Graham and Spriet, 1995</a>; <a href="http://www.ncbi.nlm.nih.gov/pubmed/18562777">Jenkens et al., 2008</a>; <a href="http://www.ncbi.nlm.nih.gov/pubmed/22142020">Desbrow et al., 2012</a>; <a href="https://www.ncbi.nlm.nih.gov/pubmed/15773860">Doherty and Smith, 2005</a>). The improvement in performance is through caffeine's direct antagonism of adenosine receptors (A1 and A2A) on the skeletal muscle membrane to induce effects on the central and peripheral nervous system to reduce pain and exertion perception (<a href="https://www.ncbi.nlm.nih.gov/pubmed/15773860">Doherty and Smith, 2005</a>), improve motor recruitment, and excitation-contraction coupling (<a href="http://www.ncbi.nlm.nih.gov/pubmed/21836046">Mohr et al., 1985</a>).  Regular use of caffeine leads to a build up of tolerance, so that higher doses are needed to achieve a beneficial effect.</p>
      </div>
        <div className="card">
          {img_2}
        <p>Caffeine is metabolized primarily by cytochrome P450 1A2, a liver enzyme responsible for metabolizing 95% of consumed caffeine (<a href="http://www.ncbi.nlm.nih.gov/pubmed/1302044">Gu et al., 1992</a>; <a href="http://www.ncbi.nlm.nih.gov/pubmed/12172216">Rasmussen et al., 2002</a>). Exercise increases this enzyme's production so that trained athletes might metabolize caffeine faster than untrained individuals. Other factors that influence the ergogenic impact of caffeine include caffeine dose, source, age, and time of day. Factors that increase cytochrome P450 activity will also influence caffeine metabolism. These factors include pregnancy, oral contraceptive use, smoking, and the menstrual cycle (<a href="https://www.ncbi.nlm.nih.gov/pubmed/28853006">Pickering and Kiely, 2018</a>).</p>

        </div>
        <div className="card">
        <p>Cytochrome P450 1A2 is encoded by the <i>CYP1A2</i> gene. The activity of <i>CYP1A2</i> varies between individuals due to genetic polymorphisms within the gene; some people are fast caffeine metabolizers whereas others are slow. <b>A key point is that the ergogenic effect of caffeine may depend on whether you are a fast or slow metabolizer of caffeine.</b> The best study of the ergogenic effect of caffeine on athletic performance was by <a href="https://www.ncbi.nlm.nih.gov/pubmed/29509641">Guest et al., 2018</a>.  This study looked at the rs762551 polymorphism in <i>CYP1A2</i>, where the A allele is associated with fast metabolism and the C allele is associated with slow metabolism. They took 101 competitive male athletes, performed a cycling time trial using 0, 2, or 4 mg of caffeine per kilogram of body mass, and compared the ergogenic effect of caffeine to the genotype of rs762551 in the <i>CYP1A2</i> gene. They found that caffeine was beneficial for fast metabolizers (6% improved time in AA genotypes) but detrimental for slow metabolizers (13% slower time in AC or CC genotypes) in the cycling time trial. This result is supported by <a href="https://www.ncbi.nlm.nih.gov/pubmed/29532291">Rahimi</a>, who found a benefit for caffeine use in resistance exercise in fast metabolizers (AA genotype), but not slow metabolizers (AC or CC genotype). These studies suggest caffeine can either enhance or be detrimental to performance depending on one’s individual genotype.</p>
        <p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4388784/">A second line of research</a> has performed a genome-wide association study to find genetic differences between people that drink a lot of coffee versus people that drink a little. Compared to studies with athletes, this study stands out because: 1) it screens the entire genome for DNA polymorphisms associated with caffeine usage (versus testing a single candidate gene in athletes) and 2) has a large cohort of ~130,000 people (versus ~100 athletes). The genome-wide study identified SNPs in eight genes to be associated with coffee drinking, including a SNP (rs2470893) in the <i>CYP1A2</i> gene.  The eight genes were assembled into a polygenic risk score to predict one’s level of caffeine intake. </p>
        <p>Interestingly, the rs2470893 polymorphism has a much stronger effect on coffee consumption than the rs762551 polymorphism used in the athletic ergogenic studies. These SNPs segregate independently in a population, meaning that one could have the fast metabolizing allele at rs2470893 and the slow metabolizing allele at rs762551. No study has yet evaluated the influence of the strong <i>CYP1A2</i> marker (rs2470893) on the ergogenic effect of caffeine on athletes.</p>
        <p>The genome-wide caffeine studies also identified a SNP in <i>AHR</i> (rs4410790) that is associated with coffee consumption. AHR is a transcription factor that turns on expression of CYP1A2. When you drink coffee, <i>AHR</i> becomes activated to turn on <i>CYP1A2</i> expression, which leads to the breakdown of caffeine. Genetic variation in <i>AHR</i> leads to different levels of <i>CYP1A2</i>, resulting in different rates of clearance of caffeine from your system. Rs4410790 lies in the <i>AHR</i> gene, and the T allele is associated with a slower rate of caffeine clearance than the C allele.</p>
        
        <p>In the results section, we report your genotype for both the rs762551 and the rs2470893 polymorphism in <i>CYP1A2</i> as well as the overall score from the 8 SNPs found for coffee consumption.</p>
      </div>
      </div>
    )
  }
}

export default CaffeineIntro;
