import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import EnsureLoggedInContainer from './components/ensure-logged-in-container';
import GATracker from './components/ga-tracker';

import ActivatePage from './pages/activate';
import BuyPage from './pages/buy';
import UpgradePage from './pages/upgrade';
import GiftPage from './pages/gift';
import ChangePasswordPage from './pages/auth/change-password';
import ReceivePage from './pages/receive';
import ConfirmEmailPage from './pages/auth/confirm-email';
import ConfirmEmailPage2 from './pages/auth/confirm-email2';
import ConfirmEmail3Page from './pages/auth/confirm-email3';
import LandingPage from './pages/landing';
import LoginPage from './pages/auth/login';
import OrderPage from './pages/order';
import PermissionsPage from './pages/permissions';
import PrivacyPage from './pages/privacy';
import RegisterPage from './pages/auth/register';
import ResetPasswordPage from './pages/auth/reset-password';
import ResultsListPage from './pages/results';
import ResultsViewerPage from './pages/result-viewer';
import TeamPage from './pages/team';
import TOSPage from './pages/tos';
import UploadFilePage from './pages/upload-file';
import TempPage from './pages/temp';
import AdminPage from './pages/admin';
import AdminConsultationsPage from './pages/consultations';
import SettingsPage from './pages/settings';
import FileNotFoundPage from './pages/error404';
import StuartPage from './pages/temp';
import BookConsultation from './pages/book-consultations';
import StrengthPage from './pages/strength-page';
import FitnessWaiverPage from './pages/fitness-waiver';
import HeroPage from './pages/hero-page';
import HeroConsentPage from './pages/hero-consent-page';
import BusinessDashboard from './pages/business-dashboard';


const AppRouter = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={GATracker(LoginPage)} />
      <Route exact path="/register" component={GATracker(RegisterPage)} />
      <Route exact path="/reset-password" component={GATracker(ResetPasswordPage)} />
      <Route exact path="/change-password" component={GATracker(ChangePasswordPage)} />
      <Route exact path="/stuart" component={GATracker(StuartPage)} />
      <Route exact path={["/hero", "/hero2", "/hero3"]} component={GATracker(HeroPage)} />
      <Route exact path="/hero-consent" component={GATracker(HeroConsentPage)} />

      {/* These routes do not use GATracker for security reasons (prevent token leaks) and cleaner analytics records */}
      <Route exact path="/change-password/:token" component={ChangePasswordPage} />
      <Route exact path="/confirm-email/:token" component={ConfirmEmailPage} />
      <Route exact path="/confirm-email2/:token" component={ConfirmEmailPage2} />
      <Route exact path="/confirm-email3/:token" component={ConfirmEmail3Page} />
      <Route exact path="/tos" component={GATracker(TOSPage)} />
      <Route exact path="/privacy" component={GATracker(PrivacyPage)} />
      <Route exact path="/safety-data-sheet" render={props => { window.location = '/assets/pdf/safety-data-sheet.pdf'; } } />
      <EnsureLoggedInContainer>
        <Switch>
          <Route exact path="/" component={GATracker(LandingPage)} />
          <Route exact path="/activate" component={GATracker(ActivatePage)} />
          <Route exact path="/buy" component={GATracker(BuyPage)} />
          <Route exact path="/upgrade" component={GATracker(UpgradePage)} />
          <Route exact path="/gift" component={GATracker(GiftPage)} />
          <Route exact path="/receive" component={GATracker(ReceivePage)} />
          <Route exact path="/order" component={GATracker(OrderPage)} />
          <Route exact path="/permissions" component={GATracker(PermissionsPage)} />
          <Route exact path="/consultations" component={GATracker(BookConsultation)} />
          <Route exact path="/results/:uploadID?" component={GATracker(ResultsListPage)} />
          <Route exact path="/results/:uploadID/:tag/:subPage?" component={GATracker(ResultsViewerPage)} />
          <Route exact path="/team" component={GATracker(TeamPage)} />
          <Route exact path="/admin" component={GATracker(AdminPage)} />
          <Route exact path="/admin/consultations" component={GATracker(AdminConsultationsPage)} />
          <Route exact path="/business" component={GATracker(BusinessDashboard)} />
          <Route exact path="/settings" component={GATracker(SettingsPage)} />
          <Route exact path="/upload" component={GATracker(UploadFilePage)} />
          <Route exact path="/temp" component={GATracker(TempPage)} />
          <Route exact path="/strength" component={GATracker(StrengthPage)} />
          <Route exact path="/fitness-waiver" component={GATracker(FitnessWaiverPage)} />

          {/* FileNotFoundPage must be the last switch case */}
          <Route component={FileNotFoundPage} />
        </Switch>
      </EnsureLoggedInContainer>
    </Switch>
  </BrowserRouter>
);

export default AppRouter;
