import React from 'react';
import RiskGraph from '../../components/risk-graph';
import GeneTable from '../../components/gene-table';

const ITBResults = (props) => {
    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Iliotibial Band Syndrome</h2>
            </div>
            <div className="card">
                <RiskGraph tag='itb' />
                <p className="mb-0">The bar graph shows your risk of ITB syndrome compared to the risk for an average person (1.00). Your risk is calculated based on your genotype at the DNA polymorphism shown in the table below.</p>
            </div>
            <div className="card">
                <p>A recent study identified a DNA polymorphism (rs76369477) in the <i>COL28A1</i> gene from a screen of the entire genome involving 909 cases of ITB syndrome (Table). The relative risk tells how strongly your genetics affects your risk of injury. If you have a C allele in rs76369477, your relative risk of injury is 2.53 (compared to 1.0). Table definitions can be shown by hovering your mouse over the column headings.</p>
                <GeneTable tag='itb' cols={['relativeRisk', 'gene'] } />
            </div>
        </div>
    );
};

export default ITBResults;
