import React from 'react';
import GeneTable from '../../components/gene-table';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
// import MarkerGraph from '../../components/marker-graph';

export const VitB12Results = (props) => {
  const { crm, result } = props;

  return (
    <div>
      <div className='title-margins'>
        <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Vitamin B12 Levels</h2>
        <h5>[<a href="https://www.ncbi.nlm.nih.gov/pubmed/23754956">Grarup et al., 2013</a>]</h5>
      </div>
      
      <div className="card">
        <BetaHistogram crm={crm} result={result} crmKey='results-vitamin-b12-histogram' />
        <p>To use this graph, find the red line that shows your genetic score.  The blue histogram shows the number of people with that genetic score. The bin containing your score tells you how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly lower level of vitamin B12.  </p>
        <p>Your genetic score for vitamin B12 level is <strong>{ typeof result.value === "number" ? `${result.value.toFixed(2)} pmol/L` : null}</strong>, which modifies your level of vitamin B12 that ranges between approximately 150-650 pmol/L.</p>
        <p><TagPercentile result={props.result} tag='vitamin-b12'/></p>
      </div>

      <div className="card">
      <p>The <b>score</b> column shows how your genotype affects your levels of vitamin B12.</p>
         <GeneTable tag='vitamin-b12' cols={[ 'gene', 'alleleFreq', 'beta', 'running-total' ] } />
         <p><i>UBN</i>, <i>CD320</i> and <i>ABCD4</i> are membrane proteins that actively transport vitamin B12 across cellular membranes. <i>MUT</i>, <i>MMA</i> and <i>MMACHC</i> encode enzymes and co-enzymes involved in vitamin B12 metabolic processes. Only <i>CLYBL</i> has a function that is not directly related to the transport, absorption and enzymatic processes of vitamin B12.</p>
         </div>

         <div className='title-break'>
          <span> The genetic test is <b>moderately</b> informative for vitamin B12</span>
          </div>
          <div className="card">
         <p>Genetic variation at these SNPs affect vitamin B12 levels by 50-150 pmol/L, which has a moderate affect on your baseline level of vitamin B12. These 15 SNPs combine to explain 6.3% of the variance in serum vitamin B12 levels, which means that vitamin B12 levels are mostly affected by diet and activity rather than genetics.</p>
          </div>
          </div>


  )
}

export default VitB12Results;
