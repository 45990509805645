import React from 'react';

class ThCIntro extends React.Component {
    render() {
        return (
            <div>
                <div className="title-margins">
                    <h2>Introduction to Thyroid Cancer</h2>
                </div>
                <div className='title-break'>
                    <span>Overview</span>
                </div>
                <div className="card">
                <img alt="" className="overview-img" src="/assets/imgs/results/ThCancer.png" height={200}/> 
                    <p>Thyroid cancer starts in the thyroid, which is a small gland in the front of your neck. The thyroid produces hormones that regulate heart rate, blood pressure, body temperature and weight. As thyroid cancer grows, symptoms include swelling in the neck, voice changes and difficulty swallowing. </p>
                </div>
                <div className='title-break'>
                    <span>Genetic Test</span>
                </div>
                <div className="card">
                    <p>The results page shows a genetic test for thyroid cancer that scans your entire genome for risk. This test shows that certain people have 4.5 fold higher risk for thyroid cancer than others. If the test indicates increased risk, screening by your physician may detect the cancer at an early stage, before there are symptoms.</p>
                </div>

            </div>
        )
    }
}

export default ThCIntro;
