import React from 'react';
import GeneTable from '../../components/gene-table';

class LactoseResults extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Lactose Intolerance</h2>
          <h5>[<a href="https://www.ncbi.nlm.nih.gov/pubmed/11788828">Enattah et al. 2002</a>;  <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2672153/">Tishkoff et al. 2007</a>]</h5>
        </div>
        <div className="card"><p>The A allele of rs4988235 and the G allele of rs145946881 are associated with the ability to metabolize lactose in adulthood. These alleles act in a dominant fashion, meaning that you only need one A allele of rs4988235 or one G allele of rs145946881 in order to metabolize lactose. Thus, adults with genotypes of A/A and A/G at rs4988235 or G/G and G/C at rs145946881 are most likely lactose tolerant. Conversely, adults with G/G at rs4988235 and C/C at rs145946881 are most likely lactose intolerant.</p>
        <p>Both polymorphisms affect the expression of a gene called lactase, <i>LCT</i>, which metabolizes lactose. In most people, <i>LCT</i> is expressed in childhood but turns off in adulthood, so that adults do not metabolize lactose well. In people with the A allele of rs4988235 or the G allele of rs145946881, <i>LCT</i> continues to be expressed so adults can metabolize lactose.</p></div>
        <div className="card">
          <GeneTable tag='lactose-intolerance' cols={[ 'gene' ] } />
          <p>Effect allele: allele asociated with lactose intolerance. People tend to be lactose intolerant if their genotype is rs4988235 (G/G) and rs145946881 (C/C).</p>
        </div>
          <div className="card">
          <p>The A allele of rs4988235 originated in Northern Europe and is found mainly in people of European descent, from 0% to 40%. The G allele of rs145946881 originated in Africa, and appears in people of African descent with allele frequencies of 0% to 46%. The rs145946881 and rs4988235 variants appeared in Africa and Europe because these two areas had cows or goats, and the ability to drink milk was an advantage to people from these areas. These variants have one of the strongest genetic signatures of natural selection yet reported in humans. The rs145946881 and rs4988235 variants arose independently around 7,000 years ago.</p>
          </div>

      </div>

    )
  }
}

export default LactoseResults;

