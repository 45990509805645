import React from 'react';

const steps = [
  {
    'title': 'Activated Kit',
    'icon':'fa-box'
  },
  {
    'title': 'Arrived at Lab',
    'icon':'fa-truck'
  },
  {
    'title': 'Genotyping',
    'icon':'fa-vials'
  },
  {
    'title': 'Results Ready',
    'icon':'fa-dna'
  }
];


export const ProgressBar = ({ currentStep, width, expectedByDate, showHeader=true }) => {
  const [progressWidth, setProgressWidth] = React.useState(0);
  const [backgroundColors, setBackgroundColors] = React.useState("");
  const [stepWidth, setStepWidth] = React.useState("");

  // set the bar width, step width and background color on mount
  React.useEffect(() => {
    // set step width
    const stepWidth = (100/steps.length).toString()+'%';
    setStepWidth(stepWidth);

    // set progress width
    const stepWidths = [0.5, 1.5, 2.5, 3.5, 4.5];
    let progWidth = currentStep < 0 ? stepWidths[Math.abs(currentStep)] / steps.length * 100 : (stepWidths[Math.abs(currentStep)] - 0.5) / steps.length * 100
    setProgressWidth(progWidth);

    // set background, which is multi colored if error
    let background = 'linear-gradient(to right,';
    if (currentStep < 0) {
      let prevStep = stepWidths[Math.abs(currentStep) - 1] / steps.length * 100;
      let errorInt = progWidth - prevStep;
      let errorPerc = (errorInt / progWidth) * 100;
      let prevPerc = (prevStep / progWidth) * 100;
      background += `#62C3D0 ${prevPerc}%, #d44950 ${errorPerc}%)`;
    } else {
      background += `#62C3D0 ${progWidth}%)`;
    };
    setBackgroundColors(background);
  }, [currentStep]);

  const getStepIconClass = (stepIndex, icon) => {
    if (stepIndex < Math.abs(currentStep)) {
      return (
        <div className='progress-step-icon complete'>
          <span className='fas fa-check' />
        </div>);
    // NOTE: Math.abs(null) === 0
    } else if (stepIndex === Math.abs(currentStep)) {
      // the former case occurs in case of an error
      return (currentStep < 0) ? (
        <div className='progress-step-icon error'>
          <span className="fas fa-times"></span>
        </div>
      ) :  (
        <div className='progress-step-icon active'>
          <span className={`fas ${icon}`}></span>
        </div>
      );
    } else {
      return <div className='progress-step-icon pending'></div>;
    };
  };

  const errorMessage = currentStep < 0 ? (
    <div className="card progress-error-text text-left p-3">
      <p className="mb-0">Your kit encountered an error at the laboratory. A member of the AxGen team will be in touch with you shortly. Please <a href="mailto:contact@axgen.us">contact us</a> if you have any questions.</p>
    </div>
  ) : null;

  const resultExpectedBy = expectedByDate !== null ? (
    <div className="text-center mt-3">
      <h5>Your results are expected by {expectedByDate}</h5>
      <p className="mb-0">Keep in mind processing times may vary and it may take up to three weeks after the above date to receive your results.</p>
    </div>
  ) : null;
  
  const barSteps = steps.map((step, i) => (
    <div key={step.title} className='progress-step' style={{width: stepWidth}}>
      <div className='progress-step-title'>
        <div className="progress-step-content h-100">
          {
            step.hasOwnProperty("shortTitle") && width < 768 ? 
            <div className="progress-text">{step.shortTitle}</div> :
            <div className="progress-text">{step.title}</div>
          }
        </div>
      </div>
      { getStepIconClass(i, step.icon) }
    </div>
  ));

  return (
    <div className="w-100 text-center container">
      {
        showHeader ? (
          <div className='title-break'>
            <span>Your Order Progress</span>
          </div>
        ) : null
      }
      {resultExpectedBy}
      <div className="row mt-3">
        { barSteps }
      </div>
      <div className="progress">
        <div className="progress-bar" role="progressbar" aria-valuenow="70"
        aria-valuemin="0" aria-valuemax="100" style={{width: `${progressWidth}%`, background: backgroundColors}}></div>
      </div>
      {errorMessage}
    </div>
  );
};

export default ProgressBar;
