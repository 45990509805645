import React from 'react';

class IBDIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
          <h2>Introduction to Intestinal Autoimmunity</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img src="/assets/imgs/results/content/gluten_free.jpg" height={300} className="overview-img" alt=""/>
          <p><b>Celiac disease</b> and <b>Inflammatory Bowel Disease (IBD)</b> are two types of autoimmune disorders that attack your intestine. </p>
          <p><b>Celiac disease</b> is a serious autoimmune disorder in which the ingestion of gluten damages your small intestine. When someone with celiac disease eats gluten, their body mounts an immune response that attacks their small intestine, which damages the lining of their small intestine and interferes with its ability to absorb nutrients properly into the body. Celiac disease is present in 1-2% of the general population and can develop at any age in people who consume gluten products. But most cases remain unrecognized and undiagnosed, which means that these individuals are untreated and exposed to the risks of long-term complications</p>
          <p><b>Inflammatory bowel disease (IBD)</b> involves chronic inflammation of the digestive tract. The two major types of IBD are ulcerative colitis (which affects the colon and rectum), and Crohn’s disease (which affects the small and large intestines, mouth, esophagus, and stomach). In the US, about 0.3% of the population has IBD with the following symptoms: abdominal pain, diarrhea, rectal bleeding, severe cramps, weight loss and anemia.  </p>
        </div>
        <div className='title-break'>
          <span>Benefits of Genetic Testing</span>
        </div>
        <div className="card">
          <p>AxGen has developed genetic tests for both celiac disease and IBD (including tests specific for Crohn’s and ulcerative colitis). Your genetics has a strong influence on your risk for celiac disease and a moderate influence on your risk for IBD. Specifically, the heritability of celiac disease is 80% and the heritability of IBD is about 20-40%. 	</p>
        </div>
        <div className="card">
          <p>Genetic testing can benefit you in at least two ways. First, if you already have either celiac disease or IBD, your genetic results give insight about how to treat your disease. If your genetic risk is low, your disease is mostly due to environmental factors; less aggressive treatments such as changing diet or lifestyle are options for improvement. If you have high genetic risk, your disease is partly due to your genetic makeup and more comprehensive treatments may be warranted. </p>
        </div>
        <div className="card">
          <p>Second, genetic testing could inform you of your risk of getting the disease, thereby empowering you to take steps to prevent the disease in the first place. Celiac disease is caused by eating gluten, which can lead to an autoimmune disorder attacking your small intestine. You can avoid celiac disease by reducing or eliminating gluten from your diet.</p> 
          <p>The causes of IBD are less clear, but likely involve diet and your microbiome. Approaches you can take to prevent IBD include:</p>
          <ul>
            <li>Eat smaller meals every two to four hours.</li>
            <li>Find healthy ways to manage stress, such as meditation, movement like tai chi, listening to music or going for a walk.</li>
            <li>Get plenty of sleep and stay physically active.</li>
            <li>Keep a food diary to identify foods that trigger IBD flares. You may find you have a food intolerance, such as lactose intolerance. If so, your body has a harder time digesting certain foods, which causes upset stomach.</li>
            <li>Reduce foods that irritate the intestines, such as those that are fibrous, spicy, greasy or made with milk. During flares, choose soft, bland foods that are less inflammatory.</li>
            <li>Cut back on caffeinated, carbonated and alcoholic beverages. Drink more water to prevent dehydration.</li>
            <li>Quit smoking.</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default IBDIntro;
