import React from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';


const HeroConsentPage = () => {
    return (
        <div>
            <Navbar stateless={true}/>
            <div className="container mt-4">
                <h1 className="mb-4"><u>Research Consent Document</u></h1>
                <div>
                    <h2><u>Consent Document - Key Points</u></h2>
                    <p>AxGen aims to make and support scientific discoveries to help find cures for chronic diseases. We invite you to take part in this project, which we call the AxGen HERO project. To get started you must agree to the terms in our full consent document (below). Here are key points of that document:</p>
                    <p>Which of my personal information is used for research?</p>
                    <ul>
                        <li>Your genetic data and any other personal information you enter into the questionnaire, except for your name and contact information, may be analyzed in the research.</li>
                        <li>Your name and contact information may be used to communicate with you but are not analyzed in combination with your genetic and other personal information.</li>
                    </ul>
                    <p>Who gets to see my personal information?</p>
                    <ul>
                        <li><i>Within AxGen</i>: AxGen researchers who conduct analyses will have access to your genetic and other personal information, but not to your name or contact information.</li>
                        <li><i>Outside of AxGen</i>: AxGen may share some data with external research partners and in scientific publications. These data will be summarized across enough customers to minimize the chance that your personal information can be revealed.</li>
                    </ul>
                </div>
                <div>
                    <h2><u>Full Consent Document</u></h2>
                    <p>AxGen seeks your voluntary participation in the AxGen HERO project.</p>
                    <p>This document explains:</p>
                    <ol>
                        <li>What you are agreeing to if you consent</li>
                        <li>How you can participate</li>
                        <li>How your data will be used</li>
                        <li>How we protect your data and keep it private</li>
                        <li>The benefits and risks of participation</li>
                        <li>Who to contact if you have questions</li>
                    </ol>
                </div>
                <div>
                    <p><strong><u>1. What am I agreeing to if I consent?</u></strong></p>
                    <p>Giving consent by checking the appropriate box below means that you agree to let AxGen researchers use your Genetic and Self-Reported Information for AxGen Research, as described above. "Genetic and Self-Reported Information" refers to:</p>
                    <ul>
                        <li>Your genetic data</li>
                        <li>Information you enter into surveys, forms and other features </li>
                        <li>Data you authorize us to import for research (if we request health information, we will ask you to review a separate Authorization form)</li>
                        <li>Your age and ethnicity</li>
                    </ul>
                    <p>Self-Reported Information includes any information you submitted prior to giving consent. Your Genetic and Self-Reported Information does not include identifying Registration Information (such as name, address, email address).</p>
                </div>
                <div>
                    <p><strong><u>2. How do I participate?</u></strong></p>
                    <p>Participation consists of allowing AxGen researchers to use your Genetic and Self-Reported Information for AxGen Research. If you do not feel comfortable providing a piece of information, you may choose not to answer that question.</p>
                    <p>For your participation, $50 will be provided as compensation for your time.</p>
                    <p>Your participation in the AxGen Research study is completely voluntary.</p>
                </div>
                <div>
                    <p><strong><u>3. How will my data be used in AxGen Research?</u></strong></p>
                    <p>Your Genetic and Self-Reported Information could be used to understand the basic causes of disease, develop drugs or other treatments and/or preventive measures, or predict an athlete's risk of injury. Some of these studies may be sponsored by or conducted on behalf of third parties, such as non-profit foundations, academic institutions or pharmaceutical companies.</p>
                </div>
                <div>
                    <p><strong><u>How do you keep my data protected and private?</u></strong></p>
                    <p>If you agree to this Consent Document, AxGen can use your information in additional ways. Specifically, AxGen researchers can use your data for research that will be published in scientific journals, or that is sponsored by the National Institutes of Health and certain other organizations.</p>
                    <p>Just as AxGen aims to minimize the chances of a privacy breach while conducting its business, AxGen researchers aim to minimize the chances of a privacy breach while conducting research. Here are the main ways we do that:</p>
                    <ul>
                        <li>The AxGen researchers who conduct the statistical analyses do not have access to Registration Information (name, address, email address) of participants.</li>
                        <li>To minimize the chance that an external person can determine that any particular customer is part of a study, AxGen researchers either
                            <ul>
                                <li>publish only data pooled across multiple participants or</li>
                                <li>publish only very limited, non-identifying information of a single individual</li>
                            </ul>
                        </li>
                        <li>All AxGen employees are trained on how to work with human research participants. In addition, all AxGen researchers are trained on how to conduct research responsibly.</li>
                    </ul>
                </div>
                <div>
                    <p><strong><u>5. What are the benefits and risks of participation?</u></strong></p>
                    <p>Benefits:</p>
                    <p>One of AxGen's missions is to make meaningful scientific contributions by enabling athlete’s to participate directly in genetic research. The genetic research may benefit all mankind by combating chronic diseases or it may benefit football at all levels by helping reduce injuries in current players.</p>
                    <p>Based on the results of this study, AxGen may develop intellectual property, including but not limited to patents, copyrights and trademarks, and/or AxGen may commercialize products or services, directly or indirectly. In such cases you will not receive any compensation.</p>
                    <p>Risks:</p>
                    <p>There are some potential risks to participating in the AxGen Research study, as described below.</p>
                    <ul>
                        <li>Your genetic data, survey responses, and/or personally identifying information may be stolen in the event of a security breach. In the event of such a breach, if your data are associated with your identity, they may be made public or released to insurance companies, which could have a negative effect on your ability to obtain insurance coverage. In addition, if you or a family member has genetic data linked to your name or your family member's name in a public database, someone who has access to your AxGen genetic data might be able to link that data to your name or your family member's name through the publicly available genetic data. Although AxGen cannot provide a 100% guarantee that your data will be safe, AxGen has strong policies and procedures in place to minimize the possibility of a breach.</li>
                        <li>When AxGen researchers publish results from this ongoing study, they may include your Genetic and Self-Reported Information but only as part of a summary across enough people to minimize the chance that your personal information will be exposed. Identification of your individual-level data from those summaries would be extremely difficult, but it is possible that a third party that has obtained some of your genetic data could compare that partial data to the published results and infer some of your other personal information.</li>
                        <li>There may be additional risks to participation that are currently unforeseeable.</li>
                    </ul>
                    <p>None of the surveys or other procedures used by the researchers in the AxGen Research study is invasive or experimental. The procedures involved do not involve more than the minimal risks described above.</p>
                </div>
                <div>
                    <p><strong><u>6. Who do I contact if I have questions?</u></strong></p>
                    <p>If you have <i><b>any questions or concerns about the AxGen’s HERO study or if you have a question about subjects' rights</b></i>, please contact the following email: <a href="mailto:contact@axgen.us">contact@axgen.us</a>.</p>
                </div>
            </div>
            <br/>
            <Footer/>
        </div>
    );
};


export default HeroConsentPage;