import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import RiskFactorGraph from '../../components/risk-factor-graph';
import { getPercentileText2} from '../../utils/results';

export const AsthmaResults = (props) => {
    const { crm, result, userName, nameEndsWithS } = props;
    let testComponent;
    let adjusted = result['adjusted_result'];
    let percentile;
    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
       adjusted = result['adjusted_result'];
    }
    if (result['value'] !== null) {
        
        testComponent =
            <div>
                <div className='title-break'>
                    <span>Your Results</span>
                </div>
                <div className="card">
                <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have higher risk for asthma. (Units for Beta are arbitrary.) </p>
                    <div>
                        {
                            adjusted ? (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-adjusted_asthma-histogram' />
                                    <p> You are in the <b>{getPercentileText2(percentile)} </b>. Your sex, height and weight have been factored in to your score to better predict your risk for asthma.</p>
                                    
                                </>
                            ) : (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-asthma-histogram' />
                                    <TagPercentile result={props.result} tag='asthma' />
                                </>
                            )
                        }

                    </div>

                </div>
                <div className='title-break'>
                    <span>How To Interpret Your Results</span>
                </div>
                <div className="card">
                <img src="/assets/imgs/results/content/FigE2.png" height={300} className="overview-img" alt="" />
                <p>You can look up your risk for asthma using this figure. Those in the &#62;95% percentile have about a 5.8-fold increased risk of asthma compared to the &lt;5% percentile.</p>
                </div>
                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">
                <p>The PRS for asthma uses 985,837 variants based on summary statistics from the Trans-National Asthma Genetic Consortium that included 23,948 cases of asthma and 118,538 controls. The PRS was validated using the UK Biobank cohort that included 16,462 cases of asthma and 398,808 controls.</p>
                <p>The asthma PRS is useful not only for prevention but also to help clinicians select treatment strategies for children already diagnosed with asthma.</p>
                <p>The multiancestral asthma PRS is being used on 5000 children in the eMERGE IV network beginning in 2022. Parents and primary care providers of children with a high-risk asthma PRS (top 5th percentile) will receive guideline-informed health recommendations.</p>
                </div>

                {
  adjusted ? (
    (() => {
      const graphMax = .085 ;
      const data = [0.039,  0.042, .08];
      return (
        <div>
          <div className='title-break'>
            <span>Impact of Genetics and Age on Risk for Asthma</span>
          </div>
          <div className="card">
            <RiskFactorGraph graphMax={graphMax} data={data} />
            <p>The graph shows how risk for asthma is affected by genetics, clinical risk factors (sex, height and weight) or both combined. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts asthma exactly and 0.00 means that there is no information. For asthma, genetics and your clinical risk factors are about equivalent, but combining them together has the most effect; overall, the combined score explains 8% of the variance in risk for asthma. </p>
          </div>
        </div>
      );
    })()
  ) : null
}
            </div>

        return (
            <div>
                <div className='title-margins'>
                <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Asthma</h2>
                <h5><a href="https://doi.org/10.1016/j.jaci.2022.03.035">Namjou et al. (2022)</a></h5>
                </div>
                {testComponent}

            </div>
            
        );
    };
};


export default AsthmaResults;
