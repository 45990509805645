import React from 'react';

//converted the component to functional for simplicity
//the above will be changed if the need for lifecycle methods arises
export const VitB12Intro = () => {
  return (
    <div>
      <div className="title-margins">
        <h2>Introduction to Vitamin B12</h2>
        <p>written by Dr. Megan Roche, M.D. and Andrew Roos </p>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
        <img src="/assets/imgs/results/content/vitb12.jpg" height={200} className="overview-img" alt=""/>
        <p>Vitamin B12 (cobalamin) is a water-soluble vitamin that is necessary for the formation of red blood cells, DNA synthesis during cell division, and maintenance of the myelin nerve sheath. Vitamin B12 is an essential nutrient, meaning it’s required for normal bodily functioning, but cannot be produced by the body itself.  Meat (such as liver, shellfish, fish, poultry), eggs and milk products contain vitamin B12. Most plant-based products do not contain vitamin B12. Deficiency in vitamin B12 is clinically associated with cardiovascular disease, cancer, and neurodegenerative disorders.</p>
        <p>Vitamin B12 is stored in substantial amounts in the liver, until it gets used by the body. The liver can store vitamin B12 for years, so deficiency from dietary habits is rare in healthy adults. Individuals who eat a vegan diet for longer than a year are at higher risk for vitamin B12 deficiency.</p>
      </div>
      <div className="card">
        <p>The Office of Dietary Supplements recommends an average daily intake of 2.4 micrograms (mcg) of vitamin B12 for men and women over 14. Pregnant women require 2.6 mcg of vitamin B12. If you are concerned about low vitamin B12 levels consider consulting with your primary care doctor.</p>
        <p>Vitamin B12 status is typically assessed via serum or plasma vitamin B12 levels. The normal range for serum vitamin B12 is 200-900 pg/mL (148-664 pmol/L). For adults, values below 200 pg/mL (148 pmol/L) may indicate a vitamin B12 deficiency and values between 200-300 pg/mL (148-221 pmol/L) are borderline deficient.</p>
      </div>
      <div className="card">
      <p>Serum vitamin B12 levels are thought to depend partly on genetics and partly on non-genetic factors. The estimated heritability is 0.59, meaning 59% of the variability in serum vitamin B12 levels is determined by genetics (<a href="https://www.ncbi.nlm.nih.gov/pubmed/19343610">Nilsson et al., 2009</a>). The main non-genetic factors that influence serum vitamin B12 levels are dietary intake of B12, absorption of B12 in the intestine, transport of B12 in the blood and cellular uptake of B12.</p>
      </div>
    </div>
  );
}

export default VitB12Intro;
