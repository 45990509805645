import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer';
import * as Actions from '../../actions';
import Alerts from '../../components/alerts';
import { useCustomDispatch } from '../../utils/customHooks';

const RegisterPage = ({ history }) => {
  // global state
  const { alerts, session } = useSelector(state => ({
    alerts: state.alerts,
    session: state.session
  }));

  // local states
  const [nextURL, setNextURL] = useState("");
  const [loginURL, setLoginURL] = useState("");
  const [disabled, disableSubmit] = useState(false);
  const [ageState, adjustAgeState] = useState({ reply: "", render: 0 });
  const [checkedOption, checkOption] = useState("");
  const [disabledResend, disableResend] = useState(false);

  // local states for the user inputs
  const [guardianName, setGuardianName] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPass, setUserPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [userName, setUserName] = useState("");
  const [referralName, setReferralName] = useState("");

  // dispatch
  const setLoginDispatch = useCustomDispatch(Actions.setLoginThunk);
  const resendConfirmationDispatch = useCustomDispatch(Actions.resendConfirmationThunk);
  const clearAnAlertDispatch = useCustomDispatch(Actions.clearSpecificAlertThunk);
  const [code, setCode] = useState(null);
  
  // helper functions
  
  // this function first sets the qOne state to the value of the target, and then sets the render based on that response
  // 1 will render the regular form for register and 2 will render the minor form
  // render is set to 0 on initialization
  // this function allows one to switch between the questions dynamically and hide the registration form if nothing is clicked on
  const _handleReply = (q) => {
    let renderVal;
    switch(q) {
      case "qOne":
        renderVal = 1;
        break;
      case "qTwo":
        renderVal = 2;
        break;
      default:
        break;
      };
    adjustAgeState({
      reply: q,
      render: renderVal
    });
  };

  // main functions

  const submitForm = (evt) => {
    //---
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    // Get the value of "some_key" in eg "https://example.com/?code=<organization_id>"
    let value = params.code; // "some_value"
    setCode(value)  
    // document.querySelector('#code').value = value;
    //---
    evt.preventDefault();
    Actions.clearAlerts();
    disableSubmit(true);
    if (userPass !== confirmPass) {
      Actions.addAlert('register', 'error', 'Passwords do not match');
      return;
    };
    Actions.attemptRegister(guardianName, guardianEmail, userEmail, userPass, userName, referralName, nextURL, value);
  };

  const resendConfirmation = (evt) => {
    evt.preventDefault();
    disableResend(true);
    clearAnAlertDispatch('resend-confirmation');
    resendConfirmationDispatch(userEmail, nextURL);
  };
  
  // lifecycle methods

  // fires on mount, then sets the nextURL state based on one's current url parameters, so the form knows where to bring a user next on submission. ie a user wants to go to their results, but their session expired, if they try to navigate to their results page directly, once they log in, they will automatically be brought to results
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const nextPath = params.get('next');
    const loginURL = nextPath ? `/login?next=${encodeURIComponent(nextPath)}` : '/login';
    setLoginURL(loginURL);
    const nextURL = nextPath ? nextPath : '/';
    setNextURL(nextURL);
    const userID = localStorage.getItem('user-id');
    if (session.userID) {
      history.push(nextURL);
    } else if (userID) {
      setLoginDispatch();
    };
  }, []);

  // redirects the user to nextURL if there is an active session already
  useEffect(() => {
    if (session.userID) history.push(nextURL);
  }, [session.userID]);

  // un-disable btns if alerts
  useEffect(() => {
    if (Object.keys(alerts).length !== 0) {
      disableSubmit(false);
      if (alerts.hasOwnProperty('resend-confirmation')) disableResend(false);
    };
  }, [alerts]);

  // resets referral state if one switches their answer from yes to no
  useEffect(() => {
    if (referralName !== "") setReferralName("");
  }, [checkedOption]);

  // resets the parent / guardian states if one toggles the top question
  useEffect(() => {
    if (guardianName !== "" || guardianEmail !== "") {
      setGuardianEmail("");
      setGuardianName("");
    };
  }, [ageState.reply]);

  // presentational elements

  // handles the rendering of the questions based on user input. the render state is dependent on the handleChange for each of the questions. the other question will only render if the render state is not "1". 
  // the abundance of div elements here is done because you can only return a single parent element in react, returning two questions not wrapped in a div results in an error
  const ageQuestions =
    <div className="reg-qs-parent">
      <p>Please select one of the following options to register:</p>
      <ul className="reg-qs">
        <li className={ageState.reply === 'qOne' ? 'li-active' : ""} onClick={() => _handleReply("qOne")}>I am at least 18 years of age and I am making this account for myself.</li>
        <li className={ageState.reply === 'qTwo' ? 'li-active' : ""} onClick={() => _handleReply("qTwo")}>I am at least 18 years of age and I am making this account for my minor child 13-17 years old.</li>
      </ul>
      <p className="mb-0">To be eligible for an AxGen account you must be at least 18 years old and registering an account for either yourself or your 13-17 year old minor child.</p>
    </div>

  // renders the age questions based on the variable above, separated it out for cleaner code
  const ageCheckboxForm =
    <div className="auth-text-section d-flex flex-column text-left mb-0">
      { ageQuestions }
    </div>

  // the following handles the referral system
  const toggleReferralLabel= referralName !== "" ? 'auth-label active' : 'auth-label';
  const toggleReferralInput = referralName !== "" ? 'form-control active' : 'form-control';
  const referralSection = 
      <div className="auth-text-section d-flex flex-column mt-3">
        <p>Did someone refer you to AxGen?</p>
        <div className="radio-buttons">
          <div className="answers">
            <label>
              <input className="mr-3 checkbox-lg" type="checkbox" value="yes" checked={checkedOption === 'yes'} onChange={(e) => checkOption(e.target.value)}/>
              Yes
            </label>
            <label>
              <input className="mr-3 checkbox-lg" type="checkbox" value="no" checked={checkedOption === 'no'} onChange={(e) => checkOption(e.target.value)}/>
              No
            </label>
          </div>
          { checkedOption === 'yes' 
            ? (
            <div className="auth-input-container mt-3 mb-0">
              <i className="fas fa-user"></i>
              <label className={toggleReferralLabel}>Referrer Name (no prefixes)</label>
              <input
                type="name"
                name="referral-name"
                onChange={(e) => setReferralName(e.target.value)}
                className={toggleReferralInput}
                value={referralName}
                required
              />
            </div>
            ) : null }
        </div>
      </div>

  // the following handles the bottom checkbox questions
  // in the class for the text, col-11 pr-0 pl-3 ensures that the textbox is not shrunk in safari or misaligned in firefox
  const bottomQs = 
  <div className="auth-bottomQs">
    <div className="d-flex align-items-center mb-3">
      <input
        type="checkbox"
        className="checkbox-lg"
        required
      />
      <div className="flex-grow-1 col-11 pr-0 pl-3">
        I have read and agree to the <a className="axgen-auth-link" href="/tos" target="blank">Terms of Use</a> and <a className="axgen-auth-link" href="/privacy" target="blank">Privacy Policy</a>.</div>
    </div>
    <div className="d-flex align-items-center mb-3">
      <input
        type="checkbox"
        className="checkbox-lg"
        required
      />
      <div className="flex-grow-1 col-11 pr-0 pl-3">I am not a resident of the European Union.</div>
    </div>
  </div>

  // handles toggling the active and non-active styles for inputs and labels. more specifically these classes create the shrinking label effect
  const togglePGName = guardianName !== "" ? 'auth-label active' : 'auth-label';
  const togglePGNameInput = guardianName !== "" ? 'form-control active' : 'form-control';
  const togglePGEmail = guardianEmail !== "" ? 'auth-label active' : 'auth-label';
  const togglePGEmailInput = guardianEmail !== "" ? 'form-control active' : 'form-control';
  const toggleFullName = userName !== "" ? 'auth-label active' : 'auth-label';
  const toggleNameInput = userName !== "" ? 'form-control active' : 'form-control';
  const toggleUserEmail = userEmail !== "" ? 'auth-label active' : 'auth-label';
  const toggleEmailInput = userEmail !== "" ? 'form-control active' : 'form-control';
  const toggleUserPass = userPass !== "" ? 'auth-label active' : 'auth-label';
  const toggleUserPassInput = userPass !== "" ? 'form-control active' : 'form-control';
  const toggleConfirmPass = confirmPass !== "" ? 'auth-label active' : 'auth-label';
  const toggleConfirmPassInput = confirmPass !== "" ? 'form-control active' : 'form-control';
  let regFormContent;
  if (ageState.render !== 0) {
    // creates the inputs for parent/guardian fields in accordance with the ageState.render
    const pgInputs = ageState.render === 2 ? (
      <div className="mb-3">
        <div className="auth-input-container">
          <i className="fas fa-user"></i>
          <label className={togglePGName}>Parent / Guardian Full Name</label>
          <input
            type="text"
            name="guardian-full-name"
            className={togglePGNameInput}
            onChange={(e) => setGuardianName(e.target.value)}
            value={guardianName}
            required
          />
        </div>
        <div className="auth-input-container">
          <i className="fa fa-envelope"></i>
          <label className={togglePGEmail}>Parent / Guardian Email</label>
          <input
            type="text"
            name="guardian-email"
            className={togglePGEmailInput}
            onChange={(e) => setGuardianEmail(e.target.value)}
            value={guardianEmail}
            required
          />
        </div>
      </div>
    ) : (
      null
    );

    regFormContent = 
      <form className="auth-form mt-3" onSubmit={(evt) => submitForm(evt)}>
        { pgInputs }
        <div className="auth-input-container">
          <i className="fas fa-user"></i>
          <label className={toggleFullName}>Full Name</label>
          <input
            type="name"
            name="full-name"
            className={toggleNameInput}
            onChange={(e) => setUserName(e.target.value)}
            value={userName}
            required
          />
        </div>
        <div className="auth-input-container">
          <i className="fa fa-envelope"></i>
          <label className={toggleUserEmail}>Email</label>
          <input
            type="email"
            name="email"
            className={toggleEmailInput}
            onChange={(e) => setUserEmail(e.target.value)}
            value={userEmail}
            required
          />
        </div>
        <div className="auth-input-container mb-0">
          <i className="fas fa-lock-alt"></i>
          <label className={toggleUserPass}>Password</label>
          <input
            type="password"
            name="password"
            autoComplete="off"
            className={toggleUserPassInput}
            onChange={(e) => setUserPass(e.target.value)}
            value={userPass}
            required
          />
        </div>
        <p className="form-sentence">Requirements: At least 8 characters long, one uppercase character, one lowercase character and one digit.</p>
        <div className="auth-input-container mb-0">
          <i className="fas fa-lock-alt"></i>
          <label className={toggleConfirmPass}>Confirm Password</label>
          <input
            type="password"
            name="confirm_password"
            autoComplete="off"
            className={toggleConfirmPassInput}
            onChange={(e) => setConfirmPass(e.target.value)}
            value={confirmPass}
            required
          />
        </div>
        { referralSection }
        { bottomQs }
        <div className="submit-row">
          <input
            type="submit"
            className="btn reg-btn"
            value="SIGN UP"
            disabled={disabled}
          />
        </div>
      </form>
  }
  
  const successReg =
    <div>
      <Alerts tag='register' level='success' />
      <form className="auth-form mt-3" onSubmit={(evt) => resendConfirmation(evt)}>
        <p>Your confirmation email was sent to {userEmail}. Did not get the email? Click the button below to resend the confirmation email. If problems persist, please <a href="mailto:contact@axgen.us">contact us</a>.</p>
        <div className="submit-row">
          <input
            type="submit"
            className="btn reg-btn"
            value="RESEND EMAIL"
            disabled={disabledResend}
          />
        </div>
      </form>
      <Alerts tag='resend-confirmation' level='success'/>
      <Alerts tag='resend-confirmation' level='error'/>
    </div>

  // the parent of the form, features the elements common to all of the forms
  // returns the success alert if a user suceeds in registering, else returns the registration form
  // Phil: if uuid, then make new order row with create_order in orders.py
  // if code then create_order()
  const success = ((alerts['register'] || {})['success'] || []).length > 0;
  const regFormParent = success ? (
    successReg
  ) : (
    <div>
      { ageCheckboxForm }
      { regFormContent }
    </div>
  );

  return (
    <div className="bg self-align-stretch">
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="auth-form-parent">
          <div className="auth-form-container">
            <div className="logreg-form">
              <div className="auth-form-header">
                  <img alt="" src="/assets/imgs/axgen-logo.png" className="logo-img" />
                  <h1>AxGen</h1>
              </div>
              <div className="auth-line"></div>
              { regFormParent }
              <Alerts tag='register' message='Failed to register' />
              </div>
            </div>
            <div className="auth-form-bottom">
              <p className="form-links mb-0" hidden={!!code}>Already have an account? <Link to={loginURL} className="btn-link">Log in</Link></p>
            </div>
          </div>
        </div>
        <Footer forceWhite={true} />
    </div>
  );
};

export default RegisterPage;