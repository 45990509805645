import React from 'react';


const MUTIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to MUTYH-Associated Polyposis</h2>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/mut_fig.png" height={300}/> 
        <p>MUTYH-associated polyposis (MAP) is a rare, hereditary condition caused by mutations in the <i>MUTYH</i> gene. People with MAP tend to develop multiple adenomatous colon polyps during their lifetime and will have an increased risk of colorectal cancer if they are not monitored closely with regular colonoscopies. An adenomatous polyp is an area where the normal cells that line the inside of the colon begin to form a mass. At first, a polyp is benign but some polyps can eventually turn malignant and spread to other parts of the body. It is likely that people with MAP will develop many polyps, and therefore their risk for colorectal cancer may be increased if these polyps are not removed.</p>
        <p>Most colorectal cancers will occur between the ages of 40 and 60. By age 60, people with MAP have about a 50 percent chance of developing colorectal cancer.</p>
        <p>The <i>MUTYH</i> gene encodes a base excision repair protein, which corrects oxidative damage to DNA. This gene is defective in people carrying mutations in both their maternal and paternal copies (e.g. homozygous). When this happens, there are many more mutations in cells as they divide and sometimes these mutations either activate a cancer-causing gene (oncogene) or they inactivate a cancer-preventing gene (tumor suppressor gene). The increased mutational burden leads to cancer, especially colorectal cancer.</p>
        <p>The most common mutations in <i>MUTYH</i> are rs34612342 and rs36053993. People carrying a mutation in <i>MUTYH</i> should tell their family members and encourage them to undergo genetic testing. </p>

      </div>
      <div className='title-break'>
        <span>Treatment</span>
      </div>
      <div className="card">
        <p>The aim of treatment is to prevent cancer and preserve a healthy, unaffected lifestyle for patients. If fewer than 20 polyps are found, they can be removed during a colonoscopy. (This is called a polypectomy.) If there are too many polyps or if they are growing too quickly to be controlled by colonoscopy, it might be necessary to remove the colon and/or rectum with surgery. While the prospect of surgery may be upsetting, it is important to realize that without it, the risk of colorectal cancer is very high.</p>
      </div>
    </div>
  )
}

export default MUTIntro;
