import React from 'react';

class HRrestIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
          <h2>Heart Rate</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        {/* left-img requires both .card-image and .card to work */}
        <div className="card">
          <div className="card-image">
            <img src="/assets/imgs/results/content/female_runner.jpeg" height={300} className="overview-img" alt="" />
          </div>
          <p>Heart rate (HR) is a crucial lever used to regulate how much blood is pumped throughout your body. It is measured by the number of contractions (beats) of your heart per minute (bpm). Monitoring your heart rate is an important tool to gauge your fitness, training load and whether you may be overtraining. You can measure your heart rate by taking your pulse, or from a variety of wearables such as sports watches and chest monitors. Your heart rate will change drastically depending on factors such as your age and overall fitness, as well as your current level of physical exertion. Monitoring your heart rate at several key stages of exertion and rest is a great way to gauge your overall cardiovascular fitness. The four major types of HR data used to assess your fitness and whether you may be overtraining are:</p>
          <ul style={{ listStyleType: 'square' }} className="mb-0">
            <li>Resting HR </li>
            <li>HR variability </li>
            <li>HR recovery  </li>
            <li>HR acceleration during exercise </li>
          </ul>
          <p>AxGen provides genetic results for each of these types of HR data. Now you can interpret the training data from your wearables based on your own individual makeup. That is, instead of using your HR data with a "one size fits all" strategy, you can use your own genetics to develop an <b>individualized</b> HR training regimen. </p>
        </div>
        <div className='title-break'>
          <span>Resting Heart Rate </span>
        </div>
        <div className="card">
          <img src="/assets/imgs/HR_graph2.jpg" height={400} className="overview-img" alt="" />
          <p>Resting heart rate is when you are in a state of idleness, such as sitting or lying down. A healthy individual&#39;s heart rate will vary according to their need to absorb oxygen or excrete carbon dioxide, as well as a multitude of other factors such as genetics, physical fitness, stress, diet and illness. While resting, you require less oxygen than when you are engaged in vigorous physical activity. </p>
          <p>For the general population, resting HR is between 60 and 100 beats per minute (bpm). <b>Trained or fit athletes have a LOWER resting heart rate than unfit athletes (see Graph). </b>Elite endurance athletes can have a heart rate as low as 30 to 40 beats per minute. Athletes have a lower heart rate because exercise strengthens the heart muscle, allowing it to pump more blood with each pump. Therefore, less beats are needed to maintain the same cardiac output as a non-athlete. A low resting heart rate is usually a healthy indicator of good cardiovascular fitness, unless the athlete experiences negative symptoms such as dizziness, fatigue or chronic illness. </p>
          <p>You can measure your resting heart rate by checking your pulse at your wrist. Place two fingers between the bone and the tendon over your radial artery (the underside of your wrist on the thumb side). Once you’ve located your pulse, count the number of beats in 15 seconds and multiply by 4 to obtain your resting heart rate in bpm.</p>
          <p>Alternatively, fitness wearables such as a sports watch or a HR chest monitor can measure your heart rate for you. </p>
        </div>
        <div className='title-break'>
          <span>Heart Rate Variability</span>
        </div>
        <div className="card">
          <img alt="" className="overview-img" src="/assets/imgs/results/content/HR_variability.jpeg" height={200} />
          <p>Heart rate variability measures how well your heart can adjust its beat in response to cues from your nervous system. If your HR is 60 bpm, your heart beats every second on the average. But some beats are slightly longer and others are slightly less than one second. This difference in your individual heart beats is called heart rate variability and reflects the healthy ability of your heart to respond to variations in demand. Heart rate variability is a positive trait that is higher in young people and in trained athletes. Low HR variability is associated with cardiovascular disease and mortality. </p>
          <p>There are numerous smart devices that measure HR variability, such as Apple, Garmin, Whoop, Oura and Biostrap HRM. These devices measure HR variability as the root mean square of the successive differences of inter beat intervals (RMSSD). RMSSD is the most common way to measure HR variability because it is  easily assessed and can be obtained from short ECG recordings. HR variabiilty is best measured during the night, as changing environmental factors during the day affect your reading. </p>
          <p>Younger people tend to have higher HR variability than older people, and males have slightly higher values than females. Elite athletes have greater HR variability than the general population, more so for endurance athletes than strength-based athletes. </p>
        </div>
        <div className='title-break'>
          <span>Heart Rate Recovery</span>
        </div>
        <div className="card">
          <div className="card-image">
            <img alt="" className="left-img" src="/assets/imgs/results/content/HR_rec.jpeg" height={200} />
          </div>
          <p>Heart rate recovery means how quickly your heart rate returns to its resting value after exercise is over. <b>Trained or fit athletes show a LARGER recovery than unfit athletes after exercise ends (see Graph). </b>Training causes your HR to return to normal faster, which increases your HR recovery. HR recovery is about 6 beats/min faster in trained subjects than in untrained subjects, measured after 60 seconds. Heart rate recovery after exercise is one of the best measures for cardiovascular shape. Heart rate recovery after exercise improves with consistent high intensity exercise, which builds cardiovascular strength. </p>
          <p>To measure your HR recovery, find your peak HR from intense exercise, and then measure your HR one minute after stopping. Calculate your HR recovery by subtracting the value after one minute from your peak HR. Another method is to simply use information from wearables like Apple Watch, Garmin or Strava that automatically measure your heart rate recovery. </p>
          <div>
            <p>There are many variations about how to measure your HR recovery and these can have a large effect on the result. So it can be tricky to compare your values for HR recovery to those from tests using other conditions. Different studies have reported heart rate recovery in athletes anywhere from 25 bpm (good) to 60 bpm (excellent) after 1 minute. A heart rate recovery of less than 20 bpm measured one minute after intense exercise is an indicator that your body is not recovering from the stress you are putting it through. Heart rate recovery after exercise will improve with consistent high intensity exercise, which builds cardiovascular strength.</p>
          </div>
          <div>
            <span>Factors Affecting HR Recovery</span>
            <ul>
              <li>Age: HR recovery is lower in older athletes.</li>
              <li>Max HR:  High peak heart rates results in higher HR recovery.</li>
              <li>Exercise Intensity: High intensity results in high heart rates and therefore a larger heart rate recovery after the cessation of exercise. However, maximal exercise may cause sympathetic activation to carry on into the early stages of recovery, which can decrease HR recovery. </li>
              <li>Endurance vs Power Athletes: Athletes engaged in intermittent sports recover HR faster than endurance athletes [<a href="https://www.ncbi.nlm.nih.gov/pubmed/22357753">Daanen et al. 2012</a>]. Amongst endurance athletes, HR recovery is higher for long distance runners compared to cyclists, because runners have higher aerobic demands than cyclists. </li>
            </ul>
          </div>
        </div>
        <div className='title-break'>
          <span>Heart Rate during Exercise (Acceleration)</span>
        </div>
        <div className="card">
          <img src="/assets/imgs/results/content/HR_ex.jpeg" height={200} className="overview-img" alt="" />
          <p>Heart Rate during exercise (HR acceleration) is how fast your heart rate increases at the start of physical activity. <b>Trained or fit athletes show a HIGHER increase than unfit athletes after exercise begins [see Graph, <a href="https://www.ncbi.nlm.nih.gov/pubmed/26888648">Bellenger et al., 2016</a>].</b> When you exert yourself, your muscles demand more oxygen, causing your heart rate to increase in order to raise cardiac output. It is important that heart rate increases appropriately during exercise so that enough blood is pumped to the muscles that need it. </p>
          <p>A simple way to measure HR increase during exercise is to measure your resting HR and your HR 5 min. after the start of your workout. Increase in HR is the difference between the HR during exercise minus the resting HR. HR acceleration can also be measured in a human performance lab, where your resting Heart Rate Increase (rHRI) can be calculated. Your HR is measured every second while you begin exercise on a cycle or treadmill, and then the rate of increase in your HR is calculated. rHRI is higher in fit individuals compared to unfit individuals. </p>
          <p>HR acceleration is useful not only to measure your fitness level, but also to assess whether or not you may be overtraining. Overtraining can result in reduced performance if the recovery period is not adequate. <a href="https://www.ncbi.nlm.nih.gov/pubmed/32884040">Bellenger et al., 2020</a> found that a reduction in HR acceleration was a marker of overtraining status.</p>
        </div>
      </div>
    )
  }
}
export default HRrestIntro;
