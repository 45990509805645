import React from 'react';

export class MCLIntro extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Introduction to MCL Injuries</h2>
          <h6>written by Dr. Megan Roche, M.D.</h6>
        </div>

        <div className='title-break'>
          <span>High Risk Sports</span>
        </div>
        <div className='high-risk-list my-3 py-1'>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/quarterback.png'/>
            <span>Football</span>
          </div>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/soccer.png' />
            <span>Soccer</span>
          </div>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/basketball.png' />
            <span>Basketball</span>
          </div>
        </div>

        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img src="/assets/imgs/results/content/mclAthlete.jpg" height={300} className="overview-img" alt=""/>
          <p>Injuries to the medial collateral ligament (MCL) are common in athletes and occur most often from a direct blow to the outside of the knee causing an extreme valgus stress. Athletes who participate in sports that involve sudden changes in direction through cutting, turning, and twisting are also at higher risk (<a href="https://www.ncbi.nlm.nih.gov/books/NBK431095/">Naqvi and Sherman, 2019</a>). The MCL is the most commonly injured ligamentous structure of the knee. Given the MCL’s role as the primary medial stabilizer of the knee, prevention of MCL injury is important for athletic performance (<a href="https://www.ncbi.nlm.nih.gov/pubmed/25983514">Al Hourani et al., 2015</a>).</p>
          <p>The MCL is a flat band of connective tissue shaped like an hour-glass that originates from the medial femoral condyle and inserts on the anterior medial tibia. Overall, the MCL is divided into the superficial medial collateral ligament (sMCL) and deep medial ligament (dMCL). The sMCL is responsible for resisting valgus forces applied to the knee through all degrees of flexion and the dMCL is responsible for providing rotational stability during extension into early flexion (<a href="https://www.ncbi.nlm.nih.gov/pubmed/20631463">Duffy and Miyamoto, 2010</a>) and (<a href="https://www.ncbi.nlm.nih.gov/pubmed/24894123">Cavaignac et al., 2015</a>).</p>
          <p>Minor trauma to the MCL can cause tearing or stretching of the superficial portions whereas higher-energy forces can cause injury to both the deep and superficial portions (<a href="https://www.ncbi.nlm.nih.gov/pubmed/28878515">Andrews et al., 2017</a>). Partial and complete tears of the MCL influence knee kinematics and increase the load on the anterior cruciate ligament (ACL) during flexion, valgus load, or internal rotational torque (<a href="https://www.ncbi.nlm.nih.gov/pubmed/19098154">Battaglia et al., 2009</a>).</p>
          <p>Grade 1 injuries involve a small amount of tearing of MCL fibers, grade 2 injuries involve disruption of a larger number of sMCL fibers with preservation of the dMCL, and grade 3 injuries involve a complete tear of the sMCL and dMCL (<a href="https://www.ncbi.nlm.nih.gov/pubmed/28878515">Andrews et al., 2017</a>).</p>
        </div>

        <div className='title-break'>
          <span>Frequency</span>
        </div>
        <div className="card">
          <p>MCL injuries most often occur when valgus or tibial external rotational forces are applied to the knee. These forces can occur from direct contact or from forceful changes in direction. Consequently, MCL injuries are common in athletes competing in physically demanding sports such as football, soccer, hockey, rugby, wrestling, judo, and skiing (<a href="https://www.ncbi.nlm.nih.gov/pubmed/16789454">Phisitkul et al., 2006</a>).</p>
          <p>MCL injuries are common in skiers and occur more often in beginner and intermediate skiers than advanced or elite skiers. Skiers with less experience tend to experience valgus-external rotation forces more often as a result of loss of balance when the center of gravity is shifted forward over the knee. In this position, contact between the inside edge of the ski tip and the ground causes abduction and external rotation of the tibia while forward momentum contributes to the valgus force (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4555526/">Shea et al., 2014</a>).  Overall, MCL injuries account for 15 to 20% of all skiing injuries and 60% of all skiing-related knee injuries (<a href="https://www.ncbi.nlm.nih.gov/pubmed/8091049">Paletta and Warren, 1994</a>).</p>
          <p>MCL injuries are also common in sports such as football. In a retrospective review of NFL combine participants between 2009 and 2015, 13.2% of players had evidence of MCL injury (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6066829">Logan et al., 2018</a>). MCL injuries can result in missed practice time and poor athletic performance. In a study of NCAA Division I football players, the average recovery time for grade 1 and grade 2 injuries was 10 days and 35 days, respectively (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6132294/">Motamedi et al., 2017</a>).</p>
          <p>Swimmers performing high volumes of breaststroke training experience increased strain on the MCL as a result of the high valgus loads that occur with the whip kick. MCL tenderness in breaststroke swimmers can occur on the femoral or tibial origin of the MCL (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3435931/">Wanivenhaus et al., 2012</a>).</p>
          <p>In NCAA ice hockey, MCL injury is the second most commonly sustained injury behind concussions. Studies report that 60% to 76% of these injuries occur in competition compared to practice (<a href="https://www.ncbi.nlm.nih.gov/pubmed/15701603/">Filk et al., 2005</a>). A case-control study examining eight college hockey seasons at one university found that 77% of MCL injuries were related to contact with another player (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3658398/">Grant et al., 2013</a>).</p>
        </div>

        <div className='title-break'>
          <span>Causes</span>
        </div>
        <div className="card">
          <p>Potential risk factors for MCL injury include prior MCL or knee injury, higher level of athletic competition, and participation in sports that involve movements such as cutting, jumping, or twisting. The scientific literature shows little evidence that MCL injury risk varies by sex. <a href="https://www.ncbi.nlm.nih.gov/pubmed/26940226">Stanley et al.</a> examined sex disparities in MCL injury in the NCCA collegiate population across five different sports and found no significant difference in MCL injury rate within sex-comparable sports.</p>
          <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/29470637">Hwang et al.</a> found an association between pronation injuries of the ankle and MCL injuries of the knee. Among 303 patients who underwent surgery for ankle fracture, 4% had an MCL injury. Proximal tibia fractures as a result of high-energy trauma are also associated with MCL injury (<a href="https://www.ncbi.nlm.nih.gov/pubmed/29451942">Samuel et al., 2018</a>).</p>
        </div>
      </div>
    )
  }
}

export default MCLIntro;
