import React from 'react';

class MgIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Magnesium</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/Mg.jpeg" height={200}/>   <p>Magnesium is the fourth most abundant mineral in the body and is necessary for nearly all biochemical processes. It helps maintain normal muscle and nerve function, keeps the bones strong, controls the heartbeat, and helps regulate blood pressure. Magnesium also controls blood sugar levels, supports the immune system and is known to be involved in energy metabolism and protein synthesis. Magnesium is also crucial for energy metabolism by the activation of enzymes known as ATPases, which are needed to generate ATP (adenosine triphosphate).</p>
        </div>
        <div className="card">

        <p>When ATP is broken down, energy is released for all muscle contractions, and when exercising strenuously, this turnover is extremely high, requiring quick synthesis of ATP. A shortfall of magnesium can limit energy production, leading to fatigue, lethargy, reduced power, muscle twitches or cramps. Chronic deficiencies of magnesium are also implicated in reduced bone mineral density and increased risk of osteoporosis as well as anemia, depression and irregular heart rate. Virtually every body system can display symptoms because systems throughout the body rely on magnesium. However, a simple magnesium deficiency could also be an underlying factor.</p>

        </div>
        <div className="card">
        <p>Magnesium requirements for athletes, especially endurance athletes, are significantly elevated when compared to the general population; about 10-20% greater. Research suggests that endurance athletes can safely consume 500 to 800 mg daily, compared to the recommended minimum of 300 to 350 mg for women and 400 to 450 mg for men.</p>
        
      </div>
      <div className="card">
        <p>Approximately 50% of total body magnesium is found in bone, and the other half is predominantly inside cells of tissues and organs. Only 1% of magnesium is found in blood, but the body works hard to maintain a constant serum magnesium concentration. A normal range of magnesium found in the blood is typically considered to be between 1.8 and 2.3 mg/dL (0.75 and 0.95 mmol/L). An individual is considered to have hypomagnesemia, or a deficiency of magnesium, when the blood concentration is below 1.8 mg/dL (0.75 mmol/L).</p>
      </div>
      <div className="card">
        <p>Green vegetables such as spinach are good sources of magnesium because the center of the chlorophyll molecule (which gives green vegetables their color) contains magnesium. Some legumes, nuts and seeds, and whole, unrefined grains are also good sources of magnesium. Pumpkin seeds are an excellent source of magnesium that can easily be incorporated into meals throughout the day.</p>
      </div>
      <div className='title-break'>
          <span>Drug Interactions</span>
        </div>
      <div className="card">
        <p>Some types of drugs can cause hypomagnesemia. These include diuretics, proton pump inhibitors, and anti-hypertensives (beta-blockers, angiotensin receptor blockers and/or ACE inhibitors). If you have an increased risk for hypomagnesemia and you take a drug that falls into the aforementioned categories, you should inform your primary care physician so that your serum magnesium levels can be monitored.</p>
      </div>
      <div className="card">
        <p>Other drugs can increase magnesium consumption and cause hypermagnesemia. These include magnesium-containing antacids and laxatives. Excessive use of these drugs can inadvertently lead to elevated levels of magnesium in the blood.</p>
      </div>
      </div>
    )
  }
}

export default MgIntro;
