import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as Actions from '../actions';

class EnsureLoggedInContainer extends React.Component {
  componentDidMount() {
    const userID = localStorage.getItem('user-id');

    if (!this.props.session.userID) {
      if (userID) {
        Actions.checkSession();
      } else {
        const attemptURL = encodeURIComponent(window.location.pathname + window.location.search);
        const { pathname } = window.location;
        if (pathname.includes('receive') || pathname.includes('activate') || pathname.includes('upload') || pathname.includes('buy') || pathname.includes('gift')) {
          this.props.history.push(`/register?next=${attemptURL}`);
        } else {
          this.props.history.push(`/login?next=${attemptURL}`);
        }
      }
    }
  }

  render() {
    if (this.props.session.userID) {
      return this.props.children;
    }
    return null;
  }
}

const mapStateToProps = state => ({
  session: state.session
});

export default withRouter(connect(mapStateToProps)(EnsureLoggedInContainer));
