import React from 'react';


export const StrengthIntro = () => {
  return (
    <div>
      <div className="title-margins">
        <h2>Muscle Strength</h2>
        <a href="https://pubmed.ncbi.nlm.nih.gov/29691431/">(Tikkanen et al., 2018</a>
      </div>
      <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img src="/assets/imgs/results/content/female_strength.jpeg" height={200} className="overview-img" alt="" />  
        <p>Increasing muscle or physical strength is the primary goal of strength training. Muscle strength is important for nearly all athletes as increased strength can help translate to increased speed, stamina, power, and explosiveness in sport.</p>
        <p>This AxGen test examines your DNA to assess your innate muscular strength. Specifically, this test will predict your hand grip strength based on genetic factors. Because strength can be influenced heavily by training and environmental factors, your exercise, lifestyle, and diet will factor greatly into your <i>actual</i> observed muscular strength.</p>
        <p>Hand grip strength is used because it is easy to test and analyze. Hand grip strength itself is not extremely important to most sports, but serves as a proxy for upper and lower body strength. Hand grip strength is a good predictor of general upper body strength, knee extension strength, and other lower body strengths [<a href="https://pubmed.ncbi.nlm.nih.gov/22755456/">(Bohannon, 2012</a>]. </p>
        </div>
      <div className='title-break'>
          <span>Benefits of Muscle Strength for Athletes</span>
        </div>
        <div className="card">
        <p>The best way to increase physical strength is through strength training. There are a wide variety of ways to strength train, from body weight exercises to lifting with weights and gym equipment. The type of sport you play, your injury risk profile, and your personal preferences for exercise should all factor into the type of training you choose to do. </p>
        <p>Regardless of the sport you play, and the type of strength training you choose to do, increasing muscle strength through strength training has several positive benefits:
        <ul >
                    <li>Help reduce injuries or severity of injury</li>
                    <li>Improves overall flexibility</li>
                    <li>Increases resting metabolism </li>
                    <li>Creates a healthier body composition between lean muscle and body fat</li>
                </ul>
        </p>
        </div>
    </div>
  );
}

export default StrengthIntro;
