import React from 'react';
import { connect } from 'react-redux';
import { findBucket } from '../../utils/results';
import BMDGraph from '../../components/bmd-graph';

const bmdChartConfig = require('../../../json/bmd-config.json');

// Displays the histrogram, the percentile and the fold increase risk for osteoporosis.
// percentile and fold increase are from the index by comparing bmdScore to avgBMD
// the graph is from crm data for bone (uses bmdScore instead of estimated_bmd). The absolute values for the bone (bmd_Score) and bone-mineral-density (estimated_bmd) scores differ.  
// Variable Names and their Meaning:
// bogCounts <= {format: list, source: bmd-config, purpose: calculate bogPercentile}
// bogSeries <= {format: list, source: bmd-config, purpose: used with user BOG-score to find which histogram bin the user should be in}
// avgBOG <= {format: list, source: bmd-config, purpose: Previously used for BOGGraphs x-axis values, doesn't seem to be used here anymore}
// bogIndex <= {format: int, source: bmd-config + bogScore, purpose: Set histogram bin in BOGGraph component (only fracture comparison)}
// bmdIndex <= {format: int, source: bmd-config + bmdScore, purpose: Set histogram bin in BMDGraph component (both fracture and t-score graphs)}
// deltaRisk <= {format: float(-70 -> 85), source: relativeRisk, purpose: more user-readable format of relative risk}
// relativeRisk <= {format: float (0.7 -> 1.85), source: bmd-config, purpose: indicator of user's BOG-fracture risk}
// percentileData <= {}
// result <= {format: float, source: server's geneticAlgorithm, purpose: equivalet to bmdScore}
// results <= {format: object, source: list of geneticAlgorith results, purpose:}
// bogScore <= {format: float, source: bmdScore modified with demographic factors, purpose: Used in BOG graphs.}
// bmdScore <= {format: float, source: server's geneticAlgorithm, purpose: It's one of the inputs for the BOG-score, and is used to calculate estimated_BMD.}
// estimated_BMD <= {format: float, source: calculated by using constants on the bmdScore, purpose: modified raw genetic score to display bmdScore with proper offsets}

// Important Terms:
// BMD -- Genetic only (also known as T-Score (physical measurement))
// BOG - BMD modified by demographic factors.
// BOGGraph -- DEPRECATED Component managing two graphs, BOGvFractures and BOGvBMD/T-score
// BMDGraph -- Component managing two graphs, BMDvFractures and BMDvTScore

// bmd refers to data from the bmd algorithm (graphType = osteo for graphing purposes).
// function gets essential bmd information
const getBMDData = (bmdScore) => {
    // setupData is the information used to create the buckets. It has the following fields:
    //  avgBMD is the average BMD Genetic Score of that bucket
    //  relativeFractureRisk/relativeOsteoRisk is the percent increased/decreased risk compared to baseline for people in the buckets
    //  avgTScore is the average TScore of a person in that bucket
    //  binSize is the percentage of people in the bucket. Sum of all binSize == 100
    //  bogCounts and bogSeries are used to calculate bogPercentile
    const setupData = bmdChartConfig.setupData;
    const { avgBMD, relativeOsteoRisk, binSize } = setupData;

    // bmd calculations
    // const bmdScore = parseFloat(result.value);
    let bmdIndex = findBucket(bmdScore, avgBMD); //bmdScore is osteo result.  avgBMD from bmdChartConfig
    const bmdRiskPercent = relativeOsteoRisk[bmdIndex];

    // bmdPercentile will return the number of people in the same or worse bucket as the person.
    const bmdPercentile = binSize.reduce(function (total, size, index) {
        if (index > bmdIndex) {
            return total;
        }
        return total + size;
    });
    console.log('bmdIndex =', bmdIndex)

    return [bmdPercentile, bmdRiskPercent, bmdIndex];
};

class BoneResults extends React.Component {
    render() {
        // NOTE: A lot of .toFixed() calculations are in this file. We may want to think about sig-figs and what the best number to round to for consistency is.
        const { result, width } = this.props;
        const bmdScore = parseFloat(result.value);
        const estimated_BMD = result['estScore'];
        const [bmdPercentile, bmdRiskPercent, bmdIndex,] = getBMDData(bmdScore);
        console.log('bmdScore =', bmdScore)
        console.log('bmdPercentile =', bmdPercentile)
        console.log('bmdRiskPercent =', bmdRiskPercent)
        console.log('estimated_BMD =', estimated_BMD)

        return (
            <div>
                <div className="title-margins">
                    <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Bone Mineral Density Results</h2>
                </div>
                <div className="card">
                    <p>Low bone density is a key factor in diagnosing osteoporosis. The <b>BMD test</b> uses 22,871 DNA markers (<a href="https://www.ncbi.nlm.nih.gov/pubmed/30048462">Kim, 2018</a>), and it&#39;s score correlates with bone mineral density.</p>
                    <p>Each genetic marker contributes a small amount of information. The total information from all markers summed together has a large effect.</p>
                </div>
                <div className='title-break'>
                    <span>Results of the <b>Bone Mineral Density</b> test. </span>
                </div>
                <div className="card">
                    <BMDGraph graphType="osteo" bmdIndex={bmdIndex} width={width} />
                    <p className="graph-comment" align="center">**Hover over the graph for more information.**</p>
                    <p><strong>{bmdPercentile}%</strong> of people have an osteoporosis genetic score equal or lower than you, indicating that your risk for osteoporosis is <strong>{(bmdRiskPercent)}</strong>, where 1.0 is the average. Your age, sex and whether you are post-menopausal (if you are female) also affect your risk.</p>
                </div>
                <div className='title-break'>
                    <span>How to use the graph </span>
                </div>
                <div className="card">
                    <p>To use the graph, find the circle that shows your genetic score. The histogram shows the range of genetic scores and the number of people with that score. The bin containing your score tells you how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have a greater risk for osteoporosis. </p>
                    <p>The line shows the risk for osteoporosis for each bin. The solid horizontal line shows your risk and the dotted line is for normal risk of osteoporosis.</p>
                    <p>If your score is on the left part of the graph and above 1.0, then you are at increased risk for osteoporosis. Conversely, if your score is on the right part and below 1.0, you have decreased risk.</p>
                </div>
                <div className='title-break'>
                    <span>What to do if your genetic test indicates risk for osteoporosis</span>
                </div>
                <div className="card">
                    <p>If your score is below the 10<sup>th</sup> percentile, we recommend that you visit OsteoStrong in either <a href="https://osteostrongla.as.me/schedule.php?appointmentType=9535338">Mar Vista</a> or <a href="https://www.osteostrongtorrance.com/first-time-schedule">Torrance</a>. OsteoStrong is a 10 minute-a-week bone strengthening center that uses cutting edge technology to increase your bone density at any age. </p>
                </div>
                <div className='title-break'>
                    <span>There is a <b>moderate</b> overlap between the bone mineral density and osteoporosis tests. </span>
                </div>
                <div className="card">
                    <p>The bone mineral density and osteoporosis genetic scores overlap somewhat (the correlation is 28%). Having both scores together gives more information about your bone health than either score alone. The BMD genetic test has MORE information about risk for osteoporosis than the osteoporosis test. A low BMD genetic score indicates a 17-fold increased risk for osteoporosis and a low osteoporosis genetic score indicates a two-fold increased risk (low scores are those below the &lt;10<sup>th</sup> percentile).  </p>
                    <p></p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        results: state.results,
    };
};

export default connect(mapStateToProps)(BoneResults);
