import React from 'react';

class LactoseRecommendation extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Lactose Intolerance Recommendations</h2>
          <p>written by Dr. Megan Roche, M.D.</p>
        </div>
          
        <div className="card">
          <p>If you are experiencing lactose intolerance-related symptoms and/or have a genetic susceptibility to lactose intolerance, a modified diet can be a good first approach. A nutritionist or registered dietitian can help guide dietary modifications. Often, it’s best to start with more broad lactose dietary restriction before gradually re-introducing more lactose products and evaluating symptoms. For many individuals, intermittent consumption of lactose products is associated with increased symptoms compared to small, consistent consumption of lactose products.</p>
        </div>
        <div className="card">
          <p>Foods that have the highest quantities of lactose include: milk, cottage cheese, yogurt, ice cream, and frozen yogurt. Higher-fat foods containing lactose may be easier to digest due to delayed gastric emptying. Cheeses (including cheddar, parmesan, mozzarella, and swiss) contain a smaller quantity of lactose. There are many lactose-free milk, ice cream, and yogurt alternatives. Soy milk, almond milk, and coconut milk form the base of many delicious dairy-free alternatives.</p>
        </div>
        <div className="card">
          <p>Lactase enzyme preparations such as Lactaid or Lactarase may help alleviate symptoms in some individuals with lactose intolerance. Lactase enzyme preparations can be taken every day, prior to consumption of lactose products, as directed by the supplement packaging.</p>
        </div>
        <div className="card">
          <p>Athletes who have lactose intolerance should be cautious when consuming lactose products prior to key training sessions or competition. Higher-intensity exercise may cause an increase in lactose intolerance symptoms.</p>
        </div>
        <div className="card">
          <p>If you are unable to consume lactose-containing products, it’s important to monitor consumption of <a href="https://ods.od.nih.gov/factsheets/Calcium-HealthProfessional/#h2">calcium</a> and <a href="https://ods.od.nih.gov/factsheets/VitaminD-HealthProfessional/#h2">vitamin D</a>. Fortunately, many dairy-free alternatives are fortified with calcium and vitamin D.  </p>
        </div>
      </div>
    )
  }
}

export default LactoseRecommendation;
