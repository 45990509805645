import React from 'react';
import ReactTooltip from 'react-tooltip'
import { _vidListMaker } from '../../utils/prehab';
import { Link } from 'react-router-dom';

const vids = {
  'Hamstring Curls': 'https://www.youtube.com/embed/P5ciNZbDkbo?rel=0',
  'Inline Lunge': 'https://www.youtube.com/embed/Oq6Dt06ya2Y?rel=0',
  "Multi-Directional Jumps": "https://www.youtube.com/embed/QXLlfYkG2sU?rel=0",
  "Depth Single Leg Box Jump": "https://www.youtube.com/embed/OuWcIFjQagU",
  'Hip Internal / External Rotation': 'https://www.youtube.com/embed/kemC5JTsPUM?rel=0'
};

const Chondro2Prevention = (props) => {
  const path = window.location.pathname; // ShoulderExercises covers both Shoulder Instability and Rotator Cuff exercises. Hence why pageHeader determines the header based on current path
  const pageHeader = path.split("/").includes("rotator-cuff") ? "Rotator Cuff Injury Prehabilitation Exercises" : "Shoulder Instability Prehabilitation Exercises"
  const vidList = _vidListMaker(vids);

  return (
    <div>
      <div className="title-margins">
        <h2>{pageHeader}</h2>
      </div>
      <div className="card p-1">
        {vidList}
      </div>
      <div className="card">
        <p className="mb-0">These exercises that can reduce your chance of runner's knee. For advice about personalizing your strength program, you can schedule a strength consultation <Link to="/consultations">here</Link>.</p>
      </div>
      <div className="card">
        <p>Steps you can take to prevent runner's knee include:
          <ul className="mb-0">
                    <li>Warm up and stretch before you work out.</li>
                    <li>Create muscle balance by strengthening your quadriceps, hamstrings, abductors, and adductors.</li>
                    <li>Wear shoe inserts that correct flat feet by increasing your arch. This will decrease the amount of pressure placed on your knees and may realign the kneecap.</li>
                    <li>Try not to run on hard surfaces, such as concrete.</li>
                    <li>Increase your exercise load slowly.</li>
                    <li>If your doctor or physical therapist suggests, try a knee brace when you work out.</li>
                </ul>
        </p>  
      </div>
      <ReactTooltip place="top" className="tooltip-axgen" effect="solid" id="exercise-types" />
    </div>
  );
};


export default Chondro2Prevention;
