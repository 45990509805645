import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';


export const HRrestRecovery = ({ userName, nameEndsWithS, uploadID, width }) => {
    // this page contains the results of multiple tests
    const { crm, uploads } = useSelector((state) => ({
        crm: state.crm,
        uploads: state.results.uploads,
    }));

    // gets the score for a given tag and returns the score sentence.  HRex has tagresult null, but ferritin has tagresult?  HRex has no results.  HRex is not a tag, so need to get it someother way?  HRex has result in upload_entry_results SQL table. uploads[uploadID] results does not include HRex but does include transferrin saturation?
    const getScoreContent = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        let score = tagResult.hasOwnProperty("value") ? tagResult['value'] : null;
        let prefixedScore;
        if (score !== null) {
            prefixedScore = score > 0 ? `+${score.toFixed(2)}` : `${score.toFixed(2)}`;
        };
        return (
            <strong>{prefixedScore}</strong>
        );
    };

    const getGraph = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        const crmKey = `results-${tag}-histogram`;
        // HRex has crmkey but no results.
        return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
    };

    const getPercentile = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        return <TagPercentile result={tagResult} tag={tag} />;
    };

    return (
        <div>
            <div className='title-margins'>
                <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Heart Rate Recovery</h2>
                <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/29769521/">Ramirez et al., 2018</a>]</h5>
            </div>
            <div className="card">
                <p>The AxGen test for Heart Rate recovery uses a genetic algorithm with 16 genetic markers developed by <a href="https://pubmed.ncbi.nlm.nih.gov/29769521/">Ramirez et al., 2018</a>. Heart rate was measured in about 68 thousand people during a 6 min cycling exercise in the <a href="https://biobank.ndph.ox.ac.uk/showcase/showcase/docs/Cardio.pdf">Cardio test</a> from UK Biobank. HR recovery was measured one minute after the exercise ended, and is calculated as the difference between the maximum HR during exercise minus the HR after one minute.  </p>
            </div>
            <div className="card">
                {getGraph("HRrec")}
                <p>The numbers indicate your HR recovery relative to other people, accounting for your height, weight, age and sex. To use this graph, find the red line that shows your genetic score in bpm. The blue histogram shows the number of people and their genetic scores for heart rate recovery. The bin containing your score tells you how many other people have a score like yours.  If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly lower recovery from exercise.  </p>
                <p>Based solely on your genetics, your predicted Heart Rate recovery score is {getScoreContent("HRrec")} bpm. </p>
                <p className="mb-0">{getPercentile("HRrec")}</p>
            </div>
            <div className='title-break'>
                <span>How does my genetic score affect my training?</span>
            </div>
            <div className="card">
                <p>The score informs how genetics affects how to treat your HR recovery data in comparison to others. For instance, if your genetic score is +1 bpm, then your HR recovery will be shifted 1 bpm higher throughout your life. This is good news as higher HR recovery is associated with fitness and health. However, this also means that the HR recovery you measure with your watch is equivalent to HR recovery that is 1 bpm LOWER for others. So if your HR recovers 60 bpm in one minute after your workout, it is equivalent to a HR recovery of 59 bpm for others. </p>
                <p>The genetic information is most useful when you are comparing your HR recovery data to others. HR recovery is affected by many factors, such as the intensity of the exercise, the type of exercise, your age and sport played. Thus, it is most useful to compare your data to athletes that have performed the same test as you.  An example would be a team that measures HR recovery from a test administered the same way. In this case, you should adjust your HR recovery score by your genetic score when comparing to other teammates.</p>
                <p>If you are tracking your own score over time, you do not need to make any adjustments. This is because your genetics do not change, and your HR recovery data is affected similarly throughout your training history. Increased HR recovery indicates improved fitness and overtraining can reduce your HR recovery.  </p>
            </div>
            <div className='title-break'>
                <span>The genetic test has a <b>small</b> effect on your heart rate recovery</span>
            </div>
            <div className="card">
                <p>The 16 SNPs in the genetic score explain 0.79% of the variability in HR recovery in the general population, which includes elderly, sedentary individuals as well as young, highly-trained athletes. The HR recovery in this highly heterogeneous population is large due to many factors such as age and training status. </p>
                <p>In a homogenous group of young, well-trained athletes, the range in HR recovery is much smaller than in the general population. The effect of genetics on heart rate recovery amongst similar athletes is likely to be much larger than 0.79%, but it will still be small compared to the effect of your training.</p>
            </div>
        </div>
    );
};

export default HRrestRecovery;
