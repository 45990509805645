import React from 'react';

class FEVIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
          <h2>Lung Function</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        {/* left-img requires both .card-image and .card to work */}
        <div className="card">
          <div className="card-image">
            <img src="/assets/imgs/results/content/female_runner.jpeg" height={300} className="overview-img" alt="" />
          </div>
          <p>blah blah</p>
          <ul style={{ listStyleType: 'square' }} className="mb-0">
            <li>Red Sox </li>
            <li>Angels </li>
            <li>Damn Yankees  </li>
          </ul>
          <p>AxGen provides genetic results for lung function. </p>
        </div>
        <div className='title-break'>
          <span>Peak Expiratory Flow </span>
        </div>
        <div className="card">
          <img alt="" className="overview-img" src="/assets/imgs/results/content/HR_variability.jpeg" height={200} />
          <p>blah </p>
          <p>blah </p>
          <p>blah </p>
        </div>
        <div className='title-break'>
          <span>FEV/FVC1</span>
        </div>
        <div className="card">
          <img alt="" className="overview-img" src="/assets/imgs/results/content/HR_variability.jpeg" height={200} />
          <p>blah </p>
          <p>blah </p>
          <p>blah </p>
        </div>
        <div className='title-break'>
          <span>Training</span>
        </div>
        <div className="card">
          <div className="card-image">
            <img alt="" className="left-img" src="/assets/imgs/results/content/HR_rec.jpeg" height={200} />
          </div>
          <p>You gotta pay the dues if you want to sing the blues. </p>

        </div>
      </div>
    )
  }
}
export default FEVIntro;
