import React from 'react';
import RiskGraph from '../../components/risk-graph';
import GeneTable from '../../components/gene-table';

const AchillesResults = (props) => {

    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Achilles Tendon Injury</h2>
                <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/33606446/">Kim et al., 2021</a>]</h5>
            </div>
            <div className="card">
                <RiskGraph tag='achilles' />
                <p className="mb-0">The bar graph shows your risk of Achilles injury compared to the risk for an average person (1.00). Your risk is calculated based on your genotypes at the four DNA polymorphisms shown in the table below.</p>
            </div>
            <div className="card">
                <p>A recent study identified four DNA polymorphisms from a screen of the entire genome involving 12,354 cases of Achilles tendon injury and 483,080 controls (Table). The relative risk tells how strongly your genetics affects your risk of injury. If you have a C allele in rs6481512, your relative risk is 0.90 (compared to 1.0) which means you have 10% decreased risk for injury. On the other hand, if you have a T allele in rs183364169, your relative risk is 2.02 indicating an increased risk of injury of 102%. Table definitions can be shown by hovering your mouse over the column headings.</p>
                <GeneTable tag='achilles' cols={['relativeRisk', 'gene'] } />
            </div>
            <div className="card">
                <p><b>Why doesn’t AxGen test for variants in collagen genes (or other candidate genes)?</b></p>
                <p className="mb-0">Other genetic testing companies report on associations of candidate genes with Achilles tendon injury. The candidate genes were identified in studies that involved a small cohort of athletes (52 to 184 athletes) and used a lenient threshold for statistical significance {"(p < 0.05)"}. But none of the published data were validated when the candidate genes were tested in a large cohort with 12,354 cases of Achilles tendon injury. Since the candidate gene associations are either not reproducible or not strong, AxGen has decided to refrain from using them.</p>
            </div>
        </div>
    );
};

export default AchillesResults;
