import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { Link } from 'react-router-dom';

export const CeliacResults = (props) => {
    const { crm, result } = props;
    let testComponent;
    if (result['value'] !== null) {    
        // let prefixedGeneticScore = result['value'] > 0 ? `+${result['value'].toFixed(2)}` : `${result['value'].toFixed(2)}`;
        
      testComponent =
      <div>
        <div className="card">
        <p>The AxGen test for gluten sensitivity uses a genetic algorithm with 2513 genetic markers developed by <a href="https://pubmed.ncbi.nlm.nih.gov/26244058/">Abraham et al., 2015</a>. </p>
        </div>
        <div className="card">
          <BetaHistogram crm={crm} result={result} crmKey='results-celiac-histogram' />
          <p>To use this graph, find the red line that shows your genetic score.  The blue histogram shows the number of people and their gluten genetic scores. The bin containing your score tells you how many other people have a score like yours. <b>If your score is on the right side of the histogram, you will tend to have a greater sensitivity to gluten. </b>Your sensitivity to gluten can be measured by looking at your chance of developing celiac disease, which is an autoimmune disease caused by eating gluten (Table).  </p>
          <p><TagPercentile result={props.result} tag='celiac'/></p>
          <p><b>Because this test is linked to celiac disease, you should consult either an <Link to="/consultations">AxGen counselor</Link> or your physician before taking action.</b></p>
        </div>
        <div className='title-break'>
          <span>Gluten sensitivity and celiac disease.</span>
        </div>
        <div className="table-container">
        <p>Your gluten genetic score is highly predictive of your risk for celiac disease [<a href="https://pubmed.ncbi.nlm.nih.gov/26244058/">Abraham et al., 2015</a>].  </p>
                <div className="table-subcontainer">
                    <table className="default-table">
                        <thead>
                            <tr>
                                <th>Gluten Genetic Score Percentile</th>
                                <th>Risk for Celiac Disease</th>
                                <th>Fold Change in Risk for Celiac Disease<sup>*</sup> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>below 35%</td>
                                <td>0%</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td>50%</td>
                                <td>2.5%</td>
                                <td>1.8x</td>
                            </tr>
                            <tr>
                                <td>80%</td>
                                <td>4.2%</td>
                                <td>3x</td>
                            </tr>
                            <tr>
                                <td>90%</td>
                                <td>7.0%</td>
                                <td>5x</td>
                            </tr>
                            <tr>
                                <td>95%</td>
                                <td>11.2%</td>
                                <td>8x</td>
                            </tr>
                        </tbody>
                    </table>
                    <p></p>
                    <p><sup>*</sup>The overall incidence of celiac disease is 1.4%. Individuals with a score below the 35th percentile are protected from getting celiac disease. Individuals at the 95th percentile have an 11.2% chance of getting celiac disease, which is 8 fold higher than the population average.   </p>
                </div>
        </div>
        <div className='title-break'>
          <span>The genetic test is <b>strongly</b> informative for gluten sensitivity</span>
        </div>
        <div className="card">
        <p>First, the overall heritability of celiac disease is 80%, which means that genetics plays a strong role compared to environment [<a href="https://pubmed.ncbi.nlm.nih.gov/16354797/">Nistico et al., 2006</a>].  </p>
        <p>Second, a gluten genetic score below the 35th percentile protects you from celiac disease whereas a score above the 95th percentile indicates an 8 fold increased risk.</p>
        <p>Third, the area under the receiver-operator curve (AUROC) for this test is 0.83, which is the ability of the genetic score to classify individuals as having celiac disease or not. The 0.83 performance metric for genetic prediction of celiac disease is one of the highest for any complex disease.  </p>
        </div>
        <div className='title-break'>
          <span>Limitations of the genetic test </span>
        </div>
        <div className="card">
        <p>Because the gluten genetic score is so informative, it is important to be aware of its limitations to prevent misunderstanding or overinterpretation.</p>
            <ul >
                    <li>One limitations is that the genetic test is based on liklihood to have <b>celiac disease.</b> There is no genetic test for non-celiac gluten sensitivity. </li>
                    <li>Individuals with a low gluten genetic score (below the 35th percentil) are unlikely to get celiac disease, but they may still be susceptible to non-celiac gluten sensitivity.</li>
                    <li>The gluten genetic score was developed for individuals of European ancestry. It is unclear how well the genetic test performs for other ancestry groups, such as Asians, Africans and Indians.</li>
                    <li>The gluten genetic score is complicated. We have done our best to present the data in a comprehensible form, but it is advisable to consult your physician or schedule an <Link to="/consultations">AxGen counseling session</Link> to better understand how to interpret the results.</li>
                    <li>AxGen’s testing should not be taken as medical advice. Do not attempt to self-diagnose based on the results of your genetic test. </li>
                </ul>
        </div>
        <div className='title-break'>
          <span>AxGen's test for gluten versus other companies</span>
        </div>
        <div className="card">
        <p>AxGen has the most complete genetic test for gluten on the market. Whereas other companies test using two genes (HLA-DQ2 and HLA-DQ8), AxGen's test includes these two genes plus thousands more that provide a stronger picture of your risk for celiac disease [<a href="https://pubmed.ncbi.nlm.nih.gov/26244058/">Abraham et al., 2015</a>]. HLA-DQ2 and HLA-DQ8 are part of the Major Histocompatibility Complex and are directly involved in the autoimmune reaction to eating gluten. However, only some people with HLA-DQ2 and HLA-DQ8 develop celiac disease and the additional information in the AxGen test helps tell you whether you are one of these people.    </p>
        </div>

      </div>

      return (
      <div>
        <div className='title-margins'>
        <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Gluten Sensitivity</h2>
        <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/26244058/">Abraham et al., 2015</a>]</h5>
        </div>
        { testComponent }

      </div>
    );
    };
};


export default CeliacResults;
