import React from 'react';

class OsteoIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
          <h2>Osteoporosis</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        {/* left-img requires both .card-image and .card to work */}
        <div className="card">
          <div className="card-image">
            <img src="/assets/imgs/results/content/osteoporosis.jpeg" height={200} className="overview-img" alt="" />
          </div>
          <p>Osteoporosis is a disease that makes your bones more hollow and less dense, and thus more likely to break (Figure). Osteopenia is a weaker form where your bone density is lower, but not as low as with osteoporosis. Osteoporosis is called a “silent disease” because you cannot feel your bones getting weaker. With either osteoporosis or osteopenia, you are at increased risk from breaking a bone from a simple fall.</p> 
          <p>Osteoporosis is more common in the elderly, as their bone density steadily decreases. Women are four times as likely to get osteoporosis compared to men, especially after menopause when bone loss gets worse. </p>
        </div>
        <div className='title-break'>
          <span>Diagnosing osteoporosis </span>
        </div>
        <div className="card">
        <p>A bone density test (termed DXA) is the best way to diagnose osteoporosis and determine a treatment plan. This test measures your bone density using T-scores, where a T-score below -2.5 indicates that you have osteoporosis and a score below -1.0 indicates osteopenia. </p>
        <p>Besides a bone scan, a questionnaire termed the <a href="https://www.sheffield.ac.uk/FRAX/tool.aspx?country=9Fracture Risk Assessment">Fracture Risk Assessment Tool (FRAX)</a> is commonly used to diagnose osteoporosis based on clinical risk factors such as: age, weight, prior fractures, family history of osteoporosis, etc.</p>
        </div>
        <div className='title-break'>
          <span>Most people with osteoporosis are unaware that they have it</span>
        </div>
        <div className="card">
        <p>Approximately 10 million Americans over the age of 50 have osteoporosis, but only 35% of these people know that they have it. The remaining 65% of people are unaware of their bone disease, and thus do not take steps to either improve their bone health or avoid a fragility fracture. These undiagnosed people are at elevated risk to break a bone from a simple fall. Osteoporosis is responsible for an estimated two million broken bones in older Americans per year, with nearly 80% occurring in people who were unaware of their condition. </p>
        </div>
        <div className='title-break'>
          <span>Advantages of a genetic test for osteoporosis </span>
        </div>
        <div className="card">
          <div className="card-image">
            <img src="/assets/imgs/results/osteostrong.jpeg" height={200} className="overview-img" alt="" />
          </div>
        <p>The <a href="https://www.ncbi.nlm.nih.gov/pubmed/30048462">genetic algorithm</a> for osteoporosis was developed in 2018 and is only available from AxGen.</p>
        <p>A real advantage of the osteoporosis genetic test is that it could inform you to strengthen your bone density. Knowing that your risk for osteoporosis is high lets you take preventative steps to strengthen your bones and prevent a fracture from a simple fall. </p>
        <p>Another advantage of this genetic test is that it works at any age, as your genetics do not change. The genetic information could inform you to take steps to prevent osteoporosis while you are relatively young, decades before you suffer loss of bone. Bone density peaks around age 30, and then steadily declines. When you are young or middle-aged, your bone health responds well to diet (Ca and vitamin D) and weight-bearing exercises. However, when you become elderly, your bone density does not respond as well, and taking medication becomes the most effective option. Individuals that undergo genetic testing when they are young or middle-aged can improve their bone density so that they retain higher bone density as they age. These people will also know to monitor their bone density with periodic bone scans, so they will be informed of their bone health status before troubles arise. </p>
        </div>

      </div>
    )
  }
}
export default OsteoIntro;
