import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TableComposer from '../../components/table-composer';
import { loadResultsThunk, loadSNPsThunk } from '../../actions';
import { useCustomDispatch } from '../../utils/customHooks';

// future todo: move the complicated data formatting to a module that can be accessed from anywhere in the app
// the above will further simplify the creation of the table configs
// tenatively this will take snps, uploads, tag, and probably extra columns and type of gene

const IbuprofenResults = (props) => {
  const [uID, setID] = useState(null);

  // the table should be presentational and not have to reply on endpoints
  // the results page needs to pass esssential information to the table
  const { uploads, snps } = useSelector((state) => ({
    snps: state.results.snps,
    uploads: state.results.uploads
  }));

  // dispatches
  const loadResults = useCustomDispatch(loadResultsThunk);
  const loadSNPs = useCustomDispatch(loadSNPsThunk);
  
  // capitalizes the riskVal so it looks better for table presentation
  // iterates word by word capitalizing first letter
  // conditional removes rogue space at end
  const _formatter = (str) => {
    let strArr = str.split(" ");
    let newStr = "";
    for (let i = 0; i < strArr.length; i++) {
      let word = strArr[i];
      let firstChar = word[0];
      let wordEnd = word.slice(1);
      if (i === strArr.length - 1) {
        newStr += (firstChar.toUpperCase() + wordEnd);
      } else {
        newStr += (firstChar.toUpperCase() + wordEnd + " ");
      }
    };
    return newStr;
  };

  // iterates through array to add extra cols to the object
  // default value of "-" for these values
  const _addExtraCols = (obj, colArr) => {
    for (let i = 0; i < colArr.length; i++) {
      let key = colArr[i];
      obj[key] = "-";
    };
    return obj;
  };

  // adds the keys for the result value and then adds the extra columns that are in an array
  // default values of "?/?" for genes, which will be changed in subsequent formatting
  const _addKeysToResult = (snpsArray)  => {
    let resultObj = {};
    for (let i = 0; i < snpsArray.length; i++) {
      let snpObj = snpsArray[i];
      let key = snpObj['id'];
      resultObj[key] = "?/?";
    };
    _addExtraCols(resultObj, ["sensitivity"]);

    return resultObj;
  };

  // stiches together the resultObj with a user's genotype for use in the gene table for ibuprofen
  const _snpStitcher = (genes, resultObj) => {
    for (let key in resultObj) {
      if (genes[key]) {
        resultObj[key] = genes[key];
      };
    };

    return resultObj;
  };

  // adds risk val to return obj under the key of 'sensitivity' so stitching together genes and snps works better
  const _addRiskVal = (geneObj, riskVal) => {
    geneObj['sensitivity'] = _formatter(riskVal);
    return geneObj;
  };

  // turns this { rs1234: ["A", "A"] } to this { rs1234: "AA" }
  // if there is no genotype for the given gene, returns "??" in the place of alleles
  const _geneCombinator = (genes) => {
    for (let gene in genes) {
      const arr = genes[gene];
      const joined = arr.join("/");
      const res = joined === "" ? "?/?" : joined;
      genes[gene] = res;
    };
    return genes;
  };
  
  // keys into the uploads state, which contains the user's results for every test, and extracts only an object of snps and user genotypes
  // ensures that the uploads state is duplicated to avoid modification of state
  // formats the genes and then adds the extra col that based on risk value into the return val
  // overall ensures that userGenes can be smoothly integrated into the resultObj
  const _dataExtractor = (tag) => {
    const extracted = uploads[uID]["results"][tag]["meta"];
    const dupedGenes = Object.assign({}, extracted["genes"]); //duplicate genes state
    const label = uploads[uID]["results"][tag]["shortLabel"];  
    _geneCombinator(dupedGenes); //add formatted genes
    _addRiskVal(dupedGenes, label); //add sensitivity to the dupedGenes state. since the sensitivity is already calculated in the results reducer and put into the "shortlabel", this value is used for presentational purposes
    return dupedGenes;
  };

  // this will combine a user's results with snps, so only one param needs to be passed into TableComposer
  // accomodates for edge case using try catch, as the line uploads[uID][tag]["meta"]["genes"] causes a fatal error if not handled properly. this error occurs when a user lacks results for a given snp
  const _fillInTableConfigs = (tag, snps) => {
    const snpsCopy = Object.assign({}, snps); //makes copy of snps so as to not mutate the original state
    const snpsArray = snpsCopy[tag]; //keys into snps to extract array
    const resultObj = _addKeysToResult(snpsArray);//creates the obj that will be returned. this line creates the keys and sets default human-readable values
    try {
      const userGenes = _dataExtractor(tag);
      _snpStitcher(userGenes, resultObj);
    } catch {
      return resultObj;
    };
    return resultObj  
  };
  
  // grabs the user's uploadID if it is not there already, loads their results
  // pulls tag from the props. tag is added to the props using the results-viewer component
  useEffect(() => {
    const { uploadID, tag } = props;
    loadResults(uploadID);
    setID(uploadID);
    loadSNPs(tag);
  }, []);

  // table does not render until the snps are loaded, preventing undefined errors
  // the goal is to match the following columns: 'sensitivity', 'genotype'
  const geneTable = snps[props.tag] && uploads[uID] ? <TableComposer tableConfig={_fillInTableConfigs(props.tag, snps)} /> : null;

  return (
    <div>
      <div className='title-margins'>
        <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Ibuprofen Sensitivity</h2>
        <h5>[<a href="https://www.ncbi.nlm.nih.gov/pubmed/15289789">Garcia-Martin et al., 2004</a>;  <a href="https://www.ncbi.nlm.nih.gov/pubmed/18694831">Lopez-Rodriguez et al., 2008</a>; <a href="https://www.ncbi.nlm.nih.gov/pubmed/30562214">Zajic et al., 2019</a>; <a href="https://www.ncbi.nlm.nih.gov/pubmed/25502615">Mazaleuskaya et al., 2015</a>]</h5>
      </div>
      <div className="card"><p>Ibuprofen is a nonsteroidal anti-inflammatory drug (NSAID) used by athletes to reduce pain or inflammation caused by exercise. Ibuprofen is normally metabolized by two cytochrome P450 enzymes (encoded by the <i>CYP2C8</i> and <i>CYP2C9</i> genes).  Both the peak level of ibuprofen and the time it lasts in your body are controlled by the activity of these enzymes.</p>
      <p>Some people have mutations in either <i>CYP2C8</i> or <i>CYP2C9</i>. This means that they do not metabolize ibuprofen well, so the level of ibuprofen is higher and it lasts longer. The most common mutation in <i>CYP2C8</i> is called <i>*3</i>, and the most common mutations in <i>CYP2C9</i> are called <i>*2</i> and <i>*3</i>.  The table shows how these mutations affect clearance of ibuprofen from your bloodstream.  People that are homozygous for the <i>CYP2C8*3</i> mutation have 10x lower clearance of ibuprofen (<a href="https://www.ncbi.nlm.nih.gov/pubmed/15289789">Garcia-Martin et al., 2004</a>). These people are very sensitive to ibuprofen. They get the same pain relief from a smaller dose, and they should wait longer than 4 hours before they take ibuprofen again. For these people, taking a high dose of ibuprofen every 4 hours could cause an overdose with gastric bleeding and kidney damage.</p>
      </div>
      <div className="card">
        <p>Table: Effect of cytochrome P450 mutations on ibuprofen clearance [<a href="https://www.ncbi.nlm.nih.gov/pubmed/15289789">Garcia-Martin et al., 2004</a>].</p>
        <div className="table-responsive">
          <table className="article-table w-100 col-sm-12 table-bordered table-condensed cf">
            <thead>
              <tr>
                <th>Mutation</th>
                <th>SNP(s)</th>
                <th>Genetic Change</th>
                <th>Ibuprofen Clearance</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CYP2C8*1</td><td></td><td>normal</td><td>100%</td>
              </tr>
              <tr>
                <td>CYP2C8*3</td><td>rs11572080, rs10509681</td><td>R139K, K399R</td><td>10%</td>
              </tr>
              <tr>
                <td>CYP2C9*1</td><td></td><td>normal</td><td>100%</td>
              </tr>
              <tr>
                <td>CYP2C9*2</td><td>rs1799853</td><td>R144C</td><td>46%</td>
              </tr>
              <tr>
                <td>CYP2C9*3</td><td>rs1799853</td><td>I359L</td><td>25%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <p>Legend:</p>
          <ul>
              <li><b>Mutation</b>: Commonly used name of the mutation.</li>
              <li><b>SNP(s)</b>: Polymorphisms used to define the mutation.</li>
              <li><b>Genetic Change</b>: Normal refers to the amino acid version found in the majority of people. The other genetic changes have the amino acid present in most people, the position of the amino acid in the CYP protein, and the amino acid present due to the mutation; e.g. R139K means R (arginine) at 139 is changed to K (lysine).</li>
              <li><b>Ibuprofen Clearance</b>: Rate of clearance of ibuprofen from your bloodstream.  Numbers less than 100% indicate that you clear ibuprofen more slowly and that ibuprofen has a stronger effect in you than normal.</li>
            </ul>
        </div>
      </div>
      <div className="card">
          <p>Your results are shown below.</p>
          { geneTable }
      </div>
    </div>
  );
};

export default IbuprofenResults;

