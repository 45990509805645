import React, {  useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import * as Actions from '../actions';
import { useCustomDispatch } from '../utils/customHooks';
import { tableGenerator } from '../utils/data-tables';
import BulkOrderForm from '../components/bulk-order-form';
import EmailOrderForm from '../components/email-order-form';
import EmailOrderUpload from '../components/email-order-upload-form';

export const OrderPage = ({ history }) => {
  // global states
  const { orders, alerts, userRole, width } = useSelector((state) => ({
    orders: state.orders,
    alerts: state.alerts,
    userRole: state.session.userRole,
    width: state.ui.width
  }));
    
  // local state
  const [optionType, chooseOption] = useState("");
  
  // dispatch
  const loadMyKitsDispatch = useCustomDispatch(Actions.loadMyKitsThunk);

  // resets alerts when forms switched
  useEffect(() => {
    Actions.clearAlerts();
  }, [optionType]);

  // fires on mount and loads a user's kits
  useEffect(() => {
    if (userRole === 'axgen-admin') {
      loadMyKitsDispatch();
    } else {
      if (userRole === 'team-manager') {
        history.push("/business");
      } else {
        history.push("/buy");
      };
    };
  }, []);

  // presentational elements

  // creates header
  const header = 
    <div className="buy-header">
      <h2>Order Kits</h2>
      <p>You can ship kits to yourself or email codes to your athletes (allowing them to ship a kit to themselves or upload their existing DNA file). You will not be charged at this time for ordering kits. Our team will contact you for invoicing later.</p>
    </div>

  // creates table
  const data = orders.myKits.map(order => {
    return [order.orderDate, order.orderID, order.inviteeEmail, order.kitBarcode, order.orderedFor, order.updatedAt, order.resultsAvailable];
  });
  const table = tableGenerator(data, ["Date Ordered", "Order ID", "Invitee Email", "Kit Barcode", "Ordered For", "Last Updated", "Results Available"], 'Your Orders', width);
  
  // putting it all together
  const cardPadding = width < 768 ? '' : 'px-1';
  const fullForm =
    <div>      
      <div className="mb-2">
        <strong>Ship Method</strong>
        <p className="mt-1">Please select your order method</p>
      </div>
      <div className="row mb-4">
        <div className="col-6">
            <div onClick={() => chooseOption('count')}
            className={classNames(`card card-hover py-3 ${cardPadding} order-option`, {"active":optionType === "count"})}>
              <div className="card-body text-center bulk-option">
                <i className="far fa-box"></i>
                <div className="mt-4 h3">Ship Kits</div>
              </div>
            </div>
        </div>
        <div className="col-6">
            <div onClick={() => chooseOption('list')}
              className={classNames(`card card-hover py-3 ${cardPadding} order-option`, {"active":optionType === "list"})}>
              <div className="card-body text-center bulk-option">
                <i className="far fa-envelope"></i>
                <div className="mt-4 h3">Email Codes</div>
              </div>
            </div>
        </div>
      </div>
      {
        optionType ? (
          optionType === 'count' ? <BulkOrderForm alerts={alerts} width={width}/> : <EmailOrderForm alerts={alerts} userRole={userRole} />
        ) : null
      }
    </div>

  return (
    <div>
      <Navbar />
      <div className="container mt-4">
        { header }
        <div className="card py-3 mb-4">
          { fullForm }
        </div>
        <div className={width > 767 ? "mb-2" : null}>
          {table}
        </div>
      </div> 
      <Footer />
    </div>
  );
}

export default OrderPage;
