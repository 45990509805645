import React from 'react';
import ReactTooltip from 'react-tooltip'
import { _tooltipper, _vidListMaker } from '../../utils/prehab';
import { Link } from 'react-router-dom';

const vids = {
  'Hair Tie Extensions': 'https://www.youtube.com/embed/E4jH6yzfBGQ?rel=0',
  'Arm Flossing': 'https://www.youtube.com/embed/8L75Z2PcDWg?rel=0',
  'Weighted Club Rotations': 'https://www.youtube.com/embed/FLMgjI2wph8?rel=0',
  'Resistance Band Wall Slides': 'https://www.youtube.com/embed/AH0406YDi-o?rel=0',
  'Wrist and Forearm Stretch': 'https://www.youtube.com/embed/jLq-QmBVCI4?rel=0'
};


const ResultsDQTPreventionPage = (props) => {
  const vidList = _vidListMaker(vids);
  const { width } = props;

  return(
    <div>
      <div className='title-margins'>
        <h2>De Quervain's Tenosynovitis Prehabilitation Exercises</h2>
      </div>
        <div className="card p-1">
          {vidList}
      </div>
      <div className="card">
        <p className="mb-0">The videos above are examples of exercises that can reduce your chance of injury. For advice personalizing your strength program based on your genetic tests, you can schedule a strength consultation <Link to="/consultations">here</Link>.</p>
      </div>
      <div className="card">
        <p>Flexibility, range of motion, and hand, wrist, forearm and shoulder strength are key for preventing de Quervain’s tenosynovitis. Exercises that improve the {_tooltipper("range of motion", "range of motion", width)} of the hand, wrist, forearm and shoulder include arm flossing and the wrist and forearm stretch. The goal of arm flossing is to restore nerve function and active range of motion stemming from the chest as well as the wrist and forearm flexors. Proper innervation and mobility must be restored for the forearm extensors to work correctly. Also, improving the flexibility of the thenar eminence muscles through thumb extension and abduction can help lengthen musculature that might be tightened as a result of repetitive movements through the thumb from gripping, pinching, or grasping. The wrist and forearm stretch targets these muscles, which affect wrist movement, primarily in extension and flexion. Restoring both movements to a neutral range of motion reduces tension through the thenar eminence, which aids in wrist kinematics. Flexibility through the thenar eminence muscles can improve overall range of motion of the thumb.</p>
        <p>{_tooltipper("Strength exercises", "strength", width)} targeting the hand, wrist and forearm, like hair tie extensions and weighted club rotations, decrease the risk of developing de Quervain’s and can be used for prehabilitation and prevention (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/26839455">Rabin et al., 2015</a>). Overactive or shortened flexors in the forearm and hand are primary factors in De Quervain’s tenosynovitis. By strengthening the extensors, hair tie extensions aim to eliminate muscular imbalance. Also, slow, controlled eccentric loads have been shown to increase elasticity and tendon health. Weighted club rotations aim to create strength in both directions of rotation through the wrist whilst increasing tendon elasticity.</p>
        <p className="mb-0">Finally, exercises like resistance band wall slides can strengthen proximal muscle groups, providing a more stable base to reduce stress on the elbow, wrist, and hand. The fascial lines of tension stem from the chest and shoulder complex and feed down into the forearms and hands. Resistance band wall slides restore ranges of motion at the chest and shoulder increases the function of the wrist and hand.</p>
      </div>
      <ReactTooltip place="top" className="tooltip-axgen" effect="solid" id="exercise-types" />
    </div>
  );
};

export default ResultsDQTPreventionPage;
