import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

class PrivacyPage extends React.Component {
  render() {
    return (
      <div>
        <Navbar stateless={true} />
        <div className="container mt-5">
          <h1 className="mb-4"><u>Privacy Policy</u></h1>
          <p className="mb-5">Effective date: 4/1/2019</p>
          <p>We at AxGen know you care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our Privacy Policy. <strong>By using or accessing the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways.</strong></p>
          <p>Remember that your use of AxGen’s Services is at all times subject to the <Link to="/tos">Terms of Use</Link>, which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the <Link to="/tos">Terms of Use</Link>.</p>
          <p><strong><u>What does this Privacy Policy cover?</u></strong></p>
          <p>This Privacy Policy covers our treatment of personally identifiable information (“Personal Information”) that we gather when you are accessing or using our Services, but not to the practices of companies we don’t own or control, or people that we don’t manage. We gather various types of Personal Information from our users, as explained in more detail below, and we use this Personal Information internally in connection with our Services, including to personalize, provide, and improve our services, to allow you to set up a user account and profile, to contact you and allow other users to contact you, to fulfill your requests for certain products and services, and to analyze how you use the Services. In certain cases, we may also share some Personal Information with third parties, but only as described below.</p>
          <p>As noted in the <Link to="/tos">Terms of Use</Link>, we do not knowingly collect or solicit personal information from anyone under the age of 13. If you are under 13, please do not attempt to register for the Services or send any personal information about yourself to us. If we learn that we have collected personal information from a child under age 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact us at <a href="mailto:contact@axgen.us">contact@axgen.us</a> or 956 Mears Ct. Stanford, CA 94305.</p>
          <p>Please note that at this time we do not offer our Service in the European Union (“EU”) or European Economic Area. If you are a resident of the EU, United Kingdom, Lichtenstein, Norway, or Iceland, please do not sign up for the Service or send us your Personal Information.</p>

          <p><strong><u>Will AxGen ever change this Privacy Policy?</u></strong></p>

          <p><strong><u></u></strong></p>
          <p></p>


          <p>>We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time as well, we will alert you to changes by placing a notice at <a href="https://www.axgen.us/">https://www.axgen.us/</a>, by sending you an email, and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them.  If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes.  Use of information we collect now is subject to the Privacy Policy in effect at the time such information is collected.</p>

          <p><strong><u>What Information does AxGen Collect?</u></strong></p>
          <p><i><u>Information You Provide to Us:</u></i></p>
          <p>When you purchase our Services or create an AxGen account and register your kit, we collect Personal Information, such as your name, date of birth, billing and shipping address, payment information (e.g., credit card) and contact information (e.g. email, phone number, license number). You may decide to provide us with additional information about yourself through surveys, forms and applications, such as your injury history and personal traits (e.g. height and weight) “User-Reported Information.”</p>
          <p>If you provide your third-party account credentials to us or otherwise sign in to the Services through a third party site or service, you understand some content and/or information in those accounts (“Third Party Account Information”) may be transmitted into your account with us if you authorize such transmissions, and that Third Party Account Information transmitted to our Services is covered by this Privacy Policy; for example, if you log into your account via LinkedIn, we may automatically collect your contact information from your third-party account.</p>
          <p>We may communicate with you if you’ve provided us the means to do so. If you’ve given us your email address, we may send you promotional email offers on behalf of other businesses, or email you about your use of the Services. Also, we may receive a confirmation when you open an email from us. This confirmation helps us make our communications with you more interesting and improve our services.  If you do not want to receive communications from us, please indicate your preference by contacting us at <a href="mailto:contact@axgen.us">contact@axgen.us</a>.</p>

          <p><i><u>Information related to our genetic testing services</u></i></p>
          <p>You must create an online account. You will be asked to review our consent document for sample storage and additional genetic analyses. In order to receive your genetic results you must either purchase and register an AxGen genetic services testing kit or, if you already have a compatible genotype file, upload it to our site. If you submit a saliva sample, our contracted genotyping laboratory processes and analyzes your sample in order to provide us with your raw Genetic Data; our laboratory will analyze your DNA by extracting it from your saliva sample. If you do not consent to sample storage (“Biobanking”), and additional analyses and you submitted a sample to our third party laboratory, your saliva sample and DNA are destroyed once the laboratory finishes its work, subject to the laboratory's legal and regulatory requirements. If you have submitted a saliva sample to the laboratory, after your sample has completed processing you can update your sample storage preference to discard a stored sample within your account settings. However, in no case will AxGen have any obligation to store samples or to perform additional analyses. </p>

          <p><i><u>Information Collected Automatically</u></i></p>
          <p>Whenever you interact with our Services, we automatically receive and record information on our server logs from your browser or device, which may include your IP address, geolocation data, device identification, “cookie” information, the type of browser and/or device you’re using to access our Services, and the page or feature you requested. “Cookies” are identifiers we transfer to your browser or device that allow us to recognize your browser or device and tell us how and when pages and features in our Services are visited and by how many people.  You may be able to change the preferences on your browser or device to prevent or limit your device’s acceptance of cookies, but this may prevent you from taking advantage of some of our features.  </p>
          <p>Our advertising partners may also transmit cookies to your browser or device, when you click on ads that appear on the Services. Also, if you click on a link to a third party website or service, a third party may also transmit cookies to you.  Again, this Privacy Policy does not cover the use of cookies by any third parties, and we aren’t responsible for their privacy policies and practices.  Please be aware that cookies placed by third parties may continue to track your activities online even after you have left our Services, and those third parties may not honor “Do Not Track” requests you have set using your browser or device.</p>
          <p>We may use this data to customize content for you that we think you might like, based on your usage patterns.  We may also use it to improve the Services – for example, this data can tell us how often users use a particular feature of the Services, and we can use that knowledge to make the Services interesting to as many users as possible. We and our third party service providers do not use your sensitive information, such as Genetic Data and User-Reported Information, for targeted advertising.</p>

          <p><i><u>Information Collected From Other Websites and Do Not Track Policy</u></i></p>
          <p>Through cookies we place on your browser or device, we may collect information about your online activity after you leave our Services.  Just like any other usage information we collect, this information allows us to improve the Services and customize your online experience, and otherwise as described in this Privacy Policy.  Your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and services (including behavioral advertising services) that you do not wish such operators to track certain of your online activities over time and across different websites.  Our Services do not support Do Not Track requests at this time, which means that we collect information about your online activity both while you are using the Services and after you leave our Services.</p>
          <p>We continuously work to enhance our Services with new products, applications and features that may result in the collection of new and different types of information. We will update our Privacy Policy and/or obtain your prior consent to new processing, as needed.</p>

          <p><strong><u>Use of Personal Information</u></strong></p>
          <p>AxGen will use and share your Personal Information only in the ways that are described in this Privacy Policy:</p>
          <p><i><u>To provide you with Services and analyze and improve our Services. </u></i></p>
          <p>We use the information described above to operate, provide, analyze and improve our Services. These activities may include, among other things, using your information in a manner consistent with this Privacy Policy to:</p>
          <ol type="a">
            <li>open your account, enable purchases and process payments, communicate with you, and implement your requests;</li>
            <li>enable and enhance your use of our website and mobile application(s), including authenticating your visits, providing personalized content and information, and tracking your usage of our Services;</li>
            <li>contact you about your account, and any relevant information about our Services (e.g. policy changes, security updates or issues, etc.);</li>
            <li>enforce our Terms of Use and other agreements;</li>
            <li>monitor, detect, investigate and prevent prohibited or illegal behaviors on our Services, to combat spam and other security risks; and</li>
            <li>perform research & development activities, which may include, for example, conducting data analysis and research in order to develop new or improve existing products and services, and performing quality control activities.</li>
          </ol>

          <p><i><u>To process, analyze and deliver your genetic testing results</u></i></p>
          <p>As noted above, you must create an AxGen account. In order to receive your genetic results you must either purchase and register our genetic services testing kit or, if you already have a compatible genotype file, upload it to our site. If you submit a saliva sample, the laboratory processes and analyzes your sample in order to provide us with your raw Genetic Data. “Genetic Data” means information regarding your genotype (such as, the locations and sequences of the nucleotide bases (the A, Ts, Gs and Cs in your genome)). We analyze your raw Genetic Data to provide you with informational reports. We continuously strive to improve our Services as a result of research and product development, as well as genetic associations identified in scientific literature. You may be notified of, or may directly access, updates if you are eligible to receive additional updates or reports in the future.</p>

          <p><i><u>To allow you to share your Personal Information with others</u></i></p>
          <p>AxGen allows you to share information, including Personal Information, through the Services. You may share information directly with individuals with AxGen accounts through our sharing features and tools. You also have the option to share information directly with others who have not participated in our Services via a unique, shareable URL or through a social media platform (such information is your User Submission). Some sharing features, including receiving sharing invitations, may require you to opt-out but you will always be forced to opt in or take some other positive action to share sensitive data.</p>

          <p><i><u>To allow you to share your Personal Information for AxGen Research purposes</u></i></p>
          <p>You may participate in AxGen Research if you provide your consent. “AxGen Research” refers to research we conduct directed at publication in peer-reviewed journals and other research funded by the federal government (such as the National Institutes of Health, “NIH”).</p>
          <p>AxGen Research may study a specific group or population, conduct or support the development of training programs or otherwise create, commercialize, and apply this new knowledge to improve sports training. AxGen Research uses Aggregate Information (as defined below) and/or User-Reported Information and Genetic Data as specified in the appropriate consent document(s), as explained in greater detail below.</p>
          <p>If and only if you have consented by completing a consent document, your de-identified Genetic Data and User-Reported Information may be used for AxGen Research, but it will be de-identified and will not be linked to your registration information. AxGen may use individual-level data and User-Reported Information internally at AxGen for research purposes. We may also provide summary statistics to our qualified research collaborators, which do not identify any individual person.</p>
          <p>Finally, in addition to the uses above under the main consent document, AxGen may share de-identified individual-level Genetic Data and User-Reported Information with certain third party research collaborators for AxGen Research purposes if (and only if) you have completed an individual level data consent agreement, or additional consent agreement.</p>
          <p>You have the ability to withdraw your consent to participate in AxGen Research by changing your consent status within your account settings at any time. Please contact us at <a href="mailto:contact@axgen.us">contact@axgen.us</a> if you are unable to change your consent status. We will not include your Genetic Data or User-Reported Information in studies that begin over 30 days after you withdraw (it may take up to 30 days to withdraw your information once you have withdrawn your consent). We will not reverse, undo, or withdraw any research involving your data that has been conducted or published prior to your withdrawal from AxGen Research. In addition, you may discontinue your participation in AxGen Research by deleting your AxGen account (as described below).</p>
          <p>Your Personal Information will not be used for AxGen Research if you decide not to complete a consent document or any additional agreement with AxGen. Your Genetic Data and User-Reported Information, however, may still be used by us and shared with our third party service providers to as described in this Privacy Policy.</p>
          <p>We want to make sure interested participants know about additional opportunities to contribute to new scientific research conducted by academic institutions, healthcare organizations and other groups. We may inform you about third party research opportunities for which you may be eligible if you have decided to participate in AxGen Research. For instance, if a university informs us about a novel research project, we may send an email to AxGen research participants who could fit the relevant eligibility criteria based on their User-Reported Information. We would do so to tell them about the research project and provide a link to participate with the research organization conducting the study. Yet we will not share individual-level Genetic Data or User-Reported Information with any third party without your consent. You can manage these notifications if you do not wish to receive them by editing your preferences in your account settings.</p>

          <p><strong><u>Will AxGen Share Any of the Information it Receives?</u></strong></p>
          <p>We share the information described above with our third party service providers in order for them to provide their services to us and help us perform the Services, which include providing cloud storage or hosting services, marketing assistance and customer support. Although we conduct most data processing activities directly in order to provide our Services to you, AxGen engages certain third party service providers to help us in supporting our Services, including in the following areas:</p>
          <p><strong>Our certified genotyping lab.</strong> Unless you already have a compatible genotype file that you choose to upload to our website in order to receive your genetic results, the following section applies. You must purchase an AxGen genetic services testing kit, and send your saliva sample to our third party laboratory in order to use our genetic testing services. Personnel at the laboratory remove and discard kit packaging upon delivery, which in some cases may contain sender information (such as name and address). Receiving personnel do not conduct testing. Testing personnel handle saliva samples that are identified solely by a unique barcode. When the laboratory has completed their analysis, they send the resulting Genetic Data to us identified by your unique barcode.</p>
          <p><strong>Information that’s been de-identified.</strong> We may share Aggregate Information with third parties. “Aggregate Information” means information that removes your name and contact information and is combined with others’ information in such a way that you cannot reasonably be individually identified. Aggregate Information is different from individual-level information and is not Personal Information since it does not identify any specific person or disclose any person’s individual’s data. On the other hand, individual-level Genetic Data or User-Reported Information consists of data about a specific person's genotypes, or consist of all of the Genetic Data about that user. AxGen will request your consent to share individual-level Genetic Data or User-Reported Information with any third party, other than our service providers as required so that we can provide you the Services.</p>
          <p><strong>Advertisers.</strong> We allow advertisers and/or merchant partners (“Advertisers”) to choose the demographic information of users who will see their advertisements and/or promotional offers and you agree that we may provide any of the information we have collected from you in non-personally identifiable form to an Advertiser, in order for that Advertiser to select the appropriate audience for those advertisements and/or offers. For example, we might use the fact you are located in San Francisco to show you ads or offers for San Francisco businesses, but we will not tell such businesses who you are. Or, we might allow Advertisers to display their ads to users with similar usage patterns to yours, but we will not disclose usage information to Advertisers except in aggregate form, and not in a manner that would identify you personally.  Note that if an advertiser asks us to show an ad to a certain audience or audience segment and you respond to that ad, the advertiser may conclude that you fit the description of the audience they were trying to reach.</p>
          <p>We may deliver a file to you through the Services (known as a “web beacon”) from an ad network.  Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting for us and for advertisers.  Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites.  Because your web browser must request these advertisements and web beacons from the ad network’s servers, these companies can view, edit, or set their own cookies, just as if you had requested a web page from their site.  You may be able to opt-out of web beacon tracking conducted by third parties through our Services by adjusting the Do Not Track settings on your browser; please note that we don’t control whether or how these third parties comply with Do Not Track requests.</p>
          <p><strong>Analytics.</strong> We use third party analytics service providers, including Google Analytics, for our business purposes, including but not limited to improving and developing our Services, monitoring and analyzing use of our Services, and increasing the functionality and user-friendliness of our Services. When you use our Services, these analytics service providers may collect and retain some information about you by planting a persistent cookie or identifier on your web browser or device. If you would like more information on how Google uses data when you use the Site, please refer to the “How Google uses data when you use our partners' sites or apps” section of Google’s policies, located <a href="https://policies.google.com/technologies/partner-sites">here</a>.</p>
          <p><strong>Agents:</strong> We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you. For instance, if you submit a saliva sample, our third party laboratory processes your sample. We may also use a third party payment processor to receive and process your credit card transactions for us. Unless we tell you differently, our agents do not have any right to use the Personal Information we share with them beyond what is necessary to assist us. Note that an “agent” may also be considered a “partner” in certain circumstances, and would be subject to the terms of the “<strong>Information that’s been de-identified</strong>” section in that regard.</p>
          <p><strong>Business Transfers.</strong> We may choose to buy or sell assets, and may share and/or transfer customer information in connection with the evaluation of and entry into such transactions. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information could be one of the assets transferred to or acquired by a third party.</p>
          <p><strong>Protection of Company and Others:</strong> We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to comply with law or court order; enforce or apply our <Link to="/tos">Terms of Use</Link> and other agreements; or protect the rights, property, or safety of AxGen, our employees, our users, or others. AxGen and our third party genotyping laboratory (if you submit a saliva sample) will retain your Genetic Data, date of birth, and sex as required for compliance with applicable legal requirements, including the CA Business and Professional Code Section 1265 and federal Clinical Laboratory Improvement Amendments of 1988 (CLIA) and College of American Pathologists (CAP) accreditation requirements. AxGen will also retain limited information related to your account and data deletion request, including but not limited to, your account deletion request identifier, email address, and record of legal agreements for a limited period of time as required by contractual obligations, and/or as necessary to establish, exercise or defend legal claims and for audit and compliance purposes.</p>
          <p>We will withhold disclosure of your Personal Information if you are participating in AxGen Research in response to judicial or other government subpoenas, warrants or orders in accordance with any applicable Certificate of Confidentiality that AxGen has obtained from the NIH. Please visit the NIH’s Certificate of Confidentiality site here to learn about the limits to what the Certificate of Confidentiality covers.</p>

          <p><strong><u>Is Personal Information about me secure?</u></strong></p>
          <p>Your account is protected by a password for your privacy and security.  If you access your account via a third party site or service, you may have additional or different sign-on protections via that third party site or service. You must prevent unauthorized access to your account and Personal Information by selecting and protecting your password and/or other sign-on mechanism appropriately and limiting access to your computer or device and browser by signing off after you have finished accessing your account.</p>
          <p>We endeavor to protect the privacy of your account and other Personal Information we hold in our records, but unfortunately, we cannot guarantee complete security.  Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time.</p>

          <p><strong><u>What Personal Information can I access?</u></strong></p>
          <p>You have the ability to access and update your registration information within your account settings. In addition, you may review and update your consent to AxGen Research and Biobanking. You may be able to correct User-Reported Information within your account. Please note that you may not be able to delete User Submissions that have been shared with others through the Services or information that has been shared with third parties.</p>

          <p><strong>Account deletion.</strong> You may delete your AxGen account and Personal Information within your account settings if you no longer wish to participate in our Services or no longer wish to have your Personal Information processed. We will send an email to the email address connected with your AxGen account describing our account deletion policy and requesting that you confirm your deletion request after you submit your request. After you confirm your request, this process cannot be cancelled or reversed. All Personal Information is deleted and any stored samples associated with your account are discarded once your account is deleted, except to the extent such information was previously included in AxGen Research. Genetic Data and/or User-Reported Information that you have provided previously and for which you have given consent to use in AxGen Research cannot be removed from completed studies that use that information, as stated in any applicable consent document. We will not include your Genetic Data or User-Reported Information in studies that begin over 30 days after you withdraw (it may take up to 30 days to withdraw your information once you have withdrawn your consent). A second exception is legal retention requirements (as further described in the section titled <strong>Protection of Company and Others</strong>).</p>
          <p>Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Information to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at <a href="mailto:contact@axgen.us">contact@axgen.us</a>. Additionally, California residents are entitled to ask us for a notice identifying the categories of Personal Information which we share with our affiliates and/or third parties for marketing purposes, and providing contact information for such affiliates and/or third parties.  If you are a California resident and would like a copy of this notice, please submit a written request to: <a href="mailto:contact@axgen.us">contact@axgen.us</a>.</p>

          <p><strong><u>What if I have questions about this policy?</u></strong></p>
          <p>If you have any questions or concerns regarding our privacy policies, please send us a detailed message to <a href="mailto:contact@axgen.us">contact@axgen.us</a>, and we will try to resolve your concerns.</p>
          <br/><br/><br/>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default PrivacyPage;
