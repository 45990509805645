import React from 'react';
import IntroTable from '../../components/introTable';

const tableData = [
    ["M. football", 141, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. volleyball", 62, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["M. basketball", 61, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. basketball", 48, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["M. soccer", 21, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["M. ice hockey", 20, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["M. indoor track", 20, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. lacrosse", 17, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. softball", 17, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["M. lacrosse", 15, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. indoor track", 14, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["M. wrestling", 13, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["M. baseball", 12, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. ice hockey", 12, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. cross-country", 10, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["M. cross-country", 9, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. outdoor track", 9, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. swimming", 9, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. field hockey", 8, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["M. swimming", 7, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. gymnastics", 6, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["M. outdoor track", 6, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["M. tennis", 6, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. soccer", 5, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
    ["W. tennis", 3, <a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a>],
];


const PatellarIntro = () => {
    const table = <IntroTable data={tableData} caption="This table shows the overall injury rate for patellar tendonitis. The injury rate is the number of injuries per 100,000 athlete exposures."/>

    return (
        <div>
            <div className="title-margins">
                <h2>Introduction to Patellar Tendinitis</h2>
                <p>written by Dr. Stuart Kim, PhD</p>
            </div>
            <div className='title-break'>
                <span>High Risk Sports</span>
            </div>
            <div className='high-risk-list my-3 py-1'>
                <div className="result-sport-figure">
                    <img src='/assets/imgs/results/athletes/basketball.png' alt="" />
                    <span>Basketball</span>
                </div>
                <div className="result-sport-figure mb-2">
                    <img src='/assets/imgs/results/athletes/runner.png'alt="" />
                    <span>Endurance Running</span>
                </div>
                <div className="result-sport-figure">
                    <img src='/assets/imgs/results/athletes/volleyball.png' alt=""/>
                    <span>Volleyball</span>
                </div>
            </div>
            <div className='title-break'>
                <span>Overview</span>
            </div>
            <div className="card">
                <img src="/assets/imgs/results/content/highJumper.jpeg" alt="" height={250} className="overview-img" />
                <p>The patellar tendon connects your kneecap (patella) to your shin bone (tibia).  Patellar tendinopathy, also known as jumper's knee, is a common condition with anterior knee pain localized to the patellar tendon, typically just inferior to the kneecap (patella) (<a href="https://pubmed.ncbi.nlm.nih.gov/28110807/">Everhart et al, 2017</a>).</p>
                <p>Pain and tenderness at the base of your kneecap are usually the first symptoms of patellar tendinitis. You may also have some swelling and a burning feeling in the kneecap. Kneeling down or getting up from a squat can be especially painful. The pain may at first be sporadic, occurring only after sports or exercise activity. As the tendon damage increases, the pain becomes progressively worse and can interfere with daily activities, such as climbing stairs or sitting in a car.</p>
                <p className="mb-0">The development of patellar tendinopathy is most commonly initiated by mechanical overload of the tendon, leading to fibril disruption and an inflammatory-mediated aberrant healing response (<a href="https://pubmed.ncbi.nlm.nih.gov/28119539/">Millar et al, 2017</a>; <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2505234/">Xu and Murrell, 2008</a>). This leads to an increase in deposition of biomechanically inferior Type-III collagen at the expense of native Type-I collagen – the hallmark of chronic tendinopathy (<a href="https://pubmed.ncbi.nlm.nih.gov/28119539/">Millar et al., 2017</a>; <a href="https://pubmed.ncbi.nlm.nih.gov/25857925/">Millar et al., 2015</a>). Subsequent immune cell influx and increased vascularity within the tendon and tendon sheath likely contribute to the sensation of pain experienced by individuals with this condition (<a href="https://pubmed.ncbi.nlm.nih.gov/28119539/">Millar et al., 2017</a>; <a href="https://pubmed.ncbi.nlm.nih.gov/33414454/">Millar et al, 2021</a>). Even with proper treatment, symptoms can be prolonged and some athletes do not experience complete resolution of symptoms (<a href="https://pubmed.ncbi.nlm.nih.gov/16934204/">Scott and Ashe, 2006</a>).</p>
            </div>
            <div className='title-break'>
                <span>Risk Factors</span>
            </div>
            <div className="card">
                <img src="/assets/imgs/results/content/patellar.png" alt="" height={250} className="overview-img" />
                <p>A combination of factors contribute to the development of patellar tendinitis, including:</p>
                <ul>
                    <li>Participation in sports. Patellar tendinitis is considered an overuse injury, and is most common in athletes participating in sports that involve jumping — such as basketball and volleyball – but can be seen in other sports such as soccer and running (<a href="https://pubmed.ncbi.nlm.nih.gov/21642599/">Hagglund et al., 2011</a>; <a href="https://pubmed.ncbi.nlm.nih.gov/25983242/">Janssen et al., 2015</a>; <a href="https://pubmed.ncbi.nlm.nih.gov/11916889/">Taunton et al., 2002</a>; <a href="https://pubmed.ncbi.nlm.nih.gov/21496108/">van der Worp et al., 2012</a>).</li>
                    <li>Sudden increase in training volume and/or intensity. A large increase in load on the patellar tendon, especially from plyometrics (jumping), heavy/deep squats or lunges (<a href="https://pubmed.ncbi.nlm.nih.gov/30054341/">Sprague et al., 2018</a>).</li>
                    <li>Tight leg muscles. Tight thigh muscles (quadriceps) and hamstrings, which run up the back of your thighs, can increase strain on your patellar tendon.</li>
                    <li>Muscular imbalance. If some muscles in your legs are much stronger than others, the stronger muscles could pull harder on your patellar tendon. This uneven pull could cause tendinitis.</li>
                    <li>Playing on hard surfaces or with shoes that lack adequate padding.</li>
                    <li>Chronic illness. Some illnesses disrupt blood flow to the knee, which weakens the tendon. Examples include kidney failure, autoimmune diseases such as lupus or rheumatoid arthritis and metabolic diseases such as diabetes.</li>
                    <li>Male gender. Male athletes are 2-3x more likely to develop patellar tendinitis than female athletes.</li>
                    <li>Another risk factor is your own genetic make-up, and AxGen provides you information on your personal risk for patellar tendinitis.</li>
                </ul>
            </div>
            <div className='title-break'>
                <span>Frequency</span>
            </div>
            <div className="table-container">
                <div className="table-subcontainer">
                    <table className="default-table">
                        <thead>
                            <tr>
                                <th>Sport</th>
                                <th>Patellar Tendinitis</th>
                                <th>Reference</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>All athletes</td>
                                <td>53% cite patellar tendinitis as a factor in quitting</td>
                                <td><a href="https://www.ncbi.nlm.nih.gov/books/NBK532969/">Santana et al., 2021</a></td>
                            </tr>
                            <tr>
                                <td>Elite volleyball players</td>
                                <td>50% of players</td>
                                <td><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1725109/pdf/v039p00102.pdf">Young et al., 2004</a></td>
                            </tr>
                            <tr>
                                <td>Recreational volleyball players</td>
                                <td>14% of players</td>
                                <td><a href="https://www.sciencedirect.com/science/article/pii/S1836955314000915">Rudavsky and Cook, 2014</a></td>
                            </tr>
                            <tr>
                                <td>Professional baseball</td>
                                <td>10% of all knee injuries</td>
                                <td><a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a></td>
                            </tr>
                            <tr>
                                <td>Professional basketball</td>
                                <td>10% of all injuries</td>
                                <td><a href="https://pubmed.ncbi.nlm.nih.gov/31041333/">Trojan et al., 2019</a></td>
                            </tr>
                            <tr>
                                <td>Runners</td>
                                <td>5% of all injuries</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            { table }
        </div>
    );
};

export default PatellarIntro;
