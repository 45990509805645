import React from 'react';
import IntroTable from '../../components/introTable';

const tableData = [
  ["Football", 24.2, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28423285">Mauntel et al., 2017</a>],
  ["Wrestling", 21.1, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28423285">Mauntel et al., 2017</a>],
  ["Ice hockey", 11.9, <a href="https://www.ncbi.nlm.nih.gov/pubmed/28423285">Mauntel et al., 2017</a>],
  ["Court", 700, <a href="https://www.ncbi.nlm.nih.gov/pubmed/24105612">Doherty et al., 2014</a>],
  ["Ice/Water sports", 370, <a href="https://www.ncbi.nlm.nih.gov/pubmed/24105612">Doherty et al., 2014</a>],
  ["Field", 100, <a href="https://www.ncbi.nlm.nih.gov/pubmed/24105612">Doherty et al., 2014</a>],
  ["Outdoor sports", 90, <a href="https://www.ncbi.nlm.nih.gov/pubmed/24105612">Doherty et al., 2014</a>]
];


class AnkleIntro extends React.Component {
  render() {
    const table = <IntroTable data={tableData} caption="Table: Ankle Injury Incidence in Different Sports. AE: Athlete Exposures"/>;

    return (
      <div>
        <div className='title-margins'>
        <h2>Introduction to Ankle Sprains:</h2>
        <p>written by Dr. Megan Roche, M.D.</p>
        </div>

        <div className='title-break'>
          <span>High Risk Sports</span>
        </div>
        <div className='high-risk-list my-3 py-1'>
          <div className="result-sport-figure mb-2">
            <img src='/assets/imgs/results/athletes/soccer.png' alt=""/>
            <span>Soccer</span>
          </div>
          <div className="result-sport-figure mb-2">
            <img src='/assets/imgs/results/athletes/basketball.png' alt=""/>
            <span>Basketball</span>
          </div>
          <div className="result-sport-figure">
            <img src='/assets/imgs/results/athletes/volleyball.png' alt=""/>
            <span>Volleyball</span>
          </div>
          <div className="result-sport-figure">
            <img src='/assets/imgs/results/athletes/quarterback.png' alt="" />
            <span>Football</span>
          </div>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img src="/assets/imgs/results/content/ankleAthlete.jpg" alt="" height={300} className="overview-img" />
        <p>Ankle sprains are the most commonly sustained musculoskeletal injuries in athletes. It is important to prevent ankle sprains before they occur. Ankle sprains can damage ligaments, which can predispose one to chronic ankle instability and elevate risk for future ankle sprains.</p>
        <p>Ankle sprains are classified into three types based on their locations--lateral, medial, and syndesmotic (high). The mechanism of injury often determines the location of the sprain, with the lateral ankle sprain being the most common sprain as a result of inversion of a plantar-flexed foot. Most epidemiological and clinical studies on ankle sprains focus on the lateral ankle sprain.</p>

        <p>In lateral ankle sprains, the ligaments are often injured in a predictable sequence, with increasing ligamentous involvement as more force is applied to the ankle. The anterior talofibular ligament (ATFL) is typically the first and only ligament involved in lateral ankle sprains. With more force, there is often combined injury of the ATFL and the calcaneofibular ligament (CFL). The posterior talofibular ligament (PTFL) is injured in severe injuries and is commonly associated with ankle fractures (<a href="https://www.ncbi.nlm.nih.gov/pubmed/26616180">Tenforde et al., 2016</a>). </p>
      <p>Medial ankle sprains occur less often and result from forced ankle eversion. Medial ankle sprains are often associated with avulsion fractures due to the force of injury and the strength of the deltoid ligament.  </p>
      <p>Syndesmotic (high) ankle sprains occur in less than 17% of ankle trauma and result from ankle sprains with external foot rotation and hyperdorsiflexion of the ankle joint. Approximately 85% of syndesmotic injuries are grade I injuries that can be managed with conservative treatment (<a href="https://www.ncbi.nlm.nih.gov/pubmed/23015976">Williams and Allen, 2010</a>).</p>
      <p>Ankle sprains are further classified by grade, with grades ranging from I-III based on clinical signs, ankle function, or imaging. Grade I injuries involve ligament stretching with microscopic tears, grade II injuries involve incomplete ligament tearing, and grade III injuries involve complete ligament tearing. Although higher grades of injury are associated with increased ligamentous tearing, the grade of injury is not the sole predictor of recovery time. </p>
      <p>Inadequate treatment of ankle sprains can contribute to the development of chronic ankle instability and recurrence of ankle sprains. Athletes with chronic ankle instability often have ligamentous laxity, episodes of giving way, chronic discomfort, and swelling.   </p>
      </div>
      <div className='title-break'>
          <span>Causes</span>
        </div>
      <div className="card">
      <p>Risk factors for lateral ankle sprains can be categorized into intrinsic (athlete-specific) or extrinsic (sport-specific) risk factors. An evidence-based clinical guideline by <a href="https://www.ncbi.nlm.nih.gov/pubmed/29514819">Vuurberg et al.</a> summarizes these risk factors.</p>
      <p>Intrinsic risk factors can be broken down into variables impacting fitness, proprioception, the anatomy of the ankle joint itself, and genetic make-up of the athlete. For instance, athletes with reduced cardiorespiratory endurance have a higher risk of lateral ankle sprain. Deficiencies in postural control, reflected on the single leg balance test, increase the risk of lateral ankle sprains. Athletes with limited ankle dorsiflexion range of motion, greater foot posture index, and abnormalities in ankle or knee alignment are also at higher risk (<a href="https://www.ncbi.nlm.nih.gov/pubmed/29514819">Vuurberg et al., 2018</a>). A recent study found two DNA polymorphisms associated with increased risk of ankle injury (<a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0185355">Kim et al., 2017 </a>). </p>
      <p>Extrinsic risk factors vary based on sport. In soccer athletes, playing on natural grass increased risk compared to artificial turf. Defenders sustained 42.3% of all ankle sprains seen in soccer athletes. In volleyball athletes, the highest number of ankle sprains were sustained after landing from a jump (<a href="https://www.ncbi.nlm.nih.gov/pubmed/23015976">Vuurberg et al., 2018</a>). </p>
      </div>
      <div className='title-break'>
          <span>High Risk Sports</span>
        </div>
        <div className="card">
      <p>Ankle injuries account for 15% of all sports injury. Basketball (41.1%), football (9.3%), and soccer (7.9%) athletes have the highest incidence of ankle sprains, likely due to movement patterns and training surfaces. For example, basketball, athletes are required to quickly accelerate and decelerate in the frontal plane, which contributes to higher injury risk (<a href="https://www.ncbi.nlm.nih.gov/pubmed/20926721">Waterman et al., 2010</a>).</p>
      <p>A 6-year epidemiological analysis from 25 NCAA sports found that the most common mechanisms of lateral ankle sprain were player contact (41.4%), non-contact (27.4%), and surface contact (22.2%). In the same NCAA population, 11.9% of lateral ankle sprains were defined as recurrent ankle sprains. However, other studies on collegiate ankle sprains report an even higher recurrence of ankle sprains (<a href="https://www.ncbi.nlm.nih.gov/pubmed/28423285">Mauntel et al., 2017</a>).</p>
      </div>
      { table }
    </div>
  )}
}

export default AnkleIntro;
