import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import Alerts from '../components/alerts';
import Calendly from '../components/calendly';
import ConsultStripeForm from '../components/consult-stripe-form';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as Actions from '../actions';
import ConsultConfirmation from './consults-confirmation';
import moment from 'moment';

// create Stripe object
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const orderPrices = {
    'genetics-consultation': parseInt(process.env.REACT_APP_PRICE_GENETICS_CONSULT),
    'strength-consultation': parseInt(process.env.REACT_APP_PRICE_STRENGTH_CONSULT)
};

const strengthCap = process.env.REACT_APP_STRENGTH_CAP;

const consultants = {
    "genetics-consultation": {
        "Stuart Kim": {  
            "username": "genetics-stuart",
            "des": "Stuart is an expert geneticist that can explain your genetic results. Before starting AxGen, he was Professor of Genetics at Stanford University and published many of the genetic tests used by AxGen.  ",
            "img": "./assets/imgs/stuart_pic.jpg",
        },
        // "Andrew Roos": {  
        //     "username": "genetics-andrew",
        //     "des": "Andrew is a sports genetics scientist and an elite triathlete who is passionate about using science to empower athletes. While at the Stanford School of Medicine he worked with Professor Stuart Kim in the field of genomics/ personalized medicine and earned an MS degree in Epidemiology/Clinical Research. In sport, after collegiate swimming, Roos transitioned to triathlon, racing as a top collegiate triathlete for Stanford and currently competes as an Elite/Pro on the short-course ITU international circuit.",
        //     "img": "./assets/imgs/andrew_bio.jpg",
        // },
        
        // "Megan Roche": {
        //     "username": "genetics-megan",
        //     "des": "Megan received her medical degree from Stanford University in 2018 and has continued research on topics related to athletic performance, bone health, longevity, and genetic predictors of sports injury. She is a five-time national trail running champion and a co-founder of the Some Work, All Play running coaching group. Prior to competing as a runner, Megan played field hockey and received an undergraduate degree in neuroscience from Duke University.",
        //     "img": "./assets/imgs/megan_bio.jpg",
        // },    
    },
    "strength-consultation": {
        // "Chris Lee": {
        //     "username": "strength-chris",
        //     "des": "Chris is a strength and conditioning coach based out of Boulder, CO where he works with world class runners and triathletes many of whom are aiming for the 2020 Olympics. Chris’s practice philosophy revolves around injury prevention and evidence based training, which is what drew him to AxGen. Chris graduated from Montclair State University where he began on a teaching track but shifted his focus to exercise Science. Chris was a 4 year NCAA swimmer and served as team captain in his senior year.",
        //     "img":  "./assets/imgs/chris_bio.jpg",
        // },  
        "Madison Hayden": {
            "username": "strength-madison",
            "des": "Madison was an All-American and captain of the men’s indoor volleyball team at Stanford University. He went on to play professional volleyball in Sweden and England. Madison will help design a strength training regiment tailored to your own unique needs based on your AxGen results, prior history of injuries and current participation in sports.",
            "img":  "./assets/imgs/madison.jpg",
        },   
    }
}

const BookConsultations = (props) => {
    const { session, consultations, alerts, width, coupon } = useSelector((state) => ({
        session: state.session,
        consultations: state.consultations,
        alerts: state.alerts,
        width: state.ui.width,
        coupon: state.orders.coupon
    }));
    const [step, stepUp] = useState(1);
    const [eventType, setEvent] = useState("");
    const [optionType, setOptionType] = useState("");
    const [consultant, selectConsultant] = useState("");
    const [localPrice, setLocalPrice] = useState(null);
    
    // disables the strength card if the cap is met
    const [strengthDisabled, disableStrength] = useState(false);

    // checks to see if the consultation is completed
    // compares today's date to the dB saved date using moment.js
    const checkForConsultCompletion = (endTime) => {
        let today = moment.utc();
        let endTimeUTC = moment.utc(endTime);
        return today.isAfter(endTimeUTC);
    };

    // async function here ensures that the results of the action are in the state before more code fires
    async function checkForConsultation() {
        await Actions.loadMyConsult();
    };
    // fires when changes are detected in the startTime or endTime of consultation
    // used to mark a consultation as complete if it is completed
    // a consultation can only be completed if it is already scheduled
    useEffect(() => {
        if ((consultations.startTime && consultations.endTime) && consultations.status === 'scheduled')  {
            let timeBool = checkForConsultCompletion(consultations.endTime);
            if (timeBool === true) {
                Actions.completeConsultation(consultations.consultant);
            };
        };
    }, [consultations.startTime, consultations.endTime]);

    // fetches consultation information on mount
    useEffect(() => {
        Actions.removeCoupon(); //remove existing coupons on mount
        checkForConsultation();
    }, []);

    // handles initial setting of steps and any subsequent changes to consultations.status
    // the backend can handle rescheduling as well
    // the steps creates a layer of insulation between global state and what the user sees, which prevents issues arising from for example a consultation not existing yet
    useEffect(() => {
        switch(consultations.status) {
            case 'cancelled': 
            case 'paid':
                // handles creation and removing event listener to prevent memory leaks
                stepUp(2);
                const listener = (e) => setEventInState(e); //binds the function to this context, allowing for adding and removing at will for event listeners
                window.addEventListener("message", listener);
                return () => {
                    window.removeEventListener("message", listener);
                };        
            case 'scheduled':
                stepUp(3);
                break;
            case 'completed':
                stepUp(1);
                break;        
            default:
                break; 
        }
    }, [consultations.status]);

    // updates the state based on the message from the calendly iframe
    const setEventInState = (e) => {
        if (isCalendlyEvent(e)) {
            setEvent(e.data.event);
        };
    };
    const isCalendlyEvent = (e) => {
        return e.data.event && e.data.event.indexOf('calendly') === 0;
    };

    // handles the scheduling of the event and rendering of the step 3 component
    // this hook functions differently than the consultations.status hook because of the asynchronous nature of calendly's webhook data
    // from the iframe for calendly, the only information we can get is this signal, which once detected renders the confirmation component
    useEffect(() => {
        if (eventType === 'calendly.event_scheduled') {
            stepUp(3);
        };
    }, [eventType]);


    useEffect(() => {
        if (consultations.strengthIdx >= strengthCap) {
            disableStrength(true);
        }
    }, [consultations.strengthIdx]);

    // calculates local price in case there is a valid coupon
    // prevents coupon chaining as the calcPrice is reset according to the env price every time one applies a coupon successfully
    const _calculateDiscount = () => {
        let calcPrice = orderPrices[optionType];
        switch(coupon.type) {
            case 'fixed':
                calcPrice -= coupon.amount;
                break;
            case 'percent':
                calcPrice = (calcPrice * (100.0 - coupon.amount) / 100);
                break;
            default:
                break;
        };

        setLocalPrice(calcPrice.toFixed(0));
    };
    
    // re-calculates the local price if there is a coupon
    // ensures there is also a selected optionType before the coupon is calculated
    useEffect(() => {
        if (coupon && optionType) {
            _calculateDiscount();
        };
    }, [coupon]);

    // calculates the price when there is an optionType selected
    // resets the coupon state and clears alerts to prevent carry overs if someone switches options
    // we do not have auto billing or a mechanism to disable viewing of the strength page if a month expires and the user has not renewed. i presume that we can have a form for this on the strength page conditionally rendered based on status (ie if 'expired...'), maybe we can stick this status in the user meta
    // removed initiation fee (3/12/2020)
    useEffect(() => {
        if (optionType) {
            Actions.removeCoupon();
            Actions.clearAlerts();
            let defaultPrice = orderPrices[optionType];
            setLocalPrice(defaultPrice);    
        };
    }, [optionType]);


    // makes list of the consultants, and renders a selected consultant as active, changing the style
    const _listMaker = (consultantObj) => {
        return Object.keys(consultantObj).map((name, i) => (
            <li key={i} onClick={() => selectConsultant(name)} className={consultant === name ? 'consultant-li-active' : ''}>
                <div className="avatar-container h-100">
                    <img src={consultantObj[name]["img"]} alt={name}></img>
                </div>
                <div className="consult-text">
                    <h5>{name}</h5>
                    <p className="bio">{consultantObj[name]["des"]}</p>
                </div>
            </li>
        ));
    };

    // handles selecting the option type
    // clears the local state for consultant as well, as the stripe form will not be keyed to the most recent consultant otherwise if there is a switch
    // also prevents one from clearing their local state if they accidently click the same option more than once
    const selectOption = (option) => {
        if (option === optionType) {
            return;
        } else {
            selectConsultant("");
            setOptionType(option);
        };
    };

    // variable that renders the consultants once one selects a consultation type
    const ourConsultants = optionType ? (
        _listMaker(consultants[optionType])
    ) : (
        null
    );

    // handles the success message
    const success = ((alerts['book-consultation'] || {})['success'] || []).length > 0;
    // handles passing in error alerts if one tries to buy a consultation and an error occurs
    const consultOrderError = ((alerts['order-consultation'] || {})['error'] || []).length > 0;
   
    // toggles the active state of the selectable options
    const geneticActive = optionType === 'genetics-consultation' ? 'active' : null;
    const strengthActive = optionType === 'strength-consultation' ? 'active' : null;

    // handles the mobile/md device padding for some of the form elements
    const cardPadding = width < 768 ? '' : 'px-1';
    const orderTypePadding = width < 992 ? "mb-3" : null;
    const nonMobileDivClass = width < 992 ? "" : "col-12 col-sm-10 col-md-8 mx-auto";
    const optionDesktopHeight = width < 992 ? null : "h-100";

    // renders the stripe form if one selects a consultant, or renders nothing
    const stripeForm = consultant ? (
        <div>
            <Elements stripe={stripePromise}>
                <ConsultStripeForm
                    session={session}
                    consultant={consultant}
                    consultType={optionType}
                    price={localPrice}
                    coupon={coupon}
                    consultOrderError={consultOrderError}
                />
            </Elements>
            { !success && <div className="">
                <img src="/assets/imgs/stripe.png" className='stripe-icon' alt=""/>
            </div> }
            <Alerts tag='order-consultation' message="Failed to submit order" />
            <Alerts tag='order-consultation' level='success' />
        </div>
    ) : (
        null
    );

    // conditionally renders the list of consultants
    const consultantSection = ourConsultants ? (
        <div className="consult-form-section">
            <ul className="our-consultants">
                <div className="subsection-title mb-2">Select Consultant</div>
                {ourConsultants}
            </ul>
        </div>
    ) : (
        null
    );

    // determines the selected consultant's username for the purposes of calendly using the information from the dB or local state (if dB info is undefined)
    // written to prevent null or undefined errors
    let consultantUN;
    if (consultations.consultant || consultant) {
        let name = consultations.consultant || consultant;
        let type = consultations.orderType || optionType;
        consultantUN = consultants[type][name]["username"];
    };

    // handles the rendering of content based on current step
    const contentController = step === 2 ? (
        <Calendly
            consultantName={consultations.consultant || consultant}
            consultantUN={consultantUN}
            name={session.userName}
            email={session.userEmail}
        />
        ) : (step === 3) ? (
            <ConsultConfirmation 
                consultant={consultations.consultant}
            />
        ) : (
            stripeForm
        );

    // handles rendering the text at the top of the form based on whether a user has results and is a user or strength-user
    const headerText = (session.userKitStep === 1 && (session.userRole === "user" || session.userRole === "strength-user")) ? (
        <p>Here you can book a consultation with our strength and conditioning coach. Select the purchase option below to get started.</p>
    ) : (
        <p>Here you can book a consultation with one of our genetic experts or our strength and conditioning coach. Select one of the purchase options below to get started.</p>
    );
    // now the genetics order option is always rendered so a user can buy a consult in advance
    const geneticsOption =
        <div className={`card card-hover order-option py-3 ${cardPadding} ${geneticActive} ${optionDesktopHeight}`} onClick={() => selectOption('genetics-consultation')}>
            <div className="card-body text-center">
                <i className="fal fa-dna"></i>
                <div className="mt-4 h3">Genetics</div>
                <div className="order-option-textbox">
                    <p>Our sports genetics counselors walk you through your AxGen results and provide personalized guidance. We go into depth about what your genetic profile indicate&#8217;s about your risk for the injuries/ conditions tested. Then, based on your genetics plus any relevant sport/injury history, we walk you through our exercise video library and empower you with training/ nutrition recommendations.</p>
                </div>
                <p className="consult-price mt-2">${orderPrices["genetics-consultation"]}</p>
            </div>
        </div>


    const strengthOption = strengthDisabled ? (
        <div className={`card order-option-disabled py-3 ${cardPadding} ${strengthActive} ${optionDesktopHeight}`}>
            <div className="card-body text-center">
                <i className="fal fa-dumbbell"></i>
                <div className="mt-4 h4">Strength</div>
                <div className="order-option-textbox">
                    <p>Our strength coach will work with you to develop a strength program based on your genetic profile (if you have purchased an AxGen Genetic Analysis) and your personal goals. During a 15-minute consultation we will discuss past injuries along with individual performance needs specific to your sport and your body. From there we will develop a 4-week training plan tailored specifically for you, powered by the AxGen video library. You can renew your plan for each subsequent month for ${orderPrices["strength-consultation"]}.</p>
                </div>
                <p className="consult-price mt-2 mb-0">${orderPrices["strength-consultation"]} per month</p>
            </div>
        </div>
    ) : (
        <div onClick={() => selectOption('strength-consultation')} className={`card card-hover order-option py-3 ${cardPadding} ${strengthActive} ${optionDesktopHeight}`}>
            <div className="card-body text-center">
                <i className="fal fa-dumbbell"></i>
                <div className="mt-4 h4">Strength</div>
                <div className="order-option-textbox">
                    <p>Our strength coach will work with you to develop a strength program based on your genetic profile (if you have purchased an AxGen Genetic Analysis) and your personal goals. During a 15-minute consultation we will discuss past injuries along with individual performance needs specific to your sport and your body. From there we will develop a 4-week training plan tailored specifically for you, powered by the AxGen video library. You can renew your plan for each subsequent month for ${orderPrices["strength-consultation"]}.</p>
                </div>
                <p className="consult-price mt-2 mb-0">${orderPrices["strength-consultation"]} per month</p>
            </div>
        </div>
    );
  
    // renders the picker for the consult type. this disappears once one pays for a consultation
    // whether a user sees the genetics option or not is based on their role and their kit step, at least if the user is a strength-user or normal user
    const consultTypePicker = 
        <div>
            <div className="consult-form-section">
                <div className="subsection-title mb-2">Select Consultation Type</div>
                <div className="row d-flex justify-content-center mt-3">
                    <div className={`col-lg-6 col-md-6 col-sm-12 ${orderTypePadding}`}>
                        { geneticsOption }
                    </div>
                    <div className={`col-lg-6 col-md-6 col-sm-12 ${orderTypePadding}`}>
                        { strengthOption }
                    </div>
                </div>
            </div>
        </div>

    return (
        <div>
            <Navbar />
            <div className="container mt-4">
                <div className={nonMobileDivClass}>
                    <div className="buy-header">
                        <h2>Book a Consultation</h2>
                    </div>
                    {headerText}
                    
                    <div className="consultation-form card pt-3 pb-0">
                        {
                            step === 1 ? consultTypePicker : null
                        }
                        <div className="multi-step-form-parent">
                                { step === 1 ?
                                    consultantSection 
                                    : null
                                }
                            <div className="consult-form-section">
                                {contentController}
                            </div>
                            <div className="consult-bottom mb-4">
                                <ul className="step-tracker">
                                    <li className={step === 1 ? "step-active" : "step"}>
                                        <p>Step 1</p>
                                        <div className="circle"></div>
                                    </li>
                                    <li className={step === 2 ? "step-active" : "step"}>
                                        <p>Step 2</p>
                                        <div className="circle"></div>
                                    </li>
                                    <li className={step === 3 ? "step-active" : "step"}>
                                        <p>Step 3</p>
                                        <div className="circle"></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/>
            <Footer />
        </div>
    )
}

export default BookConsultations;