import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Footer from '../../components/footer';
import * as Actions from '../../actions';
import Alerts from '../../components/alerts';

const ChangePasswordPage = (props) => {
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [disabled, disableSubmit] = useState(false);

  const { alerts } = useSelector(state => ({
    alerts: state.alerts
  }));

  const submitForm = (evt) => {
    evt.preventDefault();
    disableSubmit(true);
    Actions.clearAlerts();
    const token = props.match.params.token;
    if (pass !== confirmPass) {
      Actions.addAlert('change-password', 'error', 'The passwords you entered do not match');
    } else {
      Actions.changePassword(token, pass);
    }
  }

  // un-disable if alerts
  useEffect(() => {
    if (Object.keys(alerts).length !== 0) {
      disableSubmit(false);
    };
  }, [alerts]);
  
  const success = ((alerts['change-password'] || {})['success'] || []).length > 0;
    
  // conditional handles rendering the get another token link if one's token expires
  // any other error that does not relate to token expiring will not render this message
  if (Object.keys(alerts).length > 0 && !success) {
    const alertMsg = alerts['change-password']['error'][0];
    if (alertMsg === 'Token has expired') {
      return <p><br/>Has your token expired? <Link to='/reset-password' className="btn-link">Click here</Link> to try again.</p>;
    }
  }
  
  const togglePassLabel = pass !== "" ? 'auth-label active' : 'auth-label';
  const passInput = pass !== "" ? 'form-control active' : 'form-control';
  const toggleConfirmPassLabel = confirmPass !== "" ? 'auth-label active' : 'auth-label';
  const confirmPassInput = confirmPass !== "" ? 'form-control active' : 'form-control';

  return (
    <div className="bg self-align-stretch">
      <div className="d-flex self-align-stretch justify-content-center align-items-center h-100">
      <div className="auth-form-parent">
        <div className="auth-form-container">
          <div className="logreg-form">
            <div className="auth-form-header">
              <img alt="" src="/assets/imgs/axgen-logo.png" className="logo-img" />
              <h1>AxGen</h1>
            </div>
            <form onSubmit={(evt) => submitForm(evt) } className={classNames("auth-form", {"d-none":success})}>
              <div className="auth-input-container mb-0">
                  <i className="fas fa-lock-alt"></i>
                  <label className={togglePassLabel}>New Password</label>
                <input
                  type="password"
                  name="password"
                  className={passInput}
                  onChange={(e) => setPass(e.target.value)}
                  value={pass}
                  autoComplete="off"
                  required
                />
              </div>
              <p className="form-sentence">Requirements: At least 8 characters long, one uppercase character, one lowercase character and one digit.</p>
              <div className="auth-input-container">
                  <i className="fas fa-lock-alt"></i>
                  <label className={toggleConfirmPassLabel}>Confirm New Password</label>
                  <input
                    type="password"
                    name="confirm_password"
                    onChange={(e) => setConfirmPass(e.target.value)}
                    value={confirmPass}
                    className={confirmPassInput}
                    autoComplete="off"
                    required
                  />
              </div>
              <div className="submit-row">
                <input
                  type="submit"
                  disabled={disabled}
                  className="btn reg-btn"
                  value="CHANGE PASSWORD"
                />
              </div>
            </form>
              <Alerts tag='change-password' level='success' />
              <Alerts tag='change-password' message="Failed to change password" />
            </div>
          </div>
          <div className="auth-form-bottom">
            <p className="form-links mb-0"><Link to="/login" className="btn-link">Log in</Link> to your account</p>
          </div>
        </div>
      </div>
      <Footer forceWhite={true} />
    </div>
  );
};


export default ChangePasswordPage;
