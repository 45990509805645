import React from 'react';


const Mars_ColCanIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Colorectal Cancer</h2>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/ColCan_fig.png" height={200}/> 
        <p>Colorectal cancer affects the colon or rectum, which are parts of the digestive system. Colorectal cancer develops when abnormal cells in the lining of the colon or rectum begin to grow uncontrollably, forming a mass called a tumor. Most colon cancers begin as benign polyps. These are either flat or knob-like growths on the lining of the large intestine. Occasionally, the growths produce symptoms such as bleeding, constipation or blood in the stool. But often, the cells produce no symptoms at all, so people may not know that they have them. If left untreated, colorectal cancer can spread to other parts of the body, such as the liver or lungs, and can be life-threatening. However, when detected early, colorectal cancer is highly treatable and curable.</p>
        
      </div>
      <div className='title-break'>
        <span>Genetics</span>
      </div>
      <div className="card">
        <p>The results section has a polygenic risk score for colorectal cancer that indicates three fold increased risk for people in the 97th percentile. Those at increased risk should consult their health care provider and consider screening for colorectal cancer through colonoscopy.</p>
       
      </div>
      
    </div>
  )
}

export default Mars_ColCanIntro;
