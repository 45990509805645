import React from 'react';


export const AATIntro = () => {
    return (
        <div>
            <div className="title-margins">
                <h2>Introduction to Alpha-1 Antitrypsin Deficiency</h2>
            </div>


            <div className='title-break'>
                <span>Overview</span>
            </div>
            <div className="card">
                <p>Alpha-1 antitrypsin deficiency (A1AD) is a rare genetic disorder that can cause serious health problems in the liver and/or lungs. It is present in about 1 in 2,000 of people with European descent, and is rarer in other ethnicities. A1AD currently affects ~100,000 people in the United States. </p>

            </div>
            <div className='title-break'>
                <span>Causes</span>
            </div>
            <div className="card">
                <p>A1AD is an inherited disease passed from parents to their children. A1AD is caused by a mutation in the <i>SERPINA1</i> gene, leading to deficiency in the protein alpha-1 antitrypsin (AAT), which is produced in the liver. AAT helps protect the lungs from damage caused by certain enzymes that are normally present in the body. Without enough of this protein, the lungs can become damaged, leading to serious respiratory problems.</p>
                <p>The most common version (allele) of the <i>SERPINA1</i> gene, called M, produces normal levels of AAT. Most people in the general population have two copies of the M allele (MM) in each cell. Other versions of the <i>SERPINA1</i> gene lead to reduced levels of AAT. The S allele produces moderately lower levels of the protein, and the Z allele produces very little AAT. Individuals with two copies of the Z allele (ZZ) in each cell have a high risk of developing lung disease (such as COPD and emphysema) and liver disease. Those with the SZ combination have an increased risk of developing lung disease, particularly if they smoke.</p>
                <p>People with AAT deficiency usually develop the first signs and symptoms of lung disease between ages 25 and 50. Early symptoms include shortness of breath, lethargy, wheezing, and a reduced ability to exercise. More advanced symptoms are a chronic cough and chest pain. In severe cases, or if left untreated, A1AD can lead to lung damage and disease as well as liver problems, including cirrhosis.</p>
            </div>
            <div className='title-break'>
                <span>Testing</span>
            </div>
            <div className="card">
                <p>Alpha-1 antitrypsin deficiency was previously diagnosed by looking at levels of SERPINA1 protein in the bloodstream. If the levels are low, it indicates that the person has the condition. If low levels of AAT are detected, other tests such as a liver biopsy or genetic testing were used to confirm the diagnosis.</p>
                <p>The “Alpha-1 Foundation”, set up to provide treatment and support to those afflicted with A1AD provides <a href="https://www.alpha1.org/newly-diagnosed/learning-about-alpha-1/testing-for-alpha-1/">free and confidential testing</a> to those who wish to be tested.   </p>
                <p>It is now possible to use genetic testing to directly detect <i>SERPINA1</i> mutations causing A1AD. By far, the most common mutations are the Z and S alleles. </p>
            </div>
            <div className='title-break'>
                <span>Treatment and Management</span>
            </div>
            <div className="card">
                <p>There is no current cure for A1AD, but there are treatments available that can greatly reduce the lung damage caused by the disease. If properly treated, barring any severe health complications, people living with A1AD can expect to live a full and healthy life. Treatment for A1AD usually involves replacing the missing alpha-1 antitrypsin with a synthetic version. Alternatively, it may be treated through a process called “augmentation therapy”- a blood transfusion from a donor with healthy amounts of AAT in their blood. This can help to reduce symptoms and slow down the progression of the disease. In extreme cases, a lung transplant may be necessary. </p>
            </div>
            <div className="card">
            <p>Other treatments may be included that help combat the symptoms of A1AD. These include:</p>
            <ol>
                <li><b>Bronchodilators</b> are medications that make breathing easier by relaxing the muscles around the airway. Taken daily, these medications can greatly improve breathing ability and ease. </li>
                <li><b>Inhaled Corticosteroid Medication </b>reduces inflammation in the airways and can make breathing easier. </li>
                <li><b>Antibiotics</b> are given to treat common complications of A1AD such as bronchitis and COPD.</li>
            </ol>
            <p>Living with A1AD can be difficult, but there are many ways to manage the condition. It is important to follow your doctor’s advice and take any medications prescribed. It is also important to avoid smoking and high amounts of air pollution that can make symptoms worse. A1AD is a serious condition, but with proper management, it is possible to live a full and active life.</p>
            </div>
        </div>
    );
}

export default AATIntro;
