import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Footer from '../../components/footer';
import * as Actions from '../../actions';
import Alerts from '../../components/alerts';

const ResetPasswordPage = () => {
  const [disabled, disableSubmit] = useState(false);
  const [email, setEmail] = useState("")

  const { alerts } = useSelector(state => ({
    alerts: state.alerts
  }));

  const submitForm = (evt) => {
    disableSubmit(true);
    evt.preventDefault();
    Actions.clearAlerts();
    Actions.resetPassword(email);
  };

  // un-disable if alerts
  useEffect(() => {
    if (Object.keys(alerts).length !== 0) {
      disableSubmit(false);
    };
  }, [alerts]);

  const success = ((alerts['reset-password'] || {})['success'] || []).length > 0;
  const toggleEmail = email !== "" ? 'auth-label active' : 'auth-label';
  const toggleEmailInput = email !== "" ? 'form-control active' : 'form-control';
  return (
    <div className="bg self-align-stretch">
      <div className="d-flex self-align-stretch justify-content-center align-items-center h-100">
        <div className="auth-form-parent">
          <div className="auth-form-container">
            <div className="logreg-form">
              <div className="auth-form-header">
                <img alt="" src="/assets/imgs/axgen-logo.png" className="logo-img" />
                <h1>AxGen</h1>
              </div>
              <form onSubmit={(evt) => submitForm(evt)} className={classNames("auth-form", {"d-none":success})}>
                <div className="auth-input-container">
                  <i className="fa fa-envelope"></i>
                  <label className={toggleEmail}>Email</label>
                  <input
                    type="email"
                    name="email"
                    className={toggleEmailInput}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                </div>
                <div className="submit-row">
                  <input
                    type="submit"
                    className="btn reg-btn"
                    value="RESET PASSWORD"
                    disabled={disabled}
                  />
                </div>
              </form>
              <Alerts tag='reset-password' message='Failed to login' />
              <Alerts tag='reset-password' level='success' />
            </div>
          </div>
          <div className="auth-form-bottom">
            <p className="form-links mb-0">Back to <Link to="/login" className="btn-link">Login</Link></p>
          </div>
        </div>
      </div>
      <Footer forceWhite={true} />
    </div>
  );
};

export default ResetPasswordPage;
