import React from 'react';

class VitEIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Vitamin E</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/vitE.jpg" height={200}/>   
        <p>Vitamin E (alpha-tocopherol) is a fat-soluble vitamin found in many foods such as vegetable oils, meat, poultry, eggs, nuts, and fruit. Proper levels of vitamin E are required for the healthy function of many organs in the body. Vitamin E is an antioxidant, which inhibits free-radical oxidation and therefore has many protective effects. Vitamin E is also involved in the functioning of the immune system, cell signaling, and other metabolic processes, though the entirety of its role in the human body is still not fully understood.</p>
        <p>Some of the best sources of vitamin E are sunflower seeds and oil, almonds, peanut butter, spinach, broccoli, and tomatoes. Vitamin E can also be obtained in most multi-vitamins. It is recommended that individuals consume at least 15 mg of vitamin E daily. Most adults will not have issues maintaining vitamin E levels with a typical diet. Vitamin E need not be consumed daily, as the body stores excess levels in the liver for future use.</p>
        <p>Vitamin E deficiency is extremely rare in developed countries. It is seen only in people with certain health conditions such as liver disease or cystic fibrosis, or extremely unusual diets. People who do not get enough vitamin E often will also not have adequate levels of vitamins A, D, and K as well.</p>
        </div>
      </div>
    )
  }
}

export default VitEIntro;
