import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import MUIMobileTable from '../components/mui_theme_overrides';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { customSearch } from '../utils/data-tables';
import { loadTeamThunk } from '../actions';

let resultsConfig = require('../../json/results-config.json');

const upperWords = (str) => {
    return str.split(/-/g).map(s => s[0].toUpperCase() + s.slice(1)).join(' ');
};

// Draws the team page
export class TeamPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            teamTable: this.buildTeamTable(this.props.results.user),
        };
    };

    componentDidMount() {
        this.props.loadTeamThunk();
        this.buildTeamTable(this.props.results.user);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.results !== this.props.results) {
            this.setState({
                teamTable: this.buildTeamTable(this.props.results.user),
            });
        }
    }

    // converts percentiles to numbers for sorting
    convertPercentileToInt(percentile) {
        let numStr = "";
        for (let i = 0; i < percentile.length; i++) {
            let char = percentile[i];
            if (typeof parseInt(char) === 'number') numStr += char;
        };
        return parseInt(numStr);
    };

    // converts dom node content into a score 
    colorToScore(node) {
        if (typeof node === 'object') {
            let childProps = node.props.children.props;
            const { className, children } = childProps; // ie {className: "text-danger", children: "high risk"}
            switch (className) {
                case 'text-danger':
                    return -2;
                case 'text-gray':
                    // testosterone uses percentiles
                    if (children.includes("percentile")) {
                        let num = this.convertPercentileToInt(children);
                        return num;
                    };
                    return -1;
                case 'text-success':
                    return 1;
                // caffeine and ibuprofen results
                case 'text-primary':
                    if (children.includes("very")) {
                        return -3;
                    } else if (children.includes("slow")) {
                        return -2;
                    } else if (children.includes("fast")) {
                        return 1;
                    };
                // this case should never be hit
                default:
                    return 0;
            };
            // special characters case (~, -)
        } else {
            return 101;
        };
    };

    // converts dom node into date string for comparison
    nodeToString(node) {
        if (typeof node === 'object') {
            let props = node.props;
            const { children } = props; // ie { children: "2020-07-24" }
            return children;
            // special characters
        } else {
            return "101";
        };
    };

    generateTeamData() {
        const { team } = this.props.results;
        if (!team) {
            return <div>Loading your team...</div>; // or some other placeholder
        }
        // Loop through active members in the team array
        return Object.values(team).filter(t => t.active).map(member => {

            // TODO: In future, consider adding ability for users to have multiple uploads
            const uploadKey = Object.keys(member.uploads)[0]; // ue.id
            const upload = Object.keys(member.uploads).length > 0 ? member.uploads[uploadKey] : { 'uploaded_at': false };
            const { results = {} } = upload;
            const defaultChar = member.active ? '~' : '-';
            const resultsCol = upload.uploaded_at ? (<Link to={`/results/${uploadKey}`} >{upload.uploaded_at.substring(0, 10)}</Link>) : defaultChar;
            let row = [member.name, member.active ? 'yes' : 'no', resultsCol];
            const columnTag = [];
            if (Array.isArray(this.props.results.user)) {

                // Check if this.props.results.user is an array and then check if it includes 'tag'
                Object.keys(resultsConfig).forEach((tag) => {

                    // make a column if the tag is in resultsConfig
                    if (this.props.results.user.includes(tag)) {
                        columnTag.push(tag);
                    }
                })
            };


            return row.concat(columnTag.map(tag => {
                if (typeof results[tag] === 'undefined' || results[tag]['level'] === 'unknown') return defaultChar;
                const result = results[tag];

                const classMap = {
                    'red': 'text-danger',
                    'green': 'text-success',
                    'gray': 'text-gray',
                    'blue': 'text-primary'
                };

                return (<Link to={`/results/${uploadKey}/${tag}/results`}><span className={classMap[result.color]}>{result.shortLabel}</span></Link>);
            }));
        });
    }

    buildTeamTable(resultTitle = []) {
        const titles = [];
        const tags = [];

        Object.entries(resultsConfig || []).forEach((result) => {
            const tag = result[0];  // key from resultConfig; i.e. afib
            const tagValues = result[1]; // data from result; title, default page...
            // resultUser is list of tags from organization table.
            const columnTag = (
                Array.isArray(this.props.results.user) && 
                this.props.results.user.includes(tag)
              ) ? tag : null;
              
            // title derived from title in results-config.json
            const title = columnTag ? tagValues.title : null;

            if (title) {
                titles.push(title);
                tags.push(tag);
            }
        });


        return {
            tags,
            columns: ['Athlete', 'Access Granted', 'Results Available', ...titles],
            options: {
                download: false,
                filter: false,
                print: false,
                selectableRows: 'none',
                responsive: 'standard',
                tableBodyMaxHeight: "500px",
                customSearch: customSearch,

                customSort: (data, colIndex, order) => {
                    switch (colIndex) {
                        // first two columns
                        case 0:
                        case 1:
                            return data.sort((a, b) => (order === 'asc') ? a.data[colIndex].localeCompare(b.data[colIndex]) :
                                b.data[colIndex].localeCompare(a.data[colIndex]));
                        // date column
                        case 2:
                            return data.sort((a, b) => {
                                let aText = this.nodeToString(a.data[colIndex]);
                                let bText = this.nodeToString(b.data[colIndex]);
                                return (order === 'asc') ? aText.localeCompare(bText) : bText.localeCompare(aText);
                            });
                        // the rest of the columns
                        default:
                            return data.sort((a, b) => {
                                let aScore = this.colorToScore(a.data[colIndex]);
                                let bScore = this.colorToScore(b.data[colIndex]);
                                return (order === 'asc') ? aScore - bScore : bScore - aScore;
                            });
                    };
                }
            }

        };
    };

    render() {
        const { teamTable } = this.state;

        const teamData = this.generateTeamData();
        if (!teamTable) {
            return <div>Loading line 199...</div>; // or some other placeholder
        }

        const table = this.props.width > 767 ?
            <MUIDataTable
                title="Your Team"
                data={teamData}
                columns={teamTable.columns}
                options={teamTable.options}
                className="mb-5 team-table"
            />
            :
            <MUIMobileTable
                title="Your Team"
                data={teamData}
                columns={teamTable.columns}
                options={teamTable.options}
                className="mb-5 team-table"
            />

        return (
            <div>
                <Navbar />
                <div className="container my-5">
                    {table}
                </div>
                <Footer />
            </div>
        )
    }
};

const mapStateToProps = state => ({
    results: state.results,
    session: state.session,
    width: state.ui.width
});

const mapDispatchToProps = dispatch => ({
    loadTeamThunk: () => dispatch(loadTeamThunk())
});


export default connect(mapStateToProps, mapDispatchToProps)(TeamPage);
