import React from 'react';

export class Chondro2Intro extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Introduction to Runner's Knee</h2>
        </div>

        <div className='title-break'>
          <span>High Risk Sports</span>
        </div>
        <div className='high-risk-list my-3 py-1'>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/runner.png'/>
            <span>Running</span>
          </div>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/soccer.png' />
            <span>Soccer</span>
          </div>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/basketball.png' />
            <span>Basketball</span>
          </div>
        </div>

        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img src="/assets/imgs/results/content/RunnerKnee.jpg" height={300} className="overview-img" alt=""/>
          <p>Runner's knee (chondromalacia patellae) involves an inflammation of the cartilage below your knee cap (patella).  Common symptoms of runner's knee include:
          <ul className="mb-0">
                    <li>Pain around the front of the knee or the sides of the kneecap where the knee connects with the lower end of the thighbone (femur). The pain usually worsens when you are active.</li>
                    <li>Rubbing, grinding, or clicking sound of the kneecap when you bend and straighten your knee.</li>
                    <li>Kneecap that is tender to the touch.</li>
                </ul>
          </p>
        </div>
        <div className='title-break'>
          <span>Frequency</span>
        </div>
        <div className="card">
          <p>This condition is common among young, athletic individuals. The prevalence of runner’s knee is: 
          <ul className="mb-0">
                    <li>higher in females than males: 20% in female / 11% in male gymnasts, and 11% in female / 5% in male non-gymnastic athletes (<a href="https://europepmc.org/article/med/14642059">Zhang et al. 2003</a>).</li>
                    <li>35% in professional male cyclists.</li>
                    <li>15% among runners. </li>
                    <li>36% of competitive athletes and 40% in professional athletes (<a href="https://pubmed.ncbi.nlm.nih.gov/20216470/">Flanigan et al. 2010</a>).</li>
                    <li>17% of athletes without knee pain had evidence of cartilage damage by MRI (<a href="https://pubmed.ncbi.nlm.nih.gov/29886437/">Culvenor et al. 2019</a>).</li>
                </ul>
          </p>
          
        </div>

        <div className='title-break'>
          <span>Causes</span>
        </div>
        <div className="card">
          <p>Potential risk factors for runner's knee include:
          <ul className="mb-0">
                    <li>Overuse while playing sports.</li>
                    <li>Weak thigh muscles.</li>
                    <li>Tight hamstrings or Achilles tendons. </li>
                    <li>Poor foot support.</li>
                    <li>Walking or running with the feet rolling in while the thigh muscles pull the kneecap outward.</li>
                    <li>Repeated stress to your knee joints, such as from running, skiing, or jumping.</li>
                    <li>Prior kneecap injury or arthritis.</li>
                </ul>
          </p>
        </div>
      </div>
    )
  }
}

export default Chondro2Intro;
