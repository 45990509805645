import React from 'react';
import { connect } from 'react-redux';
import { findBucket } from '../../utils/results';
import OstpGraph from '../../components/ostp-graph';
import RiskFactorGraph from '../../components/risk-factor-graph';
import Collapsible from 'react-collapsible';
import { getPercentileText2 } from '../../utils/results';

const bmdChartConfig = require('../../../json/ostp-config.json');

// Displays the histrogram, the percentile and the fold increase risk for osteoporosis.
// percentile and fold increase are from the index by comparing estimated_BMD to avgBMD
// the graph is from crm data for bone (uses bmdScore instead of estimated_bmd). The absolute values for the bone (bmd_Score) and bone-mineral-density (estimated_bmd) scores differ.  
// Variable Names and their Meaning:
// bmdIndex <= {source: estimated_BMD to avgBMD, purpose: Set histogram bin in OstpGraph component }
// result <= {source: server's geneticAlgorithm, purpose: equivalent to bmdScore}
// bmdScore <= source: server's geneticAlgorithm (UER.result), purpose: used to calculate estimated_BMD. If CRFs are known, then bmdScore is adjusted_result.
// estimated_BMD <= calculated by using constants on the bmdScore, purpose: calibrate raw genetic score to t score. If CRFs are known, then UER.adjusted_result = estimated_BMD 
// avgBMD from ostp-config.json. average estimated_BMD from osteo scores
//relativeOsteoRisk from from ostp-config.json. percent increased/decreased risk compared to baseline for people in the buckets
//  binSize is the percentage of people in the bucket. Sum of all binSize == 100
//adjusted_result from UER.adjusted_result. Result from genetic score and demogs.
// ostp-graph.js has code to draw the graph.
//ostp-config.json contains variables used to plot the graph.


// function gets essential bmd information
class OsteoResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bmdIndex: null,
            bmdPercentile: null,
            bmdRiskPercent: null,
            estimated_BMD: null,
            adjusted: false,
            percentile: null,
        };
    }
    // setupData is the information used to create the buckets. It has the following fields:

    async componentDidMount() {
        const { result } = this.props;

        await new Promise(resolve => setTimeout(resolve, 0)); // Wait for the props to fully load
        if (result && typeof result === 'object') {
            const setupData = bmdChartConfig.setupData;
            const { avgBMD, avgAdjustedResult, relativeFractureRisk, relativeOsteoRisk, relativeOsteoRisk2, binSize } = setupData;
            let percentile = null;
            if (result.hasOwnProperty('percentile') && result.percentile !== undefined) {
                percentile = result.percentile;
            }
            let bmdScore, estimated_BMD, bmdIndex, adjusted;
            if (result.hasOwnProperty('adjusted_result')) {  // has demogs.
                bmdScore = parseFloat(result.adjusted_result);
                estimated_BMD = bmdScore;
                bmdIndex = findBucket(bmdScore, avgAdjustedResult); //bmdScore is adjusted_result. avgBOG from bmdChartConfig
                this.setState({
                    adjusted: true,  // Set adjusted to true if adjusted_result is present
                    bmdScore,
                    bmdIndex,
                    estimated_BMD,
                    percentile: percentile
                });
                

            } else {  // no demogs
                bmdScore = parseFloat(result.value);
                estimated_BMD = result['estScore'];
                bmdIndex = findBucket(estimated_BMD, avgBMD); //estimated_BMD is UER.result modified to align with t score.  avgBMD from bmdChartConfig
                this.setState({
                    bmdScore,
                    estimated_BMD,
                    bmdIndex,
                    percentile: percentile // from UER.percentile, not bmdPercentile.

                });
            }
            const bmdRiskPercent = relativeOsteoRisk[bmdIndex];
            const bmdRiskPercent2 = relativeOsteoRisk2[bmdIndex];

            let bmdPercentile;

            if (percentile !== null && percentile !== undefined) {

                bmdPercentile = percentile;
            } else {
                bmdPercentile = binSize.reduce(function (total, size, index) {
                    if (index > bmdIndex) {
                        return total;
                    }
                    return total + size;
                });
            }

            // return [bmdIndex, bmdPercentile, bmdRiskPercent];
            this.setState({
                bmdIndex,
                bmdPercentile,
                bmdRiskPercent,
                bmdRiskPercent2,
                estimated_BMD,
            });
        }
    }

    render() {
        // NOTE: A lot of .toFixed() calculations are in this file. We may want to think about sig-figs and what the best number to round to for consistency is.

        const { width } = this.props;
        const { bmdIndex, estimated_BMD, bmdPercentile, bmdRiskPercent, bmdRiskPercent2, adjusted: isAdjusted } = this.state;
        let percentileText = ''; // Define percentileText variable

        if (this.state.percentile !== null) {
            percentileText = getPercentileText2(this.state.percentile);
        }

        if (bmdIndex === null || bmdPercentile === null || bmdRiskPercent === null || bmdRiskPercent2 === null || estimated_BMD === null) {
            // Return a loading state or placeholder while waiting for data
            return <div>Loading props ....</div>;
        }

        return (
            <div>
                <div className="title-margins">
                    <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Bone Mineral Density Results</h2>
                </div>
                <div className="card p-1">
                    <Collapsible trigger="Tutorial">
                        <p className="intro-vid"><iframe title="Results" width="560" height="315" src="https://www.youtube.com/embed/9VplLop9vK8?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
                    </Collapsible>
                </div>
                <div className="card">
                    <p>Low bone density is a key factor in diagnosing osteoporosis. The <b>BMD test</b> uses 22,871 DNA markers (<a href="https://www.ncbi.nlm.nih.gov/pubmed/30048462">Kim, 2018</a>), and its score correlates with bone mineral density.</p>
                    <p>Each genetic marker contributes a small amount of information. The total information from all markers summed together has a large effect.</p>
                </div>
                <div className='title-break'>
                    <span>Results of the <b>Bone Mineral Density</b> test. </span>
                </div>
                <div className="card">
                    {
                        isAdjusted ?
                            <><OstpGraph graphType="osteo2" bmdIndex={bmdIndex} width={width} crmKey='results-adjusted-osteo-histogram'/>
                                <p className="graph-comment" align="center">**Hover over the graph for more information.**</p>
                                <p>Your genetic score for bone mineral density (t score) is <strong>{estimated_BMD.toFixed(3)}</strong>. You are in the <strong>{percentileText}</strong>, indicating that your risk for osteoporosis is <strong>{(bmdRiskPercent2)}</strong> fold, where 1.0 is the average. Your age, sex, height and weight have been factored in to your score to better predict your bone mineral density. If your risk for osteoporosis is more than six fold, AxGen recommends you consult your health care provider to assess your bone mineral density.</p>            <div >
                                </div></>

                            :
                            <><OstpGraph graphType="osteo" bmdIndex={bmdIndex} width={width} crmKey='results-osteo-histogram' />
                                <p className="graph-comment" align="center">**Hover over the graph for more information.**</p>
                                <p>Your genetic score for bone mineral density (t score) is <strong>{estimated_BMD.toFixed(3)}</strong>. <strong>{bmdPercentile}%</strong> of people have an osteoporosis genetic score equal or lower than you, indicating that your risk for osteoporosis is <strong>{(bmdRiskPercent)}</strong> fold, where 1.0 is the average. Your age, sex and whether you are post-menopausal (if you are female) also affect your risk. If your risk for osteoporosis is more than six fold, AxGen recommends you consult your health care provider to assess your bone mineral density.</p></>

                    }

                </div>
                <div className='title-break'>
                    <span>How to use the graph </span>
                </div>
                <div className="card">
                    <p>To use the graph, find the circle that shows your genetic score. The histogram shows the range of genetic scores and the number of people with that score. The bin containing your score tells you how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have a greater risk for osteoporosis. </p>
                    <p>The line shows the risk for osteoporosis for each bin. The solid horizontal line shows your risk and the dotted line is for normal risk of osteoporosis.</p>
                    <p>If your score is on the left part of the graph and above 1.0, then you are at increased risk for osteoporosis. Conversely, if your score is on the right part and below 1.0, you have decreased risk.</p>
                </div>
                {
                    isAdjusted ? (
                        (() => {
                            const graphMax = .3;
                            const data = [0.1719, 0.0699, 0.24648682985];
                            return (
                                <div>
                                    <div className='title-break'>
                                        <span>Impact of Genetics and Clinical Risk Factors on Osteoporosis Risk</span>
                                    </div>
                                    <div className="card">
                                        <RiskFactorGraph graphMax={graphMax} data={data} tag='osteo' width={width} />

                                        <p>The graph shows how risk for osteoporosis is affected by genetics, risk factors (height, age, weight, sex) or both combined. The genetic test provides an estimate of your bone mineral density (t score units), which is the gold standard used to diagnose osteoporosis. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts bone mineral density exactly and 0.00 means that there is no information. For bone mineral density, genetics has a larger effect than risk factors, but combining them together has the most effect; the combined score explains 24.6% of the variance in the bone mineral density value. </p>
                                    </div>
                                </div>
                            );
                        })()
                    )
                        : null
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        results: state.results,
    };
};

export default connect(mapStateToProps)(OsteoResults);
