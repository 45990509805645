import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';


export const ProstateResults = ({ userName, nameEndsWithS, uploadID, width }) => {
  // this page contains the results of multiple tests
  const { crm, uploads } = useSelector((state) => ({
    crm: state.crm,
    uploads: state.results.uploads,
  }));

  const getGraph = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});

    const crmKey = `results-${tag}-histogram`;
    return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
  };

  const getPercentile = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    return <TagPercentile result={tagResult} tag={tag} usesUKData={false} />;
  };

  return (
    <div>
      <div className='title-margins'>
        <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Prostate Cancer</h2>
        <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/33398198/">Conti et al., 2021</a>]</h5>
      </div>
      <div className='title-break'>
        <span>Your Results</span>
      </div>
      <div className="card">
        <p>To use the graph, find the red line that shows your genetic score.  The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have higher risk for prostate cancer. (Units for Beta are arbitrary.) </p>

        {getGraph("prostate")}
        <p className="mb-0">{getPercentile("prostate")} Use the figure below to find your risk for prostate cancer based on your percentile. </p>
      </div>

      <div className='title-break'>
        <span>Risk for Prostate Cancer</span>
      </div>
      <div className="card">
        <img src="/assets/imgs/results/content/prostate_risk.png" height={300} className="risk-graph-container" alt="" />
        <p>The x-axis indicates your age, the y-axis indicates your absolute risk at a given age and the colored lines indicate different percentiles for the PRS score. Absolute risk is the fraction of people at that age that have had prostate cancer. For example, 85 yo men in the 99<sup>th</sup> percentile have an absolute risk of about 0.63, meaning that 63% have had prostate cancer in their lifetime. The risk for prostate cancer for men in the 99<sup>th</sup> percentile is 11.6 fold higher compared to the 50<sup>th</sup> percentile.  </p>
        </div>
        <div className='title-break'>
          <span>Comments</span>
        </div>
        <div className="card">
          <p>Men aged between 55 and 69 yr with a high polygenic risk score may benefit from earlier and more-frequent PSA screening. For men with a low polygenic risk score and a borderline Prostate Specific Antigen level, biopsy may be unnecessary.</p>
          <p>Prostate PRS is being used in the eMERGE IV network beginning in 2022. Patients with a high-risk prostate PRS will receive guideline-informed health recommendations.</p>
        </div>
      </div>

  );
};

export default ProstateResults;
