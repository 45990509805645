import React from 'react';

class VitDIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Vitamin D</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/vitD1.jpeg" height={200}/>   <p>Vitamin D is naturally present in a few foods, added to others, and available as a dietary supplement. It is also produced from sunlight striking the skin, which triggers vitamin D synthesis.</p>
        <p>Vitamin D promotes calcium absorption that is important for bone mineralization, growth and remodeling. Vitamin D prevents rickets in children and osteomalacia in adults. Together with calcium, vitamin D helps protect older adults from osteoporosis. Finally, vitamin D reduces inflammation and modulates processes such as cell growth, neuromuscular and immune function, and glucose metabolism. </p>
        </div>
        <div className='title-break'>
          <span>Sources of vitamin D</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/vitD2.jpeg" height={200}/><p>Getting sufficient sunlight is the best way to produce vitamin D. Sunlight converts cutaneous 7-dehydrocholesterol to previtamin D3, which in turn becomes vitamin D3. Older people and those with dark skin are less able to produce vitamin D from sunlight. Approximately 5–30 minutes of sun exposure, either daily or at least twice a week without sunscreen is usually sufficient. Moderate use of commercial tanning beds that emit 2% to 6% UVB radiation is also effective [<a href="https://pubmed.ncbi.nlm.nih.gov/17634462/">Hollick, 2007</a>]. </p>
        <p>But despite the importance of the sun for vitamin D synthesis, one should limit skin exposure to sunlight and UV radiation from tanning beds. UV exposure increases your risk of skin cancer. Sun screen with a sun protection factor (SPF) of 15 or higher should be used to reduce the risk of skin cancer. In practice, there is some synthesis of vitamin D even when sunscreen is applied, since people usually do not apply sufficient amounts of sunscreen to cover all sun-exposed skin.</p>
        </div>
        <div className="card">
        <p>Another source of vitamin D is from certain foods. Food sources rich in vitamin D include: </p>
              <ul style={{listStyleType: 'square'}}>
                  <li>fatty fish, such as trout, salmon, mackerel, and tuna</li>
                  <li>egg yolks, cheese and beef liver </li>
                  <li>fortified milk, cereals and juices.</li>
                  <li>mushrooms </li>
              </ul>
        
        </div>
        <div className="card">
          <p>You can obtain vitamin D from supplements.  Vitamin D intake is measured in micrograms (mcg) or international units (IU). One microgram of vitamin D is equal to 40 IU.  Here is how much vitamin D you should take:</p>
            <ul style={{listStyleType: 'square'}}>
                  <li>Infants 0–12 months: 400 IU (10 mcg).</li>
                  <li>Children 1–18 years: 600 IU (15 mcg).</li>
                  <li>Adults up to 70 years: 600 IU (15 mcg).</li>
                  <li>Adults over 70 years: 800 IU (20 mcg).</li>
                  <li>Pregnant or lactating women: 600 IU (15 mcg).</li>
            </ul>
          
        </div>
      <div className='title-break'>
          <span>Frequency of vitamin D deficiency</span>
        </div>
      <div className="card">
        <p>The threshold for vitamin D deficiency is less than 52 nmol/L (20 ng/ml)). Vitamin D deficiency is very common. In a large study involving more than 400,000 people of European ancestry from the UK, more than half were vitamin D deficient; the median level of vitamin D among all those tested was 46 nmol/L (18 nmol/ml), which is below the threshold for vitamin D deficiency. Among athletes in various sports, 56% had insufficient levels of vitamin D [<a href="https://pubmed.ncbi.nlm.nih.gov/21310306/">Forrest  and Stuhldreher, 2011</a>].</p>
      </div>
      </div>
    )
  }
}

export default VitDIntro;
