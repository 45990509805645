import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import { TagPercentile } from '../../components/tag-percentile';

export const GlutenomicsResults = (props) => {
    const { crm, result } = props;
    let testComponent;
      testComponent =
      <div>
        <div className="card">
            <p>The AxGen test for gluten sensitivity uses a genetic algorithm with 2513 genetic markers developed by <a href="https://pubmed.ncbi.nlm.nih.gov/26244058/">Abraham et al., 2015</a>. </p>
            <BetaHistogram crm={crm} result={result} crmKey='results-glutenomics-histogram' />
            <p>To use this graph, find the red line for your genetic score. The blue histogram shows the overall number of people and their gluten genetic scores. The bin containing your score tells you how many other people have a score like yours. <b>If your score is on the right side of the histogram, you will tend to have a greater sensitivity to gluten. </b>Your sensitivity to gluten can be measured by looking at your chance of developing celiac disease, which is an autoimmune disease caused by eating gluten (Table).  </p>
        </div>
        <div className='title-break'>
          <span>Gluten Sensitivity and Celiac Disease.</span>
        </div>
        <div className="table-container">
            <p>Your gluten genetic score is highly predictive of your risk for celiac disease [<a href="https://pubmed.ncbi.nlm.nih.gov/26244058/">Abraham et al., 2015</a>].</p>
                <div className="table-subcontainer">
                    <table className="default-table">
                        <thead>
                            <tr>
                                <th>Gluten Genetic Score Percentile</th>
                                <th>Risk for Celiac Disease</th>
                                <th>Fold Change in Risk for Celiac Disease<sup>*</sup> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>below 35%</td>
                                <td>0%</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td>50%</td>
                                <td>2.5%</td>
                                <td>1.8x</td>
                            </tr>
                            <tr>
                                <td>80%</td>
                                <td>4.2%</td>
                                <td>3x</td>
                            </tr>
                            <tr>
                                <td>90%</td>
                                <td>7.0%</td>
                                <td>5x</td>
                            </tr>
                            <tr>
                                <td>95%</td>
                                <td>11.2%</td>
                                <td>8x</td>
                            </tr>
                        </tbody>
                    </table>
                    <p></p>
                    <p><sup>*</sup>The overall incidence of celiac disease is 1.4%. Individuals with a score below the 35th percentile are protected from getting celiac disease. Individuals at the 95th percentile have an 11.2% chance of getting celiac disease, which is 8 fold higher than the population average.</p>
                </div>
        </div>
        <div className="card">
            <img alt="" className="overview-img" src="/assets/imgs/results/content/Asset20.png" height={200}/> 
            <p>We offer the most advanced Genetic testing for gluten sensitivity in the industry. Our Gluten Genomic test powered by AXGEN uses a genetic algorithm with 2,513 gene mutations analyzed (SNPs), including the HLA-DQ series to ensure you get the most precise gluten genomic profile. Other companies test for only two genes, HLA-DQ2 and HLA-DQ8.</p>
            <p>Our Genomic results will categorize you in one of 5 groups based on your percentage of genetic mutations to gluten sensitivities after evaluating 2,513 genes related to Gluten sensitivities. The five groups are <a href="https://www.glutenomics.com/supplements-group-a">&lt;35%</a>, <a href="https://www.glutenomics.com/supplements-group-b">35-50%</a>, <a href="https://www.glutenomics.com/supplements-group-c">51-80%</a>, <a href="https://www.glutenomics.com/supplements-group-d">81-90%</a> and <a href="https://www.glutenomics.com/group-e-91">&gt;90%</a>. Lifestyle and supplement recommendations for each group are provided based on your personalized genomics profile.</p>
            <p>Glutenomics powered by AXGEN offers the only Genetic test in the market that helps determine not only your genetic susceptibility to gluten sensitivities but also your risk for developing Celiac Disease. The outcome of your test will indicate lifestyle and supplement recommendations based on your result findings.</p>
        </div>        
      </div>

      return (
      <div>
         <div className='title-margins'>
        <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Gluten Sensitivity</h2>
        <h2><p><TagPercentile result={props.result} tag='glutenomics' showPercentile={true} /></p></h2>
        </div>
        { testComponent }
      </div>
    );
};


export default GlutenomicsResults;
