import React from 'react';

const GoutIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Gout</h2>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/gout_fig.png" height={300}/> 
        <p>Gout is a type of inflammatory arthritis that causes pain and swelling in your joints, usually in your big toe or a lower limb. An attack of gout can occur suddenly, often waking you up in the middle of the night with the sensation that your big toe is on fire. The affected joint is hot, swollen and so tender that even the weight of the bedsheet on it may seem intolerable.</p>
        <p>There are times when symptoms get worse, known as flares, and times when there are no symptoms, known as remission. Repeated bouts of gout can lead to gouty arthritis, a worsening form of arthritis.</p>
      </div>
      <div className='title-break'>
        <span>Symptoms</span>
      </div>
      <div className="card">
        <ul>
            <li>Pain, usually intense</li>
            <li>Swelling</li>
            <li>Redness</li>
            <li>Heat</li>
        </ul>
      </div>
      <div className='title-break'>
        <span>Causes</span>
      </div>
      <div className="card">
        <p>Gout is caused by a condition known as hyperuricemia, when there is too much uric acid in the body. Uric acid is made from the break down of purines, which are found in the foods you eat. When there is too much uric acid, uric acid crystals can build up in joints, fluids, and tissues.</p>
        <ul>
            <li>Males have higher risk than females</li>
            <li>Obesity</li>
            <li>Drinks high in fructose</li>
            <li>Having a diet high in purines, which the body breaks down into uric acid. Purine-rich foods include red meat, organ meat, and some kinds of seafood, such as anchovies, sardines, mussels, scallops, trout, and tuna.</li>
            <li>Heart disease</li>
            <li>Diabetes</li>
            <li>Kidney disease</li>
            <li>Using diuretics</li>
        </ul>
      </div>
      <div className='title-break'>
        <span>Genetics</span>
      </div>
      <div className="card">
        <p>The heritability of gout is 65 percent. About 20 percent of people with gout have a relative with the condition. </p>
        <p>The results page shows a genetic test for gout that scans your entire genome for risk. This test shows that certain people have 6.5 fold increased risk for gout than others. </p>
      </div>
      <div className='title-break'>
        <span>Prevention</span>
      </div>
      <div className="card">
        <p>If your genetic test indicates that you have increased risk for gout, the best way to prevent it is to limit how often you consume high-purine foods and drinks. Make sure you drink plenty of water to help your kidneys function better and avoid dehydration.</p>
        <p>Getting regular exercise can help reduce stress on your joints and reduce your risk for obesity and other health conditions that increase your risk of developing gout.</p>
      </div>
    </div>
  )
}

export default GoutIntro;
