import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { loadResultsThunk } from '../actions';
import { getPercentileText2 } from '../utils/results';
import TagPercentile from '../components/tag-percentile';

let resultsConfig = require('../../json/results-config.json');

const getUploadID = (props) => {
  const { myUploadID } = props;
  const { uploadID: pathUploadID } = props.match.params;
  return (pathUploadID) ? pathUploadID : myUploadID;
};

export class ResultsListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "injury": [],
      "PRSinjury": [],
      "performance": [],
      "health": [],
      "PRShealth": []
    };
  };

  setupState() {
    const preCache = {
      "injury": {
        'high': [], 'neutral': [], 'low': [], 'unknown': []
      },
      "PRSinjury": {
        'high': [], 'neutral': [], 'low': [], 'unknown': []
      },
      "performance": {
        'high': [], 'neutral': [], 'low': [], 'unknown': []
      },
      "health": {
        'high': [], 'neutral': [], 'low': [], 'unknown': []
      },
      "PRShealth": {
        'high': [], 'neutral': [], 'low': [], 'unknown': []
      }
    };
    const { uploads } = this.props.results;
    console.log('Hi')
    const uploadID = getUploadID(this.props);
    // uploadID updates faster than the results state, so this conditional makes sure the results and uploadID match
    if (!uploads.hasOwnProperty(uploadID)) {
      return;
    };
    const resultData = uploads[uploadID]['results'];
    Object.keys(resultData).forEach((tag) => {
      let result = resultData[tag];

      // skip tags that lack pages
      if (result.hasOwnProperty("pageless") && result['pageless'] === true) {
        return;
      };
      let classification = resultsConfig[tag]?.classification;
      // only tags with a level can be added here
      if (result.hasOwnProperty("level")) {
        // ibuprofen is sorted slightly differently than the other tests
        if (tag === 'ibuprofen') {
          if (result.level === 'high') {
            preCache[classification]['low'].push(result);
          } else if (result.level === 'neutral') {
            preCache[classification]['high'].push(result);
          } else {
            preCache[classification]['neutral'].push(result);
          };
        } else {
          const preCacheClassification = preCache[classification];
          if (!!preCacheClassification) {
            preCacheClassification[result.level].push(result)
          }
          
        };
      };
    });

    // plot all the risk cards first, even if some are regular and some are PRS.
    // combine health/PRS health into one combined array combinedHealthArray
    // combine injury/PRS injury into one combined array combinedInjuryArray
    // reassign health and injury to have the combined arrays
    // in the render section, health and injury plot the combined cards, sorted by level.

    const healthArray = this.concatter(preCache["health"], 'health');
    const PRShealthArray = this.concatter(preCache["PRShealth"], 'PRShealth');
    const injuryArray = this.concatter(preCache["injury"], 'injury');
    const PRSInjuryArray = this.concatter(preCache["PRSinjury"], 'PRSinjury');
    const levels = ['high', 'low', 'neutral', 'unknown'];

    const combinedHealthArray = levels
      .map(level => healthArray.concat(PRShealthArray).filter(result => result.level === level))
      .reduce((acc, el) => acc.concat(el), []);
    const combinedInjuryArray = levels
      .map(level => injuryArray.concat(PRSInjuryArray).filter(result => result.level === level))
      .reduce((acc, el) => acc.concat(el), []);

    this.setState({
      "health": combinedHealthArray,
      "injury": combinedInjuryArray,
      "performance": this.concatter(preCache["performance"], 'performance')      
    });
  }

  componentDidMount() {
    const { uploads } = this.props.results;
    const uploadID = getUploadID(this.props);

    if (!(uploadID in uploads)) {
      this.props.loadResults(this.props.match.params.uploadID);
    } else {
      this.setupState();
    };
  };

  componentDidUpdate(prevProps) {
    if (getUploadID(prevProps) !== getUploadID(this.props)) {
      this.props.loadResults(getUploadID(this.props));
    };
    if (this.props.results !== prevProps.results) {
      if (!getUploadID(this.props)) {
        return; // no path ID specified and user doesn't have any uploads
      };
      this.setupState();
    };
  };

  // takes a hash of arrays, concats into a single array
  concatter(preCache, classification) {
    // we iterate through and concat it all together
    let iterable = classification === ('injury' || "PRSinjury" || 'health' || 'PRShealth') ?
      [preCache['high'], preCache['low'], preCache['neutral'], preCache['unknown']]
      : [preCache['low'], preCache['high'], preCache['neutral'], preCache['unknown']];
    return iterable.reduce((acc, el) => acc.concat(el))
  };

  render() {
    // places name at the top of the page
    const uploadID = getUploadID(this.props);
    const { uploads } = this.props.results;
    const userName = ((uploads[uploadID] || {})['demographics'] || {})['name'] || "";
    const nameEndsWithS = userName.substr(-1) === 's';
    const marg = this.props.ui.width < 768 ? 'mx-0' : '';
    const healthDisplay = this.state['health']?.length === 0 ? 'hide' : '';
    console.log('userName ', userName)
    console.log('151')
    return (
      <div>
        <Navbar />
        <div className="container mt-4">
          <div className="text-center">
            <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Genetic Results</h2>

          </div>

          <div className="card p-3 mt-2 mb-3">
            <p>Here are your genetic results:</p>
            <ul className="mb-0">
              <li>Summary only: This page shows a summary of your results with red indicating risk, green indicating protection and gray indicating average risk. For caffeine sensitivity, blue indicates a non-standard caffeine metabolism. </li>
              <li>Learn yourself: Clicking on any card provides more information about each test, such as the amount of increased risk and prehabilitation measures to be taken. </li>
              <li>AxGen consultation: You can purchase a consultation <Link to="/consultations">here</Link> with an AxGen counselor to help explain your results.</li>
            </ul>
          </div>

          <br></br>
          <div className={`title-break ${healthDisplay}`}>
            <span>Health</span>
          </div>
          <div className={`row ${marg} mt-4 d-flex`}>
            {
              this.state['health'].map((result, i) => (
                <ResultCard
                  key={i}
                  {...result}
                  riskLevel={result.level}
                  width={this.props.ui.width}
                  classification="health"
                  {...{ uploadID }}
                />
              ))
            }
          </div>

          <div className='title-break'>
            <span>Injuries</span>
          </div>
          <div className={`row ${marg} d-flex`}>
            {
              this.state['injury'].concat(this.state['PRSinjury']).map((result, i) => (
                <ResultCard
                  key={i}
                  {...result}
                  riskLevel={result.level}
                  width={this.props.ui.width}
                  classification="injury"
                  {...{ uploadID }}
                />
              ))
            }
          </div>
          <br></br>
          <div className='title-break'>
            <span>Nutrients and Performance</span>
          </div>
          <div className={`row ${marg} mt-4 d-flex`}>
            {
              this.state['performance'].map((result, i) => (
                <ResultCard
                  key={i}
                  {...result}
                  riskLevel={result.level}
                  width={this.props.ui.width}
                  classification="performance"
                  {...{ uploadID }}
                />
              ))
            }
          </div>

        </div>
        <Footer />
      </div>
    );
  };
};

export class ResultCard extends React.Component {
  // handles mobile label generation
  mobileRiskLabelGenerator(riskPercent, riskLevel) {
    const textObj = {
      'high': 'Increased risk',
      'low': 'Decreased risk',
      'neutral': 'Normal risk'
    }
    let perc = (riskPercent === "0" || riskPercent === "0%") ? null : riskPercent;
    let br = perc ? <br /> : null;
    return (
      <div>
        {perc}
        {br}
        {textObj[riskLevel]}
      </div>
    );
  };

  // handles normal label generation using ResultCard's props 
  // used to make label like "6% Increased risk"
  getRiskLabel(relativeRisk, riskLevel, geneticTextMap, width) {
    console.log('254')
    let riskPercentage = (Math.abs(relativeRisk) * 100.0).toFixed(0) + "%";
    geneticTextMap = {
      'high': `${riskPercentage} Increased risk`,
      'low': `${riskPercentage} Decreased risk`,
      'neutral': `Normal risk`
    };
    return (width < 768) ? this.mobileRiskLabelGenerator(riskPercentage, riskLevel) : geneticTextMap[riskLevel];
  };

  // used to get percentile label for tags that have percentile value in upload_entry_results
  getPercentileLabel(tag, percentile, riskLevel, width) {
    if (tag === 'vitD' || tag === 'AAA'){
      console.log('264  tag percentile, riskLevel :', tag, percentile, riskLevel)
    }
    let geneticTextMap2 = {

      'high': getPercentileText2(percentile),
      'low': getPercentileText2(percentile),
      'neutral': getPercentileText2(percentile)
    };
    if (tag === 'vitD' || tag === 'AAA'){
      console.log('264  tag percentile, riskLevel, geneticTextMap2 :', tag, percentile, riskLevel, geneticTextMap2)
    }
    return (width < 768) ? this.mobileRiskLabelGenerator(percentile, riskLevel) : geneticTextMap2[riskLevel];
  };

  render() {
    const { tag, uploadID, relativeRisk, color, longLabel, width, riskLevel, classification, level, percentile } = this.props;
    let prettyTag = tag.replace(/-/g, ' ');
    const overrides = (tag in resultsConfig) ? resultsConfig[tag] : {};
    let geneticTextMap = {}

    let riskLabel = longLabel;
    if (tag === 'vitD' || tag === 'AAA'){
      console.log('0tag :', tag, riskLabel)
    }
    if (classification === 'injury' && relativeRisk) {
      riskLabel = this.getRiskLabel(relativeRisk, riskLevel, geneticTextMap, width);
    } else if (typeof percentile !== 'undefined') {
      // riskLabel = this.getPercentileLabel(tag, percentile, riskLevel, width);
      riskLabel = getPercentileText2(percentile)
      if (tag === 'Mars_HOsteo' || tag === 'AAA'){
        console.log('2tag percentile, riskLabel :', tag, percentile, riskLabel)
      }
    } else {
      console.log("no UER.percentile")
    }
    if (tag === 'Mars_HOsteo'){
      console.log('tag, riskLabel :', tag, riskLabel)
    }

    const pad = width < 768 ? 'px-2' : '';
    const margY = width < 768 ? 'my-2' : 'my-3';
    const cardHeight = width < 768 ? 'min-vh-8' : 'min-vh-15';
    const oneItemWidth = width < 768 ? 'col-6' : "col-4";
    const valPadding = width < 992 ? 'mb-2' : "";
    const redirectLink = (tag === 'testosterone' && level === 'unknown') ? `/results/${uploadID}/${tag}/results` : `/results/${uploadID}/${tag}`;

    return (
      // Creates the row-and-column layout of the cards
      <div className={`dashboard-item ${oneItemWidth} ${pad} ${margY} ${cardHeight}`}>
        <div className="bg2 self-align-stretch"></div>

        {/* Turns each card into a link to the appropriate web-page */}
        <Link to={redirectLink}>
          {/* Creates the image on the left of each card, color determined by the riskLevel calculated above */}
          <div className={`result-card card-hover h-100 ${color}`}>
            <div className="text-center">
              <img alt="" src={"/assets/imgs/results/" + tag + ".png"} className="result-icon" />
              <div className="mt-3">
                {/* Creates the bolded name on each card */}
                <strong className={`result-name ${valPadding}`}>
                  <span>{overrides.title || prettyTag}</span>
                </strong>
                <div className="result-value">
                  {riskLabel}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  };
};


const mapStateToProps = state => ({
  myUploadID: state.session.myUploadID,
  results: state.results,
  ui: state.ui
});
console.log('347')

const mapDispatchToProps = dispatch => ({
  loadResults: (uploadID) => dispatch(loadResultsThunk(uploadID))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsListPage);
