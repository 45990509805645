import React from 'react';

const POAGIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Primary Open-Angle Glaucoma</h2>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/glaucoma.png" height={300}/>   
        <p>Primary Open-Angle Glaucoma (POAG) is the most common form of glaucoma. In a healthy eye, there is a balance between the fluid produced and the fluid that leaves the eye. With POAG, the inner eye pressure (also called intraocular pressure) rises due to blockage of the drainage canals within the eye, preventing fluid from draining. Too much intraocular pressure damages the optic nerve, which sends visual information from your eye to your brain and is vital for good vision. If left untreated, POAG can lead to blindness.</p>
        <p>POAG can occur at any age but is more common in older adults. It is one of the leading causes of blindness for people over the age of 60, affecting about three million Americans.</p>
        <p>It's important to have regular eye exams that include measurements of your eye pressure. If POAG is recognized early, vision loss can be slowed or prevented with treatment and monitoring.</p>
      </div>
      <div className='title-break'>
        <span>Symptoms</span>
      </div>
      <div className="card">
        <p>In early stages, POAG doesn&#39;t have noticeable symptoms.</p>
        <p>In advanced stages, POAG causes blurred vision, along with a narrowed field of vision, starting at the outer edges. If left untreated, POAG can cause blindness.</p>
      </div>
      <div className='title-break'>
        <span>Risk</span>
      </div>
      <div className="card">
        <p>Intraocular pressure is the most important risk factor for the development of POAG, and remains the only known modifiable risk factor.</p>
        <p>Age: The prevalence of POAG increases with age.</p>
        <p>Race: POAG is more prevalent in people of African-Caribbean descent compared with Caucasians.</p>
        <p><strong>Genetics:</strong> The results page shows a genetic test for POAG that scans your entire genome for risk. This test shows that certain people have 9 fold higher risk for POAG than others. If the test indicates increased risk, vision loss can be slowed or prevented by scheduling a test with your eye doctor.</p>
      </div>
      <div className='title-break'>
        <span>Prevention</span>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/glaucoma2.png" height={200}/>  
        <p>If the genetic test indicates increased risk for POAG, the most important preventative step you can take is to have regular eye exams even before there are any symptoms. Visual loss from POAG is irreversible, and therefore prevention is a key strategy to preventing loss of vision.</p>
      </div>
    </div>
  )
}

export default POAGIntro;
