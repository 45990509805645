import React from 'react';

class TestosteroneRecommendation extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Testosterone Recommendations</h2>
          <p>written by Dr. Megan Roche, M.D.</p>
        </div>
        
        <div className="card">
              <ul style={{listStyleType: 'square'}}>
                  <li>Consider requesting a lab test for testosterone levels from your primary care doctor.</li>
                  <li>Aim for at least seven to eight hours of sleep to optimize testosterone release.</li>
                  <li>If obese, appropriate weight loss can improve testosterone levels.</li>
                  <li>If underweight, malnourished, or experiencing RED-S, restoration of weight and/or adequate energy availability can improve testosterone levels.</li>
                  <li>Evaluate dietary habits and review foods that help optimize testosterone levels.</li>
                  <li>Avoid excessive alcohol consumption.</li>
                  <li>Optimize your Vitamin D, zinc, and magnesium levels.</li>
                  <li>If sedentary, start aerobic and resistance exercise.</li>
                  <li>If exercising, make sure to schedule adequate rest between exercise sessions to allow the body to recover.</li>
                  <li>Evaluate stressors and consider behavior modifications to reduce stress.</li>
                  <li>If considering testosterone therapy, work closely with your primary care doctor and be sure to follow the World Anti-Doping Agency Guidelines.</li>
              </ul>
        </div>
        <div className="card">
          <p>If you are concerned about symptoms of low testosterone (including reduced sex drive, erectile dysfunction, hair loss, increase in body fat, fatigue or mood changes) talk to your primary care doctor about measuring testosterone levels. Due to circadian variation in testosterone levels, serum testosterone measurement should be obtained in the morning. In women, testosterone levels vary with the menstrual cycle, with peak levels occurring in the periovulatory phase (<a href="https://www.ncbi.nlm.nih.gov/pubmed/1802820">Dabbs and La Rue, 1991</a>).</p>
        </div>
        <div className="card">
          <p>There are several natural ways to optimize testosterone production through lifestyle modifications. Optimizing testosterone can improve athletic performance. Lifestyle modifications in male and female athletes include getting adequate sleep, evaluating body composition, eating a balanced diet, optimizing vitamins and minerals, monitoring training patterns, and reducing stress.</p>
          </div>
          <div className="card">
          <p>The highest levels of testosterone release occur during REM sleep, which is the phase of sleep that occurs latest in the sleep cycle. Fragmented sleep, sleep disorders, and inadequate total sleep time are associated with reduced testosterone levels. Researchers at the University of Chicago found that one week of sleep restriction (five hours of sleep) in healthy young men resulted in a 10-15% decrease in daytime testosterone levels (<a href="https://www.ncbi.nlm.nih.gov/pubmed/21632481">Leproult and Van Cauter, 2011</a>). Athletes should aim for seven to eight hours of sleep each night. Athletes should practice good sleep hygiene (including removing electronic devices from the bedroom, developing a consistent sleep schedule, and avoiding caffeine or alcohol before bedtime) in order to optimize testosterone production. </p>
          </div>
          <div className="card">
          <p>Body composition impacts testosterone levels. Obesity decreases total testosterone levels from insulin-resistance. In more severe cases, obesity causes suppression of the hypothalamic pituitary axis. Low testosterone in turn causes increased adiposity, creating a perpetuating cycle of metabolic complications. Appropriate weight loss can restore testosterone levels in obese males (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3955331/">Fui et al., 2014</a>). </p>
          </div>
          <div className="card">
          <p>Athletes with relative energy deficiency in sports (RED-S), or impaired physiological function caused by inadequate energy intake and/or excessive energy expenditure, are at high risk for low testosterone levels. Increasing caloric intake and/or modifying exercise patterns can help improve testosterone levels in athletes with RED-S. Restoring a healthy body weight can help improve testosterone levels in athletes who are underweight.</p>
          </div>
          <div className="card">
          <p>There is also a strong link between total testosterone levels and nutritional habits. <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6266690/">Hu et al., 2018</a> found that individuals who frequently eat out and consume a high quantity of breads, pastries, dairy products, and desserts had lower testosterone levels than individuals consuming smaller quantities of these foods. Many processed foods contain high amounts of trans fats, which have been shown to reduce testosterone levels (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5312216/">Minguez-Alarcon et al., 2017</a>). Excessive alcohol consumption or alcohol abuse can reduce testosterone levels through a variety of mechanisms including increasing the conversion of testosterone to estrogen. </p>
          </div>
          <div className="card">
          <p>There may be an association between low levels of Vitamin D and total testosterone. In male Special Operations Soldiers with Vitamin D deficiency, Vitamin D levels were a significant predictor of total testosterone levels when controlling for age and BMI (<a href="https://www.fasebj.org/doi/abs/10.1096/fasebj.29.1_supplement.733.5">Wentz et al., 2015</a>). </p>
          </div>
          <div className="card">
          <p>Zinc blocks the enzyme that converts testosterone to estrogen, and magnesium inhibits the binding of testosterone to sex hormone binding globulin. So deficiencies in either zinc or magnesium ultimately reduce testosterone levels. Foods rich in zinc and magnesium include shellfish, legumes, beans, seeds, nuts and whole grains. </p>
        </div>
        <div className="card">
          <p>Exercise training, including moderate amounts of endurance exercise and resistance training, can improve short-term total testosterone levels in individuals with low levels of fitness. The short-term testosterone response decreases with time as the body adjusts to exercise training. Endurance athletes are at high risk for low testosterone from large training volumes or overtraining. In order to prevent reduction in testosterone levels, acute stress from exercise or training must be followed by periods of recovery. </p>
        </div>
        <div className="card">
          <p>In addition to physical stress, psychological stress (including anticipatory stress) is associated with low levels of testosterone (<a href="https://www.ncbi.nlm.nih.gov/pubmed/9116248">Schulz et al., 1996</a> and <a href="https://www.ncbi.nlm.nih.gov/pubmed/16136009">King et al., 2005</a>). Meditation, social relationships, time management, yoga, and spending time with pets can help reduce psychological stress. Sexual activity can transiently increase testosterone levels and can help reduce psychological stress.</p>
        </div>
        <div className="card">
          <p>Primary care doctors may consider testosterone therapy for males with low testosterone levels and clinical symptoms of low testosterone including sexual dysfunction, decreased energy, and increased body fat. Male athletes with RED-S should prioritize increased energy intake and/or decreased energy expenditure instead of starting with testosterone therapy.</p>
        </div>
        <div className="card">
          <p>The World Anti-Doping Agency requires a therapeutic use exemption (TUE) for exogenous testosterone use in athletes. A TUE can be given for low testosterone (hypogonadism) as a result of organic causes including genetic or developmental abnormalities, testicular trauma, radiation treatment or chemotherapy, and destructive disorders of the pituitary or hypothalamus. A TUE cannot be given for functional causes of low testosterone from severe stress, overtraining, obesity, malnutrition, or chronic systemic illness. Prior to considering testosterone therapy, it’s important to work closely with your primary care doctor and check the World Anti-Doping Agency guidelines. </p>
        </div>
      </div>
    )
  }
}

export default TestosteroneRecommendation;
