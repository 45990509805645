import React, {  useState, useRef, useEffect } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import * as Actions from '../actions';
import Alerts from '../components/alerts';
import { formatAddress, validateAddressInfo } from '../utils/components';

// legacy TODO: Move prices into database for ease of updating
const orderPrices = {
  'kit': parseInt(process.env.REACT_APP_PRICE_KIT),
  'upload': parseInt(process.env.REACT_APP_PRICE_UPLOAD),
};

const StripeForm = ({ session, orders, alerts, width }) => {
  // assorted local states
  const [optionType, chooseOption] = useState("");
  const [uploadType, setUploadType] = useState("");
  const [price, setPrice] = useState(null);
  const [disabled, disableSubmit] = useState(false);
  const [couponCode, applyCoupon] = useState("");
  const [address, setAddress] = useState({
    name: "",
    street: "",
    apt: "",
    city: "",
    state: "",
    zip: "",
    country: ""
  });

  // Stripe hooks
  const stripe = useStripe();
  const elements = useElements();
  
  // these three local states are to be changed in case there is a sale
  const [isSale, setSale] = useState(false);
  const [salePrice, setSalePrice] = useState(0);
  const [saleEndDate, setEndDate] = useState("");

  // refs, which point to input fields, allowing one to access an input's content
  const uploadTypeRef = useRef(null);

  // device screen-size determined style(s)
  const cardPadding = width < 768 ? '' : 'px-1';

  // helper functions
  // calculates price with coupons and then sets the local price state based on that
  const _calculateDiscountPrice = () => {
    const { coupon } = orders;
    let calcPrice = isSale ? salePrice : orderPrices[optionType]; //calculates price determined by sale status or by env
    switch(coupon.type) {
      case 'fixed':
        calcPrice -= coupon.amount;
        break;
      case 'percent':
        calcPrice = (calcPrice * (100.0 - coupon.amount) / 100);
        break;
      default:
        break;
    };

    setPrice(calcPrice.toFixed(0));
  };

  // gets the discount string for presentational reasons if there is a coupon
  const _getDiscountString = () => {
    const { coupon } = orders;
    switch(coupon.type) {
      case 'fixed':
        return `$${coupon.amount} discount applied`;
      case 'percent':
        return `${coupon.amount}% discount applied`;
      default:
        break;
    };
  };
  
  // applies discount for coupons
  const _applyDiscount = () => {
    Actions.removeCoupon(); //removes old coupon to prevent coupon chaining of multiple codes
    Actions.clearAlerts(); //clear alerts
    Actions.loadCoupon(optionType, couponCode);
  };

  // updates address state
  const handleChange = (e) => {
    e.preventDefault();
    let copiedAddress = Object.assign({}, address);
    copiedAddress[e.target.name] = e.target.value;
    setAddress(copiedAddress);
  };

  // submits an order, clears alerts on submit, disables the submit, raises alerts
  // checks for incomplete shipping info as well, although the chances of this happening are quite low as one cannot even submit the form without a complete address
  const handleSubmit = () => {
    Actions.clearAlerts();
    disableSubmit(true);

    if (optionType === 'upload' && !uploadType && !validateAddressInfo(address)) {
      Actions.addAlert('buy-kit', 'error', 'Please select the type of file you want to upload and provide billing details.');
      return;
    };

    if (optionType === 'kit' && !validateAddressInfo(address)) {
      Actions.addAlert('buy-kit', 'error', 'Incomplete shipping details. All fields with a * are required.');
      return;
    };

    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement, { name: session.userName }).then(resp => {
      const { token, error } = resp;
      const orderType = optionType === 'kit' ? 'kit' : uploadType; //determines the order type for saving in the dB
      if (error) {
        Actions.addAlert('buy-kit', 'error', error.message);
        return;
      };
      if (token) {
        if (optionType === 'upload') {
          Actions.buyUpload(token, (price * 100).toFixed(0), couponCode ? couponCode : '', orderType, formatAddress(address));
        } else {
          Actions.buyKit(token, (price * 100).toFixed(0), couponCode ? couponCode : '', orderType, formatAddress(address));
        };
      };
    });
  };

  // re-calculates the price if there is a valid coupon
  // optionType in the conditional prevents price from being calculated before there is an optionType, as that results in a price of NaN 
  useEffect(() => {
    const { coupon } = orders;
    if (optionType) {
      if (coupon) {
        _calculateDiscountPrice();
      // this conditional resets the price if someone puts in an erronous coupon
      // coupon is already removed in _applyDiscount so it does not need to be placed in this else
      } else {
        setPrice(orderPrices[optionType]); //reset price to original price
      };
    // this else conditional clears the coupon state. it functions as a check to remove any coupons that might have persisted in the global state when one goes to this page
    } else {
      Actions.removeCoupon();
    };
  }, [orders.coupon]);

  // sets the disabled submit to false if there are alerts. this can happen on either order success or failure
  useEffect(() => {
    if (Object.keys(alerts).length !== 0) {
      disableSubmit(false);
    };
  }, [alerts]);

  // resets page states given differences between the two order options
  useEffect(() => {
    if (optionType !== "") {
      Actions.removeCoupon();
      Actions.clearAlerts();
      setPrice(orderPrices[optionType]);
      applyCoupon("");

      // conditional specific resetting for optionTypes
      if (optionType === 'kit') {
        if (uploadType !== "") {
          uploadTypeRef.current.value = ""; //reset the ref value only if the uploadType exists, as if it does not, one would get a TypeError here
        };
        setUploadType(""); //resets the upload state to prevent submission of upload from kit
      };
    };
  }, [optionType]);


  // presentational

  // renders sale banner if there is a sale
  const saleBanner = isSale ? (
    <div className={classNames("sale-banner card py-2 my-4")}>
      <h3 className='sale-text'>{ `Flash Sale $${saleEndDate}.00` }</h3>
      <p className='sale-ends mb-0'><strong>{ `Ends ${saleEndDate}`}</strong></p>
    </div>
  ) : ( 
    null 
  );

  const paragraph = isSale ? (
    <p>To place your order, select one of the purchase options, then enter your credit card info.</p>
  ) : (
    <p>To place your order, select one of the purchase options, then enter the required information and a discount code if you have one. If you already have a kit (as a gift for example), then click the "activate" button at the top.</p>
  );

  // handles the padding of the order options on the mobile cards to prevent constraining
  const mobileOptionPadding = width < 768 ? "px-2" : null;

  // handles the display none with the form on success
  const success = ((alerts['buy-kit'] || {})['success'] || []).length > 0;

  // the following handles the submit button
  // renders the buy text
  const buyText = disabled ? 'BUYING' : 'BUY';
  // renders the price crossed out if coupon
  const crossedOutPrice = orders.coupon ? (
    <small className="ml-1">
      <strike>${orderPrices[optionType]}</strike>
    </small>
  ) : (
    null
  );
  // renders the button itself if the price already exists
  const priceBtn = price ? (
    <span>
    {`${buyText} ${optionType.toUpperCase()} $${price}`}
    {crossedOutPrice}
    </span>
  ) : (
    'SELECT KIT OR UPLOAD'
  );
  // conditionally determines the disabled button state for each order type as while the button is the same for all in functionality, disabled state differs
  let disabledState;
  if (optionType === 'kit') {
    disabledState = disabled || !validateAddressInfo(address);
  } else if (optionType === 'upload') {
    disabledState =  disabled || !uploadType || !validateAddressInfo(address);
  } else {
    disabledState = !price || disabled;
  };

  // renders the discount string if the coupon suceeds
  const discountStr = orders.coupon ? (
    <span className="text-success ml-3">{_getDiscountString()}</span>
  ) : (
    null
  );

  const addressForm = optionType ? (
    <div>
      {
       optionType === 'kit' ? 
       <div>
          <strong>Shipping Details</strong>
          <p className="mt-2">Please tell us where you would like us to ship your kit. (Unfortunately we cannot ship outside of the United States).</p>
       </div> : 
        <div className="mb-2">
          <strong>Billing Details</strong>
        </div>
      }
      <div className="row">
        <div className="col-sm-12">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="name"
            placeholder="full name*"
            className="form-control"
            value={address.name}
            required
          />
        </div>
        <div className="col-sm-8">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="street"
            placeholder="street address*"
            className="form-control"
            value={address.street}
            required
          />
        </div>        
        <div className="col-sm-4">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="apt"
            placeholder="apt / unit"
            className="form-control"
            value={address.apt}
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="city"
            placeholder="city*"
            className="form-control"
            value={address.city}
            required
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="state"
            placeholder="state / province*"
            className="form-control"
            value={address.state}
            required
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="zip"
            placeholder="zip / postal code*"
            className="form-control"
            value={address.zip}
            required
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="country"
            placeholder="country*"
            className="form-control"
            value={address.country}
            required
          />
        </div>
      </div>
    </div>
  ) : (
    null
  );

  // renders order form
  const orderForm = optionType ? (
    <div>
      <div>
        <div className="mb-2"><strong>Credit Card</strong></div>
          <CardElement className="mb-4 stripe-ele" />
      </div>
      <div className="mb-4">
        { addressForm }
        <div className="mb-2">
          <strong>Discount Code</strong>
          {discountStr}
        </div>
        <div className="input-group">
          <input
            type="text"
            onChange={(e) => applyCoupon(e.target.value)}
            value={couponCode}
            placeholder="discount code"
            className="form-control mb-0"
          />
          <div className="input-group-append">
            <button className="btn btn-outline-axgen" type="button" onClick={() => _applyDiscount()}>Add Discount</button>
          </div>
            <Alerts tag='load-coupon' />
        </div>
      </div>
    </div>
  ) : (
    null
  );

  return (
    <div>
      {paragraph}
      {saleBanner}
      <form className={classNames("card form py-3", { "d-none": success })}>
        <div className="mb-2">
          <strong>Order Type</strong>
        </div>
        <div className="row mb-3">
            <div className={`col-6 ${mobileOptionPadding}`}>
              <div onClick={() => chooseOption('kit')}
                className={classNames(`card card-hover py-3 ${cardPadding} order-option`, {"active":optionType === "kit"})}>
                <div className="card-body text-center">
                  <div className="icon-axgen-kit" />
                  <div className="mt-4 h3">Kit</div>
                </div>
              </div>
            </div>
            <div className={`col-6 ${mobileOptionPadding}`}>
                <div onClick={() => chooseOption('upload')}
                  className={classNames(`card card-hover py-3 ${cardPadding} order-option`, {"active":optionType === "upload"})}>
                  <div className="card-body text-center">
                    <div className="icon-uploads" />
                    <div className="mt-4 h3">Upload</div>
                  </div>
              </div>
            </div>
        </div>
        <div className={classNames("", {"d-none": optionType !=='upload'})}>
          <div className="mb-2"><strong>Upload Type</strong></div>
          <select
            ref={uploadTypeRef}
            onChange={() => setUploadType(uploadTypeRef.current.value)}
            className="form-control"
            defaultValue="">
            selected
            <option value="" disabled>Select Upload Option</option>
            <option value="23andme">23andMe</option>
            <option value="ancestry">Ancestry</option>
          </select>
        </div>

        { orderForm }
        <button
          type="button"
          className="form-control btn btn-axgen mb-0"
          disabled={disabledState}
          onClick={(e) => handleSubmit()}>
          {priceBtn}
        </button>
        
        <Alerts tag='buy-kit' message="Failed to submit order." />
      </form>
      <div className={classNames({ "d-none": success })}> 
          <img src="/assets/imgs/stripe.png" className='stripe-icon' alt=""/>
      </div>
    </div>
  );
}

export default StripeForm;
