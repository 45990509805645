import React, { useState, useEffect } from 'react';
import Alerts from './alerts';
import * as Actions from '../actions';
import { useCustomDispatch } from '../utils/customHooks';
import { formatAddress, validateAddressInfo, numKitsValidator } from '../utils/components';


// NOTE: only admins use this form
const BulkOrderForm = ({ alerts, width }) => {
    const [address, setAddress] = useState({
        "name": "",
        "street": "",
        "apt": "",
        "city": "",
        "state": "",
        "zip": "",
        "country": ""
      });
    const [disabled, disableSubmit] = useState(false);
    const [numKits, setNumKits] = useState("");

    const orderKitsCountDispatch = useCustomDispatch(Actions.orderKitsCountThunk);

    const updateNumKits = (val) => {
        // negative or too large numbers rejected
        if (parseInt(val) < 1 || parseInt(val) > 100) return;
        setNumKits(val);
    };

    // updates address state
    const _handleAddressChange = (e) => {
        let dupedState = Object.assign({}, address);
        dupedState[e.target.name] = e.target.value;
        setAddress(dupedState);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        Actions.clearAlerts();
        disableSubmit(true);
        const shippingInfo = formatAddress(address);
        if (validateAddressInfo(shippingInfo)) {
          if (numKitsValidator(numKits)) {
            orderKitsCountDispatch(shippingInfo, 'kit', parseInt(numKits));
          } else {
            Actions.addAlert('order-by-count', 'error', 'Invalid number of kits. Number of kits must be between 1 and 300.');
            return;
          };
        } else {
          Actions.addAlert('order-by-count', 'error', 'Incomplete shipping details. All fields with a * are required.');
          return;
        };
      };

    // sets the disabled submit to false if there are alerts
    useEffect(() => {
        if (Object.keys(alerts).length !== 0) {
            disableSubmit(false);
            const alertKey = 'order-by-count';
            const alertSuccess = (alerts[alertKey] || {});
            if (alertSuccess.hasOwnProperty('success')) {
                setNumKits("");
            };
        };
    }, [alerts]);
    
    const kitWord = parseInt(numKits) > 1 ? `${numKits} KITS` : numKits === "" ? "KIT(s)" : `${numKits} KIT`;
    const btnTxt = disabled ? `ORDERING ${kitWord}` : `ORDER ${kitWord}`;
    const addressFormFields = [
        { 'name': 'name', 'placeholder': 'full name*', 'className': 'form-control', 'colStyle': 'col-sm-12', 'required': true },
        { 'name': 'street', 'placeholder': 'street address*', 'className': 'form-control', 'colStyle': 'col-sm-8', 'required': true },
        { 'name': 'apt', 'placeholder': 'apt / unit', 'className': 'form-control', 'colStyle': 'col-sm-4', 'required': false },
        { 'name': 'city', 'placeholder': 'city*', 'className': 'form-control', 'colStyle': 'col-sm-6', 'required': true },
        { 'name': 'state', 'placeholder': 'state / province*', 'className': 'form-control', 'colStyle': 'col-sm-6', 'required': true },
        { 'name': 'zip', 'placeholder': 'zip / postal code*', 'className': `form-control ${ width < 768 ? "" : "mb-0"}`, 'colStyle': 'col-sm-6', 'required': true },
        { 'name': 'country', 'placeholder': 'country*', 'className': 'form-control mb-0', 'colStyle': 'col-sm-6', 'required': true }
    ];
    
    return (
        <form className="form">
            <div className="mb-2">
                <strong>Number of Kits</strong>
                <p className="mt-2">Please indicate how many kits codes you want to ship. The number must be between 1 and 100.</p>
            </div>
            <input
                type="number"
                className="form-control"
                placeholder="number of kits"
                onChange={(e) => updateNumKits(e.target.value)}
                min="1"
                max="100"
                value={numKits}
            />
            <hr />
            <div className="pb-4">
                <strong>Shipping Details</strong>
                <p className="mt-2">Please tell us where you would like us to ship your kit(s). Unfortunately we cannot ship outside of the United States.</p>
                <div className="row">
                    {
                        addressFormFields.map(field => (
                            <div className={field['colStyle']} key={field['name']}>
                            <input
                                type="text"
                                onChange={(e) => _handleAddressChange(e)}
                                name={field['name']}
                                value={address[field['name']]}
                                placeholder={field['placeholder']}
                                className={field['className']}
                                required={field['required']}
                            />
                            </div>
                        ))
                    }
                </div>
            </div>
            <button
                className="form-control btn btn-axgen mb-0" 
                type="button"
                disabled={numKits === "" || disabled}
                onClick={(e) => handleSubmit(e)}>
                <span>{btnTxt}</span>
            </button>
            <Alerts tag='order-by-count' level='error'/>
            <Alerts tag='order-by-count' level='success' />
        </form>
    );
};


export default BulkOrderForm;