import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
import { findBucket, getRiskText, validateDemographics } from '../../utils/results';
import BMDGraph from '../../components/bmd-graph';

const bmdChartConfig = require('../../../json/bmd-config.json');


// Variable Names and their Meaning:
// bogCounts <= {format: list, source: bmd-config, purpose: calculate bogPercentile}
// bogSeries <= {format: list, source: bmd-config, purpose: used with user BOG-score to find which histogram bin the user should be in}
// avgBOG <= {format: list, source: bmd-config, purpose: Previously used for BOGGraphs x-axis values, doesn't seem to be used here anymore}
// bogIndex <= {format: int, source: bmd-config + bogScore, purpose: Set histogram bin in BOGGraph component (only fracture comparison)}
// bmdIndex <= {source: bmd-config + bmdScore, purpose: Set histogram bin in BMDGraph component (both fracture and t-score graphs)}
// deltaRisk <= {format: float(-70 -> 85), source: relativeRisk, purpose: more user-readable format of relative risk}
  // relativeRisk <= {format: float (0.7 -> 1.85), source: bmd-config, purpose: indicator of user's BOG-fracture risk}
// percentileData <= {}
// result <= {calculated using server's geneticAlgorithm, purpose: equivalet to bmdScore}
// results <= {format: object, source: list of geneticAlgorith results, purpose:}
// bogScore <= {bmdScore modified with demographic factors, purpose: Used in BOG graphs.}
// bmdScore <= 1) server's geneticAlgorithm, purpose: It's one of the inputs for the BOG-score, and is used to calculate estimated_BMD.} 2) gets reset during bog calculation.
// estimated_BMD <= {calculated by using constants on the bmdScore, purpose: to calibrate with t score}. Displayed as the genetic score on the bmd page.

// Important Terms:
// BMD -- Genetic only (also known as T-Score (physical measurement))
// BOG - BMD modified by demographic factors.
// BOGGraph -- DEPRECATED Component managing two graphs, BOGvFractures and BOGvBMD/T-score
// BMDGraph -- Component managing two graphs, BMDvFractures and BMDvTScore


// function gets essential bmd information
const getBMDData = (bmdScore, bogScore) => {
  // setupData is the information used to create the buckets. It has the following fields:
  //  avgBMD is the average BMD Genetic Score of that bucket
  //  relativeFractureRisk is the percent increased/decreased risk compared to baseline for people in the buckets
  //  avgTScore is the average TScore of a person in that bucket
  //  binSize is the percentage of people in the bucket. Sum of all binSize == 100
  //  bogCounts and bogSeries are used to calculate bogPercentile
  const setupData = bmdChartConfig.setupData;
  const { avgBMD, relativeFractureRisk, binSize, bogCounts, bogSeries } = setupData;
  
  // bmd calculations
  let bmdIndex = findBucket(bmdScore, avgBMD); //bmdScore is 
  console.log(' bmdScore: ',  bmdScore)
  console.log(' bmdIndex: ',  bmdIndex)
  console.log('avgBMD :', avgBMD)
  const bmdRiskPercent = relativeFractureRisk[bmdIndex];
  // bmdPercentile will return the number of people in the same or worse bucket as the person.
  const bmdPercentile = binSize.reduce(function(total,size,index){
    if(index > bmdIndex) {
       return total;
    }
    return total + size;
  });

  // bog calculations
  let bogIndex = findBucket(bogScore, bogSeries);
  const percentileData = bogCounts.reduce((agg, count, i) => {
    return {
      'total': agg.total + count,
      'sub': agg.sub + ((i <= bogIndex) ? count : 0)
    }
  }, { 'total': 0, 'sub': 0 });
  const bogPercentile = `${(percentileData['sub'] * 100.0 / percentileData['total']).toPrecision(2)}%`;

  return [bmdPercentile, bmdRiskPercent, bmdIndex, bogPercentile];
};

// Finds risk value for a given bogScore
// Value is given in % increased risk. Negative values represent decreased risk. 
const getBOGRisk = (bogScore) => {
  const { bogLine, bogSeries } = bmdChartConfig.setupData;
  const bogIndex = findBucket(bogScore, bogSeries)
  const risk = bogLine.fractureRiskSeriesNormalized[bogIndex]
  const deltaRisk = ((risk - 1) * 100).toFixed(1);
  return deltaRisk;
};


class BMDResults extends React.Component {
  render() {
    // NOTE: A lot of .toFixed() calculations are in this file. We may want to think about sig-figs and what the best number to round to for consistency is.
    const { result, demographics, userIsMe, width } = this.props;
    const bmdScore = parseFloat(result.value);
    const estimated_BMD = result['estScore'];
    console.log('estimated_BMD :', estimated_BMD)
    let bogScore = bmdScore;
    const hasDemographics = validateDemographics(demographics);

    // Demographic information is set to default to unknown because it is rendered in the text. This makes the page more readable if that info is missing.
    let height = 'unknown';
    let weight = 'unknown';
    let age = 'unknown';
    let gender = 'unknown';

    if (hasDemographics) {
      height = demographics.height;
      weight = demographics.weight;
      age = parseInt(new Date().toISOString().slice(0, 4), 10) - demographics.birthYear; // Calculate age from difference between birth year and current year.
      gender = demographics.gender;

      // Demographic modifications
      bogScore = 0.5212011 * ((gender === 'male') ? 1 : 0)
        - 0.0184450 * age
        - 0.023320248 * height
        + 0.00299053805 * weight
        + 2.0116681 * bmdScore
        + 1.0350451;
    };
    
    const [ bmdPercentile, bmdRiskPercent, bmdIndex, bogPercentile ] = getBMDData(estimated_BMD, bogScore);

    return (
      <div>
        <div className="title-margins">
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Stress Fracture / Bone Mineral Density Results</h2>
        </div>
        <div className="card p-1">
          <Collapsible trigger="Tutorial">
            <p className="intro-vid"><iframe title="Results" width="560" height="315" src="https://www.youtube.com/embed/fvfdVam-k-0?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
          </Collapsible>
        </div>
        <div className="card">
          <p>Low bone mineral density is the largest risk factor for bone fractures, and stress fractures in particular. A genetic algorithm informs about your bone mineral density and hence your risk for stress fractures/bone fractures (<a href="https://www.ncbi.nlm.nih.gov/pubmed/30048462">Kim, 2018</a>). BMD levels are measured in T score units, where a score of -1.0 defines osteopenia and a score of -2.5 indicates osteoporosis. </p>
          <p>Your genetic score is <strong>{estimated_BMD.toFixed(3)}</strong>, which is an estimate of your BMD in T score units.  </p>
          <p>A genetic score of <strong>{estimated_BMD.toFixed(3)}</strong> indicates a <strong>{getRiskText(bmdRiskPercent)}</strong> risk for fracture.</p>
          <p className="mb-0"><strong>{bmdPercentile}%</strong> of people have a score equal or lower than you.</p>
        </div>
        <div className="card">
          <p>To use these graphs, find the circle that shows your genetic score. The histogram shows the number of people with their genetic scores. The bin containing your score tells you how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have a greater risk for fracture or low bone mineral density. </p>
          <p>The line shows the risk for each bin. The solid line shows your risk and the dotted line is the average.</p>
          <BMDGraph graphType="fracture" bmdIndex={bmdIndex} width={width}/>
          <p className="graph-comment" align="center">**Hover over the graph for more information.**</p>
          </div>
        <div className="card">
          <BMDGraph graphType="tscore" bmdIndex={bmdIndex} width={width}/>
          <p className="graph-comment" align="center">**Hover over the graph for more information.**</p>
          </div>
          {
            hasDemographics ? (
            <div className="card">
              <p>In addition to genetic factors, your height, weight, age, and sex can also be used to calculate your estimated BMD and your risk of fracture. For this calculation, short is a little better than tall, young is much better than old, heavy is better than light, and male is better than female.</p>
              <p>According the demographic information you provided us, your height is {height} inches, your weight is {weight} pounds, your age is {age} years, and your sex is {gender}.</p>
              <p>Adding these factors with your genetics creates a combined score of {bogScore.toFixed(2)}, which is an estimate of your bone mineral density (in T score units) and is associated with a {getRiskText(getBOGRisk(bogScore))} risk of fracture.</p>
              <p>{bogPercentile} of people have a score equal or lower than you.</p>
              <p>Assuming your weight does not change, we can calculate your combined score as you grow older: </p>
              {/* Shows changing BOG Score in 10 / 20 / 30 / 40 years */}
              
              <table className="default-table">
                <caption>Changing Combined Score and Fracture Risk over Time</caption>
                <thead>
                  <tr>
                    <th>Year</th>
                    <th title="BOG">Combined Score</th>
                    <th>Fracture Risk</th>
                  </tr>
                </thead>
                <tbody>
                <tr><td>{parseInt(new Date().toISOString().slice(0, 4), 10)}</td><td>{(bogScore).toFixed(2)}</td><td>{getRiskText(getBOGRisk(bogScore))}</td></tr>
                <tr><td>{parseInt(new Date().toISOString().slice(0, 4), 10) + 10}</td><td>{(bogScore - .18).toFixed(2)}</td><td>{getRiskText(getBOGRisk(bogScore - .18))}</td></tr>
                <tr><td>{parseInt(new Date().toISOString().slice(0, 4), 10) + 20}</td><td>{(bogScore - .36).toFixed(2)}</td><td>{getRiskText(getBOGRisk(bogScore - .36))}</td></tr>
                <tr><td>{parseInt(new Date().toISOString().slice(0, 4), 10) + 30}</td><td>{(bogScore - .54).toFixed(2)}</td><td>{getRiskText(getBOGRisk(bogScore - .54))}</td></tr>
                <tr><td>{parseInt(new Date().toISOString().slice(0, 4), 10) + 40}</td><td>{(bogScore - .72).toFixed(2)}</td><td>{getRiskText(getBOGRisk(bogScore - .72))}</td></tr>
                </tbody>
              </table>
            </div>
          ) : ( 
            <div>
            {
              userIsMe ? (
              <div className="card">
                <p>In addition to genetic factors, your height, weight, age, and sex can also be used to calculate your estimated BMD and your risk of fracture. For this calculation, short is a little better than tall, young is much better than old, heavy is better than light, and male is better than female.</p>
                <p className="mb-0">We invite you to share your demographic information to discover a more comprehensive analysis of your fracture risk.<br/><Link to="/settings">You can click here to input your demographic data</Link>.</p>
              </div>) : (
              <div className="card">
                <p>In addition to genetic factors, your height, weight, age, and sex can also be used to calculate your estimated BMD and your risk of fracture. For this calculation, short is a little better than tall, young is much better than old, heavy is better than light, and male is better than female.</p>
                <p className="mb-0">The person whose profile you are viewing has not input their demographic information. Please ask them to do so in their Account Settings in order to view a more comprehensive analysis of fracture risk.</p>
              </div>
              )
            }
            </div>)
          }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    results: state.results,
  };
};

export default connect(mapStateToProps)(BMDResults);

