import React from 'react';

class MgRecommendation extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Magnesium Recommendations</h2>
          <p>written by Dr. Megan Roche, M.D.</p>
        </div>
        
        <div className="card">
              <ul style={{listStyleType: 'square'}}>
                  <li>Consider requesting magnesium levels from your primary care doctor if you are concerned about deficiency.</li>
                  <li>Review foods high in magnesium.</li>
                  <li>If you are a vegan, review ways to reduce phytic acid concentration in plant foods.</li>
                  <li>If you have a magnesium deficiency, consult with a primary care doctor to discuss the possibility of supplementation.</li>
              </ul>
        </div>
        <div className="card">
          <p>Magnesium is a mineral found in many foods. However, some athletes don’t get enough magnesium in their diets as a result of eating a high quantity of processed foods. Foods that are high in magnesium include whole wheat grains, spinach, quinoa, almonds, peanuts, dark chocolate, black beans, and avocado. Magnesium is also added to fortified foods and some breakfast cereals. The recommended dietary allowance (RDA) for magnesium is 310-420 mcg, which varies based on age and gender.</p>
        </div>
        <div className="card">
          <p>Caffeine and alcohol can limit magnesium absorption. Further, phytic acid, a storage form of phosphorus, can bind (chelate) magnesium in the gastrointestinal (GI) tract and reduce magnesium absorption. Phytic acid is found in many plant foods including whole grains, legumes, nuts, and seeds. In most circumstances, a diverse diet counters the impact of phytic acids. Athletes who have vegan diets may want to consider cooking, fermenting, or soaking some of their plant foods to reduce phytic acid and prevent chelation of magnesium and other minerals in the GI tract.</p>
          </div>
          <div className="card">
          <p>Magnesium is also available as a dietary supplement in a variety of forms including magnesium oxide, citrate, chloride, and sulfate. Magnesium is also a primary ingredient in laxatives including Milk of Magnesia, though it is not fully absorbed due to the laxative effect. </p>
          </div>
          <div className="card">
          <p>It’s important to discuss magnesium supplementation with your primary care doctor prior to starting supplementation. Magnesium supplementation may be unsafe for individuals taking certain medications including diuretics or antibiotics. Side effects of magnesium supplementation can include gastrointestinal issues such as nausea and diarrhea.</p>
        </div>
      </div>
    )
  }
}

export default MgRecommendation;
