import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';

export const VitDResults = (props) => {
    const { crm, result } = props;
    let testComponent;
    if (result['value'] !== null) {    
        let prefixedGeneticScore = result['value'] > 0 ? `+${result['value'].toFixed(2)}` : `${result['value'].toFixed(2)}`;
        
      testComponent =
      <div>
        <div className="card">
        <p>The AxGen test for vitamin D uses a genetic algorithm with 7874 genetic markers developed by <a href="https://pubmed.ncbi.nlm.nih.gov/33462484/">Sinnott-Armstrong et al., 2021</a>. Other genetic testing companies currently use only one or two genetic markers. AxGen's test includes these top two markers but adds thousands more, which significantly improves the predictive power of the test. </p>
        </div>
        <div className="card">
          <BetaHistogram crm={crm} result={result} crmKey='results-vitD-histogram' />
          <p>To use this graph, find the red line that shows your genetic score.  The blue histogram shows the number of people and their vitamin D genetic scores. The bin containing your score tells you how many other people have a score like yours.  If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly lower level of vitamin D.  </p>
          <p>The X axis shows values in nmol/L.  Vitamin D is also measured in ng/ml where 1 nmol/L is 2.6 ng/ml.  </p>
          <p>Based solely on your genetics, your predicted vitamin D score is <strong>{prefixedGeneticScore}</strong> nmol/L, which is the amount that your individual level is shifted from to the median value (46.8 nmol/L).</p> 
          <p><TagPercentile result={props.result} tag='vitD'/></p>
        </div>
        <div className='title-break'>
          <span>The genetic test is <b>moderately</b> informative for vitamin D</span>
        </div>
        <div className="card">
        <p>First, the overall heritability of vitamin D was measured at 8% [<a href="https://pubmed.ncbi.nlm.nih.gov/33462484/">Sinnott-Armstrong et al., 2021</a>]. This means that genetics influences your vitamin D levels, but it is mostly set by your diet and activity.  </p>
        <p>Second, the correlation between the genetic test and vitamin D levels is about 22% (100% means it is exact and 0% means it provides no information). The genetic test can show tendencies in your vitamin D level, but it is not exact.</p>
        <p>Third, the left edge of the blue histogram has vitamin D scores around -0.4 nmol/L (relative to the average level of vitamin D, which is about 46 nmol/L). Vitamin D levels range between 30 - 70 nmol/L (11 - 27 ng/ml), so a change of -0.4 nmol/L does not change your vitamin D levels by much.  </p>
        </div>
        <div className="card">
        <p>The AxGen test for vitamin D does not apply to non-European ethnicities, such as Asians or Africans. First, sunlight activates vitamin D and the melanin in races with darker skin interferes with this activation. Second, the genetic algorithm had weaker performance at predicting vitamin D levels in people with either Asian or African ancestry [<a href="https://pubmed.ncbi.nlm.nih.gov/33462484/">Sinnott-Armstrong et al., 2021</a>]. </p>
        </div>
      </div>

      return (
      <div>
        <div className='title-margins'>
        <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Vitamin D Levels</h2>
        <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/33462484/">Sinnott-Armstrong et al., 2021</a>]</h5>
        </div>
        { testComponent }

      </div>
    );
    };
};


export default VitDResults;
