import React from 'react';
import GeneTable from '../../components/gene-table';

class VitB6Results extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Vitamin B6</h2>
          <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/19303062/">Tanaka et al., 2009</a>]</h5>
        </div>
        <div className="card">
          <p>The average level of vitamin B6 is 7.5 (+/- 7.8) ng/ml.</p>
          <p>The <b>score</b> column shows how your genotype affects your levels of vitamin B6.</p>

          <GeneTable tag='vitB6' cols={[ 'gene', 'beta', 'running-total'] } />
        </div>
        <div className='title-break'>
          <span>The genetic test has a <b>moderate</b> effect on vitamin B6</span>
        </div>
        <div className="card">
            <p>Each C allele of rs4654748 has a beneficial effect that is associated with an increase in vitamin B6 of 1.45 ng/ml. Genetic variation at rs4654748 has the potential to shift vitamin B6 levels by 2.9 ng/ml (C/C vs T/T), which is significant compared to the total level of vitamin B6 (~7.5 ng/ml).  </p>
        </div>
         </div>
    )
  }
}

export default VitB6Results;
