import React from 'react';

class PlantarIntro extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Introduction to Plantar Fasciitis</h2>
          <h6>written by Dr. Megan Roche, M.D.</h6>
        </div>

        <div className='title-break'>
          <span>High Risk Sports</span>
        </div>
        <div className='high-risk-list my-3 py-1'>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/runner.png'/>
            <span>Endurance Running</span>
          </div>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/soccer.png' />
            <span>Soccer</span>
          </div>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/basketball.png' />
            <span>Basketball</span>
          </div>
        </div>

        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img src="/assets/imgs/results/content/plantarAthlete.jpg" height={300} className="overview-img" alt="" />
          <p>Plantar fasciopathy refers to acute inflammation and chronic degeneration of the plantar fascia, which attaches at the tuberosity of the calcaneus and fans out into five slips that attach to the metatarsal heads. The plantar fascia becomes tight in late stance phase and is responsible for elevating the longitudinal arch through the windlass mechanism. Overall, the windlass mechanism contributes to medial longitudinal arch integrity and the foot stiffness required to provide forward propulsion in the gait cycle (<a href="https://www.ncbi.nlm.nih.gov/pubmed/16558682">Bolgla and Malone, 2004</a>).</p>
          <p>The clinical course of plantar fasciopathy consists of acute, subacute, and chronic phases. The acute phase involves a period of initial inflammation of the plantar origin with morning heel pain. The acute phase evolves into the subacute phase after week four with pain that progressively increases with activity and can occur at rest. Finally, the chronic phase occurs at three months and involves degenerative changes of the plantar fascia and the development of tension enthesophytes at the calcaneal insertion (<a href="https://www.ncbi.nlm.nih.gov/pubmed/24365781">Berbrayer and Fredericson, 2014</a>). Enthesophytes are abnormal bony projections that can occur at the insertion points of tendons and ligaments. </p>
        </div>

        <div className='title-break'>
          <span>Frequency</span>
        </div>
        <div className="card">
          <p>Plantar fasciopathy occurs in approximately 10% of the general population and presents bilaterally in one third of cases. In the general population, the peak incidence occurs between 40 and 60 years of age and is the most common foot condition treated in physical therapy clinics. Runners have the highest incidence of plantar fasciopathy, with prevalence reported to be as high as 22% (<a href="https://www.ncbi.nlm.nih.gov/books/NBK431073/">Buchanan and Kushner, 2019</a>). The average duration of plantar fasciopathy is longer than 6 months, but 90% of cases respond well with conservative treatment (<a href="https://www.ncbi.nlm.nih.gov/pubmed/12917892">Crawford and Thomson, 2003</a>).</p>
        </div>

        <div className='title-break'>
          <span>Causes</span>
        </div>
        <div className="card">
          <p>Potential risk factors for plantar fasciopathy include obesity, prolonged weight-bearing, limited ankle dorsiflexion, flat (pes planus) or high-arched (pes cavus) feet, and abnormalities in biomechanics or functional movements. A rapid increase in training can increase risk for plantar fasciopathy. Tight gastrocnemius and soleus muscles can impact normal biomechanics and predispose to plantar fasciopathy. Plantar fasciopathy is often due to multifactorial risk factors and biomechanics, but in rare circumstances is associated with systemic autoimmune disease.</p>
        </div>
      </div>
    )
  }
}

export default PlantarIntro;
