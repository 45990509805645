import React from 'react';
import RiskGraph from '../../components/risk-graph';
import GeneTable from '../../components/gene-table';

const ConcussionResults = (props) => {
    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Concussion</h2>
                <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/33017352/">Kim et al., 2021</a>]</h5>
            </div>
            <div className="card">
                <RiskGraph tag='concussion' />
                <p className="mb-0">The bar graph shows your risk of concussion compared to the risk for an average person (1.00). Your risk is calculated based on your genotypes at the two DNA polymorphisms shown in the table below.</p>
            </div>
            <div className="card">
                <p>Concussion is a serious injury for athletes that participate in collision sports such as football, soccer, hockey, rugby, etc. The table shows two DNA polymorphisms that affect your risk for concussion. The meanings are shown by hovering your mouse over each column heading.</p>
                <p>rs144663795 is in the Spermatogenesis Associated 5 gene (SPATA5). Strong, deleterious missense mutations in SPATA5 cause severe intellectual disability, hearing loss, vision impairment and slow and asymmetric waves in the EEG (<a href="https://www.ncbi.nlm.nih.gov/pubmed/26299366">Tanaka et al., 2015</a>). SPATA5 function may be weakly affected by rs144663795 with no overt phenotype except for an increased risk of concussion, and pathologic mutations in SPATA5 may directly cause a strong neurological phenotype.</p>
                <p>rs117985931 is located in PLXNA4, which encodes a plexin that steers axon growth (<a href="https://www.ncbi.nlm.nih.gov/pubmed/23104057">Janssen et al., 2012</a>). DNA variants in PLXNA4 are also associated with Alzheimer's disease (<a href="https://www.ncbi.nlm.nih.gov/pubmed/25043464">Jun et al., 2014</a>). Thus, plexins have important roles in neural development, neural injury, Alzheimer’s disease and concussion.</p>
                <GeneTable tag='concussion' cols={['relativeRisk', 'gene'] } />
            </div>
        </div>
    );
};

export default ConcussionResults;