import React from 'react';


export const ACLIntro = () => {
  return (
    <div>
      <div className="title-margins">
        <h2>Introduction to ACL and PCL Injury</h2>
        <p>written by Dr. Megan Roche, M.D.</p>
      </div>
      <div className='title-break'>
          <span>High Risk Sports</span>
      </div>
      <div className='high-risk-list my-3 py-1'>
        <div className="result-sport-figure mb-2">
          <img src='/assets/imgs/results/athletes/soccer.png' alt=""/>
          <span>Soccer</span>
        </div>
        <div className="result-sport-figure">
          <img src='/assets/imgs/results/athletes/quarterback.png' alt="" />
          <span>Football</span>
        </div>
        <div className="result-sport-figure">
          <img src='/assets/imgs/results/athletes/basketball.png' alt="" />
          <span>Basketball</span>
        </div>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
      <img src="/assets/imgs/results/content/lacrosse.jpeg" alt="" height={250} className="overview-img" />
        <p className="mb-0">The anterior cruciate ligament (ACL) and posterior cruciate ligament (PCL) are key structures within the knee that provide stability to the knee joint. The ACL resists abnormal forward movement of the tibia relative to the femur and provides rotational stability. The PCL resists abnormal backward movement of the tibia relative to the femur. Injuries to the ACL and PCL range from mild injury with ligament stretching to partial or complete tearing of the ligament.</p>
      </div>
      <div className='title-break'>
        <span>Causes</span>
      </div>
      <div className="card">
        <p>The ACL is the most frequently injured ligament in the knee joint (<a href="https://www.ncbi.nlm.nih.gov/books/NBK499848/">Evans & Nielsen, 2020</a>). Injuries to the ACL often occur with movements that suddenly rotate or twist the knee including cutting, pivoting, and decelerating. Skiers and football players can sustain ACL injury via contact forces that cause the tibia to shift forward in relation to the femur. Female athletes are at higher risk for ACL injury, which is thought to be attributed to hormonal factors, anatomical influences, and muscular strength (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3920910/">Renstrom et al., 2008</a>). ACL injuries are also impacted by footwear and playing surface, and factors including the length and shape of cleats has been studied for ACL prevention in field athletes (<a href="https://link.springer.com/chapter/10.1007/978-3-642-32592-2_4#:~:text=Footwear%20has%20a%20significant%20effect,with%20higher%20ACL%20injury%20rates.">Dowling & Andriacchi, 2012</a>).</p>
        <p className="mb-0">Although PCL injuries are more rare than ACL injuries, they can be serious for athletes. Injuries to the PCL often occur when the tibia is hit from an outside force with the leg in a bent position. Compared to ACL injuries, PCL injuries are more likely to be partial tears with the potential for non-surgical management. Isolated PCL injuries are uncommon and they are usually associated with multi-ligament injury (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5420825/">Vaquero-Picado & Rodriguez-Merchan, 2017</a>).</p>
      </div>
      <div className='title-break'>
        <span>Frequency</span>
      </div>
      <div className="card">
        <p>Athletes that participate in football, lacrosse, soccer, hockey, and rugby are at higher risk for ACL injury. Research from the NCAA indicates that football players sustain the greatest number of ACL injuries out of all of the collegiate sports and that female soccer and basketball players have high incidence for ACL injuries, with incidence ratios of 3.5 and 2.7 respectively (<a href="https://www.semanticscholar.org/paper/A-meta-analysis-of-the-incidence-of-anterior-tears-Prodromos-Han/fd799f8b7c6a5f4ba7b5c0e4ebf93b141aac60a2">Prodromos et al., 2007</a>). The overall incidence of ACL injuries is equal across male and female NCAA sports; however, male athletes experience a disproportionate number of contact-related ACL injuries (<a href="https://www.uptodate.com/contents/anterior-cruciate-ligament-injury">UptoDate, 2020</a>). A prospective cohort study of adolescent alpine skiers found a incidence rate of 0.5 ACL injuries per 100 months per skier and a prevalence of 9.8% (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5954346/">Westin et al., 2018</a>).</p>
        <p className="mb-0">Athletes that participate in football, soccer, and rugby are at higher risk for PCL injury. Asymptomatic, non-traumatic PCL injuries are common. A study conducted in the NFL found that 2% of players presenting for a physical exam prior to the draft had an asymptomatic PCL injury (<a href="https://www.uptodate.com/contents/posterior-cruciate-ligament-injury?search=pcl%20injury&source=search_result&selectedTitle=1~20&usage_type=default&display_rank=1">UptoDate, 2020</a>).</p>
      </div>
    </div>
  );
}

export default ACLIntro;
