import React from 'react';

class DQTIntro extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Introduction to De Quervain's Tenosynovitis</h2>
          <h6>written by Dr. Megan Roche, M.D.</h6>
        </div>

        <div className='title-break'>
          <span>High Risk Sports</span>
        </div>
        <div className='high-risk-list my-3 py-1'>
          <div className="mb-2 result-sport-figure">
            <img alt="" src='/assets/imgs/results/athletes/golfer-man.png'/>
            <span>Golf</span>
          </div>
          <div className="result-sport-figure mb-2">
            <img alt="" src='/assets/imgs/results/athletes/tennis.png' />
            <span>Tennis</span>
          </div>
        </div>

        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" src="/assets/imgs/results/content/dqtAthlete.jpg" height={200} className="overview-img" />
          <p>De Quervain’s tenosynovitis is a repetitive strain injury of the thumb tendons seen in both the general and athletic populations. Repetitive movement of the thumb and/or wrist can lead to inflammation, which manifests as pain and swelling at the base of the thumb and the radial side of the wrist (<a href="https://www.ncbi.nlm.nih.gov/pubmed/15978321">de Quervain, 1912</a>). Symptoms are triggered by activities such as grasping, gripping and movement of the thumb and wrist (<a href="https://www.ncbi.nlm.nih.gov/pubmed/9343764">Moore, 1997</a>). Consequently, de Quervain’s tenosynovitis is most commonly seen in athletes that participate in racket sports and golf.</p>
        </div>

        <div className='title-break'>
          <span>Frequency</span>
        </div>
        <div className="card">
          <p>In the general population, the prevalence of de Quervain’s tenosynovitis is estimated to be 0.6% in men and 2.1% in women (<a href="https://www.ncbi.nlm.nih.gov/pubmed/21431276">Petit Le Manac’h et al., 2011</a>). It is more common in women than men, in middle age (30-50 years old) and in African Americans compared to Caucasians (<a href="https://www.ncbi.nlm.nih.gov/pubmed/19081683">Wolf et al., 2009</a>). For women, de Quervain’s is common during and after pregnancy.</p>
          <p>For athletes, hand and wrist injuries account for 3-9% of all sports injuries and de Quervain’s tenosynovitis is the most common radial-sided tendinopathy in athletes (<a href="https://www.ncbi.nlm.nih.gov/pubmed/14623677">Rettig, 2003</a>). This injury occurs more frequently in athletes participating in racket sports (such as tennis), golf, rowing, volleyball, weightlifting and bowling.</p>
          </div>

        <div className='title-break'>
          <span>Causes</span>
        </div>
        <div className="card">
          <p>As an overuse injury, de Quervain’s is caused by repetitive movements of the thumb and/or wrist. The abductor pollicis longus and extensor pollicis brevis are the two thumb tendons that connect the forearm and thumb. They glide smoothly through a fibro-osseous tunnel within the first extensor compartment of the wrist. However, repeated gliding (from repetitive motion of the thumb and wrist) can lead to frictional microtrauma and inflammation of the tendon sheath (<a href="https://www.ncbi.nlm.nih.gov/pubmed/26510626">Adams and Habbu, 2016</a>). Additionally, non-inflammatory thickening of the tendons, consistent with chronic degeneration, is seen. Chronic degeneration restricts movement of the tendons in their sheath and causes the symptoms of pain and swelling (<a href="https://www.ncbi.nlm.nih.gov/pubmed/18063716">Ilyas et al., 2007</a>). Other causes not related to overuse include direct injuries to the thumb and wrist. Biological and anatomical risk factors include 1) a history of medial or lateral epicondylitis, 2) increased compartment septation and 3) presence of multiple tendon slips (<a href="https://www.ncbi.nlm.nih.gov/pubmed/27693273">Lee et al., 2017</a>).</p>
          <p>Athletes in sports that require repetitive hand or wrist movements that involve gripping or twisting are at higher risk for de Quervain’s tenosynovitis. There are many sports that involve these movements. For instance, de Quervain’s tenosynovitis has been attributed to grasp and swing technique in racket sports (e.g. tennis) and golf (<a href="https://www.ncbi.nlm.nih.gov/pubmed/19270188">Tagliafico et al., 2009</a>; 
  <a href="https://www.ncbi.nlm.nih.gov/pubmed/27886842"> Woo et al., 2017</a>). In rowing and weightlifting, tight grip, significant forces exerted on the thumb and wrist, and poor technique play a role in causing De Quervain's tenosynovitis (<a href="https://www.ncbi.nlm.nih.gov/pubmed/15974636">Rumball et al., 2005</a>). In volleyball, the repetitive microtrauma from impact of the ball on the dorsal radial wrist region increases the risk for de Quervain’s tenosynovitis (<a href="https://www.ncbi.nlm.nih.gov/pubmed/15716259">Rossi et al., 2005</a>).</p>
        </div>
      </div>
    )
  }
}

export default DQTIntro;
