import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';


export const IBDCrohn = ({ userName, nameEndsWithS, uploadID, width }) => {
  // this page contains the results of multiple tests
  const { crm, uploads } = useSelector((state) => ({
    crm: state.crm,
    uploads: state.results.uploads,
  }));

  const getGraph = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    const crmKey = `results-${tag}-histogram`;
    // HRex has crmkey but no results.
    return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
  };

  const getPercentile = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    return <TagPercentile result={tagResult} tag={tag} />;
  };

  return (
    <div>
      <div className='title-margins'>
        <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Crohn's Disease</h2>
      </div>
      <div className="card">
        <p>The AxGen test for Crohn's disease uses a polygenic risk score with 236 genetic markers from <a href="https://pubmed.ncbi.nlm.nih.gov/35324888/">Tanigawa et al., 2022</a>. </p>
      </div>
      <div className="card">
        {getGraph("crohn")}
        <p>The x-axis shows your risk for Crohn's disease. The important information from the histogram is the <b>percentile</b> of your genetic score compared to others (the genetic score is in artibrary units). To use this graph, hover your mouse over the red line and look up your percentile. The blue histogram shows the number of people and their genetic score for risk for Crohn's disease. The bin containing your score tells you how many other people have a score like yours. <b>If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly higher risk for Crohn's disease.</b>  </p>
        <p className="mb-0">{getPercentile("crohn")}</p>
        <br></br>
        <img src="/assets/imgs/results/Crohn.png" height={400} className="overview-img" alt="" />
        <p>The Figure shows how your genetic score affects your risk for Crohn's disease. For instance, if your percentile is below 10%, your risk for Crohn's disease is 1.75-fold higher than the average person. If the prevalence of Crohn's disease for the entire US is about 0.3%, then a genetic score below 10% indicates that you have a 0.52% chance for Crohn's (1.75 fold x 0.3% = 0.52%). A 1.75-fold increased risk for Crohn's disease is significant, but your chance of getting Crohn's is still low because the disease is rare.</p>
      </div>
      <div className='title-break'>
        <span>The genetic test has a <b>moderate</b> effect on your risk for Crohn's disease.</span>
      </div>
      <div className="card">
        <p>The impact of the Crohn's genetic score on an entire population is small because only 10% of people are affected. However, for the subset of people with a low score, the test is significant because it indicates a 1.75-fold increased risk for the disease. </p>
      </div>
    </div>
  );
};

export default IBDCrohn;
