import React from 'react';
import RiskGraph from '../../components/risk-graph';
import GeneTable from '../../components/gene-table';

const ACLResults = (props) => {
    let stringifiedText = "(p < 0.05)";
    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for ACL and PCL Injury</h2>
                <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/34169730/">Kim et al., 2021</a>]</h5>
            </div>
            <div className="card">
                <RiskGraph tag='anterior-cruciate-ligament' />
                <p className="mb-0">The bar graph shows your risk of ACL or PCL injury compared to the risk for an average person (1.00). Your risk is calculated based on your genotypes at the two DNA polymorphisms shown in the table below.</p>
            </div>
            <div className="card">
                <p>A recent study identified two DNA polymorphisms from a screen of the entire genome involving 2,214 cases of ACL and PCL injury and 519,869 controls (Table). Athletes harboring risk alleles for these DNA polymorphisms have an increased risk for ACL and PCL injury. Although the risk alleles are rare (0.1% - 0.4% allele frequency), they each confer an increased risk for ACL and PCL injury of between 2.5- to 4-fold. Table definitions can be shown by hovering your mouse over the column headings.</p>
                <GeneTable tag='anterior-cruciate-ligament' cols={['relativeRisk', 'gene'] } />
            </div>
            <div className="card">
                <p><b>Why doesn’t AxGen test for variants in collagen genes (or other candidate genes)?</b></p>
                <p className="mb-0">Other genetic testing companies report on associations of candidate genes with ACL injury. The candidate genes were identified in studies that involved a small cohort of athletes (52 to 184 athletes) and used a lenient threshold for statistical significance {stringifiedText}. When the candidate genes were tested in a large cohort with 2,214 cases of ACL and PCL injury, only one of the six genes (collagen III A1) showed an association. Compared to the two SNPs listed in the table, the polymorphism in the collagen III A1 gene is much weaker; the p-value for the collagen III A1 is about one million times weaker than those for any of the two SNPs in the table. Since the candidate gene associations are either not reproducible or not strong, AxGen has decided to refrain from using them.</p>
            </div>
        </div>
    );
};

export default ACLResults;