import React from 'react';

const Mars_arthIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Rheumatoid Arthritis</h2>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/RhArth_fig.png" height={300}/> 
        <p>Rheumatoid arthritis (RA) causes chronic inflammation, when the immune system attacks healthy tissues surrounding the joints, causing inflammation. RA most often affects small joints in the hands, wrist and feet, although larger joints (such as the shoulders, hips, and knees) may become involved later in the disease. Joints are typically affected in a symmetrical pattern; for example, if one hand is affected, the other hand also tends to be involved.</p>
        <p>The most common symptoms are pain, swelling, and stiffness of the joints. Over time, rheumatoid arthritis leads to limited mobility and loss of joint function. Joint pain and stiffness is often worse when getting out of bed in the morning or after a long rest. Rheumatoid arthritis affects about 1.3 million adults in the United States.</p>
      </div>
      <div className='title-break'>
        <span>Symptoms</span>
      </div>
      <div className="card">
        <ul>
          <li>Age: The signs and symptoms of rheumatoid arthritis usually appear in mid to late adulthood. Risk for RA increases with age.</li>
          <li>Gender: RA affects women 2-3 times as much as much as men.</li>
          <li>Smoking: Smoking significantly increases the risk of RA, especially for those with the genetic marker HLA-DR4.</li>
          <li>Obesity: Obesity increases both the risk and severity of symptoms of rheumatoid arthritis.</li>
          </ul>
      </div>
      <div className='title-break'>
        <span>Genetics</span>
      </div>
      <div className="card">
        <p>The heritability of RA is about 60%, of which 11–37% is from the genetic marker HLA-DR4. The results page shows a polygenic risk score for rheumatoid arthritis that is fairly informative; i.e. about 3.4 fold increased risk for people in the 97th percentile. Genetic testing empowers a person to consult a rheumatologist in order to take actions to help prevent or slow the progression of the disease. For instance, regular exercise, stress management and weight loss can help reduce RA symptoms and pain. Medications can also slow the disease and prevent joint deformity.</p>
      </div>
    </div>
  )
}

export default Mars_arthIntro;
