import React from 'react';
import Chart from 'react-apexcharts';

const RiskFactorGraph = ({graphMax, data, width}) => {

    const xAxisTxt = 'Variance Explained';
    graphMax = graphMax; 
    const formatter = (val) => val.toFixed(3) ; // the formatter for the data
    const tickAmt = width < 768 ? 4 : 10;
    const options = {
        chart: {
            type: 'bar',
            height: 350,
            width: width < 400 ? 100 : 600,
            horizontalAlign: 'left',
            toolbar: {
                offsetX: width < 400 ? -30 : 600, // Adjust offsetX for padding
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        xaxis: {
            categories: ['Genetics', 'Risk Factors', 'Combined'],
            min: 0.0,
            max: graphMax,
            tickAmount: tickAmt,
            title: {
                text: xAxisTxt,
                offsetY: 0,  //Need to modify for mobile Stuart
                style: {
                    cssClass: 'apexcharts-axis-titles'
                }
            },
            labels: {
                style: {
                    cssClass: 'apexcharts-axis-labels'
                },
                rotate: -25,
                // rotateAlways: width false, //mobile only rotation to prevent tick overlap
                formatter: formatter
            }
        },
        yaxis: {
            labels: {
                style: {
                    cssClass: 'apexcharts-axis-labels'
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: formatter,
            textAnchor: "middle",
            style: {
                colors: ["#ffffff"],
                cssClass: 'apexcharts-axis-labels'
            }
        },
        tooltip: {
            enabled: false
        }
    };

    const series = [
        {
            name: 'Count',
            data: data,
        },
    ];

    return (
        <div>
            <Chart options={options} series={series} type="bar" height={200} />
        </div>
    );
};



export default RiskFactorGraph;


