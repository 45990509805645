
import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';

export const StrengthResults = (props) => {
    const { crm, result } = props;
    let testComponent;
    if (result['value'] !== null) {    
        let prefixedGeneticScore = result['value'] > 0 ? `+${result['value'].toFixed(2)}` : `${result['value'].toFixed(2)}`;
        
      testComponent =
      <div>
        <div className="card">
        <p>The test for muscle strength uses a genetic algorithm with 139 genetic markers developed by <a href="https://pubmed.ncbi.nlm.nih.gov/29691431/">(Tikkanen et al., 2018)</a>.   </p>
        </div>
        <div className="card">
        <BetaHistogram crm={crm} result={result} crmKey='results-strength-histogram' />
          <p>To use this graph, find the red line that shows your genetic score.  The blue histogram shows the number of people and their muscle strength genetic scores. The bin containing your score tells you how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have slightly lower muscle strength.   </p>
          <p><TagPercentile result={props.result} tag='strength'/></p>

        </div>
        <div className="card">
        <p>The 139 genes in the muscule strength genetic risk score are the top genes in the genome. Although ACTN3 is widely popular in genetic tests for muscle strength (e.g. sprinter vs endurance athlete), it was not among the top 139 genes for muscle strength. That is, the effect of your ACTN3 genotype is smaller than for any of the 139 genes in the AxGen test. The effect of the muscle strength score is much stronger than that of ACTN3, because the muscle strength score reports the combined effect from all 139 genes.  </p>
        <p>Overall, the 139 genes that comprise the muscle strength genetic risk score are enriched for both neuronal as well as muscular genes. It is well known that muscular strength depends not only on the quantity of the involved muscles, but also on the ability of the nervous system to appropriately recruit muscle cells.  </p>  
        <p>The <i>ATP2A1</i> locus has the strongest association with muscle strength. <i>ATP2A1</i> is involved in muscular contraction and relaxation, and is the gene responsible for a muscle disorder called Brody disease (muscle cramping after exercise).</p>
        <p>The <i>FTO</i> locus has the second strongest association with muscle strength. <i>FTO</i> is associated with obesity and lipids, growth retardation, developmental delay, and facial dysmorphism.</p> 
        </div> 
        <div className='title-break'>
          <span>The genetic test has a <b>small</b> effect on muscle strength</span>
        </div>
        <div className="card">
        <p>First, strength variance is the range of strength found in the UK population, from low (e.g. elderly woman) to high (trained power athlete). Strength variance amongst athletes competing in the same sport is much narrower. As an athlete, one would like to know how much of your strength is due to your training versus how much is due to your inherent genetic makeup.     </p>
        <p>Second, for the civilian population in the UK, the 139 variants explained only 1.7% of the grip strength variance [<a href="https://pubmed.ncbi.nlm.nih.gov/29691431/">(Tikkanen et al., 2018)</a>]. Although this is a small percentage, it mostly reflects the large range in strength between weak, untrained people to strong, trained athletes. </p>
        <p>Hence, the effect of genetics on muscle strength amongst similar athletes is likely to be much larger than 1.7%, but it still may be small compared to the effect of your strength training regimen.</p>
      </div>
      <div className='title-break'>
          <span>Recommendations</span>
        </div>
        <div className="card">
        <p>If you have a <b>high</b> genetic strength score but average strength compared to your competition, you may have a higher top end for strength and there may be added value in focusing on strength training.  </p>
        <p>If you have a <b>low</b> genetic strength score, you may expect your strength training to have a slightly smaller impact on you compared to your competition. You can increase your strength training to compensate for your genetics. Strength training may have reduced effect on you partly from your genetics rather than your effort.  </p>
      </div>
      </div>

      return (
      <div>
        <div className='title-margins'>
        <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Muscle Strength</h2>
        <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/29691431/">(Tikkanen et al., 2018)</a>]</h5>
        </div>
        { testComponent }

      </div>

    );

    };
};


export default StrengthResults;
