import React from 'react';
import TagPercentile from '../../components/tag-percentile';
import BetaHistogram from '../../components/beta-histogram';
import { getIncidence } from '../../utils/results';

const RotatorResults = ({ result, userName, nameEndsWithS, tag, crm }) => {

  // ensures the user has results before showing the info
  const hasRelRisk = (result.hasOwnProperty("relativeRisk") && result['relativeRisk'] !== null);
  let pageContent;
  if (hasRelRisk) {
    let shorterLabel = (result.longLabel.toLowerCase()).split(" ")[0];
    let riskPercent = result.relativeRisk !== 0 ? `${(Math.abs(result.relativeRisk) * 100).toFixed(0)}% ${shorterLabel}` : shorterLabel;

    pageContent = 
    <div>
      {/* <p>Your genetics indicate a <b>{getIncidence(result.relativeRisk)}</b> risk for rotator cuff injury, which is a <b>{riskPercent}</b> risk compared to baseline.</p> */}
      <p>Your genetics indicate a <b>{getIncidence(result.relativeRisk)}</b> risk for rotator cuff injury, where 1.00 is normal and 1.10 is 10% increased risk.</p>
      <p><TagPercentile result={{ "value": result.relativeRisk }} tag={tag}/></p>
    </div>
  };
  
  return (
    <div>
      <div className='title-margins'>
        <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Rotator Cuff Injury</h2>
        <h5>[<a href="https://www.ncbi.nlm.nih.gov/pubmed/33482370">Kim et al., 2021</a>,<a href="https://pubmed.ncbi.nlm.nih.gov/32663566/">Tashjian et al., 2020</a>, <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0189317">Roos et al., 2017</a>]</h5>
      </div>
      <div className="card">
        <BetaHistogram result={{ "value": hasRelRisk ? result.relativeRisk : 0.0 }} crmKey="results-rotator-cuff-histogram" crm={crm}/>
        { pageContent }
        <p className="mb-0">The lifetime risk of rotator cuff injury for an average person is 40%.</p>
      </div>
      <div className="card">
        <p>Rotator cuff injury is serious for overhead throwing athletes and swimmers.</p>
        <p className="mb-0">AxGen developed a polygenic risk score for rotator cuff injury using 15 SNPs. Your risk is calculated based on your genotypes at these 15 DNA polymorphisms.</p>
      </div>
    </div>
  );
};



export default RotatorResults;
