import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import RiskFactorGraph from '../../components/risk-factor-graph';
import { getPercentileText2} from '../../utils/results';

export const Mars_ColCanResults = (props) => {
    const { crm, result, userName, nameEndsWithS, uploadID, width } = props; 
    let testComponent;
    let adjusted = result['adjusted_result'];
    let percentile;
    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
       adjusted = result['adjusted_result'];
    }
    if (result['value'] !== null) {
        
        testComponent =
            <div>
                <div className='title-break'>
                    <span>Your Results</span>
                </div>
                <div className="card">
                <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have higher risk for colorectal cancer. (Units for Beta are arbitrary.)</p>
                    <div>
                        {
                            adjusted ? (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-adjusted_Mars_ColCan-histogram' />
                                    <p> You are in the <b>{getPercentileText2(percentile)} </b>.  Your age and weight have been factored in to your score to better predict your risk for colorectal cancer.</p>

                                    
                                </>
                            ) : (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-Mars_ColCan-histogram' />
                                    <TagPercentile result={props.result} tag='Mars_ColCan' />
                                </>
                            )
                        }
                    </div>

                </div>
 
                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">
                <p>A score in the 84th percentile has about a 1.6 fold increased risk of chronic kidney disease. A score in the 97th percentile has about a 3.2 fold increased risk.</p>
                <p>Those with increased risk of colorectal cancer should consult their health care provider about getting screened with a colonoscopy.</p>
                <p>This study developed a polygenic risk score for colorectal cancer that includes 1,087,843 variants based on 306,418 individuals (34,624 cases).</p>
                <p>The study was limited to individuals of European ancestry. It is not clear how well the PRS score performs for other ancestries.</p>
                </div>

                {
  adjusted ? (
    (() => {
      const graphMax = .02 ;
      const data = [0.0117,  0.0048, 0.0165];
      return (
        <div>
          <div className='title-break'>
            <span>Impact of Genetics, Weight and Age on Risk for Colorectal Cancer</span>
          </div>
          <div className="card">
            <RiskFactorGraph graphMax={graphMax} data={data} />
            <p>The graph shows how risk for colorectal cancer is affected by genetics, risk factors (age and weight) or both combined. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts colorectal cancer exactly and 0.00 means that there is no information. For colorectal cancer, genetics has a larger effect than the risk factors, but combining them together has the most effect; overall, the combined score explains 1.6% of the variance in risk for colorectal cancer. </p>
          </div>
        </div>
      );
    })()
  ) : null
}
            </div>

        return (
            <div>
                <div className='title-margins'>
                <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Colorectal Cancer</h2>
                <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/36347255/">Mars et al. (2022)</a>]</h5>
                </div>
                {testComponent}

            </div>
            
        );
    };
};


export default Mars_ColCanResults;
