// NOTE: This file is suggested by react-ga at 
import React, { Component, } from "react";
import GoogleAnalytics from "react-ga";

const googleAnalyticsID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

if (googleAnalyticsID) { 
  GoogleAnalytics.initialize(googleAnalyticsID);
}

const GATracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    if (googleAnalyticsID) {
      GoogleAnalytics.set({
        page,
        ...options,
      });
      GoogleAnalytics.pageview(page);
    }
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default GATracker;