import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { tableGenerator } from '../utils/data-tables';
import { useCustomDispatch } from '../utils/customHooks';
import * as Actions from '../actions';
import EmailOrderForm from '../components/email-order-form';
import EmailOrderUpload from '../components/email-order-upload-form';


export const BusinessDashboard = ({ history }) => {
    const { alerts, orders, session, width } = useSelector((state) => ({
        alerts: state.alerts,
        orders: state.orders,
        session: state.session,
        width: state.ui.width
    }));
    const { myKits, isInPilot, numPreloadedCodes, numPreloadedUploads } = orders;

    // dispatches
    const loadMyKitsDispatch = useCustomDispatch(Actions.loadMyKitsThunk);
    const clearKitStatusDispatch = useCustomDispatch(Actions.clearKitStatusThunk);
    const checkManagerStatusDispatch = useCustomDispatch(Actions.getManagerStatusThunk)

    // redirects to homepage for unauthorized users
    // while only team-managers will use this page, admins should be able to see it
    useEffect(() => {
        if (session.userRole === 'team-manager' || session.userRole === 'axgen-admin') {
            clearKitStatusDispatch();
            loadMyKitsDispatch();
            checkManagerStatusDispatch();
        } else {
            history.push("/");
        };
    }, []);

    // parses data in preparation for table creation
    const setupTable = () => {
        const allOrders = [];
        myKits.forEach(order => {
            if (order.orderType === 'kit' || order.orderType === 'upload') {
                allOrders.push([order.kitBarcode, order.orderedFor !== "-" ? order.orderedFor : order.inviteeEmail, order.orderDate, order.orderType, order.resultsAvailable]);
            };
        });
        const orderCols = ["Kit Barcode", "Ordered For", "Date Ordered", "Order Type", "Results Available"];
        return tableGenerator(allOrders, orderCols, "All Orders", width);
    };

    return (
        <div>
            <Navbar />
            <div className="container mt-4">
                <div className="buy-header text-center mb-2">
                    <h2>Customer Portal</h2> 
                </div>
                <div className="admin-section mb-3 mt-4">
                    <div className='title-break mb-4'>
                        <span>Send Codes for Kits</span>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="card pt-3 col-md-8 col-sm-12">
                            <p>You can email codes to your clients (allowing them to ship a kit to themselves).</p>
                            <EmailOrderForm userRole={session.userRole} alerts={alerts} isInPilot={isInPilot} numPreloadedCodes={numPreloadedCodes} />
                        </div>
                    </div>
                    <div className='title-break mb-4'>
                        <span>Send Codes for Uploads</span>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="card pt-3 col-md-8 col-sm-12">
                            <p>You can email codes to your clients to let them upload their genome file.</p>
                            <EmailOrderUpload userRole={session.userRole} alerts={alerts} isInPilot={isInPilot} numPreloadedUploads={numPreloadedUploads} />
                        </div>
                    </div>
                    
                </div>
                <div className="admin-section mb-3 mt-4">
                    <div className='title-break mb-4'>
                        <span>All Orders</span>
                    </div>
                    { setupTable() }
                </div>
            </div>
            <div className="mt-4">
                <Footer />
            </div>
        </div>  
    );
};

export default withRouter(BusinessDashboard);
