import React from 'react';

class VitB6Intro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Vitamin B6</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/vitB6.jpg" height={200}/>   
        <p>Vitamin B6 (pyridoxine) is a water-soluble vitamin that is important for the metabolism of proteins, fats, and carbohydrates. Vitamin B6 is key for the production of red blood cells and is needed to make neurotransmitters such as serotonin and dopamine, making this vitamin key for mood regulation. Vitamin B6 may also play a role in improving brain function and lowering the risk of Alzheimer’s disease.</p>
        <p>Your body does not naturally produce vitamin B6, although some amount can be created by bacteria in the microbiome. Vitamin B6 is mostly obtained from one’s diet. Some of the best sources of vitamin B6 are pork, chicken, turkey, fish, peanuts, beans, oats, bananas, and milk. It is also available in supplement form from many multivitamins as well as B-complex vitamins. It is recommended that adult men obtain 1.4 mgs of vitamin B6 per day, and adult women consume 1.2 mgs per day. </p>
        </div>
      </div>
    )
  }
}

export default VitB6Intro;
