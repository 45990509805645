import React from 'react';
import ReactTooltip from 'react-tooltip';
import { _tooltipper, _vidListMaker } from '../../utils/prehab';
import { Link } from 'react-router-dom';

const vids = {
  'Banded Ankle Mobility': 'https://www.youtube.com/embed/9a8Dt8yRkM4?rel=0', 
  'Posterior Tibialis Heel Raises': 'https://www.youtube.com/embed/hQxhrfyxuj4?rel=0', 
  'Halos': 'https://www.youtube.com/embed/fu570xw-ERM?rel=0', 
  'Star Excursion Balance Test': 'https://www.youtube.com/embed/21arMJTCRrs?rel=0', 
  'Lateral Jumps with Stability': 'https://www.youtube.com/embed/m_qEXipYgjg?rel=0', 
  'Resistance Band Acceleration and Deceleration': 'https://www.youtube.com/embed/AJkwLSzchXo?rel=0'
};


const AnklePrevention = (props) => {
  const vidList = _vidListMaker(vids);
  const { width } = props;

  return (
    <div>
      <div className='title-margins'>
        <h2>Ankle Injury Prehabilitation Exercises</h2>
      </div>
      <div className="card p-1">
        {vidList}
      </div>
      <div className="card">
        <p className="mb-0">The videos above are examples of exercises that can reduce your chance of injury. For advice personalizing your strength program based on your genetic tests, you can schedule a strength consultation <Link to="/consultations">here</Link>.</p>
      </div>
      <div className="card">
        <p>Adequate ankle and lower leg flexibility, {_tooltipper("range of motion", "range of motion", width)}, and {_tooltipper("strength", "strength", width)} are important for preventing ankle sprain. Limited ankle dorsiflexion range of motion at baseline or following ankle injury is a predisposing risk factor for ankle sprain (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/23914912">Terada et al., 2013</a>). Sports incorporating running or sprinting require 20 to 30 degrees of active ankle dorsiflexion range of motion (<a target="_blank" rel="noopener noreferrer" href="https://pdfs.semanticscholar.org/a563/8085c3a4e963cd1b5a5d468d9e5fc6e1d00e.pdf">Youdas et al., 2009</a>). Banded ankle mobility exercises help maintain range of motion and improve lower leg strength, employing dorsiflexion to restore joint mobility through the ankle complex. Biased inversion or eversion during dorsiflexion can alter kinematics in the foot and ankle complex, increasing the risk of ankle injury. Posterior tibialis heel raises strengthen the lower leg muscles and provide support to the ankle ligaments. Ankle alignment through the full range of the heel raise discourages inversion of the ankle, which is a common occurrence during heel raises. Inversion sprains are the most common ankle injury amongst athletes.</p>
        <p>{_tooltipper("Proprioceptive exercises", "proprioception", width)} can increase functional joint stability and improve the central nervous system’s response in achieving postural control. In fact, a prospective study in a professional men’s basketball team from 2004-2010 found that proprioceptive exercises reduced game-related and practice-related ankle injury by 81% (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/26203850">Riva et al., 2016</a>). Two proprioceptive exercises that can help prevent ankle injury are halos and star excursions. Halos are meant to challenge the foot and ankle complex to dynamically stabilize while an athlete’s center of mass is continually changing. Inability to proprioceptively stabilize the foot and ankle complex puts an athlete at high risk for ankle injury. Star excursions force the ankle to exert control dynamically through all planes of motion. Athletes with a history of ankle injury were found to have greater fatigue-induced alterations of dynamic postural control on the star excursion balance test (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/23672384">Steib et al., 2013</a>).</p>
        <p>{_tooltipper("Dynamic postural control", "dynamic", width)} is the ability to maintain the body’s center of mass within a support base while the body completes a functional task. Achieving dynamic postural control requires integration of proprioception, range of motion, and strength (<a target="_blank" rel="noopener noreferrer" href="https://www.msjonline.org/index.php/ijrms/article/view/1733/1643">Thakkar and Kumar, 2015</a>). Ankle sprains in sports frequently occur when stability is compromised during abrupt changes in direction. Lateral jumps with stability teach the body how to stabilize before changing direction, mitigating the ankle sprain risk associated with abrupt changes in direction. Inversion sprains are also an issue for athletes, and most commonly occur during landing or deceleration. Resistance band acceleration and deceleration teaches the body how to decelerate and accelerate laterally and strengthens the ankle in active inversion.</p>
        <p className="mb-0">Athletes recovering from ankle sprains can initiate sport-specific moving patterns including cutting and jumping when ankle strength is close to equivalent to the unaffected side. Athletes should complete strengthening, flexibility, and proprioceptive exercises prior to initiating sport-specific movement patterns.</p>
      </div>
      <ReactTooltip place="top" className="tooltip-axgen" effect="solid" id="exercise-types" />
    </div>
  );
};

export default AnklePrevention;
