import React from 'react';


const Mars_HOsteoIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Hip Osteoarthritis</h2>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/Hip_Osteoarthritis.png" height={300}/> 
        <p>Hip arthritis is deterioration of the cartilage of the hip joint. The cartilage acts as a slippery coating between the ball and the socket that allows the ball to glide and rotate smoothly when the leg moves. The labrum, a strong cartilage that lines the outer rim of the socket, provides stability.</p>
        <p>With osteoarthritis, the cartilage in the hip joint gradually wears away over time. As the cartilage wears away, it becomes frayed and rough, and the protective joint space between the bones decreases. In advanced cases, bone rubs on bone, and any movement can cause pain and stiffness. When there is friction between bones, it can lead to bone spurs.</p>
      </div>
      <div className='title-break'>
        <span>Symptoms</span>
      </div>
      <div className="card">
        <ul>
          <li>Pain in or near the hip.</li>
          <li>Stiffness in the hip makes it difficult to move the hip or rotate the leg.</li>
          <li>Crepitus is a cracking or crunching sound in your hip.</li>
        </ul>

      </div>
      <div className='title-break'>
        <span>Genetics</span>
      </div>
      <div className="card">
        <p>The results page presents a polygenic risk score that can reveal a 2.7 fold increased risk for hip osteoarthritis for people in the 97th percentile. People at risk can take preventative steps before the arthritis becomes severe. One method for preventing osteoarthritis of the hip is to maintain a healthy weight. Another approach is to strengthen the muscles around the hip in order to prevent wear and tear on the cartilage.</p>
      </div>
    </div>
  )
}

export default Mars_HOsteoIntro;
