import React, { useState, useEffect } from 'react';
import CalendlyAPI from '../utils/calendlyAPI';

const Calendly = ({ name, email, consultantUN, consultantName }) => {
    const [calendly, makeCalendly] = useState(null);
    
    // this manages mounting the calendly script as a script tag, which contain the styling and other configs for the embedded widget
    // fires on initialization, which ensures that only one instance of the CalendlyAPI is ever created
    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.id = "calendly-widget";
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);

        // creates new instance of CalendlyAPI and saves it to the state; this api generates the url necessay for embedding calendly and and prefilling information fields for user responses
        let cal = new CalendlyAPI(consultantUN, name, email, consultantName);
        makeCalendly(cal);
    }, []);

    // ensures that the inline widget is only rendered once the script tag is attached
    const content = calendly !== null ? (
        <div className="calendly-form-parent">
            <div className="subsection-title mb-2">Schedule Consultation</div>
            <div className="calendly-embed-container">
                <div id= "calendly-embed" className="calendly-inline-widget" data-url={calendly.url}></div>
            </div>
        </div>
    ) : (
        null
    );
    
    return (
        <div className="scheduler-form">
            { content }
        </div>
    );
};

export default Calendly;