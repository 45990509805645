import React from 'react';
import RiskFactorGraph from '../../components/risk-factor-graph';
import RiskGraph from '../../components/risk-line-graph';
import { getPercentileText2, findBucket } from '../../utils/results';

// For Yu_CKD (result or adjusted_result), UER.percentile is required or the page does not load.
// Info on how to enter data to calculate CKD risk by the RICK algorithm is at https://docs.google.com/document/d/1bp6FKJnKZVKAD5Dsl8tS73fQOjAO6HU6BqZyIj--T74/edit

const bmdChartConfig = require('../../../json/ostp-config.json');

export const Yu_CKDResults = (props) => {
    const { result, userName, nameEndsWithS } = props;
    let testComponent, CKDIndex;
    const setupData = bmdChartConfig.setupData;
    const { CKDRisk, adjustedCKDRisk, avgCKDResult, avgAdjustedCKDResult } = setupData;
    let adjusted = result['adjusted_result'];
    let percentile, raw_result;
    let CKDRiskPercent, CKDRiskPercent2;
    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
        CKDIndex = findBucket(adjusted, avgAdjustedCKDResult); 
        adjusted = result['adjusted_result'];
        CKDRiskPercent2 = adjustedCKDRisk[CKDIndex];   // Assign the adjusted value to CKDRisk
    } else {
        raw_result = result['value'];
        CKDIndex = findBucket(raw_result, avgCKDResult);   
        CKDRiskPercent = CKDRisk[CKDIndex];  // Assign adjustedCKDRisk to CKDRisk
    }
    console.log('CKDIndex :', CKDIndex)
    console.log('CKDRiskPercent :', CKDRiskPercent)
    console.log('raw_result :', raw_result)

    if (result['value'] !== null) {

        testComponent =
            <div>
                {adjusted ? (
                    <>
                        <div className='title-break'>
                            <span>Your Results</span>
                        </div>
                        <div className="card">
                            <p>To use the graph, find the dot on the line that shows your genetic score. The grey histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have a higher risk for chronic kidney disease. Your score is comprised of both the genetic and clinical risk factor results.</p>
                            <RiskGraph graphType="risk" percentile={percentile} crmKey='results-adjusted-Yu_CKD-histogram' />
                            <br></br>
                            <p> You are in the <b>{getPercentileText2(percentile)}</b> and your risk for CKD is {(CKDRiskPercent2)} fold compared to the average. Your age, sex, BMI, LDL, HbA1C, smoking status and systolic blood pressure have been factored in to your score to better predict your CKD risk.</p>

                        </div>
                    </>
                ) : (
                    <>
                        <div className='title-break'>
                            <span>Your Results</span>
                        </div>
                        <div className="card">
                            <p>To use the graph, find the dot on the line that shows your genetic score. The grey histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have a higher risk for chronic kidney disease.</p>
                            <RiskGraph graphType="risk" percentile={percentile} crmKey='results-Yu_CKD-histogram' />
                            <br></br>
                            <p>You are in the <b>{getPercentileText2(percentile)}</b> and your risk for CKD is {(CKDRiskPercent)} fold compared to the average. </p>
                        </div>
                    </>
                )}
                <div>
                </div>
                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">
                    <p>This study developed a polygenic risk score (PRS) for chronic kidney disease that includes 1,477,661 variants based on 1,159,871 people. The PRS was then validated on an independent cohort of 8,886 individuals.</p>
                    {
                    adjusted ? (
                        <p>The polygenic risk score explains 28.3% of the variance in kidney function.</p>
                    ) : (
                        <p>The polygenic risk score explains 21.6% of the variance in kidney function.</p>
                        )
                    }
                    <p>The study was performed on individuals of White British descent. It is unclear how these results generalize to other ancestry groups.</p>
                    <p>The chronic kidney disese PRS is being used in the eMERGE IV network beginning in 2022. Patients with a high-risk PRS will receive guideline-informed health recommendations.</p>
                </div>

                {
                    adjusted ? (
                        (() => {
                            const graphMax = .3;
                            const data = [0.216, 0.071, 0.283];
                            return (
                                <div>
                                    <div className='title-break'>
                                        <span>Impact of Genetics and Clinical Risk Factors on CKD Risk</span>
                                    </div>
                                    <div className="card">
                                        <RiskFactorGraph graphMax={graphMax} data={data} />
                                        <p>The graph shows how risk for chronic kidney disease is affected by genetics, risk factors (age, sex, BMI, LDL, HbA1C, smoking status and systolic blood pressure) or both combined. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts chronic kidney disease exactly and 0.00 means that there is no information. For CKD, genetics has a larger effect than risk factors, but combining them together has the most effect and explains 28.3% of the variance in kidney function.  </p>
                                    </div>
                                </div>
                            );
                        })()
                    ) : null
                }
            </div>

        return (
            <div>
                <div className='title-margins'>
                    <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Chronic Kidney Disease</h2>
                    <h5><a href="https://pubmed.ncbi.nlm.nih.gov/34548389/">Yu et al., 2021</a></h5>
                </div>
                {testComponent}

            </div>
        );
    };
};


export default Yu_CKDResults;
