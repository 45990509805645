import React from 'react';


const AMDIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Age-Related Macular Degeneration</h2>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/amd_fig.png" height={300}/> 
        <p>Age-related macular degeneration (AMD) is an eye disease that can blur your central vision. AMD is caused by a deterioration of the macula - the part of the retina that controls sharp, straight-ahead vision.</p>
        <p>It is a common eye condition that mainly affects people over the age of 50. It’s estimated that 11 million Americans have this disease. It’s the number one cause of vision loss. </p>
        <p>There are two types of AMD, wet and dry. Dry AMD is quite common. About 80% of AMD is the dry form. With dry AMD, parts of the macula get thinner with age and tiny clumps of protein called drusen grow. It causes gradual vision loss, and as the condition gets worse, the light-sensitive cells in your macula get thinner and eventually die. In the atrophic form, you may have blind spots in the center of your vision. As that gets worse, you might lose central vision.</p>
        <p>Wet AMD is less common; only about 10% of people with macular degeneration have the wet form. Wet AMD is much more serious as it tends to cause faster vision loss. With wet AMD, new, abnormal blood vessels grow under the retina. These vessels may leak blood or other fluids, causing scarring of the macula. Vision loss is faster with wet AMD than with dry AMD.</p>
      </div>
      <div className='title-break'>
        <span>Symptoms</span>
      </div>
      <div className="card">
        <p>Many people don’t realize they have AMD until their vision is blurry so that straight lines look wavy. There may be blind spots and loss of central vision. Bleeding from blood vessels in the eye eventually forms a scar, leading to permanent loss of central vision. This is why it is important to have regular visits to an ophthalmologist. He or she can look for early signs of AMD before you have any vision problems.</p>
        <p>In dry AMD there is a progressive accumulation of characteristic yellow deposits, called drusen, in the macula. Dry AMD can be divided into 3 stages (early, intermediate, and late) based on the size and number of drusen present in the macula. The symptoms for wet AMD are similar to those of late, dry AMD.</p>
        <ul>
          <li>Early AMD causes no symptoms other than the presence of drusen. </li>
          <li>Intermediate AMD may have either no symptoms or mild symptoms, like mild blurriness in central vision or trouble seeing in low lighting.</li>
          <li>Late AMD or wet AMD may cause straight lines to start to look wavy or crooked. There may also be a blurry area near the center of vision. Over time, this blurry area may get bigger or result in blank spots. Colors may also seem less bright than before, and there may be more trouble seeing in low lighting.</li>
        </ul>
      </div>
      <div className="title-break">
        <span>Causes and Risk Factors</span>
      </div>
      <div className="card">
        <p>The exact cause of AMD is unknown, but there are certain risk factors that can increase your chances of developing the condition. These risk factors include age, smoking, obesity and genetics.</p>

      <ul>
        <li>Age is the biggest risk factor for AMD. As you get older, your risk of developing AMD increases. This is because the cells in the macula become less efficient at repairing themselves as you age.</li>
        <li>Smoking increases the risk of developing AMD by two to three times. This is because smoking damages the cells in the macula, making them more prone to damage from other factors.</li>
        <li>People who are overweight or obese are more likely to develop AMD than those of a healthy weight. This is because obesity increases inflammation in the body, which can damage the cells in the macula.</li>
        <li>Finally, genetics and having a family history of AMD increases your risk of developing the condition. Mutations in either the CFH or the ARMS2 genes increase your risk of AMD. </li>
      </ul>
      </div>
      <div className="title-break">
        <span>Prevention and Treatment</span>
      </div>
      <div className="card">
        <p>For dry AMD, a mix of vitamins from the Age-Related Eye Disease Study 2 (called AREDS 2) may help to slow the progression of either intermediate or late macular AMD. AREDS2 is available over the counter and the daily dose consists of:</p>
        <ul>
          <li>500 mg of vitamin C</li>
          <li>400 IU of vitamin E</li>
          <li>80 mg of zinc</li>
          <li>10 mg of lutein</li>
          <li>2 mg of zeaxanthin</li>
          <li>2 mg of copper</li>
        </ul>
        <p>Other measures to ameliorate dry AMD are quitting smoking (or never starting),  a healthy lifestyle and eating a proper diet. </p>
        <p>Treatment of wet AMD includes the possibility of medications and surgery. Common medications used to treat AMD include anti-VEGF drugs, which block the growth of new blood vessels in the eye, and corticosteroids, which reduce inflammation. Surgery may be necessary in some cases of wet AMD. Laser surgery can be used to seal leaking blood vessels in the eye, while photodynamic therapy can be used to destroy abnormal blood vessels. In some cases, a surgical procedure called a vitrectomy may be necessary to remove scar tissue from the back of the eye.</p>
      </div>

    </div>
  )
}

export default AMDIntro;
