import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';


export const IBDCeliac = ({ userName, nameEndsWithS, uploadID, width }) => {
  // this page contains the results of multiple tests
  const { crm, uploads } = useSelector((state) => ({
    crm: state.crm,
    uploads: state.results.uploads,
  }));

  const getGraph = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    const crmKey = `results-${tag}-histogram`;
    // HRex has crmkey but no results.
    return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
  };

  const getPercentile = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    return <TagPercentile result={tagResult} tag={tag} />;
  };

  return (
    <div>
      <div className='title-margins'>
        <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Celiac Disease</h2>
      </div>
      <div className="card">
        <p>The AxGen test for celiac disease uses a genetic algorithm with 2513 genetic markers developed by <a href="https://pubmed.ncbi.nlm.nih.gov/26244058/">Abraham et al., 2015</a>.</p>
      </div>
      <div className="card">
        {getGraph("celiac")}
        <p>The x-axis shows your risk for celiac disease. The important information from the histogram is the <b>percentile</b> of your genetic score compared to others (the genetic score is in artibrary units). To use this graph, hover your mouse over the red line and look up your percentile. The blue histogram shows the number of people and their genetic score for risk for celiac disease. The bin containing your score tells you how many other people have a score like yours. <b>If your score is on the <strong>right side of the histogram</strong>, you will tend to have a higher risk for celiac disease. </b> </p>
        <p className="mb-0">{getPercentile("celiac")}</p>
        <br></br>


        <p>Your genetic score is highly predictive of your risk for celiac disease [<a href="https://pubmed.ncbi.nlm.nih.gov/26244058/">Abraham et al., 2015</a>].  </p>
                <div className="table-subcontainer">
                    <table className="default-table">
                        <thead>
                            <tr>
                                <th>Genetic Score Percentile</th>
                                <th>Risk for Celiac Disease</th>
                                <th>Fold Change in Risk for Celiac Disease<sup>*</sup> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>below 35%</td>
                                <td>0%</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td>50%</td>
                                <td>2.5%</td>
                                <td>1.8x</td>
                            </tr>
                            <tr>
                                <td>80%</td>
                                <td>4.2%</td>
                                <td>3x</td>
                            </tr>
                            <tr>
                                <td>90%</td>
                                <td>7.0%</td>
                                <td>5x</td>
                            </tr>
                            <tr>
                                <td>95%</td>
                                <td>11.2%</td>
                                <td>8x</td>
                            </tr>
                        </tbody>
                    </table>
                    <p></p>
                    <p><sup>*</sup>The overall prevalence of celiac disease is 1.4%. Individuals with a score below the 35th percentile are protected from getting celiac disease. Individuals at the 95th percentile have an 11.2% chance of getting celiac disease, which is 8 fold higher than the population average.   </p>
                </div>

        <div className='title-break'>
          <span>The genetic test is <b>strongly</b> informative for Celiac disease</span>
        </div>
        <div className="card">
        <p>First, the overall heritability of celiac disease is 80%, which means that genetics plays a strong role compared to environment [<a href="https://pubmed.ncbi.nlm.nih.gov/16354797/">Nistico et al., 2006</a>].  </p>
        <p>Second, a genetic score below the 35th percentile protects you from celiac disease whereas a score above the 95th percentile indicates an 8 fold increased risk.</p>
        <p>Third, the area under the receiver-operator curve (AUROC) for this test is 0.83. This means that the genetic score is able to correctly classify 83% of individuals as having celiac disease or not. The 0.83 performance metric for genetic prediction of celiac disease is one of the highest for any complex disease.</p>
        </div>
        <div className='title-break'>
          <span>AxGen's test versus other companies</span>
        </div>
        <div className="card">
        <p>AxGen has the most complete genetic test for celiac disease on the market. Whereas other companies use two genes (HLA-DQ2 and HLA-DQ8), AxGen's test includes these two genes plus thousands more that provide a stronger picture of your risk for celiac disease [<a href="https://pubmed.ncbi.nlm.nih.gov/26244058/">Abraham et al., 2015</a>]. HLA-DQ2 and HLA-DQ8 are part of the Major Histocompatibility Complex and are directly involved in the autoimmune reaction to eating gluten. However, only some people with HLA-DQ2 and HLA-DQ8 develop celiac disease and the additional information in the AxGen test helps tell you whether you are one of these people.</p>
        </div>
      </div>
    </div>
  );
};

export default IBDCeliac;
