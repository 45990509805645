import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import RiskFactorGraph from '../../components/risk-factor-graph';
import { getPercentileText2, prob_dens_function} from '../../utils/results';

export const Mars_arthResults = (props) => {
    const { crm, result, userName, nameEndsWithS, uploadID, width } = props;
    let testComponent;
    let adjusted = result['adjusted_result'];
    let percentile;
    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
       adjusted = result['adjusted_result'];
    }

    // -----------------
    const mean = 0;
    const sd = 1;
    const testPercentile = 0.8413;
    const risk = prob_dens_function(mean, sd, testPercentile);
    
    console.log(`The risk for ${testPercentile} percentile is: ${risk}`);

    // ----------
    if (result['value'] !== null) {
        
        testComponent =
            <div>
                <div className='title-break'>
                    <span>Your Results</span>
                </div>
                <div className="card">
                    <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have higher risk for rheumatoid arthritis. (Units for Beta are arbitrary.) </p>
                    <div>
                        {
                            adjusted ? (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-adjusted_Mars_arth-histogram' />
                                    <p> You are in the <b>{getPercentileText2(percentile)} </b>. Your height, sex, age and weight have been factored in to your score to better predict your risk for rheumatoid arthritis.</p>

                                </>
                            ) : (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-Mars_arth-histogram' />
                                    <TagPercentile result={props.result} tag='Mars_arth' />
                                </>
                            )
                        }

                    </div>

                </div>
                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">
                    <p>A score in the 84th percentile has about a 1.72 fold increased risk. A score in the 97th percentile has about a 3.44 fold increased risk.</p>
                    <p></p>
                    <p>This study developed a polygenic risk score for rheumatoid arthritis that includes 1,083,565 variants based on 306,418 individuals (14,361 cases).</p>
                    <p>The study was limited to individuals of European ancestry. It is not clear how well the genetics perform for other ancestries.</p>
                </div>

                {
                    adjusted ? (
                        (() => {
                            const graphMax = .009;
                            const data = [0.002982, 0.005801, 0.008748];
                            return (
                                <div>
                                    <div className='title-break'>
                                        <span>Impact of Genetics and Age on Risk for Rheumatoid Arthritis</span>
                                    </div>
                                    <div className="card">
                                        <RiskFactorGraph graphMax={graphMax} data={data} />
                                        <p>The graph shows how risk for rheumatoid arthritis is affected by genetics, sex or both combined. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts rheumatoid arthritis exactly and 0.00 means that there is no information. For rheumatoid arthritis, genetics has a smaller effect than sex, but combining them together has the most effect; overall, the combined score explains 0.87% of the variance in risk for rheumatoid arthritis. </p>
                                    </div>
                                </div>
                            );
                        })()
                    ) : null
                }
            </div>

        return (
            <div>
                <div className='title-margins'>
                    <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Rheumatoid Arthritis</h2>
                    <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/36347255/">Mars et al. (2022)</a>]</h5>
                </div>
                {testComponent}

            </div>

        );
    };
};


export default Mars_arthResults;
