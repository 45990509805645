import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Alerts from '../components/alerts';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import * as Actions from '../actions';
import { tableGenerator } from '../utils/data-tables';
import { useCustomDispatch } from '../utils/customHooks';


export const AdminConsultationsPage = ({ history }) => {
  // global states
  const { alerts, consultations, session, width } = useSelector((state) => ({
    alerts: state.alerts,
    consultations: state.admin.consultations,
    session: state.session,
    width: state.ui.width
  }));


  // assorted local states
  const [disabled, disableSubmit] = useState(false);
  const [counselor, setCounselor] = useState("");
  const [athlete, setAthlete] = useState("");
  const [athleteData, setAthletes] = useState({});
  const [counselorData, setConsultants] = useState({});


  // dispatches
  const createConsultDispatch = useCustomDispatch(Actions.createAdminConsultThunk);
  const loadConsultsDispatch = useCustomDispatch(Actions.loadAdminConsultsThunk);


  // helper functions
  // these two time functions convert the backend utc time into a pretty time based on the user's time zone
  // they use the moment.js library
  // replace(/-/g, "/") is used to format the consult time for the Safari browser. Without this, Safari shows "Invalid date" rather than the consult details
  const dateTimeConveter = (start, end) => {
    const utcStart = new Date(`${start}`.replace(/-/g, "/"));
    const consultDate = moment.utc(utcStart).local().format('L');
    const startTime = moment.utc(utcStart).local().format('LT');

    const utcEnd = new Date(`${end}`.replace(/-/g, "/"));
    const endTime = moment.utc(utcEnd).local().format('LT');

    return `${consultDate} ${startTime} - ${endTime}`;    
  };

  // create a reference obj for athletes
  const _handleAthletes = () => {
    const athleteObj = {};
    consultations.athletes.forEach(person => {
      const athleteKey = `${person.name} (${person.email}) (${person.uploadEntryId ? person.uploadEntryId : "no results"})`;
      athleteObj[athleteKey] = person.id
    });
    setAthletes(athleteObj);
  };

  // create a reference obj for consultants
  const _handleConsultants= () => {
    const consultantObj = {};
    consultations.counselors.forEach(person => {
      consultantObj[person.name] = person.id
    });
    setConsultants(consultantObj);
  };
  
  // generates the table data
  const _generateTableData = () => {
    if (consultations.hasOwnProperty('consultations')) {
      let allConsults = consultations.consultations.map(consult => {
        const orderType = consult.orderType ? consult.orderType : "-";
        const consultTime = consult.startTime && consult.endTime ? dateTimeConveter(consult.startTime, consult.endTime) : "-";
        const uploadEntryLink = consult.uploadEntryId ? consult.uploadEntryId : "-";
        
        return [consult.consulteeName, consult.consultantName, orderType, consult.status, moment.utc(consult.createdAt).format('YYYY-MM-DD'), consultTime, uploadEntryLink];
      });
      return allConsults;
    };
  };


  // main functions
  // books admin-created consultations, which do not use calendly
  // preventDefault prevents the form from refreshing automatically onSubmit
  const addConsultation = (e) => {
    e.preventDefault();
    Actions.clearAlerts();
    disableSubmit(true);
    if (counselorData[counselor] && athleteData[athlete]) {
      createConsultDispatch(counselorData[counselor], athleteData[athlete]);
    } else {
      Actions.addAlert('create-consultation', 'error', 'Consultant and/or consultee does not exist.');
    };
  };

  // loads all of the consultations on mount 
  // redirects unauthorized users
  useEffect(() => {
    Actions.clearAlerts();
    if (session.userRole === 'axgen-admin') {
      loadConsultsDispatch();
    } else {
      history.push("/");
    };
  }, []);

  // un-disables the disabled submit once an alert is detected
  useEffect(() => {
    if (Object.keys(alerts).length !== 0) {
      disableSubmit(false);
    };
  }, [alerts]);

  // sets up data once consults are loaded
  useEffect(() => {
    if (Object.keys(consultations).length !== 0) {
      // generate athlete and consultant data structures
      _handleAthletes();
      _handleConsultants();
    };
  }, [consultations]);


  // presentational

  // creates the table
  // TODO: add custom sorting to tableGenerator to make the function more dynamic
  const tableCols = [
    "Athlete", "Counselor", "Order Type", "Status", "Date Created", "Consult Details", 
    {
      name: "View Results",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== '-') {
            return (
              <a href={`/results/${value}`}>{value}</a>
            );
          } else {
            return value;
          };
        }
      }
    }
  ];
  const consultTable = tableGenerator(_generateTableData(), tableCols, "All Consultations", width);

  // autocomplete search bar for athlete
  const athleteSearchBar = 
  <div>
    <input type="text" list="my-athletes" placeholder="Select Athlete" className="barcode-input form-control" value={athlete} onChange={(e) => setAthlete(e.target.value)}/>
    <datalist id="my-athletes">
      { 
        (consultations.hasOwnProperty('athletes')) ? (
          consultations.athletes.map((person, idx) => <option key={idx} id={person.id} value={`${person.name} (${person.email}) (${person.uploadEntryId ? person.uploadEntryId : "no results"})`} />)
        ) : null
      }
    </datalist>
  </div>
  
  // autocomplete search bar for consultant
  const consultantSearchBar = 
  <div>
    <input type="text" list="my-consultants" placeholder="Select Consultant" className="barcode-input form-control" value={counselor} onChange={(e) => setCounselor(e.target.value)}/>
    <datalist id="my-consultants">
      { 
        (consultations.hasOwnProperty('counselors')) ? (
            consultations.counselors.map((person, idx) => <option  onSelect={(e) => setCounselor(e.target.value)} key={idx} id={person.id} value={person.name} />)
        ) : null
      }
    </datalist>
  </div>

  // screen-size specific style(s)
  const formInputWidth = width > 767 ? "col-6" : "col-12"; // toggles col-12 (100% width) on mobile and col-6 (50% width) on regular-sized devices for form input fields

  // the form that admins use to submit consultations
  const adminConsultForm = 
    <form onSubmit={ (e) => addConsultation(e) } className="card form pt-3">
      <p>You can create <b>admin-created</b> consultations with this form. Note that these consultations will be free and not connected to Calendly.</p>
        <div className="row">
          <div className={formInputWidth}>
            <label>Counselor</label>
            { consultantSearchBar }
          </div>
          <div className={formInputWidth}>
            <label>Athlete</label>
            { athleteSearchBar }
            </div>
        </div>
        <input
          type="submit"
          className="form-control btn btn-axgen mb-0"
          value="ASSIGN CONSULT"
          disabled={disabled}
        />
        <Alerts tag='create-consultation' level="success"/>
        <Alerts tag='create-consultation' />
    </form>

  // handles rendering the content
  const content = session.userRole === "axgen-admin" ? (
    <div>
      <h2 className="text-center mb-4">Manage Consultations</h2>
      <div className="row d-flex justify-content-center my-4">
        <div className={width < 768 ? "col-12" : "col-8"}>
          {adminConsultForm}
        </div>
      </div>
      <div className="card mb-3">
        <p>Consultation Status Key:</p>
        <ul>
          <li><b>paid</b>: consultation has been paid for but not scheduled on Calendly.</li>
          <li><b>scheduled</b>: consultation has been paid for and scheduled on Calendly.</li>
          <li><b>cancelled</b>: consultation has been cancelled on Calendly. This action will reqire some follow-up for feedback and refunds on AxGen's part.</li>
          <li><b>admin-created</b>: consultations created using the above form by AxGen admins.</li>
          <li><b>pending / test</b>: legacy statuses for consultations before the consultation scheduler was implemented.</li>
          <li><b>completed</b>: the date and time scheduled for the consultation has passed.</li>
        </ul>
      </div>
      { consultTable }
    </div>
  ) : (
    null
  );

  return (
    <div>
      <Navbar />
      <div className="container mt-5">
        { content }
      </div>
      <br/><br/>
      <Footer />
    </div>
  );
};

export default withRouter(AdminConsultationsPage);