import React from 'react';


export const ITBIntro = () => {
  return (
    <div>
      <div className="title-margins">
        <h2>Introduction to Iliotibial Band Syndrome</h2>
      </div>
      <div className='title-break'>
          <span>High Risk Sports</span>
      </div>
      <div className='high-risk-list my-3 py-1'>
        <div className="result-sport-figure mb-2">
            <img src='/assets/imgs/results/athletes/runner.png'alt="" />
            <span>Endurance Running</span>
        </div>
        <div className="result-sport-figure">
          <img src='/assets/imgs/results/athletes/basketball.png' alt="" />
          <span>Basketball</span>
        </div>
        <div className="result-sport-figure">
          <img src='/assets/imgs/results/athletes/soccer.png' alt="" />
          <span>Soccer</span>
        </div>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
        <div className="card">
          <img src="/assets/imgs/results/content/ITB.jpg" alt="" height={250} className="overview-img" />
          <p>The iliotibial band (ITB) is a thick band of fascia that begins at the hip, runs down the lateral or outside part of the thigh, and crosses the knee to attach into the top part of the shinbone. The ITB helps stabilize the outside part of the knee through its range of motion. When the knee is flexed, the ITB moves across the femoral epicondyle, which is a bony outcropping of the femur or thighbone at the knee joint. A sac or bursa located below the ITB allows the band to glide smoothly across the condyle.</p>
          <p >ITB syndrome is a common overuse injury due to inflammation from friction arising from repeatedly rubbing the ITB across the bony condyle.  It is a common cause of lateral knee pain seen mostly in runners and cyclists, but can be seen in any sports that involve running or repetitive motion of the lower extremity.  It can take 4 to 10 weeks to recover from ITB syndrome depending on the injury's severity.  </p>
          <p >The severity of ITB injuries is typically broken down into 4 grades:</p>
            <ul >
                    <li>Grade I: Pain occurs only after running/exercise and does not limit speed or strength.</li>
                    <li>Grade II: Pain occurs during running/exercise and does not limit speed or strength.</li>
                    <li>Grade III: Pain occurs during running/exercise and limits strength or speed.</li>
                    <li>Grade IV: Pain prohibits running/exercise completely.</li>
            </ul>
        </div>
        <div className='title-break'>
          <span>Symptoms</span>
        </div>
        <div className="card">
          <img src="/assets/imgs/results/content/ITB3.jpg" alt="" height={250} className="overview-img" />
          <p>The symptoms of ITB syndrome are pain and tenderness on the lateral side of the knee. The pain is due to inflammation of the area where the ITB crosses the femoral epicondyle.</p>
          <p>Initially, there may be a sensation of stinging or needle-like pricks. This minor symptom gradually progresses to pain every time the heel strikes the ground and finally can become disabling when walking or climbing up or down steps. Some athletes may feel a snapping or popping sound at the knee, and there may be some swelling either where the ITB crosses the femoral epicondyle or below the knee where it attaches to the tibia.</p>
        </div>
        <div className='title-break'>
          <span>Causes</span>
        </div>
        <div className="card">
          <p className="mb-0">Iliotibial band inflammation is an overuse syndrome that may be the result of a combination of issues.
          <ul className="mb-0">
                    <li>Poor flexibility of muscle.</li>
                    <li>Discrepancy in leg length.</li>
                    <li>Having bowed legs.</li>
                    <li>Running on roads that are canted or banked with the center of the road higher than the outside edge. Running on the same side of the road produces the same effect as having a leg-length discrepancy. </li>
                    <li>Running downhill is especially stressful on the ITB as it works to stabilize the knee.</li>
                    <li>Bicyclists that have improper posture on their bike where toe clips are improperly aligned, forcing the foot to be internally rotated and toed in. This causes the same effect as bowed legs, increasing friction on the ITB as it crosses the knee and thereby leading to inflammation.</li>
                </ul>
          </p>
        </div>
        <div className='title-break'>
          <span>Frequency</span>
        </div>
        <div className="card">
          <p>The incidence of ITB syndrome is 2-16% for runners and 15-24% for cyclists [<a href="https://pubmed.ncbi.nlm.nih.gov/26616177/">Baker and Fredericson, 2016</a>; <a href="https://pubmed.ncbi.nlm.nih.gov/21063495/">Lavine, 2010</a>; <a href="https://pubmed.ncbi.nlm.nih.gov/22134205/">Strauss et al., 2011</a>]. The condition can cause significant time away from sport for both recreational and higher level athletes.</p>
        </div>
    </div>
  );
}

export default ITBIntro;
