import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

class TOSPage extends React.Component {
  render() {
    return (
      <div>
        <Navbar stateless={true} />
        <div className="container mt-5">
          <p className="mb-3"><strong >PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW) ARE SUBJECT TO THE FOLLOWING TERMS: THESE TERMS INCLUDE A CLASS ACTION WAIVER AND RESOLUTION DISPUTES BY ARBITRATION INSTEAD OF BY A COURT PROCEEDING. IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</strong></p>

          <h1 className="mb-4"><u>Terms of Use</u></h1>
          <p className="mb-5">Effective date: 11/01/2019</p>

          <p>Welcome to AxGen. Please read on to learn the rules and restrictions that govern your use of our website(s), products, services and applications (the “Services”). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at <a href="mailto:contact@axgen.us">contact@axgen.us</a>, 650-867-3189, 956 Mears Ct. Stanford, CA 94305.</p>

          <p>These Terms of Service (the “Terms”) are a binding contract between you and AxGen, Inc. (“AxGen,” “we” and “us”). You must agree to and accept all of the Terms, or you don’t have the right to use the Services. Your using the Services in any way means that you agree to all of these Terms, regardless of whether you have an account with us, and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document, as well as those in the <Link to="/privacy">Privacy Policy</Link> and <Link to="/copyright">Copyright Dispute Policy</Link>. In addition, when using particular AxGen Services, you shall be subject to any guidelines or rules applicable to such services that may be posted from time to time. All such guidelines or rules are hereby incorporated by reference into these Terms. </p>
          <p>Except as specified herein, these Terms apply to any use of the Services, including but not limited to providing a saliva sample for DNA extraction, processing and analysis, uploading a digital copy of your Genetic Data and working with it on the AxGen website, and/or creating or using an AxGen account (free or not) without submitting a saliva sample or Genetic Data. “Genetic Data” is information regarding a person’s genotype (such as, the locations and sequences of the nucleotide bases (the As, Ts, Gs and Cs) in your genome) provided to, or generated through analysis of a saliva sample (or otherwise) by, AxGen or by its contractors, successors, and assignees. </p>
          <p>The Services are not intended for any diagnostic, healthcare or medical purpose. If you have any health-related questions please seek the advice of your health care providers.</p>
          <p>Unless explicitly stated otherwise, each new feature that augments or enhances the current Service shall be subject to these Terms. You acknowledge and agree that the Services are provided "AS-IS" and are based on the current state of genetic research and technology used by AxGen when purchased or viewed. As knowledge and technology advance, AxGen is continually improving in order to provide the best experience for users. As part of this improvement, you acknowledge and agree that AxGen may suspend or cease providing some Services or features to some or all users at AxGen's sole discretion.</p>
          

          <p><strong><u>Will these Terms ever change?</u></strong></p>
          <p>We are constantly trying to improve our Services, so these Terms may need to change along with the Services. We reserve the right to change the Terms at any time, but if we do, we will bring it to your attention by placing a notice on the https://www.axgen.us/ website, by sending you an email, and/or by some other means. </p>
          <p>If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes. </p>
          <p>Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.</p>


          <p><strong><u>Who is eligible to use AxGen?</u></strong></p>
          <p>Whether or not you provide a saliva sample, a saliva sample for anyone for whom you have legal authority to agree, or otherwise provide your Genetic Data, you may not use the Services and may not accept these Terms if (a) you are not at least 18 years old and otherwise legally able to form a binding contract (except that a parent may let his or her minor child who is at least 13 years old access such parent’s account as set forth below), or (b) you are prohibited from receiving the Services under the laws of place where you reside or use the Services. You may use the Services only in the country your sample collection kit was shipped to by AxGen.</p>
          <p>You may submit your minor child’s saliva sample (or Genetic Data) instead of your own if he or she is at least 13 years old, in which case the references to your Genetic Data, your saliva sample, your User-Reported Information, and the like in these Terms and our Privacy Policy apply to those of your child. If you choose to provide your child’s saliva sample (or Genetic Data), (a) you represent and warrant that you have the legal right to do so and (b) you agree to explain to your child how his or her sample is used for DNA extraction and the risks associated with receiving Genetic Data (as further described in the following sections). AxGen assumes no responsibility for such consequences. You agree to provide accurate and complete information when registering for and/or purchasing our Services, including when you register for an account. You may not select as your username a name that you don’t have the right to use, or another person’s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission, but you may use the features of the Services to share your Genetic Data and other information with another user’s account (typically, your trainer’s account). </p>
          <p>Note that you may be given access to another user’s Genetic Data or other information via the Services (for example, if you are a trainer, athletes may choose to share their Genetic Data with you). You agree to keep all such Genetic Data and other information confidential, not to use it for any purpose other than with the user’s permission, and not to share any such Genetic Data or information with anyone else without the user’s permission. </p>
          <p>You will not share your account or password with anyone, except that you may share such account credentials with your minor child if you have submitted that child’s saliva sample in accordance with these Terms, in which case your child must comply with these terms as if he or she was you and you will be responsible for any noncompliance. You must protect the security of your account and your password. You’re responsible for any activity associated with your account. We cannot and will not be liable for any loss or damage arising from your failure to comply with this Section.</p>


          <p><strong><u>What about my privacy?</u></strong></p>
          <p>AxGen takes the privacy of its users very seriously. For the current AxGen Privacy Policy, please <Link to="/privacy">click here</Link>. You may not use the Services if you do not accept our Privacy Policy.</p>
          <p>The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13. We do not knowingly collect or solicit personally identifiable information from children under 13; if you are a child under 13, please do not attempt to register for the Services or send any personal information about yourself to us. If we learn we have collected personal information from a child under 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact us at <a href="mailto:contact@axgen.us">contact@axgen.us</a>.</p>


          <p><strong><u>What else should I know about AxGen?</u></strong></p>
          <p><strong>When you receive your Genetic Data, you cannot unlearn the knowledge you’ve gained.</strong> Don’t assume that your results will be reassuring. And, as research develops, you may need to obtain further services from AxGen or a trainer or physician in order to understand the meaning of your DNA in the context of such developments.</p>
          <p><strong>You may find out unexpected information about yourself.</strong> This information could be life or career changing and may have economic consequences. What you learn may trouble you and you may not be able to do anything about it.</p>
          <p><strong>The laboratory may be unable to process your sample and the results may have errors.</strong> For example, your sample might not provide enough saliva, your saliva may not contain enough DNA, or the processing may not meet our standards for accuracy. If initial processing doesn’t work for any of these reasons, AxGen will reprocess your same sample at no additional charge. If the second attempt also fails, AxGen will offer to provide another kit to collect another sample. If the second attempt also doesn’t work, the user will be entitled, as the user’s sole remedy, to a refund of the amount paid by the user to AxGen, less shipping and handling, and the user agrees not to submit another sample through a future purchase of the Service (and if the user does so, AxGen will not be required to process or reprocess the sample or provide a refund). In any case, even successful processing may result in a small portion the data being un-interpretable or incorrect, so users are not entitled to refunds in these cases.</p>
          <p><strong>Consult your trainer before you change your behavior on the basis of information from AxGen.</strong> For most common conditions, the known genes are only responsible for a small portion of the risk. Unknown genes, lifestyle and the environment could be far more important. If your Genetic Data do not show an elevated risk, you shouldn’t feel protected. Conversely, if elevated risk is indicated, that doesn’t necessarily mean you will develop applicable condition. And if you believe that your Genetic Data or other information also raises health-related questions for you, make sure to discuss those questions with your health care provider.</p>
          <p><strong>If you choose to share your Genetic Data with others, be careful with whom you share it.</strong> Genetic Data you share could be used against you. Insurance companies or businesses may increasingly request genetic information in the future. <strong>Genetic Data that you share with others—including employers—may be used against you. We assume no responsibility for such consequences.</strong> Before you share your Genetic Data with anybody you may want to consult a lawyer. Please also be aware that if you choose to share Genetic Data with your team physician or another health care provider, it may become part of your medical record. This could make your Genetic Data accessible to other health care providers and/or insurance companies in the future.</p>
          <p><strong>Genetic research is still evolving and incomplete.</strong> Only a small percentage of the data points we measure from your DNA are known to be related to human traits. The research community continues to evolve in the field of genetic research. At any moment in time we only understand part of the picture of the role of genetics. In the future, the scientific community may show previous research to be incomplete or inaccurate.</p>
          <p><strong>We do not provide medical advice.</strong> Our Services are only for informational purposes; we do not provide medical advice. The Services are not intended to be used for any diagnostic, healthcare or medical purpose and are not a substitute for professional medical advice. If you have questions about your health, always seek the advice of your health care provider.</p>
          <p><strong>You recognize that there are other limitations with respect to the Services.</strong> AxGen does not endorse, warranty or guarantee the effectiveness of any programs, tests, products, opinions, or other information that may be on our website. If we provide to you any recommendations that identify for you, based on your Genetic Data and scientific literature or research, potentially actionable information, the information is intended for informational purposes and for discussion with your trainer. We believe that genetics is only part of the picture of any individual's state of being. <strong>You rely on any information provided by AxGen, our employees, contractors, or others appearing on our website at our invitation, solely at your own risk.</strong></p>
          <p>Not all jurisdictions require our Services to be subject to license. We are therefore not universally licensed by all state, federal, or international authorities for genetic testing or for providing training advice. Finally, we may not be able to offer our Services in your jurisdiction because we do not have required licenses.</p>
          <p>You understand, represent, and agree as follows: (a) Any sample you provide is your saliva; if you are agreeing to these Terms on behalf of a person for whom you have legal authorization, you confirm that the sample provided will be the sample of that person; (b) Information you learn from AxGen is not designed for independent action; any information we provide should be confirmed and supplemented by your trainer; (c) You grant AxGen, its contractors, successors and assignees the right to perform genotyping services on the DNA extracted from your saliva sample. You specifically request AxGen to disclose the results of analyses performed on your saliva sample or Genetic Data to you and to anyone authorized by you; (d) You are not an insurance company or an employer seeking to gain information about an insured person or an employee; and (e) You are responsible for all possible consequences resulting from your sharing with others access to your Genetic Data.</p>


          <p><strong><u>Your use of the Services is subject to the following additional restrictions:</u></strong></p>
          <p>You represent, warrant, and agree that you will not contribute any Content or User Submission (each of those terms is defined below) or otherwise use the Services or interact with the Services in a manner that:</p>

          <ol type="a">
            <li>Infringes or violates the intellectual property rights or any other rights of anyone else (including AxGen);</li>
            <li>Violates any law or regulation, including, without limitation, any applicable export control laws;</li>
            <li>Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</li>
            <li>Jeopardizes the security of your AxGen account or anyone else’s (such as allowing someone else to log in to the Services as you);</li>
            <li>Attempts, in any manner, to obtain the password, account, or other security information from any other user;</li>
            <li>Violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
            <li>Runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure);</li>
            <li>“Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated means);</li>
            <li>Copies or stores any significant portion of the Content;</li>
            <li>Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services.</li>
          </ol>

          <p><strong>In case of breach of any one of these representations or warranties or any other provision of these Terms, AxGen has the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof) and you agree to indemnify and hold AxGen, its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to your breach of the representations and warranties set forth in this Section.</strong></p>


          <p><strong><u>What are my rights in AxGen?</u></strong></p>
          <p>The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, User Submissions, and so forth (all of the foregoing, the “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including AxGen’s) rights.</p>
          <p>You understand that AxGen owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services.</p>
          <p>AxGen cannot control any further distribution of Genetic Data that you share publicly. You acknowledge and agree that you are responsible for protecting and enforcing those rights. We have no obligation to do so on your behalf.</p>
          <p>You don’t acquire any rights in any research or commercial products that may be developed by AxGen or its collaborating partners by providing any sample, having your Genetic Data processed or accessing your Genetic Data. You will not receive compensation for any research or commercial products that include or result from your Genetic Data.</p>
          <p>We may establish general practices and limits concerning use of the Services, including without limitation the maximum number of days that Personal Information and Content will be retained by the Services, the amount of storage space allotted on our servers for customers, and limits on accessing the Services in a given period of time. We are not responsible for the deletion of or failure to store any messages, other communications, or other Content; or for the loss of Genetic Data caused by malfunction or destruction of data servers or other unforeseen events. We reserve the right to change these practices and limits in our sole discretion.</p>
          <p>The Services may allow you to copy or download certain Content; please remember that just because this functionality exists, doesn’t mean that all the restrictions above don’t apply – they do!</p>

          <p><strong><u>Do I have to grant any licenses to AxGen or to other users?</u></strong></p>
          <p>Anything you post, upload, share, store, or otherwise provide through the Services—other than Genetic Data and User-Reported Information (as defined in our Privacy Policy)—is your “User Submission.” Some User Submissions are viewable by other users. In order to display your User Submissions on the Services, and to allow other users to enjoy them (where applicable), you grant us certain rights in those User Submissions. Please note that all of the following licenses are subject to our <Link to="/privacy">Privacy Policy</Link> to the extent they relate to User Submissions that are also your personally-identifiable information.</p>
          <p>For all User Submissions, you hereby grant AxGen a license to translate, modify (for technical purposes, for example making sure your content is viewable on an iPhone as well as a computer) and reproduce and otherwise act with respect to such User Submissions, in each case to enable us to operate the Services, as described in more detail below. This is a license only – your ownership in User Submissions is not affected.</p>
          <p>If you store a User Submission in your own personal account, in a manner that is not viewable by any other user except you (a “Personal User Submission”), you grant AxGen the license above, as well as a license to display, perform, and distribute your Personal User Submission for the sole purpose of making that Personal User Submission accessible to you and providing the Services necessary to do so.</p>
          <p>If you share a User Submission only in a manner that only certain specified users can view (for example, a private message to one or more other users)(a “Limited Audience User Submission”), then you grant AxGen the licenses above, as well as a license to display, perform, and distribute your Limited Audience User Submission for the sole purpose of making that Limited Audience User Submission accessible to such other specified users, and providing the Services necessary to do so. Also, you grant such other specified users a license to access that Limited Audience User Submission, and to use and exercise all rights in it, as permitted by the functionality of the Services.</p>
          <p>If you share a User Submission publicly on the Services and/or in a manner that more than just you or certain specified users can view, or if you provide us (in a direct email or otherwise) with any feedback, suggestions, improvements, enhancements, and/or feature requests relating to the Services (each of the foregoing, a “Public User Submission”), then you grant AxGen the licenses above, as well as a license to display, perform, and distribute your Public User Submission for the purpose of making that Public User Submission accessible to all AxGen users and providing the Services necessary to do so, as well as all other rights necessary to use and exercise all rights in that Public User Submission in connection with the Services and/or otherwise in connection with AxGen’s business, provided that AxGen will try to notify you if it uses your Public User Submission for any reason other than displaying it on the Services. Also, you grant all other users of the Services a license to access that Public User Submission, and to use and exercise all rights in it, as permitted by the functionality of the Services.</p>
          <p>You agree that the licenses you grant are royalty-free, perpetual, sublicenseable, irrevocable, and worldwide, provided that when you delete your AxGen account, we will stop displaying your User Submissions (other than Public User Submissions, which may remain fully available) to other users (if applicable), but you understand and agree that it may not be possible to completely delete that content from AxGen’s records, and that your User Submissions may remain viewable elsewhere to the extent that they were copied or stored by other users.</p>
          <p>You understand and agree that AxGen, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so.</p>
          <p>Once you submit your saliva sample, it is processed irreversibly in such a way that we cannot return it to you. Subject to rights we retain as set forth in these Terms, any Genetic Data derived from your saliva remains your information.</p>
          <p>You acquire no rights in any research or commercial products that may be developed by AxGen or our collaborating partners by providing any sample, having your Genetic Data processed, accessing your Genetic Data, or, as identified in our Privacy Policy and Terms of Service, having your Genetic Data shared with or included in information (in aggregate form) to research partners, including commercial partners. You understand that you will not receive compensation for any research or commercial products that include or result from your Genetic Data.</p>


          <p><strong><u>What if I see something on the Services that infringes my copyright?</u></strong></p>
          <p>You may have heard of the Digital Millennium Copyright Act (the “DMCA”), as it relates to online service providers, like AxGen, being asked to remove material that allegedly violates someone’s copyright. We respect others’ intellectual property rights, and we reserve the right to delete or disable Content alleged to be infringing, and to terminate the accounts of repeat alleged infringers; to review our complete Copyright Dispute Policy and learn how to report potentially infringing content, <Link to="/copyright">click here</Link>. To learn more about the DMCA, click here.</p>


          <p><strong>Who is responsible for what I see and do on the Services?</strong></p>
          <p>Any information or content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such content originated, and you access all such information and content at your own risk, and we aren’t liable for any errors or omissions in that information or content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services.</p>
          <p>You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it. You will keep all your registration information accurate and current. You are responsible for all your activity in connection with the Services.</p>
          <p>The Services may contain links or connections to third party websites or services that are not owned or controlled by AxGen. When you access third party websites or use third party services, you accept that there are risks in doing so, and that AxGen is not responsible for such risks. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third party website or service that you visit or utilize.</p>
          <p>AxGen has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third party websites or by any third party that you interact with through the Services. In addition, AxGen will not and cannot monitor, verify, censor or edit the content of any third party site or service. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third party website or service.</p>
          <p>Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that AxGen shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.</p>
          <p>If there is a dispute between participants on this site, or between users and any third party, you agree that AxGen is under no obligation to become involved. In the event that you have a dispute with one or more other users, you release AxGen, its officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. <strong>If you are a California resident, you shall and hereby do waive California Civil Code Section 1542, which says: “A general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which, if known by him or her must have materially affected his or her settlement with the debtor.”</strong></p>


          <p><strong><u>Will AxGen ever change the Services?</u></strong></p>
          <p>We’re always trying to improve the Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We’ll try to give you notice when we make a material change to the Services that would adversely affect you, but this isn’t always practical. Similarly, we reserve the right to remove any Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.</p>
          <p>You understand and acknowledge that we may offer different or additional technology to understand Genetic Data in the future. Your initial purchase of the Services does not entitle you to any such technology without payment. You must pay additional fees to have any future technology we develop analyze your Genetic Data.</p>

          <p><strong><u>What if I want to stop using AxGen?</u></strong></p>
          <p>You’re free to do that at any time, by contacting us at <a href="mailto:contact@axgen.us">contact@axgen.us</a>; please refer to our <Link to="/privacy">Privacy Policy</Link>, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services.</p>
          <p>AxGen is also free to terminate (or suspend access to) your use of the Services or your account, for any reason in our discretion, including your breach of these Terms. AxGen has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms.</p>
          <p>Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account. We will try to provide advance notice to you prior to our terminating your account so that you are able to retrieve any important User Submissions you may have stored in your account (to the extent allowed by law and these Terms), but we may not do so if we determine it would be impractical, illegal, not in the interest of someone’s safety or security, or otherwise harmful to the rights or property of AxGen.</p>
          <p>If you have deleted your account by mistake, contact us immediately at <a href="mailto:contact@axgen.us">contact@axgen.us</a> – we will try to help, but unfortunately, we can’t promise that we can recover or restore anything.</p>
          <p>Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us.</p>


          <p><strong><u>What else do I need to know?</u></strong></p>
          <p><i><u>Warranty Disclaimer.</u></i> Neither AxGen nor its licensors or suppliers makes any representations or warranties concerning any content contained in or accessed through the Services, and we will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services. We (and our licensors and suppliers) make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through the Services. Products and services purchased or offered (whether or not following such recommendations and suggestions) through the Services are provided “AS IS” and without any warranty of any kind from AxGen or others (unless, with respect to such others only, provided expressly and unambiguously in writing by a designated third party for a specific product). YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT: (A) NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM AXGEN OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS AND (B) YOU SHOULD ALWAYS USE CAUTION WHEN GIVING OUT ANY PERSONALLY IDENTIFYING INFORMATION ABOUT YOURSELF OR THOSE FOR WHOM YOU HAVE LEGAL AUTHORITY. AXGEN DOES NOT CONTROL OR ENDORSE ANY ACTIONS RESULTING FROM YOUR PARTICIPATION IN THE SERVICES AND, THEREFORE, AXGEN SPECIFICALLY DISCLAIMS ANY LIABILITY WITH REGARD TO ANY ACTIONS RESULTING FROM YOUR PARTICIPATION IN THE SERVICES. THE SERVICES AND CONTENT ARE PROVIDED BY AXGEN (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
          <p><i><u>Limitation of Liability.</u></i> TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL AXGEN (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR ANY ACTION YOU TAKE BASED ON THE INFORMATION YOU RECEIVE IN THROUGH OR FROM THE SERVICES, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO COMPANY IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (C) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>
          <p><i><u>Indemnity.</u></i> You agree to indemnify and hold AxGen, its affiliates, officers, agents, employees, and partners (collectively the “AxGen Indemnitees”) harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) (“Losses”) arising from or in any way related to any third party claims relating to (a) your use of the Services (including any actions taken by a third party using your account), and (b) your violation of these Terms.</p>
          <p>If you have submitted a saliva sample or otherwise provided your Genetic Data, you agree to indemnify and hold the AxGen Indemnitees harmless from and against any and all Losses arising from or in any way related to the use or disclosure of any information obtained from genotyping such saliva sample and/or analyzing such Genetic Data, which is disclosed to you consistent with our Privacy Policy or results from anything we provide. In addition, if you choose to provide your Genetic Data to third parties, whether individuals to whom you facilitate access, intentionally or inadvertently, or to third parties for diagnostic or other purposes, you agree to indemnify and hold the AxGen Indemnitees harmless from and against any and all Losses arising from or in any way related to such disclosure or use of your Genetic Data.</p>
          <p><i><u>Assignment.</u></i> You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without AxGen’s prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</p>
          <p><i><u>Choice of Law; Arbitration.</u></i> These Terms are governed by and will be construed under the laws of the State of California, without regard to the conflicts of laws provisions thereof. Any dispute arising from or relating to the subject matter of these Terms shall be finally settled in San Francisco County, California, in English, in accordance with the Streamlined Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc. ("JAMS") then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction. Notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction. For all purposes of these Terms, the parties consent to exclusive jurisdiction and venue in the state or federal courts located in, respectively, San Francisco County, California, or the Northern District of California. <strong>Any arbitration under these Terms will take place on an individual basis: class arbitrations and class actions are not permitted. YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS, YOU AND COMPANY ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</strong></p>
          <p><i><u>Miscellaneous.</u></i> You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided that AxGen may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. You and AxGen agree that these Terms are the complete and exclusive statement of the mutual understanding between you and AxGen, and that it supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of AxGen, and you do not have any authority of any kind to bind AxGen in any respect whatsoever. You and AxGen agree there are no third party beneficiaries intended under these Terms.</p>
          <br/>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default TOSPage;
