import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import Chart from 'react-apexcharts';
import { hexMixer } from '../utils/colors';

const chartConfig = require('../../json/bmd-config.json');

const percentFormat = (val) => {
  return (val > 1 ? '+' : '') + (val).toString() + '%';
};

const foldFormat = (val) => {
  return (val > 1 ? '+' : '') + (val).toString() + ' fold';
};

class BMDGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = { chartData: false };
  };

  componentDidMount() {
    if (this.props.graphType === 'tscore') this.prepareTScoreGraph();
    if (this.props.graphType === 'fracture') this.prepareFractureGraph();
    if (this.props.graphType === 'osteo') this.prepareOsteoGraph();
    if (this.props.graphType === 'osteo2') this.prepareOsteoGraph2();
  };

  resetGraphTooltips() {
    // Set BMD Graph Title
    Array.from(document.querySelectorAll('.fracture-graph .apexcharts-title-text')).map(ele => {
      ele.setAttribute('data-tip', 'Shows your risk of fracture compared to the average; e.g. +55% means you have 55% increased risk and -45% means you have a 45% decreased risk of fracture.');
      return ele;
    });

    Array.from(document.querySelectorAll('.t-graph .apexcharts-title-text')).map(ele => {
      ele.setAttribute('data-tip', 'T-Score levels measured using quantitative ultrasound at the heel.');
      return ele;
    });

    Array.from(document.querySelectorAll('.osteo-graph .apexcharts-title-text')).map(ele => {
      ele.setAttribute('data-tip', 'Shows your risk of osteoporosis compared to the average; e.g.18 fold means you have an 18 increased risk and 0.18 means you have an 0.18 fold risk of osteoporosis compared to the average (decreased risk).');
      return ele;
    });

    // Set x-axis
    Array.from(document.getElementsByClassName('apexcharts-xaxis-title-text')).map(ele => {
      ele.setAttribute('data-tip', 'Low BMD scores correlate with an increased risk for stress fracture/fractures. High BMD scores correlate with decreased risk of fractures. Your BMD score is calculated using a genetic algorithm comprised of 22,886 DNA polymorphisms.');
      ele.setAttribute('data-place', 'bottom');
      return ele;
    });

    // Set y-axis
    Array.from(document.getElementsByClassName('apexcharts-yaxis-title-text')).map(ele => {
      ele.setAttribute('data-tip', 'Grey bars show the % population contained in each bin.');
      ele.setAttribute('data-place', 'right');
      return ele;
    });

    ReactTooltip.rebuild();
  };

  prepareFractureGraph() {
    const { bmdIndex, width } = this.props;
    const chartData = JSON.parse(JSON.stringify(chartConfig));
    const { setupData } = chartData;
    const { relativeFractureRisk: relRisk } = setupData;
    const riskPercent = (relRisk[bmdIndex] - Math.min(...relRisk)) * 100.0 /
      (Math.max(...relRisk) - Math.min(...relRisk));
    const userRiskColor = hexMixer(
      chartConfig.design.colors[0],
      chartConfig.design.colors[1],
      riskPercent
    );

    // Setup x-axis
    chartData.options.labels = setupData.avgBMD;
    chartData.options.xaxis.title.offsetY = width < 768 ? -22 : -15;

    // Setup y-axis
    chartData.series[0].data = setupData.relativeFractureRisk;
    chartData.series[1].data = setupData.binSize;
    chartData.options.yaxis[0].labels.formatter = (val) => percentFormat(val);

    // Create (x,y) point on fracture risk line
    chartData.options.annotations.points[0].x = setupData.avgBMD[bmdIndex];
    chartData.options.annotations.points[0].y = setupData.relativeFractureRisk[bmdIndex];
    chartData.options.annotations.points[0].marker.strokeColor = userRiskColor;

    // Create horizontal line for user risk
    chartData.options.annotations.yaxis[1].y = setupData.relativeFractureRisk[bmdIndex];
    chartData.options.annotations.yaxis[1].borderColor = userRiskColor;
    chartData.options.annotations.yaxis[1].label.borderColor = userRiskColor;
    chartData.options.annotations.yaxis[1].label.style.background = userRiskColor;
    chartData.options.annotations.yaxis[1].label.text = percentFormat(setupData.relativeFractureRisk[bmdIndex]) + " (you)";

    // Setup styling
    chartData.options.colors = [chartConfig.design.colors[1], "transparent"];
    chartData.options.fill.gradient.gradientToColors = [chartConfig.design.colors[0]];

    // Setup tooltip
    // determines position for mobile or desktop version
    width < 768 ? (
      chartData.options.tooltip.fixed = { enabled: true, position: 'topRight', offsetX: 10, offsetY: -42 }
    ) : (chartData.options.tooltip.fixed = { enabled: false });
    // make tooltip
    this.generateCustomTooltips('fracture', chartData);

    this.setState({ chartData });
  };

  prepareOsteoGraph() {
    const { bmdIndex, width } = this.props;
    const chartData = JSON.parse(JSON.stringify(chartConfig));
    const { setupData } = chartData;
    const { relativeOsteoRisk: relRisk } = setupData;
    
    const riskPercent = (relRisk[bmdIndex] - Math.min(...relRisk)) * 100.0 /
      (Math.max(...relRisk) - Math.min(...relRisk));
    const userRiskColor = hexMixer(
      chartConfig.design.colors[0],
      chartConfig.design.colors[1],
      riskPercent
    );

    // Setup x-axis
    chartData.options.labels = setupData.avgBMD;
    chartData.options.xaxis.title.offsetY = width < 768 ? -22 : -15;

    // Setup y-axis
    chartData.series[0].data = setupData.relativeOsteoRisk;
    chartData.series[1].data = setupData.binSize;
    chartData.options.yaxis[0].labels.formatter = (val) => foldFormat(val);
    chartData.options.yaxis[0].max = 18;
    chartData.options.yaxis[0].min = 0;

    // Create (x,y) point on osteo risk line
    chartData.options.annotations.points[0].x = setupData.avgBMD[bmdIndex];
    chartData.options.annotations.points[0].y = setupData.relativeOsteoRisk[bmdIndex];
    chartData.options.annotations.points[0].marker.strokeColor = userRiskColor;

    // Create horizontal line for user risk
    chartData.options.annotations.yaxis[1].y = setupData.relativeOsteoRisk[bmdIndex];
    chartData.options.annotations.yaxis[1].borderColor = userRiskColor;
    chartData.options.annotations.yaxis[1].label.borderColor = userRiskColor;
    chartData.options.annotations.yaxis[1].label.style.background = userRiskColor;
    // chartData.options.annotations.yaxis[1].label.text = foldFormat(setupData.relativeOsteoRisk[bmdIndex]) + " (you)";
    chartData.options.annotations.yaxis[1].label.text = " (you)";

    // Setup styling
    chartData.options.title.text = 'Osteoporosis Risk'
    chartData.options.colors = [chartConfig.design.colors[1], "transparent"];
    chartData.options.fill.gradient.gradientToColors = [chartConfig.design.colors[0]];

    // Setup tooltip
    // determines position for mobile or desktop version
    width < 768 ? (
      chartData.options.tooltip.fixed = { enabled: true, position: 'topRight', offsetX: 10, offsetY: -42 }
    ) : (chartData.options.tooltip.fixed = { enabled: false });
    // make tooltip
    this.generateCustomTooltips('osteo', chartData);

    this.setState({ chartData });
  };

  prepareOsteoGraph2() {
    const { bmdIndex, width } = this.props;
    const chartData = JSON.parse(JSON.stringify(chartConfig));
    const { setupData } = chartData;
    const { relativeOsteoRisk: relRisk } = setupData;
    const riskPercent = (relRisk[bmdIndex] - Math.min(...relRisk)) * 100.0 /
      (Math.max(...relRisk) - Math.min(...relRisk));
    const userRiskColor = hexMixer(
      chartConfig.design.colors[0],
      chartConfig.design.colors[1],
      riskPercent
    );

    // Setup x-axis
    chartData.options.labels = setupData.avgBOG;
    chartData.options.xaxis.title.offsetY = width < 768 ? -22 : -15;

    // Setup y-axis
    chartData.series[0].data = setupData.relativeOsteoRisk;
    chartData.series[1].data = setupData.binSize;
    chartData.options.yaxis[0].labels.formatter = (val) => foldFormat(val);
    chartData.options.yaxis[0].max = 18;
    chartData.options.yaxis[0].min = 0;

    // Create (x,y) point on osteo risk line
    chartData.options.annotations.points[0].x = setupData.avgBOG[bmdIndex];
    chartData.options.annotations.points[0].y = setupData.relativeOsteoRisk[bmdIndex];
    chartData.options.annotations.points[0].marker.strokeColor = userRiskColor;

    // Create horizontal line for user risk
    chartData.options.annotations.yaxis[1].y = setupData.relativeOsteoRisk[bmdIndex];
    chartData.options.annotations.yaxis[1].borderColor = userRiskColor;
    chartData.options.annotations.yaxis[1].label.borderColor = userRiskColor;
    chartData.options.annotations.yaxis[1].label.style.background = userRiskColor;
    // chartData.options.annotations.yaxis[1].label.text = foldFormat(setupData.relativeOsteoRisk[bmdIndex]) + " (you)";
    chartData.options.annotations.yaxis[1].label.text = " (you)";

    // Setup styling
    chartData.options.title.text = 'Osteoporosis Risk (adjusted with clinical risk factors)'
    chartData.options.colors = [chartConfig.design.colors[1], "transparent"];
    chartData.options.fill.gradient.gradientToColors = [chartConfig.design.colors[0]];

    // Setup tooltip
    // determines position for mobile or desktop version
    width < 768 ? (
      chartData.options.tooltip.fixed = { enabled: true, position: 'topRight', offsetX: 10, offsetY: -42 }
    ) : (chartData.options.tooltip.fixed = { enabled: false });
    // make tooltip
    this.generateCustomTooltips('osteo2', chartData);

    this.setState({ chartData });
  };

  prepareTScoreGraph() {
    const { bmdIndex, width } = this.props;
    const chartData = JSON.parse(JSON.stringify(chartConfig));
    const { setupData } = chartData;
    const { avgTScore } = setupData;
    const riskPercent = (avgTScore[bmdIndex] - Math.min(...avgTScore)) * 100.0 /
      (Math.max(...avgTScore) - Math.min(...avgTScore));
    const userRiskColor = hexMixer(
      chartConfig.design.colors[1],
      chartConfig.design.colors[0],
      riskPercent
    );

    // Setup x-axis
    chartData.options.labels = setupData.avgBMD;
    chartData.options.xaxis.title.offsetY = width < 768 ? -22 : -15;

    // Setup y-axis
    chartData.series[0].data = setupData.avgTScore;
    chartData.series[1].data = setupData.binSize;
    chartData.options.yaxis[0].max = 1;
    chartData.options.yaxis[0].min = -1.5;

    // Create (x,y) point on fracture risk line
    chartData.options.annotations.points[0].x = setupData.avgBMD[bmdIndex];
    chartData.options.annotations.points[0].y = avgTScore[bmdIndex];
    chartData.options.annotations.points[0].marker.strokeColor = userRiskColor;

    // Create horizontal line for user risk
    chartData.options.annotations.yaxis[1].y = avgTScore[bmdIndex];
    chartData.options.annotations.yaxis[1].borderColor = userRiskColor;
    chartData.options.annotations.yaxis[1].label.borderColor = userRiskColor;
    chartData.options.annotations.yaxis[1].label.style.background = userRiskColor;
    chartData.options.annotations.yaxis[1].label.text = (setupData.avgTScore[bmdIndex]) + " (you)";
    chartData.options.annotations.yaxis[1].label.offsetX = width < 768 ? 20 : 30;

    // Create horizontal line for average score
    chartData.options.annotations.yaxis[0].y = avgTScore[7];
    chartData.options.annotations.yaxis[0].label.text = 'Avg Score';

    // Setup styling
    chartData.options.title.text = 'T-Score'
    chartData.options.colors = [chartConfig.design.colors[0], "transparent"];
    chartData.options.fill.gradient.gradientToColors = [chartConfig.design.colors[1]];

    // Setup tooltip
    // determines position for mobile or desktop version
    width < 768 ? (
      chartData.options.tooltip.fixed = { enabled: true, position: 'topRight', offsetX: 10, offsetY: -42 }
    ) : (chartData.options.tooltip.fixed = { enabled: false });
    // make tooltips
    this.generateCustomTooltips('tscore', chartData);

    this.setState({ chartData });
  };

  // function adds tooltips to the graph
  generateCustomTooltips(graphType, chartData) {
    chartData.options.tooltip.custom = function ({ series, seriesIndex, dataPointIndex, w }) {
      const percentile = series[1].reduce((agg, v, i) => (i < dataPointIndex) ? agg + v : agg, 0.00);
      const riskColor = hexMixer(
        chartConfig.design.colors[1],
        chartConfig.design.colors[0],
        percentile
      );

      //YLabel determines the words used in the second row of the tool tip.
      //graphType determines how the numbers are formatted in the second row of the too tip: plain, percent or fold.
      const getYLabel = (graphType) => {
        let yLabel;

        if (graphType === 'tscore') {
          yLabel = 'T-Score';
        } else if (graphType === 'fracture') {
          yLabel = 'Fracture Rate';
        } else {
          yLabel = 'Osteoporosis Risk';
        }
          return yLabel;
      }

      const yLabel = getYLabel(graphType)

      const getScoreRange = (graphType, dataPointIndex, series) => {

        if (graphType === 'tscore' && dataPointIndex === 0) {
          return '<' + series[0][dataPointIndex];
        } 
        if (graphType === 'tscore' && dataPointIndex === w.config.labels.length - 1) {
          return '>' + percentFormat(series[0][dataPointIndex]);
        } 
        if (graphType === 'tscore') {
          return series[0][dataPointIndex] + ' to ' + series[0][dataPointIndex + 1];
        } 
        if (graphType === 'fracture' && dataPointIndex === 0) {
          return '>' + percentFormat(series[0][dataPointIndex]);
        } 
        if (graphType === 'fracture' && dataPointIndex === w.config.labels.length - 1) {
          return '<' + percentFormat(series[0][dataPointIndex]);
        } 
        if (graphType === 'fracture') {
          return percentFormat(series[0][dataPointIndex]) + ' to ' + percentFormat(series[0][dataPointIndex + 1]);
        } 
        if (graphType === 'osteo' && dataPointIndex === 0) {
          return '>' + foldFormat(series[0][dataPointIndex]);
        } 
        if (graphType === 'osteo' && dataPointIndex === w.config.labels.length - 1) {
          return '<' + foldFormat(series[0][dataPointIndex]);
        } 
        if (graphType === 'osteo') {
          return foldFormat(series[0][dataPointIndex]) + ' to ' + foldFormat(series[0][dataPointIndex + 1]);
        } 
        if (graphType === 'osteo2' && dataPointIndex === 0) {
          return '>' + foldFormat(series[0][dataPointIndex]);
        } 
        if (graphType === 'osteo2' && dataPointIndex === w.config.labels.length - 1) {
          return '<' + foldFormat(series[0][dataPointIndex]);
        } 
        if (graphType === 'osteo2') {
          return foldFormat(series[0][dataPointIndex]) + ' to ' + foldFormat(series[0][dataPointIndex + 1]);
        } 
      }

      let geneticScoreRange, scoreRange;
      if (dataPointIndex === 0) {  //dataPointIndex is the first in the list
        geneticScoreRange = '<' + w.config.labels[dataPointIndex + 1]; // top row in tool tip has < lowest value
        scoreRange = getScoreRange(graphType, dataPointIndex, series);
      } else if (dataPointIndex === w.config.labels.length - 1) { //dataPointIndex is the last value in the list
        geneticScoreRange = '>' + w.config.labels[dataPointIndex];
        scoreRange = getScoreRange(graphType, dataPointIndex, series);
      } else { //dataPointIndex is in the middle somewhere
        geneticScoreRange = w.config.labels[dataPointIndex] + ' to ' + w.config.labels[dataPointIndex + 1];
        scoreRange = getScoreRange(graphType, dataPointIndex, series);
      };

      return '<div class="bog-graph-tooltip">' +
        '<div class="tooltip-header" style="background-color:' + riskColor + ';">BMD Genetic Score Range: ' + geneticScoreRange + '</div>' +
        `<div>${yLabel} Range: ` + scoreRange + '</div>' +
        '<div>Percentile Range: ' + percentile.toFixed(1) + ' to ' + (percentile + series[1][dataPointIndex]).toFixed(1) + '%</div>' +
        '</div>';
    };
  };

  componentDidUpdate(prevProps) {
    if (this.props.bmdIndex !== prevProps.bmdIndex) {
      if (this.props.graphType === 'tscore') this.prepareTScoreGraph();
      if (this.props.graphType === 'fracture') this.prepareFractureGraph();
      if (this.props.graphType === 'osteo') this.prepareOsteoGraph();
      if (this.props.graphType === 'osteo2') this.prepareOsteoGraph2();
    }
    this.resetGraphTooltips();
  }
  render() {
    let { chartData = {} } = this.state;
    const { graphType } = this.props;

    return (
      <div className={classNames("graph-container bmd-graph",
        { "t-graph": graphType === 'tscore', "fracture-graph": graphType === 'fracture', "osteo-graph": graphType === 'osteo', "osteo-graph": graphType === 'osteo2' }
      )}>
        {!chartData ? null : (
          <div>
            <Chart options={chartData.options} series={chartData.series} type="line" height="350" />
            <ReactTooltip place="top" className="tooltip-axgen" effect="solid" />
          </div>
        )}

      </div>
    )
  }
}

export default BMDGraph;
