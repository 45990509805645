import Footer from '../components/footer';
import Navbar from '../components/navbar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone'
import * as Actions from '../actions';
import Alerts from '../components/alerts';


// contains the fileDropContents of the file upload element
const fileDropContents = {
    'null': {
      'title': 'Select File for Upload',
      'container-class': '',
      'icon': 'fa-cloud-upload',
      'subtitle': 'Click or Drag & Drop to select file'
    },
    'success': {
      'title': 'Ready for Upload',
      'container-class': 'active',
      'icon': 'fa-check-circle',
      'subtitle': 'Click or Drag & Drop to change file'
    },
    'failed': {
      'title': 'Invalid File',
      'container-class': 'failed',
      'icon': 'fa-times-circle',
      'subtitle': 'Click or Drag & Drop to change file'
    }
};


  const StrengthPage = () => {
    
    // global state
    const { session, strength, width, alerts } = useSelector((state) => ({
        session: state.session,
        strength: state.strength,
        width: state.ui.width,
        alerts: state.alerts
    }));


    // local states
    const [disabledSubmit, disable] = useState(false);
    const [fileState, uploadFile] = useState(null);
    const [athlete, selectAthlete] = useState(null);
    const [fileStatus, setStatus] = useState(null); //handle keying into fileDropContents
    const [btnText, setText] = useState("UPLOAD PLAN"); //handles the text on the button


    // helper functions

    // sets the athlete one selects using the dropdown to the local state
    const _setAthlete = (e) => {
        e.preventDefault();
        selectAthlete(e.target.value);
    };

    // FileReader is an api used to read file content from the user's (remote) system in secure ways
    const _uploadFile = (file) => {
        const reader = new FileReader();
        
        // runs a callback once the reader loads
        // callback tells the reader that the content of the file will be the result of the reader
        reader.onload = () => file['content'] = reader.result;

        // this conditional sets the reader.result to a base64 string from the pdf, which becomes the content of the file 
        // setStatus tells the Dropzone element how to key into fileDropContents, so it can decide what to render
        if (file.type === "application/pdf") {
            setStatus('success');
            reader.readAsDataURL(file);
        } else {
            setStatus('failed');
        };

        // file is set to local upload state regardless of type, however once is not able to submit non-pdf files
        uploadFile(file);
    };

    // function programmatically determines the content a strength-user views on the page and returns that content
    const _createPDF = () => {
        // grabs the typeof strength.workoutPlan, the two possibilites are 'string' for string types and 'object' (typeof(null) ='object' in js. this strange result is an un-fixable bug rooted in how values were stored in the first version of js. any attempts to fix this would break nearly all existing js code)
        if (strength.workoutPlan !== null) {
            if (strength.workoutPlan !== "") {
                return (
                    <div className="card p-3 mt-2 mb-3">
                        <p>Here is your strength training plan. The blue text for each exercise is clickable and opens up a YouTube video showing how the exercise is done.</p>
                        <p>Please <a href="mailto:chris@axgen.us">email</a> Chris Lee with any questions.</p>
                        <div className="strength-pdf-container">
                            <object type="application/pdf" data={strength.workoutPlan}>
                                Your browser does not support PDFs. Please click <a href={strength.workoutPlanDownload} download="my-workout-plan">here</a> to download your workout plan.
                            </object>
                        </div>
                    </div>
                );
            } else {
                return <Alerts tag="upload-strength" level="error"/>;
            };
        } else {
            return null;
        };        
    };

    // generates the athletes dropdown for the form, with ids used as values
    const _generateAthletes = () => (
        strength.athletes.map((athlete, idx) => (
            <option key={idx} value={athlete.id}>{athlete.name} ({athlete.email})</option>
        ))
    );


    // main functions

    // submits the form
    const submitForm = (e) => {
        e.preventDefault();
        disable(true);
        setText("UPLOADING...");
        Actions.clearAlerts();

        if (fileState && fileState['content']) {
            Actions.uploadStrengthPlan(fileState['content'], fileState['type'], athlete);
        } else {
          Actions.addAlert('upload-strength', 'error', 'No valid file selected. Files must be PDFs.');
        };
    };
    
    // fires on mount, grabs the strength plan for users
    // loads  users for admins and consultants
    // other conditional is meant to load strength plans for non admins or consultants 
    // this above also clears the strength.workoutPlan state, in essence, we encountered a bug where an expired presigned url was saved in the global state even when a new one was created on the strength page. the result was the pdf not displaying. this is corrected by clearing the strength.workoutPlan state on mount
    useEffect(() => {
        if (session.userRole === 'axgen-admin' || session.userRole === 'axgen-consultant') {
            Actions.loadMyAthletes();
        } else {
            Actions.clearStrengthPlan();
            Actions.getStrengthPlan();
        };
    }, []);

    // adds alert once the workoutPlan loads from the backend. if it is an empty string, that is, the user has no plan, they see an alert. also clears alerts before adding one to prevent alerts from stacking
    useEffect(() => {
        if (strength.workoutPlan === "" && strength.workoutPlanDownload === "") {
            Actions.clearAlerts();
            Actions.addAlert('upload-strength', 'error', 'You do not have a workout plan yet. Please be be patient while we create your personalized plan.');
        };
    }, [strength]);

    // undisables submit only when the file type is correct to prevent erronous submissions
    useEffect(() => {
        if (fileState) {
            if (fileState.type === 'application/pdf') {
                disable(false);
                setText("UPLOAD PLAN");
            } else {
                disable(true);
                setText("INVALID FILE TYPE");
            };
        };
    }, [fileState]);

    // fires if there are alerts
    // if alerts come in, whether error or success, the button is undisabled and changes the button text are made
    useEffect(() => {
        if (Object.keys(alerts).length !== 0) {
            disable(false);
            setText("UPLOAD PLAN");
        };
    }, [alerts]);


    // presentational elements

    const myAthletes =  strength.athletes && strength.athletes.length > 0 ? (
        _generateAthletes()
    ) : (
        null
    );
    // the following handles the dropdown element
    const fileData = fileDropContents[fileStatus];
    const fileUploadEle = 
        <div className="my-4 file-upload">
            <Dropzone onDrop={acceptedFiles => _uploadFile(acceptedFiles[0])}>
            {({getRootProps, getInputProps}) => (
                <div className={`card-container ${fileData['container-class']}`} {...getRootProps()}>
                    <input {...getInputProps()} accept=".pdf"/>
                    <b>{fileData['title']} {fileState !== null && '('+fileState['name']+')'}</b>
                    <div className={`fal fa-2x d-block my-2 ${fileData['icon']}`} />
                    <small>{fileData['subtitle']}</small>
                </div>
            )}
            </Dropzone>
        </div>

    //  the following handles the actual upload form
    const uploadPadding = width < 768 ? "": " p-3"; //padding of the form
    const uploadForm = 
        <div className={`mt-2 mb-3${uploadPadding}`}>
            <div className="row d-flex justify-content-center">
                <div className="col-md-8 col-lg-6 col-sm-12">
                    <form className="card form pt-3" onSubmit={(e) => submitForm(e)}>
                        <p>You can upload files to your athletes using this form. Files must be PDFs. All of the listed athletes have already purchased strength consultations.</p>
                        <p>Note that whenever you upload a file here, if a user already has a file in our system, it will be overwritten.</p>
                        <div>
                            <label>Athlete</label>
                            <select
                                onChange={(e) => _setAthlete(e)}
                                className="form-control"
                                defaultValue=""
                                required
                            >
                                <option value="" disabled>Select Athlete</option>
                                { myAthletes }
                            </select>
                            <hr className="my-0"></hr>
                        </div>
                        { fileUploadEle }
                        <input
                            type="submit"
                            className="form-control btn btn-axgen mb-0"
                            value={btnText}
                            disabled={disabledSubmit}
                        />
                        <Alerts tag='upload-strength' level="success"/>
                        <Alerts tag='upload-strength' />
                    </form>
                </div>
            </div>
        </div>


    // handles the conditional rendering of content based on a user's role
    // admins and consultants can see the form and upload plans, strength-users can see the plans, and anyone else can see the else case
    let strengthView;
    if (session.userRole === 'strength-user') {
        let pdfContent = _createPDF(); //function programmatically determines what a strength user can see
        strengthView = 
            <div>
                <div className="strength-card card p-3 mt-2 mb-3">
                    <p className="mb-0">Here you can view and download a strength workout customized to your demographics, injury history, and genetics, courtesy of our AxGen strength coach, Chris Lee.</p>
                </div>
                <div className="admin-section mb-3">
                    <div className='title-break mb-4'>
                        <span>My Workout</span>
                    </div>
                    { pdfContent }
                </div>
            </div>
    } else if (session.userRole === 'axgen-consultant' || session.userRole === 'axgen-admin') {
        strengthView = 
            <div>
                <div className="admin-section mb-3">
                    {uploadForm}
                </div>
            </div>
    } else {
        strengthView = 
            <div>
                <div className="card p-3 mt-2 mb-3">
                    <p>Do you want a strength workout customized to your demographics, injury history, and genetics, courtesy of our AxGen strength coach, Chris Lee?</p>
                    <p className="mb-0">If so, you can purchase one <a href="/consultations">here</a>.</p>
                </div>
            </div>
    };

    return (
        <div>
            <Navbar />
            <div className="container mt-4">
                <div className="buy-header text-center mb-2">
                    <h2>My Strength</h2> 
                </div>
                { strengthView }
            </div>
            <br/><br/>
            <Footer />
        </div>
    );
};

export default StrengthPage;