import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const middleware = [promise, thunk];
let store = createStore(reducers, applyMiddleware(...middleware));
// Uses Redux Dev Tools when in developer mode.
if (process.env.NODE_ENV === 'development') {
    store = createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)));
}

export default store;