import React from 'react';

class PhosIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Phosphorus</h2>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
        <img alt="" className="overview-img" src="/assets/imgs/results/content/phos.jpg" height={200}/>   
        <p>Phosphorus is an essential mineral that is present in many foods and available as a dietary supplement. Next to calcium, phosphorus is the second most plentiful mineral in your body. It is a component of bones and teeth, and is part of most macromolecules such as DNA, RNA, proteins and lipids.</p>
        <p>Many different types of foods contain phosphorus, including dairy products, meats and poultry, fish, eggs, nuts, legumes, vegetables, and grains. Phosphate additives (e.g., phosphoric acid, sodium phosphate, and sodium polyphosphate) are present in many foods, especially processed food products. These additives are used for preserving moisture or color and enhancing and stabilizing frozen foods.</p>
        <p>Phosphorus is available in dietary supplements containing only phosphorus, supplements containing phosphorus in combination with other ingredients, and a few multivitamin/multimineral products.</p>
        </div>
        <div className='title-break'>
          <span>Recommended Daily Allowances for Phorphorus</span>
        </div>        
        <div className="card">
        <p>Most Americans consume more than the recommended amounts of phosphorus, which is 700 mcg. The average daily phosphorus intake from both foods and supplements is 1,301 mg for women and 1,744 mg for men.</p>
        </div>
        <div className='title-break'>
          <span>Symptoms of having either too much or too little phosphorus</span>
        </div>  
        <div className="card">
        <p>Phosphorus levels that are too high or too low are rare, but both can cause medical complications.  Certain health conditions (such as diabetes and alcoholism) or medications (such as some antacids) can cause phosphorus levels in your body to drop too low.  Symptoms of low phosphorus include joint or bone pain, loss of appetite, and fatigue. Too much phosphorus can cause diarrhea, as well as a hardening of organs and soft tissue.</p>
        </div>
      </div>
    )
  }
}

export default PhosIntro;
