import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// forceWhite is passed in to render white text, this is done on the auth pages due to the background
const Footer = ({ forceWhite = false }) => {
  const forceWhiteDiv = forceWhite ? "footer-top-force-white" : "footer-top";
  const forceWhiteUl = forceWhite ? "footer-ul-force-white" : "footer-ul";
  
  return (
    <footer className="app-footer">
      <div className="footer-content">
        <div className={forceWhiteDiv}>© 2024 AxGen, Inc.</div>
        <ul className={forceWhiteUl}>
          <li><Link to="/tos">Terms of Use</Link></li>
          <li><Link to="/privacy">Privacy</Link></li>
          <li><a href="mailto:contact@axgen.us">Contact Us</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
