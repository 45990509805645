import React from 'react';


export const IronIntro = () => {
  return (
    <div>
      <div className="title-margins">
        <h2>Introduction to Iron</h2>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
        <img src="/assets/imgs/results/content/iron.jpeg" height={200} className="overview-img" alt=""/>
        <p>Iron is one of the most critical minerals for sports performance. Iron is a component of hemoglobin, myoglobin, cytochromes, and various enzymes in the muscle cells, all of which are involved in the transport and metabolism of oxygen for aerobic energy production during endurance exercise.  Iron deficiency is a condition resulting from too little iron in the body. Iron deficiency is the most common nutritional deficiency and the leading cause of anemia in the United States.  Iron deficiency can hinder an athlete’s performance. It is estimated that 10% of male athletes, and 20% of female athletes are iron deficient.</p>
        <p>Iron deficiency without anemia: This refers to an iron deficiency that is mild enough that it does not cause anemia.  If a person is otherwise healthy, symptoms seldom appear before the hemoglobin in the blood drops below 10 g/dL (generally accepted as the marker for mild or moderate anemia).  As iron stores are depleted, signs that your body is low in iron may develop. These may include: chronic fatigue/tiredness, dizziness, weakness, and headaches.</p>
        <p>Several studies have shown that untrained, iron-depleted, non-anemic women who received an iron supplement during exercise training had significantly greater increases in VO2max and 15-kilometer cycling endurance performance as compared to the placebo group, suggesting that iron deficiency without anemia impairs favorable adaptation to aerobic exercise.</p>
        <p>Iron-deficiency anemia occurs when the body is depleted of iron to the point where there is a deficiency of red blood cells or of hemoglobin in the blood.  Iron deficiency anemia impairs muscular performance. The prevalence of iron deficiency anemia is likely to be higher in athletic groups, especially in younger female athletes, than in healthy sedentary individuals.  Possible causes of iron loss in athletic individuals include inadequate dietary sources of iron, myoglobin leakage, gastrointestinal losses, sweat losses, and heavy menstrual losses.  Such losses could eventually lead to iron deficiency anemia. If an athlete fatigues easily or does not perform up to expectations, iron deficiency may be suspected.</p>
        <p className="mb-0">Hepcidin is the main iron regulatory hormone. When iron levels are high, the synthesis of hepcidin increases and the release of iron from stores is decreased. Conversely, when iron stores drop, the synthesis of hepcidin is downregulated and cells release more iron.</p>
      </div>
      <div className='title-break'>
        <span>Iron Physiology</span>
      </div>
      <div className="card">
        <p>Iron is homeostatically distributed to hemoglobin in erythrocytes and stored in the liver (predominantly involving ferritin) through a network of local and systemic regulators. Systemic regulation relies on the hormone hepcidin and the iron exporter ferroportin. Local regulation implies iron-regulatory proteins that bind iron-responsive elements in messenger RNAs (<a href="https://pubmed.ncbi.nlm.nih.gov/21149283/">Oexle et al., 2011</a>).</p>
        <p>Iron is released into circulation from duodenal enterocytes, which absorb 1-2 mg of dietary iron per day, and macrophages, which internally recycle 20-25 mg of iron from older erythrocytes. Hepatocytes are the major site of iron storage and they secrete the regulatory hormone hepcidin, which controls plasma iron levels by binding to the iron exporter ferroportin on the surface of iron-releasing cells and triggering its degradation.</p>
        <p className="mb-0">Macrophages phagocytose aged or damaged erythrocytes and catabolize heme using hemoxygenase. Export of ferrous iron from macrophages occurs via ferroportin, whose expression in macrophages is regulated at multiple levels: ferroportin transcription is induced by erythrophagocytosis and heme iron, its translation is regulated by the IRE/IRP system, and its protein stability by hepcidin. Ferroportin-mediated iron export is coupled to the function of the multicopper oxidase ceruloplasmin, a protein synthesized and secreted by the liver. Ceruloplasmin-deficient mice and humans show hepatocyte and macrophage iron accumulation.</p>
      </div>
    </div>
  );
}

export default IronIntro;
