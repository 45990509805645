import React from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { loadResultsThunk } from '../actions';


class RiskGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { myUploadID } = this.props;
    const paths = window.location.pathname.split('/');
    const pathUploadID = paths.reduce((agg, path) => {
      return agg || parseInt(path, 10);
    }, false);
    const uploadID = (pathUploadID) ? pathUploadID : myUploadID;

    if (!(uploadID in this.props.results.uploads)) {
      this.props.loadResults(uploadID);
    }
    
    this.setState({uploadID});
  }

  componentDidUpdate(prevProps) {
    const { uploadID } = this.state;
    const { tag, results, width } = this.props; 
    const xAxisTxt = 'Relative risk';
    const avgRisk = 1.0;
    const relRisk = avgRisk + (((((results.uploads || {})[uploadID] || {})['results'] || {})[tag] || {}).relativeRisk || 0.0); // relative to an average risk of 1.0x
    const graphMax = relRisk > 2.0 ? relRisk + 1 : 2.0; //it is very rare for a relative risk to be greater than 2.0, but if that occurs, the graph dynamically scales
    const formatter = (val, opts) => val.toFixed(2) + 'x'; // the formatter for the data
    const tickAmt = width < 768 ? 4 : 10;

    if (results.uploads !== prevProps.results.uploads || !('options' in this.state)) {
      if (!(tag in results.snps)) return;

      this.setState({
          options: {
            plotOptions: {
              bar: {
                horizontal: true
              }
            },
            xaxis: {
              categories: ['You', 'Average'],
              min: 0.0,
              max: graphMax,
              tickAmount: tickAmt,
              title: {
                text: xAxisTxt,
                offsetY: width < 768 ? 27 : 0, //prevents overlap on mobile devices
                style: {
                  cssClass: 'apexcharts-axis-titles'
                }
              },
              labels: {
                style: {
                  cssClass: 'apexcharts-axis-labels'
                },
                rotate: -25,
                rotateAlways: width < 768 ? true : false, //mobile only rotation to prevent tick overlap
                formatter: formatter
              }
            },
            yaxis: {
              labels: {
                style: {
                  cssClass: 'apexcharts-axis-labels'
                }
              }
            },
            dataLabels: {
              enabled: true,
              formatter: formatter,
              textAnchor: "middle",
              style: {
                colors: ["#ffffff"],
                cssClass: 'apexcharts-axis-labels'
              }
            },
            tooltip: {
              enabled: false
            }
          },
          series: [{
            data: [relRisk, avgRisk]
          }],
        });  
      }
  }

  render() {
    return (
      <div>
        { 
        this.state.options &&
        <Chart options={this.state.options} series={this.state.series} type="bar" height="200" />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    myUploadID: state.session.myUploadID,
    results: state.results,
    width: state.ui.width
  };
};

const mapDispatchToProps = dispatch => ({
  loadResults: (uploadID) => dispatch(loadResultsThunk(uploadID))
});

export default connect(mapStateToProps, mapDispatchToProps)(RiskGraph);
