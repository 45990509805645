import React from 'react';
import { Link } from 'react-router-dom';
import { _vidListMaker } from '../../utils/prehab';


const vids = {
  'Star Excursion Balance Test': 'https://www.youtube.com/embed/21arMJTCRrs?rel=0',
  'Short Foot Single Leg Squats': 'https://www.youtube.com/embed/EE5-PXOGBgE?rel=0',
  'Hamstring Eccentric Slider': 'https://www.youtube.com/embed/shVZP0NZAYk?rel=0',
  'Mini-band Squat Jumps': 'https://www.youtube.com/embed/bjVhTLToo_o',
  'Resistance Band Acceleration and Deceleration': 'https://www.youtube.com/embed/AJkwLSzchXo?rel=0',
  'Inline Lunge': 'https://www.youtube.com/embed/Oq6Dt06ya2Y?rel=0',
  'Lateral Jumps with Stability': 'https://www.youtube.com/embed/m_qEXipYgjg?rel=0', 
  'Single Leg In Box Catches': 'https://www.youtube.com/embed/IxcZsPGI9UE?rel=0',
  'Partner Pull / Power Sled': 'https://www.youtube.com/embed/Y_xypT2TYL0?rel=0',
  'Hip Internal / External Rotation': 'https://www.youtube.com/embed/kemC5JTsPUM?rel=0'
};


export const ACLExercises = () => {
  return (
    <div>
      <div className="title-margins">
        <h2>ACL Injury Prehabilitation Exercises</h2>
      </div>
      <div className="card p-1">
        { _vidListMaker(vids) }
      </div>
      <div className="card">
        <p className="mb-0">The videos above are examples of exercises that can reduce your chance of injury. For advice on how to personalize your strength program based on your genetic tests, you can schedule a strength consultation <Link to="/consultations">here</Link>.</p>
      </div>
    </div>
  );
}

export default ACLExercises;
