import React from 'react';


const AchillesPrevention = () => {
    return (
        <div>
            <div className='title-margins'>
                <h2>Achilles Tendon Injury Prehabilitation Measures</h2>
            </div>
            <div className='title-break'>
                <span>Quick Checklist</span>
            </div>
            <div className="card">
                <ul className="mb-0">
                    <li>Avoid rapid increases in training volume or intensity</li>
                    <li>Avoid Fluroquinolone antibiotics, corticosteroid injections into the Achilles, and long-term use of NSAIDs</li>
                    <li>Complete targeted strength and mobility exercises for the glutes/core and the lower legs. Eccentric exercises can help stretch and strengthen the muscle-tendon unit</li>
                </ul>
            </div>
            <div className='title-break'>
                <span>Training Considerations</span>
            </div>
            <div className="card">
                {/* <img src="/assets/imgs/results/content/plantarAthlete.jpg" height={250} className="overview-img" alt="" /> */}
                <p className="mb-0">It is important to avoid rapid increases in training volume or intensity in order to prevent Achilles tendon injuries. Targeted ankle mobility and calf stretching as part of a thorough warm up and cool down are beneficial.</p>
            </div>

            <div className='title-break'>
                <span>Footwear Considerations</span>
            </div>
            <div className="card">
                <p className="mb-0">Athletes should slowly transition to new types of footwear and should avoid training in worn out shoes. A PT or expert in biomechanics can help athletes select heel lifts or shoes with appropriate heel-to-toe drop to help minimize stress on the Achilles.</p>
            </div>
            <div className='title-break'>
                <span>Health Variables</span>
            </div>
            <div className="card">
                <p className="mb-0">Athletes should avoid taking Fluoroquinolone antibiotics when possible to avoid the associated risk with Achilles tendon rupture. Athletes with pre-existing Achilles tendinopathy should complete proper prehabilitation to prevent risk of tearing upon return to sport and should avoid the use of corticosteroid injections. Further, athletes can take a short-course (7 to 10 days) of NSAIDs in the acute phase of Achilles tendinopathy, but should avoid long-term use, which may impair tendon healing.</p>
            </div>
            <div className='title-break'>
                <span>Strengthening Exercises</span>
            </div>
            <div className="card">
                <p className="mb-0">Prehabilitation programs focused on proximal gluteal and abdominal strength, progressive loading of the Achilles tendon and strengthening of the gastrocnemius are key for preventing Achilles injuries. Eccentric exercises lengthen and strengthen the muscle-tendon unit (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3748997/">Galloway et al., 2013</a>). Eccentric Achilles exercises can be performed daily, but should be added gradually, starting with one set and working up to 3 sets of 15 reps daily.</p>
            </div>
        </div>
    );
};

export default AchillesPrevention;
