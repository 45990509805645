import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';
import IntroTable from '../../components/introTable';


export const Mars_HThyResults = ({ userName, nameEndsWithS, uploadID, width }) => {
    // this page contains the results of multiple tests
    const { crm, uploads } = useSelector((state) => ({
        crm: state.crm,
        uploads: state.results.uploads,
    }));

    // gets the score for a given tag and returns the score sentence
    const getScoreContent = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        console.log('tagResult =', tagResult)
        console.log('tag =', tag)
        let score = tagResult.hasOwnProperty("value") ? tagResult['value'] : null;
        console.log('score =', score)
        let prefixedScore;
        if (score !== null) {
            prefixedScore = score > 0 ? `+${score.toFixed(2)}` : `${score.toFixed(2)}`;
        };
        return (
            <strong>{prefixedScore}</strong>
        );
    };

    const getGraph = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});

        const crmKey = `results-${tag}-histogram`;
        return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
    };

    const getPercentile = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        return <TagPercentile result={tagResult} tag={tag} usesUKData={false} />;
    };
    const tableData = [
        ["European", 0.65, 0.031, "ND"],
        ["African", 0.58, 0.010, "ND"],
        ["East Asian", 0.625, 0.026, "ND"]
    ];
    const table = <IntroTable data={tableData} caption="Hover over the column headers for an explanation." />;
    return (
        <div>
            <div className='title-margins'>
                <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Hypothyroidism</h2>
                <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/36347255/">Mars et al. (2022)</a>]</h5>
            </div>

            <div className='title-break'>
                <span>Your Results</span>
            </div>
            <div className="card">
                <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have higher risk for hypothyroidism. (Units for Beta are arbitrary.)</p>

                {getGraph("Mars_HThy")}
                <p>You score for atrial fibrillation risk is {getScoreContent("Mars_HThy")}. </p>
                <p>{getPercentile("Mars_HThy")} A score in the 84th percentile has about a 1.47 fold increased risk. A score in the 97th percentile has about a 2.94 fold increased risk. </p>
            </div>
            <div className='title-break'>
                <span>Comments</span>
            </div>
            <div className="card">
                <p>This study developed a polygenic risk score for hypothyroidism that includes 1,092,122 variants based on 306,418 individuals (14,871 cases) from FinnGen.</p>
                <p>The study was limited to individuals of European ancestry. It is not clear how well the genetics perform for other ancestries.</p>
            </div>
        </div>
    );
};

export default Mars_HThyResults;
