import React from 'react';
import ReactTooltip from 'react-tooltip';
import { _vidListMaker } from '../../utils/prehab';
import { Link } from 'react-router-dom';

const vids = {
    'Banded Hip Internal/External Rotation': 'https://www.youtube.com/embed/kemC5JTsPUM', 
    'Storks': 'https://www.youtube.com/embed/SffFKtEUSAk', 
    'Hamstring Curls': 'https://www.youtube.com/embed/P5ciNZbDkbo',
    'Short Foot Single Leg Squats': 'https://www.youtube.com/embed/EE5-PXOGBgE'
  };

  const ITBPrevention = () => {
    const vidList = _vidListMaker(vids);
      
    return (
      <div>
        <div className='title-margins'>
          <h2>ITB Prehabilitation Exercises</h2>
        </div>
        <div className="card p-1">
          {vidList}
        </div>
        <div className="card">
          <p>The videos above are examples of exercises that can reduce your chance of ITB syndrome. For advice on how to personalize your strength program based on your genetic results, you can schedule a strength consultation <Link to="/consultations">here</Link>.</p>
          <p>Strong hip abductors, hamstrings, and glutes are key for preventing ITB syndrome. Having stronger leg muscles will take pressure off of your IT band. Additionally, tight hip flexors can be an early indicator for ITB syndrome predisposition, so it is important to stretch your hip flexors regularly.  </p>
          <p>Banded hip rotations will help strengthen your hip abductors. Storks are a great way to activate and strengthen the Glute Medius that will help keep your pelvis in proper position when running or doing any other land based activities. Hamstring curls and single leg squats will both strengthen muscles around the IT band without putting too much strain on the knees or IT band itself. </p>
        </div>
        <div className="card">
            <div className='title-break'>
                <span>Traits that predispose to ITB syndrome:</span>
            </div>
            <div className="card">
            <img src="/assets/imgs/results/content/Endurance_Runner.jpg" height={250} className="overview-img" alt="" />
                <ul className="mb-0">
                    <li>Bowed legs.</li>
                    <li>Knee arthritis.</li>
                    <li>Unequal length of your legs.</li>
                    <li>Rotating your foot or ankle inward when you walk or run.</li>
                    <li>Weakness in your abs, glutes, or hip muscles.</li>
                </ul>
            </div>
            <div className='title-break'>
                <span>Prevention Strategies</span>
            </div>
            <div className="card">
                <p className="mb-0">                
                <ul>
                    <li>Maintain flexibility in hamstrings, quads, and hip flexors. Focus on strength training exercises that work on strengthening glutes, hip abductors, and quadriceps. For low grade ITB injuries, using a foam roller to relieve muscle tightness in the ITB, quad, hamstrings and calves can be effective at minimizing pain.</li>
                    <li>For runners, a gait analysis may be useful to address poor running mechanics and improve movement coordination. </li>
                    <li>Try not to run in the same direction on an indoor track or on the same side of a banked road. Find a way to run on the opposite side of the street safely in order to minimize the risk of developing iliotibial band syndrome. Running on a banked road causes an artificial tilt to the pelvis and increases the risk of developing inflammation and pain.  </li>
                    <li>When bicycling, avoid pedaling with your toes turned in (internally rotated), which can cause abnormal stretching of the iliotibial band at the knee. Stretching is important for preventing iliotibial band syndrome.</li>
                    <li>Avoid running downhill for extended periods.</li>
                    <li>Replace your running shoes regularly.</li>
                </ul></p>
            </div>
        </div>
        <ReactTooltip place="top" className="tooltip-axgen" effect="solid" id="exercise-types" />
      </div>
    );
  };

export default ITBPrevention;
