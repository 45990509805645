import React from 'react';
import GeneTable from '../../components/gene-table';


const MUTResults = (props) => {

    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for MUTYH-Associated Polyposis</h2>
            </div>
            <div className="card">
                <p>Your genotype for MUTYH-associated polyposis is shown in the table below. Table definitions can be shown by hovering your mouse over the column headings.</p>
                <GeneTable tag='mut' cols={['gene', 'alleleFreq']} />
            </div>
            <div className='title-break'>
                <span>Comments</span>
            </div>
            <div className="card">
                <p>rs34612342 and rs36053993 are the two most common mutations in <i>MUTYH</i>. These mutations are found in people of European descent, and have not been found in people with non-European ancestries. A postive result for a <i>MUTYH</i> mutation should be verified by clinical sequencing of the <i>MUTYH</i> gene.</p>
                <p>rs34612342 causes a Tyrosine to Cysteine amino-acid change at position 165 and rs36053993 causes a Glycine to Asparagine substitution at position 396 in the MUTYH protein.</p>
                <p>Individuals that are defective at both the maternal and paternal copies of their <i>MUTYH</i> gene have an increased risk of colorectal cancer. This includes individuals that are homozygous for the risk allele at either rs34612342 (C/C) or rs36053993 (T/T). This also includes people that have rs34612342 (C) in the <i>MUTYH</i> gene from one parent and rs36053993 (T) from the other parent.</p>
                <p>Individuals that are heterozygous for a <i>MUTYH</i> mutation are carriers; i.e. rs34612342 (C/T) or rs36053993 (T/C). If their partner is also a heterozygous carrier for a <i>MUTYH</i> mutation, then there is a 25% chance that their children will be homozygous and be at risk for <i>MUTYH</i>-Associated Polyposis.</p>
                <p>Relatives of people carrying a <i>MUTYH</i> mutation should consider genetic testing; e.g. siblings have a 50% chance of also carrying the mutation. </p>
            </div>
        </div>
    );
};

export default MUTResults;