import React from 'react';


const ACLPrevention = () => {
    return (
        <div>
            <div className='title-margins'>
                <h2>ACL and PCL Injury Prehabilitation Measures</h2>
            </div>
            <div className='title-break'>
                <span>Quick Checklist</span>
            </div>
            <div className="card">
                <ul className="mb-0">
                    <li>Evaluate biomechanics and relative muscle strength</li>
                    <li>Identify hamstring, quadriceps, hip, and abdominal strength imbalances</li>
                    <li>Perform strength and conditioning exercises heading into the pre-season</li>
                    <li>Select footwear appropriate for field conditions</li>
                    <li>Female athletes should consider reviewing their menstrual cycle and ACL risk with a physician, trainer, or coach</li>
                </ul>
            </div>
            <div className='title-break'>
                <span>Biomechanics and Fatigue</span>
            </div>
            <div className="card">
                <img src="/assets/imgs/results/content/acl-knee.jpeg" alt="" height={250} className="overview-img" />
                <p>An athlete’s biomechanics and relative muscle strength can impact susceptibility to ACL and PCL injury. Completing prehabilitation exercises to prevent (1) hamstring weakness/quadriceps dominance (2) hip external rotator weakness and (3) abdominal muscle weakness can reduce risk for ACL and PCL injury. Our strength page reviews prehabilitation exercises for the ACL and PCL. A number of prevention programs have been designed for reducing the risk of non-contact ACL injuries including the FIFA 11+, which is a program designed by the governing body of soccer (<a href="https://www.fifamedicalnetwork.com/lessons/prevention-fifa-11/">FIFA 11+</a>).</p>
                <p>Fatigue leads to increased risk for ACL and PCL injury. Performing strength and conditioning exercises in the pre-season can reduce early-season injury. Maintenance of conditioning throughout the season can help prevent injuries from occurring in the later periods or later minutes of a game (<a href="https://www.jsams.org/article/S1440-2440(13)00244-2/abstract">McMaster, 2013</a>).</p>
            </div>
            <div className='title-break'>
                <span>Environment and Footwear</span>
            </div>
            <div className="card">
                <p>Many studies have examined the impact of increased field/surface traction on ACL injury. A systematic review in American football players found a relationship between increased rotational traction and risk for ACL injury. Wet conditions and cold weather are both independently associated with reduced risk for ACL injury. The risk for ACL injury on natural grass vs field turf is thought to vary based on sport (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6490939/">Thomson et al., 2019</a>).</p>
                <p>Athletes should be comfortable and familiar with the field surface they are playing on and should select proper fitting footwear appropriate for the field conditions.</p>
            </div>
            <div className='title-break'>
                <span>Gender Considerations</span>
            </div>
            <div className="card">
                <p>Female athletes are more likely to sustain ACL tears compared to male athletes participating in the same sport/activity (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3920910/">Renstrom et al., 2008</a>). Female athletes participating in high-risk sports for ACL injury should address hamstring, hip, or abdominal strength weaknesses or imbalances. Consider reviewing your menstrual cycle with a physician or coach to develop individual recommendations.</p>
            </div>
        </div>
    );
};

export default ACLPrevention;

