import React from 'react';
import RiskGraph from '../../components/risk-graph';
import GeneTable from '../../components/gene-table';


const PatellarResults = (props) => {
    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Patellar Tendinitis</h2>
                <h5><h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/34081057/">Kim et al., 2021</a>]</h5></h5>
            </div>
            <div className="card">
                <RiskGraph tag='patellar-tendinitis' />
                <p className="mb-0">The bar graph shows your risk of patellar tendinitis compared to the risk for an average person (1.00). Your risk is calculated based on your genotype at the DNA polymorphism shown in the table below.</p>
                <p></p>
            </div>
            <div className="card">
                <p>A recent study identified a DNA polymorphism from a screen of the entire genome involving 1,670 cases of patellar tendinitis and 293,866 controls (Table). The relative risk tells how strongly your genetics affects your risk of injury.  If you have an A allele in rs149047058, your relative risk is 2.75 (compared to 1.0) which means you have 175% increased risk for injury. Table definitions can be shown by hovering your mouse over the column headings.</p>
                <GeneTable tag='patellar-tendinitis' cols={['relativeRisk', 'gene'] } />
            </div>
        </div>
    );
};

export default PatellarResults;