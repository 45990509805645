import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { loadResultsThunk } from '../actions';

// Import all Result SubPages
import AnkleIntro from './results/ankle';
import AnkleResults from './results/ankle_results';
import AnklePrevention from './results/ankle_prevention';

import BMDIntro from './results/bmd_intro';
import BMDResults from './results/bmd';
import BMDPrevention from './results/bmd_prevention';
import BMDExercises from './results/bmd_exercises';
import BMDFAQ from './results/bmd_faq';

import CaffeineIntro from './results/caffeine';
import CaffeineResults from './results/caffeine_results';

import DQTIntro from './results/dqt';
import DQTResults from './results/dqt_results';
import DQTPrevention from './results/dqt_prevention';

import MCLIntro from './results/mcl';
import MCLResults from './results/mcl_results';
import MCLPrevention from './results/mcl_prevention';
import MCLExercises from './results/mcl_exercises';

import PlantarIntro from './results/plantar';
import PlantarResults from './results/plantar_results';
import PlantarPrevention from './results/plantar_prevention';
import PlantarExercises from './results/plantar_exercises';

import RotatorIntro from './results//rotator';
import RotatorResults from './results/rotator_results';

import ShoulderIntro from './results/shoulder';
import ShoulderResults from './results/shoulder_results';
import ShoulderExercises from './results/shoulder_exercises';

import ConcussionIntro from './results/concussion_intro';
import ConcussionResults from './results/concussion_results';
import ConcussionPrevention from './results/concussion_prevention';
import ConcussionExercises from './results/concussion_exercises';

import TestosteroneIntro from './results/testosterone_intro';
import TestosteroneResults from './results/testosterone_results';
import TestosteroneRecommendation from './results/testosterone_recommendation';

import VitB12Intro from './results/VitB12_intro';
import VitB12Results from './results/VitB12_results';
import VitB12Recommendation from './results/VitB12_recommendation';

import VitDIntro from './results/VitD_intro';
import VitDResults from './results/VitD_results';

import CaIntro from './results/Ca_intro';
import CaResults from './results/Ca_results';

import VitCIntro from './results/VitC_intro';
import VitCResults from './results/VitC_results';

import VitAIntro from './results/VitA_intro';
import VitAResults from './results/VitA_results';

import VitEIntro from './results/VitE_intro';
import VitEResults from './results/VitE_results';

import PhosIntro from './results/phos_intro';
import PhosResults from './results/phos_results';

import COQ10Intro from './results/COQ10_intro';
import COQ10Results from './results/COQ10_results';

import VitB6Intro from './results/VitB6_intro';
import VitB6Results from './results/VitB6_results';

import FolateIntro from './results/folate_intro';
import FolateResults from './results/folate_results';

import MgIntro from './results/mg_intro';
import MgResults from './results/mg_results';
import MgRecommendation from './results/mg_recommendation';

import LactoseIntro from './results/lactose_intro';
import LactoseResults from './results/lactose_results';
import LactoseRecommendation from './results/lactose_recommendation';

import IbuprofenIntro from './results/ibuprofen_intro';
import IbuprofenResults from './results/ibuprofen_results';
import IbuprofenRecommendation from './results/ibuprofen_recommendation';

import IronIntro from './results/iron_intro';
import IronResults from './results/iron_results';
import IronRecommendation from './results/iron_recommendation';

import ACLIntro from './results/acl_intro';
import ACLResults from './results/acl_results';
import ACLPrevention from './results/acl_prevention';
import ACLExercises from './results/acl_exercises';

import AchillesIntro from './results/achilles_intro';
import AchillesResults from './results/achilles_results';
import AchillesPrevention from './results/achilles_prevention';

import PatellarIntro from './results/patellar_intro';
import PatellarResults from './results/patellar_results';
import PatellarPrevention from './results/patellar_prevention';

import ITBIntro from './results/itb_intro';
import ITBResults from './results/itb_results';
import ITBPrevention from './results/itb_prevention';

import Chondro2Intro from './results/chondro2_intro';
import Chondro2Results from './results/chondro2_results';
import Chondro2Prevention from './results/chondro2_prevention';

import BoasResults from './results/boas_results';

import PUFAIntro from './results/pufa_intro';
import PUFAResults from './results/pufa_results';

import StrengthIntro from './results/strength_intro';
import StrengthResults from './results/strength_results';

import FEVIntro from './results/FEV_intro';
import FEVResults from './results/FEV_results';
import FEVTraining from './results/FEV_training';

import OsteoIntro from './results/osteo_intro';
import OsteoResults from './results/osteo_results';
// import OsteoPrevention from './results/osteo_prevention';
import OsteoFAQ from './results/osteo_faq';
import BoneIntro from './results/bone_intro';
import BoneResults from './results/bone_results';

import IBDIntro from './results/ibd_intro';
import IBDCeliac from './results/ibd_celiac';
import IBDIBD from './results/ibd_ibd';
import IBDCrohn from './results/ibd_crohn';
import IBDUColl from './results/ibd_ucoll';
// import IBDTreatment from './results/ibd_treatment';

import HRrestIntro from './results/HRrest_intro';
import HRrestResults from './results/HRrest_results';
import HRrestEx from './results/HRrest_exercise';
import HRrestRecovery from './results/HRrest_recovery';
import HRrestVariability from './results/HRrest_variability';

import CeliacIntro from './results/celiac_intro';
import CeliacResults from './results/celiac_results';
import CeliacPrevention from './results/celiac_prevention';

import GlutenomicsIntro from './results/glutenomics_intro';
import GlutenomicsResults from './results/glutenomics_results';
import GlutenomicsPrevention from './results/glutenomics_prevention';

import ProstateIntro from './results/prostate_intro';
import ProstateResults from './results/prostate_results';

import CancerIntro from './results/cancer_intro';
import CancerResults from './results/cancer_results';

import VTE2Intro from './results/vte2_intro';
import VTE2Results from './results/vte2_results';

import AlzIntro from './results/alz_intro';
import AlzResults from './results/alz_results';

import ComtIntro from './results/comt_intro';
import ComtResults from './results/comt_results';

import AATIntro from './results/aat_intro';
import AATResults from './results/aat_results';

import AMDIntro from './results/amd_intro';
import AMDResults from './results/amd_results';

import G6DIntro from './results/g6d_intro';
import G6DResults from './results/g6d_results';

import HAMYDIntro from './results/hamyd_intro';
import HAMYDResults from './results/hamyd_results';

import HFEIntro from './results/hfe_intro';
import HFEResults from './results/hfe_results';

import MUTIntro from './results/mut_intro';
import MUTResults from './results/mut_results';

import Yu_CKDIntro from './results/Yu_CKD_intro';
import Yu_CKDResults from './results/Yu_CKD_results';

import T2D_YeIntro from './results/T2D_Ye_intro';
import T2D_YeResults from './results/T2D_Ye_results';

import CAD_YeIntro from './results/CAD_Ye_intro';
import CAD_YeResults2 from './results/CAD_Ye_results2';

import AF_YeIntro from './results/af_ye_intro';
import AF_YeResults2 from './results/af_ye_results2';

import AsthmaIntro from './results/asthma_intro';
import AsthmaResults from './results/asthma_results';

import GoutIntro from './results/gout_intro';
import GoutResults from './results/gout_results';

import AAAIntro from './results/AAA_intro';
import AAAResults from './results/AAA_results';

import POAGIntro from './results/POAG_intro';
import POAGResults from './results/POAG_results';

import ThCIntro from './results/ThC_intro';
import ThCResults from './results/ThC_results';

import Mars_arthIntro from './results/Mars_arth_intro';
import Mars_arthResults from './results/Mars_arth_results';

import Mars_ColCanIntro from './results/Mars_ColCan_intro';
import Mars_ColCanResults from './results/Mars_ColCan_results';

import Mars_KOsteoIntro from './results/Mars_KOsteo_intro';
import Mars_KOsteoResults from './results/Mars_KOsteo_results';

import Mars_HOsteoIntro from './results/Mars_HOsteo_intro';
import Mars_HOsteoResults from './results/Mars_HOsteo_results';

import Mars_HThyIntro from './results/Mars_HThy_intro';
import Mars_HThyResults from './results/Mars_HThy_results';


const resultsConfig = require('../../json/results-config.json');

const resultPageMap = {
  'Mars_HThy': {
    'intro': Mars_HThyIntro,
    'results': Mars_HThyResults,
  },
  'asthma': {
    'intro': AsthmaIntro,
    'results': AsthmaResults,
  },
  'Mars_HOsteo': {
    'intro': Mars_HOsteoIntro,
    'results': Mars_HOsteoResults,
  },
  'Mars_KOsteo': {
    'intro': Mars_KOsteoIntro,
    'results': Mars_KOsteoResults,
  },
  'Mars_ColCan': {
    'intro': Mars_ColCanIntro,
    'results': Mars_ColCanResults,
  },
  'Mars_arth': {
    'intro': Mars_arthIntro,
    'results': Mars_arthResults,
  },
  'gout': {
    'intro': GoutIntro,
    'results': GoutResults,
  },
  'AAA': {
    'intro': AAAIntro,
    'results': AAAResults,
  },
  'POAG': {
    'intro': POAGIntro,
    'results': POAGResults,
  },
  'ThC': {
    'intro': ThCIntro,
    'results': ThCResults,
  },
  'CAD_Ye': {
    'intro': CAD_YeIntro,
    'results2': CAD_YeResults2
  },
  'AF_Ye': {
    'intro': AF_YeIntro,
    'results2': AF_YeResults2
  },
  'T2D_Ye': {
    'intro': T2D_YeIntro,
    'results': T2D_YeResults,
  },

  'Yu_CKD': {
    'intro': Yu_CKDIntro,
    'results': Yu_CKDResults,
  },
  'mut': {
    'intro': MUTIntro,
    'results': MUTResults,
  },
  'hfe': {
    'intro': HFEIntro,
    'results': HFEResults,
  },
  'hamyd': {
    'intro': HAMYDIntro,
    'results': HAMYDResults,
  },
  'g6d': {
    'intro': G6DIntro,
    'results': G6DResults,
  },
  'amd': {
    'intro': AMDIntro,
    'results': AMDResults,
  },
  'ankle': {
    'intro': AnkleIntro,
    'results': AnkleResults,
    'prevention': AnklePrevention
  },
  'bone-mineral-density': {
    'intro': BMDIntro,
    'results': BMDResults,
    'prevention': BMDPrevention,
    'exercises': BMDExercises,
    'faq': BMDFAQ
  },
  'coffee-consumption': {
    'intro': CaffeineIntro,
    'results': CaffeineResults
  },
  'de-quervains-tenosynovitis': {
    'intro': DQTIntro,
    'results': DQTResults,
    'prevention': DQTPrevention
  },
  'medial-collateral-ligament': {
    'intro': MCLIntro,
    'results': MCLResults,
    'prevention': MCLPrevention,
    'exercises': MCLExercises
  },
  'plantar-fasciitis': {
    'intro': PlantarIntro,
    'results': PlantarResults,
    'prevention': PlantarPrevention,
    'exercises': PlantarExercises
  },
  'rotator-cuff': {
    'intro': RotatorIntro,
    'results': RotatorResults,
    'exercises': ShoulderExercises
  },
  'shoulder': {
    'intro': ShoulderIntro,
    'results': ShoulderResults,
    'exercises': ShoulderExercises
  },
  'concussion': {
    'intro': ConcussionIntro,
    'results': ConcussionResults,
    'prevention': ConcussionPrevention,
    'exercises': ConcussionExercises
  },
  'COQ10': {
    'intro': COQ10Intro,
    'results': COQ10Results
  },
  'strength': {
    'intro': StrengthIntro,
    'results': StrengthResults
  },
  'testosterone': {
    'intro': TestosteroneIntro,
    'results': TestosteroneResults,
    'recommendation': TestosteroneRecommendation
  },
  'vitamin-b12': {
    'intro': VitB12Intro,
    'results': VitB12Results,
    'recommendation': VitB12Recommendation,
  },
  'vitD': {
    'intro': VitDIntro,
    'results': VitDResults,
  },
  'Ca': {
    'intro': CaIntro,
    'results': CaResults,
  },
  'vitC': {
    'intro': VitCIntro,
    'results': VitCResults,
  },
  'vitA': {
    'intro': VitAIntro,
    'results': VitAResults,
  },
  'folate': {
    'intro': FolateIntro,
    'results': FolateResults,
  },
  'phos': {
    'intro': PhosIntro,
    'results': PhosResults,
  },
  'vitE': {
    'intro': VitEIntro,
    'results': VitEResults,
  },
  'vitB6': {
    'intro': VitB6Intro,
    'results': VitB6Results,
  },
  'PUFA': {
    'intro': PUFAIntro,
    'results': PUFAResults,
  },
  'lactose-intolerance': {
    'intro': LactoseIntro,
    'results': LactoseResults,
    'recommendation': LactoseRecommendation
  },
  'ibuprofen': {
    'intro': IbuprofenIntro,
    'results': IbuprofenResults,
    'recommendation': IbuprofenRecommendation
  },
  'mg': {
    'intro': MgIntro,
    'results': MgResults,
    'recommendation': MgRecommendation
  },
  'ferritin': {
    'intro': IronIntro,
    'results': IronResults,
    'recommendation': IronRecommendation
  },
  'anterior-cruciate-ligament': {
    'intro': ACLIntro,
    'results': ACLResults,
    'prevention': ACLPrevention,
    'exercises': ACLExercises
  },
  'achilles': {
    'intro': AchillesIntro,
    'results': AchillesResults,
    'prevention': AchillesPrevention
  },
  'itb': {
    'intro': ITBIntro,
    'results': ITBResults,
    'prevention': ITBPrevention
  },
  'patellar-tendinitis': {
    'intro': PatellarIntro,
    'results': PatellarResults,
    'prevention': PatellarPrevention
  },
  'chondro2': {
    'intro': Chondro2Intro,
    'results': Chondro2Results,
    'prevention': Chondro2Prevention
  },
  'celiac': {
    'intro': CeliacIntro,
    'results': CeliacResults,
    'prevention': CeliacPrevention
  },
  'glutenomics': {
    'intro': GlutenomicsIntro,
    'results': GlutenomicsResults,
    'prevention': GlutenomicsPrevention
  },
  'HRrest': {
    'intro': HRrestIntro,
    'results': HRrestResults,
    'exercise': HRrestEx,
    'recovery': HRrestRecovery,
    'variability': HRrestVariability,
  },
  'FEV': {
    'intro': FEVIntro,
    'results': FEVResults,
    'training': FEVTraining,
  },
  'osteo': {
    'intro': OsteoIntro,
    'results': OsteoResults,
    // 'prevention': OsteoPrevention,
    'faq': OsteoFAQ,
  },
  'bone': {
    'intro': BoneIntro,
    'results': BoneResults,
  },
  'IBD': {
    'intro': IBDIntro,
    'celiac': IBDCeliac,
    'ibd': IBDIBD,
    'crohn': IBDCrohn,
    'ucoll': IBDUColl,
    // 'treatment': IBDTreatment,
  },
  'prostate': {
    'intro': ProstateIntro,
    'results': ProstateResults,
  },
  'cancer': {
    'intro': CancerIntro,
    'results': CancerResults,
  },
  'vte2': {
    'intro': VTE2Intro,
    'results': VTE2Results,
  },
  'alz': {
    'intro': AlzIntro,
    'results': AlzResults,
  },
  'comt': {
    'intro': ComtIntro,
    'results': ComtResults,
  },
  'aat': {
    'intro': AATIntro,
    'results': AATResults,
  },
  'boas': {
    'results': BoasResults
  }
};

// generates the results subpages for each injury
export class ResultViewerPage extends React.Component {
  constructor(props) {
    super(props);
    // adjust banner here
    this.state = { banner: false }
  }

  componentDidMount() {
    const { uploadID } = this.props.match.params;
    const { results } = this.props;
    if (!(uploadID in results.uploads)) {
      this.props.loadResults(uploadID);
    };
  };

  _getNavItems(tagConfig, tag, uploadID, subPageKey, result) {
    let navItems = null;
    // nav items are based on results rather than config
    if (Object.keys(result).length > 0) {
      let navList = (tagConfig.pages || []).map((page, i) =>
        <Link
          key={i}
          to={`/results/${uploadID}/${tag}/${page.link}` || '#'}
          className={classNames("nav-item", { "active": page.link === subPageKey })}
        >
          {page.title}
        </Link>
      );

      if (navList.length < 4) {
        navItems =
          <div className="nav-items">
            {navList}
          </div>
      } else {
        navItems =
          <div className="nav-items-cramped">
            {navList}
          </div>
      };
    };

    return navItems;
  };

  _createSubPage(tag, subPageKey, result, uploads, uploadID, demographics) {
    let subPage;
    if (Object.keys(result).length > 0) {
      const { crm, width, myUploadID } = this.props;
      const userName = ((uploads[uploadID] || {})['demographics'] || {})['name'] || "";
      const nameEndsWithS = userName.substr(-1) === 's';
      const userIsMe = myUploadID !== null && (myUploadID).toString() === (uploadID).toString();

      // create React element
      let subpageMap = (resultPageMap[tag] || {})[subPageKey] || null;
      if (subpageMap !== null) {
        subPage = React.createElement(subpageMap, { crm, result, uploadID, userName, nameEndsWithS, tag, width, demographics, userIsMe });
      };
    };

    return subPage;
  };

  render() {
    // get the props
    const { results } = this.props;
    const { uploadID, tag, subPage: pathSubPage } = this.props.match.params;
    const { uploads } = results;
    let result = ((uploads[uploadID] || {})['results'] || {})[tag] || {};
    let demographics = ((uploads[uploadID] || {})['demographics'] || {});

    // get the tag config based on one's results
    let tagConfig = resultsConfig.hasOwnProperty(tag) ? resultsConfig[tag] : {};
    // we need to be able to render the tag co

    let prettyTag = tagConfig.title || tag.replace(/-/g, ' ');

    // get subPage key
    let subPageKey = pathSubPage || tagConfig.defaultPage || ((tagConfig.pages || [])[0] || {}).link || '';

    // create the subPage
    let subPageComponent = this._createSubPage(tag, subPageKey, result, uploads, uploadID, demographics);

    document.body.scrollTop = document.documentElement.scrollTop = 0; // returns the user to the top of the page when one goes tp a subPage

    // handles rendering the navigation items, including for pages that do not exist
    // amount of subPages determines the sizing of the subPage nav items to prevent overflow 
    let navItems = this._getNavItems(tagConfig, tag, uploadID, subPageKey, result)

    // header 
    const navTitleHeader =
      <div className="result-intro-header">
        <Link to={`/results/${uploadID}`} className="back-link"><span className="fal fa-chevron-left"></span> Back to results</Link>
        <span className={`title-risk ${result.color || 'gray-full'}`}>{tagConfig.title || prettyTag}</span>
      </div>

    // handles stickyClass. the banner's existence determines the css class applied here
    const stickyClass = this.state.banner ? "sticky-top-banner" : "sticky-top-bannerless"
    return (
      <div>
        <Navbar clean={true} />
        <div className={`result-nav ${stickyClass}`}>
          <div className="container">
            <div className="nav-title">
              {navTitleHeader}
            </div>
            {navItems}
          </div>
        </div>
        <div className="container mt-4">
          <div className="mx-auto">
            <div className="result-explorer">
              <div className="row">
                <div className="content-view">
                  {subPageComponent}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  crm: state.crm,
  myUploadID: state.session.myUploadID,
  results: state.results,
  width: state.ui.width
});

const mapDispatchToProps = dispatch => ({
  loadResults: (uploadID) => dispatch(loadResultsThunk(uploadID))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultViewerPage);
