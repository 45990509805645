/*
  The following utility functions are used in our results pages and reducer
*/


// finds bucket for use in the polygenic histograms
export const findBucket = (score, series) => {
  return series.reduce((agg, cur, i) => {
      if (i === series.length - 1) {
        // For last item, score only needs to be greater
        if (score > parseFloat(cur)) return i;
      } else {
        if (score > parseFloat(cur)) return i;
      };
      return agg;
    }, 0);
};

// returns text showing a positive number and associated Increased/Decreased tag for readability in text
//getRiskText is for fracture risk and getOsteoRiskText is for osteoporosis risk
export const getRiskText = (deltaRisk) => {
    if (deltaRisk < 0) {
      return (deltaRisk * -1) + "% decreased"
    };
    return deltaRisk + "% increased"
};
export const getOsteoRiskText = (deltaRisk) => {
  if (deltaRisk < 0) {
    return (deltaRisk * -1) + " fold decreased"
  };
  return deltaRisk + " fold increased"
};
 
// validates demogs
export const validateDemographics = (dg) => {
    if (dg && Object.keys(dg).length > 0) {
      try {
        if (dg.height > 0 && dg.weight > 0 && dg.gender.length > 0 && dg.birthYear > 0 && ["male", "female"].indexOf(dg.gender) > -1) {
          return true;
        };
      } catch (e) {
        return false;
      };
    };
    return false;
};
  

// gets suffix for a given percentile
export const getPercentileSuffix = (percentile) => {

  let suffix;
    if (percentile > 10 && percentile < 20) {
      suffix = 'th';
    } else {
      const lastDigit = percentile.toString()[percentile.toString().length - 1];
      switch(lastDigit) {
        case '0':
          suffix = 'th';
          break;
        case '1':
          suffix = 'st';
          break;
        case '2':
          suffix = 'nd';
          break;
        case '3':
          suffix = 'rd';
          break;
        default:
          suffix = 'th';
          break;
    };
  };
  return suffix;
};

// finds the unformatted percentile
const percentileConfig = require('../../json/percentile-config.json');
export const getPercentile = (score, configKey) => {
  
  const { binScore, binSize } = percentileConfig[configKey];
  let scoreIdx = findBucket(score, binScore);
  let totalSum = binSize.reduce((acc, el) => acc + el);
  let bucketSum = binSize.reduce((total, curEl, index) => {
    if (index > scoreIdx) {
      return total;
    };
    return total + curEl;
  });
  return ((bucketSum / totalSum) * 100).toFixed(0);
};

// finds the percentile for tags that have a percentile value in upload_entry_results
export const getPercentileText2 = (percentile) => {
  let suffix = getPercentileSuffix(percentile);
  return `${percentile}${suffix} percentile` ;
};

// finds the percentile and formats it for presentational purposes
export const getPercentileText = (tag, score, gender=null) => {
  let configKey;
  if (tag === 'testosterone' && gender !== null) {
    configKey = `${gender}-testosterone`;
  } else {
    configKey = tag;
  };
  let percentile = getPercentile(score, configKey);
  let suffix = getPercentileSuffix(percentile);

  return `${percentile}${suffix} percentile`;
};


// finds relative risk based on a genetic score for an injury
export const getRelativeRisk = (riskVal) => {
  let relRisk;
  if (riskVal > 0) {
    relRisk = `${(Math.abs(riskVal)*100).toFixed(0)}% increased`;
  } else if (riskVal < 0) {
    relRisk = `${(Math.abs(riskVal)*100).toFixed(0)}% decreased`;
  } else {
    relRisk = "normal";
  };
  return relRisk;
};

// finds incidence based on a genetic score for an injury
export const getIncidence = (riskVal) => {
  return (riskVal + 1).toFixed(2) + "x";
};

export function prob_dens_function(mean, sd, percentile) {
  // Constants
  const pi = Math.PI;
  const e = Math.E;

  // Calculate the standard score (z-score) corresponding to the percentile
  const zScore = Math.sqrt(2) * inverseErrorFunction(2 * percentile - 1);
  console.log(`The zscore for ${percentile} percentile is: ${zScore}`);
  // Calculate the y value using the PDF formula
  const yValue = (1 / (sd * Math.sqrt(2 * pi))) * Math.pow(e, -0.5 * Math.pow((zScore - mean) / sd, 2));

  return yValue;
}

// Inverse error function for calculating z-score
// This function is used internally by the prob_dens_function
function inverseErrorFunction(x) {
  const a1 =  0.254829592;
  const a2 = -0.284496736;
  const a3 =  1.421413741;
  const a4 = -1.453152027;
  const a5 =  1.061405429;
  const p  =  0.3275911;

  const sign = (x < 0) ? -1 : 1;
  x = Math.abs(x);

  const t = 1.0 / (1.0 + p * x);
  const y = ((((a5 * t + a4) * t) + a3) * t + a2) * t + a1;

  return sign * (1.0 - y * Math.pow(Math.E, -x * x));
}

// Example usage
// const mean = 0;
// const sd = 1;
// const percentile = 0.5; // Example percentile

// const result = prob_dens_function(mean, sd, percentile);
// console.log(`The y value for the given percentile is: ${result}`);
