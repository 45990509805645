import React, {  useState, useEffect } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import * as Actions from '../actions';
import Alerts from '../components/alerts';
import { formatAddress } from '../utils/components';

// stripe-form2 is for charging $30 to user to upgrade their account.
// no optionType, 

const StripeForm2 = ({ session, alerts, width }) => {
  // assorted local states
  const [ disableSubmit] = useState(false);
  const [address, setAddress] = useState({
    name: "",
    street: "",
    apt: "",
    city: "",
    state: "",
    zip: "",
    country: ""
  });

  // Stripe hooks
  const stripe = useStripe();
  const elements = useElements();
  
  // refs, which point to input fields, allowing one to access an input's content
  const UPLOAD_TYPE = 'upgrade';
  const UPGRADE_PRICE = parseInt(process.env.REACT_APP_PRICE_UPLOAD);

  // helper functions
  
  // updates address state
  const handleChange = (e) => {
    e.preventDefault();
    let copiedAddress = Object.assign({}, address);
    copiedAddress[e.target.name] = e.target.value;
    setAddress(copiedAddress);
  };

  // submits an order, clears alerts on submit, disables the submit, raises alerts
  // checks for incomplete shipping info as well, although the chances of this happening are quite low as one cannot even submit the form without a complete address
  const handleSubmit = () => {
    Actions.clearAlerts();
    disableSubmit(true);

    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement, { name: session.userName }).then(resp => {
      const { token, error } = resp;
      if (error) {
        Actions.addAlert('buy-upgrade', 'error', error.message);
        return;
      };
      if (token) {
        Actions.buyUpgrade(token, (UPGRADE_PRICE * 100).toFixed(0), '', UPLOAD_TYPE, formatAddress(address));
      };
    });
  };

  // sets the disabled submit to false if there are alerts. this can happen on either order success or failure
  useEffect(() => {
    if (Object.keys(alerts).length !== 0) {
      disableSubmit(false);
    };
  }, [alerts]);

  // presentational

  const paragraph = (
    <p>Upgrade your account to view all of <a href="https://www.axgen.us/what-you-get">AxGen's tests for musculoskeletal injuries, athletic performance and nutrition</a>.</p>
  );

  // handles the display none with the form on success
  const success = ((alerts['buy-upgrade'] || {})['success'] || []).length > 0;

   const addressForm = (
    <div>
        <div className="mb-2">
          <strong>Billing Details</strong>
        </div>
      <div className="row">
        <div className="col-sm-12">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="name"
            placeholder="full name*"
            className="form-control"
            value={address.name}
            required
          />
        </div>
        <div className="col-sm-8">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="street"
            placeholder="street address*"
            className="form-control"
            value={address.street}
            required
          />
        </div>        
        <div className="col-sm-4">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="apt"
            placeholder="apt / unit"
            className="form-control"
            value={address.apt}
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="city"
            placeholder="city*"
            className="form-control"
            value={address.city}
            required
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="state"
            placeholder="state / province*"
            className="form-control"
            value={address.state}
            required
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="zip"
            placeholder="zip / postal code*"
            className="form-control"
            value={address.zip}
            required
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="country"
            placeholder="country*"
            className="form-control"
            value={address.country}
            required
          />
        </div>
      </div>
    </div>
  );

  // renders order form
  const orderForm = (
    <div>
      <div>
        <div className="mb-2"><strong>Credit Card</strong></div>
          <CardElement className="mb-4 stripe-ele" />
      </div>
      <div className="mb-4">
        { addressForm }
      </div>
    </div>
  );

  return (
    <div>
      {paragraph}
      <form className={classNames("card form py-3", { "d-none": success })}>
        { orderForm }
        <button
          type="button"
          className="form-control btn btn-axgen mb-0"
          onClick={(e) => handleSubmit()}>
          Click to upgrade
        </button>
        
        <Alerts tag='buy-upgrade' message="Failed to submit order." />
      </form>
      <div className={classNames({ "d-none": success })}> 
          <img src="/assets/imgs/stripe.png" className='stripe-icon' alt=""/>
      </div>
    </div>
  );
}

export default StripeForm2;
