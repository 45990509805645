import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';


export const Mars_KOsteoResults = ({ userName, nameEndsWithS, uploadID, width }) => {
    // this page contains the results of multiple tests
    const { crm, uploads } = useSelector((state) => ({
        crm: state.crm,
        uploads: state.results.uploads,
    }));

    const getGraph = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});

        const crmKey = `results-${tag}-histogram`;
        return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
    };

    const getPercentile = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        return <TagPercentile result={tagResult} tag={tag} usesUKData={false} />;
    };
    return (
        <div>
            <div className='title-margins'>
                <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Knee Osteoarthritis</h2>
                <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/36347255/">Mars et al. (2022)</a>]</h5>
            </div>
            <div className='title-break'>
                <span>Your Results</span>
            </div>
            <div className="card">
                <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have higher risk for knee osteoarthritis. (Units for Beta are arbitrary.)</p>

                {getGraph("Mars_KOsteo")}

                <p>{getPercentile("Mars_KOsteo")} A score in the 84th percentile has about a 1.35 fold increased risk. A score in the 97th percentile has about a 2.7 fold increased risk. </p>
            </div>
            <div className='title-break'>
                <span>Comments</span>
            </div>
            <div className="card">
                <p>This study developed a polygenic risk score for knee osteoarthritis that includes 1,052,275 variants based on 306,418 individuals (24,955 cases).</p>
                <p>The study was limited to individuals of European ancestry. It is not clear how well the PRS score performs for other ancestries.</p>
            </div>
        </div>
    );
};

export default Mars_KOsteoResults;
