import React from 'react';
import GeneTable from '../../components/gene-table';
import RiskGraph from '../../components/risk-graph';
import Collapsible from 'react-collapsible';

class ShoulderResults extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Shoulder Instability</h2>
          <h5>[<a href="https://www.ncbi.nlm.nih.gov/pubmed/28521375#">Kim et al., 2017</a>]</h5>
        </div>
        <div className="card p-1">
          <Collapsible trigger="Tutorial">
            <p className="intro-vid"><iframe title="Results" width="560" height="315" src="https://www.youtube.com/embed/_v6ss58c-k4?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
          </Collapsible>
        </div>
        <div className="card">
          <RiskGraph tag='shoulder' />
        </div>
        <div className="card">
          <p>Shoulder instability (e.g. shoulder dislocation) is serious and puts you at risk for the injury to recur. This injury is fairly rare in the civilian population (shown in the graph) but can be much more frequent for certain sports. The genetic marker is both fairly common and indicates increased risk; specifically, the T allele of rs12913965 has an allele frequency of 13% and is associated with a 69% increased risk of injury. </p>
          <GeneTable tag='shoulder' cols={[ 'alleleFreq', 'relativeRisk'] } />
        </div>
      </div>
    )
  }
}

export default ShoulderResults;
