import { CREATE_ADMIN_CONSULTATION, ACTIVATE_KIT, CONFIRM_EMAIL, CONFIRM_EMAIL2, UPLOAD_FILE, SAVE_PERMISSIONS, ORDER_KITS_COUNT, ORDER_CODES_EMAIL_LIST, RESEND_CONFIRMATION, CLEAR_SPECIFIC_ALERT, ADD_MANAGER, REMOVE_MANAGER, SEND_CODE, SEND_UPLOAD } from '../actions';

// typeof msg is either string or object
const addTag = (state, tag, level, msg)  => {
  state[tag] = state[tag] || {};
  if (typeof msg === 'object') {
    state[tag][level] = msg;
  } else {
    state[tag][level] = state[tag][level] || [];
    state[tag][level].push(msg);  
  };
  return state;
};

const addErrors = (state, tag, action) => {
  action.payload.response.data.errors.forEach((error) => {
    addTag(state, tag, 'error', error);
  });
  return state;
};

const removeTag = (state, tag) => {
  const dupedState = Object.assign({}, state);
  if (state.hasOwnProperty(tag)) delete dupedState[tag];
  return dupedState;
};

const alertReducer = (state = {}, action) => {
  let cleanState = {};
  let { tag, level, msg } = action.payload || {};
  let newState = Object.assign({}, state);

  if (action.error === true && (action.payload || {}).message === "Network Error") {
    return addTag(newState, 'global', 'error', 'Network error (500)');
  }
  switch (action.type) {
    case 'CLEAR_ALERTS':
      return cleanState;
    case 'ADD_ALERT':
      return addTag(newState, tag, level, msg);
    case CLEAR_SPECIFIC_ALERT:
      return removeTag(newState, tag);
    case 'LOGIN_REJECTED':
      return addErrors(newState, 'login', action);
    case 'REGISTER_REJECTED':
      return addErrors(newState, 'register', action);
    case 'RESET_PASSWORD_REJECTED':
      return addErrors(newState, 'reset-password', action);
    case 'CHANGE_PASSWORD_REJECTED':
      return addErrors(newState, 'change-password', action);
    case 'RECEIVE_KIT_REJECTED':
      return addErrors(newState, 'claim-kit', action);
    case 'BUY_KIT_REJECTED':
      return addErrors(newState, 'buy-kit', action);
    case 'BUY_UPLOAD_REJECTED':
      return addErrors(newState, 'buy-kit', action);
    case 'BUY_UPGRADE_REJECTED':
      return addErrors(newState, 'buy-upgrade', action);      
    case 'UPLOAD_STRENGTH_PLAN_REJECTED':
        return addErrors(newState, 'upload-strength', action);
    case 'SAVE_ACCOUNT_SETTINGS_REJECTED':
      return addErrors(newState, 'save-account-settings', action);
    case 'LOAD_COUPON_REJECTED':
      return addErrors(newState, 'load-coupon', action);
    case 'REGISTER_FULFILLED':
      return addTag(newState, 'register', 'success', 'Successfully registered account. Please check your email for a message confirming your email address.');
    case CONFIRM_EMAIL:
      return addTag(newState, 'confirm-email', 'success', 'Successfully confirmed email address. Click on the link below to log in.');
    case CONFIRM_EMAIL2:
      return addTag(newState, 'confirm-email2', 'success', 'Successfully confirmed email address. Your kit will arrive in a few days with instructions for providing a saliva sample.');
    case RESEND_CONFIRMATION:
      return addTag(newState, 'resend-confirmation', 'success', 'Succcessfully resent confirmation email.');
    case 'BUY_KIT_FULFILLED':
      return addTag(newState, 'buy-kit', 'success', 'Success. AxGen will send you a confirmation email shortly.');
    case 'BUY_UPLOAD_FULFILLED':
      return addTag(newState, 'buy-kit', 'success', 'Successfully purchased upload. AxGen will send you a confirmation email shortly.');  
    case 'BUY_UPGRADE_FULFILLED':
      return addTag(newState, 'buy-upgrade', 'success', 'Successfully purchased upgrade. AxGen will send you a confirmation email shortly.');  
    case ORDER_KITS_COUNT:
      return addTag(newState, 'order-by-count', 'success', 'Order successful. You will not be charged at this time for ordering kits. Our team will contact you for invoicing later.');
    case ORDER_CODES_EMAIL_LIST:
        return addTag(newState, 'order-by-email', 'success', 'Order successful. You will not be charged at this time for ordering kits. Our team will contact you for invoicing later.');
    case SEND_CODE:
      return addTag(newState, 'order-by-email', 'success', 'Successfully emailed codes.');
    case SEND_UPLOAD:
        return addTag(newState, 'order-by-email', 'success', 'Successfully emailed codes.');
    case 'RECEIVE_KIT_FULFILLED':
      return addTag(newState, 'claim-kit', 'success', 'Success! Your kit will be mailed to you and should arrive within the next 5-7 business days.');
    case ACTIVATE_KIT:
      return addTag(newState, 'activate-kit', 'success', 'Successfully activated kit.');
    case SAVE_PERMISSIONS:
      return addTag(newState, 'save-permissions', 'success', 'Successfully saved permissions.');
    case UPLOAD_FILE:
      return addTag(newState, 'upload-file', 'success', 'You have successfully uploaded your DNA file to AxGen! We will contact you when your AxGen profile is live and ready to view.');
    case 'UPLOAD_STRENGTH_PLAN_FULFILLED':
      return addTag(newState, 'upload-strength', 'success', 'You have successfully uploaded a strength plan for an athlete!');    
    case 'RESET_PASSWORD_FULFILLED':
      return addTag(newState, 'reset-password', 'success', 'If an account with that email exists, we will send an email with reset instructions.');
    case 'CHANGE_PASSWORD_FULFILLED':
      return addTag(newState, 'change-password', 'success', 'Successfully changed password. Click on the link below to log in.');
    case 'SAVE_ACCOUNT_SETTINGS_FULFILLED':
      return addTag(newState, 'save-account-settings', 'success', 'Successfully updated account settings.');
    case CREATE_ADMIN_CONSULTATION:
      return addTag(newState, 'create-consultation', 'success', 'Successfully assigned consultation.');
    case 'ORDER_CONSULTATION_REJECTED':
      return addErrors(newState, 'order-consultation', action);
    case 'ORDER_CONSULTATION_FULFILLED':
      return addTag(newState, 'order-consultation', 'success', 'Order successful. Click next to schedule your consultation.');
    case ADD_MANAGER:
      return addTag(newState, tag, level, msg);
    case REMOVE_MANAGER:
      return addTag(newState, tag, level, msg);
    default:
      return state;
  }
};

export default alertReducer;
