import React from 'react';
import GeneTable from '../../components/gene-table';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';

class MgResults extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>{this.props.userName ? `${this.props.userName}'${this.props.nameEndsWithS ? '' : 's'}` : ''} Results for Magnesium Levels</h2>
          <h5>[<a href="https://www.ncbi.nlm.nih.gov/pubmed/20700443">Meyer et al., 2010</a>]</h5>
        </div>
        <div className="card">
          <BetaHistogram crm={this.props.crm} result={this.props.result} crmKey='results-mg-histogram' />
          <p>To use this graph, find the red line that shows your genetic score.  The blue histogram shows the number of people and their magnesium genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly lower level of magnesium.  </p>
          <p>Your genetic score for magnesium level is <strong>{ typeof this.props.result.value === "number" ? `${this.props.result.value.toFixed(2)} mmol/L` : null}</strong>, which modifies your overall magnesium level (0.75 to 0.95 mmol/L average).</p>
          <p><TagPercentile result={this.props.result} tag='mg'/></p>
        </div>
      
          <div className="card">
          <p>The <b>score</b> column shows how your genotype affects your levels of magnesium.</p>  
          <GeneTable tag='mg' cols={[ 'gene', 'alleleFreq', 'beta', 'running-total' ] } />
          <p>The <i>TRPM6</i> gene encodes an ion channel protein that functions in epithelial magnesium transport, specifically in the active magnesium absorption in the gut and kidney. Variations in this gene (such as rs11144134) are associated with hypomagnesemia and with secondary hypocalcemia.</p>
          </div>
          <div className='title-break'>
          <span> The genetic test has a <b>small</b> effect on magnesium</span>
          </div>
          <div className="card">
          <p>These 6 SNPs combine to explain 1.6% of the variance in serum magnesium levels, which means that genetics has a much smaller influence on magnesium level compared to diet and activity. </p>
          </div>
      </div>
    )
  }
}
export default MgResults;
