import React from 'react';

class LactoseIntro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
        <h2>Introduction to Lactose Intolerance</h2>
        <p>written by Dr. Megan Roche, M.D.</p>
        </div>
        <div className='title-break'>
          <span>Overview</span>
        </div>
        <div className="card">
          <img src="/assets/imgs/results/content/dairy.jpeg" height={200} className="overview-img" alt="" />
          <p>Lactose intolerance is an inability to digest lactose, which is a disaccharide found in dairy products. Lactose intolerance is caused by reduced production of lactase (the enzyme that breaks down lactose) in the small intestine after childhood years. Most individuals with lactose intolerance experience a loss of lactase expression around 2-3 years of age and have a minimal quantity of lactase by 5-10 years of age.</p>
          <p>Symptoms of lactose intolerance include abdominal pain, flatulence, bloating, and diarrhea after ingestion of dairy products. A subset of individuals may retain some lactase activity, resulting in milder symptoms and greater tolerance to dairy. Individuals with faster transit of lactose to the large bowel are often more symptomatic. Further, undigested lactose can cause abdominal distension and intestinal hypersensitivity in some individuals (<a href="https://www.uptodate.com/contents/lactose-intolerance-clinical-manifestations-diagnosis-and-management">Hammer and Hoegnauer, 2019</a>).</p>
        </div>
        <div className="card">
          <p>The enzyme lactase is responsible for hydrolyzing the disaccharide lactose into the monosaccharides glucose and galactose. Lactase is found in the small intestine brush border on finger-like projections called microvilli. If individuals have low lactase activity, up to 75% of lactose can pass unabsorbed into the large bowel (<a href="https://www.aafp.org/afp/2002/0501/p1845.html">Swagerty et al., 2017</a>). In the large bowel, unabsorbed lactose is converted to short-chain fatty acids and products of fermentation, which can cause symptoms of lactose intolerance.</p>
        </div>
        <div className="card">
          <p>Intolerance to lactose is common and can occur from a variety of causes. The most common one is acquired primary lactase deficiency or lactase non-persistence. Less common causes include congenital lactase deficiency (absence of lactase activity at birth), developmental lactase deficiency (low lactase levels as a result of prematurity), and secondary lactose malabsorption (from underlying intestinal disease) (<a href="https://pmj.bmj.com/content/81/953/167.info">Matthews et al., 2013</a>).</p>
        </div>
        <div className="card">
          <p>The ability to metabolize lactose as an adult is more common in populations that historically had access to domesticated cows or that consumed milk products into adulthood.  For instance, Northern Europeans and some ethnic groups from Africa often maintain elevated lactase activity in adulthood (<a href="https://www.uptodate.com/contents/lactose-intolerance-clinical-manifestations-diagnosis-and-management">Hammer and Hoegnauer, 2019</a>). In contrast,  lactose intolerance  is more common in Asian, Hispanic, and African American populations. Overall, lactose intolerance occurs in up to 15% of individuals of Northern European descent, up to 80% of African-Americans and Latinos, and up to 100% of Asians (<a href="https://www.aafp.org/afp/2002/0501/p1845.html">Swagerty et al., 2017</a>).
          </p>
        </div>
        <div className='title-break'>
          <span>Lactose Intolerance Checklist</span>
        </div>
        <div className="card">
          <ul>
            <li>If you are experiencing symptoms of lactose intolerance, consider avoiding lactose-containing products. A nutritionist or registered dietitian can help guide dietary restrictions. </li>
            <li>Review foods with high lactose composition.</li>
            <li>Lactase supplementations (i.e. Lactaid or Lactrase) may help alleviate symptoms in some individuals with lactose intolerance.</li>
            <li>If you experience mild symptoms or are genetically susceptible to lactose intolerance, consider reducing lactose consumption in the week prior to competition as well as during competition.</li>
            <li>Monitor vitamin D and calcium intake if unable to consume dairy products. </li>
            <li>If you are experiencing severe symptoms related to lactose intolerance, schedule a consultation with a physician for further testing.</li>
          </ul>         
        </div>

      </div>
    )
  }
}

export default LactoseIntro;
