import React, { useState, useEffect } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import * as Actions from '../actions';
import Alerts from '../components/alerts';
import { formatAddress, validateAddressInfo } from '../utils/components';


// renders the payment form for consultations
// price is passed from the parent component book-consultation
// consultOrderError is sent by the parent component only when there is an error alert, this un-disables the submit button here
const ConsultStripeForm = ({ session, consultant, consultType, price, coupon, consultOrderError }) => {
  const [disabledSubmit, disable] = useState(false);
  const [origPrice] = useState(price); //state that is set when component mounts to the original price. this is saved to show a crossed out price if one applies a coupon. this feature is for vanity not function
  const [couponCode, addCode] = useState("");
  const [waiver, checkWaiver] = useState(false);
  const [address, setAddress] = useState({
    name: "",
    street: "",
    apt: "",
    city: "",
    state: "",
    zip: "",
    country: ""
  });

  // Stripe hooks
  const stripe = useStripe();
  const elements = useElements();

  // toggles the checkbox to true or false depending on the current state the checkbox is in
  const toggleChecked = () => {
    if (waiver) {
      checkWaiver(false);
    } else {
      checkWaiver(true);
    };
  };
  
  const handleSubmit = (evt) => {
    evt.preventDefault();
    Actions.clearAlerts();
    disable(true);
    payForConsultation();
  };

  // updates address state
  const handleChange = (e) => {
    e.preventDefault();
    let copiedAddress = Object.assign({}, address);
    copiedAddress[e.target.name] = e.target.value;
    setAddress(copiedAddress);
  };

  // undisables the submit button if there is a consultation error
  // added because the previous schema did not disable the button properly on submission. now alerts from the parent component can override the local state,  
  useEffect(() => {
    if (consultOrderError) {
      disable(false);
    };
  }, [consultOrderError]);

  // handles paying for the consultation
  // the price used is the local price, as that allows this form to handle
  // prevents users from buying strength training unless they have checked the box already.
  const payForConsultation = () => {
    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement, {name: session.userName}).then(resp => {
      const { token, error } = resp;
      if (error) {
        Actions.addAlert('order-consultation', 'error', error.message);
        return;
      };
      if (!waiver && consultType === 'strength-consultation') {
        Actions.addAlert("order-consultation", "error", "You must read and agree to the AxGen Fitness Waiver before purchase.");
        return;
      };
      if (consultType && !validateAddressInfo(address)) {
        Actions.addAlert("order-consultation", "error", "You must provide a valid billing address.");
        return;
      };
      if (token) {
        Actions.orderConsult(consultant, consultType, token, (price * 100).toFixed(0), coupon ? couponCode : "", formatAddress(address));
      };
    });
  };

  // gets the discount string for presentational reasons if there is a coupon
  const _getDiscountString = () => {
    switch(coupon.type) {
      case 'fixed':
        return `$${coupon.amount} discount applied`;
      case 'percent':
        return `${coupon.amount}% discount applied`;
      default:
        break;
    };
  };
  
  // applies discount for coupons
  // clears coupon state before applying a new one
  // a successful discount results in the coupon being passed into this component
  const _applyDiscount = () => {
    Actions.clearAlerts();
    Actions.removeCoupon();
    Actions.loadCoupon(consultType, couponCode);
  };


  // renders the discount string if the coupon succeeds
  const discountStr = coupon ? (
    <span className="text-success ml-3">{_getDiscountString()}</span>
  ) : (
    null
  );

  // the following handles the submit button
  // renders the buy text
  const buyText = disabledSubmit ? 'BUYING CONSULTATION' : 'BUY CONSULTATION';
  // renders the price crossed out if coupon
  const crossedOutPrice = coupon ? (
    <small className="ml-1">
      <strike>{`$${origPrice}`}</strike>
    </small>
  ) : (
    null
  );
  // renders the button itself if the price already exists
  const priceBtn =
    <span>
      {`${buyText} $${price}`} {crossedOutPrice}
    </span>

  // redirects user to the strength waiver page.
  // while I am not the biggest fan of using target='_blank', it is necessary in this case, as it ensures that a new window is opened when clicked on. this is important because if the link redirects the user rather than opening a new window, all of the text the user already entered on the form would be cleared. adding rel="noopener noreferrer" prevents the issues associated with _blank
  const fitnessWaiver = consultType === 'strength-consultation' ? (
    <div className="d-flex align-items-center mb-3">
      <input
        type="checkbox"
        className="mr-3 checkbox-lg"
        checked={waiver}
        onChange={(e) => toggleChecked()}
        required
      />
      <div className="flex-grow-1">I am at least 18 years old, have read and am agreeing to the <a href="/fitness-waiver" target="_blank" rel="noopener noreferrer">AxGen Fitness Waiver</a> for myself or my 13-17 year old minor child.</div>
    </div>
  ) : (
    null
  );

  const addressForm = consultType ? (
    <div>
      <div className="mb-3">
        <strong>Billing Details</strong>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="name"
            placeholder="full name*"
            className="form-control"
            value={address.name}
            required
          />
        </div>
        <div className="col-sm-8">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="street"
            placeholder="street address*"
            className="form-control"
            value={address.street}
            required
          />
        </div>        
        <div className="col-sm-4">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="apt"
            placeholder="apt / unit"
            className="form-control"
            value={address.apt}
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="city"
            placeholder="city*"
            className="form-control"
            value={address.city}
            required
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="state"
            placeholder="state / province*"
            className="form-control"
            value={address.state}
            required
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="zip"
            placeholder="zip / postal code*"
            className="form-control"
            value={address.zip}
            required
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="country"
            placeholder="country*"
            className="form-control"
            value={address.country}
            required
          />
        </div>
      </div>
    </div>
  ) : (
    null
  );

  // conditionally determines the disabled button state for each order type as while the button is the same for all in functionality, disabled state differs
  let disabledState;
  if (consultType === 'strength-consultation') {
    disabledState = disabledSubmit || !waiver || !validateAddressInfo(address);
  } else if (consultType === 'genetics-consultation') {
    disabledState =  disabledSubmit || !validateAddressInfo(address);
  } else {
    disabledState = disabledSubmit || !consultant
  };
  

  return(
    <div>
      <div className="subsection-title mb-2">Pay for Consultation</div>
      <form className="form credit-form">
        <div className="consult-card mb-4">
            <div>
              <div className="mb-2"><strong>Credit Card</strong></div>
                <CardElement className="mb-4 stripe-ele" />
              </div>
              { addressForm }
            <div className="mb-2">
              <strong>Discount Code</strong>
              {discountStr}
            </div>
            <div className="input-group mb-4">
              <input
                type="text"
                placeholder="discount code"
                className="form-control mb-0"
                value={couponCode}
                onChange={(e) => addCode(e.target.value)}
              />
              <div className="input-group-append">
                <button className="btn btn-outline-axgen" type="button" onClick={() => _applyDiscount()}>Add Discount</button>
              </div>
                <Alerts tag='load-coupon' />
            </div>
            { fitnessWaiver }
        </div>
        <button
          type="button"
          className="form-control btn btn-axgen mb-4"
          disabled={disabledState}
          onClick={(e) => handleSubmit(e)}>
            { priceBtn }
        </button>
      </form>
    </div>
  )
}

export default ConsultStripeForm;