import React from 'react';


const HFEIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Hereditary Hemochromatosis</h2>
      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/HFE_fig.png" height={400}/> 
        <p>Hereditary hemochromatosis is a disorder in which the body can build up too much iron in the skin, heart, liver, pancreas, pituitary gland and joints. Normally humans extract needed iron from food via the intestines. When there is an adequate amount of iron, the body reduces its absorption to avoid excessive accumulations. In a person with hereditary hemochromatosis, the mechanism for regulating iron absorption is faulty and the body absorbs too much iron. Too much iron is toxic to the body and over time the high levels of iron can damage tissues and organs and lead to:</p>
        <ul>
          <li>Cirrhosis (liver damage).</li>
          <li>Hepatocellular carcinoma (liver cancer).</li>
          <li>Heart problems.</li>
          <li>Arthritis (joint pain).</li>
          <li>Diabetes.</li>
        </ul>
      </div>
      <div className='title-break'>
        <span>Cause</span>
      </div>
      <div className="card">
        <p>The most common type of hereditary hemochromatosis is Type 1, which is a genetic disease resulting from mutations in the HFE gene. The most common HFE mutation is rs1800562, in which the A allele causes a cysteine to replace tyrosine at position 282 of the protein. Hereditary hemochromatosis is one of the most common genetic disorders in the U.S. An estimated 10 percent of the U.S. population are carriers of the rs1800562(A) mutation and about 1 percent are homozygous for rs1800562(A/A). Caucasians of northern European descent are at highest risk. </p>
        <p>In more than 80% of hereditary hemochromatosis cases, individuals are homozygous for the rs1800562 mutation (A/A). Genetic testing can lead to early diagnosis of hereditary hemochromatosis, when it is easily and effectively treated. Relatives of people with the HFE rs1800562(A) mutation should undergo testing since they may have the variant without knowing it.</p>
      </div>
      <div className='title-break'>
        <span>Symptoms</span>
      </div>
      <div className="card">
        <p>Symptoms of hemochromatosis usually appear after age 40 in men and age 60 in women. Women are more likely to develop symptoms after menopause, when they no longer lose iron from menstruation and pregnancy. Some people with hemochromatosis never have symptoms.</p>
        <p>Early symptoms of hereditary hemochromatosis may include extreme tiredness (fatigue), joint pain, abdominal pain, weight loss, and loss of sex drive. As the condition worsens, affected individuals may develop arthritis, liver disease (cirrhosis) or liver cancer, diabetes, heart abnormalities, or skin discoloration. The appearance and severity of symptoms can be affected by environmental and lifestyle factors such as the amount of iron in the diet, alcohol use, and infections.</p>
      </div>
      <div className="title-break">
        <span>Prevention</span>
      </div>
      <div className="card">
        <p>The earlier hemochromatosis is diagnosed, the less likely you are to develop serious complications that can cause permanent problems. If you are diagnosed with hemochromatosis, regularly scheduled blood removal is the most effective way to lower the amount of iron in your body. Your doctor may also recommend:</p>
        <ul>
          <li>Annual blood tests to check your iron levels.</li>
          <li>Liver biopsy to check for cirrhosis.</li>
          <li>Iron chelation therapy, if you cannot have blood removed. This involves medicine taken either orally or injected to lower the amount of iron in your body.</li>
          <li>Dietary changes, such as avoiding multivitamins, vitamin C supplements, and iron supplements, which can increase iron throughout your body.</li>
          <li>No alcohol use (because alcohol increases the risk of liver damage).</li>
          <li>Steps to prevent infections, including not eating uncooked fish and shellfish and getting recommended vaccinations, including those against hepatitis A and B.</li>
        </ul>
      </div>
    </div>
  )
}

export default HFEIntro;
