let defaultState = {
    workoutPlan: null,
    workoutPlanDownload: null,
    athletes: []
}

const strengthReducer = (state = defaultState, action) => {
    Object.freeze(state);
    switch(action.type) {
        case 'GET_WORKOUT_FULFILLED':
            const newState = Object.assign({}, state);
            newState.workoutPlan = action.payload.data.workoutPlan;
            newState.workoutPlanDownload = action.payload.data.workoutPlanDownload;
            return newState;
        case 'LOAD_ATHLETES_FULFILLED':
            const nextState = Object.assign({}, state);
            nextState.athletes = action.payload.data;
            return nextState;
        case 'CLEAR_STRENGTH_PLAN':
            const clearedState = Object.assign({}, state);
            clearedState.workoutPlan = null;
            clearedState.workoutPlanDownload = null;
            return clearedState;
        default:
            return state;
    }
};

export default strengthReducer;