import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';


export const FEVResults = ({ userName, nameEndsWithS, uploadID, width }) => {
    // this page contains the results of multiple tests
    const { crm, uploads } = useSelector((state) => ({
        crm: state.crm,
        uploads: state.results.uploads,
    }));

    // gets the score for a given tag and returns the score sentence. 
    const getScoreContent = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        let score = tagResult.hasOwnProperty("value") ? tagResult['value'] : null;
        let prefixedScore;
        if (score !== null) {
            prefixedScore = score > 0 ? `+${score.toFixed(2)}` : `${score.toFixed(2)}`;
        };
        return (
            <strong>{prefixedScore}</strong>
        );
    };

    const getGraph = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        const crmKey = `results-${tag}-histogram`;
        return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
    };

    const getPercentile = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        return <TagPercentile result={tagResult} tag={tag} />;
    };

    return (
        <div>
            <div className='title-margins'>
                <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Heart Rate Variability</h2>
                <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/28613276/">Nolte et al., 2017</a>]</h5>
            </div>
            <div className="card">
                <p>The heart rate variability genetic score is based on 17 DNA markers from <a href="https://pubmed.ncbi.nlm.nih.gov/28613276/">Nolte et al., 2017</a>. HR variability was assessed in about 28 thousand people using the root mean square of the successive differences of inter beat intervals (RMSSD). </p>
            </div>
            <div className="card">
                {getGraph("HRV")}
                <p>The x-axis shows your HR variability score (RMSSD) compared to the average. To use this graph, find the red line that shows your genetic score.  The blue histogram shows the number of people and their HR variability genetic scores. The bin containing your score tells you how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, your heart rate variability will tend to be slightly higher.  </p>
                <p>Based solely on your genetics, your heart rate variability score is {getScoreContent("HRV")} bpm. </p>
                <p className="mb-0">{getPercentile("HRV")}</p>
            </div>
            <div className='title-break'>
                <span>How does my genetic score affect my training? </span>
            </div>
            <div className="card">
                <p>The score informs how genetics affects your HR variability. For instance, if your genetic score is +0.1, then your HR variability (RMSSD) will be shifted 0.1 higher throughout your life. This is good news as higher HR variability is associated with fitness and health. However, this also means that the HR variability you measure with your wearable is equivalent to HR variability that is 0.1 LOWER for others. </p>
            </div>
            <div className='title-break'>
                <span>The genetic test has a <b>moderate</b> effect on your HR variability</span>
            </div>
            <div className="card">
                <p>The 16 SNPs in the genetic score from <a href="https://pubmed.ncbi.nlm.nih.gov/28613276/">Nolte et al., 2017</a> explain 2.3% of the variability in HR variability in the general population, which includes elderly, sedentary individuals as well as young, highly-trained athletes. The HR variability in this highly heterogeneous population is large due to many factors such as age, stress level and training status. </p>
                <p>In a homogenous group of young, well-trained athletes, the range in HR variability is much smaller than in the general population. The effect of genetics on heart rate variability amongst similar athletes is likely to be much larger than 2.3%, but it will still be small compared to the effect of your training.</p>
            </div>
        </div>
    );
};

export default FEVResults;
