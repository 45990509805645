import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';
import GeneTable from '../../components/gene-table';


export const VTE2Results = ({ userName, nameEndsWithS, uploadID, width }) => {
  // this page contains the results of multiple tests
  const { crm, uploads } = useSelector((state) => ({
    crm: state.crm,
    uploads: state.results.uploads,
  }));

  const getGraph = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    console.log('tag tagResult :',tag,  tagResult)
    const crmKey = `results-${tag}-histogram`;
    return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
  };

  const getPercentile = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});  
    return <TagPercentile result={tagResult} tag={tag} usesUKData={false} />;
  };

  return (
    <div>
      <div className='title-margins'>
        <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Venous Thromboembolism</h2>

      </div>
      <div className='title-break'>
        <span>Overview</span>
      </div>
      <div className="card">
        <p>There are three genetic tests for venouos thromboembolism (VTE), a clotting disorder. The first two tests look at Mendelian mutations in the Factor V or Factor II genes, which encode blood clotting proteins. The third is a polygenic risk score comprised of 297 genes. The three tests are independent of each other, and each indicates about a three-fold increased risk for VTE.</p>
      </div>
      <div className='title-break'>
        <span>Mendelian Mutations</span>
      </div>
      <div className="card">
        <p>The Factor V Leiden and Factor II (prothrombin) mutations are low-frequency variants that each confer a 2–3-fold risk of VTE. These mutations are frequently tested in clinical settings to evaluate the role of inherited thrombophilia predisposing to acute thrombotic syndromes. </p>
        <p>Factor II and Factor V are coagulation proteins in your blood. The rs1799963(A) allele in Factor II is a mutation in the mRNA that increases production of the Factor II protein. The rs6025(A) allele in Factor V is a mutation known as the Leiden mutation that causes an Arginine to Glutamine amino acid change at position 506 in Factor V. </p>

        <GeneTable tag='vte2' cols={[ 'gene', 'beta'] } />
        </div>

      <div className='title-break'>
        <span>Polygenic Risk Score</span>
      </div>

      <div className="card">
      <p>Recently, a polygenic risk score (PRS) for VTE was developed using 297 SNPs (<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6858581/">Klarin et al., 2019</a>). Although each of the SNPs contributes a small amount of information about VTE risk, the combined score provides a substantial amount of information, equivalent to the effect from the Factor V Leyden mutation. The score does not utilize either the Factor V rs6025 or Factor II rs1799963 mutations, so it provides a third, independent assessment of one's risk for VTE. </p>
      <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have higher risk for venous thromboembolism. </p>

        { getGraph("vte") }
        <p className="mb-0">{getPercentile("vte")}</p>
        <p>People with a PRS score above 95% have a 2.89-fold increased risk of VTE relative to the rest of the population. </p>

      </div>





    </div>
  );
};

export default VTE2Results;
