import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';

export const IBDIBD = (props) => {
    const { crm, result } = props;
    let testComponent;
    if (result['value'] !== null) {    
        // let prefixedGeneticScore = result['value'] > 0 ? `+${result['value'].toFixed(2)}` : `${result['value'].toFixed(2)}`;
        
      testComponent =
      <div>
        <div className="card">
        <p>The AxGen test for inflammatory bowel disease uses a polygenic risk score with 177 genetic markers from <a href="https://pubmed.ncbi.nlm.nih.gov/35324888/">Tanigawa et al., 2022</a>. </p>
        </div>
        <div className="card">
          <BetaHistogram crm={crm} result={result} crmKey='results-IBD-histogram' />
          <p>The x-axis shows risk for inflammatory bowel disease. To use this graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic score for risk for inflammatory bowel disease. The bin containing your score tells you how many other people have a score like yours. <b>If your score is on the <strong>left side of the histogram</strong>, you will tend to have a slightly higher risk for inflammatory bowel disease.</b>  </p>
          <p><TagPercentile result={props.result} tag='IBD'/> </p>
        <img src="/assets/imgs/results/ibd_graph.png" height={400} className="overview-img" alt="" />
        <p>The Figure shows how your genetic score affects your risk for inflammatory bowel disease. For instance, if your percentile is below 10%, your risk for inflammatory bowel disease is 2.1-fold higher than the average person. The prevalence of inflammatory bowel disease for the entire US is about 1.4% (<a href="https://pubmed.ncbi.nlm.nih.gov/33771127/">Freeman et al., 2021</a>), so that a genetic score below 10% indicates that you have a 3.36% chance (2.1 fold x 1.4% = 3.36%). A 2.1-fold increased risk for inflammatory bowel disease is significant, but your chance of getting it is still low because the disease is rare.</p>
        </div>
        <div className='title-break'>
          <span>The genetic test has a <b>moderate</b> effect on risk for inflammatory bowel disease. </span>
        </div>
        <div className="card">
        <p>The impact of the inflammatory bowel disease genetic score on an entire population is small because only 10% of people are affected. However, for the subset of people with a low score, the test is significant because it indicates a 2.1-fold increased risk for the disease. </p>
      </div>
      </div>

      return (
      <div>
        <div className='title-margins'>
        <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Inflammatory Bowel Disease</h2>
        </div>
        { testComponent }

      </div>
    );
    };
};


export default IBDIBD;
