import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import { useSelector } from 'react-redux';


export const HRrestEx = ({ userName, nameEndsWithS, uploadID, width }) => {
  // this page contains the results of multiple tests
  const { crm, uploads } = useSelector((state) => ({
    crm: state.crm,
    uploads: state.results.uploads,
  }));

  // gets the score for a given tag and returns the score sentence.  HRex has tagresult null, but ferritin has tagresult?  HRex has no results.  HRex is not a tag, so need to get it someother way?  HRex has result in upload_entry_results SQL table. uploads[uploadID] results does not include HRex but does include transferrin saturation?
  const getScoreContent = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    let score = tagResult.hasOwnProperty("value") ? tagResult['value'] : null;
    let prefixedScore;
    if (score !== null) {
      prefixedScore = score > 0 ? `+${score.toFixed(2)}` : `${score.toFixed(2)}`;
    };
    return (
      <strong>{prefixedScore}</strong>
    );
  };

  const getGraph = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    const crmKey = `results-${tag}-histogram`;
    // HRex has crmkey but no results.
    return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
  };

  const getPercentile = (tag) => {
    const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
    return <TagPercentile result={tagResult} tag={tag} />;
  };

  return (
    <div>
      <div className='title-margins'>
        <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Heart Rate increase during Exercise</h2>
        <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/29769521/">Ramirez et al., 2018</a>]</h5>
      </div>
      <div className="card">
        <p>The AxGen test for Heart Rate increase during exercise uses a genetic algorithm with 14 genetic markers developed by <a href="https://pubmed.ncbi.nlm.nih.gov/29769521/">Ramirez et al., 2018</a>. Heart rate was measured in about 68 thousand people during a 6 min cycling exercise in the <a href="https://biobank.ndph.ox.ac.uk/showcase/showcase/docs/Cardio.pdf">Cardio test</a> from UK Biobank. The exercise test started with 15 s of rest (pre-test), followed by 6 min of exercise (cycling) initially at constant load (2 min), then at increasing workload (4 min). The increase in HR is the difference between the maximum HR minus the resting HR. </p>
      </div>
      <div className="card">
        {getGraph("HRex")}
        <p>The x-axis shows the relative increase in HR during exercise compared to others taking into account height, weight age and sex. To use this graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic score for increase in heart rate during exercise. The bin containing your score tells you how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have a slightly higher increase in heart rate.  </p>
        <p>Based solely on your genetics, your predicted Heart Rate exercise score is {getScoreContent("HRex")} bpm. </p>
        <p className="mb-0">{getPercentile("HRex")}</p>
      </div>
      <div className='title-break'>
        <span>How does my genetic score affect my training? </span>
      </div>
      <div className="card">
        <p>The score informs how genetics affects your increase in HR during exercise. For instance, if your genetic score is +1 bpm, then your HR during  exercise will be shifted 1 bpm higher throughout your life. This is good news as a a higher HR during exercise is associated with fitness and health. However, this also means that the HR you measure with your watch is equivalent to a HR that is 1 bpm LOWER for others. So if your HR increases 140 bpm during your workout, it  is equivalent to a HR increase of 139 bpm for others. </p>
      </div>
      <div className='title-break'>
        <span>The genetic test has a <b>small</b> effect on your heart rate increase during exercise.</span>
      </div>
      <div className="card">
        <p><a href="https://pubmed.ncbi.nlm.nih.gov/29769521/">Ramirez et al., 2018</a> estimated that the 14 SNPs in the genetic score explain 0.8% of the variability in HR acceleration in the general population, which includes elderly, sedentary individuals as well as young, highly-trained athletes. This highly heterogeneous group has a large range of HR acceleration scores due to many factors such as age and training status. </p>
        <p>In a homogenous group of young, well-trained athletes, the range in HR acceleration is much smaller than in the general population. This means that your genetics has a significant effect when comparing your resting HR to that of similar athletes. Hence, the effect of genetics on heart rate increase amongst similar athletes is likely to be much larger than 0.8%, but it still may be small compared to the effect of your training.</p>
      </div>
    </div>
  );
};

export default HRrestEx;
