import React from 'react';
import ReactTooltip from 'react-tooltip'
import { _tooltipper, _vidListMaker } from '../../utils/prehab';
import { Link } from 'react-router-dom';

const vids = {
  'Banded Ankle Mobility': 'https://www.youtube.com/embed/9a8Dt8yRkM4?rel=0',
  'Foot Intrinsics': 'https://www.youtube.com/embed/g318_pGT50Q?rel=0',
  'Short Foot Single Leg Squats': 'https://www.youtube.com/embed/EE5-PXOGBgE?rel=0',
  'Posterior Tibialis Heel Raises': 'https://www.youtube.com/embed/hQxhrfyxuj4?rel=0',
  'Hamstring Flossing': 'https://www.youtube.com/embed/-gXwoLyyzBE?rel=0',
  'Storks': 'https://www.youtube.com/embed/SffFKtEUSAk?rel=0',
  'Inline Lunge': 'https://www.youtube.com/embed/Oq6Dt06ya2Y?rel=0',
  'Single Leg Med Ball Throw': 'https://www.youtube.com/embed/x8eY5FgRljg?rel=0'
};


const PlantarExercises = (props) => {
  const vidList = _vidListMaker(vids);
  const { width } = props;
    
  return (
    <div>
      <div className="title-margins">
        <h2>Plantar Fasciitis Prehabilitation Exercises</h2>
      </div>
      <div className="card p-1">
        {vidList}
      </div>
      <div className="card">
        <p className="mb-0">The videos above are examples of exercises that can reduce your chance of injury. For advice personalizing your strength program based on your genetic tests, you can schedule a strength consultation <Link to="/consultations">here</Link>.</p>
      </div>
      <div className="card">
        <p>Optimizing gastrocnemius, soleus, and Achilles tendon flexibility through stretching can minimize tension on the plantar fascia. During the gait cycle, the plantar fascia receives the most tension during ankle dorsiflexion (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC385265/">Bolga and Malone, 2004</a>). Banded ankle mobility exercises help maintain and restore {_tooltipper("range of motion", "range of motion", width)} and equalize any lines of tension that lock out the ankle. The increased range of motion distributes forces up through the gastrocnemius, decreasing impact forces and limiting excess stress on the plantar fascia during the gait cycle.</p>
        <p>There are two types of exercises to {_tooltipper("strengthen", "strength", width)} your foot muscles. The first type, which specifically strengthen the intrinsic foot musculature, include toe intrinsics and short foot single leg squats. Toe intrinsics are {_tooltipper("proprioceptive exercises", "proprioception", width)} that allow for proper control and function of the foot musculature, as they increase multiplanar control at the foot and through the toes. Evenly distributed pressure decreases the risk of tension along a localized area of the plantar fascia. Short foot single leg squats are exercises that increase the strength and mobility of the proximal muscle groups, which reduces both the force transmitted to the lower foot structures and excessive pronation. Studies show a link between weakness of the intrinsic foot musculature and plantar fasciopathy (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/27692740">Huffer et al., 2017</a>; <a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4101314/">Latey et al., 2017</a>). By focusing on technique as opposed to power during intrinsic foot exercises, individuals can work on neuromuscular recruitment of the plantar fascia and improve static and dynamic foot balance (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/23632367">Mulligan and Cook, 2013</a>).</p>
        <p>The second type of exercises are those that support the extrinsic foot musculature in the anterior, posterior and lateral compartments. These exercises include posterior tibialis heel raises and banded ankle mobility. The posterior tibialis is a key muscle in maintaining an arch or short foot and in controlling pronation. Posterior tibialis heel raises are {_tooltipper("activation exercises", "activation", width)} that mitigate excessive pronation, allowing force to be absorbed through the plantar fascia more evenly, thus decreasing the risk of developing plantar fasciitis. Combining the two types of exercises can help with shock absorption through the plantar fascia.</p>
        <p>Finally, proximal weakness or muscle imbalance in the gluteus medius, gluteus minimus, tensor fascia lata, or quadriceps muscles can cause greater transmission of force to the lower foot structures and can contribute to excessive pronation (<a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/22440758">Chuter and Janse de Jonge, 2012</a>). Exercises including storks, inline lunges, hamstring flossing, and the single leg medicine ball throw can increase the strength and mobility of the proximal muscle groups. Storks are exercises that load the gluteus medius, gluteus minimus and tensor fascia lata vertically to mitigate excessive pronation. Inline lunges are a breakdown from the single leg squats. Because they are done in a more stable position, this movement allows the athlete to focus on proper biomechanics while distributing forces evenly through the plantar fascia. Uneven force distribution places the plantar fascia at risk for strains or tears. The posterior line is largely innervated by the sciatic nerve and expands down the chain. Hamstring flossing ensures proper innervation of the lower leg musculature, preventing faulty biomechanics and compensatory patterns.</p>
        <p className="mb-0">{_tooltipper("Dynamic stabilization", "dynamic", width)} is the last piece of the puzzle and can be improved through the single leg medicine ball throw exercise. In this exercise, the plantar fascia must flex reactively as the medicine ball changes the athlete’s center of gravity. Loading the foot in all planes of motion strengthens the foot in preparation of the demands of athletics.</p>
      </div>
      <ReactTooltip place="top" className="tooltip-axgen" effect="solid" id="exercise-types" />
    </div>
  );
};

export default PlantarExercises;
