import React from 'react';


const ProstateIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Prostate Cancer</h2>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/prostateCancer.png" height={300}/> 
        <p>The prostate is a small, walnut-sized gland that produce seminal fluid in men. Cancer of the prostate is one of the most common types of cancer with over 3 million cases in the US every year. Many types of prostate cancer stay confined to the prostate gland, and may not cause any serious harm or require medical intervention. However, other kinds of prostate cancer can grow and spread aggressively in other parts of the body. </p>
        <p>In early stages, prostate cancer may cause no symptoms. In later stages, it may show any of the following:</p>
        <p>Symptoms</p>
        <ul>
          <li>Pain when urinating or ejaculating</li>
          <li>Need to frequently urinate, especially at night time</li>
          <li>Sudden onset of erectile dysfunction</li>
          <li>Trouble starting and/or stopping urination </li>
          <li>Sudden loss of weight</li>
          <li>Blood in urine or semen</li>
        </ul>
        <p>Risk Factors</p>
        <ul>
          <li>Older age: Prostate cancer most often afflicts men over 50 years old</li>
          <li>Family History: A blood relative who has had prostate cancer indicates an increased risk</li>
          <li>Genetics: Certain genetic markers are associated with an increased risk for prostate cancer. Your AxGen profile will tell you your relative genetic risk.</li>
          <li>Race: For unknown reasons, African-Americans have a greater risk of prostate cancer than other races.</li>
          <li>Obesity: People who are obese may have a greater risk for developing prostate cancer. Obesity also increases the likelihood of aggressive cancer, and the chances that cancer reappears once it has been treated.</li>
        </ul>
      </div>
      <div className='title-break'>
        <span>Genetic Test for Prostate Cancer</span>
      </div>
      <div className="card">
      <p>The results page shows a genetic test for prostate cancer that scans your entire genome for risk. This test shows that certain people have 11.5 fold increased risk for gout than others. </p>
      </div>
     </div>
  )
}

export default ProstateIntro;
