import React from 'react';

const AF_YeIntro = () => {
  return (
    <div>
      <div className='title-margins'>
        <h2>Introduction to Atrial Fibrillation</h2>
      </div>
      <div className="card">
      <img alt="" className="overview-img" src="/assets/imgs/results/afib_fig.png" height={200}/> 
        <p>Atrial fibrillation (AFib) is a common heart condition that affects ~2.7 million Americans. It is characterized by an irregular and often rapid heart rate, which can lead to a variety of health problems. It occurs when the heart’s two upper chambers (the atria) quiver instead of beating effectively. This can cause blood to pool in the atria, which can lead to the formation of blood clots. If these clots travel to the brain, they can cause a stroke. People with AFib have approximately 5 times greater risk of stroke. In addition to stroke, it can also increase the risk of heart failure and other serious complications. </p>
        </div>
        <div className='title-break'>
          <span>Causes</span>
        </div>
        <div className="card">
        <p>The cause of AFib is not always known, but it is often related to other conditions such as high blood pressure, diabetes, thyroid disease and heart disease. It can also be triggered by certain medications, alcohol, or caffeine. In some cases, AFib can be caused by an underlying heart defect or other structural problem. AFib is most common in people over the age of 60. In addition, some people may have an inherited predisposition to AFib. This means that they may be more likely to develop the condition due to their genetics and/or family history. Forty percent of the risk of ischemic stroke is heritable.  </p>
        <p><strong>Genetics:</strong> The results page shows a genetic test for AFib that scans your entire genome for risk. This test shows that certain people have 3.7 fold higher risk than others. The polygenic risk scores is comparable to clinical risk factors in the prediction of ischemic stroke in the general population.</p>
        </div>
        <div className='title-break'>
          <span>Signs and Symptoms</span>
        </div>
        <div className="card">
          <p>Symptoms of atrial fibrillation are:</p>
          <ul>
          <li>Palpitations or a racing heart rate</li>
          <li>Shortness of breath</li>
          <li>Chest pain or discomfort</li>
          <li>Weakness or fatigue</li>
          <li>Lightheadedness or dizziness</li>
          <li>Confusion</li>
          <li>Profuse sweating</li>
        </ul>
        </div>
        <div className='title-break'>
          <span>Diagnosis</span>
        </div>
        <div className="card">
        <p>Atrial fibrillation is typically diagnosed by a doctor through a physical examination and a review of the patient's medical history. The doctor may also order tests such as an electrocardiogram (ECG), echocardiogram, or blood tests to confirm the diagnosis. The current gold standard risk stratification tool uses a combination of clinical risk factors <a href="https://www.mdcalc.com/calc/801/cha2ds2-vasc-score-atrial-fibrillation-stroke-risk#next-steps">(CHA₂DS₂-VASc)</a>.</p>
        </div>
        <div className='title-break'>
          <span>Treatment</span>
        </div>
        <div className="card">
        <p>Treatment for atrial fibrillation typically involves medications to control the heart rate and rhythm, as well as lifestyle changes to reduce the risk of complications. These may include avoiding alcohol and caffeine, exercising regularly, and eating a healthy diet. In some cases, surgery may be necessary to correct an underlying heart defect. If you have been diagnosed with AFib, it is important to work with your doctor to create a treatment plan that is right for you. It is also important to monitor your condition and seek medical attention if you experience any new or worsening symptoms. With proper treatment, AFib can be managed and the risk of complications can be reduced.</p>
        </div>
    </div>
  )
}

export default AF_YeIntro;