import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer';
import * as Actions from '../../actions';
import Alerts from '../../components/alerts';
import { useCustomDispatch } from '../../utils/customHooks';
import { useSelector } from 'react-redux';

const ConfirmEmailPage2 = ({ location, match }) => {
  const attemptConfirmEmailDispatch2 = useCustomDispatch(Actions.confirmEmailThunk2);
  {/* TODO: if external_user_organization: NEED TO MAKE THIS OPTIONAL */}
  // const [loginLink, setLoginLink] = useState("/login")

  const { alerts } = useSelector((state) => ({
    alerts: state.alerts
  }));

  useEffect(() => {
    const token = match.params.token;
    attemptConfirmEmailDispatch2(token);
  }, []);

 
  return (
    <div className="bg self-align-stretch">
      <div className="d-flex self-align-stretch justify-content-center align-items-center h-100">
        <div className="auth-form-parent">
          <div className="auth-form-container">
            <div className="logreg-form">
              <div className="auth-form-header">
                <img alt="" src="/assets/imgs/axgen-logo.png" className="logo-img" />
                <h1>AxGen</h1>
              </div>
                <Alerts tag='confirm-email2' level="success" />
                <Alerts tag='confirm-email2' level="error" />
            </div>
          </div>
        </div>
      </div>
      <Footer forceWhite={true} />
    </div>
  );
};

export default ConfirmEmailPage2;
