import React from 'react';
import classNames from 'classnames';

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.state = { collapsed: true };
  }
  toggleCollapse() {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed
    });
  }
  render() {
    const { header="", body="" } = this.props;
    const { collapsed } = this.state;

    //by making each FAQ item a ul, clearer formatting anf style is achieved, especially for the mobile version
    return (
      <div className={classNames("faq", {"collapsed":collapsed})}>
        <ul className="faq-header" onClick={this.toggleCollapse}>
          <li><span className="faq-toggle fa fa-angle-down"/></li>
          <li><span>{header}</span></li>
        </ul>
        <div className="faq-body">{body}</div>
      </div>
    )
  }
}

export default FAQ;
