import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Toggle from 'react-bootstrap-toggle';
import * as Actions from '../actions';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import Alerts from '../components/alerts';


class PermissionPage extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      disabled: false
    }
  };

  componentDidMount() {
    Actions.clearAlerts();
    this.props.loadPermissions();
  };

  submitForm(evt) {
    evt.preventDefault();
    this.props.clearAlerts();
    this.setState({ disabled: true });
    const permissions = this.props.permissions.map(perm => ({
      active: perm.toggleActive,
      email: perm.email,
      name: perm.name
    }));
    this.props.savePermissions(permissions);
  };

  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.alerts).length !== 0 && (JSON.stringify(this.props.alerts) !== JSON.stringify(prevProps.alerts))) {
      this.setState({ disabled: false });
    };
  };

  render() {
    const { permissions, width } = this.props;
    const { disabled } = this.state;
    const btnWidth = width < 768 ? "col-6" : "col-4";

    return (
      <div>
        <Navbar />
        <div className="container mt-4">
          <h2 className="text-center mb-4">Permissions</h2>
            <div className="d-flex justify-content-center">
              <form className="card form py-3 col-12-sm col-md-10">
                <p>If you want to invite others (such as your trainer or coach) to have access to your genetic results, ask them to register for a free AxGen account and please give them permission below.</p>
                <p>If you see a checkmark in a blue box next to a person's name in the form below, he or she can access your genetic results. If the box is gray, it means that he or she cannot access your results. You can toggle this person's access at any time by clicking on the box and then clicking save.</p>

                <div className="table-responsive mt-3">
                  <table className="permissions-table w-100 col-sm-12 table-condensed table-bordered">
                    <thead>
                      <tr>
                        <th>Access Granted</th>
                        <th>Name</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {permissions.map((permission, i) =>
                        <PermissionEntry
                          key={i}
                          permission={permission}
                          index={i}
                          isLast={i===permissions.length-1}
                          updatePermission={this.props.updatePermission}
                          addPermission={this.props.addPermission}
                        />
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row mt-4">
                  <div className={btnWidth}>
                    <Link to="/"><button className="form-control btn btn-gray">CANCEL</button></Link>
                  </div>
                  <div className={`${btnWidth} ${width < 768 ? '' : 'offset-4'}`}>
                    <button className="form-control btn btn-axgen" onClick={(evt) => { this.submitForm(evt); }} disabled={disabled}>SAVE</button>
                  </div>
                </div>
                <Alerts tag='save-permissions' />
                <Alerts tag='save-permissions' level='success' />
              </form>
            </div>
        </div>
        <br/><br/>
        <Footer />
      </div>
    );
  };
};


const PermissionEntry = ({ permission, index, isLast, updatePermission, addPermission }) => {
  // define refs
  const emailRef = useRef(null);
  const nameRef = useRef(null);

  // marks permission as active or inactive onToggle
  const onToggle = () => {
    let newPermission = Object.assign({}, permission);
    newPermission.toggleActive = !newPermission.toggleActive;
    updatePermission(index, newPermission);
  };

  // handles text changes
  const onChange = () => {
    let newPermission = Object.assign({}, permission);
    if (emailRef.current !== null && nameRef.current !== null) {
      newPermission.email = emailRef.current.value;
      newPermission.name = nameRef.current.value;
      updatePermission(index, newPermission);
      
      // adds blank permission row if last
      if (isLast) addPermission();
    };
  };

  // prevents out of sync state
  useEffect(() => {
    try {
      if (nameRef && nameRef.current && emailRef && emailRef.current) {
        if (permission.name !== nameRef.current.value) nameRef.current.value = permission.name;
        if (permission.email !== emailRef.current.value) emailRef.current.value = permission.email;
      };  
    } catch (e) {};
    
  }, [permission, nameRef, emailRef]);
  

  return (
    <tr>
      <td className="text-center">
        <Toggle
          onClick={() => onToggle()}
          on={<span className="fa fa-check"></span>}
          off={<span className="fa fa-times"></span>}
          onstyle="axgen"
          offstyle="gray"
          active={permission.toggleActive}
          className="form-control"
          style={{maxWidth:'75px'}}
          />
      </td>
      <td>
        <input
          type="text"
          placeholder="name"
          className="form-control mb-0"
          defaultValue={permission.name}
          ref={nameRef}
          onChange={() => onChange()}
          />
      </td>
      <td>
        <input
          type="email"
          placeholder="email"
          className="form-control mb-0"
          defaultValue={permission.email}
          ref={emailRef}
          onChange={() => onChange()}
          />
      </td>
    </tr>
  );
};


const mapStateToProps = state => ({
  alerts: state.alerts,
  permissions: state.permissions,
  width: state.ui.width
});

const mapDispatchToProps = dispatch => ({
  loadPermissions: () => dispatch(Actions.loadPermissionsThunk()),
  savePermissions: permissions => dispatch(Actions.savePermissionsThunk(permissions)),
  clearAlerts: () => Actions.clearAlerts(),
  updatePermission: (index, permission) => dispatch(Actions.updatePermissionThunk(index, permission)),
  addPermission: () => dispatch(Actions.addPermissionThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionPage);
