import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TableComposer from '../../components/table-composer';
import { loadResultsThunk, loadSNPsThunk } from '../../actions';
import { useCustomDispatch } from '../../utils/customHooks';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';

const AlzResults = (props) => {
    const [uID, setID] = useState(null);
    const { crm, userName, nameEndsWithS, uploadID, width } = props;

    // the table should be presentational and not have to reply on endpoints
    // the results page needs to pass esssential information to the table
    const { uploads, snps } = useSelector((state) => ({

        snps: state.results.snps,
        uploads: state.results.uploads
    }));

    // dispatches
    const loadResults = useCustomDispatch(loadResultsThunk);
    const loadSNPs = useCustomDispatch(loadSNPsThunk);

    // capitalizes the riskVal so it looks better for table presentation
    // iterates word by word capitalizing first letter
    // conditional removes rogue space at end
    const _formatter = (str) => {
        let strArr = str.split(" ");
        let newStr = "";
        for (let i = 0; i < strArr.length; i++) {
            let word = strArr[i];
            let firstChar = word[0];
            let wordEnd = word.slice(1);
            if (i === strArr.length - 1) {
                newStr += (firstChar.toUpperCase() + wordEnd);
            } else {
                newStr += (firstChar.toUpperCase() + wordEnd + " ");
            }
        };
        return newStr;
    };

    //   iterates through array to add extra cols to the object
    //   default value of "-" for these values
    //   const _addExtraCols = (obj, colArr) => {
    //     for (let i = 0; i < colArr.length; i++) {
    //       let key = colArr[i];
    //       obj[key] = "-";
    //     };
    //     return obj;
    //   };

    // adds the keys for the result value and then adds the extra columns that are in an array
    // default values of "?/?" for genes, which will be changed in subsequent formatting
    const _addKeysToResult = (snpsArray) => {
        let resultObj = {};
        for (let i = 0; i < snpsArray.length; i++) {
            let snpObj = snpsArray[i];
            let key = snpObj['id'];
            resultObj[key] = "?/?";
        };

        // _addExtraCols(resultObj, ["risk"]);

        return resultObj;
    };

    // stiches together the resultObj with a user's genotype for use in the gene table for ibuprofen
    const _snpStitcher = (genes, resultObj) => {
        for (let key in resultObj) {
            if (genes[key]) {
                resultObj[key] = genes[key];
            };
        };

        return resultObj;
    };

    // adds risk val to return obj under the key of 'sensitivity' so stitching together genes and snps works better
    const _addRiskVal = (geneObj, riskVal) => {
        geneObj['sensitivity'] = _formatter(riskVal);
        return geneObj;
    };

    // turns this { rs1234: ["A", "A"] } to this { rs1234: "AA" }
    // if there is no genotype for the given gene, returns "??" in the place of alleles
    const _geneCombinator = (genes) => {
        for (let gene in genes) {
            const arr = genes[gene];
            const joined = arr.join("/");
            const res = joined === "" ? "?/?" : joined;
            genes[gene] = res;
        };
        return genes;
    };

    // keys into the uploads state, which contains the user's results for every test, and extracts only an object of snps and user genotypes
    // ensures that the uploads state is duplicated to avoid modification of state
    // formats the genes and then adds the extra col that based on risk value into the return val
    // overall ensures that userGenes can be smoothly integrated into the resultObj
    const _dataExtractor = (tag) => {
        const extracted = uploads[uID]["results"][tag]["meta"];
        const dupedGenes = Object.assign({}, extracted["genes"]); //duplicate genes state
        const label = uploads[uID]["results"][tag]["shortLabel"];
        _geneCombinator(dupedGenes); //add formatted genes
        _addRiskVal(dupedGenes, label); //add sensitivity to the dupedGenes state. since the sensitivity is already calculated in the results reducer and put into the "shortlabel", this value is used for presentational purposes
        return dupedGenes;
    };

    // this will combine a user's results with snps, so only one param needs to be passed into TableComposer
    // accomodates for edge case using try catch, as the line uploads[uID][tag]["meta"]["genes"] causes a fatal error if not handled properly. this error occurs when a user lacks results for a given snp
    const _fillInTableConfigs = (tag, snps) => {
        const snpsCopy = Object.assign({}, snps); //makes copy of snps so as to not mutate the original state
        const snpsArray = snpsCopy[tag]; //keys into snps to extract array
        const resultObj = _addKeysToResult(snpsArray);//creates the obj that will be returned. this line creates the keys and sets default human-readable values
        try {
            const userGenes = _dataExtractor(tag);
            _snpStitcher(userGenes, resultObj);
        } catch {
            return resultObj;
        };
        return resultObj
    };

    // grabs the user's uploadID if it is not there already, loads their results
    // pulls tag from the props. tag is added to the props using the results-viewer component
    useEffect(() => {
        const { uploadID, tag } = props;
        loadResults(uploadID);
        setID(uploadID);
        loadSNPs(tag);
    }, []);

    // table does not render until the snps are loaded, preventing undefined errors
    // the goal is to match the following columns: 'sensitivity', 'genotype'
    const geneTable = snps[props.tag] && uploads[uID] ? <TableComposer tableConfig={_fillInTableConfigs(props.tag, snps)} /> : null;

    const getGraph = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        const crmKey = `results-${tag}-histogram`;
console.log('tag, tagResult.value :', tag, tagResult.value)
        if (tagResult.value) {
            return (
                <div>
                    <p>A polygenic risk score (PRS) for Alzheimer's disease provides more resolution than just the ApoE snps (rs429358 and rs7412)(<a href="https://pubmed.ncbi.nlm.nih.gov/35324888/">Tanigawa et al., 2022</a>). The histogram shows that the PRS score separates people into three peaks (left, middle, right). People in the left peak do not carry the ApoE4 risk allele, and have the lowest risk for Alzheimer's disease. People in the middle peak are usually ApoE4/ApoE3 (88%) and have an intermediate risk for Alzheimer's (4 fold). People in the right peak are usually ApoE4/ApoE4 (57%) or ApoE4/ApoE3 (36%). These people have the highest risk for Alzheimer's (12-15 fold)). ApoE generally determines which peak you will belong to, but the additional SNPs in the PRS modify the risk for Alzheimer's up or down based on the remainder of the genome.</p>
                    <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have a higher risk for Alzheimer's disease.</p>
                    <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />
                </div>
            );
        } else {
            return <p>Your genome file has not been analyzed for the Alzheimer's PRS. You can request that your account be updated to include the Alzheimer's PRS by contacting us at <a href="mailto:contact@axgen.us">contact@axgen.us</a>.</p>;
        }
    };


    const getPercentile = (tag) => {
        const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
        return <TagPercentile result={tagResult} tag={tag} usesUKData={false} />;
    };
    return (
        <div>
            <div className='title-margins'>
                <h2>{props.userName ? `${props.userName}'${props.nameEndsWithS ? '' : 's'}` : ''} Results for Alzheimer's Disease</h2>
            </div>
            <div className="card">
                <p>Your results are shown below.</p>
                {geneTable}
            </div>
            <div className="card">
                <p>How the apoE genotype affects your risk for Alzheimer's disease:</p>
                <ul>
                    <li>One copy of apoE4 increases your chances of developing late-onset Alzheimer's by four fold.</li>
                    <li>Two copies of apoE4 increases your risk 12 to 15 times.</li>
                    <li>Among persons diagnosed with Alzeimer's Disease, 60% carry at least one apoE4 allele.</li>
                    <li>In women, carrying one apoE4 allele shifts the Alzeimer's Disease risk curve five years earlier.</li>
                    <li>Two apoE4 alleles shifts the Alzeimer's Disease risk curve to 10 years earlier in both women and men. </li>
                    <li>apoE2 is protective against AD but is associated with a greater risk for hyperinsulinemia and diabetes.</li>
                    <li>Over 87% of centenarians (100 yo or older) are apoE2/apoE3 or apoE3/apoE3</li>
                </ul>
            </div>
            <div className="card">

                <div className="table-responsive">
                    <table className="article-table w-100 col-sm-12 table-bordered table-condensed cf">
                        <thead>
                            <tr>
                                <th>apoE allele</th>
                                <th>SNP(s)</th>
                                <th>Protein Change</th>
                                <th>Alzheimer's Risk</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>apoE3</td><td>rs429358(T) rs7412(C)</td><td>Cys-112, Arg-158</td><td>normal (most common allele)</td>
                            </tr>
                            <tr>
                                <td>apoE4</td><td>rs429358(C) rs7412(C)</td><td>Arg-112, Arg-158</td><td>increased risk for Alzheimer's Disease</td>
                            </tr>
                            <tr>
                                <td>apoE2</td><td>rs429358(T) rs7412(T)</td><td>Cys-112, Cys-158</td><td>decreased risk for Alzheimer’s Disease, increased risk for Diabetes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mt-3">
                    <ul>
                        <li><b>apoE allele</b>: Commonly used name of the apoE allele.</li>
                        <li><b>SNP(s)</b>: Polymorphisms used to define the apoE allele.</li>
                        <li><b>Protein Change</b>: The amino acid and its position in the apoE protein; Cys-112 means cysteine at position 112 and Arg-158 means arginine at position 158.</li>
                        <li><b>Alzheimer's Risk</b>: Your risk for getting Alzheimer's disease.</li>
                    </ul>
                </div>
            </div>
            <div className="title-break">
                <span>Polygenic Risk Score</span>
            </div>

            <div className="card">
                {getGraph("alzPRS")}

            </div>
        </div>


    );
};

export default AlzResults;

